import OrderableIsometricCharacter from "@game/engine/gameobjects/OrderableIsometricCharacter";
import ChatComponent from "@game/engine/components/entitycomponents/ChatComponent";
import NPCSpawnerSceneComponent from "@game/engine/components/scenecomponents/NPCSpawnerSceneComponent";
import { IInteractable } from "@game/engine/interfaces/IInteractable";
import { PropertiesContainer } from "@game/engine/objects/PropertiesContainer";
import OrderableIsometricGameScene from "@game/engine/scenes/OrderableIsometricGameScene";
import {
  Direction,
  DirectionDeltas,
  Directions,
} from "@game/engine/navigation/Direction";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import { twitch } from "@game/engine/utilities/AnimationHelper";
import {
  makeInteractable,
  retrieveInteraction,
} from "@game/engine/utilities/InteractableHelper";
import { TilePositionMath } from "@game/engine/utilities/TileHelper";

export default class OrderableNonPlayerCharacter
  extends OrderableIsometricCharacter
  implements IInteractable
{
  public readonly identifier: number;
  public interaction: { [key: string]: string }; 

  constructor(
    scene: OrderableIsometricGameScene,
    public tilePosition: TilePosition3D,
    characterName: string = "mr_anonymous",
    direction: Direction,
    propertiesContainer: PropertiesContainer,
    npcSpawnerSceneComponent: NPCSpawnerSceneComponent,
  ) {
    const worldPosition = scene.worldPositionAtTilePosition(
      tilePosition,
      false,
    );
    super(scene, worldPosition.x, worldPosition.y, characterName);

    // Create and add the chat component so we can add messages above our head
    this._chatComponent = this.addComponent<ChatComponent>(new ChatComponent());

    // Change the color and move it slighly above the character
    this._chatComponent.backgroundColor = 0xff0044;
    this._chatComponent.offset.y = -160;

    // Retrieve the identifier
    this.identifier = <number>propertiesContainer.identifier;

    // Look in the right direction
    this._animatedIsometricAvatarComponent.faceIfNotAlreadyFacing(direction);

    // Set up interaction if needed
    const types = propertiesContainer.getProperty("Types");
    this.interaction = {};
    if (types && types.includes("interactable")) {
      this.interaction = retrieveInteraction(propertiesContainer);

      // We assume our sprite is our interactor
      makeInteractable<OrderableNonPlayerCharacter>(
        this._animatedIsometricAvatarComponent.sprite,
        this,
      );

      // Register ourselves
      npcSpawnerSceneComponent.registerInteractable(this);
    }
  }

  public face = (direction: Direction) =>
    this._animatedIsometricAvatarComponent.faceIfNotAlreadyFacing(direction);

  public lookAt = (tilePosition: TilePosition3D) => {
    // Make the NPC face the direction of the player, for funsies
    Directions.forEach((direction) => {
      // Calculate the difference between the previous and current position
      const delta = DirectionDeltas[direction];
      const difference = TilePositionMath.sign(
        TilePositionMath.difference(tilePosition, this.tilePosition),
      );

      // We're ignoring the z axis for now
      difference.z = 0;

      // Seems like we're moving in that direction
      if (TilePositionMath.equals(delta, difference)) {
        this.face(direction);
      }
      return;
    });
  };

  //npcs should have all their messages allowed
  public allowedMessage = (sender_id: string) => true;
  public say = (message: string) => this._chatComponent.say(message, true);

  public twitch() {
    twitch(this.scene.tweens, this._animatedIsometricAvatarComponent.sprite);
  }
}
