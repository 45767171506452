import { TypedGraph, TypedNode } from "@game/engine/navigation/Pathfinder";
import PathfindingAlgorithm from "@game/engine/navigation/pathfindingalgorithms/PathfindingAlgorithm";
import PriorityQueue from "@game/engine/objects/PriorityQueue";

export default class PathfindingAlgorithmAStar extends PathfindingAlgorithm {
  public find(
    startNode: TypedNode,
    goalNode: TypedNode,
    graph: TypedGraph,
    obstacles?: TypedGraph,
  ): TypedNode[] {
    // Creating the frontier
    const frontier = new PriorityQueue<TypedNode>();
    frontier.push(startNode, 0);

    // Setting up the bookkeeping
    const cameFrom: { [index: number]: TypedNode } = {};
    const costSoFar: { [index: number]: number } = {};

    // Setting up the first step
    cameFrom[startNode.index] = null;
    costSoFar[startNode.index] = 0;

    // Start finding a path
    while (!frontier.isEmpty()) {
      const currentNode = frontier.pop();

      // Check if we've reached it
      if (this.equals(currentNode, goalNode)) {
        const path: TypedNode[] = [];
        let backTraceNode = currentNode;
        while (!this.equals(startNode, backTraceNode)) {
          path.push(backTraceNode);
          backTraceNode = cameFrom[backTraceNode.index];
        }

        // Add the starting tile as well
        path.push(startNode);

        return path.reverse();
      }

      const neighbours = currentNode.neighbours;
      for (let index = 0; index < neighbours.length; ++index) {
        const neighbourNode = neighbours[index];

        // Check if this neighbour exists
        if (undefined === neighbourNode) {
          continue;
        }

        // Check if there is an obstacle here or no
        if (
          obstacles &&
          obstacles.has(neighbourNode.x, neighbourNode.y, neighbourNode.z)
        ) {
          continue;
        }

        // Move the cost up for this one
        const cost =
          costSoFar[currentNode.index] +
          PathfindingAlgorithmAStar.heuristic(currentNode, neighbourNode);
        if (
          !costSoFar.hasOwnProperty(neighbourNode.index) ||
          cost < costSoFar[neighbourNode.index]
        ) {
          costSoFar[neighbourNode.index] = cost;

          const priority = PathfindingAlgorithmAStar.heuristic(
            goalNode,
            neighbourNode,
          );

          frontier.push(neighbourNode, priority);

          cameFrom[neighbourNode.index] = currentNode;
        }
      }
    }

    return null;
  }

  private static heuristic(nodeA: TypedNode, nodeB: TypedNode) {
    // Pythagorian distance squared
    // return Math.pow(nodeA.x - nodeB.x, 2) + Math.pow(nodeA.y - nodeB.y, 2) + Math.pow(nodeA.z - nodeB.z, 2);
    // return Math.pow(nodeA.x - nodeB.x, 2) + Math.pow(nodeA.y - nodeB.y, 2);

    // Manhattan distance
    return (
      Math.abs(nodeA.x - nodeB.x) +
      Math.abs(nodeA.y - nodeB.y) +
      Math.abs(nodeA.z - nodeB.z)
    );
    // return Math.abs(nodeA.x - nodeB.x) + Math.abs(nodeA.y - nodeB.y);
  }
}
