import {
  EnumAllEvents,
  EnumApplicationEvents,
  EnumGameEvents,
  EnumUiEvents,
} from "@shared/enums";
import { TEventHandler, dispatchEvent, handleEvent } from "@shared/Events";
import GameScene from "@engine/scenes/GameScene";
import { Task } from "./Task";
import EventEmitter = Phaser.Events.EventEmitter;
import PublicSpaceGameScene from "@engine/scenes/PublicSpaceGameScene";
import { startMiniGame } from "@game/ApplicationHandler";
import { useGameState } from "@overlay/store/gameStateStore";
export class OnboardingPageOne extends Task {
  constructor(
    eventEmitter: EventEmitter,
    gameScene: GameScene,
    textSpeed: number,
  ) {
    super(eventEmitter, gameScene, textSpeed);

    this.title = "Bit Hotel Tour";
    this.content =
      "Hey! You there! Welcome to the Bit Hotel, valued Guest! We’re glad to have you here! Before you can roam around and explore please take our quick tour to understand all there is to do around the Hotel! ";
    this.image = [
      new URL("/assets/overlay/onboarding/Piccolo-Wave.gif", import.meta.url)
        .href,
    ];
    this.buttonNext = "Next";
    this.buttonPrevious = "";

    this.events = {
      nextEventName: "onboarding_page_one_done",
      previousEventName: "onboarding_page_one_back",
      abandonEventName: "onboarding_page_one_abandon",
    };
    this.css = {};
  }

  public override buttonPreviousAction: () => void = undefined;

  public override startAction: () => void = () => {
    dispatchEvent(EnumGameEvents.GameUiOpenOnboarding, {
      ...this,
      emitter: this._eventEmitter,
      textSpeed: this._textSpeed,
    });
  };
}

export class OnboardingPagePinching extends Task {
  constructor(
    eventEmitter: EventEmitter,
    gameScene: GameScene,
    textSpeed: number,
  ) {
    super(eventEmitter, gameScene, textSpeed);

    this.title = "Bit Hotel Tour";
    this.content =
      "Pinch your fingers to zoom in or zoom out! \n Close this window and give it a try!";
    this.image = [
      new URL("/assets/overlay/onboarding/Pinching.gif", import.meta.url).href,
    ];
    this.buttonNext = "Next";
    this.buttonPrevious = "";

    this.events = {
      nextEventName: "onboarding_page_pinch_done",
      previousEventName: "onboarding_page_pinch_back",
      abandonEventName: "onboarding_page_pinch_abandon",
    };
    this.css = {};
  }

  public override buttonPreviousAction: () => void = undefined;

  public override startAction: () => void = () => {
    dispatchEvent(EnumGameEvents.GameUiOpenOnboarding, {
      ...this,
      emitter: this._eventEmitter,
      textSpeed: this._textSpeed,
    });
  };
}

export class OnboardingPageTwo extends Task {
  private _eventHandler: TEventHandler;
  constructor(
    eventEmitter: EventEmitter,
    gameScene: GameScene,
    textSpeed: number,
  ) {
    super(eventEmitter, gameScene, textSpeed);

    this.title = "Bit Hotel Tour";
    this.content =
      "Walk over to the reception on the top-left so I can tell you how the Bit Hotel works!";
    this.image = [
      new URL(
        "/assets/overlay/onboarding/Piccolo-Point-Talk.gif",
        import.meta.url,
      ).href,
    ];
    this.buttonNext = undefined;
    this.buttonPrevious = "Previous";

    this.events = {
      nextEventName: "onboarding_page_two_done",
      previousEventName: "onboarding_page_two_back",
      abandonEventName: "onboarding_page_two_abandon",
    };
    this.css = {};
  }

  public override buttonNextAction: () => void = undefined;

  public override startAction: () => void = () => {
    if (this._scene.constructor["SceneKey"] !== "LobbyScene") {
      startMiniGame(
        "LobbyScene",
        {},
        (this._scene.constructor as typeof GameScene).SceneKey,
      );
    }
    dispatchEvent(EnumGameEvents.GamePointToLocationMarker, 200);
    dispatchEvent(EnumGameEvents.GameUiOpenOnboarding, {
      ...this,
      emitter: this._eventEmitter,
      textSpeed: this._textSpeed,
    });
    this._eventHandler = handleEvent(
      "GAME_ONBOARDING_STEPONE_DONE" as EnumAllEvents,
      () => {
        this._eventEmitter.emit(this.events.nextEventName as any);
      },
    );
  };

  public override stopAction: () => void = () => {
    dispatchEvent(EnumGameEvents.GameRemoveLocationMarker, 200);
    this._eventHandler.destroy();
  };
}

export class OnboardingPageThree extends Task {
  private _eventHandler: TEventHandler;
  constructor(
    eventEmitter: EventEmitter,
    gameScene: GameScene,
    textSpeed: number,
  ) {
    super(eventEmitter, gameScene, textSpeed);

    this.title = "Bit Hotel Tour";
    this.content =
      "Inside Bit Hotel all Guests can openly chat with each other by clicking on the chat window. If you like a player you chatted with, send them a friend request! Also try some emotes!";
    this.image = [
      new URL("/assets/overlay/onboarding/Piccolo-Talk.gif", import.meta.url)
        .href,
    ];
    this.buttonNext = undefined;
    this.buttonPrevious = undefined;

    this.events = {
      nextEventName: "onboarding_page_three_done",
      previousEventName: "onboarding_page_three_back",
      abandonEventName: "onboarding_page_three_abandon",
    };
    this.css = {};
  }

  public override buttonNextAction: () => void = undefined;
  public override buttonPreviousAction: () => void = undefined;

  public override startAction: () => void = () => {
    dispatchEvent(EnumGameEvents.GameUiOpenOnboarding, {
      ...this,
      emitter: this._eventEmitter,
      textSpeed: this._textSpeed,
    });
    dispatchEvent(EnumUiEvents.UiHighlightElement, {
      elementName: "chat",
      hightlight: true,
    });
    this._eventHandler = handleEvent(EnumUiEvents.UiChatToggleChat, () => {
      this._eventEmitter.emit(this.events.nextEventName as any);
    });
  };

  public override stopAction: () => void = () => {
    dispatchEvent(EnumUiEvents.UiHighlightElement, {
      elementName: "chat",
      hightlight: false,
    });
    this._eventHandler.destroy();
  };
}

export class OnboardingPageFour extends Task {
  private _eventHandler: TEventHandler;
  constructor(
    eventEmitter: EventEmitter,
    gameScene: GameScene,
    textSpeed: number,
  ) {
    super(eventEmitter, gameScene, textSpeed);

    this.title = "Bit Hotel Tour";
    this.content =
      "If you walk to the bottom left of the lobby you’ll find a shop! Here you can interact with items and shopkeepers to find new items to buy! They refresh every month! You can also open the shop from the user menu!";
    this.image = [
      new URL(
        "/assets/overlay/onboarding/Piccolo-Point-Talk.gif",
        import.meta.url,
      ).href,
    ];
    this.buttonNext = undefined;
    this.buttonPrevious = undefined;

    this.events = {
      nextEventName: "onboarding_page_four_done",
      previousEventName: "onboarding_page_four_back",
      abandonEventName: "onboarding_page_four_abandon",
    };
    this.css = {};
  }

  public override buttonNextAction: () => void = undefined;
  public override buttonPreviousAction: () => void = undefined;

  public override startAction: () => void = () => {
    const gameScene = this._scene as PublicSpaceGameScene;
    if (this._scene.constructor["SceneKey"] !== "LobbyScene") {
      startMiniGame(
        "LobbyScene",
        {},
        (this._scene.constructor as typeof GameScene).SceneKey,
      );
    }
    dispatchEvent(EnumGameEvents.GameUiOpenOnboarding, {
      ...this,
      emitter: this._eventEmitter,
      textSpeed: this._textSpeed,
    });
    //Should clear on its own when moving scenes
    dispatchEvent(
      EnumGameEvents.GamePointToLocationMarker,
      gameScene.worldPositionAtTilePosition({ x: 5, y: 36, z: 0 }, true),
    );
    this._eventHandler = handleEvent(
      EnumApplicationEvents.ApplicationStartMinigame,
      (data) => {
        this._eventEmitter.emit(this.events.nextEventName as any);
      },
    );
  };

  public override stopAction: () => void = () => {
    this._eventHandler.destroy();
  };
}

export class OnboardingPageFive extends Task {
  private _eventHandlers: TEventHandler[] = [];
  private _opened: boolean = false;
  constructor(
    eventEmitter: EventEmitter,
    gameScene: GameScene,
    textSpeed: number,
  ) {
    super(eventEmitter, gameScene, textSpeed);

    this.title = "Bit Hotel Tour";
    this.content =
      "In the hotel there are many rooms! To visit your room and rooms of your friends simply click the room icon! In the room list you can visit any other player that has a room and find fun events that you can join!";
    this.image = [
      new URL("/assets/overlay/onboarding/Piccolo-Talk.gif", import.meta.url)
        .href,
    ];
    this.buttonNext = undefined;
    this.buttonPrevious = undefined;

    this.events = {
      nextEventName: "onboarding_page_five_done",
      previousEventName: "onboarding_page_five_back",
      abandonEventName: "onboarding_page_five_abandon",
    };
    this.css = {};
  }

  public override buttonNextAction: () => void = undefined;
  public override buttonPreviousAction: () => void = undefined;

  public override startAction: () => void = () => {
    if ((this._scene as any).constructor["SceneKey"] !== "ShopScene") {
      startMiniGame(
        "ShopScene",
        {},
        (this._scene.constructor as typeof GameScene).SceneKey,
      );
    }
    dispatchEvent(EnumGameEvents.GameUiOpenOnboarding, {
      ...this,
      emitter: this._eventEmitter,
      textSpeed: this._textSpeed,
    });
    dispatchEvent(EnumUiEvents.UiHighlightElement, {
      elementName: "visiting",
      hightlight: true,
    });
    this._eventHandlers.push(
      handleEvent(EnumUiEvents.UiOnboardingActiveModal, (modal) => {
        if ((modal === "RoomsModal") & !this._opened) {
          this._opened = true;
          dispatchEvent(EnumUiEvents.UiHighlightElement, {
            elementName: "visiting",
            hightlight: false,
          });
        } else if (modal === "" && this._opened) {
          //wait for modal to close
          setTimeout(() => {
            this._eventEmitter.emit(this.events.nextEventName as any);
          }, 200);
        }
      }),
    );
  };

  public override stopAction: () => void = () => {
    dispatchEvent(EnumUiEvents.UiHighlightElement, {
      elementName: "visiting",
      hightlight: false,
    });
    this._eventHandlers.forEach((handler) => {
      handler.destroy();
    });
  };
}

export class OnboardingPageSix extends Task {
  private _eventHandlers: TEventHandler[] = [];
  private _opened: boolean = false;
  constructor(
    eventEmitter: EventEmitter,
    gameScene: GameScene,
    textSpeed: number,
  ) {
    super(eventEmitter, gameScene, textSpeed);

    this.title = "Bit Hotel Tour";
    this.content =
      "To acquire items in the Hotel it is important to know that you need to create a wallet. Simply click the connect wallet button and open a Bit Hotel wallet with your email! Or connect an external wallet if you prefer!";
    this.image = [
      new URL("/assets/overlay/onboarding/Piccolo-Yay.gif", import.meta.url)
        .href,
    ];
    this.buttonNext = undefined;
    this.buttonPrevious = undefined;

    this.events = {
      nextEventName: "onboarding_page_six_done",
      previousEventName: "onboarding_page_six_back",
      abandonEventName: "onboarding_page_six_abandon",
    };
    this.css = {};
  }

  public override buttonNextAction: () => void = undefined;
  public override buttonPreviousAction: () => void = undefined;

  public override startAction: () => void = () => {
    if (this._scene.constructor["SceneKey"] !== "ShopScene") {
      startMiniGame(
        "ShopScene",
        {},
        (this._scene.constructor as typeof GameScene).SceneKey,
      );
    }
    dispatchEvent(EnumGameEvents.GameUiOpenOnboarding, {
      ...this,
      emitter: this._eventEmitter,
      textSpeed: this._textSpeed,
    });
    dispatchEvent(EnumUiEvents.UiHighlightElement, {
      elementName: "connect_wallet",
      hightlight: true,
    });
    this._eventHandlers.push(
      handleEvent(EnumUiEvents.UiOnboardingActiveModal, (data) => {
        if (data === "ChangeWalletModal") {
          this._opened = true;
          dispatchEvent(EnumUiEvents.UiHighlightElement, {
            elementName: "connect_wallet",
            hightlight: false,
          });
        }
      }),
      handleEvent(
        EnumGameEvents.GameUiNpcInteraction,
        (data: { name: string }) => {
          if (data.name === "Connect to wallet") {
            this._opened = true;
            dispatchEvent(EnumUiEvents.UiHighlightElement, {
              elementName: "connect_wallet",
              hightlight: false,
            });
          }
        },
      ),
      handleEvent(EnumUiEvents.UiOnboardingActiveModal, (modal) => {
        if (modal === "" && this._opened) {
          setTimeout(() => {
            this._eventEmitter.emit(this.events.nextEventName as any);
          }, 200);
        }
      }),
    );
  };

  public override stopAction: () => void = () => {
    dispatchEvent(EnumUiEvents.UiHighlightElement, {
      elementName: "connect_wallet",
      hightlight: false,
    });
    this._eventHandlers.forEach((handler) => {
      handler.destroy();
    });
  };
}

export class OnboardingPageSeven extends Task {
  private _eventHandler: TEventHandler;
  constructor(
    eventEmitter: EventEmitter,
    gameScene: GameScene,
    textSpeed: number,
  ) {
    super(eventEmitter, gameScene, textSpeed);

    this.title = "Bit Hotel Tour";
    this.content =
      "By walking over to the arcade area you can participate in a range of competitive minigames. New mini-games are added frequently! The Arcade is on the right side of the lobby.";
    this.image = [
      new URL(
        "/assets/overlay/onboarding/Piccolo-Point-Talk.gif",
        import.meta.url,
      ).href,
    ];
    this.buttonNext = "Teleport";
    this.buttonPrevious = undefined;

    this.events = {
      nextEventName: "onboarding_page_seven_done",
      previousEventName: "onboarding_page_seven_back",
      abandonEventName: "onboarding_page_seven_abandon",
    };
    this.css = {};
  }

  public override buttonNextAction: () => void = () => {
    startMiniGame(
      "ArcadeScene",
      {},
      (this._scene.constructor as typeof GameScene).SceneKey,
    );
  };

  public override buttonPreviousAction: () => void = undefined;

  public override startAction: () => void = () => {
    if (this._scene.constructor["SceneKey"] !== "ShopScene") {
      startMiniGame(
        "ShopScene",
        {},
        (this._scene.constructor as typeof GameScene).SceneKey,
      );
    }
    dispatchEvent(EnumGameEvents.GameUiOpenOnboarding, {
      ...this,
      emitter: this._eventEmitter,
      textSpeed: this._textSpeed,
    });
    this._eventHandler = handleEvent(
      EnumApplicationEvents.ApplicationStartMinigame,
      (data) => {
        this._eventEmitter.emit(this.events.nextEventName as any);
      },
    );
  };

  public override stopAction: () => void = () => {
    this._eventHandler.destroy();
  };
}

export class OnboardingPageEight extends Task {
  constructor(
    eventEmitter: EventEmitter,
    gameScene: GameScene,
    textSpeed: number,
  ) {
    super(eventEmitter, gameScene, textSpeed);

    this.title = "Bit Hotel Tour";
    this.content =
      "Welcome to the Arcade! By winning mini-games you earn tickets that rank you on a global leaderboard of players. Based on the amount of games you win per season you earn unique rewards.";
    this.image = [
      new URL("/assets/overlay/onboarding/Piccolo-Talk.gif", import.meta.url)
        .href,
    ];
    this.buttonNext = "Next";
    this.buttonPrevious = undefined;

    this.events = {
      nextEventName: "onboarding_page_eight_done",
      previousEventName: "onboarding_page_eight_back",
      abandonEventName: "onboarding_page_eight_abandon",
    };
    this.css = {};
  }

  public override buttonPreviousAction: () => void = undefined;

  public override startAction: () => void = () => {
    if (this._scene.constructor["SceneKey"] !== "ArcadeScene") {
      startMiniGame(
        "ArcadeScene",
        {},
        (this._scene.constructor as typeof GameScene).SceneKey,
      );
    }
    dispatchEvent(EnumGameEvents.GameUiOpenOnboarding, {
      ...this,
      emitter: this._eventEmitter,
      textSpeed: this._textSpeed,
    });
  };
}

export class OnboardingPageNine extends Task {
  constructor(
    eventEmitter: EventEmitter,
    gameScene: GameScene,
    textSpeed: number,
  ) {
    super(eventEmitter, gameScene, textSpeed);

    this.title = "Bit Hotel Tour";
    this.content =
      "To have your first in-game experience, click and queue for a game of CryptoBomber";
    this.image = [
      new URL(
        "/assets/overlay/onboarding/Piccolo-Point-Talk.gif",
        import.meta.url,
      ).href,
    ];
    this.buttonNext = "Play";
    this.buttonPrevious = undefined;

    this.events = {
      nextEventName: "onboarding_page_nine_done",
      previousEventName: "onboarding_page_nine_back",
      abandonEventName: "onboarding_page_nine_abandon",
    };
    this.css = {};
  }

  public override buttonPreviousAction: () => void = undefined;

  public override startAction: () => void = () => {
    if (this._scene.constructor["SceneKey"] !== "ArcadeScene") {
      startMiniGame(
        "ArcadeScene",
        {},
        (this._scene.constructor as typeof GameScene).SceneKey,
      );
    }
    dispatchEvent(EnumGameEvents.GameUiOpenOnboarding, {
      ...this,
      emitter: this._eventEmitter,
      textSpeed: this._textSpeed,
    });
  };

  public stopAction: () => void = () => {
    const gameState = useGameState();
    gameState.joinMinigameMatchQueue({
      sceneKey: "CryptoBomberScene",
      matchData: {
        playableAmount: 0,
        currency: "tickets",
      },
    });
    $modals.closeAllModals();
  };
}

export class OnboardingPageTen extends Task {
  constructor(
    eventEmitter: EventEmitter,
    gameScene: GameScene,
    textSpeed: number,
  ) {
    super(eventEmitter, gameScene, textSpeed);

    this.title = "Bit Hotel Tour";
    this.content = "That's it! You're now an official hotel guest!";
    this.image = [
      new URL("/assets/overlay/onboarding/Piccolo-Yay.gif", import.meta.url)
        .href,
    ];
    this.buttonNext = "Explore";
    this.buttonPrevious = undefined;

    this.events = {
      nextEventName: "onboarding_page_ten_done",
      previousEventName: "onboarding_page_ten_back",
      abandonEventName: "onboarding_page_ten_abandon",
    };
    this.css = {};
  }

  public override buttonPreviousAction: () => void = undefined;

  public override startAction: () => void = () => {
    if (this._scene.constructor["SceneKey"] !== "ArcadeScene") {
      startMiniGame(
        "ArcadeScene",
        {},
        (this._scene.constructor as typeof GameScene).SceneKey,
      );
    }
    dispatchEvent(EnumGameEvents.GameUiOpenOnboarding, {
      ...this,
      emitter: this._eventEmitter,
      textSpeed: this._textSpeed,
    });
  };

  public override stopAction: () => void = () => {
    $modals.closeAllModals();
  };
}
