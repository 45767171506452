import SceneComponent from "@game/engine/components/SceneComponent";
import { InteractableEventTypes } from "@game/engine/eventtypes/InteractableEventTypes";
import { NPCInteractableEventTypes } from "@game/engine/eventtypes/NPCInteractableEventTypes";
import { IInteractableImagesDelegate } from "@game/engine/interfaces/IInteractableImagesDelegate";
import OrderableImage from "@game/engine/objects/OrderableImage";
import OrderableIsometricGameScene from "@game/engine/scenes/OrderableIsometricGameScene";
import EventEmitter = Phaser.Events.EventEmitter;

export default class InteractableImagesComponent extends SceneComponent {
  public set delegate(value: IInteractableImagesDelegate) {
    this._delegate = value;
  }

  public events: EventEmitter;

  protected declare _scene: OrderableIsometricGameScene;

  private _delegate: IInteractableImagesDelegate;

  constructor() {
    super();

    this.events = new EventEmitter();
  }

  protected onSceneSet(scene?: OrderableIsometricGameScene) {
    super.onSceneSet(scene);

    scene.interactables.forEach((interactable) => {
      if (interactable instanceof OrderableImage) {
        // Attach ourselves to interactable NPCs
        interactable.on(
          InteractableEventTypes.OnHover,
          (orderableImage: OrderableImage) => {
            this._delegate?.onInteractableImageHover(orderableImage);
            this.events.emit(NPCInteractableEventTypes.OnHover, orderableImage);
          },
        );
        interactable.on(
          InteractableEventTypes.OnInteract,
          (orderableImage: OrderableImage) => {
            this._delegate?.onInteractableImageInteract(orderableImage);
            this.events.emit(
              NPCInteractableEventTypes.OnInteract,
              orderableImage,
            );
          },
        );
        interactable.on(
          InteractableEventTypes.OnOut,
          (orderableImage: OrderableImage) => {
            this._delegate?.onInteractableImageOut(orderableImage);
            this.events.emit(NPCInteractableEventTypes.OnOut, orderableImage);
          },
        );
      }
    });
  }
}
