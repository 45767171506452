import PathfindingAlgorithmAStar from "@game/engine/navigation/pathfindingalgorithms/PathfindingAlgorithmAStar";
import PathfindingAlgorithmBee from "@game/engine/navigation/pathfindingalgorithms/PathfindingAlgorithmBee";
import { getTiledProperty } from "@game/engine/utilities/TiledHelper";
import equals from "@game/engine/utilities/TilePositionHelper";
import {
  Direction,
  DirectionDeltasIncludingElevation,
  DirectionElevationDeltas,
  NumberOfDirections,
  OppositeDirection,
} from "./Direction";
import { Graph, GraphNode } from "./Graph";
import TilePath from "../objects/TilePath";
import Tile = Phaser.Tilemaps.Tile;
import Tilemap = Phaser.Tilemaps.Tilemap;
import LayerData = Phaser.Tilemaps.LayerData;

export enum PathfindingAlgorithmsTypes {
  AStar = "AStar",
  Bee = "Bee", // Get it? Because it's a bee-line between points?
}

export declare type TilePosition3D = { x: number; y: number; z: number };
export declare type TypedGraph = Graph<Tile>;
export declare type TypedNode = GraphNode<Tile>;

export default class Pathfinder {
  private _algorithms = {
    [PathfindingAlgorithmsTypes.AStar]: new PathfindingAlgorithmAStar(),
    [PathfindingAlgorithmsTypes.Bee]: new PathfindingAlgorithmBee(),
  };
  private _connectionToDirection = {
    "N-S": Direction.South,
    "E-W": Direction.West,
  };
  private readonly _graphObstacles: Graph<Tile>;
  private readonly _graphWalkables: Graph<Tile>;
  private readonly _elevations: {
    connection: string;
    position: TilePosition3D;
  }[];

  constructor(
    map: Tilemap,
    private _defaultAlgorithmType: PathfindingAlgorithmsTypes = PathfindingAlgorithmsTypes.AStar,
  ) {
    const depth = map.height;
    const height = getTiledProperty(map, "NumberOfLevels");
    if (null === height) {
      console.log('The "NumberOfLevels" property is missing on the map');
      return;
    }
    const width = map.width;

    this._graphObstacles = new Graph<Tile>(
      width,
      depth,
      height,
      NumberOfDirections,
    );
    this._graphWalkables = new Graph<Tile>(
      width,
      depth,
      height,
      NumberOfDirections,
    );
    this._elevations = [];

    // Parse the map fully
    map.layers.forEach((layerData) => {
      // Retrieve / assume some metadata
      const level = getTiledProperty(layerData, "Level");
      const types = getTiledProperty(layerData, "Types");

      // Parse all tiles in the layer
      if (types && types.includes("navigatable")) {
        Pathfinder.iterateLayerData(layerData, (x, y, tile) => {
          // MARKTWAIN - these will be gone
          // Check if it's a special tile
          if ("Type" in tile.properties) {
            if (
              tile.properties["Type"] === "Elevation" &&
              "Connection" in tile.properties
            ) {
              this._elevations.push({
                connection: tile.properties["Connection"],
                position: { x: x, y: y, z: level },
              });
            }
          }

          // Always add it to walkable environments
          this._graphWalkables.add(x, y, level, tile);
        });
      }
    });

    // Once all tiles are in position, generate their neighbours
    this.generateNeighbours();
  }

  public setPositionsAsBlocked(
    positions: { position: TilePosition3D; tile: Tile }[],
    blocked: boolean,
  ) {
    const graphObstacles = this._graphObstacles;

    positions.forEach((position) => {
      if (blocked) {
        graphObstacles.add(
          position.position.x,
          position.position.y,
          position.position.z,
          position.tile,
        );
      } else {
        graphObstacles.remove(
          position.position.x,
          position.position.y,
          position.position.z,
          position.tile,
        );
      }
    });
  }

  public find(
    start: TilePosition3D,
    goal: TilePosition3D,
    algorithmType?: PathfindingAlgorithmsTypes,
  ): TilePath {
    // Sanity check 1
    if (!goal || !start || equals(start, goal)) {
      return new TilePath();
    }

    const goalNode = this._graphWalkables.get(goal.x, goal.y, goal.z);
    const startNode = this._graphWalkables.get(start.x, start.y, start.z);

    // Sanity check 2
    if (undefined === goalNode || undefined === startNode) {
      return new TilePath();
    }

    // Always return a tilepath, even if it's empty
    return new TilePath(
      this._algorithms[algorithmType || this._defaultAlgorithmType].find(
        startNode,
        goalNode,
        this._graphWalkables,
        this._graphObstacles,
      ) || [],
    );
  }

  public getClosestWalkableNode(
    position: TilePosition3D,
  ): TypedNode | undefined {
    if (!this._graphWalkables.has(position.x, position.y, position.z)) {
      return undefined;
    }

    const startNode = this._graphWalkables.get(
      position.x,
      position.y,
      position.z,
    );
    const checkedNodes: TypedNode[] = [];
    const nodeQueue: TypedNode[] = [].concat(startNode.neighbours);

    while (nodeQueue.length > 0) {
      const node = nodeQueue.pop();

      if (node === undefined) {
        continue;
      }

      if (!this._graphObstacles.has(node.x, node.y, node.z)) {
        return node;
      }

      checkedNodes.push(node);
      node.neighbours.forEach((neighbour) => {
        if (
          checkedNodes.indexOf(node) === -1 &&
          nodeQueue.indexOf(node) === -1
        ) {
          nodeQueue.unshift(neighbour);
        }
      });
    }

    return undefined;
  }

  public getRandomNode(includeBlocked: boolean = false): TypedNode {
    // If including blocked, just return any random walkable node
    if (includeBlocked) {
      return this._graphWalkables.allNodes[
        Phaser.Math.Between(0, this._graphWalkables.allNodes.length)
      ];
    }

    // Otherwise filter the blocked nodes out
    const allBlockedNodes = this._graphObstacles.allNodes;
    const allWalkableNodes = this._graphWalkables.allNodes;

    const usableNodes = allWalkableNodes.filter((walkableNode) => {
      return !allBlockedNodes.some((blockedNode) => {
        return blockedNode.hasEqualPosition(walkableNode.position);
      });
    });

    return usableNodes[Phaser.Math.Between(0, usableNodes.length)];
  }

  public isBlocked(position: TilePosition3D): boolean {
    // Not sure why it happens after merging memory branch, but it does
    if (this._graphObstacles === undefined) {
      return false;
    }
    return this._graphObstacles.has(position.x, position.y, position.z);
  }

  public linkElevation(node: TypedNode, connection: string) {
    this.linkElevationInDirection(
      node,
      this._connectionToDirection[connection],
    );
  }

  private generateNeighbours() {
    // First link everything on their own level if possible
    this._graphWalkables.linkAllNeighboursForDeltas(
      DirectionDeltasIncludingElevation,
    );

    // Next, link all elevations
    // this._elevations.forEach((elevation) => {
    //   const { connection, position } = elevation;
    //   this.linkElevation(
    //     this._graphWalkables.getAtPosition(position),
    //     connection,
    //   );
    // });
  }

  public linkElevationInDirection(node: TypedNode, direction: Direction) {
    if (!node.hasNeighbour(direction)) {
      const neighbour: TypedNode =
        this._graphWalkables.linkNeighbourAtIndexForDelta(
          node,
          direction,
          DirectionElevationDeltas[direction],
        );

      // Seems like we've linked up! So also link the neighbour back to us
      if (null !== neighbour) {
        const oppositeDirection = OppositeDirection[direction];
        this._graphWalkables.linkNeighbourAtIndexForDelta(
          neighbour,
          oppositeDirection,
          DirectionElevationDeltas[oppositeDirection],
        );
      }
    }
  }

  private static iterateLayerData(
    layerData: LayerData,
    callback: (x: number, y: number, tile: Tile) => void,
  ) {
    const tileMap = layerData.data;
    // Retrieve all non-empty tiles directly from the layer's data
    for (let y = 0; y < layerData.height; ++y) {
      for (let x = 0; x < layerData.width; ++x) {
        const tile: Tile = tileMap[y][x];

        // Only add if it's not an empty tile
        if (tile.index > -1) {
          callback(x, y, tile);
        }
      }
    }
  }
}
