const AchievementRangeStart = 300;
const SystemNotificationRangeStart = 100;
const TaskRangeStart = 200;
export const NotificationCodes = {
  SystemNotificationRangeStart,
  SystemBannerMessage: SystemNotificationRangeStart + 1,
  SystemForceLogout: SystemNotificationRangeStart + 10,
  TaskRangeStart,
  DailyTaskCompleted: TaskRangeStart + 1,
  DailyTaskProgressed: TaskRangeStart + 2,
  DailyTaskStreakContinued: TaskRangeStart + 3,
  DailyTaskStreakCompleted: TaskRangeStart + 4,
  CircadianTaskCompleted: TaskRangeStart + 5,
  CircadianTaskProgressed: TaskRangeStart + 6,
  AchievementRangeStart,
  AchievementProgressed: AchievementRangeStart + 1,
  AchievementUnlocked: AchievementRangeStart + 2,
};
