import TextStyle = Phaser.Types.GameObjects.Text.TextStyle;

export function NumberToCSSColorString(color: number): string {
  return "#" + color.toString(16).padStart(6, "0");
}

export const FontSize: number = 14;
export const FontFamily: string = "Depixel";
export namespace Fonts {
  export function LargeText(
    color: number = 0x000000,
    align = "center",
  ): TextStyle {
    return {
      align,
      color: NumberToCSSColorString(color),
      fontFamily: FontFamily,
      fontSize: FontSize + 4 + "px",
      wordWrap: { useAdvancedWrap: true },
    };
  }

  export function NormalText(
    color: number = 0x000000,
    align = "center",
  ): TextStyle {
    return {
      align,
      color: NumberToCSSColorString(color),
      fontFamily: FontFamily,
      fontSize: FontSize + "px",
      wordWrap: { useAdvancedWrap: true },
    };
  }

  export function SmallText(
    color: number = 0x000000,
    align = "center",
  ): TextStyle {
    return {
      align,
      color: NumberToCSSColorString(color),
      fontFamily: FontFamily,
      fontSize: FontSize - 4 + "px",
      wordWrap: { useAdvancedWrap: true },
    };
  }
}

export const FONT_SIZE: number = 14;
export namespace Fonts {
  export const NormalTextBlack: TextStyle = {
    color: "#000000",
    fontFamily: "Depixel",
    fontSize: FONT_SIZE + "px",
    wordWrap: { useAdvancedWrap: true },
  };

  export const NormalTextWhite: TextStyle = {
    color: "#ffffff",
    fontFamily: "Depixel",
    fontSize: FONT_SIZE + "px",
    wordWrap: { useAdvancedWrap: true },
  };
  export const NormalTextWhiteCentered: TextStyle = {
    align: "center",
    ...NormalTextWhite,
  };

  export function NormalTextWhiteCenteredWithWidth(width: number): TextStyle {
    return {
      fixedWidth: width,
      ...NormalTextWhiteCentered,
    };
  }

  export const SmallTextWhite: TextStyle = {
    color: "#ffffff",
    fontFamily: "Depixel",
    fontSize: FONT_SIZE - 4 + "px",
    wordWrap: { useAdvancedWrap: true },
  };

  export const SmallTextWhiteCentered: TextStyle = {
    align: "center",
    ...SmallTextWhite,
  };

  export function SmallTextWhiteCenteredWithWidth(width: number): TextStyle {
    return {
      fixedWidth: width,
      ...SmallTextWhiteCentered,
    };
  }
}
