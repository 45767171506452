import { IAudio } from "@engine/components/scenecomponents/AudioComponent";
import { postMessage } from "./postMessage";
import { EventType } from "./types";

export function mobileAddBackgroundMusic(audio: IAudio) {
  postMessage({
    type: EventType.ADD_BACKGROUND_MUSIC,
    audio: audio,
  });
}

export function mobileAddSoundEffects(audios: IAudio[]) {
  postMessage({
    type: EventType.ADD_SOUND_EFFECTS,
    audios: audios,
  });
}

export function mobileStopBackgroundAudio() {
  postMessage({
    type: EventType.STOP_BACKGROUND_AUDIO,
  });
}

export function mobilePauseSoundEffects(key: string) {
  postMessage({
    type: EventType.PAUSE_SOUND_EFFECTS,
    audioKey: key,
  });
}

export function mobileResumeSoundEffects(key: string) {
  postMessage({
    type: EventType.RESUME_SOUND_EFFECTS,
    audioKey: key,
  });
}

export function mobilePlaySoundEffect(key: string, loop: boolean = false) {
  postMessage({
    type: EventType.PLAY_SOUND_EFFECT,
    audioKey: key,
    audioLoop: loop,
  });
}

export function mobilePlayBackgroundAudio(loop: boolean = false) {
  postMessage({
    type: EventType.PLAY_BACKGROUND_AUDIO,
    audioLoop: loop,
  });
}

export function mobileStopSoundEffect(key: string) {
  postMessage({
    type: EventType.STOP_SOUND_EFFECT,
    audioKey: key,
  });
}
