import { ITradeDelegate } from "@game/engine/interfaces/ITradeDelegate";
import Network from "@game/engine/networking/Network";
import NotificationCenter, {
  NotificationCenterUpdateTypes,
} from "@game/engine/networking/NotificationCenter";
import { Notification } from "@heroiclabs/nakama-js";
import {
  dispatchEvent,
  EnumGameEvents,
  EnumUiEvents,
  handleEvent,
  TEventHandler,
} from "@shared/Events";
import { TradeItem, TradeSide } from "@shared/types/TradeTypes";
import { timeStamp } from "console";
import SceneComponent from "../SceneComponent";
import { Toast } from "@engine/notifications/Toast";
import { NFTHelpers } from "@shared/nft";
import { walletAddress } from "@overlay/modules/chain/wallet";
import { currentChainId } from "@overlay/modules/chain";

interface TradeProposal {
  from: string;
  key: string;
}

interface TradeAnswer {
  key: string;
  to: string;
  answer: boolean;
  side?: TradeSide;
}

interface NotificationTradePropsal extends Notification {
  content: TradeProposal;
}

interface NotificationTradeAnswer extends Notification {
  content: TradeAnswer;
}

interface NotificationChangeItem extends Notification {
  content: {
    key: string;
    object: TradeSide;
  };
}

interface NotificationTradePending extends Notification {
  content: {
    key: string;
    object: TradeSide;
  };
}

interface NotificationTradeCommit extends Notification {
  content: {
    key: string;
    commit: boolean;
  };
}

interface NotificationTradeAbandon extends Notification {
  content: {
    key: string;
  };
}

interface AnswerTradeResult extends TradeAnswer {
  status: boolean;
  error: string;
}

interface ChangeItemResult extends TradeSide {
  status: boolean;
  error: string;
}

interface CommitResult {
  key?: string;
  commit?: boolean;
  status: boolean;
  error: string;
}

export class TradeSceneComponent extends SceneComponent {
  private _delegate: ITradeDelegate;
  private _eventHandlers: Array<TEventHandler> = [];
  private _notificationHandlers: Array<string> = [];
  public set delegate(value: ITradeDelegate) {
    this._delegate = value;
  }

  constructor() {
    super();

    this._eventHandlers.push(
      handleEvent(
        EnumUiEvents.UiNetworkTradeProposal,
        (data: { toId: string }) => {
          this._delegate.onTradePropose(data.toId);
        },
      ),
      handleEvent(
        EnumUiEvents.UiNetworkTradeAnswer,
        (data: { key: string; from: string; answer: boolean }) => {
          this._delegate.onTradeAnswer(data.key, data.from, data.answer);
        },
      ),
      handleEvent(
        EnumUiEvents.UiNetworkTradeChange,
        (data: { key: string; side: TradeSide }) => {
          this._delegate.onTradeChange(data.key, data.side);
        },
      ),
      handleEvent(
        EnumUiEvents.UiNetworkTradeCommit,
        (data: { key: string; tx: string; commit: true }) => {
          this._delegate.onTradeCommit(data.key, data.tx, data.commit);
        },
      ),
      handleEvent(
        EnumUiEvents.UiNetworkTradeAbandon,
        (data: { key: string }) => {
          this._delegate.onTradeAbandon(data.key);
        },
      ),
      handleEvent(EnumGameEvents.GameNetworkTradeGetOngoingTrades, () => {
        setTimeout(() => {
          this.getOngoingTrades();
        }, 3000);
      }),
    );
    NotificationCenter.instance.addListener<NotificationCenterUpdateTypes.RetrievedTradeProposal>(
      NotificationCenterUpdateTypes.RetrievedTradeProposal,
      (notification: Notification) => {
        this.captureProposal(notification as NotificationTradePropsal);
      },
    ),
      NotificationCenter.instance.addListener<NotificationCenterUpdateTypes.RetrievedTradeAnswer>(
        NotificationCenterUpdateTypes.RetrievedTradeAnswer,
        (notification: Notification) => {
          this.captureAnswer(notification as NotificationTradeAnswer);
        },
      ),
      NotificationCenter.instance.addListener<NotificationCenterUpdateTypes.RetrievedTradeChange>(
        NotificationCenterUpdateTypes.RetrievedTradeChange,
        (notification: Notification) => {
          this.captureChangeItem(notification as NotificationChangeItem);
        },
      ),
      NotificationCenter.instance.addListener<NotificationCenterUpdateTypes.RetrievedTradePending>(
        NotificationCenterUpdateTypes.RetrievedTradePending,
        (notification: Notification) => {
          this.captureTradePending(notification as NotificationTradePending);
        },
      ),
      NotificationCenter.instance.addListener<NotificationCenterUpdateTypes.RetrievedTradeCommit>(
        NotificationCenterUpdateTypes.RetrievedTradeCommit,
        (notification: Notification) => {
          this.captureTradeCommit(notification as NotificationTradeCommit);
        },
      ),
      NotificationCenter.instance.addListener<NotificationCenterUpdateTypes.RetrievedTradeAbandon>(
        NotificationCenterUpdateTypes.RetrievedTradeAbandon,
        (notification: Notification) => {
          this.captureTradeAbandon(notification as NotificationTradeAbandon);
        },
      ),
      this._notificationHandlers.push(
        NotificationCenterUpdateTypes.RetrievedTradeProposal,
        NotificationCenterUpdateTypes.RetrievedTradeAnswer,
        NotificationCenterUpdateTypes.RetrievedTradeChange,
        NotificationCenterUpdateTypes.RetrievedTradePending,
        NotificationCenterUpdateTypes.RetrievedTradeCommit,
        NotificationCenterUpdateTypes.RetrievedTradeAbandon,
      );
  }

  //Proposal
  public async proposeTrade(toId: string) {
    const result = await Network.core.tradePropose(toId);
    const resultObject: {
      id: string;
      to: string;
      status: boolean;
      error: string;
    } = result.payload as any;
    if (!resultObject.status) {
      Toast.error(resultObject.error);
      return;
    }
    const userName = (await Network.getUsersData(toId))[0].username;
    Toast.info(`Trade proposal to "${userName}" sent`);
  }

  private async captureProposal(proposal: NotificationTradePropsal) {
    const user = await Network.getUsersData(proposal.content.from);
    dispatchEvent(EnumGameEvents.GameUiTradeProposal, {
      key: proposal.content.key,
      userId: proposal.content.from,
      name: user[0].username,
      userIcon: user[0].metadata.activeCharacter.name,
    });
  }

  public async answerTrade(key: string, from: string, answer: boolean) {
    const result = await Network.core.tradeAnswer(key, from, answer);
    console.log(result);
    const object = result.payload as AnswerTradeResult;
    if (!object.status) {
      Toast.error(object.error);
      return;
    }
    if (answer) {
      const userName = (await Network.getUsersData(from))[0].username;
      dispatchEvent(EnumGameEvents.GameUiTradeOpen, {
        key: key,
        object: {
          from: from,
          fromItems: await this.TradeItemsToInventoryTile(
            new Array<TradeItem>(),
          ),
          to: Network.core.currentUser.id,
          otherName: userName,
          otherAddress: object.side.otherAddress,
          toItems: await this.TradeItemsToInventoryTile(new Array<TradeItem>()),
          locked: new Map<string, boolean>(),
          commited: new Map<string, boolean>(),
          left: object.side.left,
          pending: false,
        },
      });
    }
  }

  private async captureAnswer(notification: NotificationTradeAnswer) {
    const otherUser = (await Network.getUsersData(notification.content.to))[0];
    if (notification.content.answer) {
      dispatchEvent(EnumGameEvents.GameUiTradeOpen, {
        key: notification.content.key,
        object: {
          from: Network.core.currentUser.id,
          fromItems: await this.TradeItemsToInventoryTile(
            new Array<TradeItem>(),
          ),
          to: notification.content.to,
          otherName: otherUser.username,
          otherAddress: notification.content.side.otherAddress,
          otherIcon: otherUser.metadata.activeCharacter.name,
          left: notification.content.side.left,
          toItems: await this.TradeItemsToInventoryTile(new Array<TradeItem>()),
          locked: new Map<string, boolean>([
            [Network.core.currentUser.id, false],
            [notification.content.to, false],
          ]),
          commited: new Map<string, boolean>([
            [Network.core.currentUser.id, false],
            [notification.content.to, false],
          ]),
          pending: false,
        },
      });
    } else {
      Toast.warning(`Trade proposal to "${otherUser.username}" declined`);
    }
  }

  public async changeItem(key: string, side: TradeSide) {
    const result = await Network.tradeChange(
      key,
      (side.items as any[]).map(
        (item) =>
          <TradeItem>{
            address: item.address,
            prop: item.tokenId
              ? item.tokenId.toString()
              : item.amount.toString(),
          },
      ),
      side.locked,
    );
    const resultObject: ChangeItemResult = result.payload as any;
    if (!resultObject.status) {
      Toast.error(resultObject.error);
      return;
    }
    const resultSide = resultObject as TradeSide;
    const itemsTiles = await this.TradeItemsToInventoryTile(resultSide.items);
    dispatchEvent(EnumGameEvents.GameUiTradeChangeItem, {
      key: key,
      object: {
        items: itemsTiles,
        otherUser: resultSide.otherUser,
        locked: resultSide.locked,
        commited: resultSide.commited,
        left: resultSide.left,
        otherAddress: resultSide.otherAddress,
        seed: (resultSide as any).seed ? (resultSide as any).seed : "",
      },
    });
  }

  public async commit(key: string, tx: string, answer: boolean) {
    const result = await Network.tradeCommit(key, tx, answer);
    const object = result.payload as CommitResult;
    if (object.status === false) {
      Toast.error(object.error);
      return;
    }

    dispatchEvent(EnumGameEvents.GameUiTradeCommit, {
      key: object.key,
      commit: answer,
      userId: await Network.getCurrentUserId(),
    });
  }

  public async abandon(key: string) {
    const result = await Network.tradeAbandon(key);
    if ((result.payload as any).status === false) {
      Toast.error((result.payload as any).error);
      return;
    }
    dispatchEvent(EnumGameEvents.GameUiTradeAbandon, {
      key: key,
    });
  }

  private async captureChangeItem(notification: NotificationTradePending) {
    const side = notification.content.object as TradeSide;
    const itemsTiles = await this.TradeItemsToInventoryTile(side.items);
    dispatchEvent(EnumGameEvents.GameUiTradeChangeItem, {
      key: notification.content.key,
      object: {
        items: itemsTiles,
        otherUser: side.otherUser,
        locked: side.locked,
        left: side.left,
        otherAddress: side.otherAddress,
        commited: side.commited,
      },
    });
  }

  //Change Item
  private async captureTradePending(notification: NotificationTradePending) {
    const side = notification.content.object as TradeSide;
    const itemsTiles = await this.TradeItemsToInventoryTile(side.items);
    dispatchEvent(EnumGameEvents.GameUiTradeChangeItem, {
      key: notification.content.key,
      object: {
        items: itemsTiles,
        otherUser: side.otherUser,
        locked: side.locked,
        commited: side.commited,
        left: side.left,
        otherAddress: side.otherAddress,
        seed: (side as any).seed,
      },
    });
  }

  public async captureTradeCommit(notification: NotificationTradeCommit) {
    dispatchEvent(EnumGameEvents.GameUiTradeCommit, {
      key: notification.content.key,
      commit: notification.content.commit,
      userId: notification.sender_id,
    });
  }

  public async captureTradeAbandon(notification: NotificationTradeAbandon) {
    dispatchEvent(EnumGameEvents.GameUiTradeAbandon, {
      key: notification.content.key,
    });
  }

  private async TradeItemsToInventoryTile(items: TradeItem[]): Promise<
    {
      id: string;
      address: string;
      name?: string;
      amount?: string;
    }[]
  > {
    if (items.length === 0) return [];
    const set: Array<{
      id: string;
      address: string;
      name?: string;
      amount?: string;
    }> = [];
    for (const item of items) {
      const nft = NFTHelpers.allNfts.find(
        (nft) =>
          -1 !==
          nft.address.findIndex(
            (address) =>
              address.toLocaleLowerCase() === item.address.toLocaleLowerCase(),
          ),
      );
      if (nft === undefined) {
        Toast.error(`Cannot find NFT: ${item.address} with id: ${item.prop}`);
      } else {
        set.push({
          id: "not_set",
          address: item.address,
          name: nft.metadata.name,
          amount: item.prop,
        });
      }
    }
    return set;
  }

  public async getOngoingTrades(): Promise<void> {
    const results = await Network.tradeGetUserTrades();
    if (results.length > 0) {
      for (const result of results) {
        if (
          result.wallet.toLocaleLowerCase() ===
            walletAddress.value?.toLocaleLowerCase() &&
          currentChainId.value === result.chainId
        ) {
          const tradeObject: any = result;
          //Cannot RPC maps
          tradeObject.locked = (function () {
            return new Map<string, boolean>([
              [tradeObject.locked[0].userId, tradeObject.locked[0].locked],
              [tradeObject.locked[1].userId, tradeObject.locked[1].locked],
            ]);
          })();
          tradeObject.commited = (function () {
            return new Map<string, boolean>([
              [tradeObject.commited[0].userId, tradeObject.commited[0].locked],
              [tradeObject.commited[1].userId, tradeObject.commited[1].locked],
            ]);
          })();
          const otherUser = (
            await Network.getUsersData(tradeObject.otherUser)
          )[0];
          tradeObject.otherName = otherUser.username;

          //Conversion for error checking -
          tradeObject.fromItems = await this.TradeItemsToInventoryTile(
            tradeObject.fromItems,
          );
          tradeObject.toItems = await this.TradeItemsToInventoryTile(
            tradeObject.toItems,
          );
          tradeObject.otherIcon = otherUser.metadata.activeCharacter.name;
          dispatchEvent(EnumGameEvents.GameUiTradeOpen, {
            key: tradeObject.key,
            object: tradeObject,
          });
          return;
        }
      }
    }
  }

  protected override onShutdown() {
    super.onShutdown();
    for (const eventHandler of this._eventHandlers) {
      eventHandler.destroy();
    }
    this._notificationHandlers.forEach((handler) => {
      NotificationCenter.instance.removeListener(handler);
    });
    delete this._notificationHandlers;
  }
}
