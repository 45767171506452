import { RouteRecordName, createRouter, createWebHistory } from "vue-router";
import { currentAccount } from "@shared/Global";
import Network from "@engine/networking/Network";

const Home = () => import("@overlay/pages/Main.vue");
const LoginPage = () => import("@overlay/pages/LoginPage.vue");
const SignupPage = () => import("@overlay/pages/SignupPage.vue");
const ForgotPasswordPage = () =>
  import("@overlay/pages/ForgotPasswordPage.vue");

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/login",
      name: "login",
      component: LoginPage,
    },
    {
      path: "/signup",
      name: "signup",
      component: SignupPage,
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: ForgotPasswordPage,
    },
  ],
});

const authenticationPageNames: Array<RouteRecordName> = [
  "login",
  "signup",
  "forgot-password",
];
router.beforeEach(async (to) => {
  //Redirect to login if not logged in
  try {
    const notifications = await (
      await fetch("https://static.bithotel.io/notifications.json")
    ).json();
    if (
      import.meta.env.VITE_IGNORE_NOTIFICATION_DISABLE !== "true" &&
      notifications.login.disable &&
      to.name != "login"
    ) {
      return {
        name: "login",
        query: { campaign: router.currentRoute.value.query["campaign"] },
      };
    }
  } catch (e) {
    /* empty */
  }
  if (
    !currentAccount.user?.id &&
    !authenticationPageNames.includes(to.name) &&
    !(await Network.refreshAuthenticationIfPossible())
  ) {
    return {
      name: "login",
      query: { campaign: router.currentRoute.value.query["campaign"] },
    };
  }

  //Redirect to home if logged in or authentication token successfully refreshed
  if (
    to.name !== "home" &&
    (currentAccount.user?.id ||
      (await Network.refreshAuthenticationIfPossible()))
  ) {
    return {
      name: "home",
      query: { campaign: router.currentRoute.value.query["campaign"] },
    };
  }
});

export default router;
