export const SeasonUtilities = {
  getPreviousWeekDateIdentifier(): string {
    const date = new Date();
    // Moving back in time 1 month
    date.setDate(date.getDate() - 7);

    return date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();
  },

  getCurrentWeekDateIdentifier(): string {
    const date = new Date();
    // Moving back in time 1 month
    date.setDate(date.getDate() - 7);

    return date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();
  },

  getCurrentSeasonIdentifier(): string {
    const date = new Date();
    return date.getFullYear() + "-" + date.getMonth();
  },
  getPreviousSeasonIdentifier(): string {
    const date = new Date();
    // Move to the first of the month
    date.setDate(1);
    // Moving back in time 1 month
    date.setMonth(date.getMonth() - 1);
    return date.getFullYear() + "-" + date.getMonth();
  },
};
