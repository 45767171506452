import { IDataObject } from "@engine/interfaces/IDataObject";
import Entity from "@engine/Entity";

export class Emote {
  constructor(
    protected _identifier: string,
    protected _executor: Entity,
    protected _data?: IDataObject,
  ) {}
}
