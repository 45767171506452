import { usableCharacterNames, validCharacterNames } from "./consts/Characters";

function escapeRegExp(string: string) {
  if (string) {
    string = string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }
  return string;
}

export function replaceAll(str: string, find: string, replace: string) {
  if (str) {
    str = str.replace(new RegExp(escapeRegExp(find), "g"), replace);
  }
  return str;
}

export function replacePairs(str: string, replaces: { [key: string]: string }) {
  if (str) {
    for (const pair of Object.entries(replaces)) {
      str = replaceAll(str, pair[0], pair[1]);
    }
  }
  return str;
}
/**
 * Formats NFT name in a consistent format for readability & easy asset handling
 * @param name to be formatted
 * @returns nft_name_in_snake_case
 */
export function formatNftName(name: string) {
  if (name) {
    name = replacePairs(name, {
      ".": "",
      "-": "_",
      " ": "_",
      "'": "",
    }).toLowerCase();
  }
  return name;
}
export function validateCharacterName(
  characterName: string,
  onlyUsable: boolean = false,
) {
  const list = onlyUsable
    ? usableCharacterNames
    : Object.keys(validCharacterNames);
  if (!list.includes(characterName)) {
    throw new Error(`Invalid character name "${characterName}"`);
  }
  return characterName;
}

export function chainIdToName(chainId: number): string {
  switch (chainId) {
    case 97:
      return "bsc testnet";
    case 56:
      return "bsc";
    default:
      return "";
  }
}

export function capitalizeFirstLetter(string: string) {
  if (string) {
    string = string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
}

export function toTitleCase(phrase: string) {
  if (phrase) {
    phrase = phrase
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  return phrase;
}

export function pathToFilename(path: string, withExtension: boolean = false) {
  if (!path.includes("/")) {
    return path;
  }
  const ret = path.split("/").pop();
  if (withExtension) {
    return ret;
  }
  return ret!.split(".")[0];
}

export const snooze = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

/**
 * DEBUG HELPERS
 */

export const envLists: {
  [envVarName: string]: {
    isInitialized: boolean;
    list: { [key: string]: any };
  };
} = {
  VITE_GAME_DEBUG_MODE: {
    isInitialized: false,
    list: {},
  },

  VITE_GAME_DEBUG_FLAGS: {
    isInitialized: false,
    list: {},
  },
};

export function getDebugMode(id: string): number {
  if (import.meta.env.MODE == "production") {
    return 0;
  }
  if (!envLists["VITE_GAME_DEBUG_MODE"].isInitialized) {
    const { VITE_GAME_DEBUG_MODE: _debugModes } = import.meta.env;
    initEnvList("VITE_GAME_DEBUG_MODE", _debugModes);
  }
  const defaultDebugMode = envLists["VITE_GAME_DEBUG_MODE"].list["default"];
  return envLists["VITE_GAME_DEBUG_MODE"].list[id] ?? defaultDebugMode ?? 0;
}
export function getDebugFlag(id: string) {
  if (import.meta.env.MODE == "production") {
    return "";
  }
  if (!envLists["VITE_GAME_DEBUG_FLAGS"].isInitialized) {
    const { VITE_GAME_DEBUG_FLAGS: _debugFlags } = import.meta.env;
    initEnvList("VITE_GAME_DEBUG_FLAGS", _debugFlags);
  }

  return envLists["VITE_GAME_DEBUG_FLAGS"].list[id] || "";
}

/**
 *
 * @param listId
 * @param rawList key:value pairs separated by |
 */
function initEnvList<T>(listId: string, rawList: string = "") {
  if (import.meta.env.MODE == "production") {
    return;
  }
  if (!envLists[listId]) {
    envLists[listId] = {
      isInitialized: false,
      list: {},
    };
  }
  if (rawList != "") {
    rawList.split("|").forEach((raw: string) => {
      const [key, value] = raw.split(":");
      const numberValue = parseInt(value);
      if (!isNaN(numberValue)) {
        envLists[listId].list[key] = numberValue;
      } else {
        envLists[listId].list[key] = value?.includes(",")
          ? value.split(",")
          : value;
      }
    });
  }
  envLists[listId].isInitialized = true;
}

export function formatDate(date: Date): string {
  let seconds: number | string = date.getUTCSeconds();
  let minutes: number | string = date.getUTCMinutes();
  if (minutes == 0) {
    minutes = "00";
  } else if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds == 0) {
    seconds = "00";
  } else if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return `${date.getUTCHours()} : ${minutes} : ${seconds}`;
}

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
