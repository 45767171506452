/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  TradinglibraryAbi,
  TradinglibraryAbiInterface,
} from "../TradinglibraryAbi";

const _abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenValue",
        type: "uint256",
      },
    ],
    name: "tokenSetConstructor",
    outputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "bytes32[]",
                name: "_values",
                type: "bytes32[]",
              },
            ],
            internalType: "struct TradingLibrary.ItemSet",
            name: "_inner",
            type: "tuple",
          },
        ],
        internalType: "struct TradingLibrary.TokenSet",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
] as const;

export class TradinglibraryAbi__factory {
  static readonly abi = _abi;
  static createInterface(): TradinglibraryAbiInterface {
    return new utils.Interface(_abi) as TradinglibraryAbiInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): TradinglibraryAbi {
    return new Contract(address, _abi, signerOrProvider) as TradinglibraryAbi;
  }
}
