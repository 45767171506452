import GameScene from "@game/engine/scenes/GameScene";
import {
  EnumApplicationEvents,
  EnumGameEvents,
  EnumUiEvents,
} from "@shared/enums";
import { dispatchEvent, handleEvent } from "@shared/Events";
import { TApplicationStartMinigame } from "@shared/types";
import { Game } from "phaser";
import Network from "@game/engine/networking/Network";
import DynamicLoadComponent from "@game/engine/components/scenecomponents/DynamicLoadComponent";
import { RoomData } from "@game/mini/room/CustomizableRoomScene";
import { isAllowedPlinkoEntry } from "./mini/plinko/PlinkoScene";
import { isAllowedMightyMinesEntry } from "./mini/mighty-mines/MightyMinesScene";
import { Toast } from "@engine/notifications/Toast";
import Matchmaker from "@engine/networking/Matchmaker";
import { useGameState } from "@overlay/store/gameStateStore";
import { MinigameValues } from "@common/constants/MinigameValues";

export default class ApplicationHandler {
  private _currentSceneKey: string;
  private readonly _sceneKeyToScene: { [key: string]: typeof GameScene };
  private _lastGameSetup: TApplicationStartMinigame;
  constructor(private _game: Game, scenes: (typeof GameScene)[]) {
    this._sceneKeyToScene = {};

    // Attach and load
    for (const sceneClass of scenes) {
      this._sceneKeyToScene[sceneClass.SceneKey] = sceneClass;
    }

    // Start off at the first scene that's displayed
    this._currentSceneKey = scenes[0].SceneKey;

    // Listen for possible new games
    handleEvent(
      EnumApplicationEvents.ApplicationStartMinigame,
      this.onStartMinigame,
    );
    handleEvent(EnumApplicationEvents.ApplicationRestartMinigame, () => {
      const moduleNames = Object.keys(MinigameValues);
      const module = moduleNames.find(
        (key) => MinigameValues[key].SceneKey === this._lastGameSetup.sceneKey,
      )!;
      const currency = this._lastGameSetup.params.matchData.currency;
      const playableAmount =
        this._lastGameSetup.params.matchData.playableAmount;

      dispatchEvent(EnumApplicationEvents.ApplicationStartMinigame, {
        sceneKey: "ArcadeScene",
        senderSceneKey: this._lastGameSetup.sceneKey,
        params: {
          matchQueue: {
            module: module,
            sceneKey: this._lastGameSetup.sceneKey,
            currency: currency,
            playableAmount: playableAmount,
          },
        },
      });
    });

    // TODO: connect play buttons to proper minigames
    window.addEventListener("minigame-tile-play", (event: CustomEvent) => {
      const allowedIn = isAllowedMinigameEntry(
        event.detail.key,
        event.detail.matchData,
      );
      if (!allowedIn.allowed) {
        return Toast.error(allowedIn.message);
      }
      return startMiniGame(event.detail.key, event.detail.matchData);
    });
    window.addEventListener("minigame-tile-currency", (event: CustomEvent) => {
      requestMiniGameSettings(event.detail);
    });
  }

  public onStartMinigame = async (event: TApplicationStartMinigame) => {
    const { sceneKey, senderSceneKey, isOverlayed, params } = event;
    // Sanity check
    if (!(sceneKey in this._sceneKeyToScene)) {
      console.error("Can't move to scene:\"" + sceneKey + '" does not exist');
      return;
    }
    // Abandon any current multiplayer queue tickets
    if (
      Object.keys(MinigameValues).find(
        (key) => MinigameValues[key].SceneKey === sceneKey,
      )
    ) {
      Matchmaker.instance.cancelAllTickets();
    }

    // MARKTWAIN Disconnect from the match here I guess? Change this later..
    await Network.leave();

    // Check if it loads on top
    const nextScene = this._sceneKeyToScene[sceneKey];
    if (isOverlayed) {
      this._game.scene.start(nextScene.SceneKey);
    } else {
      const { scene } = this._game;
      const fromScene = scene.getScene(this._currentSceneKey);
      const toScene = scene.getScene(nextScene.SceneKey);

      if (nextScene.SceneKey === "CustomizableRoomScene") {
        const roomData = await preloadRoomData(
          fromScene as GameScene,
          params.roomId,
          params.activate,
        );
        params.roomData = roomData;
      }

      const duration = 512;
      if (fromScene && toScene) {
        fromScene.cameras.main.fadeOut(duration, 0, 0, 0);
        fromScene.cameras.main.once(
          Phaser.Cameras.Scene2D.Events.FADE_OUT_COMPLETE,
          (camera, effect) => {
            scene.stop(fromScene).start(toScene, {
              origin: senderSceneKey,
              ...params,
            });
            toScene.events.once("create", async () => {
              toScene.cameras.main.fadeIn(duration, 0, 0, 0);

              if (params && params.matchQueue) {
                const gameState = useGameState();

                gameState.joinMinigameMatchQueue({
                  sceneKey: params.matchQueue.sceneKey,
                  matchData: {
                    currency: params.matchQueue.currency,
                    playableAmount: params.matchQueue.playableAmount,
                  },
                });
              }
            });
          },
        );
      }
    }
    this._currentSceneKey = nextScene.SceneKey;
    this._lastGameSetup = event;
  };
}

async function preloadRoomData(
  currentScene: GameScene,
  roomKey: string,
  activate?: boolean,
): Promise<RoomData> {
  const loader = new DynamicLoadComponent();
  loader.setScene(currentScene);

  if (activate) {
    await Network.rpc("roomActivate", {
      key: roomKey,
    });
  }

  const roomGetResponse = await Network.rpc("roomGet", {
    key: roomKey.toLocaleLowerCase(),
  });
  const roomData = JSON.parse(roomGetResponse.payload.roomData);
  if (roomData) {
    dispatchEvent(EnumGameEvents.GameUiRoomUpdateData, {
      id: roomKey,
      ...roomData,
    });
  }
  await loader.loadAssetList({
    json: [
      {
        key: roomData.map + "_tilemap_json",
        url:
          "/assets/game/mini/room/room_maps/" +
          roomData.map +
          "/" +
          roomData.map +
          ".tmj",
      },
    ],
  });

  return roomData;
}

export function startMiniGame(
  sceneKey: string,
  //@todo verify
  matchData: { [key: string]: string },
  senderSceneKey: string = GameScene.SceneKey,
) {
  dispatchEvent(EnumApplicationEvents.ApplicationStartMinigame, {
    sceneKey,
    senderSceneKey,
    //@todo verify
    params: { matchData: matchData },
  });
}
export async function requestMiniGameSettings(sceneKey: string) {
  const response = await Network.requestMinigameSettings(sceneKey);
  displayMiniGameSettings(response);
}
export async function displayMiniGameSettings(data: {
  [key: string]: { [key: string]: string };
}) {
  dispatchEvent(EnumUiEvents.UiOpenModal, "MinigamesModal");
  dispatchEvent(
    EnumApplicationEvents.ApplicationUiRetrievedMinigameSettings,
    data,
  );
}

function isAllowedMinigameEntry(
  sceneKey: string,
  data: { [key: string]: string },
): { allowed: boolean; message?: string } {
  if (minigameEntry[sceneKey] !== undefined) {
    return minigameEntry[sceneKey](data);
  }
  return { allowed: true };
}

const minigameEntry = {
  PlinkoScene: isAllowedPlinkoEntry,
  MightyMinesScene: isAllowedMightyMinesEntry,
};
