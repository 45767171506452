import Container = Phaser.GameObjects.Container;
import Rectangle = Phaser.GameObjects.Rectangle;
import Text = Phaser.GameObjects.Text;
import RESIZE = Phaser.Scale.Events.RESIZE;
import Size = Phaser.Structs.Size;

export default class NotificationBanner extends Container {
  public static EventTypes = {
    Completed: "notificationbannercompleted",
  };
  public static FontStyleMessage = {
    align: "center",
    color: "#ffffff",
    fontFamily: "Depixel",
    fontSize: "32px",
  };
  private static FontStyleSubject = {
    align: "center",
    color: "#ffffff",
    fontFamily: "Depixel",
    fontSize: "24px",
  };
  private readonly _background: Rectangle;
  private _messageMovementTween = null;
  private readonly _messageText: Text;
  private readonly _subjectText: Text;

  constructor(scene, x, y) {
    super(scene, x, y);

    this._background = new Rectangle(
      scene,
      0,
      0,
      scene.scale.width,
      64 + 32 + 16,
      0x000000,
    )
      .setAlpha(0.64)
      .setOrigin(0.5, 0.5)
      .setScale(1, 0)
      .setScrollFactor(0);
    this.add(this._background);

    const boundingBox = scene.physics.add
      .existing(this._background)
      .setInteractive({
        useHandCursor: true,
      });

    boundingBox.on("pointerdown", (pointer, localX, localY, event) => {
      // Stop the click from bubbling
      event.stopPropagation();

      this.hide();
    });

    this._subjectText = new Text(
      scene,
      0,
      -24,
      "title",
      NotificationBanner.FontStyleSubject,
    )
      .setAlpha(0)
      .setOrigin(0.5)
      .setScrollFactor(0);
    this.add(this._subjectText);

    this._messageText = new Text(
      scene,
      0,
      0,
      "message",
      NotificationBanner.FontStyleMessage,
    ).setScrollFactor(0);
    this.add(this._messageText);

    this.setDepth(999).setScrollFactor(0);

    scene.scale.on(RESIZE, (gameSize: Size) => {
      const width = gameSize.width;

      this.x = width / 2;
      // Reset the backgrounds' width and origin
      this._background.width = width;
      this._background.setOrigin(0.5, 0.5);

      if (this._messageMovementTween) {
        this._messageMovementTween.data[0].start = this.x;
        // // Update the target too
        this._messageMovementTween.updateTo(
          "x",
          -this.x - this._messageText.displayWidth,
        );
      }
    });
  }

  public display(subject: string, message: string, repeatAmount: number = 1) {
    this._messageText.text = message;
    this._subjectText.text = subject;

    this._messageText.x = this.x;
    this._background.setScale(1, 0);
    const tweens = this.scene.tweens;

    // First display the banner
    tweens.add({
      duration: 512,
      ease: "Cubic.easeOut",
      props: {
        scaleY: 1,
      },
      targets: this._background,

      onComplete: () => {
        // Fade the text in
        tweens.add({
          duration: 256,
          ease: "Cubic.easeIn",
          props: {
            alpha: 1,
          },
          targets: [this._messageText, this._subjectText],
          onComplete: () => {
            // Move the text from right to left
            this._messageMovementTween = tweens.add({
              duration: 8192,
              targets: this._messageText,
              onComplete: () => {
                this._messageMovementTween = null;
                this.hide();
              },
              props: {
                x: {
                  start: this.x,
                  to: -this.x - this._messageText.displayWidth,
                },
              },
              repeat: repeatAmount,
            });
          },
        });
      },
    });
  }

  public hide = () => {
    const tweens = this.scene.tweens;
    tweens.add({
      duration: 256,
      ease: "Cubic.easeOut",
      props: {
        alpha: 0,
      },
      targets: [this._messageText, this._subjectText],
      onComplete: () => {
        if (this._messageMovementTween) {
          this._messageMovementTween.stop();
        }

        tweens.add({
          delay: 128,
          duration: 512,
          ease: "Cubic.easeOut",
          onComplete: () => {
            this.emit(NotificationBanner.EventTypes.Completed, this);
            this.destroy(true);
          },
          props: {
            scaleY: 0,
          },
          targets: [this._background],
        });
      },
    });
  };
}
