import { EnumAllEvents, EnumGameEvents, EnumUiEvents } from "./enums";
import { TGameUiNpcInteraction } from "./types/EventDataTypes";
import { dispatchEvent } from "./Events";
import { NFTHelpers } from "./nft";

const interactionMap: { [key: string]: Partial<TGameUiNpcInteraction> } = {
  bartender: {
    name: "Bartender",
    image: "icons/beer.png",
    description:
      "Welcome guest, do you want to buy a drink? it'll cost 10 tickets",
    buttonAccept: {
      text: "Buy",
    },
    buttonReject: {
      text: "Leave",
    },
  },
  "open-onboarding": {
    action: () => {
      dispatchEvent(EnumUiEvents.UiOpenModal, "OnboardingModal");
    },
  },
  "open-shop": {
    action: () => {
      dispatchEvent(EnumUiEvents.UiOpenModal, "StoreModal");
    },
  },
  "open-wallet-instructions": {
    action: () => {
      dispatchEvent(EnumGameEvents.GameUiNpcInteraction, {
        name: "What's a Wallet?",
        class: "top-up",
        description:
          "We couldn't find a web3 wallet installed, would you like to read an article on how to get started?",
        // imageUrl: getMisc("elon_on_rocket"),
        buttonAccept: {
          text: "Open article",
          closeOnClick: true,
          class: "",
          icon: "",
          url: "https://medium.com/@bithotelnftgame/guide-how-to-get-started-in-bit-hotel-695bbbfe6d74",
        },
        buttonReject: {
          text: "Maybe later",
          closeOnClick: true,
          class: "",
          icon: "",
        },
      });
    },
  },
};
function mapEmit(str?: string): Partial<TGameUiNpcInteraction> | undefined {
  if (str?.indexOf("emit:") === 0) {
    const event = str.slice(5);
    return {
      action: () => dispatchEvent(event as EnumAllEvents),
    };
  } else {
    return undefined;
  }
}
const GAME_UI_NPC_INTERACTION = (data: TGameUiNpcInteraction) => {
  const _name = typeof data.name == "string" ? data.name : data.name.text;
  const mapped =
    mapEmit(data.do) || interactionMap[data.do] || interactionMap[_name];
  if (!mapped) {
    return data;
  }
  if (mapped.buttonAccept) {
    if (!mapped.buttonAccept?.callback) {
      mapped.buttonAccept.callback = () => {
        dispatchEvent(EnumUiEvents.UiGameNpcInteractionAccept);
      };
    }
  }
  if (!mapped.description) {
    try {
      mapped.description = NFTHelpers.NFT.fromName(_name).description;
    } catch (e) {
      /* empty */
    }
  }

  if (mapped.buttonReject) {
    if (!mapped.buttonReject.callback) {
      mapped.buttonReject.callback = () => {
        dispatchEvent(EnumUiEvents.UiGameNpcInteractionReject);
      };
    }
  }
  return mapped;
};

export const eventDataModifiers: Object & {
  [key: string]: (data: any) => any;
} = { GAME_UI_NPC_INTERACTION };
