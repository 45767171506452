import { TUserDeviceType } from "@overlay/types";
import { getDeviceType } from "@shared/IsMobile";
import { defineStore } from "pinia";
import { TLayoutIds } from "./managers/components";
import { mobileLandscape, mobilePortrait } from "@/mobile/postMessage";
import { OrientationLock } from "@/mobile/types";

export const useGeneralStore = defineStore("uiGeneralData", {
  state: () => ({
    // activeMinigame: {} as TMinigame,
    activeLayout: "DefaultV2Overlay" as TLayoutIds,
    deviceType: getDeviceType() as TUserDeviceType,
    rotation: OrientationLock.LANDSCAPE,
  }),
  getters: {
    isTouchDevice(state): boolean {
      return state.deviceType === "mobile" || state.deviceType === "tablet";
    },
  },
  actions: {
    mobileToggleRotation() {
      if (this.rotation === OrientationLock.PORTRAIT_UP) {
        mobileLandscape();
        this.rotation = OrientationLock.LANDSCAPE;
      } else {
        mobilePortrait();
        this.rotation = OrientationLock.PORTRAIT_UP;
      }
    },
  },
});
