import { createApp } from "vue";
import App from "./App.vue";
import "vue-toastification/dist/index.css";
import { installPlugins } from "./overlay/plugins";
import RegisterGlobalComponents from "./overlay/plugins/globalComponents";
import { ResourcesHelper } from "@engine/utilities/AssetLoaderHelper";
import { useStores } from "@store";

ResourcesHelper.checkAllAssetsAsync();

const app = createApp(App);
app.config.performance = process.env.NODE_ENV === "development";
//Make the global window object accessible in the vue template
app.config.globalProperties.window = window;

installPlugins(app).finally();
RegisterGlobalComponents(app);
const store = useStores();
app.config.globalProperties.$modals = store.modal;
app.config.globalProperties.$store = store;
// const customDirectives = [{ name: "depixel", classList: ["font", "depixel"] }];
app.directive("depixel", (el: HTMLElement) => {
  el.classList.add("font", "depixel");
});
app.directive("depixel-normal", (el: HTMLElement) => {
  el.classList.add("font", "depixel", "normal");
});
app.directive("depixel-bold", (el: HTMLElement) => {
  el.classList.add("font", "depixel", "bold");
});
app.directive("depixel-outlined", (el: HTMLElement) => {
  el.classList.add(
    "font",
    "depixel",
    store.general.deviceType == "mobile" ? "normal" : "bold",
    "outlined",
    "shadow",
    "color--white",
  );
});
app.mount("#app");
