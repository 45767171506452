import VueGtagPlugin from "vue-gtag";
import VueToastificationPlugin from "vue-toastification";
import router from "../router";
import { createPinia } from "pinia";

import i18n from "./i18n";

type TPlugin = { plugin: any; options: any; additional: Array<any> };
export default [
  router,
  {
    plugin: VueGtagPlugin,
    options: {
      appName: "Bithotel Leon",
      pageTrackerScreenviewEnabled: true,
      config: {
        id: "UA-212200440-1",
      },
    },
    additional: [router],
  },
  {
    plugin: VueToastificationPlugin,
    options: {
      position: "bottom-right",
    },
  },
  { plugin: i18n },
  { plugin: createPinia() },
] as Array<TPlugin | any>;
