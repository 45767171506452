import { walletAssets } from "@overlay/modules/chain/wallet";
import { currentAccount } from "@shared/Global";
import { currentPlayerLevel } from "@uicomps/common/currents";
// import { TNakamaAccount } from "@shared/types/nakama";
import { defineStore } from "pinia";
import { levelBar } from "../components/topbar/data";

export const useUserStore = defineStore("userData", {
  state: () => ({
    // currentAccount: {} as TNakamaAccount,
  }),
  getters: {
    currentAccount() {
      return currentAccount;
    },
    wallet() {
      return walletAssets.value;
    },
    level() {
      return { ...currentPlayerLevel, bar: levelBar(), getLevelBar: levelBar };
    },
    // finishedTodos(state) {

    //   // autocompletion! ✨
    //   return state.todos.filter((todo) => todo.isFinished);
    // },
    // unfinishedTodos(state) {
    //   return state.todos.filter((todo) => !todo.isFinished);
    // },
    // /**
    //  * @returns {{ text: string, id: number, isFinished: boolean }[]}
    //  */
    // filteredTodos(state) {
    //   if (this.filter === "finished") {
    //     // call other getters with autocompletion ✨
    //     return this.finishedTodos;
    //   } else if (this.filter === "unfinished") {
    //     return this.unfinishedTodos;
    //   }
    //   return this.todos;
    // },
  },
  actions: {
    // any amount of arguments, return a promise or not
    // addTodo(text) {
    //   // you can directly mutate the state
    //   this.todos.push({ text, id: this.nextId++, isFinished: false });
    // },
  },
});
