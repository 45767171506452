import { OrderableType } from "@game/engine/enums/OrderableType";
import OrderableIsometricGameScene from "@game/engine/scenes/OrderableIsometricGameScene";
import IIsometricOrderable from "@game/engine/interfaces/IIsometricOrderable";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import Sprite = Phaser.GameObjects.Sprite;
import Vector2 = Phaser.Math.Vector2;
import { isMobile } from "web3modal";

export class OrderableSelectorHighlight
  extends Sprite
  implements IIsometricOrderable
{
  public orderableType: OrderableType = OrderableType.Static;

  constructor(
    scene: OrderableIsometricGameScene,
    x,
    y,
    public tilePosition: TilePosition3D,
  ) {
    super(scene, x, y, "selector_spritesheet", 1);
    this.setOrigin(0.5, 0.75);

    // Add ourselves as an orderable so while we exist we're in the correct spot
    scene.addOrderable(this);

    // Fade it out then delete it after a bit
    scene.tweens.add({
      targets: this,
      alpha: 0,
      ease: "CubicIn",
      duration: 1024,
      onComplete: () => {
        scene.removeOrderable(this);
        this.destroy();
      },
    });
  }
}

export default class OrderableSelector
  extends Sprite
  implements IIsometricOrderable
{
  public orderableType: OrderableType = OrderableType.Movable;

  public get tilePosition() {
    return this._tilePosition;
  }

  public set tilePosition(value) {
    this._tilePosition = value;
    const worldPosition: Vector2 = this.scene?.worldPositionAtTilePosition(
      this._tilePosition,
    );
    if (worldPosition) {
      this.x = worldPosition.x;
      this.y = worldPosition.y;
    }
  }

  public declare scene: OrderableIsometricGameScene;

  private _tilePosition: TilePosition3D;

  constructor(scene: OrderableIsometricGameScene) {
    super(scene, 0, 0, "selector_spritesheet", 0);
    this.setOrigin(0.5, 0.75);

    if (!isMobile()) {
      // Hook into clicking
      scene.input.on("pointerdown", (pointer) => {
        if (!pointer.isDown) return;
        scene?.add.existing(
          new OrderableSelectorHighlight(
            scene,
            this.x,
            this.y,
            this._tilePosition,
          ),
        );
      });
    }
  }

  public activate() {
    this.setVisible(true);
    if (isMobile()) {
      this.scene?.add.existing(
        new OrderableSelectorHighlight(
          this.scene,
          this.x,
          this.y,
          this._tilePosition,
        ),
      );
    }
  }

  public deactivate() {
    this.setVisible(false);
  }
}
