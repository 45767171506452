export default class MineFieldTile extends Phaser.GameObjects.Sprite {
  public tile: Phaser.Tilemaps.Tile;
  public gridX: number;
  public gridY: number;

  public flipped: boolean;

  constructor(
    scene: Phaser.Scene,
    tileData: Phaser.Tilemaps.Tile,
    x: number,
    y: number,
    gridX: number,
    gridY: number,
  ) {
    super(scene, x, y, "tile_neutral");

    this.anims.create({
      key: "explode",
      frameRate: 10,
      frames: this.anims.generateFrameNumbers("tile_mine_animation", {
        start: 0,
        end: 4,
      }),
      repeat: 0,
    });

    this.anims.create({
      key: "reward",
      frameRate: 10,
      frames: this.anims.generateFrameNumbers("tile_reward_animation", {
        start: 0,
        end: 4,
      }),
      repeat: 0,
    });

    this.tile = tileData;

    //this.setDepth(900);

    this.setOrigin(0, 0);

    this.gridX = gridX;
    this.gridY = gridY;
  }

  flip(isMine: boolean) {
    if (!this.flipped) {
      if (isMine) {
        this.setTexture("tile_mine_animation");
        this.anims.play("explode");
      } else {
        this.setTexture("tile_reward_animation");
        this.anims.play("reward");
      }
      this.flipped = true;
    }
  }

  onHover() {
    if (!this.flipped) {
      this.tint = 120000;
    }
  }

  hoverReset() {
    if (!this.flipped) {
      this.tint = 0xffffff;
    }
  }

  reset() {
    this.setTexture("tile_neutral");
    this.tint = 0xffffff;
    this.flipped = false;
  }

  resetTint() {
    this.tint = 0xffffff;
  }
}
