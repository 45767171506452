import FurnitureObject from "@game/engine/components/scenecomponents/furniture/furniture-objects/FurnitureObject";
import GameScene from "@game/engine/scenes/GameScene";
import { InteractiveFurnitureConfigs } from "@common/modules/rooms/InteractiveFurnitureConfig";

import FODice from "@game/engine/components/scenecomponents/furniture/furniture-objects/FODice";
import FOTraditionalDice from "@game/engine/components/scenecomponents/furniture/furniture-objects/FOTraditionalDice";
import FOWoodenDivider from "@engine/components/scenecomponents/furniture/furniture-objects/FOWoodenDivider";
import FOArcadeMachine from "@engine/components/scenecomponents/furniture/furniture-objects/FOArcadeMachine";
import PublicSpaceGameScene from "@engine/scenes/PublicSpaceGameScene";

const InteractiveFurnitureMappings: {
  [key: string]: any;
} = {
  "0x7A68265e94353D0F18E4563a21f84E7325293914": FODice,
  "0x6bA0d2514aE9f02cf090b001d6D1460e7a5f8bf5": FOTraditionalDice,
  "0x95575D7118B7A14DfBFDdC9dac677Ab4795a7360": FOWoodenDivider,
  "0xD8F20e1730009aC75EFeCE5681E6a9e8aBede7Cb": FOArcadeMachine,
  "local-fruity-memory-arcade-machine": FOArcadeMachine,
  "local-cryptobomber-arcade-machine": FOArcadeMachine,
  "local-plinko-arcade-machine": FOArcadeMachine,
  "local-stampwars-arcade-machine": FOArcadeMachine,
};

export default class FurnitureFactory {
  public static createFurniture(
    scene: GameScene,
    name: string,
    id: string,
  ): FurnitureObject {
    const address = id.split("_")[0];
    if (InteractiveFurnitureMappings.hasOwnProperty(address)) {
      return new InteractiveFurnitureMappings[address](
        scene,
        name,
        id,
        InteractiveFurnitureConfigs[address],
      );
    }

    return new FurnitureObject(scene as PublicSpaceGameScene, name, id);
  }
}
