import { MatchmakerMatched, Socket } from "@heroiclabs/nakama-js";
import { EnumApplicationEvents, EnumUiEvents } from "@shared/enums";
import { dispatchEvent } from "@shared/Events";
import GameScene from "@engine/scenes/GameScene";
import NotificationCenter from "@engine/networking/NotificationCenter";
import { Toast } from "@engine/notifications/Toast";

export const MODULE_TO_MINIGAME_NAMES = {
  cryptobomberv2: "Crypto Bomber",
  memory: "Fruity Memory",
  stampwars: "Stamp Wars",
};

const MIN_PLAYERS_REDUCTION_TIME = 10000;

type TQueuedTicket = {
  id: string;
  matchData: { currency: string; playableAmount: number };
  minPlayers: number;
  module: string;
  pending: boolean;
  sceneKey: string;
};

export default class Matchmaker extends Phaser.Events.EventEmitter {
  public static get instance(): Matchmaker {
    if (!this._instance) {
      this._instance = new Matchmaker();
    }
    return this._instance;
  }

  public get queuedModule(): string | undefined {
    if (this._tickets.length > 0) {
      return this._tickets[0].module;
    }

    return undefined;
  }

  private _socket: Socket;
  private _tickets: TQueuedTicket[];

  private static _instance: Matchmaker;

  constructor() {
    super();

    this._tickets = [];
  }

  public cancelAllTickets() {
    this._tickets.forEach((ticket) => this.cancelMatchTicket(ticket.id));
  }

  public cancelMatchTicket(id: string) {
    const index = this._tickets.findIndex((ticket) => ticket.id === id);

    if (index > -1) {
      const ticket = this._tickets[index];

      this._tickets.splice(index, 1);

      if (ticket.pending) {
        this._socket.removeMatchmaker(ticket.id).catch((e) => console.log(e));
      }
    }

    window.setTimeout(() => {
      if (this._tickets.length === 0) {
        dispatchEvent(EnumUiEvents.UIMatchmakingCancelled);
      }
    }, 50);
  }

  public initialize(socket: Socket) {
    this._socket = socket;

    this._socket.onmatchmakermatched = this.onMatchMakerMatched.bind(this);
  }

  public async queueForMatch(
    module: string,
    sceneKey: string,
    matchData: { currency: string; playableAmount: number },
    minPlayers: number = 4,
    notify: boolean = true,
  ) {
    // The Matchmaker is prepared to handle multiple tickets, but for now, you can just queue for a single minigame
    this.cancelAllTickets();

    const ticket = await this._socket.addMatchmaker(
      `+properties.module:${module} +properties.currency:${matchData.currency} +properties.playableAmount:${matchData.playableAmount}`,
      minPlayers,
      4,
      { module: module, currency: matchData.currency },
      { playableAmount: matchData.playableAmount },
    );

    this._tickets.push({
      id: ticket.ticket,
      matchData: matchData,
      minPlayers: minPlayers,
      module: module,
      pending: true,
      sceneKey: sceneKey,
    });

    // Try to find a match with the maximum number of players, after a certain time
    // reduce the requirements so a lower amount of players match can be found.
    if (minPlayers > 2) {
      setTimeout(
        () => this.reduceTicketMinPlayers(ticket.ticket),
        MIN_PLAYERS_REDUCTION_TIME,
      );
    }

    dispatchEvent(EnumUiEvents.UIMatchmakingStarted, { minigame: module });

    if (notify) {
      Toast.info(
        `Waiting in queue for more players to play ${MODULE_TO_MINIGAME_NAMES[module]}`,
      );
    }
  }

  private onMatchMakerMatched(matched: MatchmakerMatched) {
    const ticket = this._tickets.find((ticket) => ticket.id === matched.ticket);

    ticket.pending = false;

    this.cancelAllTickets();

    NotificationCenter.instance.displaySystemWideNotification(
      `Match ready!`,
      `Match found for ${
        MODULE_TO_MINIGAME_NAMES[ticket.module]
      }. Entering minigame now.`,
      0,
    );

    setTimeout(() => {
      dispatchEvent(EnumApplicationEvents.ApplicationStartMinigame, {
        sceneKey: ticket.sceneKey,
        senderSceneKey: GameScene.SceneKey,
        params: {
          matchData: { ...ticket.matchData, matchId: matched.match_id },
        },
      });
    }, 5000);
  }

  private reduceTicketMinPlayers(ticketId: string) {
    const ticket = this._tickets.find((ticket) => ticket.id === ticketId);

    if (ticket && ticket.minPlayers > 2) {
      this.queueForMatch(
        ticket.module,
        ticket.sceneKey,
        ticket.matchData,
        ticket.minPlayers - 1,
        false,
      ).finally();
    }
  }
}
