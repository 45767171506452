import Entity from "@engine/Entity";
import PublicSpaceGameScene from "@engine/scenes/PublicSpaceGameScene";

import Container = Phaser.GameObjects.Container;
import Image = Phaser.GameObjects.Image;
import TextStyle = Phaser.Types.GameObjects.Text.TextStyle;
import Vector2 = Phaser.Math.Vector2;

export const PADDING: number = 28;
export const BUBBLE_CORNER_SIZE: number = 11;
export const CHAT_MESSAGE_DEPTH = 1000;
export const ANIMATION_DURATION: number = 512;
export const FONT_SIZE: number = 14;
export const TEXT_VISIBLE_DURATION: number = 4096;
export const LINE_HEIGHT: number = 50;
export const LINE_MAX_WIDTH: number = 350;

export enum ChatTypes {
  Public = "Public",
  DM = "DirectMessage",
}

class ChatContainer extends Container {
  public onComplete: (ChatMessage) => void;

  protected _owner: Entity;
  protected _offset: Vector2;
  protected _height: number = LINE_HEIGHT;
  protected _textVisibleDuration: number = TEXT_VISIBLE_DURATION;
  protected _animationDuration: number = ANIMATION_DURATION;
  protected _arrow: Image;
  protected _bubble: Phaser.GameObjects.RenderTexture;
  protected _text: Phaser.GameObjects.Text;
  protected _head: Image;

  protected updatePosition() {
    this.setPosition(
      this._owner.x + this._offset.x,
      this._owner.y + this._offset.y,
    );
  }

  public padding: number = PADDING;
  public get height(): number {
    return this._height;
  }

  public render(): boolean {
    if (!this._owner.scene) return false;
    this._owner.scene.add.existing(this);
    return true;
  }

  public destroy(fromScene?: boolean) {
    if (!this._owner.scene || !this._owner.scene.children.getByName(this.name))
      return;
    this._owner.scene.events.off("update", this.updatePosition, this);
    super.destroy(fromScene);
  }
}

export class ChatMessage extends ChatContainer {
  constructor(
    owner: Entity,
    data: { message: string; remote: boolean },
    headTexture: string,
    textStyle: TextStyle,
    protected _height: number = LINE_HEIGHT,
    protected _textVisibleDuration: number = TEXT_VISIBLE_DURATION,
    protected _animationDuration: number = ANIMATION_DURATION,
    offset: Vector2,
  ) {
    super(owner.scene);

    this._owner = owner;
    this._offset = new Vector2(offset.x, offset.y);
    if (!data.remote) {
      data.message = ": " + data.message;
    }
    this._text = this.scene.add
      .text(0, 0, data.message, textStyle)
      .setOrigin(0.5, 0.5);
    this._height = Math.max(this._text.height + this.padding, LINE_HEIGHT);

    this._head = this.scene.add
      .image(0, 0, headTexture)
      .setScale(0.5, 0.5)
      .setOrigin(0.5, 0.5);

    //@ts-ignore
    this._bubble = this.scene.add
      .nineslice(
        0,
        0,
        this._text.width + this.padding + this._head.width,
        this._height,
        {
          key: "common_atlas",
          frame: "chatbox_9s",
        },
        BUBBLE_CORNER_SIZE,
      )
      .setOrigin(0.5, 0.5);

    this._head.setX(-this._bubble.width / 2 + 23);
    this._head.setY(
      -this._bubble.height / 2 +
        this._head.height * this._head.scaleY * 0.5 +
        this.padding / 2 -
        3.5,
    );
    this._arrow = this.scene.add
      .image(0, this._bubble.height / 2 - 1, "common_atlas", "chatbox_arrow")
      .setOrigin(0.5, 0);

    this.setDepth(CHAT_MESSAGE_DEPTH);

    this.scene.events.on("update", this.updatePosition, this);

    this.add(this._bubble);
    this.add(this._text);
    this.add(this._head);
    this.add(this._arrow);
  }

  public tween(): void {
    this.scene.tweens.add({
      delay: this._textVisibleDuration,
      duration: this._animationDuration,
      targets: this,
      alpha: 0,
      onComplete: () => {
        // Let others know we're done
        this.onComplete(this);

        // Delete ourselves
        this.destroy();
      },
    });
  }

  public hideArrow() {
    this._arrow.visible = false;
  }

  public moveUpwards(delay: number = 0, distance: number = LINE_HEIGHT): void {
    this.scene.tweens.add({
      delay: delay,
      duration: this._animationDuration,
      ease: "Cubic.easeOut",
      targets: this._offset,
      y: this._offset.y - distance,
    });
  }
}

export class ChatTypingBubble extends ChatContainer {
  public visualize: boolean = false;
  private _animation: Phaser.GameObjects.Sprite;

  forceShow() {
    this.visualize = true;
    this.visible = true;
  }
  forceHide() {
    this.visualize = false;
    this.visible = false;
  }
  show() {
    this.visualize = true;
  }
  hide() {
    this.visualize = false;
  }
  constructor(
    owner: Entity,
    protected headTexture: string,
    protected textStyle: TextStyle,
    offset: Vector2,
    protected _height: number = LINE_HEIGHT,
    protected _textVisibleDuration: number = TEXT_VISIBLE_DURATION,
    protected _animationDuration: number = ANIMATION_DURATION,
  ) {
    super(owner.scene);

    this._owner = owner;
    this._offset = new Vector2(offset.x, offset.y);

    if (this._owner.scene.textures.exists("typing")) {
      this.loadAnimation(
        this._owner.scene as PublicSpaceGameScene,
        headTexture,
        textStyle,
      );
    } else {
      (this._owner.scene as PublicSpaceGameScene).dynamicLoadComponent
        .loadAssetList({
          atlas: [
            {
              key: "typing",
              textureURL: "/assets/game/typing.png",
              atlasURL: "/assets/game/typing.json",
            },
          ],
        })
        .then(() => {
          this._owner.scene.anims.create({
            key: "typing_bubble_animation",
            frames: this._owner.scene.anims.generateFrameNames("typing", {
              start: 0,
              end: 11,
              zeroPad: 0,
            }),
            repeat: -1,
          });

          this.loadAnimation(
            this._owner.scene as PublicSpaceGameScene,
            headTexture,
            textStyle,
          );
        });
    }
  }

  private loadAnimation(
    scene: PublicSpaceGameScene,
    headTexture: string,
    textStyle: TextStyle,
  ) {
    this._text = this.scene.add.text(0, 0, ": ", textStyle).setOrigin(0.5, 0.5);

    this._animation = scene.add
      .sprite(0, 0, "typing")
      .setOrigin(0.5, 0.5)
      .setScale(0.5, 0.5);
    this._animation.anims.play("typing_bubble_animation");

    this._height = Math.max(
      this._animation.height * 0.5 + this.padding,
      LINE_HEIGHT,
    );

    this._head = this.scene.add
      .image(0, 0, headTexture)
      .setScale(0.5, 0.5)
      .setOrigin(0.5, 0.5);

    //@ts-ignore
    this._bubble = this.scene.add
      .nineslice(
        0,
        0,
        this._text.width +
          this._animation.width * 0.5 +
          this.padding +
          this._head.width,
        this._height,
        {
          key: "common_atlas",
          frame: "chatbox_9s",
        },
        BUBBLE_CORNER_SIZE,
      )
      .setOrigin(0.5, 0.5);

    this._head.setX(-this._bubble.width / 2 + 23);
    this._head.setY(-3.5);
    this._text.setX(this._head.x + this._head.width * 0.5 - 4);
    this._animation.setX(this._text.x + this._animation.width * 0.5);
    this._arrow = this.scene.add
      .image(0, this._bubble.height / 2 - 1, "common_atlas", "chatbox_arrow")
      .setOrigin(0.5, 0);

    this.setDepth(CHAT_MESSAGE_DEPTH);

    this.scene.events.on("update", this.updatePosition, this);
    this.add(this._bubble);
    this.add(this._head);
    this.add(this._arrow);
    this.add(this._text);
    this.add(this._animation);
    this.render();
  }

  destroy(fromScene?: boolean) {
    // (Mario) Workaround. Prevents this._animation from crashing the game when being removed by the scene directly.
    this._animation.destroy(false);

    super.destroy(fromScene);
  }
}
