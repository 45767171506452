import GameScene from "@game/engine/scenes/GameScene";
import EventEmitter = Phaser.Events.EventEmitter;

export default class SceneComponent extends EventEmitter {
  public isPreTickEnabled: boolean = false;
  public isTickEnabled: boolean = false;

  protected _scene: GameScene;

  public get scene(): GameScene {
    return this._scene;
  }

  public set scene(scene: GameScene) {
    this._scene = scene;

    this.onSceneSet(scene);
  }

  public get isAttachedToScene(): boolean {
    return !(this._scene === undefined || this._scene === null);
  }

  public create() {}

  public getScene(): GameScene {
    return this._scene;
  }

  public onCreated() {}

  public preTick(
    deltaSeconds: number,
    deltaTime: number,
    time?: number,
  ): void {}

  public preload() {}

  public setScene(scene: GameScene) {
    this._scene = scene;

    this.onSceneSet(scene);
  }

  public shutdown() {
    this.onShutdown();
  }

  public tick(deltaSeconds: number, deltaTime: number, time?: number): void {}

  protected onSceneSet(scene?: GameScene): void {
    // Override this in case you needed the scene for something
  }

  protected onShutdown() {}

  protected removeFromScene() {
    if (this._scene) {
      this._scene.removeComponent(this);
    }
  }
}
