import { MarketplaceAbi, MarketplaceAbi__factory } from "../typechain";
import { SignerOrProvider } from "../evm";
import { ERC721MarketplaceUpgradeable } from "../typechain/MarketplaceAbi";
import { toRaw } from "vue";
import { Marketplace } from ".";
import { BaseEvm } from "../base/evm";

export class EVMMarketplace
  extends BaseEvm<MarketplaceAbi>
  implements Marketplace
{
  constructor(_signerOrProvider: SignerOrProvider) {
    const mpAddress = import.meta.env.VITE_CONTRACT_MARKETPLACE_ADDRESS;
    const contract = MarketplaceAbi__factory.connect(
      mpAddress,
      toRaw(_signerOrProvider.value),
    );
    super(contract);
  }

  async getListing(
    id: number,
  ): Promise<ERC721MarketplaceUpgradeable.ERC721ListingStructOutput> {
    return await this._contract.getERC721Listing(id);
  }

  async addListing(
    nft: string,
    tokenId: number,
    price: number,
  ): Promise<string> {
    this._ethersUtils.checkAddresses([nft]);
    if (!this.getSigner()._isSigner) {
      throw new Error(`No signer available`);
    }
    const priceInWei = this._ethersUtils.toWei(price);
    const tx = await this._contract.addERC721Listing(nft, tokenId, priceInWei);
    await tx.wait(1);
    return tx.hash;
  }

  async cancelListing(listingId: number): Promise<string> {
    const tx = await this._contract.cancelERC721Listing(listingId);
    await tx.wait(1);
    return tx.hash;
  }

  async executeListing(listingId: number): Promise<string> {
    const tx = await this._contract.executeERC721Listing(listingId);
    await tx.wait(1);
    return tx.hash;
  }

  async getListingIdByToken(
    nft: string,
    tokenId: number,
    wallet: string,
  ): Promise<number> {
    this._ethersUtils.checkAddresses([nft, wallet]);
    return +(await this._contract.getErc721TokenToListingId(
      nft,
      tokenId,
      wallet,
    ));
  }

  get contractAddress(): string {
    return this._contract.address;
  }
}
