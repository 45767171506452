import { MinigameValues } from "@common/constants/MinigameValues";
import Matchmaker from "@engine/networking/Matchmaker";
import GameScene from "@engine/scenes/GameScene";
import { getIcon } from "@overlay/assets";
import { EnumApplicationEvents } from "@shared/enums";
import { dispatchEvent } from "@shared/Events";
import { NFTHelpers } from "@shared/nft";
import {
  TMinigame,
  TMinigamePlayer,
  TMinigamePlayerFields,
} from "@shared/types";
import { defineStore } from "pinia";
import { useUserStore } from "./userStore";
import { event } from "vue-gtag";
import { GameCurrencies } from "@common/enums/GameCurrencies";
import { mobilePortrait } from "@/mobile/postMessage";

const TICKET_REWARD_BONUSES: { [key: string]: number } = {
  None: 0,
  Common: 0.2,
  Uncommon: 0.4,
  Rare: 0.6,
  "Super rare": 0.8,
  Epic: 1,
  Legendary: 3,
};

const earnings: number[] = [
  3.5, 3.5, 1.75, 0.35, 0.35, 0.35, 0.35, 0.35, 0.35, 0.35, 0.35, 0.35, 0.35,
  0.35, 0.35, 0.35, 0.35, 0.35, 0.35, 0.35, 0.35, 0.35, 0.35, 0.35, 0.35,
];

const gameLengthPrize = [
  {
    twoPlayer: 2,
    threePlayer: 3,
    fourPlayer: 4,
  },
  {
    twoPlayer: 2,
    threePlayer: 3,
    fourPlayer: 4,
  },
  {
    twoPlayer: 3,
    threePlayer: 4,
    fourPlayer: 5,
  },
  {
    twoPlayer: 3,
    threePlayer: 4,
    fourPlayer: 5,
  },
  {
    twoPlayer: 3,
    threePlayer: 4,
    fourPlayer: 6,
  },
  {
    twoPlayer: 4,
    threePlayer: 5,
    fourPlayer: 6,
  },
  {
    twoPlayer: 4,
    threePlayer: 6,
    fourPlayer: 7,
  },
  {
    twoPlayer: 4,
    threePlayer: 6,
    fourPlayer: 8,
  },
  {
    twoPlayer: 4,
    threePlayer: 6,
    fourPlayer: 8,
  },
  {
    twoPlayer: 5,
    threePlayer: 6,
    fourPlayer: 7,
  },
  {
    twoPlayer: 5,
    threePlayer: 6,
    fourPlayer: 8,
  },
  {
    twoPlayer: 5,
    threePlayer: 7,
    fourPlayer: 9,
  },
  {
    twoPlayer: 5,
    threePlayer: 7,
    fourPlayer: 9,
  },
  {
    twoPlayer: 5,
    threePlayer: 7,
    fourPlayer: 10,
  },
  {
    twoPlayer: 6,
    threePlayer: 7,
    fourPlayer: 8,
  },
  {
    twoPlayer: 6,
    threePlayer: 7,
    fourPlayer: 9,
  },
  {
    twoPlayer: 6,
    threePlayer: 8,
    fourPlayer: 10,
  },
  {
    twoPlayer: 6,
    threePlayer: 8,
    fourPlayer: 11,
  },
  {
    twoPlayer: 6,
    threePlayer: 9,
    fourPlayer: 12,
  },
  {
    twoPlayer: 6,
    threePlayer: 9,
    fourPlayer: 13,
  },
];

const rewardsPercentage: number[][] = [
  [1, 0.3],
  [1, 0.75, 0.5],
  [1, 0.75, 0.5, 0.5],
];
type TMinigameHistoryObject = {
  id: number;
  rewardsEarned?: {
    [key in GameCurrencies]?: number;
  };
};

export const useGameState = defineStore("gameState", {
  state: () => ({
    minigames: [
      {
        id: 1,
        module: "cryptobomberv2",
        name: "crypto-bomber",
        key: "CryptoBomberScene",
        gradient: { from: "#53203F", to: "#E48FC3" },
        background: "#74365C",
        rewards: [140, 110, 60],
        description: `Rise in the ranks by bombing your fellow guests! Bomb open the
                boxes to earn boosts. Fire makes your bombs stronger, rollerskates
                make you faster, and bombs increasae your inventory. Use the arrow
                keys to navigate around the field, and the space bar to drop the
                bomb!`,
        disabled: false,
      },
      {
        id: 2,
        name: "fruity-memory",
        module: "memory",
        gradient: { from: "#53203F", to: "#E48FC3" },
        background: "#74365C",
        rewards: [150, 120, 100],
        description: "",
        disabled: true,
        key: "MemoryGameScene",
      },
      {
        id: 3,
        name: "stampwars",
        module: "stampwars",
        gradient: { from: "#53203F", to: "#E48FC3" },
        background: "#74365C",
        description: "",
        disabled: true,
        key: "StampWarsScene",
      },
      {
        id: 4,
        name: "bit-plinko",
        module: "plinko",
        key: "PlinkoScene",
        gradient: { from: "#53203F", to: "#E48FC3" },
        background: "#74365C",
        description: "",
        disabled: true,
      },
      {
        id: 5,
        name: "mighty-mines",
        module: "mines",
        key: "MightyMinesScene",
        gradient: { from: "#53203F", to: "#E48FC3" },
        background: "#74365C",
        description: "",
        disabled: true,
      },
      {
        id: 6,
        name: "dogeball",
        module: "dogeball",
        gradient: { from: "#53203F", to: "#E48FC3" },
        background: "#74365C",
        description: "",
        disabled: true,
      },
      {
        id: 7,
        name: "rocket-crash",
        module: "crash",
        gradient: { from: "#53203F", to: "#E48FC3" },
        background: "#74365C",
        description: "",
        disabled: false,
        key: "CrashScene",
      },
    ],
    selectedMinigame: { id: -1, module: "", name: "null" } as TMinigame,
    activeMinigame: {
      id: 0,
      name: "",
      titleImage: "",
      gradient: { from: "", to: "" },
      background: "",
      rewards: [],
    } as unknown as TMinigame,
    minigamePlayers: [] as Array<TMinigamePlayer>,
    minigameScoreboard: { winners: [] } as {
      disablePodium?: boolean;
      winners: Array<TMinigamePlayer>;
      matchData?: {
        [key: string]: string | number | { [key: string]: string };
      };
    },
    _currentMinigamePlayer: {
      hearts: 3,
      color: "",
      progress: 0,
      conquered: 0,
      score: 0,
      ticketsWon: 0,
      bonus: 0,
      team: 0,
    } as TMinigamePlayerFields,
    _minigameHistory: [] as Array<TMinigameHistoryObject>,

    gameSettings: {} as any,
    gameSettingsVisible: false,
  }),
  getters: {
    currentMinigamePlayer(state) {
      const user = useUserStore();
      const nft = NFTHelpers.NFT.fromAddress(
        user.currentAccount.user.metadata?.activeCharacterAddress!,
      );
      return {
        ...state._currentMinigamePlayer,
        ...nft,
        ...{
          username: user.currentAccount.user.username,
          id: user.currentAccount.user.id,
        },
      };
    },
  },
  actions: {
    startMinigame(minigame: TMinigame | null) {
      if (this.activeMinigame.id != -1) {
        this.endMinigame();
      }
      this.updateActiveMinigame(minigame);
    },
    endMinigame(rewardsEarned?: { [key in GameCurrencies]?: number }) {
      const historyObject: TMinigameHistoryObject = {
        id: this.activeMinigame.id,
        rewardsEarned,
      };
      this._minigameHistory.push(historyObject);
    },
    initializeCurrentMinigamePlayer(data: { color?: string; team?: number }) {
      Object.keys(this._currentMinigamePlayer).forEach((key: string) => {
        if (key in this._currentMinigamePlayer) {
          //@ts-ignore
          this._currentMinigamePlayer[key] = data[key];
        }
      });
    },
    currencyAndAmountToIcon(
      currency: string,
      amount: number,
      inMatch: boolean = false,
    ) {
      if (amount === 0 && !inMatch) {
        return getIcon("normal-mode");
      }
      switch (currency) {
        case "sbth": {
          if (amount > 1000 && !inMatch) {
            return getIcon("coin-stack-bth-large");
          } else if (amount > 0) {
            return getIcon("coin-stack-bth");
          }
          return "";
        }
        case "tickets": {
          if (amount > 1000 && !inMatch) {
            return getIcon("tickets-stack");
          }
          return getIcon("tickets");
        }
      }
      return getIcon("tickets");
    },
    updateActiveMinigame(minigame: TMinigame | null) {
      if (minigame) {
        Object.assign(this.activeMinigame, minigame);
      } else {
        this.activeMinigame = {
          id: 0,
          name: "",
          titleImage: "",
          gradient: { from: "", to: "" },
          background: "",
          rewards: [],
        };
        this.minigamePlayers.length = 0;
      }
    },
    joinMinigameLobby(value: { [key: string]: string }) {
      if (value.sceneKey) {
        this.selectedMinigame = this.minigames.find(
          (minigame) => minigame.key === value.sceneKey,
        )!;
      }
      dispatchEvent(
        //@ts-ignore
        new CustomEvent("minigame-tile-play", {
          detail: { key: this.selectedMinigame.key, ...value },
        }),
      );
    },
    joinMinigameMatchQueue(data: {
      sceneKey: string;
      matchData: { [key: string]: any };
    }) {
      // Google Analytics event
      event("Button-click-game", {
        event_category: `Play Minigame: ${this.selectedMinigame.name}`,
        event_label: `Play Minigame: ${this.selectedMinigame.name}`,
        value: 1,
      });
      if (data.sceneKey) {
        this.selectedMinigame = this.minigames.find(
          (minigameConfig) => minigameConfig.key === data.sceneKey,
        )!;
      }
      this.updateActiveMinigame(this.selectedMinigame);

      const miniGameData = MinigameValues[this.selectedMinigame.module];
      if (this.selectedMinigame.name === "bit-plinko") {
        mobilePortrait();
      }
      if (miniGameData.MaxPlayers === 1) {
        dispatchEvent(EnumApplicationEvents.ApplicationStartMinigame, {
          sceneKey: miniGameData.SceneKey,
          senderSceneKey: GameScene.SceneKey,
          params: {
            matchData: data.matchData,
          },
        });

        this.gameSettingsVisible = false;
        this.gameSettings = undefined;
        return;
      }
      Matchmaker.instance
        .queueForMatch(
          this.selectedMinigame.module,
          this.selectedMinigame.key!,
          {
            currency: data.matchData.currency,
            playableAmount: data.matchData.playableAmount,
          },
        )
        .finally();

      this.gameSettingsVisible = false;
      this.gameSettings = undefined;
    },
    rewardTicketsForMultiplayerMatch(
      gameLength: number,
      playerPosition: number,
    ): number {
      const { currentAccount } = useUserStore(); // user.currentAccount;
      if (!currentAccount.user?.metadata) {
        return 0;
      }
      const numberOfPlayers = this.minigamePlayers.length + 1;
      // Check if the player has played before
      const currentDate = new Date();
      if ("lastPlayedTimestamp" in currentAccount.user.metadata) {
        // First check if we need to reset some data
        const previousDate = new Date(
          currentAccount.user.metadata.lastPlayedTimestamp!,
        );

        // Check if we're past midnight
        if (
          previousDate.getDate() !== currentDate.getDate() ||
          previousDate.getMonth() !== currentDate.getMonth() ||
          previousDate.getFullYear() !== currentDate.getFullYear()
        ) {
          currentAccount.user.metadata.timePlayedInMinutes = 0;
        }
      }

      // Convert gameLength to minutes
      const minutesPlayed = Math.ceil(gameLength / 60000);

      // Keep track of when we played last
      currentAccount.user.metadata.lastPlayedTimestamp = currentDate.getTime();

      // Keeping track of the played time for today
      if ("timePlayedInMinutes" in currentAccount.user.metadata) {
        currentAccount.user.metadata.timePlayedInMinutes! += minutesPlayed;
      } else {
        currentAccount.user.metadata.timePlayedInMinutes = minutesPlayed;
      }

      // Keep track of the total time played
      if ("totalTimePlayedInMinutes" in currentAccount.user.metadata) {
        currentAccount.user.metadata.totalTimePlayedInMinutes! += minutesPlayed;
      } else {
        currentAccount.user.metadata.totalTimePlayedInMinutes = minutesPlayed;
      }

      const glp = gameLengthPrize[Math.min(Math.ceil(minutesPlayed), 19)]; //19 levels of game length
      // ctx.info("glp:" glp);

      let glpReward = 0;
      if (numberOfPlayers === 2) {
        glpReward = glp.twoPlayer;
      } else if (numberOfPlayers === 3) {
        glpReward = glp.threePlayer;
      } else if (numberOfPlayers === 4) {
        glpReward = glp.fourPlayer;
      }

      const divider = 60;

      const rewardFirstPlace = Math.ceil(
        earnings[
          Math.floor(
            currentAccount.user.metadata.timePlayedInMinutes! / divider,
          )
        ] *
          (1 +
            TICKET_REWARD_BONUSES[
              NFTHelpers.NFT.fromAddress(
                currentAccount.user.metadata.activeCharacterAddress!,
              ).rarity
            ]) *
          glpReward,
      );

      let playerReward;

      // Track wins and losses
      if (playerPosition === 1) {
        if ("wins" in currentAccount.user.metadata) {
          currentAccount.user.metadata.wins! += 1;
        } else {
          currentAccount.user.metadata.wins = 1;
        }
      } else {
        if ("losses" in currentAccount.user.metadata) {
          currentAccount.user.metadata.losses! += 1;
        } else {
          currentAccount.user.metadata.losses = 1;
        }
      }

      if (playerPosition === 1) {
        playerReward = rewardFirstPlace;
      } else if (playerPosition === 2) {
        playerReward = Math.ceil(
          rewardFirstPlace * rewardsPercentage[numberOfPlayers - 2][1],
        );
      } else if (playerPosition === 3) {
        playerReward = Math.ceil(
          rewardFirstPlace * rewardsPercentage[numberOfPlayers - 2][1],
        );
        playerReward = Math.ceil(
          playerReward * rewardsPercentage[numberOfPlayers - 2][2],
        );
      } else {
        playerReward = Math.ceil(
          rewardFirstPlace * rewardsPercentage[numberOfPlayers - 2][1],
        );
        playerReward = Math.ceil(
          playerReward * rewardsPercentage[numberOfPlayers - 2][2],
        );
        playerReward = Math.ceil(
          playerReward * rewardsPercentage[numberOfPlayers - 2][3],
        );
      }

      return playerReward;
    },
    resetCurrentMinigamePlayer() {
      this._currentMinigamePlayer = {
        hearts: 3,
        color: "",
        progress: 0,
        conquered: 0,
        score: 0,
        ticketsWon: 0,
        bonus: 0,
        team: 0,
      };
    },
  },
});
