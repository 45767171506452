import { GameLocales } from "@common/enums/GameLocales";
import { createI18n } from "vue-i18n";

import en from "./locales/en.yaml";
import pt from "./locales/pt.yaml";

function getLocaleSafe() {
  const browserLocale = (
    (navigator.language || (navigator as any).userLanguage) as string
  ).split("-")[0];
  return browserLocale in GameLocales ? browserLocale : "en";
}
export const i18n = createI18n({
  allowComposition: true,
  legacy: false,
  locale: getLocaleSafe(),
  fallbackLocale: "en",
  messages: {
    en,
    pt,
  },
  // something vue-i18n options here ...
});

export default i18n;
