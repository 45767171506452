import { BaseContract, Signer } from "ethers";
import { toRaw } from "vue";
import { EthersUtils } from "../evm";

export abstract class BaseEvm<T extends BaseContract> {
  protected readonly _contract: T;
  protected readonly _ethersUtils: EthersUtils;
  readonly address: string;

  constructor(contract: T) {
    this._contract = contract;
    this._ethersUtils = new EthersUtils();
    this.address = contract.address;
  }

  protected getSigner(): Signer {
    const signer = toRaw(this._contract).signer;
    if (!signer) {
      throw new Error(`No signer available`);
    }
    return signer;
  }
}
