import EntityComponent from "@game/engine/components/EntityComponent";
import IsometricCharacter from "@game/engine/IsometricCharacter";
import {
  Direction,
  DirectionDeltas,
  Directions,
} from "@game/engine/navigation/Direction";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import IsometricGameScene from "@game/engine/scenes/IsometricGameScene";
import { TilePositionMath } from "@game/engine/utilities/TileHelper";
import { usableCharacterNames } from "@shared/consts/Characters";
import Sprite = Phaser.GameObjects.Sprite;

export default class AnimatedIsometricAvatarComponent extends EntityComponent {
  public get sprite(): Sprite {
    return this._sprite;
  }
  protected readonly _sprite: Sprite;
  private readonly availableAnimationTypes: string[] = ["idle", "sit", "walk"];
  private readonly directionToAnimationName: string[] = [
    "isometric_up_left", // North: mirrorX
    "isometric_down_left", // East: mirrorX
    "isometric_down_left", // South
    "isometric_up_left", // West
    "top_down_left", // NorthEast: mirrorX
    "top_down_down", // SouthEast
    "top_down_left", // SouthWest
    "top_down_up", // NorthWest
  ];

  private direction: Direction = Direction.South;

  constructor(
    private scene: IsometricGameScene,
    private _characterName: string,
    private frameRate: number = 12,
  ) {
    super();
    // Add a sprite to show us on the screen
    this._sprite = new Sprite(scene, 0, 0, _characterName);
    this.characterName = _characterName;
    this.scene.add.existing(this._sprite);
  }
  public get characterName() {
    return this._characterName;
  }
  public set characterName(characterName: string) {
    if (!usableCharacterNames.includes(characterName)) {
      characterName = "default_character";
    }
    this._sprite.setFrame(0);
    this._sprite.setOrigin(0.5, 0.9);
    if (!this.scene.anims.exists(characterName)) {
      // Check if the animations exist
      const json = this.scene.cache.json.get(characterName + "_json");

      // Some sanity checking
      if (!json || !("animations" in json)) {
        throw new Error(
          "[x] Invalid animation JSON provided for character '" +
            characterName +
            "': missing animations - Did you preload the JSON and Spritesheet?'",
        );
        return;
      }

      // Retrieve the meta data
      const animationMetaData = json["animations"];

      // The atlas to retrieve the animations from
      const characterAtlasKey = characterName + "_atlas";

      // Iterate over both the animation types and all the available directions
      this.availableAnimationTypes.forEach((availableAnimationType) => {
        // Quickly make it a set to filter out duplicates MARKTWAIN?
        new Set(this.directionToAnimationName).forEach((animationName) => {
          // Sanity check
          const prefix = animationName + "_" + availableAnimationType;
          if (!(prefix in animationMetaData)) {
            return;
          }

          // Calculate the number of frames
          const numberOfFrames = animationMetaData[prefix].length - 1;

          this.scene.anims.create({
            frameRate: this.frameRate,
            frames: this.scene.anims.generateFrameNames(characterAtlasKey, {
              end: numberOfFrames,
              prefix: prefix + "_",
              suffix: ".png",
              zeroPad: 3,
            }),
            key:
              characterName +
              "_" +
              animationName +
              "_" +
              availableAnimationType,
            repeat: -1,
          });
        });
      });
      // Used for checking if the animations already exist
      this.scene.anims.create({ key: characterName });
    }
    this._characterName = characterName;
    // So there's a default state
    this._sprite.play(characterName + "_isometric_down_left_idle");
  }
  public face(direction: Direction) {
    this.direction = direction;

    this._sprite.flipX =
      this.direction === Direction.North ||
      this.direction === Direction.NorthEast ||
      this.direction === Direction.East;

    this.playCurrentStateAnimation();
  }

  // Returns true if direction changed
  public faceIfNotAlreadyFacing(direction: Direction): boolean {
    if (!this.isFacing(direction)) {
      this.face(direction);

      return true;
    }

    return false;
  }

  public sit() {
    const anim =
      this._characterName +
      (this.direction === Direction.North || this.direction === Direction.West
        ? "_isometric_up_left_sit"
        : "_isometric_down_left_sit");

    this._sprite.play(anim);
  }

  protected onOwnerSet() {
    super.onOwnerSet();

    this.owner.add(this._sprite);

    // If the character does anything, make sure we're up to date
    this.owner.on(IsometricCharacter.EventTypes.StateChanged, () => {
      this.playCurrentStateAnimation();
    });

    // If we're moving, make sure to face the right way
    this.owner.on(
      IsometricCharacter.EventTypes.MovedToTile,
      (tilePosition: TilePosition3D, previousTilePosition: TilePosition3D) => {
        Directions.forEach((direction) => {
          // Calculate the difference between the previous and current position
          const delta = DirectionDeltas[direction];
          const difference = TilePositionMath.difference(
            tilePosition,
            previousTilePosition,
          );

          // We're ignoring the z axis for now
          difference.z = 0;

          // Seems like we're moving in that direction
          if (TilePositionMath.equals(delta, difference)) {
            this.faceIfNotAlreadyFacing(direction);
          }
          return;
        });
      },
    );
  }

  private isFacing(direction: Direction): boolean {
    return this.direction === direction;
  }

  protected playCurrentStateAnimation() {
    const animationKey =
      this.characterName +
      "_" +
      this.directionToAnimationName[this.direction] +
      "_" +
      this.owner.state;
    this._sprite.play(animationKey);
  }
}
