import { TilePosition3D } from "@game/engine/navigation/Pathfinder";

export default function equals(
  tilePositionA: TilePosition3D,
  tilePositionB: TilePosition3D,
) {
  if (!tilePositionA || !tilePositionB) {
    return false;
  }
  return (
    tilePositionA.x === tilePositionB.x &&
    tilePositionA.y === tilePositionB.y &&
    tilePositionA.z === tilePositionB.z
  );
}

export const TilePosition3DZero: TilePosition3D = { x: 0, y: 0, z: 0 };
export const TilePosition3DMinusOne: TilePosition3D = { x: -1, y: -1, z: -1 };
