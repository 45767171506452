import { EntityComponentV2 } from "@engine/components/EntityComponentV2";
import Entity from "@engine/Entity";
import GameScene from "@engine/scenes/GameScene";
import { IIdentifiableAsset } from "@engine/objects/DynamicLoader";
import { Network } from "@network";
import { AnimatedEmote } from "@engine/gameobjects/emotes/AnimatedEmote";
import EventEmitter from "eventemitter3";
import { Fonts } from "@engine/constants/Fonts";
import { IConsumable } from "@common/interfaces/IConsumable";
import Container = Phaser.GameObjects.Container;
import Text = Phaser.GameObjects.Text;
import Tween = Phaser.Tweens.Tween;
import Sprite = Phaser.GameObjects.Sprite;
import GAMEOBJECT_POINTER_OVER = Phaser.Input.Events.GAMEOBJECT_POINTER_OVER;
import GAMEOBJECT_POINTER_OUT = Phaser.Input.Events.GAMEOBJECT_POINTER_OUT;
import GAMEOBJECT_POINTER_DOWN = Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN;
import Image = Phaser.GameObjects.Image;
import Vector2 = Phaser.Math.Vector2;

interface IInventoryResponsePayload {
  inventory: {
    consumable: IConsumable;
    count: number;
  }[];
}

class EmoteSelector extends Container {
  private readonly _amount: number;
  private readonly _amountText: Text;
  private readonly _background: Sprite;
  private _emote: AnimatedEmote | undefined;
  private readonly _identifier: string | null;
  private readonly _owner: Entity;
  private _tween: Tween;
  private _tweenHover: Tween;

  public constructor(
    scene: GameScene,
    owner: Entity,
    identifier: string | null,
    amount: number,
    offset,
    onSelected: (identifier: string) => void,
  ) {
    super(scene, owner.x + offset.x, owner.y + offset.y);

    this._amount = amount;
    this._identifier = identifier;
    this._owner = owner;

    this._background = new Sprite(
      scene,
      0,
      0,
      "emotewheel",
      this.isEnabled() ? "enabled" : "disabled",
    );

    this.add(this._background);

    const amountBackground = new Image(scene, 30, -30, "amountbackground");
    this.add(amountBackground);

    const w = 20;
    this._amountText = new Text(
      scene,
      30,
      -30,
      "" + Math.min(amount, 99),
      Fonts.SmallTextWhiteCenteredWithWidth(w),
    ).setOrigin(0.5, 0.5);
    this.add(this._amountText);

    this.setAlpha(0).setDepth(999).setScale(0);

    // Making sure we're clickable
    if (this.isEnabled()) {
      this.setInteractive({
        hitArea: new Phaser.Geom.Circle(0, 0, 40),
        hitAreaCallback: Phaser.Geom.Circle.Contains,
        useHandCursor: true,
      });

      this.on(GAMEOBJECT_POINTER_DOWN, (a, b, c, event) => {
        event.stopPropagation();
        onSelected(this._identifier);
      });
      this.on(GAMEOBJECT_POINTER_OUT, () => {
        this.onHovered(false);
      });
      this.on(GAMEOBJECT_POINTER_OVER, () => {
        this.onHovered(true);
      });
    }
  }

  public hide() {
    this._tween.stop();

    this.scene.tweens.add({
      duration: 256,
      onComplete: () => {
        this.destroy(true);
      },
      props: {
        alpha: 0,
      },
      targets: this._emote ? [this, this._emote.getSprite()] : this,
    });
  }

  public isEnabled() {
    return this._identifier !== null && this._amount > 0;
  }

  public show(position: number, total: number, radius: number) {
    if (this._identifier) {
      this._emote = new AnimatedEmote(this._identifier, this._owner, {}, true);
      this._emote.getSprite()?.setScale(0);
      this._emote.getSprite().alpha = this._amount > 0 ? 1 : 0.75;
    }

    this._tween = this.scene.tweens.add({
      delay: 32 * position,
      duration: 256,
      ease: "Back.easeOut",
      props: {
        x:
          this.x +
          Math.cos(Math.PI * 2 * (position / total) - Math.PI / 2) * radius,
        y:
          this.y +
          Math.sin(Math.PI * 2 * (position / total) - Math.PI / 2) * radius,
        scaleX: 1,
        scaleY: 1,
      },
      targets: this._emote ? [this, this._emote.getSprite()] : this,
    });

    this.scene.tweens.add({
      targets: this,
      alpha: 1,
      delay: 32 * position,
      duration: 256,
      ease: "Back.easeOut",
    });
  }

  private onHovered(over: boolean) {
    this._background.setFrame(over ? "hover" : "enabled");

    this._tweenHover?.stop();
    this._tweenHover = this.scene.tweens.add({
      duration: 256,
      props: {
        scaleX: over ? 1.1 : 1.0,
        scaleY: over ? 1.1 : 1.0,
      },
      targets: this,
    });
  }
}

export class EmoteWheelComponent extends EntityComponentV2 {
  public static EventTypes = {
    EmoteSelected: "emotewheelcomponentemoteselected",
  };
  public events: EventEmitter;
  private readonly _allSelectors: EmoteSelector[];
  private _isShowing = false;
  private readonly _offset: Vector2;
  private readonly _radius: number;
  private readonly _scene: GameScene;
  private _selectors: { [identifier: string]: EmoteSelector };
  private readonly _wheelSize: number;

  public constructor(
    owner: Entity,
    offset: Vector2 = Vector2.ZERO,
    wheelSize: number = 10,
    radius: number = 128,
  ) {
    super(owner);

    this._offset = offset;
    this._wheelSize = wheelSize;
    this._radius = radius;

    this.events = new EventEmitter();
    this._allSelectors = [];
    this._scene = owner.scene;
    this._selectors = {};

    this.loadAssets();
  }

  public hide() {
    for (const selector of this._allSelectors) {
      selector.hide();
    }
    this._allSelectors.length = 0;
    this._selectors = {};
    this._isShowing = false;
  }

  public show() {
    Network.Core.core.rpc("Inventory", { type: "emote" }).then((response) => {
      const inventory: { [identifier: string]: number } = {};
      if ("payload" in response && "inventory" in response.payload) {
        (<IInventoryResponsePayload>response.payload).inventory.map(
          (item: { consumable: IConsumable; count: number }) => {
            inventory[item.consumable.identifier] = item.count;
          },
        );
      }

      for (
        let index = 0;
        index < AnimatedEmote.AvailableAnimatedEmotes.length;
        ++index
      ) {
        const identifier = AnimatedEmote.AvailableAnimatedEmotes[index];
        if (!(identifier in inventory)) {
          inventory[identifier] = 0;
        }
        this.addEmoteSelector(
          index,
          inventory[identifier],
          this._wheelSize,
          this._radius,
        );
      }

      for (
        let index = AnimatedEmote.AvailableAnimatedEmotes.length;
        index < this._wheelSize;
        ++index
      ) {
        this.addEmoteSelector(index, null, this._wheelSize, this._radius);
      }

      this._isShowing = true;
    });
  }

  public toggle() {
    if (this._isShowing) {
      this.hide();
    } else {
      this.show();
    }
  }

  private addEmoteSelector(
    index: number,
    count: number,
    numberOfEmotes: number,
    radius: number,
  ): EmoteSelector {
    const identifier =
      index < AnimatedEmote.AvailableAnimatedEmotes.length
        ? AnimatedEmote.AvailableAnimatedEmotes[index]
        : null;
    const emoteSelector = new EmoteSelector(
      this._scene,
      this._owner,
      identifier,
      count,
      this._offset,
      this.onEmoteSelected,
    );
    if (identifier) {
      this._selectors[AnimatedEmote.AvailableAnimatedEmotes[index]] =
        emoteSelector;
    }
    this._allSelectors.push(emoteSelector);
    this._scene.add.existing(emoteSelector);

    emoteSelector.show(index, numberOfEmotes, radius);

    return emoteSelector;
  }

  private loadAssets() {
    const assets: IIdentifiableAsset[] = [];

    assets.push({
      atlasURL: "/assets/game/sprites/emotewheel/emotewheel.json",
      key: "emotewheel",
      textureURL: "/assets/game/sprites/emotewheel/emotewheel-spritesheet.png",
      type: "atlas",
    });
    assets.push({
      key: "amountbackground",
      type: "image",
      url: "/assets/game/sprites/emotewheel/number_container.png",
    });

    this._scene.dynamicLoadAssets(assets);

    AnimatedEmote.PreloadAllAnimatedEmotes(this._scene);
  }

  private onEmoteSelected = (identifier: string) => {
    this.events.emit(EmoteWheelComponent.EventTypes.EmoteSelected, identifier);
    this.hide();
  };
}
