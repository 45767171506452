export default [
  {
    address: ["0x39b41f517E1f214FB2Da2Dc2f01CE5A7a5CD597E"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Uncommon",
        },
        {
          trait_type: "Replicas",
          value: "100",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "One of the center pieces of the kitchen furniture line. An integral part of your bar inside of your room, as you don't want to leave your guests standing!",
      name: "Bar Stool",
      texture: "kitchenbarstoolteal_1x1x2_1",
    },
    type: "furniture",
  },
  {
    address: ["0xd72a70519B4E79674758C7c2309bF30ce2F35bd0"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Rare",
        },
        {
          trait_type: "Replicas",
          value: "60",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "One of the items that allows Hotel guests to hang their NFTs on their room walls. A fun way to highlight your art pieces on- and off-chain.",
      name: "Painting Frame",
      disabled: true,
    },
    type: "furniture",
  },
  {
    address: ["0x783CabDA919f8607376835C140dbF0bE90F51C8C"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Epic",
        },
        {
          trait_type: "Replicas",
          value: "10",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "One of the most precious mini-game furniture of the arcade collection. Play the Rocket Crash mini-game directly from your room with hidden advantages by placing this arcade machine down. Only very few Hotel guests own any item of the arcade machine collection.",
      name: "Jet Rocket Arcade Machine",
      texture: "jetrocketmachine_1x1x3_1",
    },
    type: "furniture",
  },
  {
    address: ["0xc233623Bf1Ad14Dc1608da1851c0e079b98A5D51"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Super Rare",
        },
        {
          trait_type: "Replicas",
          value: "50",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "A mythical creature that only the very few have seen. With it's super rare color and predatory stance the Tourmaline dragon is truly a sight to behold. As one of the first dragons seen in the Bit Hotel this furniture piece is in high demand amongst collectors.",
      name: "Tourmaline Dragon Statue",
      texture: "dragonpinktourmaline_1x1x2_1",
    },
    type: "furniture",
  },
  {
    address: ["0x9070B35bFdcc31472965D79e7B0854b103a9b9D7"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Super Rare",
        },
        {
          trait_type: "Replicas",
          value: "40",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "One of the gaming chairs that is in high demand by the e-girls of the hotel. A fun and colourful part of the gaming furniture collection. ",
      name: "Pink Gaming Chair",
      texture: "gamerchairpink_1x1x2_1",
    },
    type: "furniture",
  },
  {
    address: ["0x07CCE68bcD021139ce8bc774e49b677f896913DA"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Rare",
        },
        {
          trait_type: "Replicas",
          value: "80",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "A bed for your Bit Hotel pets. Allows your pet to chill out and have a nap in your room.",
      name: "Pet Bed",
      texture: "petbedsmalllightpurple_2x1x1_1",
    },
    type: "furniture",
  },
  {
    address: ["0xa788082DfDe390BC526FbC789a6DBaFF131480E6"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Uncommon",
        },
        {
          trait_type: "Replicas",
          value: "300",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "The caffeine infused center piece of most hotel guest's rooms. A fun and compact furniture piece of the kitchen collection.",
      name: "Coffee Maker",
      disabled: true,
    },
    type: "furniture",
  },
  {
    address: ["0x7A68265e94353D0F18E4563a21f84E7325293914"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Common",
        },
        {
          trait_type: "Replicas",
          value: "500",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "A fun dice game that allows any hotel guest with the right permissions to initiate a roll of the dice.",
      name: "Led Dice",
      texture: "leddice_1x1x1_11",
    },
    type: "furniture",
  },
  {
    address: ["0xbc8022Dc19E6cD97F8601Fd4f58583e78E9B64Ae"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Uncommon",
        },
        {
          trait_type: "Replicas",
          value: "1000",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "An uncommon painting to hang on the wall of your Bit Hotel room!",
      name: "Moon Moon Painting",
      disabled: true,
    },
    type: "furniture",
  },
  {
    address: ["0x9864ba7A6F846170D3734C7d46ab1749fD8b77Fd"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Rare",
        },
        {
          trait_type: "Replicas",
          value: "500",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "A rare painting to hang on the wall of your Bit Hotel room!",
      name: "Elon Dusk Painting",
      disabled: true,
    },
    type: "furniture",
  },
  {
    address: ["0x51de6d891dcb6ca2ba25dd8da3fbacde8daa8b40"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Rare",
        },
        {
          trait_type: "Replicas",
          value: "250",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "A mythical creature that only the very few have seen. With it's rare color and predatory stance the Amethyst dragon is truly a sight to behold. As one of the first dragons seen in the Bit Hotel this furniture piece is in high demand amongst collectors",
      name: "Amethyst Dragon",
      texture: "dragonamethyst_1x1x2_1",
    },
    type: "furniture",
  },
  {
    address: ["0x98c645b0532cc16f4db452104b5307adb534fbfe"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Uncommon",
        },
        {
          trait_type: "Replicas",
          value: "2100",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "A classic twist on the retro and popular Pacman game that will bring you back to your upbringing. Play as Pacman and try and eat as many cookies as you can before the ghosts catch up to you.  As one of the first arcade machines seen in the Bit Hotel, this furniture piece is in high demand amongst collectors.",
      name: "Pacman Arcade Machine",
      disabled: true,
    },
    type: "furniture",
  },
  {
    address: ["0x24Db3309707756046A31f3DE2d3c58959E5dc6Ae"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Rare",
        },
        {
          trait_type: "Replicas",
          value: "80",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "Whether you're chilling with your friends or having a nap, this sofa can comfort you whenever you need. Nine out of ten Bit Hotel designer's recommend you to own a sofa!",
      name: "Modern Sofa",
      texture: "modernsofagrey_2x1x2_1",
    },
    type: "furniture",
  },
  {
    address: ["0x466556d90F58Cd07102d645056F770eF7DDa7626"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Super Rare",
        },
        {
          trait_type: "Replicas",
          value: "40",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "One of the gaming desks that is in high demand by the e-girls of the Bit Hotel. A fun workstation that allows you to play your favorite games as well. Definitely a must-have!",
      name: "Gamer Desk",
      texture: "gamerdeskblackpink_2x1x3_1",
    },
    type: "furniture",
  },
  {
    address: ["0xe95619b6010CEf6F237d5E3967976DA2B8D28615"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Epic",
        },
        {
          trait_type: "Replicas",
          value: "10",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "One of the most precious mini-game furniture of the arcade collection. Play a game of air hockey directly from your room with hidden advantages by placing this furniture down on the ground. Only very few Hotel guests own any item of the arcade machine collection.",
      name: "Air Hockey Table",
      texture: "airhockeytable_2x3x2_1",
    },
    type: "furniture",
  },
  {
    address: ["0x0286Ad24dD584C986dfd16Abda416160eBb7CAB8"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Uncommon",
        },
        {
          trait_type: "Replicas",
          value: "300",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "A large and fancy bookshelf you can use to store your everyday items. A common appearance in Bit Hotel rooms due to its ease of use and utility.",
      name: "Bookshelf",
      texture: "basicbookshelfgreen_3x1x3_1",
    },
    type: "furniture",
  },
  {
    address: ["0xBCdEBF39E08cEc32C478817DeC1934E687b49Fe6"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Rare",
        },
        {
          trait_type: "Replicas",
          value: "60",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "A great place to have a nap! This bed is one of the rarer sightings in Bit Hotel rooms and is extremely comfortable. Sweet dreams!",
      name: "Modern Bed",
      texture: "modernbedpurple_3x3x2_1",
    },
    type: "furniture",
  },
  {
    address: ["0xA7ee8A1a7014B2a26Cef7DA0634B1326424445BB"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Common",
        },
        {
          trait_type: "Replicas",
          value: "500",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "A fresh looking dresser to stock your favorite clothes. A common item found in Bit Hotel rooms due to its utility and great price-quality ratio.",
      name: "Dresser",
      texture: "moderndresserteal_2x1x2_1",
    },
    type: "furniture",
  },
  {
    address: ["0x900c3BA4550153b3eE1084D3a2acEDF61E77961b"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Uncommon",
        },
        {
          trait_type: "Replicas",
          value: "100",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "Whether you're a coffee snob or more of a tea guy or gal, this table will allow you to hold any small kitchen appliance items that you wish.",
      name: "Coffee Table",
      texture: "basiccoffeetableteal_2x1x2_1",
    },
    type: "furniture",
  },
  {
    address: ["0x9D3124f1db92554Eb86C704d26C4590AcB423EB9"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Super Rare",
        },
        {
          trait_type: "Replicas",
          value: "50",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "A bed for your Bit Hotel pets. Allows your pet to chill out and have a nap in your room.",
      name: "Pet House",
      texture: "pethousesmallpink_1x1x2_1",
    },
    type: "furniture",
  },
  {
    address: ["0x4a05c1ceecb15acf8b9cbe1f0039ab96785b5f19"],
    metadata: {
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "75" },
        { trait_type: "Drop", value: "Season 1" },
      ],
      name: "Dogefather Painting",
      description:
        "The infamous Doge pictured in his sunday suit back in the 19th century. Truly a sight to behold often leaving viewers flabbergasted. Ownership of this painting reflects true support of the Bit Hotel and its guests.",
    },
    type: "furniture",
    disabled: true,
  },

  {
    address: ["0xD8F20e1730009aC75EFeCE5681E6a9e8aBede7Cb"],
    type: "furniture",
    metadata: {
      name: "MightyMines Arcade Machine",
      description:
        "One of the most precious mini-game furniture of the arcade collection. Play the MightyMines mini-game directly from your room with hidden advantages by placing this arcade machine down in your room. Only very few Hotel guests own any item of the arcade machine collection.",
      attributes: [
        { trait_type: "Rarity", value: "Epic" },
        { trait_type: "Replicas", value: "10" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "mightyminesmachine_1x1x3_1",
    },
  },

  {
    address: ["0x1b3f6BC8077badc4FAC2BC57F5C2A10f0DA383c4"],
    type: "furniture",
    metadata: {
      name: "Gamer Coffee Table",
      description:
        "A stylish neon-lit coffee table to add to your gaming furniture collection. Among the items in the gaming furniture set that are in very high demand with Bit Hotel guests due to its rarity.",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "40" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "gamerconsoletableteal_2x1x2_1",
    },
  },

  {
    address: ["0x6bA0d2514aE9f02cf090b001d6D1460e7a5f8bf5"],
    type: "furniture",
    metadata: {
      name: "Traditional Dice",
      description:
        "A fun dice game that allows any hotel guest with the right permissions to initiate a roll of the dice in a room.",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "50" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "standarddice_1x1x4_13",
    },
  },

  {
    address: ["0xDDeb56c9ed98C7299cd7627A8B2b5b95F1C01e90"],
    type: "furniture",
    metadata: {
      name: "Throne Sofa",
      description:
        "A majestic sofa that has gold plated sides that reflect your charms brightly into the eyes of your room guests. Definitely a rare items to see among Hotel guest rooms, therefore in high demand.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "60" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "thronesofa_2x1x3_1",
    },
  },

  {
    address: ["0x11fc8bB5b5D88F16bD82A24F796d7b8940151839"],
    type: "furniture",
    metadata: {
      name: "Pet Feeding Bowl",
      description:
        "If you don't want your little friend to get hungry you might want to get him some food. That is where the feeding bowl comes in, as you don't want your pet to be eating his munchies from the floor. This feeding bowl is among the rarer pet items in the game.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "80" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "petbowlpurple_1x1x1_1",
    },
  },

  {
    address: ["0xD346c4aFa71342a600eFB2e13c232071169E83Fe"],
    type: "furniture",
    metadata: {
      name: "Plastic Chair",
      description:
        "A simple but elegant chair to add to your furniture collection. The perfect affordable addition to your sitting corner.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "100" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "basicplasticchairteal_1x1x2_1",
    },
  },

  {
    address: ["0x2Eb41152355950Cf17DB7F1710e7fF6fDcbEdb45"],
    type: "furniture",
    metadata: {
      name: "Side Table",
      description:
        "The perfect little table to place in the sitting corner of your room. One of the more common types of side tables.",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "500" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "basicsidetableblue_1x1x2_1",
    },
  },
];
