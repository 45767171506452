import Season2 from "./Season2";
import Season4 from "./Season4";
import Season5 from "./Season5";
import Season6 from "./Season6";
import Season7 from "./Season7";
import Season8 from "./Season8";
import Season10 from "./Season10";
import Season11 from "./Season11";
import Season12 from "./Season12";
import Season13 from "./Season13";
// import Season14 from "./Season14";

export default [
  ...Season2,
  ...Season4,
  ...Season5,
  ...Season6,
  ...Season7,
  ...Season8,
  ...Season10,
  ...Season11,
  ...Season12,
  ...Season13,
  // ...Season14,
];
