<script setup lang="ts">
defineProps(["modelValue"]);
const emits = defineEmits(["update:modelValue", "input"]);
function emitChange(value) {
  emits("update:modelValue", value);
  emits("input", value);
}
</script>
<template>
  <label class="checkbox flex center--vertical">
    <input
      type="checkbox"
      :checked="modelValue"
      @input="emitChange(($event.target as HTMLInputElement).checked)"
    />
    <span class="indicator"></span>
    <span v-depixel class="font--xsm">
      <slot />
    </span>
  </label>
</template>
<style scoped lang="scss">
label.checkbox {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    ~ span.indicator {
      border: 2px solid lighten(get-color(blue-300), 50%);
      transition: background 150ms $ease-in-out-backwards, border 200ms ease;
    }
    &:hover ~ span.indicator {
      border: 2px solid lighten(get-color(blue-300), 25%);
    }
    &:focus ~ span.indicator {
      border: 2px solid get-color(blue-300);
    }

    // &:focus:checked ~ span {
    //   border: 2px solid darken(get-color(green-200), 15%);
    // }

    &:checked ~ span.indicator {
      color: get-color(white-50);
      background: get-color(green-200)
        url("/assets/overlay/icons/glyphs/check.png") no-repeat center/80%;
      border: 2px solid transparent;
    }
  }

  span.indicator {
    border-radius: get-size(xxs);
    position: absolute;
    left: 0;
    width: get-size(sm);
    height: get-size(sm);
    background-color: lighten(get-color(blue-300), 65%);
    border: 2px solid lighten(get-color(blue-300), 65%);
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    & + span {
      margin-left: get-size(m);
    }
  }
}

// label.checkbox {
//   @include checkbox($green);

//   &.blue {
//     @include checkbox($blue);
//   }

//   &.red {
//     @include checkbox($red);
//   }
// }
</style>
