/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import type { Provider } from "@ethersproject/providers";
import type {
  MarketplaceAbi,
  MarketplaceAbiInterface,
} from "../MarketplaceAbi";

const _abi = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "previousAdmin",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "newAdmin",
        type: "address",
      },
    ],
    name: "AdminChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "beacon",
        type: "address",
      },
    ],
    name: "BeaconUpgraded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "BthBurned",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newBth",
        type: "address",
      },
    ],
    name: "BthChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "listingId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "seller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "buyer",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "erc721TokenAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "erc721TokenId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum ERC721MarketplaceUpgradeable.Category",
        name: "category",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "priceInWei",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "timeExecuted",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum ERC721MarketplaceUpgradeable.Status",
        name: "status",
        type: "uint8",
      },
    ],
    name: "ERC721ExecutedListing",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "listingId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "seller",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "erc721TokenAddress",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "erc721TokenId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "enum ERC721MarketplaceUpgradeable.Category",
        name: "category",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "priceInWei",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "timeAdded",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum ERC721MarketplaceUpgradeable.Status",
        name: "status",
        type: "uint8",
      },
    ],
    name: "ERC721ListingAdd",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "listingId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum ERC721MarketplaceUpgradeable.Category",
        name: "category",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "timeCancelled",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum ERC721MarketplaceUpgradeable.Status",
        name: "status",
        type: "uint8",
      },
    ],
    name: "ERC721ListingCancelled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "listingId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum ERC721MarketplaceUpgradeable.Category",
        name: "category",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "timeRemoved",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "enum ERC721MarketplaceUpgradeable.Status",
        name: "status",
        type: "uint8",
      },
    ],
    name: "ERC721ListingRemoved",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address[]",
        name: "addresses",
        type: "address[]",
      },
      {
        indexed: false,
        internalType: "enum ERC721MarketplaceUpgradeable.Category[]",
        name: "category",
        type: "uint8[]",
      },
    ],
    name: "IncludedErc721TokenAddressesAdded",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address[]",
        name: "addresses",
        type: "address[]",
      },
    ],
    name: "IncludedErc721TokenAddressesRemoved",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Paused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "previousAdminRole",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "newAdminRole",
        type: "bytes32",
      },
    ],
    name: "RoleAdminChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleGranted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "account",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
    ],
    name: "RoleRevoked",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newAddress",
        type: "address",
      },
    ],
    name: "TimelockChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "newToken",
        type: "address",
      },
    ],
    name: "TokenChanged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "Unpaused",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "implementation",
        type: "address",
      },
    ],
    name: "Upgraded",
    type: "event",
  },
  {
    inputs: [],
    name: "DEFAULT_ADMIN_ROLE",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "INCLUDED_COLLECTIONS",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "TIMELOCK_CONTROLLER_ROLE",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "UPGRADER_ROLE",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "erc721TokenAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "erc721TokenId",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "priceInWei",
        type: "uint256",
      },
    ],
    name: "addERC721Listing",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "erc721TokenAddresses",
        type: "address[]",
      },
      {
        internalType: "enum ERC721MarketplaceUpgradeable.Category[]",
        name: "categories",
        type: "uint8[]",
      },
    ],
    name: "bulkAddIncludedCollections",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address[]",
        name: "erc721TokenAddresses",
        type: "address[]",
      },
    ],
    name: "bulkRemoveIncludedCollections",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "mListingId",
        type: "uint256",
      },
    ],
    name: "cancelERC721Listing",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256[]",
        name: "mListingIds",
        type: "uint256[]",
      },
    ],
    name: "cancelERC721Listings",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "mBth",
        type: "address",
      },
    ],
    name: "changeBth",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "newListingFeeInWei",
        type: "uint256",
      },
    ],
    name: "changeListingFeeInWei",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "mToken",
        type: "address",
      },
    ],
    name: "changeToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "mListingId",
        type: "uint256",
      },
    ],
    name: "executeERC721Listing",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "getAllIncludedCollections",
    outputs: [
      {
        internalType: "address[]",
        name: "",
        type: "address[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "erc721TokenAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "erc721TokenId",
        type: "uint256",
      },
    ],
    name: "getERC721Category",
    outputs: [
      {
        internalType: "enum ERC721MarketplaceUpgradeable.Category",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "mListingId",
        type: "uint256",
      },
    ],
    name: "getERC721Listing",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "listingId",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "seller",
            type: "address",
          },
          {
            internalType: "address",
            name: "erc721TokenAddress",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "erc721TokenId",
            type: "uint256",
          },
          {
            internalType: "enum ERC721MarketplaceUpgradeable.Category",
            name: "category",
            type: "uint8",
          },
          {
            internalType: "uint256",
            name: "priceInWei",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "timeAdded",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "timeCancelled",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "timePurchased",
            type: "uint256",
          },
          {
            internalType: "enum ERC721MarketplaceUpgradeable.Status",
            name: "status",
            type: "uint8",
          },
        ],
        internalType: "struct ERC721MarketplaceUpgradeable.ERC721Listing",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "mListingId",
        type: "uint256",
      },
    ],
    name: "getERC721ListingListItem",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "parentListingId",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "listingId",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "childListingId",
            type: "uint256",
          },
        ],
        internalType: "struct ERC721MarketplaceUpgradeable.ListingListItem",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "erc721TokenAddress",
        type: "address",
      },
    ],
    name: "getErc721Categories",
    outputs: [
      {
        internalType: "enum ERC721MarketplaceUpgradeable.Category",
        name: "",
        type: "uint8",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "mCategory",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "mSort",
        type: "string",
      },
    ],
    name: "getErc721ListingHead",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "mOwner",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "mCategory",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "mSort",
        type: "string",
      },
    ],
    name: "getErc721OwnerListingHead",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "erc721TokenAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "erc721TokenId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "mOwner",
        type: "address",
      },
    ],
    name: "getErc721TokenToListingId",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "erc721TokenAddress",
        type: "address",
      },
    ],
    name: "getIncludedCollectionIndex",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getNextERC721ListingId",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
    ],
    name: "getRoleAdmin",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "grantRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "hasRole",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "mBth",
        type: "address",
      },
      {
        internalType: "address",
        name: "mToken",
        type: "address",
      },
      {
        internalType: "address",
        name: "mOwner",
        type: "address",
      },
      {
        internalType: "address",
        name: "mWallet",
        type: "address",
      },
      {
        internalType: "address",
        name: "mTimelockController",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "mListingTax",
        type: "uint256",
      },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "initializerRan",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "isListingFeeEnabled",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "listingFeeInWei",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "mListingId",
        type: "uint256",
      },
    ],
    name: "listingIdIndex",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "listingIds",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "listingTax",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    name: "onERC721Received",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "pause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "paused",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "proxiableUUID",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "renounceRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "role",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "account",
        type: "address",
      },
    ],
    name: "revokeRole",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "erc721TokenAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "newController",
        type: "address",
      },
    ],
    name: "setCollectionController",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bool",
        name: "value",
        type: "bool",
      },
    ],
    name: "setListingFeeEnabled",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes4",
        name: "interfaceId",
        type: "bytes4",
      },
    ],
    name: "supportsInterface",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "timelockController",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalIncludedCollections",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalListingIds",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "unpause",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newImplementation",
        type: "address",
      },
    ],
    name: "upgradeTo",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newImplementation",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "upgradeToAndCall",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "version",
    outputs: [
      {
        internalType: "string",
        name: "",
        type: "string",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
] as const;

export class MarketplaceAbi__factory {
  static readonly abi = _abi;
  static createInterface(): MarketplaceAbiInterface {
    return new utils.Interface(_abi) as MarketplaceAbiInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MarketplaceAbi {
    return new Contract(address, _abi, signerOrProvider) as MarketplaceAbi;
  }
}
