import { IIsometricTilemap } from "@game/engine/interfaces/IIsometricTilemap";
import equals, {
  TilePosition3DMinusOne,
  TilePosition3DZero,
} from "@game/engine/utilities/TilePositionHelper";
import TilePathFollowerComponent from "./components/entitycomponents/TilePathFollowerComponent";
import Pawn from "./Pawn";
import TilePath from "./objects/TilePath";
import { TilePosition3D } from "./navigation/Pathfinder";
import IsometricGameScene from "@game/engine/scenes/IsometricGameScene";
import OrderableIsometricGameScene from "@game/engine/scenes/OrderableIsometricGameScene";
import Vector2 = Phaser.Math.Vector2;

export enum PlayerState {
  Idle = "idle",
  // Sitting = "sit",
  Walking = "walk",
}

export default class IsometricCharacter extends Pawn {
  public static EventTypes = {
    CompletedPath: "playercompletedpath",
    MovedToTile: "playermovedtotile",
    StartedPath: "playerstartedpath",
    StateChanged: "playerstatechanged",
    Teleported: "playerteleported",
  };
  public pathFollowerComponent: TilePathFollowerComponent;
  public declare state: PlayerState;
  public tilePosition: TilePosition3D = TilePosition3DZero;

  protected _previousTilePosition: TilePosition3D = TilePosition3DMinusOne;

  private _isometricTilemap: IIsometricTilemap;

  constructor(scene: IsometricGameScene | OrderableIsometricGameScene, x, y) {
    super(scene, x, y);

    // Create and add a path follower component for... Following paths

    this.pathFollowerComponent = this.addComponent<TilePathFollowerComponent>(
      new TilePathFollowerComponent(scene.map),
    );

    this.pathFollowerComponent.events.on(
      TilePathFollowerComponent.EventTypes.Completed,
      (tilePath: TilePath) => {
        this.emit(IsometricCharacter.EventTypes.CompletedPath, tilePath);

        this.setPlayerState(PlayerState.Idle);
      },
    );

    this.pathFollowerComponent.events.on(
      TilePathFollowerComponent.EventTypes.Started,
      (tilePath: TilePath) => {
        this.emit(IsometricCharacter.EventTypes.StartedPath, tilePath);
        this.setPlayerState(PlayerState.Walking);
      },
    );

    // For using the tooling
    this._isometricTilemap = scene;
  }

  public override addedToScene() {
    super.addedToScene();

    // Start out idling
    this.setPlayerState(PlayerState.Idle);
  }

  public follow(tilePath: TilePath): void {
    this.pathFollowerComponent.follow(tilePath, this.scene.cameras.main);
  }

  public setToTilePosition() {
    const point: Vector2 = this._isometricTilemap.worldPositionAtTilePosition(
      this.tilePosition,
    );
    if (point) {
      this.x = point.x;
      this.y = point.y;
    }
  }

  public teleport = () => {
    this.emit(IsometricCharacter.EventTypes.Teleported, this);
  };

  public override tick(deltaSeconds: number, deltaTime: number) {
    super.tick(deltaSeconds, deltaTime);

    if (!this.scene) {
      return;
    }

    const tilePosition = this._isometricTilemap.tilePositionAtCoordinates(
      this.x,
      this.y,
    );

    if (tilePosition) {
      if (!equals(tilePosition, this.tilePosition)) {
        this._previousTilePosition = this.tilePosition;
        this.tilePosition = tilePosition;

        this.emit(
          IsometricCharacter.EventTypes.MovedToTile,
          this.tilePosition,
          this._previousTilePosition,
        );
      }
    }
  }

  protected override onShutdown() {
    super.onShutdown();
    this.pathFollowerComponent.events.removeAllListeners();
  }

  private setPlayerState(state: PlayerState) {
    if (this.state === state) {
      return;
    }
    const previousState: PlayerState = this.state;
    this.state = state;
    // Make sure we don't do any unneeded updates - only update when we're walking
    switch (this.state) {
      case PlayerState.Idle:
        if (this.scene) {
          // Only unregister to save clock time if there's nothing else using it
          if (!this._hasTickEnabledComponents) {
            this.unregisterForTick();
          }
        }
        break;

      case PlayerState.Walking:
        this.registerForTick();
        break;
    }
    this.emit(
      IsometricCharacter.EventTypes.StateChanged,
      this,
      this.state,
      previousState,
    );
  }
}
