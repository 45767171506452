<script lang="ts" setup>
import { getCharacterAvatar } from "@overlay/assets";
import { ref, watch } from "vue";
import { getAvatarImageUrl } from "../../components/common/helpers";

const props = defineProps({
  value: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    default: "42px",
    validator(value: string) {
      return value.includes("px") || value.includes("%");
    },
  },
});
const avatarImageUrl = ref(getCharacterAvatar("Default Character"));
const isNftImage = ref(false);
watch(
  () => props.value,
  async (value) => {
    if (value) {
      const avatarImage = await getAvatarImageUrl(value);
      isNftImage.value = avatarImage.isNftImage;
      avatarImageUrl.value = avatarImage.imageUrl;
    }
  },
  { immediate: true },
);
</script>
<template>
  <span class="avatar pos--rel">
    <img :class="{ crop: isNftImage }" :src="avatarImageUrl" />
    <slot />
  </span>
</template>
<style lang="scss" scoped>
.avatar {
  width: v-bind("props.size");
  height: v-bind("props.size");
  overflow: hidden;

  img {
    width: 100%;
    &:not(.crop) {
      height: 100%;
    }

    &.crop {
      clip-path: circle(v-bind("props.size") at 50% 0);
    }
  }
}
</style>
