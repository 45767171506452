type TMinigameConfig = {
  MinPlayers: number;
  MaxPlayers: number;
  SceneKey: string;
  GameTitle: string;
};

export const MinigameValues: { [key: string]: TMinigameConfig } = {
  cryptobomberv2: {
    MinPlayers: 2,
    MaxPlayers: 4,
    SceneKey: "CryptoBomberScene",
    GameTitle: "Crypto Bomber",
  },
  memory: {
    MinPlayers: 2,
    MaxPlayers: 10,
    SceneKey: "MemoryGameScene",
    GameTitle: "Fruity Memory",
  },
  mines: {
    MinPlayers: 1,
    MaxPlayers: 1,
    SceneKey: "MightyMinesScene",
    GameTitle: "Mighty Mines",
  },
  plinko: {
    MinPlayers: 1,
    MaxPlayers: 1,
    SceneKey: "PlinkoScene",
    GameTitle: "Bit Plinko",
  },
  stampwars: {
    MinPlayers: 2,
    MaxPlayers: 4,
    SceneKey: "StampWarsScene",
    GameTitle: "Stamp Wars",
  },
  crash: {
    MinPlayers: 1,
    MaxPlayers: 1,
    SceneKey: "CrashScene",
    GameTitle: "Crash",
  },
};
