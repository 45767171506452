import EventEmitter from "eventemitter3";
import { CommunicatorEvents } from "@/shared/constants/CommunicatorEvents";
import { ISystemChatMessage } from "@/shared/interfaces/ISystemChatMessage";

namespace _Safe {
  export class Communicator extends EventEmitter {
    public sendSystemChat(message: ISystemChatMessage) {
      this.emit(CommunicatorEvents.SystemChat, message);
    }

    public sendSystemChatChecking() {
      this.emit(CommunicatorEvents.SystemChat, {
        text: "Let me check that for you..",
      });
    }
  }
}
export const Communicator = new _Safe.Communicator();
