import LocationMarker from "@engine/gameobjects/LocationMarker";
import { NPCInteractableEventTypes } from "@engine/eventtypes/NPCInteractableEventTypes";
import OrderableNonPlayerCharacter from "@engine/gameobjects/OrderableNonPlayerCharacter";
import { EnumGameEvents, TEventHandler, handleEvent } from "@shared/Events";
import SceneComponent from "../SceneComponent";
import EventEmitter = Phaser.Events.EventEmitter;
import Vector2 = Phaser.Math.Vector2;

export class LocationMarkerSceneComponent extends SceneComponent {
  private _locationMarker: Map<any, LocationMarker> = new Map<
    any,
    LocationMarker
  >();
  private _npcs: OrderableNonPlayerCharacter[] = [];
  private handlers: TEventHandler[];
  constructor() {
    super();
    this.isTickEnabled = true;
    this.isPreTickEnabled = true;
    this.handlers = [
      handleEvent(EnumGameEvents.GamePointToLocationMarker, (data) => {
        // npc indentifier
        if (typeof data === "number") {
          this._npcs.forEach((npc) => {
            if (npc.identifier === data) {
              this._locationMarker.set(
                data,
                new LocationMarker(
                  "/assets/game/onboarding/text_arrow_option_a_rasterized.png",
                  this.scene,
                  npc,
                  new Vector2(0, -180),
                ),
              );
            }
          });
          //actual location
        } else {
          this._locationMarker.set(
            JSON.stringify(data),
            new LocationMarker(
              "/assets/game/onboarding/text_arrow_option_a_rasterized.png",
              this.scene,
              data as { x: number; y: number },
              new Vector2(0, 25),
            ),
          );
        }
      }),
      handleEvent(EnumGameEvents.GameRemoveLocationMarker, (data) => {
        let res = this._locationMarker.get(data);
        if (res === undefined) {
          res = this._locationMarker.get(JSON.stringify(data));
        }
        if (res !== undefined) {
          res.destroy();
          this._locationMarker.delete(data);
        }
      }),
    ];
  }
  setNpcs(npcs: OrderableNonPlayerCharacter[], npcSpawnEmitter?: EventEmitter) {
    this._npcs = npcs;
    npcSpawnEmitter?.addListener(
      NPCInteractableEventTypes.OnSpawn,
      (npc: OrderableNonPlayerCharacter) => {
        this._npcs.forEach((npc) => {
          if (npc.identifier === npc.identifier) return;
        });
        this._npcs.push(npc);
      },
    );
  }

  public override tick(
    deltaSeconds: number,
    deltaTime: number,
    time?: number,
  ): void {
    this._locationMarker.forEach((marker) => marker.tick(deltaSeconds));
  }

  protected clear() {
    this.handlers.forEach((handler) => handler.destroy());
    this.handlers = [];
    this._npcs = [];
    this._locationMarker.clear();
  }
  protected onShutdown(): void {
    this.clear();
  }
  destroy(): void {
    this.clear();
  }
}
