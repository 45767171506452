import { Friends, Notification } from "@heroiclabs/nakama-js";
import NotificationCenter, {
  NotificationCenterUpdateTypes,
} from "../../networking/NotificationCenter";
import SceneComponent from "../SceneComponent";
import {
  EnumGameEvents,
  EnumNetworkEvents,
  EnumUiEvents,
  handleEvent,
  TEventHandler,
} from "@shared/Events";
import { dispatchEvent } from "@shared/Events";
import { IGeneralUtilityDelegate } from "@engine/interfaces/IGeneralUtilityDelegate";
import { Network } from "@network";
import { currentAccount } from "@shared/Global";
import { EnumChatEvents } from "@engine/interfaces/IChatMessageEvent";
import GameScene from "@engine/scenes/GameScene";
import { Toast } from "@engine/notifications/Toast";
import { IPrivateMetadata } from "@common/interfaces/IPrivateMetadata";

interface NotificationTicketTransfer extends Notification {
  content: {
    key: string;
  };
}

interface NotificationKarmaSent extends Notification {
  content: {
    key: string;
  };
}

export class GeneralUtilityComponent extends SceneComponent {
  private _notificationHandlers: Array<string> = [];
  private _eventHandlers: Array<TEventHandler> = [];
  public delegate: IGeneralUtilityDelegate;

  constructor() {
    super();
    this._eventHandlers.push(
      handleEvent(EnumUiEvents.UiGameAddFriend, async (toId: string) => {
        await this.delegate.onAddFriend(toId);
        dispatchEvent(EnumUiEvents.UiGameListFriends);
      }),
      handleEvent(EnumUiEvents.UiGameBlockFriend, async (toId: string) => {
        await this.delegate.onBlockFriend(toId);
        dispatchEvent(EnumUiEvents.UiGameListFriends);
      }),
      handleEvent(EnumUiEvents.UiGameListFriends, async () => {
        await this.listFriends();
        dispatchEvent(
          EnumGameEvents.GameUiListFriends,
          await this.listFriends(),
        );
      }),
      handleEvent(EnumUiEvents.UiGameRemoveFriend, async (toId: string) => {
        await this.delegate.onRemoveFriend(toId);
        dispatchEvent(EnumUiEvents.UiGameListFriends);
      }),
      handleEvent(EnumUiEvents.UiGameSearchFriend, (input: string) => {
        this.delegate.onSearchFriend(input);
      }),
      handleEvent(EnumUiEvents.UiGameSendKarma, (data: { toId: string }) => {
        this.delegate.onSendKarma(data.toId);
      }),
      handleEvent(EnumUiEvents.UIGamePushNotificationsList, async () => {
        const notifications = await this.listPushNotifications();
        dispatchEvent(
          EnumGameEvents.GameUIPushNotificationsRetrieved,
          notifications,
        );
      }),
      handleEvent(
        EnumUiEvents.UIGamePushNotificationsSave,
        async (
          notifications: IPrivateMetadata["pushNotificationAvailable"],
        ) => {
          const privateMetadata =
            await Network.Core.core.retrievePrivateMetadata();
          if (privateMetadata === null) return;
          await Network.Core.core.storePrivateMetadata({
            ...privateMetadata,
            pushNotificationAvailable: notifications,
          });
        },
      ),
    );

    NotificationCenter.instance.addListener<NotificationCenterUpdateTypes.RetrievedFriendsInvite>(
      NotificationCenterUpdateTypes.RetrievedFriendsInvite,
      (notification: Notification) => {
        this.captureFriendsInvite(notification);
      },
    ),
      this._notificationHandlers.push(
        NotificationCenterUpdateTypes.RetrievedFriendsInvite,
      );

    NotificationCenter.instance.addListener<NotificationCenterUpdateTypes.RetrievedFriendsAccept>(
      NotificationCenterUpdateTypes.RetrievedFriendsAccept,
      (notification: Notification) => {
        this.captureFriendsAccept(notification as NotificationTicketTransfer);
      },
    ),
      this._notificationHandlers.push(
        NotificationCenterUpdateTypes.RetrievedFriendsAccept,
      );

    NotificationCenter.instance.addListener<NotificationCenterUpdateTypes.ReceivedUserTickets>(
      NotificationCenterUpdateTypes.ReceivedUserTickets,
      (notification: Notification) => {
        this.captureTicketTransfer(notification as NotificationTicketTransfer);
      },
    ),
      this._notificationHandlers.push(
        NotificationCenterUpdateTypes.ReceivedUserTickets,
      );
    NotificationCenter.instance.addListener<NotificationCenterUpdateTypes.ReceivedKarma>(
      NotificationCenterUpdateTypes.ReceivedKarma,
      (notification: Notification) => {
        this.captureKarmaSent(notification as NotificationKarmaSent);
      },
    );
    this._notificationHandlers.push(
      NotificationCenterUpdateTypes.ReceivedKarma,
    );

    //Level
    NotificationCenter.instance.addListener<NotificationCenterUpdateTypes.RetrievedLevelExperience>(
      NotificationCenterUpdateTypes.RetrievedLevelExperience,
      (notification: Notification) => {
        this.captureLevelExperience(notification);
      },
    );
    this._notificationHandlers.push(
      NotificationCenterUpdateTypes.RetrievedLevelExperience,
    );

    NotificationCenter.instance.addListener<NotificationCenterUpdateTypes.RetrievedLevel>(
      NotificationCenterUpdateTypes.RetrievedLevel,
      (notification: Notification) => {
        this.captureLevel(notification);
      },
    );
    this._notificationHandlers.push(
      NotificationCenterUpdateTypes.RetrievedLevel,
    );
  }

  protected onSceneSet(scene?: GameScene): void {
    dispatchEvent(EnumUiEvents.UiGameListFriends);

    Network.Core.retrieveSecretMetadata().then((res: any) => {
      dispatchEvent(EnumGameEvents.GameUiInitialLevelData, res.level);
    });
  }
  public async addFriend(userId: string): Promise<boolean> {
    return Network.Core.addFriend(userId);
  }

  public async blockFriend(userId: string): Promise<boolean> {
    return Network.Core.blockFriend(userId);
  }

  public async removeFriend(userId: string): Promise<boolean> {
    return Network.Core.removeFriend(userId);
  }

  public async listFriends(): Promise<Friends> {
    return Network.Core.listFriends();
  }

  public async searchFriend(input: string): Promise<void> {
    const result: Array<{
      id: string;
      username: string;
      metadata: { activeCharacter: { [key: string]: string } };
    }> = await Network.Core.searchFriend(input);
    dispatchEvent(EnumGameEvents.GameUiSearchFriend, result);
  }

  public async captureFriendsInvite(notification: Notification) {
    dispatchEvent(EnumUiEvents.UiGameListFriends);
    Toast.info(notification.subject);
  }

  public async captureFriendsAccept(notification: Notification) {
    dispatchEvent(EnumUiEvents.UiGameListFriends);
  }

  public async captureTicketTransfer(notification: NotificationTicketTransfer) {
    dispatchEvent(EnumGameEvents.ReceivedUserTicketsDone, {
      key: notification.content.key,
    });
  }

  public async captureKarmaSent(notification: NotificationKarmaSent) {
    //@todo user where?
    dispatchEvent(EnumGameEvents.GameUiReceivedKarma, {
      key: notification.content.key,
    });
    dispatchEvent(EnumNetworkEvents.NetworkRequestUserData);
  }

  public async captureLevelExperience(notification: Notification) {
    dispatchEvent(EnumGameEvents.GameUiLevelExperience, notification.content);
  }

  public async captureLevel(notification: Notification) {
    dispatchEvent(EnumGameEvents.GameUiLevel, notification.content);
    dispatchEvent(EnumNetworkEvents.NetworkRequestUserData);
  }

  public async sendKarma(toId: string) {
    const res = await Network.Core.sendKarma(toId);
    const payload = res.payload as { success: boolean; message?: string };
    const playerData = (await Network.Core.getUsersData(toId))[0];
    if (payload.success) {
      const content = {
        timestamp: Date.now(),
        message: `Karma sent to ${playerData.username}`,
        karma: currentAccount.user.metadata.karma
          ? currentAccount.user.metadata.karma
          : 0,
        eventType: EnumChatEvents.ChatTyping,
      };
      dispatchEvent(EnumUiEvents.UiChatMessage, { message: { content } });
      dispatchEvent(EnumUiEvents.UiGameUpdateRemotePlayerData, {
        openModal: true,
        id: toId,
      });
    } else {
      Toast.error(payload.message);
      return;
    }
  }

  public async listPushNotifications(): Promise<
    IPrivateMetadata["pushNotificationAvailable"] | undefined
  > {
    const privateMetadata = await Network.Core.core.retrievePrivateMetadata();
    if (privateMetadata === null) return undefined;
    return privateMetadata.pushNotificationAvailable;
  }

  protected override onShutdown() {
    super.onShutdown();
    for (const eventHandler of this._eventHandlers) {
      eventHandler.destroy();
    }
    this._notificationHandlers.forEach((handler) => {
      NotificationCenter.instance.removeListener(handler);
    });
    delete this._notificationHandlers;
  }
}
