declare type Vector3 = { x: number; y: number; z: number };

export class Graph<T> {
  allNodes: GraphNode<T>[] = [];
  nodes: GraphNode<T>[];

  constructor(
    public width: number,
    public depth: number,
    public height: number,
    private _numberOfNeighbours: number = 4,
  ) {
    this.nodes = new Array<GraphNode<T>>(width * depth * height);
  }

  public add(x: number, y: number, z: number, value: T) {
    // Calculate the 1d index
    const index = this.index(x, y, z);

    // Place the node within the internal map
    const node = new GraphNode<T>(
      value,
      x,
      y,
      z,
      index,
      this._numberOfNeighbours,
    );

    // Store it in an easily accessible way
    this.nodes[index] = node;

    // Store it in a nicely iterable way
    this.allNodes.push(node);
  }

  public linkNeighbourAtIndexForDelta(
    node: GraphNode<T>,
    index: number,
    delta: Vector3,
  ): GraphNode<T> {
    // Move over the grid
    const deltadX: number = node.x + delta.x;
    const deltadY: number = node.y + delta.y;
    const deltadZ: number = node.z + delta.z;

    // Check if it's a valid position and also make sure we don't flip around
    if (this.isValidPosition(deltadX, deltadY, deltadZ)) {
      return null;
    }

    // Check if it exists
    const neighbour: GraphNode<T> = this.get(deltadX, deltadY, deltadZ);
    if (!!neighbour) {
      node.setNeighbour(index, neighbour);
      return neighbour;
    }

    return null;
  }

  public linkNeighbourForDeltas(node: GraphNode<T>, deltas: Vector3[]) {
    deltas.forEach((delta, index) => {
      this.linkNeighbourAtIndexForDelta(node, index, delta);
    });
  }

  public linkAllNeighboursForDeltas(deltas: Vector3[]) {
    // Iterate every node, and get corresponding neighbouring nodes
    this.allNodes.forEach((node) => {
      this.linkNeighbourForDeltas(node, deltas);
    });
  }

  public get(x: number, y: number, z: number): GraphNode<T> {
    return this.nodes[this.index(x, y, z)];
  }

  public getAtPosition(position: Vector3): GraphNode<T> {
    return this.get(position.x, position.y, position.z);
  }

  public getValue(x: number, y: number, z: number): T {
    return this.nodes[this.index(x, y, z)].value;
  }

  public has(x: number, y: number, z: number): boolean {
    return this.nodes[this.index(x, y, z)] !== undefined;
  }

  public index(x: number, y: number, z: number): number {
    return z * this.width * this.depth + y * this.width + x;
  }

  public isValidPosition(x: number, y: number, z: number): boolean {
    return (
      x < 0 ||
      x > this.width - 1 ||
      y < 0 ||
      y > this.depth - 1 ||
      z < 0 ||
      z > this.height - 1
    );
  }

  public print() {
    console.log("Contains " + this.allNodes.length + " tiles");
  }

  public remove(x: number, y: number, z: number, value: T) {
    const index = this.index(x, y, z);
    const node = this.nodes[index];

    if (node && node.value === value) {
      this.nodes[index] = undefined;
      this.allNodes.splice(this.allNodes.indexOf(node), 1);
    }
  }
}

export class GraphNode<T> {
  public neighbours: GraphNode<T>[];
  public position: Vector3;

  constructor(
    public value: T,
    public x: number,
    public y: number,
    public z: number,
    public index: number,
    numberOfNeighbours: number = 4,
  ) {
    this.neighbours = new Array<GraphNode<T>>(numberOfNeighbours);
    this.position = { x: x, y: y, z: z };
  }

  public hasEqualPosition(otherPosition: {
    x: number;
    y: number;
    z: number;
  }): boolean {
    return (
      this.position.x === otherPosition.x &&
      this.position.y === otherPosition.y &&
      this.position.z === otherPosition.z
    );
  }

  public hasNeighbour(index: number): boolean {
    return !!this.neighbours[index];
  }

  public setNeighbour(index: number, neighbour: GraphNode<T>) {
    this.neighbours[index] = neighbour;
  }
}
