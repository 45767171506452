export default [
  {
    address: ["0xaC34c5Fa400414cCE820b584164f43E799b9EC65"],
    type: "furniture",
    metadata: {
      name: "Farmertruck",
      description:
        "The largest furniture in the Bit Hotel, a spooky truck full of pumpkins to park in your room. If you ever want to create a spooky room or a farm themed room this truck cannot be missed.",
      attributes: [
        { trait_type: "Rarity", value: "Epic" },
        { trait_type: "Replicas", value: "10" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "farmertruck_2x3x2_1",
    },
  },
  {
    address: ["0x5254AddfFb796B85Db6973E28ABf008C588f4215"],
    type: "furniture",
    metadata: {
      name: "Tall Autumn Tree",
      description:
        "A beautiful tall autumn tree that can create a great natural setting in your room.",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "40" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "tall_autumn_tree_yellow_a",
    },
  },
  {
    address: ["0x2d7d28D89241700e8562b608efDab7479cC958e5"],
    type: "furniture",
    metadata: {
      name: "Stacked Pumpkins",
      description:
        "A large stack of pumpkins to use in your halloween or autumn themed room setup.",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "50" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "stackedpumpkins_1x1x2_1",
    },
  },
  {
    address: ["0x55f291721aFD041A3B0B90E964C7893999d0635d"],
    type: "furniture",
    metadata: {
      name: "Ping Pong Table",
      description:
        "One of the most precious mini-game furniture of the arcade collection. Play a game of ping pong directly from your room with hidden advantages by placing this furniture down on the ground. Only very few Hotel guests own any item of the arcade machine collection.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "60" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "pingpongtable_2x3x2_1",
    },
  },
  {
    address: ["0x0b891A25D2bF47E887b39c88850a20e39513401A"],
    type: "furniture",
    metadata: {
      name: "Kitchen Counter",
      description:
        "A cute little kitchen counter to cook dinner with your friends. Part of the modern kitchen furniture collection this kitchen appliance is one of the more rare parts of the hotel room kitchens due to its scarcity.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "100" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "moderncounterwhiteblack_1x1x2_6",
    },
  },
  {
    address: ["0x9a8b1FE31BE7eF27C4321601EbFF5633b1Fb3A22"],
    type: "furniture",
    metadata: {
      name: "Kitchen Sideboard",
      description:
        "A cute little kitchen cabinet to put your plates and glasses for when your friends come over for dinner. Part of the modern kitchen furniture collection this kitchen appliance is one of the more rare parts of the hotel room kitchens due to its scarcity.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "300" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "kitchensideboardblack_3x1x2_1",
    },
  },
  {
    address: ["0xFECa36AB08E89196387eA395Ac657150E11d5A76"],
    type: "furniture",
    metadata: {
      name: "Kitchen Counter Corner",
      description:
        "A corner piece needed to enlarge your kitchen counter and to cook dinner with your friends. Part of the modern kitchen furniture collection this kitchen appliance is a well-known and good-looking part of most hotel guests' inventory.",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "500" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "moderncountercornerblackwhite_1x1x2_4",
    },
  },
];
