import { Emote } from "@engine/gameobjects/emotes/Emote";
import { IDataObject } from "@engine/interfaces/IDataObject";
import Entity from "@engine/Entity";
import { IIdentifiableAsset } from "@engine/objects/DynamicLoader";
import GameScene from "@engine/scenes/GameScene";
import Sprite = Phaser.GameObjects.Sprite;
import PlayAnimationConfig = Phaser.Types.Animations.PlayAnimationConfig;
import ANIMATION_COMPLETE = Phaser.Animations.Events.ANIMATION_COMPLETE;

export class AnimatedEmote extends Emote {
  public static AvailableAnimatedEmotes: string[] = [
    "blush",
    "cry",
    "kiss",
    "rage",
    "raincloud",
    "smile",
    "surprised",
    "zzz",
  ];
  private _sprite: Sprite | null;

  constructor(
    identifier: string,
    executor: Entity,
    data: IDataObject,
    showCase: boolean = false,
  ) {
    super(identifier, executor, data);

    const { scene, x, y } = executor;
    const key = "emote_" + identifier;
    const url = "assets/game/emotes/atlases/emote-" + identifier;
    executor.scene.dynamicLoadAssetIfNotLoadingOrLoaded(
      {
        type: "atlas",
        key,
        textureURL: url + ".png",
        atlasURL: url + ".json",
      },
      (key: string) => {
        this._sprite = new Sprite(scene, x, showCase ? y : y - 160, key, 0)
          .setDepth(999)
          .setOrigin(0.5);

        if (!showCase) {
          this._sprite.setScale(0);
        }

        // Create (if needed) then play the animation
        const animationKey = key + "_animation";
        let animation = scene.anims.get(animationKey);
        if (!animation) {
          const possibleAnimation = scene.anims.create({
            key: animationKey,
            frames: scene.anims.generateFrameNames(key),
            frameRate: 14,
          });
          if (possibleAnimation) {
            animation = possibleAnimation;
          }
        }
        animation.repeat = showCase ? -1 : 4;
        scene.anims.play(animationKey, this._sprite);

        // Show the emote
        if (!showCase) {
          const duration = 128;
          scene.tweens.add({
            duration,
            ease: "Back.easeOut",
            targets: this._sprite,
            props: {
              scaleX: 1,
              scaleY: 1,
            },
          });

          // Once it is done, hide it again
          this._sprite.on(
            ANIMATION_COMPLETE,
            (animationConfiguration: PlayAnimationConfig) => {
              // if (++counter === animationConfiguration.repeat - 1) {
              // One more loop
              scene.tweens.add({
                duration,
                ease: "Back.easeIn",
                targets: this._sprite,
                props: {
                  scaleX: 0,
                  scaleY: 0,
                },
                onComplete: () => {
                  // Clean up!
                  this.destroy();
                },
              });
              // }
            },
          );
        }

        // Add it to the scene
        scene.add.existing(this._sprite);
      },
    );
  }

  public static PreloadAllAnimatedEmotes(
    scene: GameScene,
    onComplete: () => void = () => {},
  ) {
    const assets: IIdentifiableAsset[] = [];
    for (const identifier of AnimatedEmote.AvailableAnimatedEmotes) {
      const key = "emote_" + identifier;
      const url = "assets/game/emotes/emote-" + identifier;
      assets.push({
        type: "atlas",
        key,
        textureURL: url + ".png",
        atlasURL: url + ".json",
      });
    }

    scene.dynamicLoadAssetsIfNotLoaded(assets, onComplete);
  }

  public destroy() {
    this._sprite.destroy(true);
  }

  public getSprite(): Sprite {
    return this._sprite;
  }
}
