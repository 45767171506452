import Container = Phaser.GameObjects.Container;
import TextStyle = Phaser.GameObjects.TextStyle;
import { NineSlice } from "phaser3-nineslice";
import { Scene } from "phaser";
import OverlayScene, {
  ScreenAnchorPosition,
} from "@engine/scenes/OverlayScene";
import Text = Phaser.GameObjects.Text;
import Image = Phaser.GameObjects.Image;
import Tween = Phaser.Tweens.Tween;

const DEFAULT_TEXT_STYLE = {
  align: "center",
  fontFamily: "Depixel",
  fontSize: "14px",
};

const TEXT_COLOR = {
  error: "#F55157",
  info: "#00BDEB",
  success: "#0DCF57",
  warning: "#F0D450",
};

const NOTIFICATION_DURATION = 5000;
const MAX_ICON_SIZE = 80;

enum ToastType {
  Error = "error",
  Info = "info",
  Success = "success",
  Warning = "warning",
}

class ToastNotification extends Container {
  private _background: NineSlice;
  private _icon: Image;
  private _moveTween: Tween;
  private _text: Text;

  constructor(
    scene: Scene,
    type: ToastType = ToastType.Info,
    text: string,
    iconTexture?: string,
    iconFrame?: string,
    color?: number,
  ) {
    super(scene);

    this._background = scene.add
      .nineslice(
        0,
        0,
        300,
        140,
        {
          key: "common_atlas",
          frame: `toast_${type}_9s_f`,
        },
        21,
      )
      .setOrigin(0.5, 0.5);

    this.scale = 0;
    this.alpha = 0;

    this._text = new Text(scene, 0, 0, text, DEFAULT_TEXT_STYLE).setOrigin(0.5);
    this._text.setWordWrapWidth(iconTexture ? 250 : 280);
    this._text.setColor(TEXT_COLOR[type]);

    this.add(this._background);
    this.add(this._text);

    if (iconTexture) {
      this._icon = new Image(scene, -120, 0, iconTexture, iconFrame);
      this._text.x += 20;

      const iconMaxDimension = Math.max(this._icon.width, this._icon.height);
      if (iconMaxDimension > MAX_ICON_SIZE) {
        this._icon.setScale(MAX_ICON_SIZE / iconMaxDimension);
      }

      this.add(this._icon);
    }

    if (color) {
      this._background.tint = color;
    }
  }

  public moveVertically(y: number) {
    if (this._moveTween) {
      this._moveTween.stop();
    }

    this._moveTween = this.scene.tweens.add({
      targets: this,
      ease: "Sine.easeOut",
      duration: 500,
      y: y,
    });
  }

  public show() {
    this.scene.tweens.add({
      targets: this,
      alpha: 1,
      ease: "Sine.easeOut",
      duration: 300,
    });
    this.scene.tweens.add({
      targets: this,
      scale: 1,
      ease: "Back.easeOut",
      duration: 500,
    });
  }

  public async hide() {
    return new Promise((resolve) => {
      this.scene.tweens.add({
        targets: this,
        scale: 0.8,
        alpha: 0,
        ease: "Sine.easeOut",
        duration: 400,
        onComplete: resolve,
      });
    });
  }
}

class ToastManager {
  private _container: Container;
  private _notifications: ToastNotification[] = [];
  private _scene: OverlayScene;

  public error(message: string) {
    this.info(message, ToastType.Error, "common_atlas", "toast_error_icon_sml");
  }

  public initialize(scene: OverlayScene) {
    this._scene = scene;
    this._container = new Phaser.GameObjects.Container(scene);

    this._scene.addAnchoredObject(this._container, {
      x: { anchor: ScreenAnchorPosition.Edge, value: -180 },
      y: { anchor: ScreenAnchorPosition.Edge, value: -100 },
    });
  }

  public info(
    message: string,
    type: ToastType = ToastType.Info,
    iconTexture: string = "common_atlas",
    iconFrame: string = "toast_info_icon_sml",
    textStyle?: TextStyle,
    color?: number,
  ) {
    const notification = new ToastNotification(
      this._scene,
      type,
      message,
      iconTexture,
      iconFrame,
      textStyle,
      color,
    );
    notification.y = 100;

    this._container.add(notification);
    this._notifications.unshift(notification);

    notification.show();

    this.repositionNotifications();

    this._scene.time.delayedCall(
      NOTIFICATION_DURATION,
      () => {
        const notification = this._notifications.pop();

        notification.hide().then(() => notification.destroy());

        this.repositionNotifications();
      },
      undefined,
      this,
    );
  }

  private repositionNotifications() {
    this._notifications.forEach((notification, index) => {
      notification.moveVertically(-index * 150);
    });
  }

  public success(message: string) {
    this.info(
      message,
      ToastType.Success,
      "common_atlas",
      "toast_success_icon_sml",
    );
  }

  public warning(message: string) {
    this.info(
      message,
      ToastType.Warning,
      "common_atlas",
      "toast_warning_icon_sml",
    );
  }
}

const Toast = new ToastManager();
export { Toast };
