import { Session } from "./index";
import Config from "../Config";
import { isNativeMobileApp } from "../../../mobile/isOnIOS";
import {
  mobileRetrieveValue,
  mobileStoreValue,
} from "../../../mobile/postMessage";
import { EnumApplicationEvents, handleEvent } from "@shared/Events";
import { EventType } from "../../../mobile/types";

const LOCAL_STORAGE_PREPEND_KEY =
  Config.getConfig("nakama").LOCAL_STORAGE_PREPEND_KEY;
const LOCAL_STORAGE_AUTH_TOKEN_KEY =
  LOCAL_STORAGE_PREPEND_KEY +
  Config.getConfig("nakama").LOCAL_STORAGE_AUTH_TOKEN_KEY;
const LOCAL_STORAGE_REFRESH_TOKEN_KEY =
  LOCAL_STORAGE_PREPEND_KEY +
  Config.getConfig("nakama").LOCAL_STORAGE_REFRESH_TOKEN_KEY;

export async function getLocalSession(): Promise<{
  authToken: string | null;
  refreshToken: string | null;
}> {
  if (!isNativeMobileApp()) {
    const authToken = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY);
    const refreshToken = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
    return { authToken, refreshToken };
  } else {
    return new Promise((resolve, reject) => {
      handleEvent(
        EnumApplicationEvents.ApplicationValueRetrieved,
        (data: { key: string; value: string }) => {
          if (data.key === "session_data") {
            if (!data.value || data.value === "null") {
              resolve({ authToken: null, refreshToken: null });
            } else {
              const authToken = data.value.split("_____")[0];
              const refreshToken = data.value.split("_____")[1];
              resolve({
                authToken: authToken,
                refreshToken: refreshToken,
              });
            }
          }
        },
      );
      setTimeout(reject, 500);
      mobileRetrieveValue("session_data");
    });
  }
}

export function storeLocalSession(session: Session) {
  if ("token" in session && "refresh_token" in session) {
    if (!isNativeMobileApp()) {
      localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, session.token);
      localStorage.setItem(
        LOCAL_STORAGE_REFRESH_TOKEN_KEY,
        session.refresh_token,
      );
    } else {
      const saveData = session.token + "_____" + session.refresh_token;

      mobileStoreValue("session_data", saveData);
    }
  }
}

export function clearLocalSession() {
  if (!isNativeMobileApp()) {
    localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY);
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);
  } else {
    mobileStoreValue("session_data", "");
  }
}
