export default [
  {
    address: ["0xC828F765c088323598Cdf4B5D0eDB46B2D7F0E9e"],
    type: "furniture",
    metadata: {
      name: "Jukebox",
      description:
        "This trendy jukebox is a fun way to entertain guests in your room. With numerous songs to choose from it allows music lovers the versatility to throw any kind of party in their room. Only very few hotel guests own a jukebox making it a very sought after item in the hotel.",
      attributes: [
        { trait_type: "Rarity", value: "Epic" },
        { trait_type: "Replicas", value: "10" },
        { trait_type: "Drop", value: "Season 4" },
      ],
      texture: "jukeboxorange_1x1x2_1",
    },
  },

  {
    address: ["0x82cd9429D9B689894a03AB676D3499936B979494"],
    type: "furniture",
    metadata: {
      name: "Smart Toaster",
      description:
        "Who doesn't like a good toast? With this addition to your kitchen furniture you are sure to make a great breakfast. With its smart functions and trendy color this toaster is a relatively rare item amongst hotel guests.",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "40" },
        { trait_type: "Drop", value: "Season 4" },
      ],
      texture: "smarttoasterpink_1x1x2_14",
      disabled: true,
    },
  },

  {
    address: ["0x5B2787AC89c2535E426054a0CD0A91763646cb58"],
    type: "furniture",
    metadata: {
      name: "Cat Tree",
      description:
        "A large house for your Bit Hotel pet that not many hotel guests can afford. A super rare cat tree that makes a little palace for your four legged friend. A very in demand item amongst cat lovers in the Bit Hotel.",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "50" },
        { trait_type: "Drop", value: "Season 4" },
      ],
      texture: "cattreeyellowpink_2x1x3_1",
    },
  },

  {
    address: ["0xA90cd799283DD41719c8919a179C23A09F2bb4c6"],
    type: "furniture",
    metadata: {
      name: "Speaker Stand",
      description:
        "Want to throw a party in your room? This speaker stand allows you to organize a fun music event or rave in your room and is part of the music furniture collection. In high demand under most hotel guests.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "60" },
        { trait_type: "Drop", value: "Season 4" },
      ],
      texture: "speakerstandpurple_1x1x2_2",
    },
  },

  {
    address: ["0x85a3c14ADa2572a593B282FfB2A6e7151Cd5f68D"],
    type: "furniture",
    metadata: {
      name: "Controller Table",
      description:
        "A stylish purple gaming inspired table to add to your gaming furniture collection. Among the more rare items in the gaming furniture set that are in very high demand with Bit Hotel guests due to its scarcity ",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "80" },
        { trait_type: "Drop", value: "Season 4" },
      ],
      texture: "gamecontrollertablepurple_2x2x2_1",
    },
  },

  {
    address: ["0x1Ef0d2B7c0E56EA88C8Fb14A2bC7CCC03b0D1fFf"],
    type: "furniture",
    metadata: {
      name: "Teal Gaming Chair",
      description:
        "One of the gaming chairs that is commonly used in the gaming setup of many hotel guests. A fun and colorful part of the gaming furniture collection.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "100" },
        { trait_type: "Drop", value: "Season 4" },
      ],
      texture: "gamerchairwhiteteal_1x1x2_1",
    },
  },

  {
    address: ["0x270622b8789dB1e2cf17D83F39Bd2cb542600378"],
    type: "furniture",
    metadata: {
      name: "Long Bookshelf",
      description:
        "A high and moderb bookshelf you can use to store your everyday items. A common appearance in Bit Hotel rooms due to its ease of use and utility.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "300" },
        { trait_type: "Drop", value: "Season 4" },
      ],
      texture: "longbookshelfgreen_1x1x3_1",
    },
  },

  {
    address: ["0x440a902191B2F1A3d7310C1cF8F110c7031288C0"],
    type: "furniture",
    metadata: {
      name: "Pet Scratching Ball",
      description:
        "A small and simple toy for your Bit Hotel pet, allows your little buddy to scratch its claws and play around. Relatively common item among pet owners.",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "500" },
        { trait_type: "Drop", value: "Season 4" },
      ],
      texture: "petscratchingballteal_1x1x2_1",
    },
  },
];
