import Image = Phaser.GameObjects.Image;
import Timeline = Phaser.Tweens.Timeline;
import Tween = Phaser.Tweens.Tween;
import GameObject = Phaser.GameObjects.GameObject;
import Vector2Like = Phaser.Types.Math.Vector2Like;
import Vector2 = Phaser.Math.Vector2;
import Sprite = Phaser.GameObjects.Sprite;
import TweenManager = Phaser.Tweens.TweenManager;
import TweenBuilderConfig = Phaser.Types.Tweens.TweenBuilderConfig;

function arrayify(targets) {
  return Array.isArray(targets) ? targets : [targets];
}

export function appear(
  tweens: TweenManager,
  targets: Image | Image[] | Sprite | Sprite[],
  startInvisible: boolean = false,
  duration: number = 512,
): Tween[] {
  return [
    bounce(tweens, targets, true, duration),
    fadeIn(tweens, targets, true, duration),
  ];
}

export function bounce(
  tweens: TweenManager,
  targets: Image | Image[],
  startInvisible: boolean = false,
  duration: number = 2048,
): Tween {
  // Check if we should make the targets invisible first
  if (startInvisible) {
    targets = arrayify(targets);

    // Set the alpha to 0
    targets.forEach((target) => {
      target.setAlpha(0);
      target.setScale(0);
    });
  }

  return tweens.add({
    duration: duration,
    ease: "Bounce.easeOut",
    targets: targets,
    props: {
      scaleX: 1,
      scaleY: 1,
    },
  });
}

export function collect(
  tweens: TweenManager,
  targets: GameObject | GameObject[],
  destination: Vector2Like,
  keepFollowingDestination: boolean = true,
  duration: number = 512,
  offset: Vector2 = Vector2.ZERO,
): Tween {
  const tweenConfiguration: TweenBuilderConfig = {
    ease: "Cubic.easeIn",
    duration: duration,
    props: {
      alpha: 0,
      scaleX: 0,
      scaleY: 0,
      x: destination.x,
      y: destination.y,
    },
    targets: targets,
  };
  if (keepFollowingDestination) {
    tweenConfiguration.onUpdate = (tween: Tween) => {
      tween.updateTo("x", destination.x + offset.x);
      tween.updateTo("y", destination.y + offset.y);
    };
  }
  return tweens.add(tweenConfiguration);
}

export function fadeIn(
  tweens: TweenManager,
  targets: Image | Image[],
  startInvisible: boolean = false,
  duration: number = 512,
): Tween {
  // Check if we should make the targets invisible first
  if (startInvisible) {
    targets = arrayify(targets);

    // Set the alpha to 0
    targets.forEach((target) => {
      target.setAlpha(0);
    });
  }

  return tweens.add({
    duration: duration,
    targets: targets,
    props: {
      alpha: 1,
    },
  });
}

export function scaleAndFadeOut(
  tweens: TweenManager,
  targets: GameObject | GameObject[],
  duration: number = 512,
): Tween {
  return tweens.add({
    targets: targets,
    props: {
      alpha: 0,
      scaleX: 0,
      scaleY: 0,
    },
    ease: "Back.easeIn",
    duration: duration,
  });
}

export function scaleOut(
  tweens: TweenManager,
  targets,
  duration: number = 512,
): Tween {
  return tweens.add({
    duration: duration,
    ease: "Back.easeIn",
    targets: targets,
    props: {
      scaleX: 0,
      scaleY: 0,
    },
  });
}

export function squash(
  tweens: TweenManager,
  targets,
  scaleY: number = 1.1,
): Timeline {
  return tweens.timeline({
    targets: targets,
    tweens: [
      {
        duration: 128,
        props: {
          scaleY: scaleY,
        },
      },
      {
        duration: 2048,
        ease: "Elastic.easeOut",
        props: {
          scaleY: 1,
        },
      },
    ],
  });
}

export function stretch(
  tweens: TweenManager,
  targets,
  scaleX: number = 1.1,
): Timeline {
  return tweens.timeline({
    targets: targets,
    tweens: [
      {
        duration: 128,
        props: {
          scaleX: scaleX,
        },
      },
      {
        duration: 2048,
        ease: "Elastic.easeOut",
        props: {
          scaleX: 1,
        },
      },
    ],
  });
}

export function twitch(tweens: TweenManager, targets): Timeline {
  return tweens.timeline({
    targets: targets,
    tweens: [
      {
        duration: 128,
        props: {
          scaleX: 1.1,
          scaleY: 0.9,
        },
      },
      {
        duration: 2048,
        ease: "Elastic.easeOut",
        props: {
          scaleX: 1,
          scaleY: 1,
        },
      },
    ],
  });
}
