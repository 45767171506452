import { defineStore } from "pinia";
import { TItemTile } from "@overlay/types";
import { TNakamaUser } from "@shared/types/nakama";
import { ITask } from "@engine/components/scenecomponents/tasks/Task";
import { EventEmitter } from "events";
import { TGameUiNpcInteraction } from "@shared/types";
//TODO(temp): Move this to where it belongs
export interface OnboardingPageData extends ITask {
  emitter: EventEmitter;
  textSpeed: number;
}
export const useModalDisplayDataStore = defineStore("uiModalDisplayData", {
  state: () => ({
    selectedStoreItem: {} as TItemTile,
    selectedStoreTheme: {} as { name?: string; theme?: string },
    selectedStorePage: 0,
    remotePlayerInfo: {} as TNakamaUser,
    onboardingPageData: {} as OnboardingPageData,
    npcInteractionData: {} as TGameUiNpcInteraction,
  }),
  getters: {},
  actions: {},
});
