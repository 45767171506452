declare type Vector3 = { x: number; y: number; z: number };

export enum Direction {
  North,
  East,
  South,
  West,
  NorthEast,
  SouthEast,
  SouthWest,
  NorthWest,
  None = -1,
}

export const DirectionDeltas: Vector3[] = [
  { x: 0, y: -1, z: 0 },
  { x: 1, y: 0, z: 0 },
  { x: 0, y: 1, z: 0 },
  { x: -1, y: 0, z: 0 },
  { x: 1, y: -1, z: 0 },
  { x: 1, y: 1, z: 0 },
  { x: -1, y: 1, z: 0 },
  { x: -1, y: -1, z: 0 },
];

export const DirectionElevationDeltas: Vector3[] = [
  { x: 0, y: -1, z: 1 }, // ok
  { x: 1, y: 0, z: -1 }, // MARKTWAIN
  { x: 1, y: 0, z: 1 },
  { x: 0, y: 1, z: -1 }, // ok
  { x: -1, y: 0, z: 1 }, // MARKTWAIN
  { x: -1, y: 0, z: -1 },
];

export const DirectionDeltasIncludingElevation: Vector3[] = []
  .concat(DirectionDeltas)
  .concat(DirectionElevationDeltas);

// Only
DirectionDeltasIncludingElevation.concat(DirectionElevationDeltas);

export const Directions: Direction[] = [
  Direction.North,
  Direction.East,
  Direction.South,
  Direction.West,
  Direction.NorthEast,
  Direction.SouthEast,
  Direction.SouthWest,
  Direction.NorthWest,
];

export const DirectionsCardinal: Direction[] = Directions.slice(0, 4);

export const DirectionsInOrder: Direction[] = [
  Direction.North,
  Direction.NorthEast,
  Direction.East,
  Direction.SouthEast,
  Direction.South,
  Direction.SouthWest,
  Direction.West,
  Direction.NorthWest,
];

export const NameToDirection: { [name: string]: Direction } = {
  North: Direction.North,
  East: Direction.East,
  South: Direction.South,
  West: Direction.West,
  NorthEast: Direction.NorthEast,
  SouthEast: Direction.SouthEast,
  SouthWest: Direction.SouthWest,
  NorthWest: Direction.NorthWest,
  None: Direction.None,
};

export const DirectionToString: string[] = [
  "North",
  "East",
  "South",
  "West",
  "NorthEast",
  "SouthEast",
  "SouthWest",
  "NorthWest",
  "None",
];

export const NumberOfDirections = 8;

export const OppositeDirection: Direction[] = [
  Direction.South,
  Direction.West,
  Direction.North,
  Direction.East,
];
