<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>
<script setup lang="ts">
import { useStores } from "@store";
import { extractCSSClasses } from "@uicomps/common/helpers";
const { general: uiStore } = useStores();
</script>
<template>
  <template v-if="uiStore.isTouchDevice">
    <div
      class="touch-wrapper"
      :class="extractCSSClasses($attrs.class as string, 'touch-wrapper:')"
      @click="$emit('click', $event)"
    >
      <button
        v-depixel-outlined
        :class="extractCSSClasses($attrs.class as string, '!touch-wrapper:')"
        :type="($attrs.type as 'button'|'submit') || 'button'"
      >
        <slot name="button-before"></slot>
        <slot><span></span></slot>
        <slot name="button-after"></slot>
      </button>
    </div>
  </template>
  <template v-else>
    <button
      type="button"
      v-depixel-outlined
      :class="extractCSSClasses($attrs.class as string, 'touch-wrapper:')"
    >
      <slot name="button-before"></slot>
      <span><slot></slot></span>
      <slot name="button-after"></slot>
    </button>
  </template>
</template>
<style scoped lang="scss">
$touch-margin: get-size(xxsm);
.touch-wrapper:deep() {
  position: relative;

  button {
    &::before {
      content: "";
      position: absolute;
      left: -#{$touch-margin};
      top: -#{$touch-margin};
      width: calc(100% + $touch-margin * 2);
      height: calc(100% + $touch-margin * 2);
      cursor: pointer;
    }
    img {
      max-height: 100%;
      top: unset;
    }
  }
}
</style>
