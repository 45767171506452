import FurnitureObject from "@game/engine/components/scenecomponents/furniture/furniture-objects/FurnitureObject";
import CustomizableRoomScene from "@game/mini/room/CustomizableRoomScene";
import { TInteractiveFurnitureConfig } from "@shared/consts/InteractiveFurniture";
import { IInteractiveFurnitureUseResponse } from "@common/modules/rooms/interfaces/FurnitureOperations";

export default class InteractiveFurnitureObject extends FurnitureObject {
  public get activated(): boolean {
    return this._activated;
  }

  readonly interactive: boolean = true;
  readonly offlineActivation: boolean = false;

  protected _activated: boolean = false;
  protected _config: TInteractiveFurnitureConfig;

  constructor(
    scene: CustomizableRoomScene,
    name: string,
    id: string,
    config: TInteractiveFurnitureConfig,
  ) {
    super(scene, name, id);

    this._config = config;
  }

  public processActivationResponse(
    response: IInteractiveFurnitureUseResponse,
  ) {}

  public setState(state: number) {}
}
