import { TilePosition3D } from "@game/engine/navigation/Pathfinder";

export interface PropertyContainer {
  properties?: LayerDataProperty[] | object[] | object;
}

interface LayerDataProperty {
  name: string;
  type: string;
  value: any;
}

export function getTiledTilePositionFromProperties(
  data: PropertyContainer,
  fallback = null,
): TilePosition3D {
  const positionX = getTiledProperty(data, "PositionX");
  const positionY = getTiledProperty(data, "PositionY");
  const positionZ = getTiledProperty(data, "PositionZ");

  if (null === positionX || null === positionY || null === positionZ) {
    return fallback;
  }

  return { x: positionX, y: positionY, z: positionZ };
}

export function getTiledProperty(
  data: PropertyContainer,
  name: string,
  fallback = null,
): any {
  if (!data || !("properties" in data)) {
    return fallback;
  }
  const properties = data.properties;
  if (Array.isArray(properties)) {
    for (let index = 0; index < properties.length; index++) {
      const property: LayerDataProperty = <LayerDataProperty>properties[index];
      if (property.name === name) {
        return property.value;
      }
    }
    return fallback;
  } else {
    const property: LayerDataProperty = <LayerDataProperty>properties;
    if (property.name === name) {
      return property.value;
    }
    return fallback;
  }
}
