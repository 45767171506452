import { EntityComponentV2 } from "@engine/components/EntityComponentV2";
import { FireworkEmote } from "@engine/gameobjects/emotes/FireworkEmote";
import Entity from "@engine/Entity";
import { AnimatedEmote } from "@engine/gameobjects/emotes/AnimatedEmote";
import { IDataObject } from "@engine/interfaces/IDataObject";

const IdentifierToEmoteClass = {
  ["blush"]: AnimatedEmote,
  ["cry"]: AnimatedEmote,
  ["firework"]: FireworkEmote,
  ["kiss"]: AnimatedEmote,
  ["rage"]: AnimatedEmote,
  ["raincloud"]: AnimatedEmote,
  ["smile"]: AnimatedEmote,
  ["surprised"]: AnimatedEmote,
  ["zzz"]: AnimatedEmote,
};

export class EmoteComponent extends EntityComponentV2 {
  constructor(owner: Entity) {
    super(owner);
  }

  public execute = (identifier: string, data?: IDataObject) => {
    if (identifier in IdentifierToEmoteClass) {
      new IdentifierToEmoteClass[identifier](identifier, this._owner, data);
    } else {
      console.log("[EmoteComponent] " + identifier + " is invalid");
    }
  };
}
