import { TaskTracker } from "./TaskTracker";
import GameScene from "@engine/scenes/GameScene";
import {
  OnboardingPageEight,
  OnboardingPageFive,
  OnboardingPageFour,
  OnboardingPageNine,
  OnboardingPageOne,
  OnboardingPagePinching,
  OnboardingPageSeven,
  OnboardingPageSix,
  OnboardingPageTen,
  OnboardingPageThree,
  OnboardingPageTwo,
} from "./OnboardingTasks";
import EventEmitter = Phaser.Events.EventEmitter;
import { TEventHandler, handleEvent } from "@shared/Events";
import { EnumAllEvents } from "@shared/enums";
import isMobile from "@shared/IsMobile";

export const OnboardingSingleton = {
  onboardingLobbyClosed: false,
  onboardingShopClosed: false,
  onboardingArcadeClosed: false,
};
export interface IOnboardingTaskTracker {
  new (scene: GameScene, eventEmitter: EventEmitter): TaskTracker;
}
export class OnboardingLobbyTaskTracker extends TaskTracker {
  private _eventHandler: TEventHandler;
  private resetOnPiccolo: boolean = true;
  private resetToIndex = isMobile() ? 2 : 1;
  constructor(protected scene: GameScene, public eventEmitter: EventEmitter) {
    super(
      scene,
      isMobile()
        ? [
            new OnboardingPageOne(eventEmitter, scene, 25),
            new OnboardingPagePinching(eventEmitter, scene, 25),
            new OnboardingPageTwo(eventEmitter, scene, 25),
            new OnboardingPageThree(eventEmitter, scene, 25),
            new OnboardingPageFour(eventEmitter, scene, 25),
          ]
        : [
            new OnboardingPageOne(eventEmitter, scene, 25),
            new OnboardingPageTwo(eventEmitter, scene, 25),
            new OnboardingPageThree(eventEmitter, scene, 25),
            new OnboardingPageFour(eventEmitter, scene, 25),
          ],
      eventEmitter,
      "onboarding-lobby-closed",
      "onboarding-lobby-completed",
    );
    this.setRestartTrigger(true);
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("onboarding-lobby-index", this.index.toString());
    });
  }

  private setRestartTrigger(set: boolean) {
    if (set) {
      this._eventHandler = handleEvent(
        "GAME_ONBOARDING_STEPONE_DONE" as EnumAllEvents,
        () => {
          if (this.resetOnPiccolo) {
            if (localStorage.getItem(this.onCloseStorage) !== "true") return;
            if (this.index > -1) super.abandon();
            this.index = this.resetToIndex;
            localStorage.setItem("onboarding-lobby-completed", "false");
            localStorage.setItem("onboarding-shop-completed", "false");
            localStorage.setItem("onboarding-arcade-completed", "false");
            localStorage.setItem("crypto-bomber-game-played", "false");
          } else {
            if (this.index > -1) return;
            localStorage.setItem("onboarding-lobby-closed", "false");
            localStorage.setItem("onboarding-shop-closed", "false");
            localStorage.setItem("onboarding-arcade-closed", "false");
          }
          OnboardingSingleton.onboardingLobbyClosed = false;
          OnboardingSingleton.onboardingShopClosed = false;
          OnboardingSingleton.onboardingArcadeClosed = false;
          this.start();
          return;
        },
      );
    } else {
      this._eventHandler.destroy();
    }
  }
  public start(): void {
    if (
      (localStorage.getItem(this.onCompletedStorage) === "true" &&
        localStorage.getItem(this.onCloseStorage) === "true") ||
      OnboardingSingleton.onboardingLobbyClosed
    )
      return;
    const index = localStorage.getItem("onboarding-lobby-index");
    if (index !== null && index !== "NaN") {
      if (this.index === -1) this.index = Math.max(-1, parseInt(index) - 1);
    } else localStorage.setItem("onboarding-lobby-index", "-1");

    super.start();
  }
  public override abandon(): void {
    localStorage.setItem(this.onCloseStorage, "true");
    localStorage.setItem("onboarding-lobby-index", this.index.toString());
    OnboardingSingleton.onboardingLobbyClosed = true;
    if (localStorage.getItem(this.onCompletedStorage) === "true") {
      super.abandon();
    }
  }
  public override end(): void {
    localStorage.setItem(this.onCompletedStorage, "true");
    localStorage.setItem(this.onCloseStorage, "true");
    localStorage.setItem("onboarding-lobby-index", "-1");
    super.end();
  }

  public shutdown(): void {
    window.removeEventListener("beforeunload", () => {
      localStorage.setItem("onboarding-lobby-index", this.index.toString());
    });
  }
}

export class OnboardingShopTaskTracker extends TaskTracker {
  constructor(protected scene: GameScene, public eventEmitter: EventEmitter) {
    super(
      scene,

      [
        new OnboardingPageFive(eventEmitter, scene, 25),
        new OnboardingPageSix(eventEmitter, scene, 25),
        new OnboardingPageSeven(eventEmitter, scene, 25),
      ],
      eventEmitter,
      "onboarding-shop-closed",
      "onboarding-shop-completed",
    );
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("onboarding-shop-index", this.index.toString());
    });
  }

  public start(): void {
    if (OnboardingSingleton.onboardingShopClosed) return;
    const index = localStorage.getItem("onboarding-shop-index");
    if (index !== null && index !== "NaN")
      this.index = Math.max(-1, parseInt(index) - 1);
    else localStorage.setItem("onboarding-shop-index", "-1");

    super.start();
  }

  public override abandon(): void {
    localStorage.setItem("onboarding-shop-index", this.index.toString());
    OnboardingSingleton.onboardingShopClosed = true;
    if (localStorage.getItem(this.onCloseStorage) === "true") {
      localStorage.setItem(this.onCompletedStorage, "true");
      super.abandon();
    }
  }

  public override end(): void {
    localStorage.setItem("onboarding-shop-index", "-1");
    super.end();
  }

  public shutdown(): void {
    window.removeEventListener("beforeunload", () => {
      localStorage.setItem("onboarding-shop-index", this.index.toString());
    });
  }
}

export class OnboardingArcadeTaskTracker extends TaskTracker {
  private preventEnd =
    window.localStorage.getItem("crypto-bomber-game-played") !== "true";
  constructor(protected scene: GameScene, public eventEmitter: EventEmitter) {
    super(
      scene,
      window.localStorage.getItem("crypto-bomber-game-played") !== "true"
        ? [
            new OnboardingPageEight(eventEmitter, scene, 25),
            new OnboardingPageNine(eventEmitter, scene, 25),
          ]
        : [new OnboardingPageTen(eventEmitter, scene, 25)],
      eventEmitter,
      "onboarding-arcade-closed",
      "onboarding-arcade-completed",
    );
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("onboarding-arcade-index", this.index.toString());
    });
  }
  public start(): void {
    if (OnboardingSingleton.onboardingArcadeClosed) return;
    const index = localStorage.getItem("onboarding-arcade-index");
    if (index !== null && index !== "NaN")
      this.index = Math.max(-1, parseInt(index) - 1);
    else localStorage.setItem("onboarding-arcade-index", "-1");
    super.start();
  }

  public override abandon(): void {
    localStorage.setItem("onboarding-arcade-index", this.index.toString());
    if (this.index !== 1) OnboardingSingleton.onboardingArcadeClosed = true;
    if (localStorage.getItem("onboarding-arcade-completed") === "true") {
      localStorage.setItem("onboarding-arcade-closed", "true");
      super.abandon();
    }
  }

  public override end(): void {
    localStorage.setItem("onboarding-arcade-index", "-1");
    if (!this.preventEnd) super.end();
  }

  public shutdown(): void {
    window.removeEventListener("beforeunload", () => {
      localStorage.setItem("onboarding-arcade-index", this.index.toString());
    });
  }
}
