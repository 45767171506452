import { Signer, ethers } from "ethers";
import { computed, reactive, ref } from "vue";
import { Keys, signRawMessage } from "casper-js-sdk";

import { currentChain, currentChainId, isChainIdValid } from ".";
import { getBthContract, getTokenAddress } from "./contractAccess";
import contractAddresses from "./contractAddresses";
import {
  dispatchEvent,
  EnumApplicationEvents,
  EnumUiEvents,
  handleEventOnce,
} from "@shared/Events";
import { currentAccount } from "@shared/Global";
import { Web3auth } from "./web3auth";
import { EChainId, EChainType, TChain } from "@shared/types";
import { Chain } from "@shared/consts/SupportedChains";
import { Toast } from "@engine/notifications/Toast";
import { isNativeMobileApp } from "@/mobile/isOnIOS";
import { getMobileProvider, getPrivateKey } from "@/mobile/web3auth";
import { JsonRpcProvider, JsonRpcSigner } from "@ethersproject/providers";
import isMobile from "@shared/IsMobile";
import {
  Web3ModalV2,
  // web3modal
} from "./web3modal";

// const apiUrl =
//   "https://us-central1-abiding-arch-334410.cloudfunctions.net/cspr_testnet";

export enum ProviderType {
  None,
  Web3Auth,
  External,
}
export let providerType: ProviderType = ProviderType.None;
export let web3AuthInstance: Web3auth | undefined;
let modal: Web3ModalV2;
export let provider: ethers.providers.JsonRpcProvider | undefined;
export let signer: ethers.providers.JsonRpcSigner | undefined;

export const walletAddress = ref<string | undefined>(
  currentAccount?.user?.metadata?.walletAddress,
);
const _walletAssets = reactive({
  BTH: "0",
  TICKETS: "0",
  SBTH: "0",
  HEADSET: false,
});

export const walletAssets = computed(() => {
  return {
    BTH: _walletAssets.BTH,
    TICKETS: currentAccount.wallet?.tickets,
    SBTH: currentAccount.wallet?.sbth,
    HEADSET: _walletAssets.HEADSET,
  };
});
export const WALLET_CHANGE_MESSAGE =
  "Signed message for setWalletAuthorization: ";
let game_triggered_wallet_event = false;

function reloadWindow() {
  if (!game_triggered_wallet_event) {
    game_triggered_wallet_event = false;
    location.reload();
  }
}

async function setupWeb3Auth(
  chainId: number,
  reconnect: boolean,
  web3auth: Web3auth,
  config: { provider: JsonRpcProvider; wallet: ethers.Wallet },
): Promise<void> {
  provider = config.provider;
  signer = config.wallet;
  providerType = ProviderType.Web3Auth;
  web3AuthInstance = web3auth;
  emitWalletConnected(
    await signer?.getAddress(),
    !reconnect ? await signWalletConnectMessage(signer as JsonRpcSigner) : "",
    chainId,
    reconnect,
  );
}

async function setupWeb3Modal(
  chainId: number,
  reconnect: boolean,
): Promise<void> {
  const res = await modal.toEthers();
  provider = res.provider;
  signer = res.signer;
  providerType = ProviderType.External;
  if (
    (await signer?.getChainId()) !== new Chain(chainId).chain_id &&
    !(await evmExternalProviderChangeNetwork(new Chain(chainId).default))
  ) {
    Toast.info(`Requesting switch to a supported chain`);
    Toast.error(`Unable to switch chain`);
    return;
  }
  emitWalletConnected(
    await signer.getAddress(),
    !reconnect ? await signWalletConnectMessage(signer as JsonRpcSigner) : "",
    chainId,
    reconnect,
  );
}

function defaultWeb3ModalConnectCallback(
  chainId: number,
  reconnect: boolean = false,
): () => Promise<void> {
  return async () => await setupWeb3Modal(chainId, reconnect);
}

// TODO: Still needs some refactoring.
async function handleCsprReconnect(): Promise<boolean> {
  providerType = ProviderType.Web3Auth;
  const web3auth = new Web3auth(false);
  await web3auth.initWeb3AuthCore();
  const loginStatus = web3auth.status();
  if (loginStatus !== "connected") {
    await web3auth.login();
  }
  web3AuthInstance = web3auth;

  let private_key: string;
  if (isNativeMobileApp()) private_key = await getPrivateKey(false);
  else private_key = (await web3auth.getPrivateKey()).toString();

  if (!private_key) {
    throw Error("invalid cspr key");
  }

  const keybuf: Keys.AsymmetricKey = Keys.getKeysFromHexPrivKey(
    private_key,
    Keys.SignatureAlgorithm.Ed25519,
  );

  const casperPublicKey = keybuf.publicKey.toHex();

  walletAddress.value = casperPublicKey;

  return true;
}

export async function disconnect(chainId: EChainId) {
  if (web3AuthInstance && web3AuthInstance.status() == "connected") {
    await web3AuthInstance.logout();
  }

  emitWalletConnected("null", "", chainId);

  providerType = ProviderType.None;
  provider = undefined;
  signer = undefined;
  web3AuthInstance = undefined;
  modal = undefined;
}

export async function connect(
  chainId: EChainId,
  web3authLogin: boolean = false,
): Promise<boolean> {
  const chain = new Chain(chainId);
  if (!isChainIdValid(chain.id)) {
    Toast.error("Invalid network to connect");
    return false;
  }
  if (chain.type == EChainType.EVM) {
    await handleEvmConnect(chainId, web3authLogin);
  } else if (chain.type == EChainType.CASPER) {
    try {
      const { walletAddress, signedMessage, chainId } = await handleCsprConnect(
        web3authLogin,
      );

      emitWalletConnected(walletAddress, signedMessage, chainId);
    } catch (e) {
      console.log("unable to connect");
    }
  }

  return true;
}

function emitWalletConnected(
  walletAddress: string,
  signedMessage: string,
  chainId: EChainId,
  reconnect: boolean = false,
) {
  if (currentAccount.email) {
    dispatchEvent(EnumUiEvents.UiWalletChanged, {
      walletAddress: walletAddress,
      signedMessage: signedMessage,
      message: WALLET_CHANGE_MESSAGE + currentAccount.user.id,
      chainId: chainId,
      reconnect,
    });
  } else {
    handleEventOnce(
      EnumApplicationEvents.ApplicationNetworkAuthenticated,
      () => {
        if (currentAccount.email) {
          dispatchEvent(EnumUiEvents.UiWalletChanged, {
            walletAddress: walletAddress,
            signedMessage: signedMessage,
            message: WALLET_CHANGE_MESSAGE + currentAccount.user.id,
            chainId: chainId,
            reconnect,
          });
        }
      },
    );
  }
  dispatchEvent(EnumApplicationEvents.ApplicationPreventReload, false);
}
export async function tryReconnectWallet(): Promise<void> {
  if (
    currentAccount.user.metadata.walletAddress &&
    typeof currentAccount.user.metadata.chainId == "number"
  ) {
    const chain = new Chain(currentAccount.user.metadata.chainId);
    if (chain.type == EChainType.CASPER) {
      await handleCsprReconnect();
      return;
    }
    if (chain.type === EChainType.EVM) {
      const web3auth = new Web3auth(true);
      await web3auth.initWeb3AuthCore();
      if (web3auth.status() === "connected") {
        const config = await web3auth.connect(chain.id, true);
        if (!config) return;
        setupWeb3Auth(chain.id, true, web3auth, config);
      } else {
        if (!modal) modal = await Web3ModalV2.init();
        Web3ModalV2.attemptReconnect(
          currentAccount.user.metadata.walletAddress,
          new Chain(currentAccount.user.metadata.chainId),
          defaultWeb3ModalConnectCallback(
            currentAccount.user.metadata.chainId,
            true,
          ),
        );
      }
    }
  }

  ////@todo implement Web3ModalV2 reconnect and Web3Auth reconnect
  // const _chainId = localStorage.getItem("chainId");
  // if (!_chainId) {
  //   return false;
  // }
  // const chain = new Chain(EChainId[_chainId]);
  // currentChainId.value = chain.id;
  // if (chain.type == EChainType.EVM) {
  //   return await handleEvmReconnect(enforced);
  // } else if (chain.type == EChainType.CASPER) {
  //   return await handleCsprReconnect();
  // }
  // return false;
}

export async function signWalletConnectMessage(
  signer: Signer,
): Promise<string> {
  try {
    const signedMessage = await signer.signMessage(
      WALLET_CHANGE_MESSAGE + currentAccount.user.id,
    );

    return signedMessage;
  } catch (e) {
    Toast.error("Request have failed or was declined");
    return "";
  }
}

async function isWalletAddress(
  address: string,
  wallet: Signer,
): Promise<boolean> {
  if (!address || address === "null") return true;
  if (
    address.toLocaleLowerCase() !==
    (await wallet.getAddress()).toLocaleLowerCase()
  ) {
    return false;
  }
  return true;
}

async function handleEvmConnect(
  chainId: EChainId,
  web3authLogin = false,
): Promise<void> {
  if (web3authLogin) {
    if (web3AuthInstance && web3AuthInstance.status() === "connected") {
      await web3AuthInstance.logout();
    }
    const web3auth = new Web3auth(false);
    await web3auth.initWeb3AuthCore();
    const config:
      | { provider: JsonRpcProvider; wallet: ethers.Wallet }
      | undefined = await web3auth.connect(chainId);
    if (!config) return;
    await setupWeb3Auth(chainId, false, web3auth, config);

    return;
  } else {
    if (!modal) modal = await Web3ModalV2.init();
    await modal.connect(defaultWeb3ModalConnectCallback(chainId));
    return;
  }
}

async function handleCsprConnect(web3auth: boolean): Promise<{
  signedMessage: string;
  walletAddress: string;
  chainId: EChainId;
}> {
  if (!web3auth) {
    const CasperWalletProvider = window.CasperWalletProvider;
    let provider = {} as typeof CasperWalletProvider;
    try {
      provider = CasperWalletProvider();
    } catch (e) {
      Toast.error("Install Casper Wallet first!");
      throw new Error();
    }
    const connected = await provider.requestConnection();
    if (connected) {
      const publicKey = await provider.getActivePublicKey();
      const signedMessage = await provider.signMessage(
        WALLET_CHANGE_MESSAGE + currentAccount.user.id,
        publicKey,
      );
      currentChainId.value = EChainId.CSPR;
      return {
        signedMessage: signedMessage.signatureHex,
        walletAddress: publicKey,
        chainId: EChainId.CSPR,
      };
    }
    throw new Error(`Failed connecting`);
  } else {
    const web3auth = new Web3auth(false);
    await web3auth.initWeb3AuthCore();
    if (web3auth.status() !== "connected") {
      await web3auth.login();
    }
    let private_key: string;
    if (isNativeMobileApp()) private_key = await getPrivateKey(false);
    else private_key = (await web3auth.getPrivateKey()).toString();

    if (!private_key) {
      throw Error("invalid cspr key");
    }

    const keybuf: Keys.AsymmetricKey = Keys.getKeysFromHexPrivKey(
      private_key,
      Keys.SignatureAlgorithm.Ed25519,
    );

    const casperPublicKey: string = keybuf.publicKey.toHex();

    const signedMessage: Uint8Array = await signRawMessage(
      keybuf,
      WALLET_CHANGE_MESSAGE + currentAccount.user.id,
    );

    const signedMessageString: string = toHexString(signedMessage);
    currentChainId.value = EChainId.CSPR;

    return {
      signedMessage: signedMessageString,
      walletAddress: casperPublicKey,
      chainId: currentChainId.value,
    };
  }
}

function toHexString(byteArray) {
  return Array.prototype.map
    .call(byteArray, function (byte) {
      return ("0" + (byte & 0xff).toString(16)).slice(-2);
    })
    .join("");
}

export async function getAccountAssets() {
  await getBthBalance();
  _walletAssets.HEADSET = false;
}

export async function getApprovedBth() {
  let allowance = 0;
  if (!currentChain.value.chain_id) {
    throw new Error();
  }
  try {
    const _allowance = await getBthContract(
      contractAddresses[currentChain.value.chain_id].tokenContract,
    ).allowance(
      walletAddress.value,
      contractAddresses[currentChain.value.chain_id].marketplaceContract,
    );
    allowance = Number(_allowance) / 10 ** 18;
  } catch (e) {
    console.error(e);
    Toast.error("Failed to retrieve approved amount");
  }
  return allowance;
}

export async function approveMaxBth() {
  try {
    await getBthContract(
      contractAddresses[currentChainId.value].tokenContract,
    ).approve(
      contractAddresses[currentChainId.value].marketplaceContract,
      ethers.utils.parseEther("1000000000").toString(),
    );
  } catch (e) {
    console.error(e);
    Toast.error("Failed to approve the requested token amount");
  }
}

export async function getBthBalance() {
  try {
    if (walletAddress.value && getTokenAddress(currentChainId.value)) {
      const result = await getBthContract(
        getTokenAddress(currentChainId.value),
      ).balanceOf(walletAddress.value);
      _walletAssets["BTH"] = ethers.utils.formatEther(result);
    } else {
      _walletAssets["BTH"] = "0";
    }
  } catch (e) {
    console.error(e);
    return 0;
  }
  return parseInt(walletAssets.value["BTH"]);
}

//Called from `evmExternalProviderChangeNetwork`
async function evmExternalProviderSwitchNetwork(
  chain: TChain,
  argProvider?: ethers.providers.JsonRpcProvider,
): Promise<Boolean> {
  const chainId = "0x" + chain.chain_id.toString(16);
  try {
    await (argProvider ? argProvider : provider).send(
      "wallet_switchEthereumChain",
      [{ chainId: chainId }],
    );
    return true;
  } catch (e) {
    console.log(e);
    Toast.error(
      `Request to change to switch networks have failed or was declined`,
    );
    return false;
  }
}

//Called from `evmExternalProviderChangeNetwork`
async function evmExternalProviderAddNetwork(
  chain: TChain,
  argProvider?: ethers.providers.JsonRpcProvider,
): Promise<Boolean> {
  try {
    game_triggered_wallet_event = true;
    await (argProvider ? argProvider : provider)?.send(
      "wallet_addEthereumChain",
      [
        {
          chainId: chain.chain_id,
          chainName: chain.name,
          rpcUrls: [chain.rpc_url],
          blockExplorerUrls: [chain.explorer_url],
          nativeCurrency: {
            name: chain.native_currency.name,
            symbol: chain.native_currency.symbol,
            decimals: 18,
          },
        },
      ],
    );
    return true;
  } catch (e) {
    console.error(e);
    Toast.error(
      `Request to add ${chain.name} chain have failed or was declined`,
    );
    return false;
  }
}

export async function evmProviderChangeWallet(
  address: string,
  web3authLogin: boolean,
): Promise<Boolean> {
  if (web3authLogin) {
    Toast.error(`Change wallet account to ${address}`);
    return false;
  }
  if (!window.ethereum) {
    return true;
  }
  try {
    const result = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    if (result[0].toLocaleLowerCase() !== address.toLocaleLowerCase()) {
      Toast.error(
        `Connected wallet is: ${result[0]}. Please connect wallet ${address}!`,
      );
      return false;
    }
    return true;
  } catch (e) {
    Toast.error("Request have failed or was declined");
    return false;
  }
}

async function evmExternalProviderChangeNetworkPC(
  chain: TChain,
  argProvider?: ethers.providers.JsonRpcProvider,
): Promise<Boolean> {
  if (argProvider ? !argProvider : !provider) {
    Toast.error("Cannot switch chains");
    return false;
  }
  game_triggered_wallet_event = true;
  if (
    !(await evmExternalProviderSwitchNetwork(
      chain,
      argProvider ? argProvider : provider,
    ))
  ) {
    Toast.warning(
      `Try to add '${chain.name}' chain to your available wallet networks first`,
    );
    if (
      !(await evmExternalProviderAddNetwork(
        chain,
        argProvider ? argProvider : provider,
      ))
    )
      return false;
    if (
      !(await evmExternalProviderSwitchNetwork(
        chain,
        argProvider ? argProvider : provider,
      ))
    ) {
      return false;
    }
  }
  if (!argProvider) {
    signer = provider?.getSigner();
  }
  return true;
}

async function evmExternalProviderChangeNetworkMobile(
  chain: TChain,
): Promise<Boolean> {
  game_triggered_wallet_event = true;
  Toast.info(`Set your chain to ${chain.name}`);
  await new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, 2000);
  });
  if (!(await modal.changeNetwork(chain))) {
    Toast.info(
      `In order to proceed, please add ${chain.name} first. Afterward, kindly restart your wallet and the game itself for the changes to take effect.`,
    );
    return false;
  }
  return true;
}

export async function evmExternalProviderChangeNetwork(
  chain: TChain,
  argProvider?: ethers.providers.JsonRpcProvider,
): Promise<Boolean> {
  if (!isMobile())
    return evmExternalProviderChangeNetworkPC(chain, argProvider);
  else return evmExternalProviderChangeNetworkMobile(chain);
}

export async function evmWeb3AuthProviderChangeNetwork(
  chain: TChain,
): Promise<Boolean> {
  let config: {
    wallet: ethers.Wallet;
    provider: ethers.providers.JsonRpcProvider;
  };
  if (!isNativeMobileApp()) {
    config = Web3auth.toEthersWallet(
      (await web3AuthInstance?.getPrivateKey()) as string,
      chain,
    );
  } else {
    const res = await getMobileProvider(chain, true);
    if (!res) {
      return false;
    }
    config = res;
  }
  provider = config.provider;
  signer = config.wallet;
  return true;
}

export async function defaultProviderChangeNetwork(
  chain: TChain,
): Promise<Boolean> {
  switch (providerType) {
    case ProviderType.Web3Auth:
      return evmWeb3AuthProviderChangeNetwork(chain);
    case ProviderType.External:
      return evmExternalProviderChangeNetwork(chain);
  }
  return false;
}
