export const FurnitureNFTs: { [key: string]: string } = {

  "0x9070b35bfdcc31472965d79e7b0854b103a9b9d7": "gamerchairpink_1x1x2_1",
  "0x7a68265e94353d0f18e4563a21f84e7325293914": "leddice_1x1x1_11",
  "0xc233623bf1ad14dc1608da1851c0e079b98a5d51": "dragonpinktourmaline_1x1x2_1",
  "0x39b41f517e1f214fb2da2dc2f01ce5a7a5cd597e": "kitchenbarstoolteal_1x1x2_1",
  "0x783cabda919f8607376835c140dbf0be90f51c8c": "jetrocketmachine_1x1x3_1",
  // "0xd72a70519b4e79674758c7c2309bf30ce2f35bd0": "", // not yet usable
  "0x07cce68bcd021139ce8bc774e49b677f896913da": "petbedsmalllightpurple_2x1x1_1",
  // "0xa788082dfde390bc526fbc789a6dbaff131480e6": "singleservecoffeemakerteal_1x1x2_10", // not yet usable

  "0x9d3124f1db92554eb86c704d26c4590acb423eb9": "pethousesmallpink_1x1x2_1",
  "0x74f18bb178694ec6ed66776e9068473aeb456d02": "airhockeytable_2x3x2_1",
  "0x466556d90f58cd07102d645056f770ef7dda7626": "gamerdeskblackpink_2x1x3_1",
  "0xa7ee8a1a7014b2a26cef7da0634b1326424445bb": "moderndresserteal_2x1x2_1",
  "0x0286ad24dd584c986dfd16abda416160ebb7cab8": "basicbookshelfgreen_3x1x3_1",
  "0xbcdebf39e08cec32c478817dec1934e687b49fe6": "modernbedpurple_3x3x2_1",
  "0x900c3ba4550153b3ee1084d3a2acedf61e77961b": "basiccoffeetableteal_2x1x2_1",
  "0x24db3309707756046a31f3de2d3c58959e5dc6ae": "modernsofagrey_2x1x2_1",

  "0xd8f20e1730009ac75efece5681e6a9e8abede7cb": "mightyminesarcademachine_1x1x3_33",
  "local-fruity-memory-arcade-machine": "fruitymemoryarcademachine_1x1x3_60",
  "local-cryptobomber-arcade-machine": "cryptobomberarcademachine_1x1x3_94",
  "local-plinko-arcade-machine": "bitplinkoarcademachine_1x1x4_55",
  "local-stampwars-arcade-machine": "stampwarsarcademachine_1x1x3_36",

  // test network
  "0x343fd560a6fdeae70b58a9f7d1172ed038accd4c": "dragonpinktourmaline_1x1x2_1",
  "0x6767cb4eb60d7b013e28020f18a1b5fb73702708": "gamerchairpink_1x1x2_1",
  "0x02061196c45fa417d0df9db5e05ff487f7c49a61": "leddice_1x1x1_11"
};