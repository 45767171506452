export * from "./enums/Events";
import EventEmitter from "eventemitter3";
import { EnumAllEvents } from "./enums";
import { eventDataModifiers } from "./EventModifiers";
import { getDebugMode } from "./Helpers";

export const EE = new EventEmitter<string>();

export const LOGGER_ID = "SHARED_EVENT";
const debugMode = getDebugMode(LOGGER_ID);
const isDebug = debugMode > 0;
const isVerbose = debugMode == 3;

const eventHandlers: { [key: string]: Array<string> } = {};

let silencedEvents: Array<EnumAllEvents> = [];

function isEventSilenced(eventType: EnumAllEvents) {
  if (!silencedEvents.length) {
    const _silencedEvents = import.meta.env.VITE_GAME_SILENCED_EVENTS;
    if (_silencedEvents) {
      silencedEvents = _silencedEvents.split("|");
    }
  }
  return silencedEvents.includes(eventType);
}

/**
 * @param  {EnumAllEvents} eventType defined: GameUiNpcInteraction
 * @param  {T} data?
 */
function modifyEventData<T>(eventType: EnumAllEvents, data?: T) {
  if (eventDataModifiers[eventType]) {
    if (isDebug && !isEventSilenced(eventType)) {
      console.log(
        `Data before modification: `,
        JSON.parse(JSON.stringify(data)),
      );
    }
    const modifiedData = eventDataModifiers[eventType](data);
    if (isDebug && !isEventSilenced(eventType)) {
      console.log(`Event "${eventType}" data modified to`, modifiedData);
    }
    return modifiedData;
  }
  if (data && isDebug && !isEventSilenced(eventType)) {
    console.log("no data modifiers for " + eventType);
  }
  return data;
}

export function dispatchEvent<T>(eventType: EnumAllEvents, data?: T) {
  if (isDebug && !isEventSilenced(eventType)) {
    let from = "";

    const stack = new Error().stack || "stack empty";
    for (const line of stack.split("\n")) {
      if (!line.includes("dispatchEvent") && line.indexOf("Error") != 0) {
        if (line.includes("@") && line.indexOf("@") < line.indexOf("/")) {
          //firefox
          const splitLine = line.split("@");
          from = `${splitLine.shift()} ${splitLine.join("@")}`;
          break;
        }
        from = line.trim().replace("at ", "");
        break;
      }
    }
    console.groupCollapsed(
      `Dispatching ${eventType} from ${from} ${
        data ? ", expand for data & stack" : ""
      }`,
    );
    data = modifyEventData(eventType, data);
    if (data) {
      console.log(data);
    }
    if (isVerbose) {
      console.trace();
    }
    console.groupEnd();
  } else {
    data = modifyEventData(eventType, data);
  }
  if (eventHandlers[eventType]) {
    for (const eventName of eventHandlers[eventType]) {
      EE.emit(eventName, data);
    }
  } else {
    EE.emit(eventType, data);
  }
}

function wrapHandler(eventType: EnumAllEvents, callback: (data?: any) => void) {
  return (event: any) => {
    if (isDebug && !isEventSilenced(eventType)) {
      console.groupCollapsed(
        `Handling ${eventType}, expand for callback &  stack`,
      );
      console.log(callback);
      if (isVerbose) {
        console.trace();
      }
      console.groupEnd();
    }
    callback(event);
  };
}

export type TEventHandler = {
  id: number;
  eventType: string;
  eventName: string;
  handler: (data?: any) => void;
  destroy: () => void;
};
/**
 *
 * @param eventType
 * @param callback
 * @param onBeforeUnmount(callback:()=>void), hook that will execute it's param function before destroying
 * @param attachId add "-Date.now()" at the end
 * @returns attachedId if attachId == true
 */
export function handleEvent<T>(
  eventType: EnumAllEvents,
  callback: (data: T) => void,
  onBeforeUnmount?: (f: () => void) => void,
  attachId?: boolean | number,
): TEventHandler;
export function handleEvent<T>(
  eventType: EnumAllEvents,
  callback: (data?: T) => void,
  onBeforeUnmount?: (f: () => void) => void,
): TEventHandler {
  if (isDebug && !isEventSilenced(eventType)) {
    console.groupCollapsed(
      `Attaching ${eventType} handler expand for callback & stack`,
    );
    console.log(callback);
    if (isVerbose) {
      console.trace();
    }
    console.groupEnd();
  }
  const handler = wrapHandler(eventType, callback);
  const id = parseInt(Math.random().toString(10).slice(2));
  const eventName = eventType.toString() + "-" + id;

  if (!eventHandlers[eventType]) {
    eventHandlers[eventType] = [];
  }

  const eventHandler = {
    id,
    eventType,
    eventName,
    handler,
    callback,
    destroy: () => {
      EE.removeAllListeners(eventName);
      const index = eventHandlers[eventType].indexOf(eventName);
      if (index > -1) {
        eventHandlers[eventType].splice(index, 1);
      }
    },
  };
  eventHandlers[eventType].push(eventName);
  EE.on(eventName, handler);
  if (onBeforeUnmount) {
    onBeforeUnmount(() => {
      EE.removeAllListeners(eventName);
      eventHandlers[eventType].splice(
        eventHandlers[eventType].indexOf(eventName),
        1,
      );
    });
  }
  return eventHandler;
}

export function handleEventOnce(
  eventType: EnumAllEvents,
  callback: (data: any) => void,
) {
  if (isDebug && !isEventSilenced(eventType)) {
    console.groupCollapsed(
      `Attaching one-time ${eventType} handler, expand for callback & stack`,
    );
    console.log(callback);
    console.log(callback.toString());
    if (isVerbose) {
      console.trace();
    }
    console.groupEnd();
  }
  EE.once(eventType, (event: any) => {
    if (isDebug && !isEventSilenced(eventType)) {
      console.groupCollapsed(
        `Handling one-time ${eventType} handler, expand for callback & stack`,
      );
      console.log(callback);
      console.log(callback.toString());
      if (isVerbose) {
        console.trace();
      }
      console.groupEnd();
    }
    callback(event);
  });
}
