import { scaleAndFadeOut } from "@game/engine/utilities/AnimationHelper";
import { IIdentifiableAsset } from "@engine/objects/DynamicLoader";
import TWEEN_COMPLETE = Phaser.Tweens.Events.TWEEN_COMPLETE;
import GameScene from "@engine/scenes/GameScene";
import Image = Phaser.GameObjects.Image;
import Vector2 = Phaser.Math.Vector2;
import Entity from "@engine/Entity";

export default class LocationMarker {
  public static ImageKey = "__halo__";
  private _halo: Image;
  private _timePassed = 0;

  constructor(
    private _imageURL: string = "/assets/game/halos/crown.png",
    private _scene: GameScene,
    private _destination: { x: number; y: number } | Entity,
    private _offset: Vector2 = new Vector2(0, -180),
  ) {
    const key = LocationMarker.ImageKey;
    const assetConfig: IIdentifiableAsset = {
      key,
      type: "image",
      url: this._imageURL,
    };
    this._scene.dynamicLoadAssetIfNotLoadingOrLoaded(
      assetConfig,
      (key: string) => {
        this.displayHalo(this._scene, key, this._destination);
      },
    );
  }

  public tick(deltaSeconds: number) {
    this._timePassed += deltaSeconds * 3;
    if (!this._halo) return;
    const target: Vector2 = new Vector2(this._destination).add(this._offset);
    target.y += Math.cos(this._timePassed) * this._offset.y * 0.125;

    const haloPosition: Vector2 = new Vector2(this._halo);
    const destination = haloPosition.lerp(target, 1);

    this._halo.setPosition(destination.x, destination.y);
  }

  public destroy() {
    scaleAndFadeOut(this._scene.tweens, this._halo).on(TWEEN_COMPLETE, () => {
      if (!this._halo) return;
      this._halo.destroy(true);
    });
  }

  private displayHalo = (
    scene: GameScene,
    key: string,
    destination: Entity | { x: number; y: number },
  ) => {
    this._halo = new Image(
      scene,
      destination.x + this._offset.x,
      destination.y + this._offset.y,
      key,
    )
      .setScale(2)
      .setOrigin(0.5)
      .setDepth(999);
    this._halo.setAlpha(0);
    scene.add.existing(this._halo);

    scene.tweens.add({
      duration: 512,
      props: {
        alpha: 1,
      },
      ease: "Cubic.easeOut",
      targets: this._halo,
    });
  };
}
