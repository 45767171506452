import {
  EChainId,
  EChainType,
  ENetworkType,
  TChain,
  TChaincurrency,
} from "@shared/types";
export interface TChainExtended extends TChain {
  icon: string;
  available: boolean;
}

export class Chain {
  private _chain: TChain;
  constructor(chainIdentifier: EChainId) {
    const chain = supportedChains.filter(
      (chain: TChain) =>
        chain.id == chainIdentifier || chain.chain_id == chainIdentifier,
    )[0];
    if (!chain) {
      throw new Error(`No chain found: ${EChainId[chainIdentifier]}`);
    }
    this._chain = chain;
  }

  static fromChainId(chainId: number): Chain {
    const chain = supportedChains.filter(
      (chain: TChain) => chain?.chain_id && chain.chain_id == chainId,
    )[0];
    if (!chain) {
      throw new Error(`No chain found with chain id: ${chainId}`);
    }
    return new Chain(chain.id);
  }

  get type(): EChainType {
    return this._chain.type;
  }

  get chain_id(): number | undefined {
    return this._chain.chain_id;
  }

  get rpc_url(): string {
    return this._chain.rpc_url;
  }

  get name(): string {
    return this._chain.name;
  }

  get explorer_url(): string {
    return this._chain.explorer_url;
  }

  get currency(): TChaincurrency {
    return this._chain.native_currency;
  }

  get marketplace_currency(): string {
    return this._chain.marketplace_currency;
  }

  get id(): EChainId {
    return this._chain.id;
  }

  get default(): TChain {
    return this._chain;
  }
}

export const supportedChains: Array<TChainExtended> = [
  {
    id: EChainId.BSC,
    type: EChainType.EVM,
    name: "Binance Smart Chain",
    network: ENetworkType.MAINNET,
    chain_id: 56,
    rpc_url: "https://bsc-dataseed1.defibit.io/",
    explorer_url: "https://bscscan.com/",
    marketplace_currency: "BTH",
    native_currency: {
      symbol: "BNB",
      name: "BNB",
      decimals: 18,
    },
    icon: "bnb",
    available: true,
  },
  {
    id: EChainId.GLMR,
    type: EChainType.EVM,
    name: "Moonbeam",
    network: ENetworkType.MAINNET,
    chain_id: 1284,
    rpc_url: "https://rpc.api.moonbeam.network",
    explorer_url: "https://moonscan.io/",
    marketplace_currency: "BTH",
    native_currency: {
      symbol: "GLMR",
      name: "GLMR",
      decimals: 18,
    },
    icon: "moonbeam",
    available: true,
  },
  {
    id: EChainId.CSPR,
    type: EChainType.CASPER,
    network: ENetworkType.MAINNET,
    name: "Casper",
    rpc_url: "",
    explorer_url: "",
    marketplace_currency: "CSPR",
    native_currency: {
      symbol: "CSPR",
      name: "CSPR",
      decimals: 18,
    },
    icon: "casper",
    available: true,
  },
];
