import InteractiveFurnitureObject from "@game/mini/room/objects/InteractiveFurnitureObject";
import CustomizableRoomScene from "@game/mini/room/CustomizableRoomScene";
import { TInteractiveFurnitureConfig } from "@shared/consts/InteractiveFurniture";
import { IInteractiveFurnitureUseResponse } from "@common/modules/rooms/interfaces/FurnitureOperations";

export default class FOWoodenDivider extends InteractiveFurnitureObject {
  private _open: boolean = false;

  constructor(
    scene: CustomizableRoomScene,
    name: string,
    id: string,
    config: TInteractiveFurnitureConfig,
  ) {
    super(scene, name, id, config);

    this._frameNumber = 0;
  }

  public override processActivationResponse(
    response: IInteractiveFurnitureUseResponse,
  ) {
    this.setState(response.data.state);
  }

  public override setState(state: number) {
    this._open = state === 1;
    this._frameNumber = state;
    this.updateFrames();

    this._scene.onDoorStateChanged(this, this._open);
  }

  protected update(time, delta) {}
}
