import Entity from "@engine/Entity";
import { IIdentifiableAsset } from "@engine/objects/DynamicLoader";
import { ParticleEmitterPositionProxy } from "@engine/objects/ParticleEmitterPositionProxy";
import { BlendModes } from "phaser";
import { AllColors } from "@common/constants/Palette";
import { Emote } from "@engine/gameobjects/emotes/Emote";
import ParticleEmitterManager = Phaser.GameObjects.Particles.ParticleEmitterManager;

export class FireworkEmote extends Emote {
  public static Identifier: string = "firework";

  constructor(
    identifier: string,
    executor: Entity,
    data?: { [key: string]: any },
  ) {
    super(identifier, executor, data);

    const { scene, x, y } = executor;

    // All we need
    const assets: IIdentifiableAsset[] = [
      {
        type: "image",
        key: "particle",
        url: "assets/game/particles/circle.png",
      },
      {
        type: "text",
        key: "particle-effect-fireworks-bang",
        url: "assets/game/particles/effects/fireworks-bang.json",
      },
      {
        type: "text",
        key: "particle-effect-fireworks-fuse",
        url: "assets/game/particles/effects/fireworks-fuse.json",
      },
    ];

    // Only start after everything has loaded
    scene.dynamicLoadAssetsIfNotLoaded(assets, () => {
      const manager = new ParticleEmitterManager(scene, "particle");
      manager.x = x;
      manager.y = y;
      manager.setDepth(executor.depth + 1);

      for (let index = 0; index < 16; ++index) {
        const bangEmitter = manager.createEmitter(
          new Function(
            "return " + scene.cache.text.get("particle-effect-fireworks-bang"),
          )(),
        );
        const fuseEmitter = manager.createEmitter(
          new Function(
            "return " + scene.cache.text.get("particle-effect-fireworks-fuse"),
          )(),
        );

        const randomColor =
          AllColors[Phaser.Math.Between(0, AllColors.length - 1)];
        bangEmitter.tint.onChange(randomColor);
        fuseEmitter.tint.onChange(randomColor);

        bangEmitter.blendMode = BlendModes.ADD;
        fuseEmitter.blendMode = BlendModes.ADD;

        scene.tweens.add({
          delay: Phaser.Math.Between(0, 256) + index * 256,
          duration: Phaser.Math.Between(1024, 2048),
          ease: "Cubic.easeIn",
          props: {
            x: Phaser.Math.Between(-128, 128),
            y: Phaser.Math.Between(-512, -128),
          },
          targets: new ParticleEmitterPositionProxy(fuseEmitter),
          onComplete: () => {
            fuseEmitter.stop();
            bangEmitter.explode(
              bangEmitter.quantity.propertyValue,
              fuseEmitter.x.propertyValue,
              fuseEmitter.y.propertyValue,
            );
          },
          onStart: () => {
            fuseEmitter.start();
          },
        });
      }

      scene.add.existing(manager);

      // MARKTWAIN - clean up too..
    });
  }
}
