import { BigNumber, ethers, utils } from "ethers";
import { toRaw } from "vue";

export class SignerOrProvider {
  private readonly _signerOrProvider: ethers.Signer | ethers.providers.Provider;
  constructor(signerOrProvider: ethers.Signer | ethers.providers.Provider) {
    this._signerOrProvider = toRaw(signerOrProvider);
  }

  isSigner(): boolean {
    if (this._signerOrProvider instanceof ethers.Signer) {
      return true;
    }
    return false;
  }

  get signer(): ethers.Signer {
    if (this._signerOrProvider instanceof ethers.Signer) {
      return toRaw(this._signerOrProvider);
    }
    throw new Error(`No signer available`);
  }

  get provider(): ethers.providers.Provider {
    if (this._signerOrProvider instanceof ethers.Signer) {
      return toRaw(this._signerOrProvider).provider;
    } else if (this._signerOrProvider instanceof ethers.providers.Provider) {
      return toRaw(this._signerOrProvider);
    }
    throw new Error(`No valid type found`);
  }

  get value(): ethers.providers.Provider | ethers.Signer {
    return toRaw(this._signerOrProvider);
  }
}

export class EthersUtils {
  private readonly _signer: ethers.Signer;
  constructor(signer?: ethers.Signer) {
    this._signer = toRaw(signer);
  }

  checkAddresses(addressList: Array<string>): void {
    for (const address of addressList) {
      if (!utils.isAddress(address)) {
        throw new Error(`Invalid address provided`);
      }
    }
  }

  toWei(ethAmount: number): BigNumber {
    return utils.parseEther(ethAmount.toString());
  }

  fromWei(weiAmount: BigNumber): number {
    return +utils.formatEther(weiAmount);
  }

  hashData(data: string): string {
    return utils.hashMessage(data);
  }

  async signMessage(data: string): Promise<string> {
    if (!this._signer) {
      throw new Error(`No signer given`);
    }
    const hash = this.hashData(data);
    return await this._signer.signMessage(hash);
  }
}
