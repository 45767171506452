import { INFURA, METADATA_PROXY, toast } from "./const";
import { Toast } from "@engine/notifications/Toast";
const SI_SYMBOL = ["", "K", "M", "G", "T", "P", "E"];

export function snakeToCapitalFirst(text: string) {
  const ret = text.split("-").join(" ");
  return ret.charAt(0).toUpperCase() + ret.slice(1);
}

export function camelToCapital(text: string) {
  return text
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
}

export function replaceMetadataProxy(url: string): string {
  if (!url) {
    return "";
  }
  return url.replace(INFURA, METADATA_PROXY);
}

export function handleChainError(error: string): void {
  if (error.includes("cannot estimate gas")) {
    error = "Transaction reverted";
  } else if (error.includes("user rejected")) {
    error = "Use rejected transaction";
  }
  Toast.error(error);
  console.log(error);
}

export const formatNumber = (number: number): string => {
  // what tier? (determines SI symbol)
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier === 0) {
    return number.toString();
  }

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = number / scale;

  if (number < 10000) {
    /** Taking only one digit after decimal
     * because toFixed(1) rounded the numbers in some cases
     *  2.99 => 3.0
     */

    const oneDigitAfterDecimal = Number(String(scaled).slice(0, 3));

    return oneDigitAfterDecimal.toFixed(1) + suffix;
  }

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
};
