import { reactive, ref } from "vue";
import { TUiGameSettingsUpdateAudio } from "./types";
import { TNakamaAccount } from "./types/nakama/NakamaTypes";
//TODO: Move to store
export const currentAccount: TNakamaAccount = reactive({
  email: "",
  user: { id: "" },
  wallet: { sbth: 0, tickets: 0 },
});
export const currentSession: any = ref({});
export const currentGameScene = ref("LobbyScene");
export const gameAudioValues: TUiGameSettingsUpdateAudio = reactive({
  master: { value: 1, muted: false },
  sfx: { value: 0.6, muted: false },
  music: { value: 0.5, muted: false },
  voice: { value: 0.7, muted: false },
});

export const pushNotifcationKeyToTitleMap = {
  onFriendRequest: "Notify on friend request",
  onFriendLogin: "Notify when friends sign in",
  onFriendSearchingMatch: "Notify when friends play multiplayer minigames",
};

export const gamePushNotifications = reactive({
  onFriendRequest: true,
  onFriendLogin: true,
  onFriendSearchingMatch: true,
});

//TODO: refac
try {
  const savedAudio = localStorage.getItem("bithotel_game_audio");
  if (savedAudio && savedAudio.length > 0) {
    Object.assign(gameAudioValues, JSON.parse(savedAudio));
  }
} catch (e) {
  console.error(e);
}
