import { PublicSpaceNetworkedComponent } from "@game/mini/publicspace/components/scenecomponents/PublicSpaceNetworkedComponent";
import { TFurniturePlacement } from "@game/engine/components/scenecomponents/furniture/FurnitureComponent";
import { PublicSpaceOpCodes } from "@common/modules/publicspace/opcodes/PublicSpaceOpCodes";
import { dispatchEvent, EnumNetworkEvents } from "@shared/Events";
import CustomizableRoomScene from "@game/mini/room/CustomizableRoomScene";
import Network from "@network";
import FurnitureObject from "@game/engine/components/scenecomponents/furniture/furniture-objects/FurnitureObject";
import { MatchData } from "@heroiclabs/nakama-js";
import { IInteractiveFurnitureUseResponse } from "@common/modules/rooms/interfaces/FurnitureOperations";
import { IInteractiveFurnitureData } from "@common/modules/rooms/interfaces/IInteractiveFurnitureData";
import { IPlayerPermissionsChangeRequest } from "@common/modules/rooms/interfaces/PlayerOperations";
import { TRoomRole } from "@shared/types";

export class RoomNetworkedComponent extends PublicSpaceNetworkedComponent {
  constructor() {
    super("room");
  }

  protected override onMatchData(matchData: MatchData) {
    super.onMatchData(matchData);

    const scene = this._scene as CustomizableRoomScene;

    switch (matchData.op_code) {
      case PublicSpaceOpCodes.OnFurnitureAdded:
        const addData: { userId: string; placement: TFurniturePlacement } =
          JSON.parse(this.decodeMatchData(matchData.data));

        if (addData.userId !== scene.localPlayerId) {
          scene.addFurnitureFromBackend(addData.placement).finally();
        }
        break;
      case PublicSpaceOpCodes.OnFurnitureMoved:
        const moveData: { userId: string; placement: TFurniturePlacement } =
          JSON.parse(this.decodeMatchData(matchData.data));

        if (moveData.userId !== scene.localPlayerId) {
          scene.moveFurnitureFromBackend(moveData.placement);
        }
        break;
      case PublicSpaceOpCodes.OnFurnitureRemoved:
        const removeData: { userId: string; furnitureId: string } = JSON.parse(
          this.decodeMatchData(matchData.data),
        );

        if (removeData.userId !== scene.localPlayerId) {
          scene.removeFurnitureFromBackend(removeData.furnitureId);
        }
        break;
      case PublicSpaceOpCodes.OnPlayerKicked:
        const playerKickData: { author: string; playerId: string } = JSON.parse(
          this.decodeMatchData(matchData.data),
        );

        if (playerKickData.playerId === scene.localPlayerId) {
          scene.onKick(playerKickData.author);
        }
        break;
      case PublicSpaceOpCodes.OnPlayerPermissionsChanged:
        const permissionsChangeRequest: IPlayerPermissionsChangeRequest =
          JSON.parse(this.decodeMatchData(matchData.data));

        scene.onPlayerPermissionsChanged(permissionsChangeRequest);
        break;
    }
  }

  public sendFurnitureAdded(furniturePlacement: TFurniturePlacement) {
    const scene = this._scene as CustomizableRoomScene;

    Network.rpc("roomFurnitureAdd", {
      key: scene.roomId,
      furnitureData: furniturePlacement,
    }).then(() =>
      dispatchEvent(EnumNetworkEvents.NetworkRequestUserActiveNfts),
    );

    this.sendMatchState(PublicSpaceOpCodes.OnFurnitureAdded, {
      id: furniturePlacement.id,
      name: furniturePlacement.name,
      x: furniturePlacement.position.x,
      y: furniturePlacement.position.y,
      z: furniturePlacement.position.z,
      direction: furniturePlacement.direction,
    });
  }

  public sendFurnitureMoved(furniturePlacement: TFurniturePlacement) {
    const scene = this._scene as CustomizableRoomScene;

    Network.rpc("roomFurnitureMove", {
      key: scene.roomId,
      furnitureData: furniturePlacement,
    }).finally();

    this.sendMatchState(PublicSpaceOpCodes.OnFurnitureMoved, {
      id: furniturePlacement.id,
      name: furniturePlacement.name,
      x: furniturePlacement.position.x,
      y: furniturePlacement.position.y,
      z: furniturePlacement.position.z,
      direction: furniturePlacement.direction,
    });
  }

  public sendFurnitureRemoved(furniturePlacement: TFurniturePlacement) {
    const scene = this._scene as CustomizableRoomScene;

    Network.rpc("roomFurnitureRemove", {
      key: scene.roomId,
      furnitureData: furniturePlacement,
    }).then(() =>
      dispatchEvent(EnumNetworkEvents.NetworkRequestUserActiveNfts),
    );

    this.sendMatchState(PublicSpaceOpCodes.OnFurnitureRemoved, {
      id: furniturePlacement.id,
    });
  }

  public sendPlayerKick(playerId: string) {
    this.sendMatchState(PublicSpaceOpCodes.OnPlayerKickRequest, {
      playerId: playerId,
    });
  }

  public sendRoleChangeRequest(data: { playerId: string; role: TRoomRole }) {
    this.sendMatchState(
      PublicSpaceOpCodes.OnPlayerPermissionsChangeRequest,
      data,
    );
  }
}
