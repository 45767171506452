export default class PriorityQueue<T> {
  data: [number, T][] = [];

  public isEmpty(): boolean {
    return this.data.length === 0;
  }

  public peek(): T {
    return this.isEmpty() ? null : this.data[0][1];
  }

  public pop(): T {
    return this.isEmpty() ? null : this.data.pop()[1];
  }

  public push(element: T, priority: number) {
    if (this.isEmpty()) {
      this.data.push([priority, element]);
      return;
    }

    for (let index = 0; index < this.data.length; ++index) {
      if (this.data.length - 1 === index) {
        this.data.push([priority, element]);
        return;
      }

      if (this.data[index][0] > priority) {
        this.data.splice(index, 0, [priority, element]);
        return;
      }
    }
  }

  public size(): number {
    return this.data.length;
  }
}
