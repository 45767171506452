import { currentAccount } from "@shared/Global";
import { computed, reactive } from "vue";

// const _currentRoomData = computed(() => {});

export const currentRoomData = reactive({
  id: "",
  permissions: {} as { [key: string]: any },
  map: "",
  furniture: [],
});

export const canEditRoom = computed(() => {
  if (!currentAccount || !currentAccount.user || !currentAccount.user.id) {
    return false;
  }
  const currentUserRole = currentRoomData.permissions[currentAccount.user.id];
  if (currentUserRole && currentUserRole == "owner") {
    return true;
  }
  return false;
});

export const currentPlayerLevel = reactive({
  level: 0,
  experience: 0,
  experienceGoal: 0,
});
