import { OrderableType } from "@game/engine/enums/OrderableType";
import IIsometricOrderable from "@game/engine/interfaces/IIsometricOrderable";
import AnimatedIsometricAvatarComponent from "@game/engine/components/entitycomponents/AnimatedIsometricAvatarComponent";
import IsometricCharacter from "@game/engine/IsometricCharacter";
import { Direction } from "@game/engine/navigation/Direction";
import IsometricGameScene from "@game/engine/scenes/IsometricGameScene";
import ChatComponent from "@game/engine/components/entitycomponents/ChatComponent";
import { formatNftName, validateCharacterName } from "@shared/Helpers";
import HaloComponent from "@game/engine/components/entitycomponents/HaloComponent";
import { EmoteComponent } from "@engine/components/entitycomponents/EmoteComponent";
import { IConsumable } from "@common/interfaces/IConsumable";
import Sprite = Phaser.GameObjects.Sprite;

export default class OrderableIsometricCharacter
  extends IsometricCharacter
  implements IIsometricOrderable
{
  public orderableType: OrderableType.Movable;
  protected _animatedIsometricAvatarComponent: AnimatedIsometricAvatarComponent;
  protected _chatComponent: ChatComponent;
  protected _emoteComponent: EmoteComponent;

  public get characterName() {
    return this._animatedIsometricAvatarComponent.characterName;
  }

  public get sprite(): Sprite {
    return this._animatedIsometricAvatarComponent.sprite;
  }

  constructor(
    scene,
    x,
    y,
    characterName: string = "mr_anonymous",
    role?: string,
    local: boolean = false,
  ) {
    super(scene, x, y);
    characterName = formatNftName(characterName);
    validateCharacterName(characterName);
    // Adding an animated avatar that hooksitself into the character and displays the correct animation based on the player's movement
    this._animatedIsometricAvatarComponent =
      this.addComponent<AnimatedIsometricAvatarComponent>(
        new AnimatedIsometricAvatarComponent(
          this.scene as IsometricGameScene,
          characterName,
        ),
      );

    // Add a neat little floating component if needed
    if (["admin", "moderator"].indexOf(role) > -1) {
      this.addComponent(
        new HaloComponent("/assets/game/halos/crown.png", role),
      );
    }

    if (["OG"].indexOf(role) > -1) {
      this.addComponent(
        new HaloComponent("/assets/game/halos/crown_vip.png", role),
      );
    }

    if (["ONE"].indexOf(role) > -1) {
      this.addComponent(
        new HaloComponent("/assets/game/halos/number_one.png", role),
      );
    }

    // Create and add the chat component so we can add messages above our head
    this._chatComponent = this.addComponent<ChatComponent>(
      new ChatComponent(local),
    );
    // Move it slighly above the character
    this._chatComponent.offset.y = -160;

    this._emoteComponent = this.createAndAddComponent(EmoteComponent);
  }

  public allowedMessage(sender_id: string): boolean {
    return this._chatComponent.allowedMessage(sender_id);
  }

  public consume(consumable: IConsumable, data?: { [key: string]: any }) {
    if (consumable.type === "emote") {
      this._emoteComponent.execute(consumable.identifier, data);
    }
  }

  public face = (direction: Direction) => {
    this._animatedIsometricAvatarComponent.faceIfNotAlreadyFacing(direction);
  };

  public say(
    message: string,
    remote?: boolean,
    textStyle?: Phaser.Types.GameObjects.Text.TextStyle,
  ): void {
    this._chatComponent.say(message, remote, textStyle);
  }

  public setDepth(value: number): this {
    if (this.depth === value) {
      return this;
    }
    return super.setDepth(value);
  }

  public startTypingAnimation(): void {
    this._chatComponent.startTypingAnimation();
  }

  public stopTypingAnimation(): void {
    this._chatComponent.stopTypingAnimation();
  }

  public updateCharacterName(characterName: string) {
    this._animatedIsometricAvatarComponent.characterName = characterName;
  }
}
