/* eslint-disable prettier/prettier */
import AudioComponent from "@game/engine/components/scenecomponents/AudioComponent";
import Button from "@game/engine/components/ui/Button";
import GameScene from "@game/engine/scenes/GameScene";
import { PlinkoRisks } from "./common/enums/PlinkoRisks";
import GainedTextEffect from "@game/engine/gameobjects/effects/GainedTextEffect";
import TrailParticleEmitterManager from "@game/engine/gameobjects/effects/TrailParticleEmitterManager";
import Container = Phaser.GameObjects.Container;
import Sprite = Phaser.GameObjects.Sprite;
import TileSprite = Phaser.GameObjects.TileSprite;
import Text = Phaser.GameObjects.Text;
import RenderTexture = Phaser.GameObjects.RenderTexture;
import ParticleEmitterManager = Phaser.GameObjects.Particles.ParticleEmitterManager;
import Vector2Like = Phaser.Types.Math.Vector2Like;
import LoadingSceneComponent from "@engine/components/scenecomponents/LoadingSceneComponent";
import { currentAccount } from "@shared/Global";
import { PlinkoNetworkedComponent } from "./components/PlinkoNetworkedComponent";
import { mobilePortrait } from "@/mobile/postMessage";
import { isNativeMobileApp } from "@/mobile/isOnIOS";

export function isAllowedPlinkoEntry(data: { [key: string]: string }): {
  allowed: boolean;
  message?: string;
} {
  if (currentAccount.wallet[data.currency] < 100) {
    return {
      allowed: false,
      message: `You dont have the minimum amount of 100 ${data.currency} to enter.`,
    };
  }
  return { allowed: true };
}

export default class PlinkoScene extends GameScene {
  // set up scene key
  public static SceneKey: string = "PlinkoScene";
  //Network component
  public networkedComponent: PlinkoNetworkedComponent;
  private _addBallsButton: Button;
  private _addRowButton: Button;
  //global variable to know if the game is ongoing
  private _animationOngoing: boolean;
  //Audio component
  private _audioManager: AudioComponent;
  private _ballCountBackground: RenderTexture;
  private _ballCountText: Text;
  //global variable for counting bounces of the ball on dividers
  private _ballCounter: number;
  private _ballText: Text;
  private _balls: Sprite[];
  private _basketLabels: Container;
  private _basketSize: number;
  private _baskets: Container;
  private _bgCloudContainer: Container;
  private _bgCoinContainer: Container;
  private _bounceEffects: Sprite[][];
  private _bouncerMatrix: Sprite[][];
  private _bouncers: Container;
  //global variables of the game dimensions
  private _containerHeight: number;
  private _containerWidth: number;
  private _fullBackground: TileSprite;
  private _gameBackground: Sprite;
  private _gameBackgroundFront: Sprite;
  //global variables for game objects references
  private _gameContainer: Container;
  private _gameHeight: number;
  private _gameObjectOriginalSize: number;
  private _gameObjectSize: number;
  private _gameWidth: number;
  private _gameXScale: number = 1;
  private _gameYScale: number = 1;
  private _highRiskButton: Button;
  private _lineSpacing: number;
  private _loadingSceneComponent: LoadingSceneComponent;
  private _lowRiskButton: Button;
  private _midRiskButton: Button;
  //global variable that saves the set of moves
  private _matchData: { [key: string]: string };
  private _moveDirections: number[][];
  private _multiplierBackground: RenderTexture;
  private _multiplierBox: Text;
  private _multiplierBoxText: Text;
  private _payoutBackground: RenderTexture;
  private _payoutBox: Text;
  private _payoutBoxText: Text;
  private _priceCountText: Text;
  private _priceText: Text;
  private _priceTextBackground: RenderTexture;
  private _prizeList: number[];
  //arrays for storing the sequence of movements and prize pool
  private _prizePool: number[];
  //global variable for the risk chosen for the game
  private _risk: PlinkoRisks;
  private _rowCountBackground: RenderTexture;
  private _rowCountText: Text;
  private _rowDistance: number;
  // number of rows for Plinko
  private _rowQuantity: number;
  private _rowText: Text;
  private _standardHeight: number;
  private _standardWidth: number;
  private _startButton: Button;
  private _subtractBallsButton: Button;
  private _subtractRowButton: Button;
  private _trailParticleEmitterManager: TrailParticleEmitterManager;
  private _trailParticles: ParticleEmitterManager;
  private _tweenMaxScale: number;
  private _uiContainer: Container;
  private _xMargin: number;
  private _yMargin: number;
  private _yOffset: number;
  private _yTopOffset: number;
  private _onlyOneStart: boolean;
  constructor() {
    super({
      key: PlinkoScene.SceneKey,
    });
    //margin and offset values, defined according to actual UI size and game object sizes, may need to be changed when those change.
    this._xMargin = 100; //horizontal margin to be inside the rocks (apply to both sides)
    this._yMargin = 0; //vertical margin to be inside the rocks (apply to both sides)
    this._yTopOffset = 150; //vertical offset on the top of the game container (start the game after the pipe on top of background)
    this._yOffset = 100; //vertical offset for the game container (game container stays under the top UI)
    //Dimensional values initialized - may be taken from the canvas size or platform if possible
    this._containerHeight = 980;
    this._standardHeight = 980;
    this._containerWidth = 912;
    this._standardWidth = 912;
    //Variables which will be taken from server side or the overlay input.
    this._rowQuantity = 12; //this will be updated in initialize, standard value for testing min 8, max 15
    this._prizePool = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    ];
    this._ballCounter = 5; //this will be updated in initialize, standard value for testing
    this._risk = PlinkoRisks.HIGH; // LOW /MEDIUM/HIGH options - this will be updated in initialize, standard value for testing

    this._animationOngoing = false;
  }

  public async initializeGame(
    rowQuantity: number,
    ballQuantity: number,
    risk: PlinkoRisks,
    multipliers: number[],
  ) {
    if (this._gameContainer != null) {
      // if the game container is not empty, clear all game objects to start from scratch, but first save the UI and background
      this._gameContainer.remove(this._uiContainer);
      this._gameContainer.remove(this._bgCloudContainer);
      this._gameContainer.remove(this._bgCoinContainer);
      this._gameContainer.iterate(function (child) {
        child.destroy();
      });
      this._gameContainer.destroy();
      //clear bouncer effects array
      this._bounceEffects = [];
      this._bouncerMatrix = [];
    }

    //redefine proprieties according to input
    this._rowQuantity = rowQuantity;
    this._ballCounter = ballQuantity;

    this._risk = risk;

    //update prize pool
    this._prizePool = multipliers;

    //clear prize list
    this._prizeList = [];

    //clear ball list
    this._balls = [];

    //update UI with recieved values
    this.updateUI();

    //define that it has not started
    this._onlyOneStart = false;

    // create the game area using the game objects
    this.setupGameArea();
    this._loadingSceneComponent // Alright we can remove this now
      .setText("done");
    this._loadingSceneComponent.hide();
  }

  public startGame = async (finalMovements: number[][]) => {
    this._animationOngoing = true;
    // first reset current multiplier and payout
    this._multiplierBoxText.setText("1.00");
    this._payoutBoxText.setText("0");

    this._moveDirections = finalMovements;
    this.clickStart(0, this._moveDirections);
  };

  protected addBalls() {
    if (this._ballCounter < 10) {
      this._ballCounter = this._ballCounter + 1;
      this._ballCountText.setText(this._ballCounter.toString());
      this._priceCountText.setText((this._ballCounter * 100).toString());
      this.events.emit("updateGame");
    }
  }

  protected ballBounced(
    moves: number[],
    bounceCounter: number,
    moveType: String,
  ) {
    //method called when a ball hits a divider, makes sound and triggers animation
    this._audioManager.play("bouncing", false, 0.7);

    //calculate position of the current bounce
    let index: number = 0;

    for (let i: number = 0; i < bounceCounter; i++) {
      index += moves[i];
    }

    index = (index + bounceCounter) / 2 + 1;
    //create the animations for the current bounce
    if (bounceCounter < this._rowQuantity - 1) {
      // if this is a bouncer

      // if this bounce is a rebound to the side, adjust index to find where bounced
      index = moveType === "rebound" ? index + moves[bounceCounter] : index;

      const bounceEffect = this._bounceEffects[bounceCounter][index];
      const bouncer = this._bouncerMatrix[bounceCounter][index];

      this.tweens.add({
        targets: bounceEffect,
        duration: 300,
        alpha: { from: 0.8, to: 0 },
        scale: {
          from: 1.4,
          to: 0.5,
        },
        ease: Phaser.Math.Easing.Quadratic.Out,
      });

      this.tweens.add({
        targets: bouncer,
        duration: 50,
        scale: 1.5,
        ease: Phaser.Math.Easing.Quadratic.Out,
      });

      this.tweens.add({
        targets: bouncer,
        duration: 500,
        scale: 1,
        ease: Phaser.Math.Easing.Back.Out,
        delay: 50,
      });
    }
  }

  protected ballBouncer = (
    target,
    ballIndex: number,
    moves: number[],
    bounceCounter: number,
  ) => {
    // creates the tweens that will move the ball through each line of plinko according to the order provided
    const duration: number =
      Phaser.Math.RND.integerInRange(480, 520) * (1 + this._rowQuantity / 160);

    // tween for the ball movement, bounces to the other side then fall
    this.tweens.timeline({
      //tween that controls the X movement, goes to the divider on the same line then to the middle
      targets: target,
      tweens: [
        {
          x: {
            value:
              target.x +
              moves[bounceCounter] * (this._lineSpacing - target.width / 2),
            duration: (duration * 9) / 12,
          },
          ease: Phaser.Math.Easing.Sine.Out,
          onComplete: () => {
            this.ballBounced(moves, bounceCounter, "rebound");
          }, //when arriving at next divider call function for bouncing (makes sounds+animation)
          onCompleteScope: this,
        },
        {
          x: {
            value: target.x + (moves[bounceCounter] * this._lineSpacing) / 2,
            duration: (duration * 3) / 12,
          },
          ease: Phaser.Math.Easing.Sine.Out,
        },
      ],
    });
    this.tweens.timeline({
      //tween that controls the Y movment
      targets: target,
      tweens: [
        {
          //goes up a bit to create an arc to bounc on the same layer
          y: {
            value: target.y - this._rowDistance / 4,
            duration: (duration * 3.5) / 12,
          },
          ease: "Sine.easeOut",
        },
        {
          //arrive at the same layer level
          y: {
            value: target.y + target.width,
            duration: (duration * 4.5) / 12,
          },
          ease: "Sine.easeIn",
        },
        {
          // falls down to the next layer
          y: {
            value: target.y + this._rowDistance,
            duration: (duration * 3) / 12,
          },
          ease: Phaser.Math.Easing.Quadratic.In,
        },
      ],
      onComplete: () => {
        //recursive call for the next step of movement
        //if it did not arrive at a basket then recursive call for the next movement, otherwise finish movement with basketCatch
        if (bounceCounter < this._rowQuantity - 2) {
          this.ballBounced(moves, bounceCounter + 1, "normal"); // play sounds and animation
          this.ballBouncer(target, ballIndex, moves, ++bounceCounter); // recursive call for next step of movement
        } else {
          this.basketCatch(moves, ballIndex, bounceCounter + 1);
        }
      },
      onCompleteScope: this,
    });
  };

  protected basketCatch(
    moves: number[],
    ballIndex: number,
    bounceCounter: number,
  ) {
    //execute when the ball arrive at the bottom row with the prize pool
    this._balls[ballIndex].destroy();
    this._audioManager.play("ballPipe");

    // loop calculate the index of the basket that the ball arrived in.
    let index: number = 0;
    for (let i: number = 0; i < moves.length; i++) {
      index += moves[i];
    }
    index = (index + (this._rowQuantity - 1)) / 2;

    // Flash the basket
    const basket = this._bounceEffects[bounceCounter][index];
    this.tweens.add({
      targets: basket,
      duration: 256,
      alpha: { from: 0, to: 1 },
      yoyo: true,
    });

    this._prizeList.push(this._prizePool[index]);

    let totalPrize: number = 0;
    for (let i: number = 0; i < this._prizeList.length; i++) {
      totalPrize += this._prizeList[i] * 100;
    }

    const worldTransformMatrix = basket.getWorldTransformMatrix();
    const startPosition: Vector2Like = {
      x: worldTransformMatrix.tx,
      y: worldTransformMatrix.ty,
    };

    // Show how much we've scored above the basket
    new GainedTextEffect(
      this,
      startPosition.x,
      startPosition.y - 16,
      "+" + (this._prizeList[this._prizeList.length - 1] * 100).toFixed(0),
    );

    const targetWorldTransformMatrix =
      this._payoutBoxText.getWorldTransformMatrix();

    // Show a nice trail flying towards the payout
    const finalPrize = Math.trunc(totalPrize);
    this._trailParticleEmitterManager.animate(
      startPosition.x,
      startPosition.y,
      targetWorldTransformMatrix.tx,
      targetWorldTransformMatrix.ty,
      () => {
        this.tweens.add({
          ease: "cubic.easeinOut",
          targets: this._payoutBoxText,
          props: {
            scaleX: 1.4,
            scaleY: 1.4,
          },
          duration: 64,
          yoyo: true,
        });
        this._payoutBoxText.setText(finalPrize.toString());
      },
    );

    // Check if the last ball has fallen
    if (ballIndex === this._ballCounter - 1) {
      //if this is the last ball
      this._audioManager.stopBackgroundAudio();
      const sound = this._audioManager.play("victory");
      sound.once("complete", () => {
        this._audioManager.playBackgroundAudio(true, 0.8);
      }); //restart the bgm after victory sound is finished

      this._prizeList.push(this._prizePool[index]);

      const finalMulti = totalPrize / (this._ballCounter * 100);
      this._multiplierBoxText.setText(finalMulti.toFixed(2));

      this.networkedComponent.sendEndOfAnimation();

      this._uiContainer.iterate(function (child) {
        if (child instanceof Button) {
          child.setDisabled(false);
        }
      });
      this._onlyOneStart = false;
      this._animationOngoing = false;
      this.visibilityControls(true);
      //event dispatch that calls the overlay window a the end of the game displaying the total prizes
      //dispatchEvent(EnumGameEvents.GamePlinkoGameOver);
    }
  }

  protected clickStart(ballIndex: number, moveSet: number[][]) {
    //Initialize the balls falling when the button begin is clicked
    // get the array of movements for the current ball
    const moves: number[] = moveSet[ballIndex];

    //if it did not release all the balls, release the current ball by index
    if (ballIndex < this._ballCounter) {
      //create a new ball
      const currentBall = new Sprite(
        this,
        this._containerWidth / 2,
        -100,
        "ball" + this._gameObjectSize,
      ).setOrigin(0.5, 0.5);
      //add ball to the game container.
      this._gameContainer.add(currentBall); // add ball to the container
      this._gameContainer.bringToTop(currentBall); // make sure the ball is visible
      this._gameContainer.bringToTop(this._gameBackgroundFront); // make sure the pipe is in front of the ball
      this._balls.push(currentBall); //store ball reference on its list

      //create movement that drops the ball
      this.tweens.timeline({
        targets: currentBall,
        tweens: [
          {
            y: {
              value:
                this._yMargin +
                this._rowDistance +
                this._yTopOffset -
                this._gameObjectSize / 2,
              duration: 600,
              ease: "Sine.easeIn",
            },
          },
        ], // after dropping it calls the next movement and recursion to create the next ball
        onComplete: () => {
          this.ballBouncer(currentBall, ballIndex, moves, 0),
            this.clickStart(++ballIndex, moveSet),
            this.ballBounced(moves, 0, "normal");
        },
      });
    }
  }

  protected async create() {
    super.create();

    if (isNativeMobileApp()) {
      mobilePortrait();
    }

    // For pretty trails
    this._trailParticleEmitterManager = new TrailParticleEmitterManager(this);

    // Default background color is the same as the clouds
    this.backgroundColor = 0x9cf2ff;
    // Create networked component
    this.networkedComponent = this.addComponent<PlinkoNetworkedComponent>(
      new PlinkoNetworkedComponent(this._matchData),
    );
    await this.networkedComponent.connect();
    await this.networkedComponent.findAndJoinMatch();
    //resize function for adjusting game dimensions
    this.scale.on("resize", (gameSize) => {
      if (this._animationOngoing === false) {
        //get new size
        this._gameWidth = gameSize.width;
        this._gameHeight = gameSize.height - 2 * this._yOffset;
        //set the scale from previous size to new size
        this._gameXScale = this._gameWidth / this._standardWidth;
        this._gameYScale = this._gameHeight / this._standardHeight;
        //update scene variables
        if (this._gameWidth < this._standardWidth) {
          //if width is smaller than maximum width change width according to scale
          this._containerWidth = this._gameWidth;
        } else {
          // else make width equal to the maximum width
          this._containerWidth = this._standardWidth;
          this._gameXScale = 1;
        }
        if (this._gameHeight < this._standardHeight) {
          //if height is smaller than maximum width change width according to scale
          this._containerHeight = this._gameHeight;
          // else make width equal to the maximum height
        } else {
          this._containerHeight = this._standardHeight;
          this._gameYScale = 1;
        }

        // update game container position
        this._gameContainer.setX(
          Math.max(this._gameWidth / 2 - this._containerWidth / 2, 0),
        );
        this._gameContainer.setY(this._yOffset);

        //update game object size if scale is less then half of original game
        if (Math.min(this._gameXScale, this._gameYScale) < 0.5) {
          if (this._gameObjectOriginalSize === 20) {
            this._gameObjectSize = 10;
          } else if (this._gameObjectOriginalSize === 30) {
            this._gameObjectSize = 20;
          }
        } else {
          this._gameObjectSize = this._gameObjectOriginalSize;
        }
        //Change the sprites images to fit the new game object size
        this._balls.forEach((x) => x.setTexture("ball" + this._gameObjectSize));
        this._bouncers.each((x) =>
          x.setTexture("bouncer" + this._gameObjectSize),
        );
        this._baskets.each((x) => {
          if (x.texture.key.includes("red")) {
            x.setTexture("red_basket" + this._gameObjectSize);
          }
          if (x.texture.key.includes("orange")) {
            x.setTexture("orange_basket" + this._gameObjectSize);
          }
          if (x.texture.key.includes("yellow")) {
            x.setTexture("yellow_basket" + this._gameObjectSize);
          }
        });

        //refresh game area
        this.requestRefreshGame();
        //update camera size
        this.cameras.resize(gameSize.width, gameSize.height);

        //update background
        this._fullBackground.setPosition(0, this.scale.gameSize.height);

        this._fullBackground.width = this.scale.gameSize.width;
      }
    });

    //create the image at the back of the game
    this._fullBackground = this.add
      .tileSprite(
        0,
        this.scale.gameSize.height,
        this.scale.gameSize.width,
        1080,
        "backgroundFull",
      )
      .setOrigin(0, 1);

    this._bgCloudContainer = new Container(this, -320, 80);
    //create clouds
    const cloud0: Sprite = this.add.sprite(0, 0, "cloud0").setOrigin(0.5, 0.5);
    this._bgCloudContainer.add(cloud0);
    const cloud1: Sprite = this.add
      .sprite(270, 90, "cloud1")
      .setOrigin(0.5, 0.5);
    this._bgCloudContainer.add(cloud1);
    const cloud2: Sprite = this.add
      .sprite(1280, 0, "cloud2")
      .setOrigin(0.5, 0.5);
    this._bgCloudContainer.add(cloud2);
    const cloud3: Sprite = this.add
      .sprite(1400, 10, "cloud3")
      .setOrigin(0.5, 0.5);
    this._bgCloudContainer.add(cloud3);
    const cloud4: Sprite = this.add
      .sprite(1300, 200, "cloud4")
      .setOrigin(0.5, 0.5);
    this._bgCloudContainer.add(cloud4);
    const cloud5: Sprite = this.add
      .sprite(1600, 150, "cloud5")
      .setOrigin(0.5, 0.5);
    this._bgCloudContainer.add(cloud5);

    //make clouds move!
    this.tweens.add({
      targets: this._bgCloudContainer,
      x: {
        value: this._bgCloudContainer.x + 10,
        duration: 4000,
        yoyo: true,
        ease: "Quad.easeInOut",
      },
      loop: -1,
    });
    this.tweens.add({
      targets: this._bgCloudContainer,
      y: {
        value: this._bgCloudContainer.y + 10,
        duration: 2500,
        yoyo: true,
        ease: "Quad.easeInOut",
      },
      loop: -1,
    });

    this._bgCoinContainer = new Container(this, -220, 70);
    //create left coins
    for (let i = 0; i < 3; i++) {
      const coin = this.add.sprite(60 * i, 0, "coin").setOrigin(0.5, 0.5);
      this._bgCoinContainer.add(coin);
    }
    // create right coins
    for (let i = 0; i < 4; i++) {
      const coin = this.add
        .sprite(1275, 70 + 60 * i, "coin")
        .setOrigin(0.5, 0.5);
      this._bgCoinContainer.add(coin);
    }
    this.tweens.add({
      targets: this._bgCoinContainer,
      y: {
        value: this._bgCoinContainer.y + 20,
        duration: 2000,
        yoyo: true,
        ease: "Sine.easeInOut",
      },
      loop: -1,
    });

    //initialize game UI
    this.createUI();

    //initialize update event listener
    this.events.on("updateGame", this.requestUpdateData, this);

    //update visuals when joined
    this.networkedComponent.eventEmitter.once<"userJoined" | "userLeft">(
      "userJoined",
      this.requestRefreshGame,
      this,
    );

    // pause all sounds except BGM
    this._audioManager.pause("bouncing");
    this._audioManager.pause("ballPipe");
    this._audioManager.pause("victory");
    // start bgm
    this._audioManager.playBackgroundAudio(true, 0.8);
  }

  protected createBackground() {
    //set background image and shape it to the maximum game size (it sets the limits of the game)
    this._gameBackground = this.add
      .sprite(this._containerWidth / 2, this._containerHeight / 2, "background")
      .setOrigin(0.5, 0.5);
    this._gameBackgroundFront = this.add
      .sprite(
        this._containerWidth / 2 + 3,
        this._gameBackground.getTopCenter().y + 244,
        "backgroundFront",
      )
      .setOrigin(0.5, 0.5);
  }

  protected createBaskets(
    rowNumber: number,
    rowYPosition: number,
    prizeList: number[],
  ) {
    // create all the baskets at the end of the plinko tree
    // calculate the horizontal center of the game area
    const xCenter = this._containerWidth / 2;
    //create the containers for baskets and its labels
    this._baskets = new Container(this, 0, 0);
    this._basketLabels = new Container(this, 0, 0);
    //calculate the first X position value based on the current row number
    let xPosition: number = xCenter - ((rowNumber - 1) / 2) * this._lineSpacing;
    // define variable to collect the effects created on this line.
    const lineEffects: Sprite[] = [];
    //variable to save the color of each basket
    const colors: string[] = [];
    //loop creates all the baskets in this row
    for (let i: number = 0; i < this._rowQuantity; i++) {
      //create the basket variable
      let basket: Sprite;
      // depending on the position of the basket it is created with a different color, red on the outermost baskets, Yellow for the middle ones and orange for in between
      if (i > this._rowQuantity / 2) {
        // if the ball is on the right half of the baskets, replicate the color of its symmetric basket
        //creates the basket sprite
        basket = new Sprite(
          this,
          xPosition,
          rowYPosition,
          colors[this._rowQuantity - 1 - i] + "_basket" + this._basketSize * 10,
        ).setData("prize", this._prizePool[i]); //.setScale(Math.min(this._gameXScale,this._gameYScale)*(10/this._rowQuantity));
        //add the basket to the container
        this._baskets.add(basket);
        //create the basket visual effect (lighting)
        const bounceEffect: Sprite = new Sprite(
          this,
          xPosition + 1,
          rowYPosition,
          colors[this._rowQuantity - 1 - i] +
            "_basket_light" +
            this._basketSize * 10,
        ); //.setScale(Math.min(this._gameXScale,this._gameYScale)*(10/this._rowQuantity));
        bounceEffect.setAlpha(0);
        //add effect to its list
        lineEffects.push(bounceEffect);
      } else if (i < this._rowQuantity / 6) {
        //creates the basket sprite
        basket = new Sprite(
          this,
          xPosition,
          rowYPosition,
          "red_basket" + this._basketSize * 10,
        ).setData("prize", this._prizePool[i]); //.setScale(Math.min(this._gameXScale,this._gameYScale)*(10/this._rowQuantity));
        //add the basket to the container
        this._baskets.add(basket);
        //create the basket visual effect (lighting)
        const bounceEffect: Sprite = new Sprite(
          this,
          xPosition + 1,
          rowYPosition,
          "red_basket_light" + this._basketSize * 10,
        ); //.setScale(Math.min(this._gameXScale,this._gameYScale)*(10/this._rowQuantity));
        bounceEffect.setAlpha(0);
        //add effect to its list
        lineEffects.push(bounceEffect);
        //add the color to the color list
        colors.push("red");
      } else if (i < (this._rowQuantity * 2) / 6) {
        //creates the basket sprite
        basket = new Sprite(
          this,
          xPosition,
          rowYPosition,
          "orange_basket" + this._basketSize * 10,
        ).setData("prize", this._prizePool[i]); //.setScale(Math.min(this._gameXScale,this._gameYScale)*(10/this._rowQuantity));
        //add the basket to the container
        this._baskets.add(basket);
        //create the basket visual effect (lighting)
        const bounceEffect: Sprite = new Sprite(
          this,
          xPosition + 1,
          rowYPosition,
          "orange_basket_light" + this._basketSize * 10,
        ); //.setScale(Math.min(this._gameXScale,this._gameYScale)*(10/this._rowQuantity));
        bounceEffect.setAlpha(0);
        //add effect to its list
        lineEffects.push(bounceEffect);
        //add the color to the color list
        colors.push("orange");
      } else {
        //creates the basket sprite
        basket = new Sprite(
          this,
          xPosition,
          rowYPosition,
          "yellow_basket" + this._basketSize * 10,
        ).setData("prize", this._prizePool[i]); //.setScale(Math.min(this._gameXScale,this._gameYScale)*(10/this._rowQuantity));
        //add the basket to the container
        this._baskets.add(basket);
        //create the basket visual effect (lighting)
        const bounceEffect: Sprite = new Sprite(
          this,
          xPosition + 1,
          rowYPosition,
          "yellow_basket_light" + this._basketSize * 10,
        ); //.setScale(Math.min(this._gameXScale,this._gameYScale)*(10/this._rowQuantity));
        bounceEffect.setAlpha(0);
        //add effect to its list
        lineEffects.push(bounceEffect);
        //add the color to the color list
        colors.push("yellow");
      }
      // create basketLabel+labelText container
      const basketCombo: Container = new Container(
        this,
        xPosition,
        rowYPosition + basket.height / 2,
      );
      // create the label for the current basket
      const basketLabel: Sprite = new Sprite(
        this,
        0,
        0,
        "basket_label" + this._basketSize * 10,
      );
      // create the text to be positioned inside the label sprite

      const basketLabelText: Text = new Text(
        this,
        0,
        -2,
        prizeList[i].toFixed(1),
        { color: "#000", align: "center" },
      ).setOrigin(0.5, 0.5);

      basketLabelText.setFontSize(basketLabel.height / 2 - 30 / rowNumber);

      // add both to combo container
      basketCombo.add(basketLabel);
      basketCombo.add(basketLabelText);
      this._basketLabels.add(basketCombo);
      //set up intractable on the combo container
      basketCombo.setInteractive(
        new Phaser.Geom.Rectangle(
          -basketLabel.displayWidth / 2,
          -basketLabel.displayHeight / 2,
          basketLabel.displayWidth,
          basketLabel.displayHeight,
        ),
        Phaser.Geom.Rectangle.Contains,
      );
      // on pointer over increase the size of the label as much as possible then adjust font size
      basketCombo.on(
        "pointerover",
        function (pointer, localX, localY, event) {
          (basketCombo.list[0] as Sprite).setTexture(
            "basket_label" + Math.min(this._basketSize * 10 + 30, 60),
          );
          (basketCombo.list[1] as Text).setFontSize(
            (basketCombo.list[0] as Sprite).height / 2 - 30 / rowNumber,
          );
        },
        this,
      );
      // on pointer moving out return the size of the label to original then adjust font size
      basketCombo.on(
        "pointerout",
        function (pointer, localX, localY, event) {
          (basketCombo.list[0] as Sprite).setTexture(
            "basket_label" + this._basketSize * 10,
          );
          (basketCombo.list[1] as Text).setFontSize(
            (basketCombo.list[0] as Sprite).height / 2 - 30 / rowNumber,
          );
          //(basketCombo.list[1] as Text).setScale(Math.min(this._gameXScale,this._gameYScale)*(10/(this._rowQuantity)));
        },
        this,
      );

      //update current horizontal position
      xPosition += this._lineSpacing;
    }
    //add the line of effects to its list
    this._bounceEffects.push(lineEffects);
  }

  protected createBouncers(rowNumber: number): number {
    // creates the dividers according to how many rows the game has and return the Y position of the last row
    // set global parameters based on current game scale
    let rowYPosition = this._yMargin + this._rowDistance + this._yTopOffset;
    const xCenter = this._containerWidth / 2;
    this._bouncers = new Container(this, 0, 0);

    for (let i: number = 0; i < rowNumber - 1; i++) {
      //iterate on the rows
      let xPosition = xCenter - ((i + 2) / 2) * this._lineSpacing;
      const lineEffects: Sprite[] = [];
      const lineBouncers: Sprite[] = [];

      for (let j: number = 0; j < i + 3; j++) {
        //iterate from left to right create each divider (creates 2 extra at the edges for extra bounciness)
        // create the bouncer and bounce effect
        const bounce: Sprite = new Sprite(
          this,
          xPosition,
          rowYPosition,
          "bouncer" + this._gameObjectSize,
        ); //.setOrigin(0.5);//.setScale(Math.min(this._gameXScale,this._gameYScale)*(10/this._rowQuantity));
        const bounceEffect: Sprite = new Sprite(
          this,
          xPosition,
          rowYPosition,
          "bouncerEffect" + (this._gameObjectSize + 10),
        ); //.setOrigin(0.5);
        bounceEffect.setAlpha(0);
        //add the created values to the global variables
        lineEffects.push(bounceEffect);
        lineBouncers.push(bounce);
        this._bouncers.add(bounce);
        //update line position (moves from left to right according to the spacing defined globally)
        xPosition += this._lineSpacing;
      }
      this._bounceEffects.push(lineEffects);
      this._bouncerMatrix.push(lineBouncers);
      //update the row position (goes from top to bottom according to the spacing defined globally)
      rowYPosition += this._rowDistance;
    }
    //return the next position to be used after the last bouncers.
    return rowYPosition;
  }
  protected createGameContainer() {
    //create the game container and places each game object within it.
    //creates the container, create it in the middle of the screen on horizontal axis (or on the left size if the screen is small), on vertical axis uses offset to be on safe area
    this._gameContainer = new Container(
      this,
      Math.max(this._gameWidth / 2 - this._containerWidth / 2, 0),
      this._yOffset,
    );
    //add game container to scene
    this.add.existing(this._gameContainer);
    //add each object container to the container
    this._gameContainer.add(this._bgCloudContainer);
    this._gameContainer.add(this._gameBackground);
    this._gameContainer.add(this._bgCoinContainer);
    this._gameContainer.add(this._baskets);
    this._gameContainer.add(this._bouncers);
    for (let i: number = 0; i < this._bounceEffects.length; i++) {
      this._gameContainer.add(this._bounceEffects[i]);
    }
    this._gameContainer.add(this._basketLabels);
    this._gameContainer.add(this._gameBackgroundFront);

    this._gameContainer.add(this._uiContainer);
  }

  protected createUI() {
    const textColor = "#2D5D6A";
    const textStyleAmount = {
      align: "center",
      color: "#2D5D6A",
      fixedHeight: 42,
      fixedWidth: 64,
      font: "28px depixel",
    };
    const textStyleLabel = {
      color: "#ffffff",
      font: "16px depixel",
    };

    const textStyleValue = {
      align: "center",
      font: "20px depixel",
      color: textColor,
      fixedWidth: 128,
    };

    // Amount of Rows part
    // this._rowCountBackground = this.add.sprite(55, 55, "numberBackground");
    this._rowCountBackground = this.addNineslicedBackgroundAt(24, 55, 64);
    this._rowCountText = this.add.text(
      24,
      34,
      this._rowQuantity.toString(),
      textStyleAmount,
    );
    this._rowText = this.add.text(-17, 10, "Amount of Rows: ", textStyleLabel);

    // Amount of Balls part
    const subtractBallsButton = new Button(
      this,
      0,
      72,
      "minusButtonNormal",
      "",
      "minusButtonPressed",
      "minusButtonHover",
      "minusButtonDisabled",
    );
    this._subtractBallsButton = subtractBallsButton;
    subtractBallsButton.addOnClickEvent("ballSubtract");
    this.events.on("ballSubtract", this.subtractBalls, this);

    const addBallsButton = new Button(
      this,
      55,
      72,
      "plusButtonNormal",
      "",
      "plusButtonPressed",
      "plusButtonHover",
      "plusButtonDisabled",
    );
    this._addBallsButton = addBallsButton;
    addBallsButton.addOnClickEvent("ballAdd");
    this.events.on("ballAdd", this.addBalls, this);

    this._ballText = this.add.text(
      -17,
      100,
      "Amount of Balls: ",
      textStyleLabel,
    );
    // this._ballCountBackground = this.add.sprite(55, 145, "numberBackground");
    this._ballCountBackground = this.addNineslicedBackgroundAt(24, 145, 64);
    this._ballCountText = this.add.text(
      26,
      128,
      this._ballCounter.toString(),
      textStyleAmount,
    );

    // Create start button
    this._startButton = new Button(
      this,
      27,
      124,
      "startButtonNormal",
      "",
      "startButtonPressed",
      "startButtonHover",
      "startButtonDisabled",
    );
    this._startButton.addOnClickEvent("startGame");
    this.events.on("startGame", this.requestGameStart, this);

    // Create risk choice buttons
    this._lowRiskButton = new Button(
      this,
      0,
      100,
      "lowButtonNormal",
      "",
      "lowButtonPressed",
      "lowButtonHover",
      "lowButtonDisabled",
    );
    this._lowRiskButton.addOnClickEvent("lowRisk");
    this.events.on("lowRisk", this.lowRiskClick, this);

    this._midRiskButton = new Button(
      this,
      27,
      100,
      "midButtonNormal",
      "",
      "midButtonPressed",
      "midButtonHover",
      "midButtonDisabled",
    );
    this._midRiskButton.addOnClickEvent("midRisk");
    this.events.on("midRisk", this.midRiskClick, this);

    this._highRiskButton = new Button(
      this,
      54,
      100,
      "highButtonNormal",
      "",
      "highButtonPressed",
      "highButtonHover",
      "highButtonDisabled",
    );
    this._highRiskButton.addOnClickEvent("highRisk");
    this.events.on("highRisk", this.highRiskClick, this);

    // this._priceTextBackground = this.add
    //   .sprite(230, 55, "numberBackground")
    //   .setScale(1.2, 1);
    this._priceTextBackground = this.addNineslicedBackgroundAt(190, 55);
    this._priceText = this.add.text(190, 10, "Total cost: ", textStyleLabel);
    this._priceCountText = this.add.text(
      210,
      43,
      this._ballCounter.toString(),
      {
        font: "20px depixel",
        color: textColor,
      },
    );
    this._priceCountText.setText((this._ballCounter * 100).toString());

    //create row count selector
    const subtractRowButton = new Button(
      this,
      0,
      28,
      "minusButtonNormal",
      "",
      "minusButtonPressed",
      "minusButtonHover",
      "minusButtonDisabled",
    );
    this._subtractRowButton = subtractRowButton;
    subtractRowButton.addOnClickEvent("rowSubtract");
    this.events.on("rowSubtract", this.rowSubtract, this);

    const addRowButton = new Button(
      this,
      55,
      28,
      "plusButtonNormal",
      "",
      "plusButtonPressed",
      "plusButtonHover",
      "plusButtonDisabled",
    );

    this._addRowButton = addRowButton;
    addRowButton.addOnClickEvent("rowAdd");
    this.events.on("rowAdd", this.rowAddition, this);

    let multiplierMarginRight = 650;
    let currentPayoutMargin = 650;
    let multiplierHeight = 80;
    const currentPayoutHeight = 130;
    let multiplierText = "Current Multiplier:";
    let payoutText = "Current Payout:";

    if (window.innerWidth > 560 && window.innerWidth < 860) {
      multiplierMarginRight = this._gameWidth / 2 + 140;
      currentPayoutMargin = this._gameWidth / 2 + 190;
    } else if (window.innerWidth < 560) {
      multiplierMarginRight = this._gameWidth / 2;
      currentPayoutMargin = this._gameWidth / 2;
      multiplierHeight = 160;
      multiplierText = "Multiplier";
      payoutText = "Payout:";
      //textStyleLabel
    }

    this._multiplierBox = this.add.text(
      multiplierMarginRight,
      multiplierHeight - 70,
      multiplierText,
      textStyleLabel,
    );
    // this._multiplierBackground = this.add
    //   .sprite(730, 80, "numberBackground")
    //   .setScale(1.5, 1);

    this._multiplierBackground = this.addNineslicedBackgroundAt(
      multiplierMarginRight,
      multiplierHeight - 20,
    );
    this._multiplierBoxText = this.add
      .text(
        multiplierMarginRight + 40,
        multiplierHeight - 22,
        "1.00",
        textStyleValue,
      )
      .setOrigin(0.5);
    // this._multiplierBoxText = this.add.text(666, 70, "1.00", {
    //   font: "20px depixel",
    //   color: textColor,
    // });

    this._payoutBox = this.add.text(
      currentPayoutMargin,
      currentPayoutHeight + 35,
      payoutText,
      textStyleLabel,
    );
    // this._payoutBackground = this.add
    //   .sprite(730, 180, "numberBackground")
    //   .setScale(2, 1);
    this._payoutBackground = this.addNineslicedBackgroundAt(
      currentPayoutMargin,
      currentPayoutHeight + 80,
    );
    this._payoutBoxText = this.add
      .text(
        multiplierMarginRight + 40,
        currentPayoutHeight + 78,
        "0",
        textStyleValue,
      )
      .setOrigin(0.5);

    // place all in container
    this._uiContainer.add(this._startButton);
    this._uiContainer.add(this._lowRiskButton);
    this._uiContainer.add(this._midRiskButton);
    this._uiContainer.add(this._highRiskButton);
    this._uiContainer.add(this._rowCountBackground);
    this._uiContainer.add(this._rowCountText);
    this._uiContainer.add(this._rowText);
    this._uiContainer.add(this._addRowButton);
    this._uiContainer.add(this._subtractRowButton);
    this._uiContainer.add(this._ballCountBackground);
    this._uiContainer.add(this._ballCountText);
    this._uiContainer.add(this._ballText);
    this._uiContainer.add(this._addBallsButton);
    this._uiContainer.add(this._subtractBallsButton);
    this._uiContainer.add(this._multiplierBox);
    this._uiContainer.add(this._multiplierBackground);
    this._uiContainer.add(this._multiplierBoxText);
    this._uiContainer.add(this._payoutBox);
    this._uiContainer.add(this._payoutBackground);
    this._uiContainer.add(this._payoutBoxText);
    this._uiContainer.add(this._priceText);
    this._uiContainer.add(this._priceTextBackground);
    this._uiContainer.add(this._priceCountText);
  }

  protected highRiskClick() {
    this._risk = PlinkoRisks.HIGH;
    this._highRiskButton.setDisabled(true);
    this._midRiskButton.setDisabled(false);
    this._lowRiskButton.setDisabled(false);
    this.events.emit("updateGame");
  }

  protected override onCreated(): void {
    // initialize audio component.
    const ballBounce = {
      config: {
        mute: false,
        volume: 0.7,
        rate: 0.5,
        detune: 0,
        seek: 0,
        loop: false,
        delay: 0,
      },
      key: "bouncing",
      options: { instances: 2 },
      path: "/assets/game/mini/plinko/audio/mp3/ballBounce.mp3",
    };
    const ballPipe = {
      config: {
        mute: false,
        volume: 1,
        rate: 1,
        detune: 0,
        seek: 0,
        loop: false,
        delay: 0,
      },
      key: "ballPipe",
      path: "/assets/game/mini/plinko/audio/mp3/ballHitPipe_op2.mp3",
    };
    const victorySound = {
      key: "victory",
      path: "/assets/game/mini/plinko/audio/mp3/finalSound.mp3",
    };
    const bgm = {
      config: {
        mute: false,
        volume: 0.8,
        rate: 1,
        detune: 0,
        seek: 0,
        loop: true,
        delay: 0,
      },
      key: "bgm",
      path: "/assets/game/mini/plinko/audio/mp3/plinkoBgMusic.mp3",
    };
    this._audioManager.addSoundEffects([ballBounce, ballPipe, victorySound]);
    this._audioManager.addBackgroundMusic(bgm);
  }
  protected override init(data?: object) {
    // initialize global variables according to game size
    // set game size variables
    this._matchData = (data as any)?.matchData;
    this._loadingSceneComponent = this.addComponent<LoadingSceneComponent>(
      new LoadingSceneComponent(false),
    ).on(LoadingSceneComponent.EventTypes.LoadingCompleted, () => {
      this._loadingSceneComponent.setText("connecting..");
    });

    this._audioManager = this.addComponent<AudioComponent>(
      new AudioComponent(),
    );
    this._gameWidth = this.scale.gameSize.width;
    this._gameHeight = this.scale.gameSize.height - 2 * this._yOffset;
    this._standardWidth = this._gameWidth < 912 ? this._gameWidth : 912;
    this._standardHeight = this._gameHeight < 980 ? this._gameHeight : 980;
    this._containerWidth = this._standardWidth;
    this._containerHeight = this._standardHeight;
    //initialize balls array
    this._balls = [];

    //initialize prize list array
    this._prizeList = [];

    //initialize bouncer effects array
    this._bounceEffects = [];
    this._bouncerMatrix = [];

    // initialize UI container
    this._uiContainer = this.add.container(30, 100);

    // defines the game objects size and speed of tweens according to the number of rows
    if (this._rowQuantity > 12) {
      this._gameObjectSize = 10;
      this._gameObjectOriginalSize = 10;
      this._tweenMaxScale = 0.8;
    } else if (this._rowQuantity > 9) {
      this._gameObjectSize = 20;
      this._gameObjectOriginalSize = 20;
      this._tweenMaxScale = 0.9;
    } else {
      this._gameObjectSize = 30;
      this._gameObjectOriginalSize = 30;
      this._tweenMaxScale = 1;
    }
    //update margins according to game size
    this._xMargin = this._gameWidth < 912 ? this._gameObjectSize / 2 : 100;

    //calculate starting values for the spacing between objects
    this._lineSpacing =
      (this._containerWidth - 2 * this._xMargin) / this._rowQuantity;
    this._rowDistance =
      (this._containerHeight - 2 * this._yMargin - this._yTopOffset) /
      (this._rowQuantity + 1);

    // calculate the sizes of the baskets relative to the number of rows.
    this._basketSize =
      (this._rowQuantity - 16) % 2 == 0
        ? Math.abs(this._rowQuantity - 16) / 2 + 1
        : Math.abs(this._rowQuantity - 15) / 2 + 1;
  }

  protected lowRiskClick() {
    this._risk = PlinkoRisks.LOW;
    this._highRiskButton.setDisabled(false);
    this._midRiskButton.setDisabled(false);
    this._lowRiskButton.setDisabled(true);
    this.events.emit("updateGame");
  }

  protected midRiskClick() {
    this._risk = PlinkoRisks.MEDIUM;
    this._highRiskButton.setDisabled(false);
    this._midRiskButton.setDisabled(true);
    this._lowRiskButton.setDisabled(false);
    this.events.emit("updateGame");
  }

  protected onShutdown(): void {
    super.onShutdown();
    this.events.removeListener("updateGame");
    this.events.removeListener("startGame");
    this.events.removeListener("lowRisk");
    this.events.removeListener("midRisk");
    this.events.removeListener("highRisk");
    this.events.removeListener("ballSubtract");
    this.events.removeListener("ballAdd");
    this.events.removeListener("rowSubtract");
    this.events.removeListener("rowAdd");
    this.events.removeListener("updateGame");
  }

  protected preload() {
    super.preload();
    // Load the particle shapes
    TrailParticleEmitterManager.Preload(this);

    //load balls
    this.load.image(
      "ball10",
      "/assets/game/mini/plinko/Balls/ballPlinko10.png",
    );
    this.load.image(
      "ball20",
      "/assets/game/mini/plinko/Balls/ballPlinko20.png",
    );
    this.load.image(
      "ball30",
      "/assets/game/mini/plinko/Balls/ballPlinko30.png",
    );
    //load bouncers
    this.load.image(
      "bouncer10",
      "/assets/game/mini/plinko/Bouncers/bouncerPlinko10.png",
    );
    this.load.image(
      "bouncer20",
      "/assets/game/mini/plinko/Bouncers/bouncerPlinko20.png",
    );
    this.load.image(
      "bouncer30",
      "/assets/game/mini/plinko/Bouncers/bouncerPlinko30.png",
    );
    //load the bouncer effects
    this.load.image(
      "bouncerEffect10",
      "/assets/game/mini/plinko/BouncersLighted/bouncerLightPlinko10.png",
    );
    this.load.image(
      "bouncerEffect20",
      "/assets/game/mini/plinko/BouncersLighted/bouncerLightPlinko20.png",
    );
    this.load.image(
      "bouncerEffect30",
      "/assets/game/mini/plinko/BouncersLighted/bouncerLightPlinko30.png",
    );
    this.load.image(
      "bouncerEffect40",
      "/assets/game/mini/plinko/BouncersLighted/bouncerLightPlinko40.png",
    );

    //load UI
    this.load.image(
      "startButtonNormal",
      "/assets/game/mini/plinko/buttons/start_normal.png",
    );
    this.load.image(
      "startButtonPressed",
      "/assets/game/mini/plinko/buttons/start_presed.png",
    );
    this.load.image(
      "startButtonHover",
      "/assets/game/mini/plinko/buttons/start_hover.png",
    );
    this.load.image(
      "startButtonDisabled",
      "/assets/game/mini/plinko/buttons/start_disabled.png",
    );

    this.load.image(
      "minusButtonNormal",
      "/assets/game/mini/plinko/buttons/minus_normal.png",
    );
    this.load.image(
      "minusButtonPressed",
      "/assets/game/mini/plinko/buttons/minus_pressed.png",
    );
    this.load.image(
      "minusButtonHover",
      "/assets/game/mini/plinko/buttons/minus_hover.png",
    );
    this.load.image(
      "minusButtonDisabled",
      "/assets/game/mini/plinko/buttons/minus_disabled.png",
    );

    this.load.image(
      "plusButtonNormal",
      "/assets/game/mini/plinko/buttons/plus_normal.png",
    );
    this.load.image(
      "plusButtonPressed",
      "/assets/game/mini/plinko/buttons/plus_pressed.png",
    );
    this.load.image(
      "plusButtonHover",
      "/assets/game/mini/plinko/buttons/plus_hover.png",
    );
    this.load.image(
      "plusButtonDisabled",
      "/assets/game/mini/plinko/buttons/plus_disabled.png",
    );

    this.load.image(
      "lowButtonNormal",
      "/assets/game/mini/plinko/buttons/low_normal.png",
    );
    this.load.image(
      "lowButtonPressed",
      "/assets/game/mini/plinko/buttons/low_pressed.png",
    );
    this.load.image(
      "lowButtonHover",
      "/assets/game/mini/plinko/buttons/low_hover.png",
    );
    this.load.image(
      "lowButtonDisabled",
      "/assets/game/mini/plinko/buttons/low_disabled.png",
    );

    this.load.image(
      "midButtonNormal",
      "/assets/game/mini/plinko/buttons/mid_normal.png",
    );
    this.load.image(
      "midButtonPressed",
      "/assets/game/mini/plinko/buttons/mid_pressed.png",
    );
    this.load.image(
      "midButtonHover",
      "/assets/game/mini/plinko/buttons/mid_hover.png",
    );
    this.load.image(
      "midButtonDisabled",
      "/assets/game/mini/plinko/buttons/mid_disabled.png",
    );

    this.load.image(
      "highButtonNormal",
      "/assets/game/mini/plinko/buttons/high_normal.png",
    );
    this.load.image(
      "highButtonPressed",
      "/assets/game/mini/plinko/buttons/high_pressed.png",
    );
    this.load.image(
      "highButtonHover",
      "/assets/game/mini/plinko/buttons/high_hover.png",
    );
    this.load.image(
      "highButtonDisabled",
      "/assets/game/mini/plinko/buttons/high_disabled.png",
    );

    this.load.image(
      "numberBackground",
      "/assets/game/mini/plinko/buttons/text_field.png",
    );

    //load all baskets and their effects
    for (let i = 0; i < 6; i++) {
      this.load.image(
        "orange_basket" + (i + 1) + "0",
        "/assets/game/mini/plinko/Baskets/Orange/" + i + "_0_0.png",
      );
      this.load.image(
        "orange_basket_light" + (i + 1) + "0",
        "/assets/game/mini/plinko/Baskets/Orange/" + i + "_1_0.png",
      );
    }
    for (let i = 0; i < 6; i++) {
      this.load.image(
        "yellow_basket" + (i + 1) + "0",
        "/assets/game/mini/plinko/Baskets/Yellow/" + i + "_0_0.png",
      );
      this.load.image(
        "yellow_basket_light" + (i + 1) + "0",
        "/assets/game/mini/plinko/Baskets/Yellow/" + i + "_1_0.png",
      );
    }
    for (let i = 0; i < 6; i++) {
      this.load.image(
        "red_basket" + (i + 1) + "0",
        "/assets/game/mini/plinko/Baskets/Red/" + i + "_0_0.png",
      );
      this.load.image(
        "red_basket_light" + (i + 1) + "0",
        "/assets/game/mini/plinko/Baskets/Red/" + i + "_1_0.png",
      );
    }
    //load the basket labels
    for (let i = 0; i < 6; i++) {
      this.load.image(
        "basket_label" + (i + 1) + "0",
        "/assets/game/mini/plinko/BasketLabels/basketLabel" + i + ".png",
      );
    }
    //load background images
    this.load.image(
      "background",
      "/assets/game/mini/plinko/Background_Assets/plinko_background_no_assets.png",
    );
    for (let i = 0; i < 6; i++) {
      this.load.image(
        "cloud" + i,
        "/assets/game/mini/plinko/Background_Assets/cloud_" + i + ".png",
      );
    }
    this.load.image(
      "coin",
      "/assets/game/mini/plinko/Background_Assets/coin.png",
    );
    this.load.image(
      "backgroundFront",
      "/assets/game/mini/plinko/Background/foreground_pipe.png",
    );
    this.load.image(
      "backgroundFull",
      "/assets/game/mini/plinko/Background/background_background.png",
    );

    this.load.audio(
      "ballPipe",
      "/assets/game/mini/plinko/audio/mp3/ballHitPipe_op2.mp3",
    );
    this.load.audio(
      "victory",
      "/assets/game/mini/plinko/audio/mp3/finalSound.mp3",
    );
    this.load.audio(
      "bouncing",
      "/assets/game/mini/plinko/audio/mp3/ballBounce.mp3",
    );
    this.load.audio(
      "bgm",
      "/assets/game/mini/plinko/audio/mp3/plinkoBgMusic.mp3",
    );
  }

  protected requestGameStart() {
    const currency = currentAccount.wallet[this._matchData["currency"]];
    if (!currency || currency < this._ballCounter * 100) {
      return $modals.openModal("NoTicketsModal");
    }

    if (this._onlyOneStart) {
      return;
    }

    this.visibilityControls(false);

    this._onlyOneStart = true;
    this._uiContainer.iterate(function (child) {
      if (child instanceof Button) {
        child.setDisabled(true);
      }
    });
    this.networkedComponent.sendStartGame();
  }

  protected visibilityControls(visible: boolean) {
    this._payoutBox.visible = visible;
    this._ballText.visible = visible;
    this._ballCountBackground.visible = visible;
    this._ballCountText.visible = visible;
    this._subtractBallsButton.visible = visible;
    this._rowCountBackground.visible = visible;
    this._rowCountText.visible = visible;
    this._rowText.visible = visible;
    this._multiplierBox.visible = visible;
    this._addBallsButton.visible = visible;
    this._subtractBallsButton.visible = visible;
    this._addRowButton.visible = visible;
    this._subtractRowButton.visible = visible;

    this._startButton.visible = visible;
    this._lowRiskButton.visible = visible;
    this._midRiskButton.visible = visible;
    this._highRiskButton.visible = visible;
    this._priceTextBackground.visible = visible;
    this._priceText.visible = visible;
    this._priceCountText.visible = visible;

    this._multiplierBackground.visible = visible;
    this._multiplierBoxText.visible = visible;
    this._payoutBox.visible = visible;
    this._payoutBackground.visible = visible;
    this._payoutBoxText.visible = visible;
  }

  protected requestRefreshGame() {
    this.networkedComponent.sendRefreshGame();
  }

  protected requestUpdateData() {
    this.networkedComponent.sendUpdateOfData(
      this._rowQuantity,
      this._ballCounter,
      this._risk,
    );
    this.networkedComponent.sendRefreshGame();
  }

  protected rowAddition() {
    let changed = false;
    if (this._rowQuantity % 2 === 1) {
      this._rowQuantity -= 1;
      changed = true;
    }
    if (this._rowQuantity < 16) {
      this._rowQuantity = this._rowQuantity + 2;
      this._rowCountText.setText(this._rowQuantity.toString());
      changed = true;
    } else {
      this._rowQuantity = 16;
      changed = true;
    }
    if (changed) {
      this.events.emit("updateGame");
    }
  }

  protected rowSubtract() {
    if (this._rowQuantity % 2 === 1) {
      this._rowQuantity += 1;
    }
    if (this._rowQuantity > 8) {
      this._rowQuantity = this._rowQuantity - 2;
      this._rowCountText.setText(this._rowQuantity.toString());
    } else {
      this._rowQuantity = 8;
    }
    this.events.emit("updateGame");
  }

  protected setupGameArea() {
    // calculate the sizes of the baskets relative to the number of rows.
    this._basketSize =
      (this._rowQuantity - 16) % 2 == 0
        ? Math.abs(this._rowQuantity - 16) / 2 + 1
        : Math.abs(this._rowQuantity - 15) / 2 + 1;

    // defines the game objects size and speed of tweens according to the number of rows
    if (this._rowQuantity > 12) {
      this._gameObjectSize = 10;
      this._gameObjectOriginalSize = 10;
      this._tweenMaxScale = 0.8;
    } else if (this._rowQuantity > 9) {
      this._gameObjectSize = 20;
      this._gameObjectOriginalSize = 20;
      this._tweenMaxScale = 0.9;
    } else {
      this._gameObjectSize = 30;
      this._gameObjectOriginalSize = 30;
      this._tweenMaxScale = 1;
    }
    //update margins according to game size
    this._xMargin = this._gameWidth < 912 ? this._gameObjectSize / 2 : 100;

    //calculate starting values for the spacing between objects
    this._lineSpacing =
      (this._containerWidth - 2 * this._xMargin) / this._rowQuantity;
    this._rowDistance =
      (this._containerHeight - 2 * this._yMargin - this._yTopOffset) /
      (this._rowQuantity + 1);

    //create the background of the plinko area
    this.createBackground();

    // update y top offset
    this._yTopOffset = this._gameBackgroundFront.getBottomCenter().y + 10;
    //redefine spacial variables
    this._lineSpacing =
      (this._containerWidth - 2 * this._xMargin) / this._rowQuantity;
    this._rowDistance =
      (this._containerHeight - 2 * this._yMargin - this._yTopOffset) /
      (this._rowQuantity + 1);
    //create all the dividers, returns the last position used for baskets
    const finalPosition: number = this.createBouncers(this._rowQuantity);
    //create the baskets at the bottom row of the game

    this.createBaskets(this._rowQuantity, finalPosition, this._prizePool);

    //creates the new game container and places each game object on it
    this.createGameContainer();
  }

  private addNineslicedBackgroundAt(
    x: number,
    y: number,
    width: number = 128,
    height = 42,
  ): RenderTexture {
    return this.add
      .nineslice(x, y, width, height, "numberBackground", [8, 6, 6, 6])
      .setOrigin(0, 0.5);
  }

  private subtractBalls() {
    if (this._ballCounter > 1) {
      this._ballCounter = this._ballCounter - 1;
      this._ballCountText.setText(this._ballCounter.toString());
      this._priceCountText.setText((this._ballCounter * 100).toString());
      this.events.emit("updateGame");
    }
  }

  private updateUI() {
    this._rowCountText.setText(this._rowQuantity.toString());
    this._ballCountText.setText(this._ballCounter.toString());
    this._priceCountText.setText((this._ballCounter * 100).toString());
    if (this._risk == PlinkoRisks.HIGH) {
      this._highRiskButton.setDisabled(true);
    } else if (this._risk == PlinkoRisks.MEDIUM) {
      this._midRiskButton.setDisabled(true);
    } else {
      this._lowRiskButton.setDisabled(true);
    }
  }
}
