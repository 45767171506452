import Entity from "@engine/Entity";

export class EntityComponentV2 {
  public isTickEnabled: boolean = false;

  constructor(protected _owner: Entity) {}

  public destroy() {
    this.onDestroy();
  }

  public onAddedToEntity() {}

  public onAddedToScene() {}

  public shutdown() {
    this.onShutdown();
  }

  public tick(deltaSeconds: number, deltaTime: number) {}

  protected onDestroy() {}

  protected onShutdown() {}
}
