export default [
  {
    address: ["0x86ab08ee1cd4be3a2d171dc3e6c2b6dfea0d4dd7"],
    type: "character",
    metadata: {
      name: "Picollo Pepe",
      description:
        "One of the few know-it-all piccolo's of the Hotel, having access to the service elevator for quick transport. Knows all Hotel Guests by name and makes sure he takes care of the other guests in the best way possible.",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "500" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },

  {
    address: ["0xd909F24C2F3E26EF5C947f043992FfF692a1CC1F"],
    type: "character",
    metadata: {
      name: "Degen Daytrader",
      description:
        "A true candlestick warrior that buys the tops and sells the bottoms while hanging around rugpull discords and subreddits to get rekt. Is one of the only hotel guests with access to the trading room and tools inside the hotel.",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "400" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },

  {
    address: ["0xba182Ef4b4525ddef30EC36BD5E80388c7E3E6A1"],
    type: "character",
    metadata: {
      name: "Innkeeper Kermit",
      description:
        "The always enthusiastic and funny inn-keeper of the Hotel that makes everyone feel at home. Has the keys to the bar and loves to make some tokens by selling his home-brewed craft beers.",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "300" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },

  {
    address: ["0x4E1f43211E402f420c15916866Fd5fcEdD23123A"],
    type: "character",
    metadata: {
      name: "Kawaii Keyboardwarrior",
      description:
        "Her kawaii looks and fiery character is what makes this e-girl a Bit Hotel celebrity. As an avid streamer of Bit Hotel mini-games she often ends top 10% in the leaderboards and makes sure her followers follow along. An absolute sphinx behind a keyboard, so don't let appearances be deceiving.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "200" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },

  {
    address: ["0x5eeF2200bBdc29c81AF88AD62A6aEB665b90C94A"],
    type: "character",
    metadata: {
      name: "The Monopolist Manager",
      description:
        "A true monopolist of the Hotel, having deep pockets and owning large chunks of floors that he rents out for income. A power player that makes sure he draws the cards, monopolizing asset classes so he can set the price. Don't step on his squares, that's a warning.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "100" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },

  {
    address: ["0x970E431881575905B5CB19300356184ce393D743"],
    type: "character",
    metadata: {
      name: "Maitre Morty",
      description:
        "Jeez Rick, a-a-are you sure this Bit Hotel thing is going to be a good idea? W-w-what if I get bored of doing this maid café thing, is there a way out? Oh man, I hope this plays out alright.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "80" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },

  {
    address: ["0xA8555377f94624d9d3Dde11A544506F98A3fAa40"],
    type: "character",
    metadata: {
      name: "Meta Masked Fox",
      description:
        "The fox that knows his way into all the Hotel Guests' wallets. Somehow has access to all the private and public keys and never gets caught. A mischievous and rarely seen guest that lurks in the shadows.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "60" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },

  {
    address: ["0x8d806D63C0827B8AAf8bD4D3CB87c0ca5af74eCf"],
    type: "character",
    metadata: {
      name: "Astronaut CZ",
      description:
        "Your celebrity astronaut that works 80 hours per week and has a yellow themed room in the Hotel for some reason. Loves to centralize assets for efficiency and sponsors the Hotel team behind the scenes to boost the hotel growth to the moon!",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "50" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },

  {
    address: ["0xe01B551484765335b908C8A20c5380eFc5C00052"],
    type: "character",
    metadata: {
      name: "Director Dolan",
      description:
        "One of the directors of the Hotel, the degen cousin of Donald Duck, a memelord first class. Loves to patrol the corridors looking for any cash that was dropped on the floor to give to his small nephews. Has access to the director board rooms and loves to work on comics in his spare time.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "40" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },

  {
    address: ["0x79b50C9eE81c2878E3f2aa440F0F5d612ff09539"],
    type: "character",
    metadata: {
      name: "The ZuckerClerk",
      description:
        "The pinnacle of centralized control in the Hotel. Monitoring and censoring the bad actors in the Bit Hotel ecosystem from his ivory tower on the top floor, the Zuckerclerk is far more than meets the eye. Takes care of the Hotel management's social accounts in his spare time as he's shown affinity for it. Hosts social gatherings with exclusions and loves to update the Hotel's economy algorithms when he's bored.",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "30" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },

  {
    address: ["0xE3E539b0A1eb97256c59f1c1FD13A59367e2B55A"],
    type: "character",
    metadata: {
      name: "Karaoke Kim",
      description:
        "One of the very few North Korean Hotel guests in the Bit Hotel. Has a very explosive character on his bad days and never seems to quite get the power he wants in the Hotel even though he controls a lot of floors with an iron fist. A regular customer at the Hotel's karaoke bar.",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "15" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },

  {
    address: ["0xAf8B0F134F7Fc66407cc35F2AA287b450D782043"],
    type: "character",
    metadata: {
      name: "Notorious Neo",
      description:
        "A native resident of the metaverse, knows exactly when there is a glitch in the Bit Hotel matrix and when it's time to log out. Knows the game architects by their first names and exerts control over them if things don't go his way. Offer Hotel guests that feel stuck a way out sometimes too.",
      attributes: [
        { trait_type: "Rarity", value: "Epic" },
        { trait_type: "Replicas", value: "10" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },

  {
    address: ["0x605cc7D6e77A75D6d171d373D772CA3CF3048930"],
    type: "character",
    metadata: {
      name: "Grumpy Nyan Cat",
      description:
        "The rarest, most colorful and legendary Hotel guest to date. With abundant colors and a sparkly aura seeing Grumpy Nyan cat in the lobby is a true sight to behold. With never seen before perks and utilities this rainbow cat is more than a viral sensation, he's a movement in itself and renowned throughout the Hotel. Leaves a trail of rainbows wherever he goes and somehow earns massive bonuses by competing in the Hotel's arcade. A true mystery only few will be able to understand.",
      attributes: [
        { trait_type: "Rarity", value: "Epic" },
        { trait_type: "Replicas", value: "2" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },

  {
    address: ["0xF29eB9E45CD2518788B54A2f062C6dAFEfC94d66"],
    type: "character",
    metadata: {
      name: "Beta Tester Hotel Guest",
      description:
        "The limited edition Hotel Guest that only the few that participated in the Beta have. With his head-pan and testing-goggles this guest is expert in finding early bugs in the hotel and notifying the administrators.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "500" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },

  {
    address: ["0x926965c398fd294326466B9371CBc6F8a5FBaA99"],
    type: "character",
    metadata: {
      name: "Brook Soul",
      description:
        "Brook Soul is the epitome of the modern (maybe make it “e-girl”) live-streaming gamer. Due to some ‘confusion’ with her apartment’s rent, she has had to move her streaming setup into the rooms of hotels - but only those with the best wifi. Brook has a dream of being the go-to live-streaming gamer of the Ultra ecosystem, often gaming so avidly that her hands light up like lava for long periods of time.",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "300" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },
  {
    address: ["0xb3a1E53166abc32E0279D91ce7e780B2f7f42B7e"],
    type: "character",
    metadata: {
      name: "William Skull",
      description:
        "A reclusive and eccentric game developer, William Skull, is named after his legendary idol who was responsible for the birth of modern video gaming.  W. Skull is an insomniac who lives only on energy drinks and always has a healthy layer of orange dust on his fingertips, renting out hotel rooms to develop his games undisturbed. William is an avid supporter of the Ultra Platform and can usually be found debating blockchain gaming pros and cons with other Ultra community members.",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "300" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },
  {
    address: ["0x298fe9972ed99Ca001B7B55A968357773088f71D"],
    type: "character",
    metadata: {
      name: "Lithium Hotel Guest",
      description:
        "A true early adopter when it comes to Bit Hotel. Loves to stroll around the Lithium quarters in the Hotel and supports Lithium as a member of their community.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "350" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },

  {
    address: ["0x525bd3D7e5F46C57BB197fC97A523aA8d18373A2"],
    type: "character",
    metadata: {
      name: "Moonbeam Hotel Guest",
      description:
        "A true web3 evangelist, supporting Bit Hotel and Moonbeam actively. Often hangs around the moonbeam clubhouse with his friends to talk about the newest Moonbeam dApps.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "300" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },
  {
    address: ["0x98F846D92D3f36723E8c35e2fF437130281D9bD5"],
    type: "character",
    metadata: {
      name: "The Wise Tortus",
      description:
        "When you obtain this skin you will be overflowing with ancient wisdom of the mystic arts of kungfu. Once you equip the wise tortus attracting big booty latina's will never be easier. If you obtain this skin all the latina hearts you will win.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "200" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },
  {
    address: ["0x28a99561ad4b759e36253625353f38992251cadd"],
    type: "character",
    metadata: {
      name: "Governor",
      description:
        "Behold! The epitome of responsibility, wisdom, and justice. Anointed by The Herd to lead the governance votes on the green grasslands of The Farm. Eternal Bull in a kick-ass Robe of Fire. Don't mind the soother. It helps calm his nerves.",
      attributes: [
        { trait_type: "Rarity", value: "Epic" },
        { trait_type: "Replicas", value: "5" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },
  {
    address: ["0x7818e2B56d341eF5a7139e297531dC4Dd9549F70"],
    type: "character",
    metadata: {
      name: "G2A Hotel Guest",
      description:
        "A loyal fan of the Bit Hotel and the G2A platform. Limited edition collection created in partnership between both companies.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "1000" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },
  {
    address: ["0x871827A844A9698C9865043A68D2968FDBceB38C"],
    type: "character",
    metadata: {
      name: "Avocado Hotel Guest",
      description:
        "A loyal fan of the Bit Hotel and the Avocado Guild. Limited edition collection created in partnership between both projects, a very in demand and rare hotel guest.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "150" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },
  // testnet
  {
    address: [
      "fcb663f602b65f729070cf663742050403e2b1a1c2778ed408bae739856e177c",
    ],
    type: "character",
    metadata: {
      name: "CasperPunk Hotel Guest",
      description:
        "The original and limited edition CasperPunk hotel guest that is only available on the Casper blockchain. This scary character is in high demand among hotel guests.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "3000" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },
  // mainnet
  {
    address: [
      "9aaff24d0c5d395008f10ded20b13194717e4e2a2a2bacd67bcd6f2c7192a3fb",
    ],
    type: "character",
    metadata: {
      name: "CasperPunk Hotel Guest",
      description:
        "The original and limited edition CasperPunk hotel guest that is only available on the Casper blockchain. This scary character is in high demand among hotel guests.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "3000" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },
  {
    address: ["0x91a8296E1e1d159C3b75A340595Eb9921D3e3FFD"],
    type: "character",
    metadata: {
      name: "Barbie Hotel Guest",
      description:
        "A fabulous and independent women in her own pink world. A well known hotel guest iconic for her fashion style and humor.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "500" },
        { trait_type: "Drop", value: "Season 2" },
      ],
    },
  },
];
