<script setup lang="ts">
defineProps({
  type: {
    type: String,
    default: "text",
  },
  name: {
    type: String,
    default: "input-box",
  },
  modelValue: {
    type: String,
  },
  errorText: {
    type: String,
  },
});
// defineProps(["modelValue", "errorText", "type", "name"]);
const emits = defineEmits(["update:modelValue", "input"]);
function emitChange(value) {
  emits("update:modelValue", value);
  emits("input", value);
}
</script>
<template>
  <div class="input-group pos--rel">
    <div
      v-depixel-normal
      class="color-text--bright-red-100 pos--abs padding-h--xsm border-radius--s"
      v-if="errorText"
    >
      {{ errorText }}
    </div>

    <input
      v-depixel-normal
      :name="name"
      :type="type"
      :value="modelValue"
      :class="{ error: !!errorText }"
      @input="emitChange(($event.target as HTMLInputElement).value)"
      autofocus
    />
  </div>
</template>
<style scoped lang="scss">
.input-group {
  width: 100%;
  div {
    top: -1.1em;
    left: 1em;
    background-color: get-color(orange-50);
    border: 4px solid #fff;
    translate: 0 0 1px;
    z-index: 1;
  }
}
</style>
