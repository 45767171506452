export default [
  {
    address: ["0xf3E41b0981A317C981fd1e8EaB83E0bAE2e2F9c5"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Uncommon",
        },
        {
          trait_type: "Replicas",
          value: "75",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "The Solchick Bit Hotel guest is a rare little chick that was attained only by the truest of Solchick and Bit Hotel fans.",
      name: "Solchick",
    },
    type: "character",
  },
  {
    address: ["0xb95F99cD13abc6c996B2564A98Ff1DAbFA7a529F"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Rare",
        },
        {
          trait_type: "Replicas",
          value: "75",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "The protagonist of FOMO Chronicles Manga now roams the Bit Hotel metaverse. Loves to go on adventures with his friends, one of which is Mr. Diamond Hands!",
      name: "Otaku",
    },
    type: "character",
  },
  {
    address: ["0x3116c327aadb1dcfa59f6f8c1d37b9b1a00bf3f4"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Uncommon",
        },
        {
          trait_type: "Replicas",
          value: "300",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "An early bird when it comes to Bit Hotel. Loves to stroll around the aviary in the Hotel and supports Superlauncher as a member of their flock.",
      name: "Superlauncher Fan",
    },
    type: "character",
  },
  {
    address: ["0x135a7f92541943cbadc22a5b80a21fff290b7f9b"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Common",
        },
        {
          trait_type: "Replicas",
          value: "400",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "The youngest member of the Superlauncher family and the most curious mind in the Hotel. Watch out if you see him hanging around with Picollo Pepe, they're probably up to no good!",
      name: "Superlauncher Kid",
    },
    type: "character",
  },
  {
    address: ["0xcfDA19E3bC1CfB60591b7518e190edd0CFC784c5"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Epic",
        },
        {
          trait_type: "Replicas",
          value: "5",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "Behold! The epitome of responsibility, wisdom, and justice. Anointed by The Herd to lead the governance votes on the green grasslands of The Farm. Eternal Bull in a kick-ass Robe of Fire. Don't mind the soother. It helps calm his nerves.",
      name: "Governor",
    },
    type: "character",
  },
  {
    address: ["0x8Fe5136F242feF69b864CE729FDD47B9d901743a"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Super Rare",
        },
        {
          trait_type: "Replicas",
          value: "15",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "You want to have this guy's problems. He has too much crypto so he is willing to lend it to anyone who wants it. He is doing it with a smile on his face because he well knows well the 8th wonder of the world - Compound interest.",
      name: "Lender",
    },
    type: "character",
  },
  {
    address: ["0xC0a9FBa9F73FF59731f978F90335647C9B997904"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Rare",
        },
        {
          trait_type: "Replicas",
          value: "40",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "The size of this Alpaca makes you well assured that the funds are safu. Karate, Krav-maga, Jiu-jitsu, bar brawling, oracle price-feed reading. You name it. He wields it all. Some say that it's the wool and he's just fluffy but no one is eager to test that claim.",
      name: "Vault Guardian",
    },
    type: "character",
  },
  {
    address: ["0x609A5A80A2B2657D6C453A3A92a378adACfd55D4"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Uncommon",
        },
        {
          trait_type: "Replicas",
          value: "50",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "Chic suit, flying hair, and a suitcase full of cash. This guy knows where the money is. That's because he mints it. Where is he going with that suitcase? The car dealer for a lambo or the casino..?",
      name: "Minter",
    },
    type: "character",
  },
  {
    address: ["0x1A6EC3eCbFaD269F3e8c2deBCdE6a5A91494D6aa"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Epic",
        },
        {
          trait_type: "Replicas",
          value: "5",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "Behold! The epitome of responsibility, wisdom, and justice. Anointed by The Herd to lead the governance votes on the green grasslands of The Farm. Eternal Bull in a kick-ass Robe of Fire. Don't mind the soother. It helps calm his nerves.",
      name: "Farmer",
    },
    type: "character",
  },
  {
    address: ["0xa7C5Ef64b5a5d7440bB28AeE7A15b8ab7a0a3f75"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Uncommon",
        },
        {
          trait_type: "Replicas",
          value: "1500",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "With it's ghostly appearance this green Hotel guest is in a category of its own. Signifying loyal support to the Wasder ecosystem this guest is a true limited edition memoir to the Bit Hotel Wasder season of 2022. Numerous Hotel guests participated in the collaborative season and only few got lucky enough to acquire the character making it a beloved part of any collector's repetoire.",
      name: "Wasder Hotel Guest",
    },
    type: "character",
  },
  {
    address: ["0xcded358e017688a3cfb0bb69c1b5e7ec65129f83"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Rare",
        },
        {
          trait_type: "Replicas",
          value: "50",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "The loyal supporter of the Cryptoknights, a green knight that is a rare sight in the lobby and signifies a great deal to the Bit Hotel and Cryptoknights communities.",
      name: "Cryptoknight",
    },
    type: "character",
  },
  {
    address: ["0xDB424433FCea817DE4f0Fe13A1f9A4Eb30b9824B"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Rare",
        },
        {
          trait_type: "Replicas",
          value: "150",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "The trading connoisseur that neglects sell buttons and holds as if his hands were made of diamonds. Truly has the most long-term commitment to the Hotel of all the hotel guests.",
      name: "Diamond Hand Hotel Guest",
    },
    type: "character",
  },
  {
    address: ["0x90BF5e3622e1597Cde0193daB61b7171be0Fa63F"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Rare",
        },
        {
          trait_type: "Replicas",
          value: "100",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "One of the most fanatic supporters of the Bit Hotel and has been with the creators in their toughest of times. Battles his way through the waves of scammers, hackers, and bad actors and makes sure the Hotel Guests are safe at all times.",
      name: "Anti-Hacker Rambo",
    },
    type: "character",
  },
  {
    address: ["0xdf409c8ca790142939d34ba1681ad5ff44cb56d8"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Rare",
        },
        {
          trait_type: "Replicas",
          value: "50",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "The original and limited edition hotel guest only available during Valentines day. A badass and charming angel that most hotel guests adore. Great to add to your arsenal.",
      name: "Cupid Hotel Guest",
    },
    type: "character",
  },
  {
    address: ["0x91a8296E1e1d159C3b75A340595Eb9921D3e3FFD"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Uncommon",
        },
        {
          trait_type: "Replicas",
          value: "500",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description:
        "A fabulous and independent women in her own pink world. A well known hotel guest iconic for her fashion style and humor.",
      name: "Barbie Hotel Guest",
    },
    type: "character",
  },
];
