import Entity from "../Entity";

export default class EntityComponent {
  public isTickEnabled: boolean = false;
  protected _owner: Entity;

  public get owner() {
    return this._owner;
  }

  public set owner(owner: Entity) {
    this._owner = owner;

    this.onOwnerSet();
  }

  public destroy() {
    this.onDestroy();
  }

  public shutdown() {
    this.onShutdown();
  }

  public tick(deltaSeconds: number, deltaTime: number) {}

  protected onDestroy() {}

  protected onOwnerSet() {
    // Override this in case you needed the owner for something
  }

  protected onShutdown() {}
}
