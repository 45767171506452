/* eslint-disable prettier/prettier */
import MinigameBackendComponent from "@game/engine/components/scenecomponents/MinigameBackendComponent";
import { MinesBackend } from "./backend/MinesBackend";
import MightyMinesScene from "./MightyMinesScene";
import { dispatchEvent, EnumNetworkEvents } from "@shared/Events";
import { MinesEventTypes } from "./common/eventtypes/MinesEventTypes";

export default class MightyMinesBackendComponent extends MinigameBackendComponent {
  protected declare _scene: MightyMinesScene;

  constructor() {
    super("mines");

    // Do you remember that our backend sends "remoteMove" message to us? Subscribing.
    this.getMessagesEmitter().on(
      MinesBackend.EventTypes.returnMultiplier,
      this.onMultiplierReturn,
      this,
    );
    this.getMessagesEmitter().on(
      MinesBackend.EventTypes.gameEnded,
      this.onGameOver,
      this,
    );
    this.getMessagesEmitter().on(
      MinesBackend.EventTypes.bombExploded,
      this.onExplodeBomb,
      this,
    );
    this.getMessagesEmitter().on(
      MinesBackend.EventTypes.StartGameData,
      this.onStartGame,
      this,
    );
  }

  public sendGameCheckout(x: number, y: number) {
    this.send(MinesEventTypes.GameCheckout, { x: x - 2, y: y - 2 });
  }

  public sendRequestFlip(x: number, y: number) {
    this.send(MinesEventTypes.RequestFlip, { x: x - 2, y: y - 2 });
  }

  public sendStartGame(stake: number, bombCount: number) {
    this.send(MinesEventTypes.StartGame, {
      stake: stake,
      bombCount: bombCount,
    });
  }

  private onExplodeBomb(data: { tileX: number; tileY: number }) {
    dispatchEvent(EnumNetworkEvents.NetworkRequestUserData);
    this._scene.onBombDrop(data.tileX, data.tileY);
  }

  private onGameOver() {
    dispatchEvent(EnumNetworkEvents.NetworkRequestUserData);

    this._scene.gameOver();
  }

  private onMultiplierReturn(data: {
    newMultiplier: number;
    tileX: number;
    tileY: number;
    checkoutEnabled: boolean;
  }) {
    dispatchEvent(EnumNetworkEvents.NetworkRequestUserData);
    this._scene.onMultiplierReturn(
      data.newMultiplier,
      data.tileX,
      data.tileY,
      data.checkoutEnabled,
    );
  }

  private onStartGame(data: { checkoutEnabled: boolean }) {
    dispatchEvent(EnumNetworkEvents.NetworkRequestUserData);
    this._scene.startGame(data.checkoutEnabled);
  }
}
