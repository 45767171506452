import ParticleEmitterManager = Phaser.GameObjects.Particles.ParticleEmitterManager;
import ParticleEmitter = Phaser.GameObjects.Particles.ParticleEmitter;
import GameObject = Phaser.GameObjects.GameObject;
import { BlendModes, Scene } from "phaser";

class MovementTweenProxy {
  private _x: number;

  public get x() {
    return this._x;
  }

  public set x(x: number) {
    this._x = x;
    // this._emitter.setPosition(x, this._y);
  }

  private _y: number;

  public get y() {
    return this._y;
  }

  public set y(y: number) {
    this._y = y;
    // this._emitter.setPosition(this._x, y);
  }

  constructor(private _emitter: ParticleEmitter, x: number = 0, y: number = 0) {
    this._emitter.setPosition(x, y);
    this._x = x;
    this._y = y;
  }

  public getFollowable(): GameObject {
    return <GameObject>(<unknown>this);
  }
}

export default class TrailParticleEmitterManager {
  private _emitters: ParticleEmitter[];
  private _emittersThatAreDone: ParticleEmitter[];
  private _manager: ParticleEmitterManager;

  constructor(private _scene: Scene) {
    this._emitters = [];
    this._emittersThatAreDone = [];
    this._manager = this._scene.add.particles("particle").setDepth(999);
  }

  public static Preload(scene: Scene) {
    scene.load.image("particle", "assets/game/particles/circle.png");
    scene.load.atlas(
      "shapes",
      "assets/game/particles/shapes.png",
      "assets/game/particles/shapes.json",
    );
    scene.load.text(
      "particle-effect",
      "assets/game/particles/effects/trail.json",
    );
  }

  public animate(
    fromX: number,
    fromY: number,
    toX: number,
    toY: number,
    onComplete = () => {},
  ) {
    const emitter = this.getOrCreateEmitter();
    const trail = new MovementTweenProxy(emitter, fromX, fromY);
    const duration = 1024;
    emitter.startFollow(trail.getFollowable());
    emitter.start();
    this._scene.tweens.add({
      props: {
        x: { value: toX, duration, ease: "cubic.easeIn" },
        y: { value: toY, duration, ease: "back.easeOut" },
      },
      onComplete: () => {
        emitter.stop();
        this._emittersThatAreDone.push(emitter);
        onComplete();
      },
      targets: trail,
    });
  }

  public getOrCreateEmitter(): ParticleEmitter {
    if (this._emittersThatAreDone.length > 0) {
      return this._emittersThatAreDone.pop();
    }
    const emitter = this.instantiate();
    this._emitters.push(emitter);
    return emitter;
  }

  public instantiate(): ParticleEmitter {
    const emitter = this._manager.createEmitter(
      new Function("return " + this._scene.cache.text.get("particle-effect"))(),
    );
    emitter.setBlendMode(BlendModes.ADD);
    return emitter;
  }
}
