/**
 * Use events to trigger functionality from the Overlay or handle logic internally
 */

import GameScene from "@engine/scenes/GameScene";
import EventEmitter = Phaser.Events.EventEmitter;
import { ITask } from "./Task";

export interface ITaskTracker {
  start();
  nextPage();
  previousPage();
  end();
}

export abstract class TaskTracker implements ITaskTracker {
  public EVENT_START = "EVENT_START";
  public EVENT_DONE = "EVENT_DONE";
  public EVENT_NEXT = "EVENT_NEXT";
  public EVENT_PREVIOUS = "EVENT_PREVIOUS";
  public EVENT_ABANDON = "EVENT_ABANDON";

  protected textSpeed = 25; //ms
  protected index = -1;
  protected page?: ITask;
  protected onboardingPages: ITask[] = [];

  protected onCloseStorage: string;
  protected onCompletedStorage: string;

  constructor(
    protected scene: GameScene,
    onboardingPages: ITask[],
    public eventEmitter: EventEmitter,
    onCloseStorage: string,
    onCompletedStorage: string,
  ) {
    this.onboardingPages = onboardingPages;
    this.onCloseStorage = onCloseStorage;
    this.onCompletedStorage = onCompletedStorage;
  }
  public start() {
    if (
      localStorage.getItem(this.onCloseStorage) === "true" &&
      localStorage.getItem(this.onCompletedStorage) === "true"
    )
      return;
    this.nextPage();
    this.eventEmitter.emit(this.EVENT_START);
  }
  private startPage(index: number) {
    this.clearEvents();
    this.index = index;
    if (this.index >= this.onboardingPages.length) {
      this.end();
      return;
    }
    this.page = this.onboardingPages[this.index];
    this.eventEmitter.addListener(
      this.onboardingPages[this.index].events.nextEventName,
      () => {
        this.page?.stopAction();
        this.nextPage();
      },
    );
    this.eventEmitter.addListener(
      this.onboardingPages[this.index].events.previousEventName,
      () => {
        this.page?.stopAction();
        this.previousPage();
      },
    );
    this.eventEmitter.addListener(
      this.onboardingPages[this.index].events.abandonEventName,
      () => {
        this.abandon();
      },
    );
    this.page.startAction();
  }
  public nextPage() {
    //Override me
    this.startPage(this.index + 1);
    this.eventEmitter.emit(this.EVENT_NEXT);
  }
  public previousPage() {
    //Override me
    this.startPage(this.index - 1);
    this.eventEmitter.emit(this.EVENT_PREVIOUS);
  }

  public end() {
    //Override me
    localStorage.setItem(this.onCloseStorage, "true");
    localStorage.setItem(this.onCompletedStorage, "true");
    this.clearEvents();
    this.eventEmitter.emit(this.EVENT_DONE);
    this.index = -1;
  }

  public abandon() {
    //Override me
    this.page?.stopAction();
    this.clearEvents();
    this.eventEmitter.emit(this.EVENT_ABANDON);
    this.index = -1;
  }

  public shutdown() {
    this.abandon();
  }
  protected clearEvents() {
    if (!this.page) return;
    this.eventEmitter.removeAllListeners(this.page.events.nextEventName);
    this.eventEmitter.removeAllListeners(this.page.events.previousEventName);
    this.eventEmitter.removeAllListeners(this.page.events.abandonEventName);
  }
}
