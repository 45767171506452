export const TradeContracts = {
  bsc: "0xff36c2c43da29e214e97d912149a349c50d54ec6",
  "bsc testnet": "0x408313848b9c56f3c5498bde4ecf96ea4e91ebd3",
};
export const TradeLibraryContracts = {
  bsc: "0x4f6b67661FbCb90b6B41E42ea6D9C5400679435d",
  "bsc testnet": "0x988f98E578d9d5507E8D803f1a90D3D5C37479A6",
};
export const TradeAbi = [
  {
    inputs: [
      {
        internalType: "address payable",
        name: "pool",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "fee",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "erc",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "setter",
        type: "address",
      },
    ],
    name: "AddERC",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "nonce",
        type: "bytes32",
      },
    ],
    name: "OfferCancelled",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "nonce",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "left",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "right",
        type: "address",
      },
      {
        components: [
          {
            components: [
              {
                internalType: "bytes32[]",
                name: "_values",
                type: "bytes32[]",
              },
            ],
            internalType: "struct TradingLibrary.ItemSet",
            name: "_inner",
            type: "tuple",
          },
        ],
        indexed: false,
        internalType: "struct TradingLibrary.TokenSet[]",
        name: "tokensLeft",
        type: "tuple[]",
      },
      {
        components: [
          {
            components: [
              {
                internalType: "bytes32[]",
                name: "_values",
                type: "bytes32[]",
              },
            ],
            internalType: "struct TradingLibrary.ItemSet",
            name: "_inner",
            type: "tuple",
          },
        ],
        indexed: false,
        internalType: "struct TradingLibrary.TokenSet[]",
        name: "tokensRight",
        type: "tuple[]",
      },
    ],
    name: "OfferCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "nonce",
        type: "bytes32",
      },
    ],
    name: "OfferFinalized",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "nonce",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "address",
        name: "signer",
        type: "address",
      },
    ],
    name: "OfferSigned",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "erc",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "setter",
        type: "address",
      },
    ],
    name: "RemoveERC",
    type: "event",
  },
  {
    stateMutability: "payable",
    type: "fallback",
  },
  {
    inputs: [],
    name: "FEE",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "FEE_POOL",
    outputs: [
      {
        internalType: "address payable",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "IERC721_INTEFACEID",
    outputs: [
      {
        internalType: "bytes4",
        name: "",
        type: "bytes4",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "addERC",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "nonce_",
        type: "bytes32",
      },
    ],
    name: "cancelOffer",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "erc",
        type: "address",
      },
    ],
    name: "getERCAllowed",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getERCAllowedAll",
    outputs: [
      {
        internalType: "address[]",
        name: "",
        type: "address[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "left",
        type: "address",
      },
      {
        internalType: "address",
        name: "right",
        type: "address",
      },
      {
        components: [
          {
            components: [
              {
                internalType: "bytes32[]",
                name: "_values",
                type: "bytes32[]",
              },
            ],
            internalType: "struct TradingLibrary.ItemSet",
            name: "_inner",
            type: "tuple",
          },
        ],
        internalType: "struct TradingLibrary.TokenSet[]",
        name: "tokensLeft",
        type: "tuple[]",
      },
      {
        components: [
          {
            components: [
              {
                internalType: "bytes32[]",
                name: "_values",
                type: "bytes32[]",
              },
            ],
            internalType: "struct TradingLibrary.ItemSet",
            name: "_inner",
            type: "tuple",
          },
        ],
        internalType: "struct TradingLibrary.TokenSet[]",
        name: "tokensRight",
        type: "tuple[]",
      },
      {
        internalType: "uint256",
        name: "seed",
        type: "uint256",
      },
    ],
    name: "getNonce",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "nonce_",
        type: "bytes32",
      },
    ],
    name: "getOffer",
    outputs: [
      {
        components: [
          {
            internalType: "address",
            name: "left",
            type: "address",
          },
          {
            internalType: "address",
            name: "right",
            type: "address",
          },
          {
            components: [
              {
                components: [
                  {
                    internalType: "bytes32[]",
                    name: "_values",
                    type: "bytes32[]",
                  },
                ],
                internalType: "struct TradingLibrary.ItemSet",
                name: "_inner",
                type: "tuple",
              },
            ],
            internalType: "struct TradingLibrary.TokenSet[]",
            name: "tokensLeft",
            type: "tuple[]",
          },
          {
            components: [
              {
                components: [
                  {
                    internalType: "bytes32[]",
                    name: "_values",
                    type: "bytes32[]",
                  },
                ],
                internalType: "struct TradingLibrary.ItemSet",
                name: "_inner",
                type: "tuple",
              },
            ],
            internalType: "struct TradingLibrary.TokenSet[]",
            name: "tokensRight",
            type: "tuple[]",
          },
          {
            internalType: "uint256",
            name: "finalizedBlock",
            type: "uint256",
          },
          {
            internalType: "enum ITrading.OfferState",
            name: "state",
            type: "uint8",
          },
        ],
        internalType: "struct ITrading.Offer",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "nonce",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "wallet",
        type: "address",
      },
    ],
    name: "getOfferSigned",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "wallet",
        type: "address",
      },
    ],
    name: "getWalletOffers",
    outputs: [
      {
        internalType: "bytes32[]",
        name: "",
        type: "bytes32[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "nonce_",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "left",
        type: "address",
      },
      {
        internalType: "address",
        name: "right",
        type: "address",
      },
      {
        components: [
          {
            components: [
              {
                internalType: "bytes32[]",
                name: "_values",
                type: "bytes32[]",
              },
            ],
            internalType: "struct TradingLibrary.ItemSet",
            name: "_inner",
            type: "tuple",
          },
        ],
        internalType: "struct TradingLibrary.TokenSet[]",
        name: "tokensLeft",
        type: "tuple[]",
      },
      {
        components: [
          {
            components: [
              {
                internalType: "bytes32[]",
                name: "_values",
                type: "bytes32[]",
              },
            ],
            internalType: "struct TradingLibrary.ItemSet",
            name: "_inner",
            type: "tuple",
          },
        ],
        internalType: "struct TradingLibrary.TokenSet[]",
        name: "tokensRight",
        type: "tuple[]",
      },
    ],
    name: "proceedOffer",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address",
      },
    ],
    name: "removeERC",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
];

export const TradeLibraryAbi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "tokenValue",
        type: "uint256",
      },
    ],
    name: "tokenSetConstructor",
    outputs: [
      {
        components: [
          {
            components: [
              {
                internalType: "bytes32[]",
                name: "_values",
                type: "bytes32[]",
              },
            ],
            internalType: "struct TradingLibrary.ItemSet",
            name: "_inner",
            type: "tuple",
          },
        ],
        internalType: "struct TradingLibrary.TokenSet",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "pure",
    type: "function",
  },
];
