import InteractiveFurnitureObject from "@game/mini/room/objects/InteractiveFurnitureObject";
import CustomizableRoomScene from "@game/mini/room/CustomizableRoomScene";
import { TInteractiveFurnitureConfig } from "@shared/consts/InteractiveFurniture";
import { IInteractiveFurnitureUseResponse } from "@common/modules/rooms/interfaces/FurnitureOperations";

const ROLL_ANIMATION_FRAMES = [0, 7, 8, 9, 10, 11, 8, 7];
const FRAME_DURATION = 50;

export default class FOTraditionalDice extends InteractiveFurnitureObject {
  private _animationIndex: number = 0;
  protected _frameTime: number = 0;
  private _rollResult: number;

  constructor(
    scene: CustomizableRoomScene,
    name: string,
    id: string,
    config: TInteractiveFurnitureConfig,
  ) {
    super(scene, name, id, config);

    this._frameNumber = Phaser.Math.RND.integerInRange(1, 6);
    this.updateFrames();
  }

  public override processActivationResponse(
    response: IInteractiveFurnitureUseResponse,
  ) {
    if (response.data.random === 0) {
      this._rollResult = 0;
      this.displayResult();
      return;
    }

    this._activated = true;
    this._animationIndex = 0;
    this._frameTime = 0;
    this._rollResult = response.data.random;
  }

  public setState(state: number) {
    this._rollResult = state;
    this.displayResult();
  }

  protected override update(time, delta) {
    if (!this._scene) return;

    if (this._activated) {
      this._frameTime += delta;

      if (this._frameTime >= FRAME_DURATION) {
        if (this._animationIndex === ROLL_ANIMATION_FRAMES.length - 1) {
          this._activated = false;
          this.displayResult();
        } else {
          this.updateAnimationFrame();
          this._frameTime -= FRAME_DURATION;
        }
      }
    }
  }

  private updateAnimationFrame() {
    this._animationIndex++;
    this._frameNumber = ROLL_ANIMATION_FRAMES[this._animationIndex];

    this.updateFrames();
  }

  private displayResult() {
    this._frameNumber = this._rollResult;
    this.updateFrames();
  }
}
