export default [
  {
    address: ["0x3b79a920068362e568d494f108CB1e536A3a6BE5"],
    type: "furniture",
    metadata: {
      name: "Fancy Sofa",
      description:
        "One of few majestic sofas that have gold plated sides to reflect your charms brightly into the eyes of your room guests. Definitely a rare items to see among Hotel guest rooms, therefore in high demand among the hotel elite!",
      attributes: [
        { trait_type: "Rarity", value: "Epic" },
        { trait_type: "Replicas", value: "10" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "fancysofayellow_2x1x3_1",
    },
  },
  {
    address: ["0xEfC39F9dC9a9303EA243411b58b913166E3EA4ba"],
    type: "furniture",
    metadata: {
      name: "Football Table",
      description:
        "A fun interactive piece of furniture that is perfect foor the football and soccer lovers! Definitely a great piece to add to your gamer room!",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "40" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "foosballtable_2x3x2_1",
    },
  },
  {
    address: ["0x3f60F65528C766753459A24B0BC4053bcD9Bf92b"],
    type: "furniture",
    metadata: {
      name: "Modern Sofa",
      description:
        "Whether you're chilling with your friends or having a nap, this sofa can comfort you whenever you need. Nine out of ten Bit Hotel designer's recommend you to own a sofa!",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "50" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "modernsofapink_2x1x2_1",
    },
  },
];
