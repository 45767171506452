import Camera = Phaser.Cameras.Scene2D.Camera;
import Path = Phaser.Curves.Path;
import Vector2 = Phaser.Math.Vector2;
import IsometricGameScene from "@game/engine/scenes/IsometricGameScene";
import { TypedNode } from "../navigation/Pathfinder";
import getCenterPoint, {
  worldPositionAtTilePosition,
} from "../utilities/TileHelper";
import Vector2Like = Phaser.Types.Math.Vector2Like;
import Tilemap = Phaser.Tilemaps.Tilemap;
import OrderableIsometricGameScene from "@game/engine/scenes/OrderableIsometricGameScene";

export default class TilePath {
  constructor(public nodes: TypedNode[] = []) {}

  public get goal(): TypedNode {
    return this.nodes.length > 0 ? this.nodes[this.nodes.length - 1] : null;
  }

  public get start(): TypedNode {
    return this.nodes.length > 0 ? this.nodes[0] : null;
  }

  public isFollowable(): boolean {
    return this.nodes.length > 0;
  }

  public toFollowablePathFromMap(
    map: Tilemap,
    startPosition: Vector2Like,
    useTilePosition: boolean = false,
  ): Path {
    const path: Path = new Path();
    this.nodes.forEach((node, index) => {
      const point: Vector2 = useTilePosition
        ? (
            map.scene as OrderableIsometricGameScene
          ).worldPositionAtTilePosition(node.position)
        : worldPositionAtTilePosition(map, node.position);

      // First point, just start
      if (index === 0) {
        // Check if we're standing on a tile or not
        if (startPosition.x === point.x && startPosition.y === point.y) {
          path.moveTo(point.x, point.y);
        } else {
          // If not, we'll start at our current position and just kinda skip the first tile
          path.moveTo(startPosition.x, startPosition.y);
          // path.lineTo(point.x, point.y);
        }
      } else {
        path.lineTo(point.x, point.y);
      }
    });
    return path;
  }

  public toFollowablePath(
    camera: Camera,
    scene: IsometricGameScene,
    startPosition: Vector2Like,
  ): Path {
    const path: Path = new Path(startPosition.x, startPosition.y);
    this.nodes.forEach((node, index) => {
      const point: Vector2 = getCenterPoint(node.value, camera, scene);

      // First point, just start
      if (index === 0) {
        path.moveTo(point.x, point.y);
      } else {
        path.lineTo(point.x, point.y);
      }
    });
    return path;
  }
}
