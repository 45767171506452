import {
  Channel,
  ChannelPresenceEvent,
  Client,
  Friends,
  LeaderboardRecord,
  MatchPresenceEvent,
  NotificationList,
  Presence,
  RpcResponse,
  Session,
  Socket,
} from "@heroiclabs/nakama-js";
import { Match, MatchData } from "@heroiclabs/nakama-js/socket";
import {
  dispatchEvent,
  EnumNetworkEvents,
  EnumUiEvents,
  handleEvent,
  handleEventOnce,
} from "@shared/Events";
import { currentAccount } from "@shared/Global";
import { TInventoryResponse } from "@shared/types/NetworkTypes";
import { TNakamaAccount, TNakamaUser } from "@shared/types/nakama/NakamaTypes";
import { chainIdToName, getDebugMode } from "@shared/Helpers";
import {
  clearLocalSession,
  getLocalSession,
  storeLocalSession,
} from "@shared/network/Session";
import { EChainId, TUiWalletChanged } from "@shared/types";
import {
  EnumAllEvents,
  EnumApplicationEvents,
  EnumGameEvents,
} from "@shared/enums";
import BasicCache from "@shared/Cache";
import NotificationCenter from "@game/engine/networking/NotificationCenter";
import { TradeItem, TradeObject } from "@shared/types/TradeTypes";
import { TradeAbi, TradeContracts } from "@shared/consts/Trade";
import { TActiveNftData } from "@shared/types/NftTypes";
import { NFTHelpers } from "@shared/nft";
import { SeasonUtilities } from "@common/utilities/SeasonUtilities";
import { IModuleConfiguration } from "@common/interfaces/IModuleConfiguration";
import { getAccountAssets, walletAddress } from "@overlay/modules/chain/wallet";
import { currentChainId } from "@overlay/modules/chain";
import IChatMessageEvent, {
  EnumChatEvents,
} from "@engine/interfaces/IChatMessageEvent";
import { IPrivateMetadata } from "@common/interfaces/IPrivateMetadata";
import { ISecretMetadata } from "@common/interfaces/ISecretMetadata";
import { UserMetadata } from "@shared/types/nakama";
import EventEmitter = Phaser.Events.EventEmitter;
import { mobileUnlockOrientation } from "@/mobile/postMessage";
import Matchmaker from "@engine/networking/Matchmaker";
import { Toast } from "@engine/notifications/Toast";

export const MatchEvents = {
  OnData: "onmatchdata",
  OnMatchJoined: "onmatchjoined",
  OnPresenceJoined: "onmatchpresencejoined",
  OnPresenceLeft: "onmatchpresenceleft",
};

export const ChannelEvents = {
  OnPresenceJoined: "onchannelpresencejoined",
  OnPresenceLeft: "onchannelpresenceleft",
};

export namespace Network {
  const PrivateMetadataStorageCollection: string = "private";
  const PrivateMetadataStorageKey: string = "metadata";
  const SecretMetadataStorageCollection: string = "secret";
  const SecretMetadataStorageKey: string = "metadata";

  interface INetworkCore {
    authenticateEmail(email: string, password: string): Promise<boolean>;

    connect(): Promise<boolean>;

    rpc(identifier: string, input?: object): Promise<RpcResponse>;

    sendMatchState(
      operationCode: number,
      data: any,
      presences?: Presence[],
    ): Promise<void>;
  }

  //TODO: move logger to @shared

  class LogPrinter extends EventEmitter {
    private readonly _debugMode: number = 0;

    constructor(private readonly _loggerName) {
      super();
      this._debugMode = getDebugMode(_loggerName);
    }

    public error(message: Error | any, ...args): void {
      if (this._debugMode >= 2) {
        let error: Error;
        if (message instanceof Error) {
          error = message;
        } else if (message.message) {
          error = new Error(message.message);
        } else {
          error = new Error(message);
        }
        console.groupCollapsed(
          "\x1b[31m[%sERROR] %s\x1b[0m",
          this._loggerName + "_",
          error.message,
        );
        console.error(error, message, ...args);
        console.groupEnd();
      }
    }

    public info(...args): void {
      if (this._debugMode) {
        const containingObject = [...args].find(
          (arg) => typeof arg == "object",
        );
        const messages = [...args].filter((arg) => typeof arg === "string");
        const message = !containingObject
          ? [...args].join(" ")
          : messages.join(" ") +
            ` { ${Object.keys(containingObject).join(", ")}} `;
        console.groupCollapsed(
          "\x1b[34m[%sINFO] %s\x1b[0m",
          this._loggerName + "_",
          message,
        );
        console.log(...args);
        if (this._debugMode === 3) {
          console.trace();
        }
        console.groupEnd();
      }
    }

    public trace(...args): void {
      if (this._debugMode === 3) {
        console.groupCollapsed(...["[TRACE]", ...args]);
        console.trace();
        console.groupEnd();
      }
    }
  }

  abstract class IEventHandler extends LogPrinter implements INetworkCore {
    protected static eventHandlers: {
      [key: string]: {
        handler: (data?: any) => void;
        options?: { handleOnce: boolean };
      };
    } = {};
    private static isInitialized = false;
    protected onChannelPresence: (presences: ChannelPresenceEvent) => void;
    protected onMatchData: (result: MatchData) => void;
    protected onMatchPresence: (presences: MatchPresenceEvent) => void;

    public static initListeners(forceInit: boolean = false) {
      if (!this.isInitialized || forceInit) {
        for (const handlerName of Object.keys(this.eventHandlers)) {
          if (this.eventHandlers[handlerName].options?.handleOnce) {
            handleEventOnce(
              handlerName as EnumAllEvents,
              this.eventHandlers[handlerName].handler.bind(this),
            );
          } else {
            handleEvent(
              handlerName as EnumAllEvents,
              this.eventHandlers[handlerName].handler.bind(this),
            );
          }
        }
        this.isInitialized = true;
      }
    }

    protected static panic() {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
      clearLocalSession();
    }

    abstract authenticateEmail(
      email: string,
      password: string,
    ): Promise<boolean>;

    abstract connect(): Promise<boolean>;

    abstract getNftMetadata(address: string, id: string): Promise<object>;

    abstract joinMatch(matchIdentifier: string): Promise<Match>;

    abstract joinPrivateChat(
      channelId: string,
      privateName: string,
    ): Promise<string>;

    abstract listNotifications(limit: number): Promise<NotificationList>;

    abstract requestMinigameSettings(sceneKey: string): Promise<{
      currency?: { [key: string]: string };
      playableAmount?: { [key: string]: string };
      playerToWin?: { [key: string]: string };
    }>;

    abstract rpc(identifier: string, input?: object): Promise<RpcResponse>;

    abstract sendKarma(toId: string): Promise<RpcResponse>;

    abstract sendMatchState(
      operationCode: number,
      data: any,
      presences?: Presence[],
    ): Promise<void>;

    abstract tradeAbandon(key: string): Promise<RpcResponse>;

    abstract tradeAnswer(
      key: string,
      from: string,
      answer: boolean,
    ): Promise<RpcResponse>;

    abstract tradeChange(
      key: string,
      items: TradeItem[],
      locked: boolean,
    ): Promise<RpcResponse>;

    abstract tradeCommit(
      key: string,
      tx: string,
      answer: boolean,
    ): Promise<RpcResponse>;

    abstract tradeGetUserTrades(): Promise<TradeObject[]>;

    abstract tradePropose(to: string): Promise<RpcResponse>;

    abstract tradeUserAvailableERCs(): Promise<Map<string, object>>;
  }

  //@ts-ignore

  export class Core extends IEventHandler {
    static eventHandlers = {
      [EnumUiEvents.UINetworkLeaderboardRetrieveRecords]: {
        async handler(leaderboardId) {
          const records = await Core.core.retrieveLeaderboard(leaderboardId);
          if (!records || records.length === 0) {
            dispatchEvent(EnumUiEvents.UINetworkLeaderboardReceivedRecords, []);
            return;
          }
          const userIdToMetaData = {};
          const userIdToActiveCharacter = {};

          try {
            const userIds = records.map((record) => record.owner_id);
            const userDatas = await Core.core.getUsersData(userIds);
            console.log({ userDatas });

            for (const userData of userDatas) {
              userIdToMetaData[userData.id] = userData.metadata;
              userIdToActiveCharacter[userData.id] =
                userData.metadata.activeCharacter.name;
            }
          } catch (error) {
            console.log(error);
          }

          const maxPercentageToTier = {
            5: "diamond",
            15: "gold",
            40: "silver",
            75: "bronze",
            100: "stone",
          };

          const leaderboardRecords = [];
          for (let index = 0; index < records.length; ++index) {
            const topPercentage = (index / records.length) * 100;
            const record = records[index];
            console.log(record.owner_id);
            const metadata = userIdToMetaData[record.owner_id];

            // Retrieve the data for this specific crown
            let tier = "stone";
            for (const percentage of Object.keys(maxPercentageToTier)) {
              if (topPercentage <= Number(percentage)) {
                tier = maxPercentageToTier[percentage];
                break;
              }
            }

            const seasonIdentifier =
              SeasonUtilities.getCurrentSeasonIdentifier();
            let losses = 0;
            let wins = record.subscore;
            if (
              "seasonalStatistics" in metadata &&
              seasonIdentifier in metadata.seasonalStatistics
            ) {
              losses = metadata.seasonalStatistics[seasonIdentifier].losses;
              wins = metadata.seasonalStatistics[seasonIdentifier].wins;
            }

            leaderboardRecords.push({
              activeCharacterName:
                userIdToActiveCharacter[record.owner_id] || null,
              losses,
              points: record.score,
              progress: 0,
              rank: record.rank,
              tickets: record.score,
              tier: tier,
              userId: record.owner_id,
              username: record.username,
              wins,
            });
          }

          dispatchEvent(
            EnumUiEvents.UINetworkLeaderboardReceivedRecords,
            leaderboardRecords,
          );
        },
      },
      [EnumUiEvents.UiNetworkRequestInventory]: {
        async handler() {
          try {
            await this.getInventory();
          } catch (e) {
            console.error(e);
          }
        },
      },
      [EnumUiEvents.UiNetworkUpdateCharacter]: {
        async handler(characterAddress: string) {
          // try {
          if (!NFTHelpers.isAddressValid(characterAddress)) {
            characterAddress = "0xDEF";
          }
          await Core.core.rpc("UserSetActiveCharacterAddress", {
            characterAddress,
          });
          const updatedAccount = await Core.getCurrentSessionAccount();
          BasicCache.playerMetadata[Core.currentUser.id] = {
            ...updatedAccount.user.metadata,
            username: updatedAccount.user.username
              ? updatedAccount.user.username
              : updatedAccount.user.metadata.username,
          };
          // } catch (e) {
          //   Core.logger.error(e);
          // }
        },
      },
      [EnumUiEvents.UiNetworkLeaderboardGetTop]: {
        async handler() {
          await this.getLeaderboardTop();
        },
      },
      [EnumUiEvents.UiNetworkUserTicketTransfer]: {
        async handler({ targetUserId, amount }) {
          try {
            await this.userTransferTickets(targetUserId, amount);
          } catch (e) {
            console.error(e);
          }
        },
      },
      [EnumNetworkEvents.NetworkRequestUserData]: {
        async handler(userId: string) {
          if (!userId || userId === "") {
            await Core.getCurrentSessionAccount();
            return;
          }
          const user = await Core.getUsersData(userId);
          if (user.length > 0) {
            BasicCache.playerMetadata[userId] = {
              ...user[0].metadata,
              username: user[0].username
                ? user[0].username
                : user[0].metadata.username,
            };
          }
        },
      },
      [EnumNetworkEvents.NetworkRequestStorageItem]: {
        //TODO:
        async handler({ collection, key }) {
          await Core.core.getStorageItems([{ collection, key }]);
        },
      },
      [EnumNetworkEvents.NetworkCallRPC]: {
        async handler({ identifier, input }) {
          const resp = await Core.rpc(identifier, input);
          dispatchEvent(EnumNetworkEvents.NetworkCallRPCResponse, {
            identifier,
            input,
            payload: resp.payload,
          });
        },
      },
      [EnumNetworkEvents.NetworkRequestUsersData]: {
        async handler(data: {
          identifier: string;
          userId?: string;
          userIds?: Array<string>;
        }) {
          let userIds = data.userIds;
          if (data.userId) {
            userIds = [data.userId];
          }
          const users = await Core.getUsersData(userIds);
          dispatchEvent(EnumNetworkEvents.NetworkRequestUsersDataResponse, {
            ...data,
            users,
          });
        },
      },
    };
    private static state = {
      isAuthenticated: false,
      _hasJoinedMatch: false,
    };

    private static _core: _NetworkCore;

    /**
     * Returns current network core
     * or creates a new one
     * ! TODO: make this private
     */
    public static get core(): _NetworkCore {
      if (Core._core) {
        return Core._core;
      }
      return Core.create();
    }

    public static get currentMatchId() {
      //TODO: make this private
      return Core.core._match?.match_id || "";
    }

    public static get currentUser() {
      return Core.core.currentUser;
    }

    public static get isAuthenticated() {
      Core._logger.info("getting auth", Core.state);
      return Core.state.isAuthenticated;
    }

    private static set isAuthenticated(val) {
      Core._logger.info("setting isAuthenticated to " + val);
      Core.state.isAuthenticated = val;
    }

    private static _logger: LogPrinter = new LogPrinter("NK_NET");

    private static get logger() {
      return Core._logger;
    }

    public get core(): _NetworkCore {
      return Core.core;
    }

    constructor(id: string = "NC_WRAPPER") {
      super(id);
      Core._logger = new LogPrinter(id);
    }

    public static async addFriend(userId: string): Promise<boolean> {
      return Core.core.addFriend(userId);
    }

    public static async authenticateEmail(
      email: string,
      password: string,
    ): Promise<{ success: boolean; [key: string]: any }> {
      try {
        await Core.core.authenticateEmail(email, password);
        await Core.getCurrentSessionAccount();
        Core.state.isAuthenticated = true;
        Core.core.storeLocalSession();
        return { success: true };
      } catch (error) {
        let errorMessage;
        try {
          errorMessage = await error.json();
          Core.logger.error(errorMessage);
        } catch (failsafe) {
          errorMessage = failsafe;
          Core.logger.error("failsafe", failsafe);
        }
        return { success: false, ...errorMessage };
      }
    }

    static async blockFriend(userId: string): Promise<boolean> {
      return Core.core.blockFriend(userId);
    }

    static async connect(): Promise<boolean> {
      return await Core.core.connect();
    }

    public static async connectAndJoin() {
      await Core.connect();
      await Core.gameFindMatch();
    }

    public static create(): _NetworkCore {
      try {
        if (Core._core) {
          /**
           * validate if it isn't corrupted or smth
           * can be extended to handle multiple network sessions
           */
          throw new Error(
            "Attempted to create new NetworkCore while already exists",
          );
        }
        Core._core = new _NetworkCore();
      } catch (e) {
        Core.logger.error("%s", e);
        // this._core = new _NetworkCore();
        if (Core._core) {
          Core.panic();
        }
      }
      return Core._core;
    }

    public static async getCurrentSessionAccount(): Promise<TNakamaAccount> {
      const account = await Core.core.getAccountFromSession();
      Object.assign(currentAccount, account);
      const userMetadata: UserMetadata = JSON.parse(account.user.metadata);
      currentAccount.user.metadata = userMetadata;
      currentAccount.user.metadata.activeCharacter = NFTHelpers.NFT.fromAddress(
        userMetadata.activeCharacterAddress || "0xDEF",
      );
      currentAccount.wallet = JSON.parse(account.wallet);
      return currentAccount;
    }

    public static async getCurrentUserId(): Promise<string> {
      return (await this.getCurrentSessionAccount()).user.id;
      s;
    }

    public static async getDisabledModules(): Promise<IModuleConfiguration[]> {
      return this.getModulesConfiguration(false);
    }

    public static async getEnabledModules(): Promise<IModuleConfiguration[]> {
      return this.getModulesConfiguration(true);
    }

    public static async getInventory(): Promise<void> {
      const resp: TInventoryResponse = Object.create(
        await this.core.rpc("userGetWalletNfts", {}),
      );
      let payload;
      if ("payload" in resp) {
        if ("result" in resp.payload) {
          payload = resp.payload.result;
        } else {
          payload = resp.payload;
        }
      }
      if (payload.length === 0) {
        dispatchEvent(EnumUiEvents.UiNetworkRequestInventoryUpdate, []);
        return;
      }
      const validNfts = payload.filter((nft) =>
        NFTHelpers.isAddressValid(nft.token_address),
      );

      console.log({ validNfts });
      for (const nft of validNfts) {
        if (nft.metadata && typeof nft.metadata === "string") {
          try {
            nft.metadata = JSON.parse(nft.metadata);
          } catch (e) {
            console.error(e.message);
          }
        } else if (nft.token_uri.includes("bithotel")) {
          const metadata = await fetch(nft.token_uri);
          nft.metadata = await metadata.json();
        }
      }

      dispatchEvent(EnumUiEvents.UiNetworkRequestInventoryUpdate, validNfts);
    }

    public static async getLeaderboardTop() {
      const resp = await this.rpc("leaderboardGetTop", {});
      dispatchEvent(
        EnumUiEvents.UiNetworkLeaderboardGetTopUpdate,
        resp.payload,
      );
    }

    public static async getModulesConfiguration(
      enabled: boolean = true,
    ): Promise<IModuleConfiguration[]> {
      const response = await this.rpc(
        enabled ? "EnabledModules" : "DisabledModules",
      );
      return response && "payload" in response && "modules" in response.payload
        ? <IModuleConfiguration[]>response.payload.modules
        : [];
    }

    public static getNftMetadata(address: string, id: string): Promise<object> {
      return Core.core.getNftMetadata(address, id);
    }

    public static async getUsersData(userIds: string | Array<string>) {
      return this.core.getUsersData(userIds);
    }

    //Similar as above, but the metadata is not in a child object
    public static async getUsersSquashedData(userIds: string | Array<string>) {
      return this.core.getUsersSquashedData(userIds);
    }

    public static async joinChat(chatIdentifier: string) {
      await Core.core.joinChat(chatIdentifier);
    }

    static joinMatch(matchIdentifier: string): Promise<Match> {
      return Core.core.joinMatch(matchIdentifier);
    }

    public static async joinPrivateChat(
      channelId: string,
      privateName: string,
    ): Promise<string> {
      return Core.core.joinPrivateChat(channelId, privateName);
    }

    public static async leave() {
      return await Core.core.leave();
    }

    public static async leaveChat(channelId: string) {
      return await Core.core.leaveChat(channelId);
    }

    public static listFriends(): Promise<Friends> {
      return Core.core.listFriends();
    }

    public static listNotifications(limit: number): Promise<NotificationList> {
      return Core.core.listNotifications(limit);
    }

    public static async refreshAuthenticationIfPossible() {
      if (await Core.core.refreshAuthenticationIfPossible()) {
        await this.getCurrentSessionAccount();
        Core.isAuthenticated = true;
      }
      return Core.isAuthenticated;
    }

    public static async removeFriend(userId: string) {
      return Core.core.removeFriend(userId);
    }

    static async requestMinigameSettings(sceneKey: string): Promise<{
      currency?: Array<string>;
      playableAmount?: { [key: string]: string };
      playerToWin?: { [key: string]: string };
    }> {
      return Core.core.requestMinigameSettings(sceneKey);
    }

    static rpc(identifier: string, input?: object): Promise<RpcResponse> {
      return Core.core.rpc(identifier, input);
    }

    static searchFriend(input: string): Promise<
      Array<{
        id: string;
        username: string;
        metadata: { activeCharacter: { [key: string]: string } };
      }>
    > {
      return Core.core.searchFriend(input);
    }

    static retrieveSecretMetadata(): Promise<ISecretMetadata | null> {
      return Core.core.retrieveSecretMetadata();
    }

    static sendKarma(toId: string): Promise<RpcResponse> {
      return Core.core.sendKarma(toId);
    }

    static sendMatchState(
      operationCode: number,
      data: any,
      presences?: Presence[],
    ): Promise<void> {
      return Core.core.sendMatchState(operationCode, data, presences);
    }

    static tradeAbandon(key: string): Promise<RpcResponse> {
      return Core.core.tradeAbandon(key);
    }

    static tradeAnswer(
      key: string,
      from: string,
      answer: boolean,
    ): Promise<RpcResponse> {
      return Core.core.tradeAnswer(from, key, answer);
    }

    static tradeChange(
      key: string,
      items: TradeItem[],
      locked: boolean,
    ): Promise<RpcResponse> {
      return Core.core.tradeChange(key, items, locked);
    }

    static tradeCommit(
      key: string,
      tx: string,
      answer: boolean,
    ): Promise<RpcResponse> {
      return Core.core.tradeCommit(key, tx, answer);
    }

    static tradeGetUserTrades(): Promise<TradeObject[]> {
      return Core.core.tradeGetUserTrades();
    }

    static tradePropose(to: string): Promise<RpcResponse> {
      return Core.core.tradePropose(to);
    }

    static tradeUserAvailableERCs(): Promise<Map<string, object>> {
      return Core.core.tradeUserAvailableERCs();
    }

    public static async userTransferTickets(
      targetUserId: string,
      amount: number,
    ) {
      await this.rpc("sendTicketsToUser", {
        targetUserId,
        amount,
      });

      dispatchEvent(EnumUiEvents.UiUserTicketTransferUpdate);
    }

    public static async writeChatMessage(channelId: string, message: any) {
      return Core.core.writeChatMessage(channelId, message);
    }

    private static async gameFindMatch(
      module = "lobby",
      randomMatch: boolean = true,
    ): Promise<void> {
      try {
        const response = await Core.rpc("gameFindMatch", {
          module,
        });
        if ("payload" in response) {
          const payload: { ids?: string[] } = response.payload;
          if ("ids" in payload) {
            const ids = payload.ids;
            if (ids.length > 0) {
              // See if we can join a random match, or just join the first one available
              if (randomMatch && ids.length > 1) {
                await Core.joinMatch(
                  ids[Phaser.Math.Between(0, ids.length - 1)],
                );
              } else {
                await Core.joinMatch(ids[0]);
              }

              // If we got here, we did join a match
              this.state._hasJoinedMatch = true;
            }
          }
        }
      } catch (e) {
        this.state._hasJoinedMatch = false;
        this.logger.error(e);
        throw new Error("Failed to join match");
      }
    }
  }

  // Core nakama interactions
  class _NetworkCore extends IEventHandler {
    public _match: Match;
    //TODO: Temp public
    public _socket: Socket;
    private _channel: Channel;
    private readonly _client: Client;
    private _remotePresences: Presence[];
    private _session: Session;

    public get currentUser() {
      return { id: this._session.user_id, username: this._session.username };
    }

    constructor() {
      const {
        VITE_SERVER_SOCKET_KEY: serverKey,
        VITE_SERVER_HOST: serverHost,
        VITE_SERVER_PORT: serverPort,
        VITE_SERVER_USE_HTTPS: serverUseSSL,
      } = import.meta.env;
      super("NETWORK_CORE");
      this.info("created network_core", serverHost, serverPort);
      this._remotePresences = [];
      this._client = new Client(
        <string>serverKey,
        <string>serverHost,
        <string>serverPort,
        serverUseSSL === "true",
      );
      handleEvent(
        EnumUiEvents.UiWalletChanged,
        async (data: TUiWalletChanged) => {
          if (!data.reconnect) {
            const result = (await this.rpc("userSetWalletAddress", data)) as {
              payload: { success: boolean; error?: string };
            };
            if (result.payload.success !== true) {
              Toast.error(result.payload.error);
              return;
            }
          }
          walletAddress.value =
            data.walletAddress !== "null" ? data.walletAddress : undefined;
          currentChainId.value = data.chainId;
          localStorage.setItem("chainId", EChainId[currentChainId.value]);
          dispatchEvent(EnumUiEvents.UiNetworkRequestInventory);
          dispatchEvent(EnumNetworkEvents.NetworkRequestUserData);
          getAccountAssets();
          if (data.walletAddress === "null")
            Toast.info("Wallet successfully disconnected");
          else {
            Toast.info("Wallet successfully connected");
            dispatchEvent(EnumGameEvents.GameNetworkTradeGetOngoingTrades);
          }
        },
      );
      handleEventOnce(EnumUiEvents.UiLogout, async () => {
        try {
          await this._client.sessionLogout(
            this._session,
            this._session.token,
            this._session.refresh_token,
          );
          mobileUnlockOrientation();
        } catch (_) {}
        clearLocalSession();
        window.location.pathname = "/login";
      });
      handleEvent(EnumUiEvents.UINetworkRequestRoomsList, async () => {
        const list = await this.rpc("roomList", {});
        if (list && list.payload && (list.payload as any).rooms) {
          dispatchEvent(
            EnumNetworkEvents.NetworkUiUpdateRoomsList,
            (list.payload as any).rooms,
          );
        }
      });
      handleEvent(EnumNetworkEvents.NetworkRequestUserActiveNfts, async () => {
        const response = await this.rpc("userGetActiveNfts", {});

        if (
          response &&
          response.payload &&
          "success" in response.payload &&
          response.payload.success
        ) {
          dispatchEvent(
            EnumNetworkEvents.NetworkUpdateUserActiveNfts,
            (
              response.payload as {
                success: boolean;
                activeNfts: TActiveNftData[];
              }
            ).activeNfts,
          );
        }
      });
    }

    public async addFriend(userId: string): Promise<boolean> {
      return this._client.addFriends(this._session, [userId]);
    }

    public async authenticateEmail(
      email: string,
      password: string,
    ): Promise<boolean> {
      if (
        import.meta.env.VITE_SERVER_CREATE_ACCOUNT_ON_LOGIN &&
        import.meta.env.VITE_SERVER_CREATE_ACCOUNT_ON_LOGIN === "true"
      ) {
        this._session = await this._client.authenticateEmail(
          email,
          password,
          true,
          email.split("@")[0],
        );

        localStorage.setItem("jwt", this._session.token);
      } else {
        this._session = await this._client.authenticateEmail(
          email,
          password,
          false,
        );
      }

      // If we get here, auth went well
      this.info("Authenticated successfully");
      //load the global current account
      return true;
    }

    public async blockFriend(userId: string): Promise<boolean> {
      return this._client.blockFriends(this._session, [userId]);
    }

    public async connect() {
      try {
        if (!this._socket) {
          this._socket = this._client.createSocket(
            import.meta.env.VITE_SERVER_USE_HTTPS === "true" ?? false,
            false,
          );
        }
        // The second part determines "online" status
        this._session = await this._socket.connect(this._session, true);
        this._socket.ondisconnect = () => {
          setTimeout(() => {
            dispatchEvent(EnumNetworkEvents.NetworkSocketDisconnected);
          }, 1000);
        };
        this._socket.onchannelmessage = (message: IChatMessageEvent) =>
          dispatchEvent(EnumNetworkEvents.NetworkChatMessage, message);
        dispatchEvent(EnumApplicationEvents.ApplicationNetworkAuthenticated);

        // We can initialize the Notification Center now we're all connected and such
        NotificationCenter.instance.initialize(
          this._client,
          this._session,
          this._socket,
        );

        Matchmaker.instance.initialize(this._socket);

        return true;
      } catch (e) {
        this.error(e);
      }
      return false;
    }

    public async getAccountFromSession(session?: Session) {
      if (session || this._session) {
        return await this._client.getAccount(session || this._session);
      }
      throw new Error("Trying to get account without session");
    }

    public async getChatHistory(channelId: string) {
      return await this._client.listChannelMessages(
        this._session,
        channelId,
        20,
        false,
      );
    }

    public async getNftMetadata(address: string, id: string): Promise<object> {
      return this._client.rpc(this._session, "userGetNftMetadata", {
        address: address,
        id: id,
      });
    }

    public async getStorageItems(
      storageRequests: Array<{
        collection?: string;
        key?: string;
        user_id?: string;
      }>,
    ) {
      storageRequests.forEach(
        (request) => (request.user_id = request.user_id || this.currentUser.id),
      );
      const ret = await this._client.readStorageObjects(this._session, {
        object_ids: storageRequests,
      });
      return ret.objects;
    }

    public async getUsersData(userIds: string | Array<string>) {
      const ret = await this._client.getUsers(
        this._session,
        typeof userIds === "string" ? [userIds] : userIds,
      );
      if (!ret.users || ret.users.length === 0) {
        throw new Error("no users found with ids: " + JSON.stringify(userIds));
      }
      return ret.users.map((user) => {
        user.metadata.activeCharacter = NFTHelpers.NFT.fromAddress(
          user.metadata.activeCharacterAddress || "0xDEF",
        );
        return user;
      }) as Array<TNakamaUser>;
    }

    //Similar as above, but the metadata is not in a child object
    public async getUsersSquashedData(userIds: string | Array<string>) {
      const userData = (await this.getUsersData(userIds))[0];
      return {
        ...userData,
        ...userData.metadata,
        username: userData.username
          ? userData.username
          : userData.metadata.username,
      };
    }

    public async joinChat(chatIdentifier: string) {
      this._channel = await this._socket.joinChat(
        chatIdentifier,
        1,
        true,
        false,
      );
      dispatchEvent(EnumNetworkEvents.NetworkChatJoinChannel, {
        channelId: this._channel.id,
      });
      this.info("joined chat " + this._channel.id);
      const messageHistory = await this.getChatHistory(this._channel.id);
      if (
        messageHistory &&
        messageHistory.messages &&
        messageHistory.messages.length
      ) {
        const missingCacheUserIds = [];
        for (const message of messageHistory.messages) {
          if (!BasicCache.playerMetadata[message.sender_id]) {
            missingCacheUserIds.push(message.sender_id);
          }
        }
        if (missingCacheUserIds.length) {
          //fetch missing user metadata in the background
          this.getUsersData(missingCacheUserIds).then((users) => {
            for (const user of users) {
              const userMetadata = user.metadata;
              BasicCache.playerMetadata[user.id] = {
                ...userMetadata,
                username: user.username
                  ? user.username
                  : user.metadata.username,
              };
            }
          });
        }
        dispatchEvent(
          EnumNetworkEvents.NetworkUiUpdateMessageHistory,
          messageHistory.messages,
        );
      }
    }

    public async joinMatch(matchIdentifier: string): Promise<Match> {
      // Hook into the match, now that we're connected to one
      this._socket.onmatchdata = this.onMatchData;
      this._socket.onmatchpresence = this.onMatchPresence;
      this._socket.onchannelpresence = this.onChannelPresence;
      // try {
      //   //TODO: hotfix to prevent "already joined"
      //   await this._socket.leaveMatch(matchIdentifier);
      // } catch (e) {
      //   this.error(e);
      // }
      this._match = await this._socket.joinMatch(matchIdentifier);
      this.info("Joined match", this._match);
      if (this._match.presences) {
        // Filter ourself out of the remote presences
        this._remotePresences = this._match.presences.filter((presence) => {
          return presence.user_id !== this._match.self.user_id;
        });

        // Let others know one by one
        this._remotePresences.forEach((presence) => {
          this.emit(MatchEvents.OnPresenceJoined, presence);
        });
      }

      return this._match;
    }

    public async joinPrivateChat(
      channelId: string,
      privateName: string,
    ): Promise<string> {
      const channel = await this._socket.joinChat(channelId, 2, true, false);
      this.info("joined chat " + channel.id);
      dispatchEvent(EnumNetworkEvents.NetworkChatJoinChannel, {
        channelId: channel.id,
        channelType: "DirectMessage",
        privateName,
      });
      return channel.id;
    }

    public async leave(): Promise<unknown> {
      if (this._channel) {
        // console.log("leaving chat " + this._channel.id);
        await this._socket.leaveChat(this._channel.id);
      }
      if (this._match) {
        // console.log("leaving match " + this._match.match_id);
        await this._socket.leaveMatch(this._match.match_id);
        this._match = null;
      }
      return Promise.resolve();
    }

    public async leaveChat(channelId: string) {
      return this._socket.leaveChat(channelId);
    }

    public async listFriends(): Promise<Friends> {
      return this._client.listFriends(this._session);
    }

    public async listNotifications(limit: number): Promise<NotificationList> {
      return this._client.listNotifications(this._session, limit);
    }

    public async refreshAuthenticationIfPossible() {
      let authToken, refreshToken: string;
      // try catch, because this will fail on older app versions
      try {
        const session = await getLocalSession();
        authToken = session.authToken;
        refreshToken = session.refreshToken;
      } catch (e) {}
      if (null !== authToken && null !== refreshToken) {
        try {
          this._session = Session.restore(authToken, refreshToken);
          if (this._session.isrefreshexpired(Date.now() / 1000)) {
            throw new Error("Refresh token has expired, please log in again");
          }
          this._socket = this._client.createSocket(
            import.meta.env.VITE_SERVER_USE_HTTPS === "true" ?? false,
            false,
          );
          // The second part determines "online" status
          this._session = await this._socket.connect(this._session, true);
          this.info("Restored session successfully");
          return true;
        } catch (e) {
          clearLocalSession();
          this.error(
            "Failed to restore authentication token from localStorage, redirecting to /login",
            e,
          );
        }
      }
      return false;
    }

    public async removeFriend(userId: string): Promise<boolean> {
      return this._client.deleteFriends(this._session, [userId]);
    }

    public async requestMinigameSettings(sceneKey: string): Promise<{
      currency?: Array<string>;
      playableAmount?: { [key: string]: string };
      playerToWin?: { [key: string]: string };
    }> {
      return (await this.rpc("gameRequestSettings", { key: sceneKey })).payload;
    }

    public async retrieveLeaderboard(
      leaderboardId: string,
      limit: number = 100, // For some reason it's always 1 more than the limit
    ): Promise<LeaderboardRecord[]> {
      try {
        const records = await this._client.listLeaderboardRecords(
          this._session,
          leaderboardId,
          [],
          limit - 1,
        );
        return records.records || null;
      } catch (error) {
        // console.error(error);
        return null;
      }
    }

    public retrievePersonalMetadata<T>(
      collection: string,
      key: string,
      userId: string,
    ): Promise<T | null> {
      return new Promise<T | null>((resolve, reject) => {
        this._client
          .readStorageObjects(this._session, {
            object_ids: [
              {
                collection,
                key,
                user_id: userId,
              },
            ],
          })
          .then((result) => {
            if (result.objects.length > 0) {
              resolve(<T>result.objects[0].value);
            }
            resolve(null);
          })
          .catch(reject);
      });
    }

    public retrievePrivateMetadata(): Promise<IPrivateMetadata | null> {
      return this.retrievePersonalMetadata<IPrivateMetadata>(
        PrivateMetadataStorageCollection,
        PrivateMetadataStorageKey,
        this._session.user_id,
      );
    }

    public retrieveSecretMetadata(): Promise<ISecretMetadata | null> {
      return this.retrievePersonalMetadata<ISecretMetadata>(
        SecretMetadataStorageCollection,
        SecretMetadataStorageKey,
        this._session.user_id,
      );
    }

    public async rpc(
      identifier: string,
      input: object = {},
    ): Promise<RpcResponse> {
      return this._client.rpc(this._session, identifier, input);
    }

    public async searchFriend(input: string): Promise<
      Array<{
        id: string;
        username: string;
        metadata: { activeCharacter: { [key: string]: string } };
      }>
    > {
      const result = await this.rpc("userSearchFriend", {
        input,
      });
      if (!result.payload.success) {
        Toast.error(result.payload.message);
        return undefined;
      }
      return result.payload.result;
    }

    public sendKarma(toId: string): Promise<RpcResponse> {
      return this.rpc("sendKarmaToUser", {
        targetUserId: toId,
      });
    }

    public sendMatchState(
      operationCode: number,
      data: any,
      presences?: Presence[],
    ): Promise<void> {
      if (this._match) {
        return this._socket.sendMatchState(
          this._match.match_id,
          operationCode,
          JSON.stringify(data),
          presences,
        );
      }
      this.error("Failed to send state - no connected match");

      return Promise.reject();
    }

    public storeLocalSession() {
      storeLocalSession(this._session);
    }

    public storePrivateMetadata(privateMetadata: IPrivateMetadata) {
      return this._client.writeStorageObjects(this._session, [
        {
          collection: PrivateMetadataStorageCollection,
          key: PrivateMetadataStorageKey,
          value: privateMetadata,
        },
      ]);
    }

    public async tradeAbandon(key: string): Promise<RpcResponse> {
      return this._client.rpc(this._session, "tradeAbandon", {
        key: key,
      });
    }

    public async tradeAnswer(
      key: string,
      from: string,
      answer: boolean,
    ): Promise<RpcResponse> {
      return this._client.rpc(this._session, "tradeAnswer", {
        key: key,
        from: from,
        answer: answer,
      });
    }

    public async tradeChange(
      key: string,
      items: TradeItem[],
      locked: boolean,
    ): Promise<RpcResponse> {
      return this._client.rpc(this._session, "tradeChangeItemSet", {
        key: key,
        items: items,
        locked: locked,
      });
    }

    public async tradeCommit(
      key: string,
      tx: string,
      answer: boolean,
    ): Promise<RpcResponse> {
      return this._client.rpc(this._session, "tradeCommit", {
        key: key,
        tx: tx,
        answer: answer,
      });
    }

    public async tradeGetUserTrades(): Promise<TradeObject[]> {
      const result = (
        await this._client.rpc(this._session, "tradeGetOngoing", {})
      ).payload as {
        content?: TradeObject[];
        status: boolean;
        error: string;
      };
      //@todo throw exception?
      if (!result.status) {
        return undefined;
      }
      return result.content;
    }

    public async tradePropose(to: string): Promise<RpcResponse> {
      return this._client.rpc(this._session, "tradeProposal", {
        to: to,
      });
    }

    public async tradeUserAvailableERCs(): Promise<Map<string, object>> {
      const userTokens = await this._client.rpc(
        this._session,
        "userGetWalletERC20s",
        {},
      );
      const userNFTs = await this._client.rpc(
        this._session,
        "userGetWalletNFTs",
        {},
      );
      const userData = (await this.getUsersData(this._session.user_id))[0];
      const chainName = chainIdToName(userData.metadata.chainId);
      const tokens = (userTokens.payload as any[]).filter(async (token) => {
        const queryBody = {
          contract: TradeContracts[chainName],
          chainId: chainName,
          function: "getERCAllowed",
          abi: TradeAbi,
          params: {
            erc: token.token_address,
          },
        };
        const result = await this._client.rpc(
          this._session,
          "userCallContract",
          queryBody,
        );
        if (result.payload) return token.token_address;
      });
      const nfts = ((userNFTs.payload as any).result as any[]).find(
        async (token) => {
          const queryBody = {
            contract: TradeContracts[chainName],
            chainId: chainName,
            function: "getERCAllowed",
            abi: TradeAbi,
            params: {
              erc: token.token_address,
            },
          };
          return await this._client.rpc(
            this._session,
            "userCallContract",
            queryBody,
          );
        },
      );
      const result: Map<string, object> = new Map<string, object>();
      if (tokens != undefined)
        tokens.forEach((token) => {
          result.set(token.token_address, token);
        });
      if (nfts != undefined)
        nfts.forEach((nft) => {
          if (result[nft.token_address] != undefined)
            result[nft.token_address].push(nft);
          else result[nft.token_address] = [nft];
        });
      return result;
    }

    public async writeChatMessage(channelId: string, message: any) {
      this._socket
        .writeChatMessage(channelId, message.content)
        .finally(async () => {
          if (message.content.eventType === EnumChatEvents.ChatTyping) {
            await this.rpc("userTrackChatMessage");
          }
        });
    }

    protected onChannelPresence = (presences: ChannelPresenceEvent) => {
      if (presences.leaves) {
        presences.leaves.forEach((presence) => {
          if (presence.user_id !== this._match.self.user_id) {
            this.emit(ChannelEvents.OnPresenceJoined, {
              presence,
              channelId: presences.channel_id,
            });
            this.info(
              `${presence.username} left channel ${presences.channel_id}`,
            );
          }
        });
      }
      if (presences.joins) {
        presences.joins.forEach((presence) => {
          if (presence.user_id !== this._match.self.user_id) {
            this.emit(ChannelEvents.OnPresenceLeft, {
              presence,
              channelId: presences.channel_id,
            });
            this.info(
              `${presence.username} joined channel ${presences.channel_id}`,
            );
          }
        });
      }
    };

    protected onMatchData = (result: MatchData) => {
      this.emit(MatchEvents.OnData, result);
    };

    protected onMatchPresence = (presences: MatchPresenceEvent) => {
      //HOTFIX: onMatchPresence called before match created?
      if (!this._match) {
        setTimeout(() => {
          this.onMatchPresence(presences);
        }, 3000);
        return;
      }
      // Remove players that disconnected if needed
      if (presences.leaves) {
        // First remove them from the current remote presences
        this._remotePresences = this._remotePresences.filter(
          (connectedPresence) => {
            for (let index = 0; index < presences.leaves.length; ++index) {
              if (
                presences.leaves[index].user_id === connectedPresence.user_id
              ) {
                return false;
              }
            }
            return true;
          },
        );

        // Let others know one by one
        presences.leaves.forEach((presence) => {
          this.emit(MatchEvents.OnPresenceLeft, presence);
          this.info(presence.username + " left");
        });
      }

      // Add all players that connected if needed
      if (presences.joins) {
        // Add the joins to the remote presences
        this._remotePresences = this._remotePresences.concat(presences.joins);
        // console.trace(presences, this);
        // Let others know one by one
        presences.joins.forEach((presence) => {
          // Somehow we can join our own match?
          if (presence.user_id !== this._match.self.user_id) {
            this.emit(MatchEvents.OnPresenceJoined, presence);
            this.info(presence.username + " joined");
          }
        });
      }
    };
  }
}
export default Network.Core;
