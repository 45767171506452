import SceneComponent from "@game/engine/components/SceneComponent";
import AtlasJSONFileConfig = Phaser.Types.Loader.FileTypes.AtlasJSONFileConfig;
import ImageFileConfig = Phaser.Types.Loader.FileTypes.ImageFileConfig;
import BitmapFontFileConfig = Phaser.Types.Loader.FileTypes.BitmapFontFileConfig;
import AudioFileConfig = Phaser.Types.Loader.FileTypes.AudioFileConfig;
import JSONFileConfig = Phaser.Types.Loader.FileTypes.JSONFileConfig;
import SpriteSheetFileConfig = Phaser.Types.Loader.FileTypes.SpriteSheetFileConfig;
import GameScene from "@game/engine/scenes/GameScene";

export type AssetList = {
  atlas?: AtlasJSONFileConfig[];
  audio?: AudioFileConfig[];
  bitmapFont?: BitmapFontFileConfig[];
  image?: ImageFileConfig[];
  json?: JSONFileConfig[];
  spritesheet?: SpriteSheetFileConfig[];
};

type AssetType =
  | "atlas"
  | "audio"
  | "bitmapFont"
  | "image"
  | "json"
  | "spritesheet";

class BatchLoad extends Phaser.Events.EventEmitter {
  private _complete: boolean = false;
  private _loader: Phaser.Loader.LoaderPlugin;
  private _pendingFiles: string[] = [];
  private _pendingOthers: string[] = [];

  constructor(scene) {
    super();

    this._loader = new Phaser.Loader.LoaderPlugin(scene);
    this._loader.crossOrigin = "anonymous";

    this._loader.on("loaderror", this.onFileLoadFailed, this);
    this._loader.on("filecomplete", this.onFileComplete, this);
    this._loader.on("complete", this.onComplete, this);
  }

  public addPendingOthersKey(key: string) {
    this._pendingOthers.push(key);
  }

  public get complete(): boolean {
    return this._complete;
  }

  public load(assetList: AssetList) {
    for (let type in assetList) {
      for (let i = 0; i < assetList[type].length; i++) {
        const assetConfig = assetList[type][i];
        this._pendingFiles.push(assetConfig.key);
        this._loader[type](assetConfig);
      }
    }

    if (this.numPendingFiles > 0) {
      this._loader.start();
    } else {
      this.emit("complete");
    }
  }

  public get numPendingFiles(): number {
    return this._pendingFiles.length + this._pendingOthers.length;
  }

  public onFileLoadedFromOther(key: string, type: AssetType) {
    const index = this._pendingOthers.indexOf(key);

    if (index > -1) {
      this._pendingOthers.splice(index, 1);
      this.checkComplete();
    }
  }

  private checkComplete() {
    if (this.numPendingFiles === 0) {
      this.onComplete();
    }
  }

  private onComplete() {
    this._complete = true;
    this.emit("complete", this);
  }

  private onFileComplete(key: string, type: AssetType) {
    console.log("File load complete: " + key);
    const index = this._pendingFiles.indexOf(key);

    if (index > -1) {
      this._pendingFiles.splice(index, 1);
      this.emit("filecomplete", key, type);
      this.checkComplete();
    }
  }

  private onFileLoadFailed(key: string) {
    console.log("File load failed: " + key);
    this.emit("file_failed");
  }
}

export default class DynamicLoadComponent extends SceneComponent {
  private _pendingBatches: BatchLoad[] = [];
  private _pendingFileKeys: string[] = [];

  constructor() {
    super();
  }

  public async loadAssetList(assetList: AssetList): Promise<void> {
    const batch = new BatchLoad(this._scene);
    const filteredList: AssetList = {
      atlas: [],
      audio: [],
      bitmapFont: [],
      image: [],
      json: [],
    };

    for (let type in assetList) {
      for (let i = 0; i < assetList[type].length; i++) {
        const assetConfig = assetList[type][i];
        if (!this.isAssetLoaded(type as AssetType, assetConfig.key)) {
          if (!this.isAssetLoading(assetConfig.key)) {
            filteredList[type].push(assetConfig);
            this._pendingFileKeys.push(assetConfig.key);
          } else {
            batch.addPendingOthersKey(assetConfig.key);
          }
        }
      }
    }

    batch.on("filecomplete", this.onFileComplete, this);
    batch.once("complete", this.onBatchComplete, this);

    return new Promise<void>((resolve, reject) => {
      batch.once("complete", resolve, this);
      batch.load(filteredList);
    });
  }

  public setScene(scene: GameScene) {
    // So it can be used as a non-component class
    this._scene = scene;
  }

  public unloadAssetList(assetList: AssetList) {
    for (let type in assetList) {
      assetList[type].forEach((assetConfig) => {
        this.unloadAssetByType(type as AssetType, assetConfig.key);
      });
    }
  }

  private isAssetLoaded(type: AssetType, key: string): boolean {
    if (type === "image" || type === "atlas") {
      return this._scene.textures.getTextureKeys().indexOf(key) !== -1;
    } else {
      return this._scene.cache[type].getKeys().indexOf(key) !== -1;
    }
  }

  private isAssetLoading(key: string): boolean {
    return this._pendingFileKeys.indexOf(key) !== -1;
  }

  private onBatchComplete(batch: BatchLoad) {
    const index = this._pendingBatches.indexOf(batch);

    if (index > -1) {
      this._pendingBatches.splice(index, 1);
    }
  }

  private onFileComplete(key: string, type: AssetType) {
    const index = this._pendingFileKeys.indexOf(key);

    if (index > -1) {
      this._pendingFileKeys.splice(index, 1);
    }

    this._pendingBatches.forEach((batch) => {
      batch.onFileLoadedFromOther(key, type);
    });
  }

  private unloadAssetByType(type: AssetType, key: string) {
    switch (type) {
      case "image":
      case "spritesheet":
        this.scene.textures.remove(key);
        break;
      case "atlas":
        this.scene.textures.remove(key);
        this.scene.cache.json.remove(key);
        break;
    }
  }
}
