import SceneComponent from "@game/engine/components/SceneComponent";
import { dispatchEvent, EnumUiEvents } from "@shared/Events";
import Rectangle = Phaser.GameObjects.Rectangle;
import Text = Phaser.GameObjects.Text;
import Sprite = Phaser.GameObjects.Sprite;
import GameScene from "@engine/scenes/GameScene";
import PublicSpaceGameScene from "@engine/scenes/PublicSpaceGameScene";

// Add me in the constructor of your scene or it's too late
export default class LoadingSceneComponent extends SceneComponent {
  public static EventTypes = {
    LoadingCompleted: "loadingsceneloadingcompleted",
  };
  private readonly DEPTH: number = 999;
  private _background: Rectangle;
  private _coinSprite: Sprite;
  private _text: Text;

  constructor(
    private _automaticallyHide: boolean = true,
    private _backgroundColor: number = 0x000000,
    private _textColor: number = 0xffffff,
    private _duration: number = 512,
    private _fontSize: number = 22,
  ) {
    super();
    //Emit event to disable input on window
    dispatchEvent(EnumUiEvents.UiEnableInput, false);
  }

  public hide(avoidTween: boolean = false) {
    // Nice little fade out and destroy
    //Fade-out the coin spinner only, as the background and text look weirdly static when tweening on smaller screens
    this._background.destroy(true);
    this._text.destroy(true);
    if (!avoidTween)
      this._scene.tweens.add({
        alpha: 0,
        duration: this._duration,
        onComplete: () => {
          this._scene.scale.off("resize", this.onResized);
          this.removeFromScene();
          if (this._coinSprite) {
            this._coinSprite.destroy(true);
          }
          dispatchEvent(EnumUiEvents.UiEnableInput, true);
        },

        targets: [this._background, this._text, this._coinSprite],
      });
    else {
      this._scene.scale.off("resize", this.onResized);
      this.removeFromScene();
      if (this._coinSprite) {
        this._coinSprite.destroy(true);
      }
      dispatchEvent(EnumUiEvents.UiEnableInput, true);
    }
  }

  public preload() {
    super.preload();

    // Add a background
    this._background = this._scene.add.rectangle(
      this._scene.scale.width * 0.5,
      this._scene.scale.height * 0.5,
      this._scene.scale.width,
      this._scene.scale.height,
      this._backgroundColor,
    );
    this._background.setScrollFactor(0);
    this._background.setDepth(this.DEPTH);

    this._text = this._scene.add.text(
      0,
      this._scene.scale.height * 0.5 + this._fontSize * 2,
      "0%",
      {
        align: "center",
        color: "#" + this._textColor.toString(16),
        fixedWidth: this._scene.scale.width,
        fontFamily: "Depixel",
        fontSize: this._fontSize + "px",
      },
    );
    this._text.setScrollFactor(0);
    this._text.setDepth(this.DEPTH + 1);

    if (this._scene.textures.exists("coinBrr")) {
      this.addCoin();
    } else {
      (this._scene as PublicSpaceGameScene).dynamicLoadComponent
        .loadAssetList({
          atlas: [
            {
              key: "coinBrr",
              textureURL: "/assets/game/coinBrr.png",
              atlasURL: "/assets/game/coinBrr.json",
            },
          ],
        })
        .then(() => this.addCoin());
    }

    // Hook into the loading progress
    this._scene.load.once("complete", this.onProgressCompleted);
    this._scene.load.on("progress", this.onProgressMade);

    // And also make sure we scale correctly
    this._scene.scale.on("resize", this.onResized);
  }

  public setText(text: string) {
    this._text.setText(text);
  }

  protected onProgressCompleted = () => {
    this._scene.load.off("progress", this.onProgressMade);
    if (this._automaticallyHide) {
      this.hide();
    }

    this.emit(LoadingSceneComponent.EventTypes.LoadingCompleted, this);
  };

  protected onProgressMade = (progress: number) => {
    this._text.text = (progress * 100.0).toFixed(0) + "%";
  };

  protected onResized = (gameSize) => {
    this._background.height = gameSize.height;
    this._background.width = gameSize.width;
    this._text.width = gameSize.width;
    this._text.setFixedSize(gameSize.width, gameSize.height);
    this._text.y = (gameSize.height - this._fontSize) * 0.5;

    if (this._coinSprite) {
      this._coinSprite.scale = Math.min(gameSize.height / 944, 1);
      this._coinSprite.setPosition(
        gameSize.width / 2,
        gameSize.height / 2 - 200 * this._coinSprite.scale,
      );
    }
  };

  protected override onShutdown() {
    super.onShutdown();

    this._scene.scale.off("resize", this.onResized);
    this._background.destroy(true);
    this._text.destroy(true);
    this.removeFromScene();
    if (this._coinSprite) {
      this._coinSprite.destroy(true);
    }
  }

  private addCoin() {
    this._scene.anims.create({
      key: "loading_coin_flip",
      frames: this._scene.anims.generateFrameNames("coinBrr", {
        start: 0,
        end: 9,
        zeroPad: 0,
      }),
      repeat: -1,
    });

    this._coinSprite = this._scene.add.sprite(
      this._scene.scale.width / 2,
      this._scene.scale.height / 2 - 200,
      "coinBrr",
    );
    this._coinSprite.anims.play("loading_coin_flip");
    this._coinSprite.setDepth(this.DEPTH + 1);
    this._coinSprite.setScrollFactor(0);
    this.onResized(this.scene.scale.gameSize);
  }
}
