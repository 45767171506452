export default [
  {
    address: ["0x95495dB8d8102C928a4cc29C8aab45fCaF03CF83"],
    type: "furniture",
    metadata: {
      name: "Teleporter",
      description:
        "A rare furniture item from the future, allowing hotel guests to teleport between places in their room. Its a very unique sight to behold and owned by very few hotel guests.",
      attributes: [
        { trait_type: "Rarity", value: "Epic" },
        { trait_type: "Replicas", value: "10" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "roundteleportorange_1x1x2_53",
    },
  },

  {
    address: ["0xAdD0037587e8017613F811B66425D0dE5234975d"],
    type: "furniture",
    metadata: {
      name: "Epic Floortiles",
      description: "Floor Tile",
      attributes: [
        { trait_type: "Rarity", value: "Epic" },
        { trait_type: "Replicas", value: "10" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      persistent: true,
      texture: "floor_tile_11",
    },
  },

  {
    address: ["0x7BE9333f775CE0d4A1DDe8D8778b57784a505E8a"],
    type: "furniture",
    metadata: {
      name: "Superrare Floortiles",
      description: "Floor Tile",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "80" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      persistent: true,
      texture: "floor_tile_9",
    },
  },

  {
    address: ["0x39DF754BE6c97F891E4823fBDEb3a24Cb00D95a8"],
    type: "furniture",
    metadata: {
      name: "Rare Floortiles",
      description: "Floor Tile",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "150" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      persistent: true,
      texture: "floor_tile_10",
    },
  },

  {
    address: ["0x4e4b6A441E0E930E528B0795D2f7BFEEb3655450"],
    type: "furniture",
    metadata: {
      name: "Uncommon Floortiles",
      description: "Floor Tile",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "300" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      persistent: true,
      texture: "floor_tile_7",
    },
  },

  {
    address: ["0x8Dd35d35Bc87893fBf4e1CC44b486b13DFD3CB0B"],
    type: "furniture",
    metadata: {
      name: "Common Floortiles",
      description: "Floor Tile",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "500" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      persistent: true,
      texture: "floor_tile_6",
    },
  },

  {
    address: ["0x0E9E67A62e02B001B410ED135cf1c7AcABAC303e"],
    type: "furniture",
    metadata: {
      name: "Oakwood Floortiles",
      description: "Floor Tile",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "300" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      persistent: true,
      texture: "floor_tile_4",
    },
  },

  {
    address: ["0xA7d428533621E7902C43C26a862f76cA1335036A"],
    type: "furniture",
    metadata: {
      name: "White Wood Floortiles",
      description: "Floor Tile",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "300" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      persistent: true,
      texture: "floor_tile_3",
    },
  },

  {
    address: ["0xCeF80DCfE4Aa6ae54d452aAC386Aafe0169a1af6"],
    type: "furniture",
    metadata: {
      name: "Black Wood Floortiles",
      description: "Floor Tile",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "300" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      persistent: true,
      texture: "floor_tile_2",
    },
  },

  {
    address: ["0x250Ed1e77bE7bfc48DCeBe67896252A6893d924F"],
    type: "furniture",
    metadata: {
      name: "Black Ceramic Floortiles",
      description: "Floor Tile",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "300" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      persistent: true,
      texture: "floor_tile_5",
    },
  },

  {
    address: ["0x5F6d629eC6D9495e438e76aA5231c5E83d0064Ea"],
    type: "furniture",
    metadata: {
      name: "Gray Ceramic Floortiles",
      description: "Floor Tile",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "300" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      persistent: true,
      texture: "floor_tile_0",
    },
  },

  // {
  //   address: ["PlaceholderFloorTile10"],
  //   type: "furniture",
  //   metadata: {
  //     name: "Floor Tile 0",
  //     description: "Floor Tile",
  //     attributes: [
  //       { trait_type: "Rarity", value: "Epic" },
  //       { trait_type: "Replicas", value: "10" },
  //       { trait_type: "Drop", value: "Season 2" },
  //     ],
  //     persistent: true,
  //     texture: "floor_tile_10",
  //   },
  // },

  // {
  //   address: ["PlaceholderFloorTile11"],
  //   type: "furniture",
  //   metadata: {
  //     name: "Floor Tile 0",
  //     description: "Floor Tile",
  //     attributes: [
  //       { trait_type: "Rarity", value: "Epic" },
  //       { trait_type: "Replicas", value: "10" },
  //       { trait_type: "Drop", value: "Season 2" },
  //     ],
  //     persistent: true,
  //     texture: "floor_tile_11",
  //   },
  // },
];
