import SceneComponent from "../SceneComponent";
import { TaskTracker } from "./tasks/TaskTracker";
import { IOnboardingTaskTracker } from "./tasks/OnboardingTaskTrackers";

export default class OnboardingSceneComponent extends SceneComponent {
  //onboarding closed
  private onboardingSeen = false;
  private onboardingFlow: TaskTracker[];
  private onboardingIndex: number = 0;
  private _step?: TaskTracker;

  constructor(private _taskTrackerConstructor: IOnboardingTaskTracker[]) {
    super();
  }
  onSceneSet() {
    this.onboardingFlow = this._taskTrackerConstructor.map(
      (taskTrackerConstructor) => new taskTrackerConstructor(this.scene, this),
    );
  }

  public start() {
    this.moveStep();
  }

  public moveStep() {
    this._step = this.onboardingFlow[this.onboardingIndex];
    this._step.eventEmitter.addListener(this._step.EVENT_DONE, () => {
      this.onboardingIndex++;
      if (this.onboardingIndex >= this.onboardingFlow.length) {
        this.end();
      } else {
        this.moveStep();
      }
    });
    this._step.eventEmitter.addListener(this._step.EVENT_ABANDON, () => {
      this.removeAllListeners();
      this.destroy();
    });
    this._step.start();
  }
  public end() {
    if (!this.onboardingSeen) {
      localStorage.setItem("onboarding-completed", "true");
      localStorage.setItem("onboarding-closed", "true");
    }
    this.destroy();
  }

  protected onShutdown(): void {
    if (!this._step) return;
    this._step.shutdown();
    this.destroy();
  }

  public destroy() {
    super.destroy();
  }
}
