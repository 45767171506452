import { ref } from "vue";
import { useToast } from "vue-toastification";

export const SCREEN_LOCK = ref({});

export const GATEWAY = import.meta.env.VITE_API_GATEWAY;

export const INFURA = import.meta.env.VITE_METADATA_IPFS_URL;

export const METADATA_PROXY = import.meta.env.VITE_METADATA_PROXY_URL;

export const ADS_ENABLED: boolean = JSON.parse(
  import.meta.env.VITE_ADS_ENABLED,
);

export const snooze = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const toast = useToast();
