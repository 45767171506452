export default [
  {
    address: ["0xfc928723f48dA6F69A2a3519E97437A5f610970b"],
    type: "furniture",
    metadata: {
      name: "Bitcoin Trophy",
      description:
        "Only the most elite Bit Hotel guests own this trophy, as proof of their valiant efforts in the leaderboard seasons. Extremely rare to come by, due to the low amount of replica's.",
      attributes: [
        { trait_type: "Rarity", value: "Epic" },
        { trait_type: "Replicas", value: "10" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "roundteleportorange_1x1x2_53",
    },
  },
  {
    address: ["0x1b2f93B8E53d2F28401dc2C0b83819A1669bbE30"],
    type: "furniture",
    metadata: {
      name: "Easter Egg",
      description:
        "An often hidden piece of furniture usually onl seen during the Easter holidays in Bit Hotel. A fun and colorful seasonal item that might hold some mysteries of its own...",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "40" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "eastereggpink_1x1x2_32",
    },
  },
  {
    address: ["0x22630C302f9FBe46F02684684020D9A359b245d3"],
    type: "furniture",
    metadata: {
      name: "Arcade Sofa",
      description:
        "An uniquely designed sofa that is based on the original space invaders game. A true work of art, only owned by a small selection of elite hotel guests.",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "50" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "classicarcadesofapink_2x1x1_1",
    },
  },
  {
    address: ["0x929508De33257537011e49E5a235C0F3e9992ba5"],
    type: "furniture",
    metadata: {
      name: "Gamer Bookshelf",
      description:
        "A large and super rare gamer book shelf that only very few gamers in the hotel own. It allows the user to store all kinds of cool gaming equipment and is often used as the center-piece of your gaming room.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "60" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "gamershelfpurple_2x1x4_3",
    },
  },
  {
    address: ["0x177b52e244a8d19BB6aa2eDe7141c42B0F34F37F"],
    type: "furniture",
    metadata: {
      name: "Dining Chair",
      description:
        "A stylish wooden chair with comfortable cushioning, great for your living room setup.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "80" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "diningchairwhitepurple_1x1x2_1",
    },
  },
  {
    address: ["0x1Dc7cE6b412656A3335Aab2e183Ae20351f3e063"],
    type: "furniture",
    metadata: {
      name: "Dining Table",
      description:
        "A stylish wooden dinner table that hotel guests often use to finish their room setup.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "100" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "diningtableblackwood_3x1x2_1",
    },
  },
  {
    address: ["0x1CcaF97bae49029cE0bb0c408759cF281c8f278a"],
    type: "furniture",
    metadata: {
      name: "Kitchen Sink",
      description:
        "Always wash your hands before dinner! With this cute kitchen sink you can clean the dishes after you had dinner with friends and keep your room nice and tidy!",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "300" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "kitchensinkwhiteblack_1x1x2_5",
    },
  },
];
