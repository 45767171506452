export enum EnumChatEvents {
  ChatTyping = "CHAT_TYPING",
  ChatStartTyping = "CHAT_START_TYPING",
  ChatStopTyping = "CHAT_STOP_TYPING",
}

export default interface IChatMessageEvent {
  content: {
    timestamp: number;
    message: string;
    karma: number;
    eventType: EnumChatEvents;
    textStyle: Phaser.Types.GameObjects.Text.TextStyle;
  };
  sender_id: string;
}
