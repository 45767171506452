import { MatchData } from "@heroiclabs/nakama-js";
import { ConsumableComponentOpCodes } from "@common/components/consumablecomponent/ConsumableComponentOpCodes";
import { IConsumable } from "@common/interfaces/IConsumable";
import { ExtendedNetworkedComponent } from "@engine/components/scenecomponents/v2/ExtendedNetworkedComponent";
import PublicSpaceGameScene from "@engine/scenes/PublicSpaceGameScene";
import { Communicator } from "@/shared/objects/Communicator";
import { CommunicatorEvents } from "@/shared/constants/CommunicatorEvents";

interface IOnConsumableData {
  consumable: IConsumable;
  data?: { [key: string]: any };
  userId: string;
}

export class ConsumableNetworkedComponent extends ExtendedNetworkedComponent {
  protected declare _scene: PublicSpaceGameScene;

  public override onCreated() {
    super.onCreated();

    Communicator.on(CommunicatorEvents.Emote, this.sendConsume, this);
  }

  public sendConsume(identifier: string, data?: { [key: string]: any }) {
    this.sendMatchState(ConsumableComponentOpCodes.OnConsume, {
      identifier,
      data,
    });
  }

  protected override onMatchData(matchData: MatchData) {
    switch (matchData.op_code) {
      case ConsumableComponentOpCodes.OnConsume: {
        const { userId, consumable, data } =
          this.decodeAndParseMatchData<IOnConsumableData>(matchData.data);
        this._scene.getPlayerById(userId)?.consume(consumable, data);
        break;
      }
    }
  }

  protected override onShutdown() {
    super.onShutdown();

    Communicator.off(CommunicatorEvents.Emote, this.sendConsume, this);
  }
}
