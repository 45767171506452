import SceneComponent from "@engine/components/SceneComponent";
import GameScene from "@engine/scenes/GameScene";
import Key = Phaser.Input.Keyboard.Key;
import Vector2 = Phaser.Math.Vector2;
import Gamepad = Phaser.Input.Gamepad.Gamepad;
import VirtualJoystick from "phaser3-rex-plugins/plugins/virtualjoystick";

export default class MovementInputComponent extends SceneComponent {
  public static EventTypes = {
    DirectionChanged: "direction_changed",
  };

  public get direction(): Vector2 {
    return this._direction;
  }

  private _direction: Vector2;
  private _gamepad: Gamepad;
  private _keys: { [key: string]: Key };
  private _virtualJoystick: VirtualJoystick;

  constructor() {
    super();

    this.isTickEnabled = true;

    this._direction = new Vector2();
  }

  public addVirtualJoystick(joystick: VirtualJoystick){
    this._virtualJoystick = joystick;
  }

  public override tick(deltaSeconds: number, deltaTime: number, time?: number) {
    const newDirection = new Vector2();

    this.getDirectionFromKeyboard(newDirection);

    if (newDirection.equals(Vector2.ZERO) && this._gamepad) {
      this.getDirectionFromGamepadArrows(newDirection);
    }

    if (newDirection.equals(Vector2.ZERO) && this._gamepad) {
      this.getDirectionFromGamepadJoystick(newDirection);
    }

    if(newDirection.equals(Vector2.ZERO) && this._virtualJoystick) {
      this.getDirectionFromVirtualJoystick(newDirection);
    }

    if (!this._direction.equals(newDirection)) {
      this._direction = newDirection;
      this.emit(
        MovementInputComponent.EventTypes.DirectionChanged,
        new Vector2(this._direction),
      );
    }
  }

  protected override onSceneSet(scene: GameScene) {
    this._keys = scene.input.keyboard.addKeys(
      "LEFT, RIGHT, UP, DOWN, W, A, S, D",
      false,
    ) as {
      [key: string]: Key;
    };
    this._gamepad = scene.input.gamepad.getPad(0);

    if (!this._gamepad) {
      scene.input.gamepad.once(
        "connected",
        (gamepad) => {
          this._gamepad = gamepad;
        },
        this,
      );
    }
  }

  protected onShutdown() {
    this.scene.input.keyboard.removeAllKeys(true);
    super.onShutdown();
  }

  private getDirectionFromGamepadArrows(output: Vector2) {
    output.reset();

    if (this._gamepad.up) {
      output.y -= 1;
    }
    if (this._gamepad.down) {
      output.y += 1;
    }
    if (this._gamepad.left) {
      output.x -= 1;
    }
    if (this._gamepad.right) {
      output.x += 1;
    }
  }

  private getDirectionFromGamepadJoystick(output: Vector2) {
    output.copy(this._gamepad.leftStick);
  }

  private getDirectionFromKeyboard(output: Vector2) {
    output.reset();

    if (this._keys.UP.isDown || this._keys.W.isDown) {
      output.y -= 1;
    }
    if (this._keys.DOWN.isDown || this._keys.S.isDown) {
      output.y += 1;
    }
    if (this._keys.LEFT.isDown || this._keys.A.isDown) {
      output.x -= 1;
    }
    if (this._keys.RIGHT.isDown || this._keys.D.isDown) {
      output.x += 1;
    }
  }

  private getDirectionFromVirtualJoystick(output: Vector2) {
    output.reset();

    if (this._virtualJoystick.up) {
      output.y -= 1;
    }
    if (this._virtualJoystick.down) {
      output.y += 1;
    }
    if (this._virtualJoystick.left) {
      output.x -= 1;
    }
    if (this._virtualJoystick.right) {
      output.x += 1;
    }
  }
}
