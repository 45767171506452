import { provider, signer } from "./wallet";
import {
  BthAbi,
  BthAbi__factory,
  Erc721Abi,
  Erc721Abi__factory,
  MarketplaceAbi,
  MarketplaceAbi__factory,
  PaymentreceiverAbi,
  PaymentreceiverAbi__factory,
  PresaleAbi,
  PresaleAbi__factory,
  TradinglibraryAbi,
  TradingAbi,
  TradinglibraryAbi__factory,
  TradingAbi__factory,
} from "./typechain";
import { EChainId } from "@shared/types";
import { Chain } from "@shared/consts/SupportedChains";
import contractAddresses from "./contractAddresses";

export function getMarketplaceContract(
  contractAddress: string,
  withSigner = true,
): MarketplaceAbi {
  const contract = MarketplaceAbi__factory.connect(contractAddress, provider);
  if (withSigner) {
    return contract.connect(signer);
  } else {
    return contract;
  }
}

export function getBthContract(
  contractAddress: string,
  withSigner = true,
): BthAbi {
  const contract = BthAbi__factory.connect(contractAddress, provider);
  if (withSigner) {
    return contract.connect(signer);
  } else {
    return contract;
  }
}

export function getErc721Contract(
  contractAddress: string,
  withSigner = true,
): Erc721Abi {
  const contract = Erc721Abi__factory.connect(contractAddress, provider);
  if (withSigner) {
    return contract.connect(signer);
  } else {
    return contract;
  }
}

export function getPaymentReceiverContract(
  contractAddress: string,
  withSigner = true,
): PaymentreceiverAbi {
  const contract = PaymentreceiverAbi__factory.connect(
    contractAddress,
    provider,
  );
  if (withSigner) {
    return contract.connect(signer);
  } else {
    return contract;
  }
}

export function getPresaleContract(
  contractAddress: string,
  withSigner = true,
): PresaleAbi {
  const contract = PresaleAbi__factory.connect(contractAddress, provider);
  if (withSigner) {
    return contract.connect(signer);
  } else {
    return contract;
  }
}

export function getTradeContract(
  contractAddress: string,
  withSigner = true,
): TradingAbi {
  const contract = TradingAbi__factory.connect(contractAddress, provider);
  if (withSigner) {
    return contract.connect(signer);
  } else {
    return contract;
  }
}

export function getTradeLibraryContract(
  contractAddress: string,
  withSigner = true,
): TradinglibraryAbi {
  const contract = TradinglibraryAbi__factory.connect(
    contractAddress,
    provider,
  );
  if (withSigner) {
    return contract.connect(signer);
  } else {
    return contract;
  }
}

export function getTokenAddress(chainId: EChainId): string {
  const chain = new Chain(chainId);
  return contractAddresses[chain.chain_id]?.tokenContract;
}
