import SceneComponent from "@game/engine/components/SceneComponent";
import GameScene from "@game/engine/scenes/GameScene";
import NotificationCenter, {
  NotificationCenterUpdateTypes,
} from "@game/engine/networking/NotificationCenter";
import { Notification } from "@heroiclabs/nakama-js";
import NotificationBanner from "@game/engine/gameobjects/NotificationBanner";
import OverlayScene, {
  OverlayDepthLayer,
  ScreenAnchorPosition,
} from "@engine/scenes/OverlayScene";

interface ISystemNotificationData {
  message: string;
  subject: string;
}

export default class SystemNotificationComponent extends SceneComponent {
  private _bannerContainer: NotificationBanner;
  private _notificationBuffer: ISystemNotificationData[];

  constructor() {
    super();

    this._notificationBuffer = [];
  }

  protected onSceneSet(scene?: GameScene) {
    super.onSceneSet(scene);

    NotificationCenter.instance.on(
      NotificationCenterUpdateTypes.ReceivedSystemMessage,
      this.onReceivedSystemMessage,
    );
  }

  protected onShutdown() {
    super.onShutdown();

    NotificationCenter.instance.off(
      NotificationCenterUpdateTypes.ReceivedSystemMessage,
      this.onReceivedSystemMessage,
    );
  }

  private displaySystemMessage(
    subject: string,
    message: string,
    repeatAmount: number = 1,
  ) {
    const overlayScene = this.scene.scene.get("OverlayScene") as OverlayScene;
    // If the banner exists it means it's still going, so we're going to buffer the notification for later
    if (this._bannerContainer) {
      this._notificationBuffer.push({ subject, message });
      return;
    }

    // Show a neat little banner
    this._bannerContainer = new NotificationBanner(
      overlayScene,
      overlayScene.scale.width * 0.5,
      overlayScene.scale.height * 0.5,
    );
    overlayScene.addAnchoredObject(
      this._bannerContainer,
      {
        x: { anchor: ScreenAnchorPosition.Center, value: 0 },
        y: { anchor: ScreenAnchorPosition.Center, value: 0 },
      },
      OverlayDepthLayer.Top,
    );

    this._bannerContainer.display(subject, message, repeatAmount);

    // When it's done, check if there are more messages to display
    this._bannerContainer.on(NotificationBanner.EventTypes.Completed, () => {
      this._bannerContainer = null;
      if (this._notificationBuffer.length > 0) {
        const { subject, message } = this._notificationBuffer.shift();
        this.displaySystemMessage(subject, message);
      }
    });
  }

  private onReceivedSystemMessage = (
    notification: Notification,
    repeatAmount: number = 1,
  ) => {
    if (this.isAttachedToScene) {
      this.displaySystemMessage(
        notification.subject,
        notification.content["message"],
        repeatAmount,
      );
    }
  };
}
