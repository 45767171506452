export default [
  {
    address: ["0xDEF"],
    // token_id: 1,
    type: "character",
    metadata: {
      name: "Default Character",
      description: "Bithotel guest",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "1" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },

  {
    address: ["0xDEFFemaleA"],
    type: "character",
    metadata: {
      name: "Default Female A",
      description: "Bithotel guest",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "1" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },
  {
    address: ["0xDEFMaleA"],
    type: "character",
    metadata: {
      name: "Default Male A",
      description: "Bithotel guest",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "1" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },
  {
    address: ["0xDEFFemaleB"],
    type: "character",
    metadata: {
      name: "Default Female B",
      description: "Bithotel guest",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "1" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },
  {
    address: ["0xDEFMaleB"],
    type: "character",
    metadata: {
      name: "Default Male B",
      description: "Bithotel guest",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "1" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },
  {
    address: ["0xDEFFemaleC"],
    type: "character",
    metadata: {
      name: "Default Female C",
      description: "Bithotel guest",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "1" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },
  {
    address: ["0xDEFMaleC"],
    type: "character",
    metadata: {
      name: "Default Male C",
      description: "Bithotel guest",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "1" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },
  {
    address: ["0xA4011E3C0D80E152721E477AE387338E6a6C1a98"],
    type: "character",
    metadata: {
      name: "Janitor Jim",
      description:
        "One of the oldest occupants of the Bit Hotel, been there since day one. Knows the management by name and his way through the secret corridors of the hotel. A gray but wise man.",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "500" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },

  {
    address: ["0x851Fea1272Bf2C47c6b2253B17fb83A40Acca92e"],
    type: "character",
    metadata: {
      name: "Piccolo Bimbo",
      description:
        "The know-it-all picollo that helps all guests to their rooms. Don't let appearances be deceiving, a sharp young kid. Has a keen ear for gossip and knows things before they happen...",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "400" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },

  {
    address: ["0x9F07ca343dee11519522b84Dc97E6271163dC465"],
    type: "character",
    metadata: {
      name: "Lady McBitmain",
      description:
        "The main supplier of hardware on which the Bit Hotel runs, a true monopolist. She loves to spend time at the fanciest restaurants and bars as well as cuddle her dog.",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "300" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },

  {
    address: ["0xc03358513051dece7441B9c358CAEfdfeE25CDb4"],
    type: "character",
    metadata: {
      name: "Wally Bets",
      description:
        "The pinnacle of degenerates, a true mascot for the short squeeze movement and thereby flamboyantly against short-sellers. Apes into projects but never sells, hodl's his bags.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "200" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },

  {
    address: ["0x30A96e1313d7eaAFac2F29E18128F8FF1695e384"],
    type: "character",
    metadata: {
      name: "When Lamboy",
      description:
        "A big fan of high-end luxury brands and fast cars. Pump and dumped his way to the top where he now resides. Has a vast network of connections in the Bit Hotel and flexes his bling whenever he can.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "150" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },

  {
    address: ["0xE27CbD36F6F4138a42071341AD00aA7f5cb2a73c"],
    type: "character",
    metadata: {
      name: "Moon Moon",
      description:
        "The most ambitious dog the hotel has seen in a while. Moon Moon is an astronaut by profession and owns the title of the first dog in space. Fully capable of conversation and always aims for the moon",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "100" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },

  {
    address: ["0xb95f3d56a3d5E44202DC06647E196460E5E3E644"],
    type: "character",
    metadata: {
      name: "Mr. Anonymous",
      description:
        "Hacks into hotel security systems for sport to spy on the ladies locker room. Believes in decentralization and shared governance of the hotel amenities. Uses his hotel influence from the shadows.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "60" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },

  {
    address: ["0x9c6f6c6dBb9919aE976ADE3697f229CEcF79c3A5"],
    type: "character",
    metadata: {
      name: "Shibasketballer",
      description:
        "The most feared yet animal-loving sportsman of the Bit Hotel. Has been breezing through competitions on his way to the top of the leaderboards and shows no signs of losing.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "50" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },

  {
    address: ["0x3de112038A25c5F29217513C3aAff5F1847e6c9f"],
    type: "character",
    metadata: {
      name: "Ms. Kitty",
      description:
        "The most adorable character walking the Bit Hotel universe. Had an early win with her cryptokitty designs and now lives in the suites of the hotel. Has never been seen without her onesie.",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "40" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },

  {
    address: ["0x2bEAc18cAabb176C2488F16ee5601ae18bD71144"],
    type: "character",
    metadata: {
      name: "Elon Dusk",
      description:
        "One of the most brilliant and forward thinking hotel guests. Works at least 100 hours per week and does not care what the world thinks. Uses the Bit Hotel rocket launchpad for his commute to work.",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "30" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },

  {
    address: ["0x1336f49D554A456AdD6bf9Ef2cd8c16Cfc976Ecc"],
    type: "character",
    metadata: {
      name: "Mr. Diamondhands",
      description:
        "The guy that only uses buy-orders and doesn't lose a sweat. Truly has the most rock-steady attitude of all the hotel guests as he likes to short-squeeze his competitors for sport.",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "20" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },

  {
    address: ["0x855eC76e3d9305fE449eD0f116BAa4Cfff8D2bd3"],
    type: "character",
    metadata: {
      name: "Crypto Punkhead",
      description:
        "Traveled back in time from the future to join the Bit Hotel metaverse. One of the first to join the NFT space, a feisty character that sets the trends inside of Bit Hotel and helps govern the hotel to make sure all are having a good time.",
      attributes: [
        { trait_type: "Rarity", value: "Epic" },
        { trait_type: "Replicas", value: "10" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },

  {
    address: ["0xd40E468F8860c40577F9F8886bb5cCdB962f75Cd"],
    type: "character",
    metadata: {
      name: "The Dogefather",
      description:
        "A doge that makes offers people can't refuse. Controls parts of the Bit Hotel through his pawns. The dogefather doesn't get sniffed on. He does the sniffing. Truly a character you don't want to run into at night. Will have a significant influence within the Bit Hotel governance model.",
      attributes: [
        { trait_type: "Rarity", value: "Epic" },
        { trait_type: "Replicas", value: "5" },
        { trait_type: "Drop", value: "Season 1" },
      ],
    },
  },
];
