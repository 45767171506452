import { EChainId } from "@shared/types";
import { CasperNFT, currentChainId, EVMNFT } from "..";
import { SignerOrProvider } from "../evm";
import { signer, web3AuthInstance } from "../wallet";

export * from "./evm";
export * from "./casper";

export interface BaseNFT {
  readonly address: string;

  transfer(tokenId: number, recipient: string): Promise<string>;

  getApproved(tokenId: number): Promise<string>;

  approve(tokenId: number, spender: string): Promise<string>;
}

export async function getNFT(nft: string): Promise<BaseNFT> {
  if (currentChainId.value == EChainId.CSPR) {
    let privateKey: string | undefined;
    if (web3AuthInstance && web3AuthInstance.status() == "connected") {
      privateKey = (await web3AuthInstance.getPrivateKey()).toString();
    }
    const contractHash = await contractPackageHashToContractHash(nft);
    return new CasperNFT(contractHash, privateKey);
  } else {
    const signerOrProvider = new SignerOrProvider(signer);
    return new EVMNFT(nft, signerOrProvider);
  }
}

async function contractPackageHashToContractHash(
  contractPackageHash: string,
): Promise<string> {
  if (!import.meta.env.VITE_CSPR_API) {
    throw new Error(`Casper API not defined`);
  }
  const baseUrl = import.meta.env.VITE_CSPR_API;
  const res = await (
    await fetch(
      `${baseUrl}/contracts?contract_package_hash=${contractPackageHash}`,
    )
  ).json();
  if (res.itemCount == 1) {
    const item = res.data[0];
    return item.contract_hash;
  }
  return contractPackageHash;
}
