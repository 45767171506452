import { Toast } from "@engine/notifications/Toast";
import isMobile from "@shared/IsMobile";
import { Chain } from "@shared/consts/SupportedChains";
import { TChain } from "@shared/types";
import {
  configureChains,
  createConfig,
  getAccount,
  getPublicClient,
  getWalletClient,
  disconnect,
} from "@wagmi/core";
import {
  arbitrum,
  avalanche,
  bsc,
  fantom,
  mainnet,
  moonbeam,
  moonriver,
  optimism,
  polygon,
} from "@wagmi/core/chains";

const supportedChains = [
  arbitrum,
  avalanche,
  bsc,
  fantom,
  mainnet,
  moonbeam,
  moonriver,
  optimism,
  polygon,
];

import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/html";
import { ethers } from "ethers";
import EventEmitter from "eventemitter3";

enum modalEvents {
  interruptConnect = "Interrupt_Connect",
  interruptSelectNetwork = "Interrupt_SelectNetwork",
}

export class Web3ModalV2 {
  private modal: Web3Modal | undefined;
  private connectUnsubscribe: undefined | (() => void);
  private interruptableEmitters: Map<string, EventEmitter> = new Map<
    string,
    EventEmitter
  >();

  private static wagmiConfig: any | undefined = undefined;
  private static readonly projectId = "20361e73c47df4218b0a0825a6ec99f3";

  private static setConfig() {
    if (!this.projectId) {
      throw new Error("You need to provide VITE_PROJECT_ID env variable");
    }

    const { publicClient } = configureChains(supportedChains, [
      w3mProvider({ projectId: this.projectId }),
    ]);
    this.wagmiConfig = createConfig({
      autoConnect: true,
      connectors: w3mConnectors({
        chains: supportedChains,
        projectId: this.projectId,
      }),
      publicClient,
    });
  }

  static attemptReconnect(
    walletAddress: string,
    chain: Chain,
    cb?: () => void | Promise<void>,
  ) {
    if (!this.wagmiConfig) this.setConfig();
    new Promise(() => {
      let i = 0;
      const attempt = () =>
        setTimeout(async () => {
          if (i >= 15) return;
          if (getAccount().isConnected) {
            const wallet = await getWalletClient();
            if (
              wallet?.account.address.toLocaleLowerCase() ===
                walletAddress.toLocaleLowerCase() &&
              (await wallet.getChainId()) === chain.chain_id
            ) {
              if (cb) cb();
            }
            return;
          } else {
            i++;
            attempt();
          }
        }, 3000);
      attempt();
    });
    return;
  }

  static async init(): Promise<Web3ModalV2> {
    if (!this.wagmiConfig) this.setConfig();

    const ethereumClient = new EthereumClient(
      this.wagmiConfig,
      supportedChains,
    );

    const web3modal = new Web3ModalV2();
    web3modal.modal = new Web3Modal(
      {
        projectId: this.projectId,
        // walletImages: {
        //   safe: "https://pbs.twimg.com/profile_images/1566773491764023297/IvmCdGnM_400x400.jpg",
        // },
      },
      ethereumClient,
    );
    return web3modal;
  }

  subscribe(
    cb: (event: {
      data: any;
      name: string;
      timestamp: number;
      type: string;
      userSessionId: string;
    }) => void,
  ): () => void {
    return this.modal.subscribeEvents(cb);
  }

  async connect(connectCb?: () => void) {
    if (!isMobile()) {
      if (connectCb) {
        if (this.connectUnsubscribe) this.connectUnsubscribe();
        this.connectUnsubscribe = this.subscribe(
          (data: {
            data: any;
            name: string;
            timestamp: number;
            type: string;
            userSessionId: string;
          }) => {
            if (data.type === "TRACK" && data.name === "ACCOUNT_CONNECTED") {
              connectCb();
            }
          },
        );
      }
      //https://docs.walletconnect.com/2.0/web/web3modal/html/wagmi/actions
      if (getAccount().status === "connected") await disconnect();
      await this.modal.openModal({ route: "ConnectWallet" });
      return;
    } else {
      if (!this.interruptableEmitters.get(modalEvents.interruptConnect)) {
        this.interruptableEmitters.set(
          modalEvents.interruptConnect,
          new EventEmitter(),
        );
      }
      this.interruptableEmitters
        .get(modalEvents.interruptConnect)
        ?.emit(modalEvents.interruptConnect);

      new Promise(async (resolve) => {
        let allowCallback = true;
        this.interruptableEmitters
          .get(modalEvents.interruptConnect)
          ?.once(modalEvents.interruptConnect, () => {
            allowCallback = false;
          });

        if (getAccount().status === "connected") await disconnect();
        await this.modal.openModal({ route: "ConnectWallet" });

        let tries = 0;
        const delay = async () => {
          if ((await getWalletClient()) === null) {
            setTimeout(() => {
              delay();
            }, 1000);
            tries++;
            if (tries >= 15) resolve(false);
          } else {
            if (connectCb && allowCallback) connectCb();
            resolve(true);
          }
        };
        delay();
      });
      return;
    }
  }

  async changeNetwork(chain: TChain): Promise<boolean> {
    if (!isMobile()) {
      return false;
    } else {
      if ((await getWalletClient()) === null) {
        Toast.error("Connect a wallet first");
        return false;
      }
      if (!this.interruptableEmitters.get(modalEvents.interruptSelectNetwork)) {
        this.interruptableEmitters.set(
          modalEvents.interruptSelectNetwork,
          new EventEmitter(),
        );
      }
      this.interruptableEmitters
        .get(modalEvents.interruptSelectNetwork)
        ?.emit(modalEvents.interruptSelectNetwork);
      return new Promise(async (resolve) => {
        try {
          await this.modal.openModal({ route: "SelectNetwork" });
        } catch (e) {
          return resolve(false);
        }
        this.interruptableEmitters
          .get(modalEvents.interruptSelectNetwork)
          ?.once(modalEvents.interruptConnect, () => {
            return resolve(false);
          });

        let tries = 0;
        const delay = async () => {
          if ((await getPublicClient().getChainId()) !== chain.chain_id) {
            setTimeout(() => {
              delay();
            }, 1000);
            tries++;
            if (tries >= 40) return resolve(false);
          } else {
            return resolve(true);
          }
        };
        delay();
      });
    }
  }

  //https://wagmi.sh/core/ethers-adapters
  async toEthers(): Promise<{
    signer: ethers.Signer;
    provider: ethers.providers.Web3Provider;
  }> {
    const wallet = await getWalletClient({
      chainId: getPublicClient().chain.id,
    });
    if (!wallet) return { provider: undefined, signer: undefined };
    const provider = new ethers.providers.Web3Provider(wallet.transport, "any");
    return {
      provider: provider,
      signer: provider.getSigner(),
    };
  }
}
