import { MatchData, Presence } from "@heroiclabs/nakama-js";
import SceneComponent from "@game/engine/components/SceneComponent";
import Network, { MatchEvents } from "@game/engine/networking/Network";
import NetworkLike, { UserJoinedEvent, UserLeftEvent } from "./NetworkLike";
import EventEmitter from "eventemitter3";
import { TNakamaUser } from "@shared/types/nakama/NakamaTypes";
import { EnumGameEvents } from "@shared/enums";
import { dispatchEvent } from "@shared/Events";

const OperationCodes = {
  Any: 100,
};

interface GameFindMatchPayload {
  ids?: string[];
}

interface RoomFindMatchPayload {
  matchId?: string;
}

export default class NetworkedComponent
  extends SceneComponent
  implements NetworkLike
{
  public static EventTypes = {
    MatchJoined: "matchjoined",
    UserJoined: "userJoined",
    UserLeft: "userLeft",
  };
  protected _decoder: TextDecoder;
  private _matchEventsEmitter = new EventEmitter<"userJoined" | "userLeft">();
  private _messagesEmitter = new EventEmitter();

  protected _hasJoinedMatch: boolean = false;

  public get hasJoinedMatch() {
    return this._hasJoinedMatch;
  }

  public get currentUserId(): string {
    return Network.currentUser.id;
  }

  constructor(private _module: string) {
    super();

    this._decoder = new TextDecoder();
  }

  /**
   * @deprecated Auth is happening outside of phaser, we only need to connect&join
   */
  async authenticateAndJoin(email: string, password: string) {
    try {
      await Network.authenticateEmail(email, password);
      await Network.connect();
      await this.join();
      await Network.joinChat("lobby");
    } catch (error) {
      console.error(error);
    }
  }

  public getMatchEventsEmitter(): EventEmitter<"userJoined" | "userLeft"> {
    return this._matchEventsEmitter;
  }

  public getMessagesEmitter(): EventEmitter {
    return this._messagesEmitter;
  }

  public async getUsersData(
    userIds: string | Array<string>,
  ): Promise<Array<TNakamaUser>> {
    return (await Network.getUsersData(userIds)) as Array<TNakamaUser>;
  }

  public async initialize() {
    //TODO: should be a better way to do it
    if (Network.isAuthenticated && Network.currentMatchId) {
      await Network.connect();
      await Network.joinChat(this._module + Network.currentMatchId);
      Network.initListeners();
    } else {
      setTimeout(async () => await this.initialize(), 1000);
    }
  }

  public async join(randomMatch: boolean = false): Promise<void> {
    try {
      // const response = await Network.rpc("FindMatch", {
      //   module: this._module === "lobby" ? "publicspace" : this._module,
      //   data: {
      //     space: "lobby",
      //   },
      //   // currency: "sbth",
      //   // playableAmount: 1000,
      // });
      const response = await Network.rpc("gameFindMatch", {
        module: this._module,
      });
      if (response && "payload" in response) {
        const payload: GameFindMatchPayload = response.payload;
        if (payload && "ids" in payload) {
          const ids = payload.ids;
          if (ids.length > 0) {
            // See if we can join a random match, or just join the first one available
            if (randomMatch && ids.length > 1) {
              await Network.core.joinMatch(
                ids[Phaser.Math.Between(0, ids.length - 1)],
              );
            } else {
              await Network.core.joinMatch(ids[0]);
            }

            // If we got here, we did join a match
            this._hasJoinedMatch = true;
          }
        } else {
          console.error("No ids in payload in response");
        }
      } else {
        console.error("No payload in response");
      }
    } catch (e) {
      this._hasJoinedMatch = false;
      console.error(e);

      if (e.message === "already joined") {
        dispatchEvent(EnumGameEvents.GameUiAlreadyJoinedError);
      }
      //TODO: should we wipe the socket connection if client failed to join match?
      Network.core._socket.disconnect(true);
      throw new Error("Failed to join match");
    }
  }

  public async joinRoom(roomId: string): Promise<void> {
    try {
      const response = await Network.rpc("roomFindMatch", {
        module: "room",
        roomId: roomId,
      });

      if (response && "payload" in response) {
        const payload: RoomFindMatchPayload = response.payload;

        if (payload && "matchId" in payload) {
          await Network.core.joinMatch(payload.matchId);

          this._hasJoinedMatch = true;
        } else {
          console.log("No matchId in roomFindMatch response");
        }
      } else {
        console.log("No payload in roomFindMatch response");
      }
    } catch (e) {
      this._hasJoinedMatch = false;
      console.error(e);

      Network.core._socket.disconnect(true);
      throw new Error("Failed to join room's match");
    }
  }

  public async send(
    eventName: string,
    data: Record<string, unknown>,
  ): Promise<void> {
    // Sanity check
    if (!this._hasJoinedMatch) {
      // console.warn();
      // return Promise.reject();
      throw new Error("Attempt to send a message before match join");
    }

    const msg = { eventName, data };
    await Network.core.sendMatchState(OperationCodes.Any, msg);
  }

  public sendChatMessage(message: string): void {
    throw new Error("Method not implemented.");
  }

  protected onSceneSet() {
    super.onSceneSet();

    Network.core.on(MatchEvents.OnData, this.onMatchData, this);
    Network.core.on(
      MatchEvents.OnPresenceJoined,
      this.onMatchPresenceJoined,
      this,
    );
    Network.core.on(MatchEvents.OnPresenceLeft, this.onMatchPresenceLeft, this);
    Network.getCurrentSessionAccount();
  }

  protected onShutdown() {
    super.onShutdown();

    Network.core.off(MatchEvents.OnData, this.onMatchData, this);
    Network.core.off(
      MatchEvents.OnPresenceJoined,
      this.onMatchPresenceJoined,
      this,
    );
    Network.core.off(
      MatchEvents.OnPresenceLeft,
      this.onMatchPresenceLeft,
      this,
    );
  }

  private decodeMatchData<T>(data: Uint8Array): T {
    return <T>JSON.parse(this._decoder.decode(data));
  }

  private onMatchData(matchData: MatchData) {
    if (matchData.op_code === OperationCodes.Any) {
      const result = this.decodeMatchData<{ eventName: string; data: unknown }>(
        matchData.data,
      );
      this._messagesEmitter.emit(result.eventName, result.data);
    }
  }

  private onMatchPresenceJoined(presence: Presence) {
    const userJoined: UserJoinedEvent = {
      userId: presence.user_id,
      userName: presence.username,
    };
    this._matchEventsEmitter.emit("userJoined", userJoined);
  }

  private onMatchPresenceLeft(presence: Presence) {
    const userLeft: UserLeftEvent = {
      userId: presence.user_id,
    };
    this._matchEventsEmitter.emit("userLeft", userLeft);
  }
}
