import { getTiledProperty } from "@game/engine/utilities/TiledHelper";

export enum TilemapLayerTypes {
  Collidable = "collidable",
  Obstacle = "obstacle",
  Orderable = "orderable",
}

interface LayerDataProperty {
  name?: string;
  type?: string;
  value?: any;
}

export class TilemapLayer {
  public get id() {
    return this.original.id;
  }

  public name: string;
  public properties: LayerDataProperty[];
  public visible: boolean;
  public x: number;
  public y: number;

  constructor(public original: any) {
    this.name = original.name;
    this.properties = original.properties;
    this.visible = original.visible;
    this.x = original.x;
    this.y = original.y;
  }

  public getProperty(name: string, fallback = null): any {
    const propertyValue = getTiledProperty(this, name);
    if (null === propertyValue) {
      return fallback;
    }
    return propertyValue;
  }
}

export class TilemapImageLayer extends TilemapLayer {
  public image: string;

  constructor(original: any) {
    super(original);

    this.image = original.image;
  }
}

export class TilemapTileLayer extends TilemapLayer {}
