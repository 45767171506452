import GameScene from "@engine/scenes/GameScene";
import EventEmitter = Phaser.Events.EventEmitter;

export interface ITask {
  image: string[];
  title: string;
  content: string;
  buttonNext: string;
  buttonNextAction: Function;
  startAction: Function;
  buttonPrevious: string;
  buttonPreviousAction: Function;
  buttonAbandonAction: Function;
  stopAction: Function;
  events: {
    previousEventName: string;
    nextEventName: string;
    abandonEventName: string;
  };
  css: { [key: string]: any };
}

export abstract class Task implements ITask {
  public title: string;
  public content: string;
  public image: string[];
  public buttonNext: string;
  public buttonPrevious: string;
  public events: {
    nextEventName: string;
    previousEventName: string;
    abandonEventName: string;
  };
  public css: {};
  protected _eventEmitter: EventEmitter;
  protected _scene: GameScene;
  protected _textSpeed: number;

  constructor(
    eventEmitter: EventEmitter,
    gameScene: GameScene,
    textSpeed: number,
  ) {
    this._eventEmitter = eventEmitter;
    this._scene = gameScene;
    this._textSpeed = textSpeed;
  }

  public buttonNextAction: () => void = () => {
    this._eventEmitter.emit(this.events.nextEventName, {});
  };

  public buttonPreviousAction: () => void = () => {
    this._eventEmitter.emit(this.events.previousEventName, {});
  };

  public buttonAbandonAction: () => void = () => {
    this._eventEmitter.emit(this.events.abandonEventName, {});
  };

  public startAction: () => void;

  public stopAction: () => void = () => {};
}
