export enum EChainId {
  "CSPR",
  "BSC",
  "GLMR",
}

export enum EChainType {
  "EVM",
  "CASPER",
}

export enum ENetworkType {
  "TESTNET",
  "MAINNET",
}

export type TChain = {
  id: EChainId;
  type: EChainType;
  name: string;
  network: ENetworkType;
  chain_id?: number;
  explorer_url: string;
  rpc_url: string;
  marketplace_currency: string;
  native_currency: TChaincurrency;
};

export type TChaincurrency = {
  symbol: string;
  name: string;
  decimals: number;
};

export type TInventoryTile = {
  amount?: number;
  icon?: string;
  id: string;
  name: string;
  rarity: string;
  tokenId?: number;
  address?: string;
  forSale?: boolean;
  listingId?: number;

  //for cache
  expires?: number;
};

export type TCarouselItem = {
  imageUrl: string;
  interaction?: {
    type: string;
    data: any;
  };
  duration?: number;
  transition?: {
    direction: "up" | "down" | "left" | "right";
    duration: number;
  };
};
