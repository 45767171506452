import {
  TilePosition3D,
  TypedGraph,
  TypedNode,
} from "@game/engine/navigation/Pathfinder";
import PathfindingAlgorithm from "@game/engine/navigation/pathfindingalgorithms/PathfindingAlgorithm";

export default class PathfindingAlgorithmBee extends PathfindingAlgorithm {
  // This algorithms ignores obstacles completely, just so you know
  public find(
    startNode: TypedNode,
    goalNode: TypedNode,
    graph: TypedGraph,
    obstacles?: TypedGraph,
  ): TypedNode[] {
    const amountOfSteps = PathfindingAlgorithmBee.minimumAmountOfSteps(
      startNode.position,
      goalNode.position,
    );

    // Using a set so duplicates are automagically filtered out
    const line = new Set<TypedNode>();

    let interpolant = 0;
    for (let step = 0; step < amountOfSteps; ++step) {
      if (0 === amountOfSteps) {
        interpolant = 0;
      } else {
        interpolant = step / amountOfSteps;
      }

      // Add a position as a rounded location so it's unique
      const interpolatedPosition = PathfindingAlgorithmBee.roundPosition(
        PathfindingAlgorithmBee.lerpPosition(startNode, goalNode, interpolant),
      );
      const node: TypedNode = graph.get(
        interpolatedPosition.x,
        interpolatedPosition.y,
        interpolatedPosition.z,
      );
      if (node) {
        line.add(node);
      }
    }

    // Add the final point as well
    line.add(goalNode);

    return Array.from(line);
  }

  private static lerp(start: number, end: number, interpolant: number): number {
    return start + interpolant * (end - start);
  }

  private static lerpPosition(
    positionA: TilePosition3D,
    positionB: TilePosition3D,
    interpolant: number,
  ): TilePosition3D {
    return {
      x: PathfindingAlgorithmBee.lerp(positionA.x, positionB.x, interpolant),
      y: PathfindingAlgorithmBee.lerp(positionA.y, positionB.y, interpolant),
      z: PathfindingAlgorithmBee.lerp(positionA.z, positionB.z, interpolant),
    };
  }

  private static minimumAmountOfSteps(
    positionA: TilePosition3D,
    positionB: TilePosition3D,
  ): number {
    return Math.max(
      Math.abs(positionA.x - positionB.x),
      Math.abs(positionA.y - positionB.y),
      Math.abs(positionA.z - positionB.z),
    );
  }

  private static roundPosition(position: TilePosition3D): TilePosition3D {
    return {
      x: Math.round(position.x),
      y: Math.round(position.y),
      z: Math.round(position.z),
    };
  }
}
