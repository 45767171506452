import { CLByteArray, CLKey, CLU64, RuntimeArgs } from "casper-js-sdk";
import { BaseNFT } from ".";
import { BaseCasper } from "../base/casper";

export class CasperNFT extends BaseCasper implements BaseNFT {
  constructor(nft: string, privateKey?: string) {
    if (!nft.startsWith("hash-")) {
      nft = `hash-${nft}`;
    }
    super(nft, privateKey);
  }

  async transfer(tokenId: number, recipient: string): Promise<any> {
    throw new Error(`Not implemented`);
  }

  async getApproved(tokenId: number): Promise<string> {
    throw new Error(`Not implemented`);
  }

  async approve(tokenId: number, spender: string): Promise<string> {
    if (spender.includes("-")) {
      spender = spender.split("-")[1];
    }
    const approveArgs = RuntimeArgs.fromMap({
      operator: new CLKey(
        new CLByteArray(Uint8Array.from(Buffer.from(spender, "hex"))),
      ),
      token_id: new CLU64(tokenId),
    });
    const deploy = this._contractClient.callEntrypoint(
      "approve",
      approveArgs,
      this._publicKey,
      this.getChainName(),
      "3000000000",
    );
    const signedDeploy = await this.signDeploy(deploy);
    return await this.putDeploy(signedDeploy);
  }
}
