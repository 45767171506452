import { IInteractable } from "@game/engine/interfaces/IInteractable";
import { makeInteractable } from "@game/engine/utilities/InteractableHelper";
import OrderableIsometricCharacter from "@game/engine/gameobjects/OrderableIsometricCharacter";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import { TNakamaUser } from "@shared/types/nakama/NakamaTypes";

export default class RemotePlayerCharacter
  extends OrderableIsometricCharacter
  implements IInteractable
{
  public identifier: string | number;
  public interaction: { [action: string]: string };

  constructor(
    scene,
    public tilePosition: TilePosition3D,
    public userData: TNakamaUser,
    role?: string,
  ) {
    const worldPosition = scene.worldPositionAtTilePosition(tilePosition, true);
    super(
      scene,
      worldPosition.x,
      worldPosition.y,
      userData.metadata?.activeCharacter?.name,
      role,
    );
    this.identifier = this.userData.id;
    this.interaction = {
      interact: this.userData.toString(),
    };
    //TODO: add this to debug-only
    makeInteractable(this._animatedIsometricAvatarComponent.sprite, this);
  }
}
