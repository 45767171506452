import { InteractableEventTypes } from "@game/engine/eventtypes/InteractableEventTypes";
import SceneComponent from "@game/engine/components/SceneComponent";
import RemotePlayerCharacter from "@game/mini/lobby/gameobjects/RemotePlayerCharacter";
import { IRemotePlayerManagerDelegate } from "@game/engine/interfaces/IRemotePlayerManagerDelegate";
import { RemotePlayerInteractableEventTypes } from "@game/engine/eventtypes/RemotePlayerInteractableEventTypes";
import PublicSpaceGameScene from "@game/engine/scenes/PublicSpaceGameScene";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import ISpawnData from "@game/engine/interfaces/ISpawnData";
import { TilePositionMath } from "@game/engine/utilities/TileHelper";
import { TilePosition3DMinusOne } from "@game/engine/utilities/TilePositionHelper";
import { TNakamaUser, UserMetadata } from "@shared/types/nakama";
import BasicCache from "@shared/Cache";
import Network from "@game/engine/networking/Network";
import {
  appear,
  scaleAndFadeOut,
} from "@game/engine/utilities/AnimationHelper";
import EventEmitter = Phaser.Events.EventEmitter;
import TWEEN_COMPLETE = Phaser.Tweens.Events.TWEEN_COMPLETE;
import {
  EnumGameEvents,
  EnumUiEvents,
  handleEvent,
  dispatchEvent,
} from "@shared/Events";

export default class RemotePlayerManagerSceneComponent extends SceneComponent {
  public events: EventEmitter;
  protected declare _scene: PublicSpaceGameScene;
  private _network: typeof Network = Network;
  private readonly _remotePlayers: { [userId: string]: RemotePlayerCharacter };

  private _delegate: IRemotePlayerManagerDelegate;

  public set delegate(value: IRemotePlayerManagerDelegate) {
    this._delegate = value;
  }

  constructor(
    private _spawnDatas: {
      [identifier: string]: ISpawnData;
    },
    private _defaultSpawnIdentifier: string = "default",
  ) {
    super();

    this.events = new EventEmitter();

    this._remotePlayers = {};

    handleEvent(
      EnumUiEvents.UiGameUpdateRemotePlayerData,
      async (data: { openModal?: boolean; id: string }) => {
        this._remotePlayers[data.id].userData = (
          await Network.core.getUsersData(data.id)
        )[0];
        if (data.openModal) {
          dispatchEvent(
            EnumGameEvents.GameUiPlayerInteraction,
            this._remotePlayers[data.id].userData,
          );
        }
      },
    );
  }

  public createAndAddRemotePlayer(
    remotePlayerData: TNakamaUser,
    tilePosition?: TilePosition3D,
  ): RemotePlayerCharacter {
    if (this.getPlayerById(remotePlayerData.id)) {
      return;
    }
    const spawnData = this._spawnDatas[this._defaultSpawnIdentifier];
    if (!spawnData) {
      console.error(
        "No spawn data - did you set a 'default' spawn location for the map?",
      );
      return null;
    }
    if (TilePositionMath.equals(tilePosition, TilePosition3DMinusOne)) {
      tilePosition = spawnData.tilePosition;
    }

    //TODO: add x,y to user metadata
    // console.log(
    //   "metadata" in remotePlayerData &&
    //     "role" in remotePlayerData.metadata &&
    //     remotePlayerData.metadata["role"],
    // );
    const player = new RemotePlayerCharacter(
      this._scene,
      tilePosition || spawnData.tilePosition,
      remotePlayerData,
      "metadata" in remotePlayerData &&
        "role" in remotePlayerData.metadata &&
        remotePlayerData.metadata["role"],
    );
    this._remotePlayers[remotePlayerData.id] = player;
    // Add to the scene
    this._scene.add.existing(player);
    this._scene.addOrderable(player);

    // Face the right way
    player.face(spawnData.direction);

    // Make them clickable
    this.registerInteractable(player);

    appear(this._scene.tweens, player.sprite, true);

    //Add player to local cache
    this.tryAddToCache(remotePlayerData.id, remotePlayerData.metadata);

    return player;
  }

  public getPlayerById(userId: string): RemotePlayerCharacter | null {
    if (userId in this._remotePlayers) {
      return this._remotePlayers[userId];
    }
    return null;
  }

  public moveRemotePlayer(userId: string, goalPosition: TilePosition3D) {
    if (userId in this._remotePlayers) {
      this._scene.moveCharacterToGoalIfPossible(
        this._remotePlayers[userId],
        goalPosition,
      );
    }
  }

  public registerInteractable(player: RemotePlayerCharacter) {
    // Attach ourselves to interactable NPCs
    player.on(InteractableEventTypes.OnHover, (_player) => {
      this._delegate?.onRemotePlayerHover(_player);
      this.events.emit(RemotePlayerInteractableEventTypes.OnHover, _player);
    });
    player.on(InteractableEventTypes.OnInteract, (_player) => {
      this._delegate?.onRemotePlayerInteract(_player);
      this.events.emit(RemotePlayerInteractableEventTypes.OnInteract, _player);
    });
    player.on(InteractableEventTypes.OnOut, (_player) => {
      this._delegate?.onRemotePlayerOut(_player);
      this.events.emit(RemotePlayerInteractableEventTypes.OnOut, _player);
    });
  }

  public removeRemotePlayer(userId: string) {
    if (userId in this._remotePlayers) {
      const remotePlayer = this._remotePlayers[userId];
      scaleAndFadeOut(this._scene.tweens, remotePlayer.sprite, 256).on(
        TWEEN_COMPLETE,
        () => {
          remotePlayer.destroy(true);
          // Remove it from the sorting list too
          this._scene.removeOrderable(remotePlayer);
        },
      );
      remotePlayer.userData.username;

      dispatchEvent(EnumGameEvents.GameRemotePlayerLeft, {
        userId,
        username: remotePlayer.userData.username,
      });
      delete this._remotePlayers[userId];
    }
  }

  public async tryAddToCache(userId: string, userMetadata?: UserMetadata) {
    try {
      const [user] = await Network.getUsersData(userId);
      if (!userMetadata) {
        userMetadata = user.metadata;
      }
      BasicCache.playerMetadata[userId] = {
        ...userMetadata,
        username:
          user.username && user.username !== null
            ? user.username
            : user.metadata.username,
      };
    } catch (e) {
      //failed to add
    }
  }

  public updatePlayerCharacter(userId: string, characterName: string) {
    const player = this.getPlayerById(userId);

    if (player) {
      player.updateCharacterName(characterName);
    }
  }
}
