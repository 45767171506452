import { TilePosition3D } from "@common/interfaces/TilePosition3D";
import { Size } from "@common/interfaces/Size";
import { TilePosition2D } from "@common/interfaces/TilePosition2D";
import { Vector2Like } from "@common/interfaces/Vector2Like";

export const TilePosition2DUtilities = {
  add: function (
    positionA: TilePosition2D,
    positionB: TilePosition2D,
  ): TilePosition2D {
    return { x: positionA.x + positionB.x, y: positionA.y + positionB.y };
  },

  clearValueFromArrayAtTilePosition: function <T>(
    array: T[],
    tilePosition: TilePosition2D,
    mapHeight: number,
  ) {
    this.setValueAtTilePosition<T | null>(array, tilePosition, mapHeight, null);
  },

  equals: function (
    positionA: TilePosition2D,
    positionB: TilePosition2D,
  ): boolean {
    return positionA.x === positionB.x && positionA.y === positionB.y;
  },

  getValueFromArrayAtTilePosition: function <T>(
    array: T[],
    tilePosition: TilePosition2D,
    mapHeight: number,
  ): T {
    return array[this.tilePositionToIndex(tilePosition, mapHeight)];
  },

  setValueAtTilePosition: function <T>(
    array: T[],
    tilePosition: TilePosition2D,
    mapHeight: number,
    value: T,
  ): void {
    array[this.tilePositionToIndex(tilePosition, mapHeight)] = value;
  },

  tilePositionToIndex: function (
    tilePosition: TilePosition2D,
    mapHeight: number,
  ): number {
    const { x, y } = tilePosition;
    return y * mapHeight + x;
  },

  // Handy candy
  to3D: function (tilePosition: TilePosition2D, z: number = 0) {
    return { x: tilePosition.x, y: tilePosition.y, z };
  },

  toTilePosition3D: function (tilePosition: TilePosition2D, z: number = 0) {
    return { x: tilePosition.x, y: tilePosition.y, z };
  },

  // MARKTWAIN - Remove the 2d from the name
  positionToTilePosition2D: function (
    position: Vector2Like,
    tileSize: Size,
  ): TilePosition2D {
    return {
      x: Math.floor(position.x / tileSize.width),
      y: Math.floor(position.y / tileSize.height),
    };
  },

  worldPositionAtTilePosition: function (
    tilePosition: TilePosition2D,
    tileSize: Size,
  ): Vector2Like {
    return {
      x: tileSize.width * tilePosition.x + tileSize.width * 0.5,
      y: tileSize.height * tilePosition.y + tileSize.height * 0.5,
    };
  },
};

export const TilePosition3DUtilities = {
  add: function (
    positionA: TilePosition3D,
    positionB: TilePosition3D,
  ): TilePosition3D {
    return {
      x: positionA.x + positionB.x,
      y: positionA.y + positionB.y,
      z: positionA.z + positionB.z,
    };
  },

  difference: function (
    positionA: TilePosition3D,
    positionB: TilePosition3D,
  ): TilePosition3D {
    return {
      x: positionA.x - positionB.x,
      y: positionA.y - positionB.y,
      z: positionA.z - positionB.z,
    };
  },

  equals: function (
    positionA: TilePosition3D,
    positionB: TilePosition3D,
  ): boolean {
    return (
      positionA.x === positionB.x &&
      positionA.y === positionB.y &&
      positionA.z === positionB.z
    );
  },

  sign: function (position: TilePosition3D): TilePosition3D {
    const { x, y, z } = position;
    position.x = Math.sign(x);
    position.y = Math.sign(y);
    position.z = Math.sign(z);
    return position;
  },

  to2D: function (tilePosition: TilePosition3D) {
    return { x: tilePosition.x, y: tilePosition.y };
  },

  toTilePosition2D: function (tilePosition: TilePosition3D) {
    return { x: tilePosition.x, y: tilePosition.y };
  },
};
