import { TMenuItem } from "@overlay/types";
import { dispatchEvent, EnumUiEvents } from "@shared/Events";
import { currentPlayerLevel } from "@uicomps/common/currents";
import { ref } from "vue";
import { event } from "vue-gtag";
export const menuActive = ref(false);

export const menuItems: Array<TMenuItem> = [
  {
    id: 0,
    displayName: "group-1",
    type: "divider",
  },
  {
    id: 1,
    displayName: "Inventory",
    icon: "inventory-bag.png",
    type: "button",
    callback: triggerMenuItemModal,
  },
  {
    id: 2,
    displayName: "Minigames",
    icon: "controller.png",
    type: "button",
    callback: triggerMenuItemModal,
  },
  {
    id: 3,
    displayName: "Challenges",
    icon: "challenges.png",
    type: "button",
    callback: triggerMenuItemModal,
  },

  {
    id: 4,
    displayName: "Leaderboard",
    icon: "crowns.png",
    type: "button",
    callback: triggerMenuItemModal,
  },
  {
    id: 5,
    displayName: "group-2",
    type: "divider",
  },
  {
    id: 6,
    displayName: "Store",
    icon: "tickets.png",
    type: "button",
    callback: triggerMenuItemModal,
  },
  {
    id: 7,
    displayName: "Marketplace",
    icon: "basket.png",
    type: "button",
    callback: redirectMarketplace,
  },
  {
    id: 8,
    displayName: "Friends",
    value: "FriendsListModal",
    icon: "friends.png",
    type: "button",
    callback: triggerMenuItemModal,
  },
  {
    id: 9,
    displayName: "Rooms",
    icon: "room.png",
    type: "button",
    callback: triggerMenuItemModal,
  },
  {
    id: 10,
    displayName: "group-3",
    type: "divider",
  },
  {
    id: 11,
    displayName: "Deposit",
    icon: "trade.png",
    type: "button",
    callback: triggerMenuItemModal,
  },
  {
    id: 12,
    displayName: "Settings",
    icon: "glyphs/sound-high.png",
    type: "button",
    callback: triggerMenuItemModal,
  },
  {
    id: 13,
    displayName: "Patreon",
    icon: "patreon.png",
    type: "button",
    callback: redirectPatreon,
    style: "width: 45px; margin-right: -10px;",
  },
  {
    id: 14,
    displayName: "Referral",
    icon: "referral.png",
    type: "button",
    callback: triggerMenuItemModal,
  },
  {
    id: 15,
    displayName: "group-4",
    type: "divider",
  },

  {
    id: 16,
    displayName: "Toggle Rotation",
    icon: "glyphs/rotate.svg",
    type: "button",
    callback: () => $store.general.mobileToggleRotation(),
  },
  {
    id: 17,
    displayName: "Sign Out",
    icon: "glyphs/sign-out.png",
    type: "button",
    callback: () => dispatchEvent(EnumUiEvents.UiLogout),
  },
];

export function toggleMenu(setActive?: boolean) {
  if (setActive === undefined) {
    menuActive.value = !menuActive.value;
  } else {
    menuActive.value = setActive;
  }
}
export function triggerMenuItemModal(menuItem: TMenuItem, emits?: any) {
  emits("openModal", menuItem.value ?? menuItem.displayName + "Modal");

  // Google Analytics event
  event("Button-click-game", {
    event_category: menuItem.value ?? menuItem.displayName,
    event_label: menuItem.value ?? menuItem.displayName,
    value: 1,
  });
}

export function redirectPatreon() {
  var url = "https://patreon.com/BitHotel";

  // open the link in a new tab
  window.open(url, "_blank");
}

export function redirectMarketplace() {
  var url = "https://bithotel.io/#/marketplace";

  // open the link in a new tab
  window.open(url, "_blank");
}

export function levelBar(
  experience?: number,
  experienceGoal?: number,
): string[] {
  // * 40, due to the level bar having 40 parts
  const ratio = Math.ceil(
    ((experience ?? currentPlayerLevel.experience) /
      (experienceGoal ?? currentPlayerLevel.experienceGoal)) *
      40,
  );
  const parts = [];
  let base = "/assets/overlay/exp_bar/";
  let secParts = ["a", "b", "c", "d"];

  switch (currentPlayerLevel.level) {
    case 1:
      base += "blue/";
      break;
    case 2:
      base += "green/";
      break;
    case 3:
      base += "purple/";
      break;
    case 4:
      base += "pink/";
      break;
    case 5:
      base += "orange/";
      break;
    default:
      base += "yellow/";
  }
  for (let i = 0; i < ratio; i++) {
    let part = base;
    if (i < 4) {
      part += "left_";
    } else if (i < 36) {
      part += "middle_";
    } else part += "right_";
    part += secParts[i % 4] + ".png";

    parts.push(part);
  }
  return parts;
}
