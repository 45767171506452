import { ethers } from "ethers";
import { mobileCachedWeb3Auth, mobileSignInWeb3Auth } from "./postMessage";
import { Web3auth } from "@overlay/modules/chain/web3auth";
import { TChain } from "@shared/types";

export async function getPrivateKey(cachedKey: boolean): Promise<string> {
  if (!cachedKey) mobileSignInWeb3Auth();
  else mobileCachedWeb3Auth();
  const privateKey = await new Promise((resolve) => {
    setTimeout(() => {
      resolve("");
    }, 100000);
    (window as any)["exposedSetPrivateKey"] = (privKey: string) => {
      resolve(privKey);
    };
  });
  return privateKey as string;
}

export async function getMobileProvider(
  chain: TChain,
  cachedKey: boolean = false,
): Promise<
  | {
      wallet: ethers.Wallet;
      provider: ethers.providers.JsonRpcProvider;
    }
  | undefined
> {
  const privKey = await getPrivateKey(cachedKey);
  try {
    return Web3auth.toEthersWallet(privKey, chain);
  } catch (error) {
    console.log(error, "getMobileProvider");
  }
  return undefined;
}
