import { IAudio } from "@engine/components/scenecomponents/AudioComponent";

export enum EventType {
  ROTATE = "ROTATE",
  UNLOCK_ORIENTATION = "UNLOCK_ORIENTATION",
  CANCEL_ALL_SCHEDULED_NOTIFICATIONS_ASYNC = "CANCEL_ALL_SCHEDULED_NOTIFICATIONS_ASYNC",
  SCHEDULE_PUSH = "SCHEDULE_PUSH",
  SET_LOADING = "SET_LOADING",
  SET_URI = "SET_URI",
  GET_PUSH_TOKEN = "GET_PUSH_TOKEN",
  BUY_PRODUCT = "BUY_PRODUCT",
  BUY_PACKAGE = "BUY_PACKAGE",
  TRACK = "TRACK",
  GET_DEVICE_INFO = "GET_DEVICE_INFO",
  REQUEST_REVIEW = "REQUEST_REVIEW",
  GDPR_FORGET_ME = "GDPR_FORGET_ME",
  SET_KEYBOARD_OFFSET = "SET_KEYBOARD_OFFSET",
  SET_USER_ID = "SET_USER_ID",
  REWARDED_AD = "REWARDED_AD",
  WEB3AUTH_LOGIN = "WEB3AUTH_LOGIN",
  WEB3AUTH_CACHED = "WEB3AUTH_CACHED",
  GET_VERSION = "GET_VERSION",
  GET_PACKAGE_PRICES = "GET_VERSION",
  COPY_CLIPBOARD = "COPY_CLIPBOARD",
  PLAY_AUDIO = "PLAY_AUDIO",
  ADD_BACKGROUND_MUSIC = "ADD_BACKGROUND_MUSIC",
  ADD_SOUND_EFFECTS = "ADD_SOUND_EFFECTS",
  PLAY_BACKGROUND_AUDIO = "PLAY_BACKGROUND_AUDIO",
  STOP_BACKGROUND_AUDIO = "STOP_BACKGROUND_AUDIO",
  PAUSE_AUDIO = "PAUSE_AUDIO",
  PAUSE_SOUND_EFFECTS = "PAUSE_SOUND_EFFECTS",
  RESUME_SOUND_EFFECTS = "RESUME_SOUND_EFFECTS",
  PLAY_SOUND_EFFECT = "PLAY_SOUND_EFFECT",
  STOP_SOUND_EFFECT = "STOP_SOUND_EFFECT",
  STORE_VALUE = "STORE_VALUE",
  RETRIEVE_VALUE = "RETRIEVE_VALUE",
}

export interface PostMessage {
  type: EventType | 10; // 10 is used to check for update
  uri?: string; // type setUri
  showSplashScreen?: boolean; // type loading
  push?: {
    // type schedulePush
    title: string;
    body: string;
    data: string;
    seconds: number;
  };
  direction?: OrientationLock; // type rotate
  productId?: string;
  eventToken?: string;
  trackData?: any;
  packageIndex?: number;
  userId?: string;

  adUnitIdsIOS?: Array<string>;
  adUnitIdsAndroid?: Array<string>;
  adKeyWords?: Array<string>;
  customAdData?: string;
  clipboardString?: string;
  audio?: IAudio;
  audios?: IAudio[];
  audioKey?: string;
  audioLoop?: boolean;

  storeData?: { key: string; value?: string };
}

export enum OrientationLock {
  /**
   * The default orientation. On iOS, this will allow all orientations except `Orientation.PORTRAIT_DOWN`.
   * On Android, this lets the system decide the best orientation.
   */
  DEFAULT = 0,
  /**
   * All four possible orientations
   */
  ALL = 1,
  /**
   * Any portrait orientation.
   */
  PORTRAIT = 2,
  /**
   * Right-side up portrait only.
   */
  PORTRAIT_UP = 3,
  /**
   * Upside down portrait only.
   */
  PORTRAIT_DOWN = 4,
  /**
   * Any landscape orientation.
   */
  LANDSCAPE = 5,
  /**
   * Left landscape only.
   */
  LANDSCAPE_LEFT = 6,
  /**
   * Right landscape only.
   */
  LANDSCAPE_RIGHT = 7,
  /**
   * A platform specific orientation. This is not a valid policy that can be applied in [`lockAsync`](#screenorientationlockasyncorientationlock).
   */
  OTHER = 8,
  /**
   * An unknown screen orientation lock. This is not a valid policy that can be applied in [`lockAsync`](#screenorientationlockasyncorientationlock).
   */
  UNKNOWN = 9,
}
