import EntityComponent from "../EntityComponent";
import {
  ANIMATION_DURATION,
  ChatMessage,
  ChatTypingBubble,
  FONT_SIZE,
  LINE_HEIGHT,
  LINE_MAX_WIDTH,
  TEXT_VISIBLE_DURATION,
} from "./chat/ChatUtilities";
import {
  dispatchEvent,
  EnumGameEvents,
  EnumNetworkEvents,
  EnumUiEvents,
  handleEvent,
  TEventHandler,
} from "@shared/Events";
import { Network } from "@network";
import { EnumChatEvents } from "@engine/interfaces/IChatMessageEvent";
import { currentAccount } from "@shared/Global";
import { Friend, Notification, Presence } from "@heroiclabs/nakama-js";
import NotificationCenter, {
  NotificationCenterUpdateTypes,
} from "@engine/networking/NotificationCenter";
import TextStyle = Phaser.Types.GameObjects.Text.TextStyle;
import Vector2 = Phaser.Math.Vector2;
import OrderableIsometricCharacter from "@engine/gameobjects/OrderableIsometricCharacter";
import { Toast } from "@engine/notifications/Toast";

const publicStyle: TextStyle = {
  color: "#000000",
  fontFamily: "Depixel",
  fontSize: FONT_SIZE + "px",
  wordWrap: { width: LINE_MAX_WIDTH, useAdvancedWrap: true },
};

const privateStyle: TextStyle = {
  color: "#8112ff",
  fontFamily: "Depixel",
  fontSize: FONT_SIZE + "px",
  wordWrap: { width: LINE_MAX_WIDTH, useAdvancedWrap: true },
};

interface IDirectMessageContent {
  username: string;
}

export default class ChatComponent extends EntityComponent {
  public offset: Vector2 = new Vector2(0, 0);
  private channelId: string = "";
  private channelStyles: { [key: string]: TextStyle } = {};
  private chatMessages: ChatMessage[] = [];
  private eventHandlers: Array<TEventHandler> = [];
  private friends: Array<Friend> = [];
  private isAnimating: boolean = false;
  private messagesBuffer: {
    message: string;
    textStyle: TextStyle;
    remote: boolean;
  }[] = [];
  private notificationHandlers: Array<string> = [];
  private privateChannels: Map<string, string> = new Map<string, string>();
  private typingBubble: ChatTypingBubble | undefined = undefined;

  constructor(
    local: boolean = false,
    private lineHeight: number = LINE_HEIGHT,
    private textVisibleDuration: number = TEXT_VISIBLE_DURATION,
    public animationDuration: number = ANIMATION_DURATION,
    public staggerDelay: number = 64,
  ) {
    super();
    if (local) {
      NotificationCenter.instance.addListener(
        NotificationCenterUpdateTypes.RetrievedChatDirectInvite,
        async (notification: Notification) => {
          const result = await this.captureChatDirectInvite(notification);
          if (!result.joined) {
            return;
          }
          this.saveChannel(
            result.channelId,
            "DirectMessage",
            (<IDirectMessageContent>notification.content).username,
          ).finally();
        },
      );
      this.notificationHandlers.push(
        NotificationCenterUpdateTypes.RetrievedChatDirectInvite,
      );

      this.eventHandlers.push(
        handleEvent(
          EnumNetworkEvents.NetworkChatJoinChannel,
          (data: {
            channelId: string;
            channelType?: string;
            privateName?: string;
          }) => {
            this.saveChannel(
              data.channelId,
              data.channelType,
              data.privateName,
            );
          },
        ),
        handleEvent(
          EnumNetworkEvents.NetworkChatLeaveChannel,
          (channelId: string) => {
            if (this.channelId === channelId) {
              this.channelId = "";
            } else {
              const channels = Array.from(this.privateChannels.values());
              channels.forEach((value, index) => {
                if (value == channelId) {
                  this.privateChannels.delete(
                    Array.from(this.privateChannels.keys())[index],
                  );
                  return;
                }
              });
            }
          },
        ),
        handleEvent(
          EnumUiEvents.UiChatMessage,
          async (data: { message: any; channelName?: string }) =>
            this.handleChatMessage(data),
        ),
        handleEvent(EnumUiEvents.UiChatJoinDirect, async (username: string) => {
          await this.handleJoinPrivateChat(username);
        }),
        handleEvent(EnumGameEvents.GameUiListFriends, (data: any) => {
          this.friends = data.friends;
        }),
        handleEvent(
          EnumNetworkEvents.NetworkChannelPresenceLeft,
          (data: { presence: Presence; channelId: string }) => {
            if (
              this.privateChannels.get(data.presence.username) ===
              data.channelId
            ) {
              Network.Core.leaveChat(
                this.privateChannels.get(data.presence.username),
              );
              this.privateChannels.delete(data.presence.username);
            }
          },
        ),
      );

      //get all friends, to filter join requests
      setTimeout(() => {
        dispatchEvent(EnumUiEvents.UiGameListFriends);
      }, 500);
    }
  }

  public allowedMessage(sender_id: string) {
    const friend = this.friends.find((friend) => friend.user.id === sender_id);
    //if blocked
    return friend ? friend.state !== 3 : true;
  }

  public async captureChatDirectInvite(
    notification: Notification,
  ): Promise<{ joined: boolean; channelId?: string }> {
    return this.handleJoinPrivateChat(
      (<IDirectMessageContent>notification.content).username,
    );
  }

  public say(
    message: string,
    remote: boolean,
    textStyle: TextStyle = publicStyle,
  ): void {
    this.addMessage(message, remote, textStyle);
  }

  public startTypingAnimation(): void {
    if (!this.typingBubble) {
      const characterName = (this.owner as OrderableIsometricCharacter)
        .characterName;
      this.typingBubble = new ChatTypingBubble(
        this.owner,
        characterName,
        publicStyle,
        this.offset,
      );
    }
    this.typingBubble.show();
    this.checkBuffer();
  }

  public stopTypingAnimation(): void {
    if (this.typingBubble) this.typingBubble.forceHide();
  }

  protected onDestroy(): void {
    for (const handler of this.eventHandlers) {
      handler.destroy();
    }
    for (const channelId of this.privateChannels.values()) {
      Network.Core.leaveChat(channelId);
    }
  }

  private addMessage(
    message: string,
    remote: boolean,
    textStyle: TextStyle,
  ): void {
    if (this.typingBubble) this.typingBubble.forceHide();
    // If we're animating, add it to the buffer for later adding
    this.messagesBuffer.push({
      message,
      textStyle: textStyle ? textStyle : publicStyle,
      remote,
    });

    this.checkBuffer();
  }

  private async cancelTypingAnimation() {
    //cancel animation
    const content = {
      timestamp: Date.now(),
      message: "",
      karma: currentAccount.user.metadata.karma
        ? currentAccount.user.metadata.karma
        : 0,
      eventType: EnumChatEvents.ChatStopTyping,
    };
    await Network.Core.writeChatMessage(this.channelId, { content });
  }

  private checkBuffer(): void {
    // If we're currently animating, just wait it out
    if (this.isAnimating || !this.owner.scene) {
      return;
    }

    if (this.messagesBuffer.length > 0) {
      this.displayMessage(this.messagesBuffer.pop());
    } else {
      if (this.chatMessages.length <= 0 && this.typingBubble) {
        if (this.typingBubble.visualize) this.typingBubble.visible = true;
        else this.typingBubble.visible = false;
        setTimeout(() => {
          this.checkBuffer();
        }, 750);
      }
    }
  }

  private displayMessage(data: {
    message: string;
    textStyle: TextStyle;
    remote: boolean;
  }): void {
    const characterName = (this.owner as OrderableIsometricCharacter)
      .characterName;
    if (data.remote && "userData" in this.owner) {
      data.message = `${this.owner.userData.username}: ${data.message}`;
    }
    this.isAnimating = true;
    const chatMessage = new ChatMessage(
      this.owner,
      data,
      characterName,
      data.textStyle,
      this.lineHeight,
      this.textVisibleDuration,
      this.animationDuration,
      this.offset,
    );
    chatMessage.render();
    chatMessage.tween();
    // When the chat message has disappeared, remove it from the array
    chatMessage.onComplete = (chatMessage) => {
      const chatMessageIndex = this.chatMessages.indexOf(chatMessage);
      if (chatMessageIndex > -1) {
        this.chatMessages.splice(chatMessageIndex, 1);
      }
      this.checkBuffer();
    };

    // Do some bookkeeping
    this.chatMessages.push(chatMessage);

    // Stagger them a bit? Looks cooler right :D?
    const numberOfMessages: number = this.chatMessages.length;
    const lastMessage: ChatMessage = this.chatMessages[numberOfMessages - 1];

    for (let index = 0; index < numberOfMessages; index++) {
      // Inverse the index to make them "push" each other upwards
      this.chatMessages[index].moveUpwards(
        (numberOfMessages - index) * this.staggerDelay,
        index === numberOfMessages - 1
          ? lastMessage.height / 2
          : lastMessage.height,
      );

      if (index < numberOfMessages - 1) {
        this.chatMessages[index].hideArrow();
      }

      // This makes it look like the top message pulls the others up
      // this.chatMessages[index].moveUpwards(index * staggerDelay);
    }

    // Afterwards, check if more messages need to be displayed, also add 32ms just to be safe
    this.owner.scene.time.delayedCall(
      this.animationDuration + 32 + this.staggerDelay * numberOfMessages,
      () => {
        this.isAnimating = false;

        this.checkBuffer();
      },
    );
  }

  private async handleChatMessage(data: {
    message: any;
    channelName?: string;
  }) {
    if (data.channelName) {
      const joinResult = await this.handleJoinPrivateChat(data.channelName);
      if (joinResult.joined) {
        if (joinResult.pending) {
          await new Promise((resolve) => setTimeout(resolve, 500));
        }
        data.message.content.textStyle =
          this.channelStyles[this.privateChannels.get(data.channelName)];
        try {
          await Network.Core.writeChatMessage(
            this.privateChannels.get(data.channelName),
            data.message,
          );
        } catch (e) {
          //redundant
          if (e.code === 3 && e.message === "Invalid channel identifier") {
            Toast.warning("That player did not accept your DM");
            await Network.Core.leaveChat(joinResult.channelId);
          }
        }
      }
      await this.cancelTypingAnimation();
      return;
    } else if (this.channelId) {
      data.message.textStyle = this.channelStyles[this.channelId];
      await Network.Core.writeChatMessage(this.channelId, data.message);
    }
  }

  private async handleJoinPrivateChat(
    username: string,
  ): Promise<{ joined: boolean; pending?: boolean; channelId?: string }> {
    const friend = this.friends.find((friend) => {
      return friend.user.username === username;
    });
    if (!friend) {
      Toast.warning("You are not friends with that player");
      return { joined: false };
    }
    if (friend.state === 3) {
      Toast.warning("You have blocked that player");
      return { joined: false };
    }
    if (!(await Network.Core.getUsersData([friend.user.id]))[0].online) {
      Toast.warning("That player is not yet online");
      return { joined: false };
    }
    if (this.privateChannels.get(username) && friend) {
      return { joined: true, channelId: this.privateChannels.get(username) };
    }
    const channelId = await Network.Core.joinPrivateChat(
      this.friends.find((friend) => {
        return friend.user.username === username;
      }).user.id,
      username,
    );
    return { joined: true, pending: true, channelId };
  }

  private async saveChannel(
    channelId: string,
    channelType?: string,
    privateName?: string,
  ) {
    switch (channelType) {
      case "DirectMessage": {
        if (!privateName) {
          throw new Error("invalid chat channel join request");
        }
        this.privateChannels.set(privateName, channelId);
        //@todo iterate on the text styles;
        this.channelStyles[channelId] = privateStyle;
        break;
      }
      default: {
        this.channelId = channelId;
        this.channelStyles[channelId] = publicStyle;
      }
    }
  }
  public destroy(): void {
    this.eventHandlers.forEach((handler) => handler.destroy());
    this.eventHandlers = [];
    this.typingBubble?.destroy();
  }
}
