import { formatNftName, toTitleCase } from "@shared/Helpers";
import { TNetworkNft } from "@shared/types";
import characters from "./characters";
import rooms from "./rooms";
import furniture from "./furniture";

export namespace NFTHelpers {
  export const definitions = {
    characters,
    rooms,
    furniture,
  };
  type TNft = {
    address: Array<string>;
    tokenId?: number;
    amount?: number;
    owner?: string;
    type: string;
    metadata: {
      name: string;
      description: string;
      disabled?: boolean;
      external_link?: string;
      image?: string;
      attributes: Array<{ trait_type: string; value: string }>;
      persistent?: boolean;
      texture?: string;
    };
  };
  type TType = string | "character" | "furniture" | "room";
  type TSeason = "season 1" | "season 2";
  type TRarity =
    | "common"
    | "uncommon"
    | "rare"
    | "super rare"
    | "epic"
    | "legendary";
  const nftMap: {
    characters: Array<TNft>;
    furniture: Array<TNft>;
    rooms: Array<TNft>;
  } = {
    characters,
    furniture,
    rooms,
  };
  export const allNfts: Array<TNft> = [
    ...nftMap.characters,
    ...nftMap.furniture,
    ...nftMap.rooms,
  ];
  /**
   *
   * @param nft
   * @returns NFT token address in lowerCase
   */
  function getAddress(nft: TNft | TNetworkNft): string {
    if ("token_address" in nft) {
      return nft.token_address.toLowerCase();
    }
    try {
      const _nft: TNft = JSON.parse(JSON.stringify(nft));
      return _nft.address.shift() || "";
    } catch (e: any) {
      return "";
    }
  }
  /**
   *
   * @param address case insensitive
   * @returns
   */
  export function isAddressValid(address: string) {
    return allNfts.some((n) =>
      n.address.map((a) => a.toLowerCase()).includes(address.toLowerCase()),
    );
  }
  export function isNameValid(name: string) {
    return allNfts.some(
      (n) => n.metadata.name.toLowerCase() == name.toLowerCase(),
    );
  }
  // export function isValid(nft: TNft) {
  //   return allNfts.some((n) => getAddress(nft) == getAddress(n));
  // }
  /**
   *
   * @param address case insensitive
   * @returns TNft where TNft.address[] includes address
   */
  function getByAddress(address: string) {
    return allNfts.find((n) =>
      n.address.map((a) => a.toLowerCase()).includes(address.toLowerCase()),
    );
  }
  /**
   *
   * @param name case insensitive, will be formatted
   * @returns
   */
  function getByName(name: string) {
    return allNfts.find(
      (n) => formatNftName(n.metadata.name) == formatNftName(name),
    );
  }
  /**
   *
   * @param attributes nft metadata attributes
   * @param trait_type case insensitive
   * @returns trait value lowercase
   */
  function getTraitValue(
    attributes: Array<{ trait_type: string; value: string }>,
    trait_type: string,
  ) {
    return (
      attributes.find((item) => item.trait_type.toLowerCase() == trait_type)
        ?.value || ""
    ).toLowerCase();
  }
  function seasonNumber(season: TSeason): number {
    return ["season 1", "season 2"].indexOf(season.toLowerCase()) + 1;
  }

  function rarityNumber(rarity: TRarity) {
    return (
      ["common", "uncommon", "rare", "super rare", "epic", "legendary"].indexOf(
        rarity.toLowerCase(),
      ) + 1
    );
  }
  export class NFT {
    public get id() {
      return `${this.address}_${this.tokenId}`.toLowerCase();
    }

    readonly amount: number;
    readonly address: string;
    readonly description: string;
    readonly disabled: boolean;
    readonly name: string;
    readonly persistent?: boolean;
    readonly texture?: string;
    readonly type: TType;
    readonly tokenId: number;
    readonly rarity: TRarity;

    public get rarityNumber() {
      return rarityNumber(this.rarity);
    }
    readonly season: TSeason;
    public get seasonNumber() {
      return seasonNumber(this.season);
    }
    public get formattedName() {
      return formatNftName(this.name);
    }
    readonly icon?: string;
    listingId: number = 0;
    private constructor(nft: TNft) {
      this.address = getAddress(nft);
      this.type = nft.type;
      const metadata = nft.metadata;

      this.tokenId = nft.tokenId || 0;
      this.amount = nft.amount || 1;

      this.disabled = metadata.disabled || Boolean(false);
      this.name = toTitleCase(metadata.name);
      this.description = metadata.description;
      this.rarity = getTraitValue(metadata.attributes, "rarity") as TRarity;
      this.season = getTraitValue(metadata.attributes, "drop") as TSeason;
      this.texture = metadata.texture;
      this.persistent = metadata.persistent;

      this.icon = metadata.image;
    }
    public toString() {
      return JSON.stringify({
        ...this,
        id: this.id,
        rarityNumber: this.rarityNumber,
        formattedName: this.formattedName,
      });
    }
    public static fromAddress(address: string) {
      if (!address) {
        address = "0xDEF";
      }
      if (address == "0xDEFFemaleeA") {
        address = "0xDEFFemaleA";
      }

      const _nft = getByAddress(address);
      if (_nft) {
        return new NFT(_nft);
      } else {
        throw new Error(`NFT address ${address} is invalid`);
      }
    }
    public static fromName(name: string) {
      const _nft = getByName(name);
      if (_nft) {
        return new NFT(_nft);
      } else {
        throw new Error(`NFT name ${name} is invalid`);
      }
    }
    public static fromTNft(nft: TNft) {
      const address = getAddress(nft);
      const _nft = getByAddress(address);
      if (_nft) {
        return new NFT(_nft);
      } else {
        throw new Error(`NFT address ${address.toLowerCase()} is invalid`);
      }
    }
    /**
     *
     * @param nft
     * @returns NFT based on TNft retreived with nft.token_address
     */
    public static fromTNetworkNft(nft: TNetworkNft) {
      const address = getAddress(nft);
      const _nft = getByAddress(address) || getByName(nft.name);
      if (_nft) {
        _nft.tokenId = parseInt(nft.token_id);
        _nft.owner = nft.owner_of;
        _nft.amount = parseInt(nft.amount);
        if (
          _nft.metadata.name.toLowerCase() == nft.metadata.name.toLowerCase()
        ) {
          Object.assign(_nft.metadata, nft.metadata);
        }
        return new NFT(_nft);
      } else {
        throw new Error(`NFT address ${address.toLowerCase()} is invalid`);
      }
    }
  }
}
