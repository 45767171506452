import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import { TilemapImageLayer } from "@game/engine/objects/TilemapLayer";
import TiledObject = Phaser.Types.Tilemaps.TiledObject;

export function extractPropertiesContainer(
  object: TiledObject | TilemapImageLayer,
  tilePosition: TilePosition3D,
): PropertiesContainer {
  const { properties } = object;
  if (properties) {
    const convertedProperties = {};
    properties.forEach((property) => {
      convertedProperties[property.name] = property.value;
    });
    return new PropertiesContainer(convertedProperties, tilePosition, object);
  }
  return null;
}

export function getTilePositionFromPropertiesContainer(
  propertiesContainer: PropertiesContainer,
): TilePosition3D {
  const positionX = propertiesContainer.getProperty("PositionX");
  const positionY = propertiesContainer.getProperty("PositionY");
  const positionZ = propertiesContainer.getProperty("PositionZ");

  if (null === positionX || null === positionY || null === positionZ) {
    return null;
  }

  return { x: positionX, y: positionY, z: positionZ };
}

export class PropertiesContainer {
  public get identifier(): number | string {
    return this._identifier;
  }

  private readonly _identifier: number | string;

  constructor(
    public properties: { [name: string]: number | string },
    public tilePosition: TilePosition3D,
    public owner: TiledObject | TilemapImageLayer,
  ) {
    if (owner instanceof TilemapImageLayer) {
      this._identifier = owner.name;
    } else {
      this._identifier = owner.id;
    }
  }

  public hasProperty(name: string) {
    return name in this.properties;
  }

  public hasPropertyWithNameAndValue(
    name: string,
    value: string | number,
  ): boolean {
    if (name in this.properties) {
      return this.properties[name] === value;
    }
    return false;
  }

  public hasPropertyWithNameContainingValue(
    name: string,
    value: string,
  ): boolean {
    if (name in this.properties) {
      return (<string>this.properties[name]).includes(value);
    }
    return false;
  }

  public getProperty(name: string, fallback = null) {
    if (name in this.properties) {
      return this.properties[name];
    }
    return fallback;
  }
}
