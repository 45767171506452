export enum PlinkoOpCodes {
  RefreshGame = 1,
  StartGame,
  StartGameData,
  RefreshGameData,
  AnimationFinished,
  ResetGame,
  UpdateData,
  RandomOffline,
}
