import { useModalDisplayDataStore } from "./modalDataStore";
import { useModalStore } from "./modalStore";
import { useGeneralStore } from "./generalStore";
import { useUserStore } from "./userStore";
import { useGameState } from "./gameStateStore";

export * from "./modalDataStore";
export * from "./modalStore";
export * from "./generalStore";
export * from "./userStore";

// export * from "./userStore";
// export * from "./uiStore";
// export * from "./dataStore";

export function useStores() {
  const general = useGeneralStore();
  const modal = useModalStore();
  const user = useUserStore();
  const modalData = useModalDisplayDataStore();
  const gameState = useGameState();
  return { general, modal, user, modalData, gameState };
}
