import { InteractableEventTypes } from "@game/engine/eventtypes/InteractableEventTypes";
import { IInteractable } from "@game/engine/interfaces/IInteractable";
import { PropertiesContainer } from "@game/engine/objects/PropertiesContainer";
import GameObject = Phaser.GameObjects.GameObject;
import EventEmitter = Phaser.Events.EventEmitter;
import GAMEOBJECT_POINTER_DOWN = Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN;
import GAMEOBJECT_POINTER_OUT = Phaser.Input.Events.GAMEOBJECT_POINTER_OUT;
import GAMEOBJECT_POINTER_OVER = Phaser.Input.Events.GAMEOBJECT_POINTER_OVER;

const DOUBLE_CLICK_INTERVAL = 300;

export function makeInteractable<T extends GameObject | EventEmitter>(
  interactable: GameObject,
  owner: T,
  hitArea: any = undefined,
  eatClick: boolean = true,
) {
  interactable.setInteractive({
    hitArea,
    useHandCursor: true,
  });
  interactable.on(GAMEOBJECT_POINTER_OVER, () => {
    owner.emit(InteractableEventTypes.OnHover, owner);
  });
  interactable.on(GAMEOBJECT_POINTER_OUT, () => {
    owner.emit(InteractableEventTypes.OnOut, owner);
  });
  interactable.on(GAMEOBJECT_POINTER_DOWN, (a, b, c, event) => {
    //Prevent clicking on a player through a vue modal
    if (a.downElement.localName !== "canvas") {
      return;
    }

    const lastClickTime = interactable.getData("last_click") || Number(0);
    const currentTime = Date.now();

    if (currentTime - lastClickTime < DOUBLE_CLICK_INTERVAL) {
      owner.emit(InteractableEventTypes.OnDoubleClick);
      interactable.setData("last_click", 0);
      clearTimeout(interactable.getData("click_timer_id"));
    } else {
      const timerId = setTimeout(
        () => owner.emit(InteractableEventTypes.OnInteract, owner),
        DOUBLE_CLICK_INTERVAL,
      );
      interactable.setData("last_click", currentTime);
      interactable.setData("click_timer_id", timerId);
    }

    if (eatClick) {
      // Stop clicking on stuff
      event.stopPropagation();
    }
  });
}

export function retrieveInteraction(propertiesContainer: PropertiesContainer): {
  [key: string]: string;
} {
  const interaction = {};
  const interactionString = propertiesContainer.getProperty("Interaction");
  if (interactionString) {
    interactionString.split("|").forEach((interactionPair) => {
      const split = interactionPair.split(";");
      if (split.length === 2) {
        interaction[split[0]] = split[1];
      }
    });
  } else {
    console.error(
      'Object marked as interactable, but missing "Interaction" field',
    );
  }

  return interaction;
}

export function setupInteractable<
  T extends (IInteractable & GameObject) | (IInteractable & EventEmitter),
>(
  interactable: GameObject,
  owner: T,
  propertiesContainer: PropertiesContainer,
) {
  // See if we should make ourselves interactable
  const types = propertiesContainer.getProperty("Types");
  if (types && types.includes("interactable")) {
    // Store the interactions
    owner.interaction = retrieveInteraction(propertiesContainer);

    // We assume our sprite is our interactor
    makeInteractable<T>(interactable, owner);
  }
}
