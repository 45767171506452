import { OrderableType } from "@game/engine/enums/OrderableType";
import { IInteractable } from "@game/engine/interfaces/IInteractable";
import IIsometricOrderable from "@game/engine/interfaces/IIsometricOrderable";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import { PropertiesContainer } from "@game/engine/objects/PropertiesContainer";
import { setupInteractable } from "@game/engine/utilities/InteractableHelper";
import Image = Phaser.GameObjects.Image;
import EventEmitter = Phaser.Events.EventEmitter;
import Sprite = Phaser.GameObjects.Sprite;

export default class OrderableImage
  extends EventEmitter
  implements IInteractable, IIsometricOrderable
{
  public get identifier(): number | string {
    return this.propertiesContainer.identifier;
  }

  public interaction: { [action: string]: string };
  public orderableType: OrderableType.Static;

  constructor(
    public tilePosition: TilePosition3D,
    public image: Image | Sprite,
    public propertiesContainer: PropertiesContainer,
  ) {
    super();

    this.interaction = {};
    setupInteractable(image, this, propertiesContainer);
  }

  public setDepth(depth: number) {
    this.image.setDepth(depth);
  }
}
