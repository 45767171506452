import SceneComponent from "@engine/components/SceneComponent";
import NotificationCenter, {
  NotificationCenterUpdateTypes,
} from "@engine/networking/NotificationCenter";
import { IAchievement } from "@common/interfaces/IAchievement";
import { Scene } from "phaser";
import { Fonts } from "@engine/constants/Fonts";
import { Notification } from "@heroiclabs/nakama-js";
import Container = Phaser.GameObjects.Container;
import Ellipse = Phaser.GameObjects.Ellipse;
import RESIZE = Phaser.Scale.Events.RESIZE;
import Graphics = Phaser.GameObjects.Graphics;
import Text = Phaser.GameObjects.Text;
import Image = Phaser.GameObjects.Image;
import Size = Phaser.Structs.Size;

export class AchievementNotificationComponent extends SceneComponent {
  private static OverlaySceneKey: string =
    "achievementnotificationoverlayscenekey";
  private readonly _achievementsBuffer: IAchievement[];
  private _overlayScene!: Scene;
  private _popup?: AchievementPopup;

  constructor() {
    super();

    this._achievementsBuffer = [];
  }

  public create() {
    super.create();

    const key = AchievementNotificationComponent.OverlaySceneKey;
    if (!this.getScene().scene.get(key)) {
      this._overlayScene = this.getScene().scene.add(key, Scene, true);
    }
  }

  public onCreated() {
    super.onCreated();

    NotificationCenter.instance.on(
      NotificationCenterUpdateTypes.UnlockedAchievement,
      this.receivedUnlockedAchievementNotification,
    );
  }

  public unlockedAchievement(achievement: IAchievement) {
    try {
      // Load the image if needed
      this.getScene().dynamicLoadAssetIfNotLoadingOrLoaded(
        {
          key: "__achievement-" + achievement.identifier + "__",
          type: "image",
          url:
            "assets/shared/achievements/badges/" +
            achievement.identifier +
            ".png",
        },
        (key: string, success: boolean) => {
          console.log(key, success);
          if (this._popup) {
            this._achievementsBuffer.push(achievement);
            return;
          }

          this._popup = new AchievementPopup(
            this._overlayScene,
            achievement,
            false,
          );
          this._popup.on(
            AchievementPopup.EventTypes.Completed,
            (achievementPopup: AchievementPopup) => {
              this._popup = undefined;
              if (this._achievementsBuffer.length > 0) {
                this.unlockedAchievement(
                  <IAchievement>this._achievementsBuffer.shift(),
                );
              }
            },
          );

          this._overlayScene.add.existing(this._popup);
        },
      );
    } catch (error) {
      console.log("Well well well");
    }
  }

  protected onShutdown() {
    super.onShutdown();

    NotificationCenter.instance.off(
      NotificationCenterUpdateTypes.UnlockedAchievement,
      this.receivedUnlockedAchievementNotification,
    );

    if (this._overlayScene) {
      this.getScene().scene.remove(
        AchievementNotificationComponent.OverlaySceneKey,
      );
    }
  }

  private receivedUnlockedAchievementNotification = (
    notification: Notification,
  ) => {
    if (notification.content && "achievement" in notification.content) {
      this.unlockedAchievement(<IAchievement>notification.content.achievement);
    }
  };
}

class AchievementPopup extends Container {
  public static EventTypes = {
    Completed: "achievementpopupcompleted",
  };

  private readonly _background: Graphics;
  private readonly _badgeBackground: Ellipse;

  public constructor(
    scene: Scene,
    achievement: IAchievement,
    useGenericImage: boolean,
  ) {
    super(scene, 0, 0);

    // Place us at a certain position on the screen and make sure we stay there on resize
    this.onResized(this.scene.scale.gameSize);
    this.scene.scale.on(RESIZE, this.onResized);

    const size = { width: 384, height: 64 };
    this._background = new Graphics(scene);
    this._background.fillStyle(0x000000, 1);
    this._background.fillRoundedRect(
      -size.width * 0.5,
      0,
      size.width,
      size.height,
      size.height / 2,
    );
    this._background.setAlpha(0).setScale(0);

    // The badge background
    const padding = 8;
    const badgeSize = { height: size.height, width: size.height };
    this._badgeBackground = new Ellipse(
      scene,
      size.width * -0.5 + badgeSize.width / 2,
      size.height * 0.5,
      badgeSize.width - padding * 2,
      badgeSize.height - padding * 2,
      0xffffff,
    )
      // .setAlpha(0.64)
      .setDepth(9)
      .setOrigin(0.5, 0.5)
      .setScale(0);

    achievement.value;
    const badge = new Image(
      scene,
      this._badgeBackground.x,
      this._badgeBackground.y,
      useGenericImage
        ? "__achievement_generic_" + achievement.value + "__"
        : "__achievement-" + achievement.identifier + "__",
    )
      .setDepth(10)
      .setScale(0);

    const achievementUnlockedText = new Text(
      scene,
      -size.width / 2 + badgeSize.width,
      this._badgeBackground.y,
      "achievement unlocked",
      {
        // backgroundColor: "#00ff00",
        fixedWidth: size.width - badgeSize.width - padding,
        ...Fonts.LargeText(0xffffff),
      },
    )
      .setOrigin(0, 0.6)
      .setAlpha(0);

    const achievementTitle = new Text(
      scene,
      -size.width / 2 + badgeSize.width + padding * 2,
      padding,
      achievement.title,
      Fonts.LargeText(0xffffff, "left"),
    )
      .setOrigin(0, 0)
      .setAlpha(0);

    const achievementDescription = new Text(
      scene,
      -size.width / 2 + badgeSize.width + padding * 2,
      size.height - padding,
      achievement.description,
      Fonts.NormalText(0xffffff, "left"),
    )
      .setOrigin(0, 1)
      .setAlpha(0);

    this.add(this._background);
    this.add(this._badgeBackground);
    this.add(badge);
    this.add(achievementUnlockedText);
    this.add(achievementTitle);

    this.add(achievementDescription);

    this.setDepth(999).setScrollFactor(0);

    // Set up the animation
    const duration = 512;
    scene.tweens.timeline({
      tweens: [
        // Show the background
        {
          duration,
          ease: "cubic.inout",
          targets: this._background,
          props: {
            alpha: 0.32,
            scaleX: {
              delay: duration,
              getActive() {
                return 0.01;
              },
              getEnd() {
                return 1;
              },
            },
            scaleY: {
              value: 1,
            },
          },
        },
        // Show the badge
        {
          delay: scene.tweens.stagger(128, {}),
          targets: [this._badgeBackground, badge],
          props: {
            rotation: {
              duration: duration,
              ease: "cubic.out",
              value: Math.PI * 2,
            },
            scale: {
              duration: duration / 2,
              ease: "back.out",
              value: 1,
            },
          },
          onComplete: () => {
            this.pulsate(duration * 4, 4);
          },
        },
        // Show then hide the achievement unlocked text
        {
          hold: duration * 2,
          targets: achievementUnlockedText,
          props: {
            alpha: {
              value: 1,
            },
          },
          yoyo: true,
        },
        // Show then hide achievement title and description
        {
          duration,
          hold: duration * 4 * 2,
          targets: [achievementTitle, achievementDescription],
          props: {
            alpha: 1,
          },
          yoyo: true,
        },
        // Hide the badge
        {
          delay: scene.tweens.stagger(duration / 2, {}),
          duration,
          ease: "back.in",
          targets: [badge, this._badgeBackground],
          props: {
            scale: 0,
          },
        },
        // Hide the background
        {
          duration,
          ease: "cubic.inout",
          targets: this._background,
          props: {
            scaleX: {
              getEnd() {
                return 0.01;
              },
            },
            scaleY: {
              delay: duration,
              getEnd() {
                return 0;
              },
            },
          },
        },
      ],
      onComplete: () => {
        this.emit(AchievementPopup.EventTypes.Completed, this);
        this.scene.scale.off(RESIZE, this.onResized);
        this.destroy(true);
      },
    });
  }

  private onResized = (gameSize: Size) => {
    this.setPosition(gameSize.width * 0.5, 128);
  };

  private pulsate = (duration: number, repeat: number) => {
    if (repeat === 0) {
      return;
    }
    const { height, width, x, y } = this._badgeBackground;
    const pulse = new Ellipse(this.scene, x, y, width * 3, height * 3, 0xffffff)
      .setScale(0)
      .setDepth(this._badgeBackground.depth);
    this.add(pulse);
    this.scene.tweens.add({
      duration,
      targets: pulse,
      ease: "cubic.out",
      props: {
        alpha: 0,
        scale: 1,
      },
      onComplete: () => {
        this.remove(pulse);
        pulse.destroy();
        this.pulsate(duration, repeat - 1);
      },
    });
  };
}
