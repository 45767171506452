import Tile = Phaser.Tilemaps.Tile;
import Vector2 = Phaser.Math.Vector2;
import Camera = Phaser.Cameras.Scene2D.Camera;
import { IIsometricTilemap } from "@game/engine/interfaces/IIsometricTilemap";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import Tilemap = Phaser.Tilemaps.Tilemap;

export default function getCenterPoint(
  tile: Tile,
  camera: Camera,
  scene: IIsometricTilemap,
) {
  const point: Vector2 = new Vector2();
  tile.tilemapLayer.tileToWorldXY(tile.x, tile.y, point, camera);

  // Get the center of the bottom of the tile
  point.set(
    point.x + tile.width * 0.5 - scene.tileOffset.x,
    point.y + tile.height - tile.width * 0.25 - scene.tileOffset.y,
  );
  return point;
}

export const ISOMETRIC_TILE_OFFSET = new Vector2(0, 32);

export function worldPositionAtTilePosition(
  map: Tilemap,
  tilePosition: TilePosition3D,
  isCentered: boolean = true,
) {
  const { tileHeight, tileWidth } = map;
  const tileX = tilePosition.x;
  const tileY = tilePosition.y;
  const x = (tileX - tileY) * (tileWidth * 0.5);
  const y = (tileX + tileY) * (tileHeight * 0.5);

  const point: Vector2 = new Vector2(
    x - ISOMETRIC_TILE_OFFSET.x,
    y - ISOMETRIC_TILE_OFFSET.y * tilePosition.z,
  );

  if (!isCentered) {
    return point;
  }

  // Get the center of the bottom of the tile
  point.set(point.x + tileWidth * 0.5, point.y + tileHeight - tileWidth * 0.25);
  return point;
}

export const TilePositionMath = {
  add: function (
    positionA: TilePosition3D,
    positionB: TilePosition3D,
  ): TilePosition3D {
    return {
      x: positionA.x + positionB.x,
      y: positionA.y + positionB.y,
      z: positionA.z + positionB.z,
    };
  },

  difference: function (
    positionA: TilePosition3D,
    positionB: TilePosition3D,
  ): TilePosition3D {
    return {
      x: positionA.x - positionB.x,
      y: positionA.y - positionB.y,
      z: positionA.z - positionB.z,
    };
  },

  equals: function (
    positionA: TilePosition3D,
    positionB: TilePosition3D,
  ): boolean {
    return (
      positionA.x === positionB.x &&
      positionA.y === positionB.y &&
      positionA.z === positionB.z
    );
  },

  sign: function (position: TilePosition3D): TilePosition3D {
    const { x, y, z } = position;
    position.x = Math.sign(x);
    position.y = Math.sign(y);
    position.z = Math.sign(z);
    return position;
  },
};
