import SceneComponent from "@game/engine/components/SceneComponent";
import {
  ChannelEvents,
  MatchEvents,
  Network,
} from "@game/engine/networking/Network";
import { MatchData, Presence } from "@heroiclabs/nakama-js";
import {
  dispatchEvent,
  EnumGameEvents,
  EnumNetworkEvents,
} from "@shared/Events";

interface IRPCGameFindMatchPayload {
  ids: string[];
}

export class NetworkedComponent extends SceneComponent {
  protected _decoder: TextDecoder;
  protected _hasJoinedMatch: boolean = false;
  protected readonly _matchData: { [key: string]: string } = {};
  protected readonly _module: string = "--no-module-set-somehow--";
  protected _network: typeof Network.Core.core = Network.Core.core;
  protected _presences: { [userId: string]: Presence };

  constructor(module: string, _matchData?: { [key: string]: string }) {
    super();
    this._module = module;
    this._matchData = _matchData ? _matchData : {};
    this._decoder = new TextDecoder();
    this._presences = {};

    this._network.on(MatchEvents.OnData, this.onMatchData, this);
    this._network.on(
      MatchEvents.OnPresenceJoined,
      this.onMatchPresenceJoined,
      this,
    );
    this._network.on(
      MatchEvents.OnPresenceLeft,
      this.onMatchPresenceLeft,
      this,
    );
    this._network.on(
      ChannelEvents.OnPresenceJoined,
      this.onChannelPresenceJoined,
      this,
    );
    this._network.on(
      ChannelEvents.OnPresenceJoined,
      this.onChannelPresenceLeft,
      this,
    );
  }

  public async connect() {
    if (Network.Core.isAuthenticated && Network.Core.currentMatchId) {
      await this._network.connect();
      await this._network.joinChat(this.getChatIdentifier());
      Network.Core.initListeners();
    } else {
      setTimeout(async () => await this.connect(), 1000);
    }
  }

  public async findAndJoinMatch(
    module: string,
    data: object = {},
  ): Promise<boolean> {
    try {
      const response = await this._network.rpc("findMatch", {
        ...{ module },
        ...{ data },
      });
      if (response && "payload" in response) {
        const payload: IRPCGameFindMatchPayload = <IRPCGameFindMatchPayload>(
          response.payload
        );
        if (payload && "ids" in payload) {
          const ids = payload.ids;
          if (ids.length > 0) {
            // See if we can join a random match, or just join the first one available
            if (ids.length > 1) {
              await this._network.joinMatch(
                ids[Phaser.Math.Between(0, ids.length - 1)],
              );
            } else {
              await this._network.joinMatch(ids[0]);
            }

            // If we got here, we did join a match
            this._hasJoinedMatch = true;
          }
        } else {
          console.error(
            "[NetworkedComponent] No ids in payload in response",
            payload,
          );
          return false;
        }
      } else {
        console.error("[NetworkedComponent] No payload in response");
        return false;
      }
    } catch (error) {
      this._hasJoinedMatch = false;
      console.error(error);
      if (error.message === "already joined") {
        dispatchEvent(EnumGameEvents.GameUiAlreadyJoinedError);
      }
      throw new Error("Failed to join match");
    }

    return true;
  }

  public async joinMatch(matchId: string) {
    return this._network.joinMatch(matchId);
  }

  protected decodeAndParseMatchData<T>(data: Uint8Array): T {
    return <T>JSON.parse(this._decoder.decode(data));
  }

  protected decodeMatchData(data: Uint8Array): string {
    return this._decoder.decode(data);
  }

  protected getChatIdentifier(): string {
    return Network.Core.currentMatchId;
  }

  protected onChannelPresenceJoined(data: {
    presence: Presence;
    channelId: string;
  }) {
    //redo the functionality via the scenes override
    // its a quick iteration
    dispatchEvent(EnumNetworkEvents.NetworkChannelPresenceJoined, data);
    // Override me
  }

  protected onChannelPresenceLeft(data: {
    presence: Presence;
    channelId: string;
  }) {
    //redo the functionality via the scenes override
    // its a quick iteration
    dispatchEvent(EnumNetworkEvents.NetworkChannelPresenceLeft, data);
    // Override me
  }

  protected onMatchData(matchData: MatchData) {
    // Override me
  }

  protected onPresenceJoined(presence: Presence) {
    // Override me
  }

  protected onPresenceLeft(presence: Presence) {
    // Override me
  }

  protected override onShutdown() {
    this._network.off(MatchEvents.OnData, this.onMatchData, this);
    this._network.off(
      MatchEvents.OnPresenceJoined,
      this.onMatchPresenceJoined,
      this,
    );
    this._network.off(
      MatchEvents.OnPresenceLeft,
      this.onMatchPresenceLeft,
      this,
    );
    this._network.off(
      ChannelEvents.OnPresenceJoined,
      this.onChannelPresenceJoined,
      this,
    );
    this._network.off(
      ChannelEvents.OnPresenceJoined,
      this.onChannelPresenceLeft,
      this,
    );

    super.onShutdown();
  }

  protected sendMatchState(opCode: number, data?: any, presences?: Presence[]) {
    this._network.sendMatchState(opCode, data, presences).finally();
  }

  private onMatchPresenceJoined(presence: Presence) {
    this._presences[presence.user_id] = presence;

    this.onPresenceJoined(presence);
  }

  private onMatchPresenceLeft(presence: Presence) {
    // Let people know before we remove them
    this.onPresenceLeft(presence);

    if (presence.user_id in this._presences) {
      delete this._presences[presence.user_id];
    }
  }
}
