import { getCharacterAvatar, getNftImage } from "@overlay/assets";
import { replaceMetadataProxy } from "@overlay/modules/helpers";
import BasicCache from "@shared/Cache";
import { NFTHelpers } from "@shared/nft";
import { ComponentInternalInstance } from "vue";
import { Network } from "@game/engine/networking/Network";
import { Configuration } from "@common/constants/Configuration";
import { Toast } from "@engine/notifications/Toast";
import { isMobileOrBelow } from "@shared/IsMobile";

export function rarityNumber(rarity: string): number {
  return (
    ["common", "uncommon", "rare", "super rare", "epic", "legendary"].indexOf(
      rarity.toLowerCase(),
    ) + 1
  );
}

export function seasonNumber(season: string): number {
  return ["season 1", "season 2"].indexOf(season.toLowerCase()) + 1;
}

export function rarityDots(rarity: number): Array<number> {
  const dots = Array(5);
  dots.fill(true, 0, rarity);
  dots.fill(false, rarity, 4);
  return dots;
}

export function getTraitValue(
  attributes: Array<{ trait_type: string; value: string }>,
  trait_type: string,
) {
  const temp = attributes.find(
    (item) => item.trait_type.toLowerCase() == trait_type,
  );
  if (temp != undefined) {
    return temp.value;
  }
  return null;
}

export function parentEmit(
  instance: ComponentInternalInstance,
  target: string,
  event: string,
  data: any,
) {
  let parent = instance?.parent;
  while (parent) {
    if (parent.type.__name?.toLowerCase() == target.toLowerCase()) {
      parent.emit(event, data);
      break;
    }
    parent = parent.parent;
  }
}

export function parentEmitTo(
  instance: ComponentInternalInstance,
  target: string,
  event: string,
  data: any,
) {
  let current = instance;
  let parent = current?.parent;
  while (parent) {
    if (parent.type.__name?.toLowerCase() == target.toLowerCase()) {
      current.emit(event, data);
      break;
    }
    parent = parent.parent;
    current = parent;
  }
}

export enum EnumCurrencyType {
  BTH = "BTH",
  TICKET = "TICKET",
  SBTH = "SBTH",
}

export function rpcIdToUrl(id: string, httpKey: boolean | string) {
  const url = new URL(
    (import.meta.env.VITE_SERVER_USE_HTTPS == "true" ? "https" : "http") +
      "://" +
      import.meta.env.VITE_SERVER_HOST.toString(),
  );
  url.port = import.meta.env.VITE_SERVER_PORT.toString();
  url.pathname = `/v2/rpc/${encodeURIComponent(String(id))}`;
  if (httpKey) {
    url.searchParams.append(
      "http_key",
      httpKey === true
        ? import.meta.env.VITE_SERVER_HTTP_KEY.toString()
        : httpKey.toString(),
    );
  }
  return url;
}

export async function rpcHttpKey(id: string, input?: object) {
  const resp = await fetch(rpcIdToUrl(id, true).toString(), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
    },
    body: JSON.stringify(JSON.stringify(input)),
  });
  const t = (await resp.json()).payload;
  return JSON.parse(t);
}

export async function copyToClipboard(text: string) {
  await navigator.clipboard.writeText(text);
  Toast.success("Copied to clipboard \n" + text);
}

export function requestFullscreen() {
  var elem = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
    //@ts-ignore
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    //@ts-ignore
    elem.webkitRequestFullscreen();
    //@ts-ignore
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    //@ts-ignore
    elem.msRequestFullscreen();
  }
}

/**
 *
 * @param value Character name or player id
 */
export async function getAvatarImageUrl(value: string): Promise<{
  imageUrl: string;
  isNftImage: boolean;
}> {
  if (value.trim() === Configuration.SystemUserId) {
    return { imageUrl: getCharacterAvatar("piccolo_bimbo"), isNftImage: false };
  }

  if (value.toLowerCase().indexOf("0x") === 0 || value.length == 64) {
    value = NFTHelpers.NFT.fromAddress(value).name;
  }
  let characterName = "Default Character",
    imageUrl = "",
    isNftImage = false;

  if (value.includes("-") && value.length == 36) {
    const metadata = (await BasicCache.playerMetadata[value])
      ? await BasicCache.playerMetadata[value]
      : await Network.Core.getUsersSquashedData(value);
    //failsafe
    if (metadata && metadata.activeCharacterAddress) {
      const nft = NFTHelpers.NFT.fromAddress(metadata.activeCharacterAddress);
      imageUrl = getCharacterAvatar(nft.name);
      characterName = nft.name;
    } else {
      imageUrl = getCharacterAvatar("Default character");
    }
  } else if (value != "undefined") {
    imageUrl = getCharacterAvatar(value);
    characterName = value;
  }
  if (imageUrl.includes("undefined")) {
    imageUrl = getNftImage(characterName);
    isNftImage = true;
  }
  if (imageUrl.includes("undefined")) {
    const networkNft: any = await Network.Core.getNftMetadata(
      NFTHelpers.NFT.fromName(value).address,
      "1",
    );

    try {
      let t = JSON.parse(networkNft.payload.metadata);
      imageUrl = replaceMetadataProxy(t?.image);
    } catch (e) {
      console.error(e);
    }
  }
  return { imageUrl, isNftImage };
}

/**
 *
 * @param classList space-separated or array
 * @param prefix ex: "btn:" to filter out classes starting with "btn:", "!btn:" to filter out classes NOT starting with "btn:"
 */
export function extractCSSClasses(
  classList: string | Array<string>,
  prefix: string,
  removePrefix?: boolean,
) {
  if (!classList) {
    return "";
  }
  if (typeof classList === "string") {
    classList = classList.split(" ");
  }
  const _prefix = prefix.slice(1);
  return classList
    .filter((c) =>
      !prefix.includes("!") ? c.includes(_prefix) : !c.includes(_prefix),
    )
    .join(" ")
    .replaceAll(_prefix, "");
}

export function compactNumber(value: number, force?: boolean) {
  return isMobileOrBelow() || force
    ? Intl.NumberFormat("en-US", {
        notation: "compact",
        maximumFractionDigits: 1,
      }).format(value)
    : value;
}
