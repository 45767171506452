import { TNakamaUser } from "@shared/types/nakama/NakamaTypes";
import EventEmitter from "eventemitter3";
import { ZodType, ZodTypeDef } from "zod";
import SceneComponent from "../SceneComponent";
import NetworkLike from "./NetworkLike";
import { LeaderboardRecord } from "@heroiclabs/nakama-js";
import { IHTTPRequestResponse } from "@shared/interfaces/IHTTPRequestResponse";

// Implement to create a backend for your minigame
export interface MinigameBackend<State> {
  initInputCallbacks(input: InputSource<State>): void;

  onCreate(): { state: State };

  onUpdate(ctx: MatchContext<State>): void;

  onUserJoin(ctx: MatchContext<State>): void;

  onUserLeave(ctx: MatchContext<State>): void;
}

/**
 * @deprecated Use MinigameBackend interface instead
 */
export type MatchHandler<State> = MinigameBackend<State>;

// NetworkedComponent replacement for dev and local testing
// Basically to simulate minigame backends in your browser
// TODO: player leave, multiple players support
export class BackendSimulationComponent
  extends SceneComponent
  implements NetworkLike
{
  private _ctx: MatchContext<unknown>;
  private _matchEventsEmitter = new EventEmitter<"userJoined" | "userLeft">();
  private _messageQueue = new Array<{
    eventName: string;
    data: Record<string, unknown>;
  }>();
  private _state: unknown = null;
  private readonly _testUserId = "88918cc6-bd1c-4ab4-978d-4d2d8bc52351"; // Random numbers. No hidden meaning
  private _userId = "";
  private _userStorage: Record<string, unknown> = {};

  private _hasJoinedMatch = false;

  get hasJoinedMatch(): boolean {
    return this._hasJoinedMatch;
  }

  constructor(private _handlerFactory: () => MinigameBackend<unknown>) {
    super();

    this.isTickEnabled = true;

    const self = this;
    this._ctx = {
      get state() {
        return self._state;
      },
      output: new EventEmitter(),
      outputsPerUserId: self.createOutputsPerUserId(),
      get userId() {
        return self._userId;
      },
      request(
        url: string,
        method: RequestMethod,
        headers?: { [p: string]: string },
        body?: string,
        timeout?: number,
      ) {},
      addToLeaderboard(
        leaderboardId: string,
        userId: string,
        scoreToAdd: number,
        subscoreToAdd: number,
      ): LeaderboardRecord {
        return null;
      },
      adjustTickets(userId: string, ticketsDelta: number): number {
        console.log("Trying to adjust tickets for " + userId, ticketsDelta);
        return ticketsDelta;
      },
      getStorageValue(userId, key) {
        if (userId !== self._userId) {
          console.warn(
            `Trying to get storage value for user '${String(
              userId,
            )}' but user is not in the match`,
          );
          return undefined;
        }
        return self._userStorage[key];
      },
      setStorageValue(userId, key, value) {
        if (userId !== self._userId) {
          console.warn(
            `Trying to set storage value for user '${String(
              userId,
            )}' but user is not in the match`,
          );
          return;
        }
        self._userStorage[key] = value;
      },
      rewardTicketsForMultiplayerMatch(
        userId: string,
        gameLength: number,
        playerPosition: number,
        numberOfPlayers: number,
      ): number {
        console.log(
          "Trying to reward tickets to " + userId,
          gameLength,
          playerPosition,
          numberOfPlayers,
        );
        return 0;
      },
    };
  }

  async authenticateAndJoin(): Promise<void> {
    this._userId = "";
    this.prepareHandler((handler) => (this._state = handler.onCreate().state));

    this._userId = this._testUserId;
    this.prepareHandler((handler) => handler.onUserJoin(this._ctx));

    this._hasJoinedMatch = true;
  }

  getMatchEventsEmitter(): EventEmitter<"userJoined" | "userLeft"> {
    return this._matchEventsEmitter;
  }

  getMessagesEmitter(): EventEmitter {
    return this._ctx.output;
  }

  getUsersData(userIds: string | string[]): Promise<TNakamaUser[]> {
    throw new Error("Method not implemented.");
  }

  send(eventName: string, data: Record<string, unknown>): void {
    if (!this._hasJoinedMatch) {
      return console.error(
        "You forgot to call authenticateAndJoin. It's required, even in simulation",
      );
    }
    this._messageQueue.push({ eventName, data });
  }

  sendChatMessage(message: string): void {
    throw new Error("Method not implemented.");
  }

  tick(_deltaSeconds: number, _deltaTime: number, _time?: number): void {
    // Run server-side message handlers
    this._userId = this._testUserId;
    this.prepareHandler((_handler, inputSource) => {
      inputSource.updateDeps(this._ctx);

      // I want to really remove a message from the queue before emitting
      // So in case "emit" throws we forget the message
      while (this._messageQueue.length) {
        const msg = this._messageQueue.shift();
        inputSource.emitter.emit(msg.eventName, msg.data);
      }
    });

    // Run server-side update
    this._userId = "";
    this.prepareHandler((handler) => handler.onUpdate(this._ctx));
  }

  private createOutputsPerUserId(): Record<
    string,
    EventEmitter<string | symbol, any>
  > {
    return new Proxy(
      {},
      {
        get: (target, key) => {
          if (!target[key]) {
            const emitter = new EventEmitter();
            emitter.emit = (eventName, data) => {
              const userExists = this._testUserId === key;
              if (userExists) {
                // For now we don't support multiple players
                // So just emit for everyone. Cause basically in simulation we don't have multiple players
                // It may be changed in the future
                this._ctx.output.emit(eventName, data);
              } else {
                // Probably using generic logger interface would be better.
                // Also, I don't want to throw exceptions here cause we don't want the real server to stop
                console.warn(
                  `Trying to emit event '${String(
                    eventName,
                  )}' to user '${String(key)}' but user is not in the match`,
                );
              }
              return true;
            };
            target[key] = emitter;
          }
          return target[key];
        },
      },
    );
  }

  // The real Nakama may re-create handler each time so we simulate this behavior too
  private prepareHandler(
    callback: (
      handler: MinigameBackend<unknown>,
      inputSource: SimulationInputSource,
    ) => void,
  ) {
    const inputSource = new SimulationInputSource();
    const handler = this._handlerFactory();
    handler.initInputCallbacks(inputSource);
    callback(handler, inputSource);
  }
}

// Please keep this up-to-date with matchHandler.ts (bithotel-server)
// Will be moved to bithotel-shared at some point.
type RequestMethod = "get" | "post" | "put" | "patch" | "head";

export interface MatchContext<State> {
  // Get value from storage
  readonly getStorageValue: (userId: string, key: string) => unknown;
  readonly label: Record<string, unknown>;
  // Emit messages to all connected clients
  readonly output: EventEmitter;
  // ctx.outputsPerUserId[userId].emit(eventName, data)
  readonly outputsPerUserId: Record<string, EventEmitter>;

  // Emit messages to specific client. Example:
  // Set value in storage
  readonly setStorageValue: (
    userId: string,
    key: string,
    value: unknown,
  ) => void;
  // User-defined state
  readonly state: State;
  // User ID or '' for some cases (i.e. onUpdate)
  readonly userId: string;

  addToLeaderboard(
    leaderboardId: string,
    userId: string,
    scoreToAdd: number,
    subscoreToAdd: number,
  ): LeaderboardRecord;

  adjustTickets(userId: string, ticketsDelta: number): number;

  error(message: string): void;

  info(message: string): void;

  numberOfTicketsForUserByUserId(userId: string): number;

  request(
    url: string,
    method: RequestMethod,
    headers?: { [header: string]: string },
    body?: string,
    timeout?: number,
  ): IHTTPRequestResponse;

  rewardTicketsForMultiplayerMatch(
    userId: string,
    gameLength: number,
    playerPosition: number,
    numberOfPlayers: number,
  ): number;

  updateMatchLabel(label: string | object);

  warn(message: string): void;
}

// Please keep this up-to-date with matchHandler.ts (bithotel-server)
// Will be moved to bithotel-shared at some point.
export interface InputSource<State> {
  on<Input>(
    eventName: string,
    callback: (ctx: MatchContext<State>, data: Input) => void,
    inputSchema: ZodType<Input>,
  ): void;
}

// Pretty like NakamaInputSource, but not the same
// It's possible to refactor a bit (use ctx.logger instaed of console.log) and then move to bithotel-shared
class SimulationInputSource implements InputSource<unknown> {
  emitter = new EventEmitter();
  private _handlerCtx?: MatchContext<unknown>;

  on<Input>(
    eventName: string,
    callback: (ctx: MatchContext<unknown>, data: Input) => void,
    inputSchema: ZodType<Input, ZodTypeDef, Input>,
  ): void {
    this.emitter.on(eventName, (rawData) => {
      const parseResult = inputSchema.safeParse(rawData);
      if (parseResult.success) {
        if (this._handlerCtx) {
          callback(this._handlerCtx, parseResult.data);
        }
      } else {
        // it's logger.error in Nakama
        console.error("Schema failed to parse message '%s'", eventName);
      }
    });
  }

  updateDeps(handlerCtx: MatchContext<unknown>) {
    this._handlerCtx = handlerCtx;
  }
}
