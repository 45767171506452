export default [
  {
    address: ["0x536148e5b6caf1D0B5C321CDcD5819054d479c50"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Common",
        },
        {
          trait_type: "Replicas",
          value: "1120",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "The perfect place to begin as a resident of the Bit Hotel. Small, but enough square meters to decorate with some of your favorite furniture. Almost all of the popular hotel guests started in this room and worked their way up. The perfect place to start your Bit Hotel adventure!",
      name: "Starter Room",
    },
    type: "room",
  },
  {
    address: ["0x69a5f8434DeF56b1f752Ab1680079D1d42379dF0"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Common",
        },
        {
          trait_type: "Replicas",
          value: "500",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "A comfortable entry-level room in the Bit Hotel! The Two Person Room is a minimalistic and clean room with just enough space to have a few friends over and have some fun. Perfect for lovebirds sharing their first flat together, too.",
      name: "Two Person Room",
    },
    type: "room",
  },
  {
    address: ["0x0336c2A938948C7Ebe8cCbB14328EFDe0663EED5"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Common",
        },
        {
          trait_type: "Replicas",
          value: "500",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "If you are looking for a larger room, with beautiful marble accents, this is definitely something for you. The Family Room is an entry-level hotel room, but with more than enough space to accommodate your friends. The room is most commonly used by groups of friends that want to host a small party. Just add a jukebox, disco ball and colourful dance floor and you're good to go.",
      name: "Family Room",
    },
    type: "room",
  },
  {
    address: ["0x211c219E3285CAe380f3827ec543a388B44F1610"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Uncommon",
        },
        {
          trait_type: "Replicas",
          value: "920",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "The Starter Studio is what most hotel guests acquire when they start to do well in-game. All leaderboard legends had one and worked their way up. The mezzanine ensures you have a cool extra space for your bed (or hammock!)",
      name: "Starter Studio",
    },
    type: "room",
  },
  {
    address: ["0xfBF126db4C6232C8255C7cCd3950F4A697275540"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Uncommon",
        },
        {
          trait_type: "Replicas",
          value: "360",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "Our L-shaped Comfortable Studio might be something for you, is for those looking for a little more space and comfort. It has 50 tiles to make it the coziest studio you could imagine. This Comfortable Studio has more than enough room for a number of cool pieces of furniture, and to host mini-games.",
      name: "Comfortable Studio",
    },
    type: "room",
  },
  {
    address: ["0xDa19E9B3Bdd270B4585976c2701eD9Bec490F951"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Uncommon",
        },
        {
          trait_type: "Replicas",
          value: "360",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "This one’s for the extravaganzas among us that don’t want to splash all their money on upscale condos. Luxurious look and feel, compact but spacious. The Extravagant Studio is our entry option to upscale real estate in the Bit Hotel ecosystem. It’s great comfort at a fraction of the cost.",
      name: "Extravagant Studio",
    },
    type: "room",
  },
  {
    address: ["0xaf70A2557AbEA98Af0Afe8Ed42F9619711B51DDA"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Rare",
        },
        {
          trait_type: "Replicas",
          value: "160",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "Ah, the magnificent Upscale Condos. These are among the first estate options labeled Rare. An epic mezzanine, a great view from your window and an even more impressive set of bragging rights come with it. 70 tiles of space that you could fill up with any furniture you could imagine.",
      name: "Medium Upscale Studio",
    },
    type: "room",
  },
  {
    address: ["0x38f22551ab0bBDc8602101a34621EDD430e0A2b8"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Rare",
        },
        {
          trait_type: "Replicas",
          value: "160",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "The Large Opulent Condo is a rare find, and you can see why; Large windows, a built-in flatscreen tv and 90 tiles of space make this estate a real eye catcher. This large condominium will certainly leave your guests impressed and jealous. The successful traders, leaderboard winners and NFT collectors in the Bit Hotel often fight over this condo.",
      name: "Large Opulent Condo",
    },
    type: "room",
  },
  {
    address: ["0x6aBF540461046130600e660A172708c5FF7a2556"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Super Rare",
        },
        {
          trait_type: "Replicas",
          value: "60",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "Can you smell the grandeur? You might’ve just walked into one of the first in our Super Rare Penthouse series: the Monumental Majestic Penthouse! A true monumental piece of real estate that can only be owned by the very lucky few. Host a party here and you're a true magnet of prestigious hotel guests.",
      name: "Monumental Majestic Penthouse",
    },
    type: "room",
  },
  {
    address: ["0x34460e3195b61a221a616F904900d48396493aA6"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Super Rare",
        },
        {
          trait_type: "Replicas",
          value: "60",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "Why only have one floor if you can have three? Our Grandiose Stately Penthouse features three floors with heaps of space to present your most luxurious items and furniture. A stately getaway from the masses, a truly fine piece of super rare real estate that is often sold before it hits the open market. Loved by the Hotel elite and sought after by all.",
      name: "Grandiose Stately Penthouse",
    },
    type: "room",
  },
  {
    address: ["0xa06C989e4aD52c0039606FA051FDACf17580591F"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Super Rare",
        },
        {
          trait_type: "Replicas",
          value: "40",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "A two-floor penthouse that becomes partly pre-furnished and will leave everyone who joins you there in awe. With views of the entire surroundings of the Bit Hotel, this marvel is only owned by Hotel elites and celebrities.",
      name: "Luxurious Posh Penthouse",
    },
    type: "room",
  },
  {
    address: ["0x8BFaB7b2031f5ad1D7d4e3b74C1CAB7A476600a0"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Epic",
        },
        {
          trait_type: "Replicas",
          value: "40",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "One of the only suites in the Bit Hotel, the créme de la créme, the cream of the crop, the very top of the line when it comes to Hotel assets. With a legendary view outside and a private elevator this suite is one of a kind.",
      name: "Imposing Deluxe Suite",
    },
    type: "room",
  },
  {
    address: ["0xd53dC5198b43F547A6b8e18CF1412859FA7fa975"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Epic",
        },
        {
          trait_type: "Replicas",
          value: "40",
        },
        {
          trait_type: "Drop",
          value: "Season 1",
        },
      ],
      description:
        "The pinnacle of luxury real estate in the Bit Hotel metaverse. Often owned by billionaire collectors or overly ambitious hotel guests that have the right connections and can pull some strings. A great investment due to the scarce availabilty throughout the hotel and the ultimate form of bragging rights.",
      name: "Grand Palatial Suite",
    },
    type: "room",
  },
  {
    address: ["0x61f24ff49423db20be6d92a27fdeda523012c7a8"],
    metadata: {
      attributes: [
        {
          trait_type: "Rarity",
          value: "Common",
        },
        {
          trait_type: "Replicas",
          value: "500000",
        },
        {
          trait_type: "Drop",
          value: "Season 2",
        },
      ],
      description: "Another Basic room",
      name: "Basic Room",
    },
    type: "room",
  },
];
