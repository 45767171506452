import InteractiveFurnitureObject from "@game/mini/room/objects/InteractiveFurnitureObject";
import CustomizableRoomScene from "@game/mini/room/CustomizableRoomScene";
import { TInteractiveFurnitureConfig } from "@shared/consts/InteractiveFurniture";
import { MinigameValues } from "@common/constants/MinigameValues";
import { dispatchEvent, EnumApplicationEvents } from "@shared/Events";
import GameScene from "@engine/scenes/GameScene";
import Matchmaker from "@engine/networking/Matchmaker";
import { mobilePortrait } from "@/mobile/postMessage";

const NFTToMinigameModule = {
  "0xd8f20e1730009ac75efece5681e6a9e8abede7cb": "mines",
  "local-fruity-memory-arcade-machine": "memory",
  "local-cryptobomber-arcade-machine": "cryptobomberv2",
  "local-plinko-arcade-machine": "plinko",
  "local-stampwars-arcade-machine": "stampwars",
};

export default class FOArcadeMachine extends InteractiveFurnitureObject {
  readonly offlineActivation: boolean = true;

  constructor(
    scene: CustomizableRoomScene,
    name: string,
    id: string,
    config: TInteractiveFurnitureConfig,
  ) {
    super(scene, name, id, config);
  }

  public override activate() {
    const module = NFTToMinigameModule[this.id.toLowerCase()];
    const minigameValues = MinigameValues[module];

    if (this.id.toLocaleLowerCase() == "local-plinko-arcade-machine") {
      console.log("TESTTTT");
      mobilePortrait();
    }

    if (minigameValues.MaxPlayers === 1) {
      dispatchEvent(EnumApplicationEvents.ApplicationStartMinigame, {
        sceneKey: minigameValues.SceneKey,
        senderSceneKey: GameScene.SceneKey,
        params: {
          matchData: {
            currency: "tickets",
            playableAmount: 0,
          },
        },
      });
    } else {
      Matchmaker.instance
        .queueForMatch(module, minigameValues.SceneKey, {
          currency: "tickets",
          playableAmount: 0,
        })
        .finally();
    }
  }
}
