import SceneComponent from "@engine/components/SceneComponent";
import GameScene from "@engine/scenes/GameScene";
import { Pinch } from "phaser3-rex-plugins/plugins/gestures";
import Button from "phaser3-rex-plugins/plugins/input/button/Button";
import VirtualJoyStick from "phaser3-rex-plugins/plugins/virtualjoystick";
import OverlayScene, {
  ScreenAnchorPosition,
} from "@engine/scenes/OverlayScene";

const MARGIN_X = 32;
const MARGIN_Y = 32;
const SCREEN_HEIGHT_RATIO = 0.1;
const RADIUS_MIN = 24;
const RADIUS_MAX = 70;
// const CONTAINER_SCALE = 1;

type TGesturesConfig = {
  pinchZoom: boolean;
  pointerDragCamera: boolean;
  zoomLimits?: { max: number; min: number };
};
type TJoystickConfig = {
  enable: boolean;
  relocateOnTouch?: boolean;
};
type TButtonConfig = {
  enable: boolean;
  callback?: () => void;
  relocateOnTouch?: boolean;
};
export default class TouchControlsSceneComponent extends SceneComponent {
  public get pinching(): boolean {
    return this._pinching;
  }

  private _pinch: Pinch;
  private _pinching: boolean = false;
  private _uiJoystick: VirtualJoyStick;
  private _uiButton: Button;
  private _buttonContainer: Phaser.GameObjects.Container;
  // private _uiJoystickContainer: Phaser.GameObjects.Container;
  private _createdRadius: number;
  // private _holdingButton: boolean = false;
  constructor(
    gesturesConfig: TGesturesConfig,
    joystickConfig: TJoystickConfig,
    buttonConfig: TButtonConfig,
  );
  constructor(
    gesturesConfig: TGesturesConfig,
    joystickConfig: TJoystickConfig | boolean,
    buttonConfig: TButtonConfig | boolean,
  );
  constructor(
    private _gestures: TGesturesConfig | boolean,
    private _joystick: TJoystickConfig | boolean,
    private _button: TButtonConfig | boolean,
  ) {
    super();

    //@ts-ignore
    // window.c = this;
  }
  /**
   * ! TODO add failsafe get for joystick
   */
  public get joystick() {
    return this._uiJoystick;
  }
  public get button() {
    return this._uiButton;
  }
  public getJoystickCursorKeys() {
    if (this._uiJoystick) {
      return this._uiJoystick.createCursorKeys();
    }
    return {};
  }
  public onButtonClick(callback: () => void) {
    if (this._uiButton) {
      this._uiButton.on("click", callback);
    }
  }

  public onZoom() {
    // if (this._uiJoystick) {
    //   this._uiJoystick.setPosition(
    //     this.scene.cameras.main.centerX -
    //       this.scene.cameras.main.displayWidth * 0.5 +
    //       this._createdRadius * 8,
    //     this.scene.cameras.main.centerY +
    //       this.scene.cameras.main.displayHeight * 0.5 -
    //       this._createdRadius * 8,
    //   );
    //   this._uiJoystick.setVisible(true);
    // }
    // if (this._buttonContainer) {
    //   this._buttonContainer.setPosition(
    //     this.scene.cameras.main.centerX +
    //       this.scene.cameras.main.displayWidth * 0.5 -
    //       this._createdRadius * 7,
    //     this.scene.cameras.main.centerY +
    //       this.scene.cameras.main.displayHeight * 0.5 -
    //       this._createdRadius * 7,
    //   );
    //   this._buttonContainer.setVisible(true);
    // }
  }
  protected onSceneSet(scene?: GameScene) {
    super.onSceneSet(scene);
    if ("ontouchstart" in window && scene) {
      this.initUiElements(scene);
      const relocateJoystick =
        typeof this._joystick == "object" && this._joystick.relocateOnTouch;
      const relocateButton =
        typeof this._button == "object" && this._button.relocateOnTouch;
      if (relocateJoystick || relocateButton) {
        this.initListeners(relocateJoystick, relocateButton);
      }
      setTimeout(() => {
        this.onZoom();
      }, 300);
    }
  }
  private createPinch(scene: GameScene, gestures: TGesturesConfig) {
    this._pinch = new Pinch(scene, { threshold: 24 });
    const camera = scene.cameras.main;

    if (gestures.pinchZoom) {
      this._pinch.on("pinch", () => (this._pinching = true), this);
      this._pinch.on("pinchend", () => (this._pinching = false), this);
      this._pinch.on(
        "pinch",
        (pinch: Pinch) => {
          let zoom = Phaser.Math.RoundTo(camera.zoom * pinch.scaleFactor, -3);

          if (gestures.zoomLimits) {
            zoom = Phaser.Math.Clamp(
              zoom,
              gestures.zoomLimits.min,
              gestures.zoomLimits.max,
            );
          }

          camera.zoom = zoom;
          this.onZoom();
        },
        this.scene,
      );
      this._pinch.on(
        "pinchend",
        (pinch: Pinch) => {
          if (gestures.zoomLimits) return; // Should stay within limits

          const targetValues = [1, 0.5]; // 0.75 zoomed out, 1 zoomed default, 1.5 zoomed in
          const intervalTime = 10; // Time interval for updates in milliseconds
          const closestPoint = findClosestPoint(camera.zoom, targetValues); // Find the closest point
          const duration = Math.abs(closestPoint - camera.zoom) * 1000; // 0.1 difference will take 0.5 a second
          const steps = duration / intervalTime; // Number of steps needed
          const stepValue = (closestPoint - camera.zoom) / steps; // Value to decrement in each step
          const interval = setInterval(updateVariable, intervalTime);

          function updateVariable() {
            camera.zoom += stepValue; // Decrement the variable value

            if (stepValue > 0 && camera.zoom >= closestPoint) {
              camera.zoom = closestPoint; // Ensure the variable reaches the exact closest point
              clearInterval(interval); // Stop the interval
            } else if (stepValue < 0 && camera.zoom <= closestPoint) {
              camera.zoom = closestPoint; // Ensure the variable reaches the exact closest point
              clearInterval(interval); // Stop the interval
            }
          }

          function findClosestPoint(value, points) {
            return points.reduce(function (prev, curr) {
              return Math.abs(curr - value) < Math.abs(prev - value)
                ? curr
                : prev;
            });
          }
          updateVariable();
        },
        this.scene,
      );
    }

    if (gestures.pointerDragCamera) {
      this._pinch.on(
        "drag1",
        (pinch: Pinch) => {
          // if (pinch.pointers.length > 1) {
          camera.scrollX -= pinch.drag1Vector.x / camera.zoom;
          camera.scrollY -= pinch.drag1Vector.y / camera.zoom;
          // }
        },
        this.scene,
      );
    }
  }
  private calculateOptimalDimensions() {
    const overlayScene = this.scene.scene.get("OverlayScene") as OverlayScene;
    const { width: screenWidth, height: screenHeight } =
      overlayScene.scale.gameSize;
    const radius =
      Phaser.Math.Clamp(
        (Math.min(screenHeight, screenWidth) * SCREEN_HEIGHT_RATIO) / 2,
        RADIUS_MIN,
        RADIUS_MAX,
      ) * 2.5;
    const [marginX, marginY] = [MARGIN_X, MARGIN_Y];
    return {
      screenWidth,
      screenHeight,
      radius,
      marginX,
      marginY,
    };
  }

  private createJoystick() {
    const { radius } = this.calculateOptimalDimensions();
    const overlayScene = this.scene.scene.get("OverlayScene") as OverlayScene;

    this._createdRadius = radius;
    this._uiJoystick = new VirtualJoyStick(overlayScene, {
      x: 0,
      y: 0,
      radius: radius,
      base: overlayScene.add
        .circle(0, 0, radius, 0x292e40, 0.5)
        .setDepth(10_000)
        .setStrokeStyle(2, 0xffffff),
      thumb: overlayScene.add
        .circle(0, 0, radius * 0.33, 0xaddcea, 0.7)
        .setDepth(10_000),
      dir: 3, // 'up&down'|0|'left&right'|1|'4dir'|3|'8dir'|3
      // forceMin: 16,
      // enable: true
    });

    overlayScene.addAnchoredObject(this._uiJoystick, {
      x: { anchor: ScreenAnchorPosition.Relative, value: 0.125 },
      y: { anchor: ScreenAnchorPosition.Edge, value: -radius * 1.5 },
    });
  }
  private createButton(callback?: () => void) {
    const { radius, containerScale } = this.calculateOptimalDimensions();
    const overlayScene = this.scene.scene.get("OverlayScene") as OverlayScene;

    this.scene.load.image("bomb", "assets/mini/cryptobomber/bomb.png");
    this._buttonContainer = overlayScene.add
      .container(
        this.scene.cameras.main.worldView.right,
        this.scene.cameras.main.worldView.bottom,
      )
      .setSize(radius, radius);

    const buttonCircle = new Phaser.GameObjects.Arc(
      overlayScene,
      0,
      0,
      radius,
      0,
      360,
      false,
      0x292e40,
      0.5,
    );
    buttonCircle.setStrokeStyle(2, 0xffffff);
    this._buttonContainer.add(buttonCircle); // .setVisible(false);

    const buttonIcon = new Phaser.GameObjects.Image(overlayScene, 0, 0, "bomb");
    //Set the origin to approximate visual center (24,36)/(61,63)
    buttonIcon.setOrigin(0.42, 0.54);
    buttonIcon.setDisplaySize(radius, radius);
    this._buttonContainer.add(buttonIcon);
    // this.scene.input.addPointer(1);
    this._uiButton = new Button(this._buttonContainer, { mode: 0 });

    overlayScene.addAnchoredObject(this._buttonContainer, {
      x: { anchor: ScreenAnchorPosition.EdgeRelative, value: -0.125 },
      y: { anchor: ScreenAnchorPosition.Edge, value: -radius * 1.5 },
    });

    this.onButtonClick(() => {
      buttonCircle.fillAlpha = 0.7;
      setTimeout(() => {
        buttonCircle.fillAlpha = 0.5;
      }, 100);
      if (callback) {
        callback();
      }
    });
  }

  private initUiElements(scene?: GameScene) {
    //Check if device supports touch
    if (typeof this._gestures == "object") {
      this.createPinch(scene, this._gestures);
    }

    if (typeof this._joystick == "object" && this._joystick.enable) {
      this.createJoystick();
      this.enableHideJoystickOnTouch();
    }

    if (typeof this._button == "object" && this._button.enable) {
      this.createButton(this._button.callback);
    }
    //   this.initListeners();
  }
  private enableHideJoystickOnTouch() {
    this.scene.input.on(
      "pointerdown",
      (pointer: Phaser.Input.Pointer) => {
        if (pointer.x < this.scene.scale.gameSize.width / 2) {
          this.joystick.setVisible(false);
        } else {
          this.joystick.setVisible(true);
        }
      },
      this.scene,
    );
    this.scene.input.on(
      "pointerup",
      (pointer: Phaser.Input.Pointer) => {
        this.joystick.setVisible(true);
      },
      this.scene,
    );
  }
  private initListeners(relocateJoystick: boolean, relocateButton: boolean) {
    const { radius, containerScale } = this.calculateOptimalDimensions();
    this.scene.input.on(
      "pointerdown",
      (pointer: Phaser.Input.Pointer) => {
        //only bottom part of screen
        if (pointer.worldY > this.scene.scale.gameSize.height / 2) {
          //left side Moving the joystick to touch location
          if (
            relocateJoystick &&
            pointer.x < this.scene.scale.gameSize.width / 2
          ) {
            // this._joyStick.setPosition(pointer.position.x * 0.5, 150);
          } else if (
            relocateButton &&
            pointer.x > this.scene.scale.gameSize.width / 2
          ) {
            const radiusScaled = radius * containerScale;
            //right side move button to touch location if outside button
            if (
              pointer.worldX < this._buttonContainer.x - radiusScaled ||
              pointer.worldX > this._buttonContainer.x + radiusScaled ||
              pointer.worldY < this._buttonContainer.y - radiusScaled ||
              pointer.worldY > this._buttonContainer.y + radiusScaled
            ) {
              //   this._buttonContainer.setPosition(pointer.worldX, pointer.worldY);
              //   this._button.emit("click");
            }
          }
        }
      },
      this.scene,
    );
  }
  protected onShutdown() {
    const overlayScene = this.scene.scene.get("OverlayScene") as OverlayScene;

    overlayScene.removeAnchoredObject(this._uiJoystick);
    overlayScene.removeAnchoredObject(this._buttonContainer);

    super.onShutdown();

    if (this._pinch) {
      this._pinch.shutdown();
    }

    if (this._uiButton) {
      this._uiButton.shutdown();
    }
  }
}
