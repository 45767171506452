import GameScene from "@game/engine/scenes/GameScene";
import { TNakamaUser } from "@shared/types/nakama/NakamaTypes";
import EventEmitter from "eventemitter3";
import SceneComponent from "../SceneComponent";
import {
  BackendSimulationComponent,
  MinigameBackend,
} from "./BackendSimulationComonent";
import NetworkedComponent from "./NetworkedComponent";
import NetworkLike from "./NetworkLike";
import CustomizableRoomScene from "@game/mini/room/CustomizableRoomScene";

export default class MinigameBackendComponent
  extends SceneComponent
  implements NetworkLike
{
  public static EventTypes = {
    Connected: "connected",
  };

  protected declare _scene: GameScene;

  private readonly _isSimulated: boolean = false;
  private _module: string;
  private impl: NetworkLike & SceneComponent;

  // Online mode
  constructor(minigameName: string);

  // Offline mode. Backend simulated locally, in your browser
  constructor(handlerFactory: () => MinigameBackend<unknown>);

  constructor(arg: unknown) {
    super();
    this.isTickEnabled = true;

    if (typeof arg === "string") {
      this._module = arg;
      this.impl = new NetworkedComponent(arg);
    } else {
      this._isSimulated = true;
      this.impl = new BackendSimulationComponent(
        arg as () => MinigameBackend<unknown>,
      );
    }

    this.initialize().finally();
  }

  get hasJoinedMatch(): boolean {
    return this.impl.hasJoinedMatch;
  }

  public async initialize() {
    if (!this._isSimulated) {
      const networkComponent = this.impl as NetworkedComponent;
      await networkComponent.initialize();
      this._module === "room"
        ? await networkComponent.joinRoom(
            (this._scene as CustomizableRoomScene).roomId,
          )
        : await networkComponent.join();
      this.emit(
        MinigameBackendComponent.EventTypes.Connected,
        networkComponent.currentUserId,
      );
    }
  }

  authenticateAndJoin(
    email: string = "",
    password: string = "",
  ): Promise<void> {
    return this.impl.authenticateAndJoin(email, password);
  }

  getUsersData(userIds: string | string[]): Promise<TNakamaUser[]> {
    return this.impl.getUsersData(userIds);
  }

  sendChatMessage(message: string): void {
    return this.impl.sendChatMessage(message);
  }

  send(eventName: string, data: Record<string, unknown> = {}): void {
    return this.impl.send(eventName, data);
  }

  getMessagesEmitter(): EventEmitter {
    return this.impl.getMessagesEmitter();
  }

  getMatchEventsEmitter(): EventEmitter<"userJoined" | "userLeft"> {
    return this.impl.getMatchEventsEmitter();
  }

  tick(deltaSeconds: number, deltaTime: number, time?: number): void {
    this.impl.tick(deltaSeconds, deltaTime, time);
  }

  protected onSceneSet() {
    this.impl.scene = this.scene;
  }

  protected onShutdown() {
    this.impl.shutdown();
  }
}
