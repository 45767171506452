import IPostTickable from "@game/engine/interfaces/IPostTickable";
import { Scene } from "phaser";
import EntityComponent from "./components/EntityComponent";
import ITickable from "./interfaces/ITickable";
import GameScene from "./scenes/GameScene";
import { EntityComponentV2 } from "@engine/components/EntityComponentV2";
import DESTROY = Phaser.GameObjects.Events.DESTROY;

export default class Entity
  extends Phaser.GameObjects.Container
  implements IPostTickable, ITickable
{
  public componentV2s: Set<EntityComponentV2>;
  public components: Set<EntityComponent>;
  public isRegisteredAsPostTickable: boolean;
  public isRegisteredAsTickable: boolean;
  public declare scene: GameScene;
  public tickableComponents: Set<EntityComponent>;

  protected _hasTickEnabledComponents: boolean;

  constructor(scene: Scene, x?: number, y?: number) {
    super(scene, x, y);

    this.isRegisteredAsPostTickable = false;
    this.isRegisteredAsTickable = false;
    this._hasTickEnabledComponents = false;

    scene.events.once("shutdown", this.onShutdown, this);
    this.once(DESTROY, this.onDestroy, this);
    this.components = new Set<EntityComponent>();
    this.componentV2s = new Set<EntityComponentV2>();
    this.tickableComponents = new Set<EntityComponent>();
  }

  public addComponent<C extends EntityComponent>(component: C): C {
    // Register us as the owner
    component.owner = this;

    // Store it
    this.components.add(component);

    // Check if the component needs ticking
    if (component.isTickEnabled) {
      // If this components needs ticks, then we need ticks as well
      if (!this.isRegisteredAsTickable) {
        this.registerForTick();
        this._hasTickEnabledComponents = true;
      }

      // Add it to the seperate list
      this.tickableComponents.add(component);
    }

    return component;
  }

  public override addedToScene() {
    super.addedToScene();

    for (const component of this.componentV2s) {
      component.onAddedToScene();
    }
  }

  public createAndAddComponent<C extends EntityComponentV2>(
    ComponentClass: {
      new (owner: Entity, ...args: any[]);
    },
    ...args
  ): C {
    // Inject the owner and its type automagically
    const component = new ComponentClass(this, ...args);

    // Store it
    this.components.add(component);
    this.componentV2s.add(component);

    // Check if the component needs ticking
    if (component.isTickEnabled) {
      // If this components needs ticks, then we need ticks as well
      if (!this.isRegisteredAsTickable) {
        this.registerForTick();
        this._hasTickEnabledComponents = true;
      }

      // Add it to the seperate list
      this.tickableComponents.add(component);
    }

    // Let it know it's been added to the entity
    component.onAddedToEntity();

    return component;
  }

  public postTick(deltaSeconds: number, deltaTime: number) {}

  public tick(deltaSeconds: number, deltaTime: number) {
    this.tickableComponents.forEach((tickableComponent) => {
      tickableComponent.tick(deltaSeconds, deltaTime);
    });
  }

  protected onDestroy() {
    this.tickableComponents.clear();
    this.components.forEach((component) => component.destroy());
    this.components.clear();
  }

  protected onShutdown() {
    this.tickableComponents.clear();
    this.components.forEach((component) => component.shutdown());
    this.components.clear();
  }

  protected registerForPostTick() {
    if (this.isRegisteredAsPostTickable) {
      return;
    }
    this.scene.registerPostTickable(this);

    this.isRegisteredAsPostTickable = true;
  }

  protected registerForTick(): void {
    if (this.isRegisteredAsTickable) {
      return;
    }
    this.scene.registerTickable(this);

    this.isRegisteredAsTickable = true;
  }

  protected unregisterForPostTick = () => {
    this.scene.unregisterPostTickable(this);

    this.isRegisteredAsPostTickable = false;
  };

  protected unregisterForTick = () => {
    this.scene.unregisterTickable(this);

    this.isRegisteredAsTickable = false;
  };
}
