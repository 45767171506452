import { OrderableType } from "@game/engine/enums/OrderableType";
import IIsometricOrderable from "@game/engine/interfaces/IIsometricOrderable";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import Image = Phaser.GameObjects.Image;

export default class IsometricOrderableStack implements IIsometricOrderable {
  public orderableType: OrderableType = OrderableType.Static;
  public tilePosition: TilePosition3D;

  private _depth: number = -1;
  private _parts: Image[];

  constructor() {
    this._parts = [];
  }

  public addPart(
    tilePosition: TilePosition3D,
    part: Image,
  ): IsometricOrderableStack {
    this._parts.push(part);

    if (
      (this.tilePosition && this.tilePosition.z > tilePosition.z) ||
      this.tilePosition === undefined
    ) {
      this.tilePosition = tilePosition;
    }

    return this;
  }

  public clear() {
    this.tilePosition = undefined;
    this._parts = [];
  }

  public setDepth(depth: number) {
    if (-1 === this._depth || this._depth !== depth) {
      this._depth = depth;
      // Order them, but stick within the "whole" numbers the others are part of - should work with up to 100 or so parts
      for (let index = 0; index < this._parts.length; ++index) {
        this._parts[index].setDepth(depth);
      }
    }
  }
}
