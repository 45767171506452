import { IInteractable } from "@game/engine/interfaces/IInteractable";
import IIsometricOrderable from "@game/engine/interfaces/IIsometricOrderable";
import { IIsometricTilemap } from "@game/engine/interfaces/IIsometricTilemap";
import Pathfinder, { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import { Grid } from "@game/engine/objects/Grid";
import OrderableImage from "@game/engine/objects/OrderableImage";
import IsometricOrderableStack from "@game/engine/objects/IsometricOrderableStack";
import {
  extractPropertiesContainer,
  PropertiesContainer,
} from "@game/engine/objects/PropertiesContainer";
import {
  TilemapImageLayer,
  TilemapLayer,
  TilemapTileLayer,
} from "@game/engine/objects/TilemapLayer";
import GameScene from "@game/engine/scenes/GameScene";
import {
  getTiledProperty,
  getTiledTilePositionFromProperties,
} from "@game/engine/utilities/TiledHelper";
import getCenterPoint, {
  worldPositionAtTilePosition,
} from "@game/engine/utilities/TileHelper";
import Vector2 = Phaser.Math.Vector2;
import Tile = Phaser.Tilemaps.Tile;
import Tilemap = Phaser.Tilemaps.Tilemap;
import PhaserTilemapLayer = Phaser.Tilemaps.TilemapLayer;
import Tileset = Phaser.Tilemaps.Tileset;
import Pointer = Phaser.Input.Pointer;

export interface IOrderableTileset {
  firstGid: number;
  texture: string;
}

export interface IOrderableTilesetImportData {
  frameConfig?: { frameHeight: number; frameWidth: number };
  path: string;
}

export interface ISpritesheetImportData {
  frameConfig?: { frameHeight: number; frameWidth: number };
  key: string;
  path: string;
}

export default class OrderableIsometricGameScene
  extends GameScene
  implements IIsometricTilemap
{
  public interactables: IInteractable[];
  public tileOffset: Vector2 = new Vector2(0, 32);
  protected _animationPaths: { [key: string]: string };
  protected readonly _debugMode: boolean = true;
  protected _imagePaths: { [key: string]: string };
  protected _navigatableLayers: PhaserTilemapLayer[];
  protected _pathfinder: Pathfinder;
  protected _propertiesContainers: PropertiesContainer[];
  protected _propertiesGrid: Grid<PropertiesContainer>;
  protected _tilemapKey: string;
  protected _tilesets: Tileset[];
  // Private tho
  private readonly _assetPath: string;
  private _isDirty: boolean = false;
  private _layers: TilemapLayer[];
  private _orderableGrid: Grid<IIsometricOrderable>;
  private _orderableTilesets: { [key: number]: IOrderableTileset };
  private _orderables: IIsometricOrderable[];
  private readonly _sortEveryFrame: boolean;
  private _spritesheetImportDatas: ISpritesheetImportData[];
  private readonly _tilemapPath: string;
  private _tilesetPaths: string[];

  protected _map: Tilemap;

  public get map(): Tilemap {
    return this._map;
  }

  constructor(
    config,
    assetPath: string,
    tilemapPath: string,
    tilesetPathOrPaths: string[],
    imagePathOrPaths: string[] = [],
    orderableTilesetImportDatas: (string | IOrderableTilesetImportData)[] = [],
    animationAtlasPaths: string[] = [],
    sortEveryFrame: boolean = false,
  ) {
    super(config);

    this._assetPath = assetPath;
    this._sortEveryFrame = sortEveryFrame;
    this._tilemapPath = this._assetPath + tilemapPath;

    // Load the image paths
    this._imagePaths = {};
    imagePathOrPaths.forEach((imagePath) => {
      this._imagePaths[imagePath] = this._assetPath + imagePath;
    });

    // Load the animation paths
    this._animationPaths = {};
    animationAtlasPaths.forEach((animationPath) => {
      this._animationPaths[animationPath] = this._assetPath + animationPath;
    });

    // Load the tilesets
    this._tilesetPaths = tilesetPathOrPaths.map(
      (tilesetPath: string) => this._assetPath + tilesetPath,
    );

    // Add all spritesheets for now
    const defaultFrameConfig = {
      frameHeight: 96,
      frameWidth: 128,
    };
    this._spritesheetImportDatas = [];
    orderableTilesetImportDatas.forEach((importData) => {
      let path;
      let frameConfig = defaultFrameConfig;
      if ("string" === typeof importData) {
        path = importData;
      } else {
        frameConfig = importData.frameConfig || defaultFrameConfig;
        path = importData.path;
      }
      const pathSplit = path.split("/");
      const filename = pathSplit[pathSplit.length - 1].split(".")[0];
      this._spritesheetImportDatas.push({
        frameConfig: frameConfig,
        key: filename + "_spritesheet",
        path: this._assetPath + path,
      });
    });

    this.interactables = [];

    this._debugMode = config.debugMode || false;
    this._orderables = [];
    this._orderableTilesets = {};
    this._propertiesContainers = [];
  }

  public addInteractable(interactable: IInteractable) {
    this.interactables.push(interactable);
  }

  public addOrderable(orderable: IIsometricOrderable) {
    this._orderables.push(orderable);
  }

  public getPropertiesContainer(
    x: number,
    y: number,
    z: number,
  ): PropertiesContainer {
    return this._propertiesGrid.get(x, y, z);
  }

  public getPropertiesContainersContainingType(
    type: string,
  ): PropertiesContainer[] {
    return this._propertiesContainers.filter((propertiesContainer) =>
      propertiesContainer.hasPropertyWithNameContainingValue("Types", type),
    );
  }

  public getPropertiesContainersOfType(type: string): PropertiesContainer[] {
    return this._propertiesContainers.filter((propertiesContainer) =>
      propertiesContainer.hasPropertyWithNameAndValue("Type", type),
    );
  }

  public hasPropertiesContainer(x: number, y: number, z: number): boolean {
    return this._propertiesGrid.has(x, y, z);
  }

  public removeOrderable(orderable: IIsometricOrderable) {
    const index = this._orderables.indexOf(orderable);
    if (index >= 0) {
      this._orderables.splice(index, 1);
    }
  }

  public sortOrderables() {
    if (!this._orderableGrid) {
      return;
    }
    const movableOrderablesGrid = new Grid<IIsometricOrderable[]>(
      this._orderableGrid.width,
      this._orderableGrid.depth,
      this._orderableGrid.height,
    );

    this._orderables.forEach((orderable) => {
      const tilePosition = orderable.tilePosition;
      if (!tilePosition) {
        return;
      }
      if (
        movableOrderablesGrid.has(
          tilePosition.x,
          tilePosition.y,
          tilePosition.z,
        )
      ) {
        movableOrderablesGrid
          .get(tilePosition.x, tilePosition.y, tilePosition.z)
          .push(orderable);
      } else {
        movableOrderablesGrid.add(
          tilePosition.x,
          tilePosition.y,
          tilePosition.z,
          [orderable],
        );
      }
    });

    let depth: number = 1;
    for (let level: number = 0; level < this._orderableGrid.height; ++level) {
      for (let y: number = 0; y < this._orderableGrid.depth; ++y) {
        for (let x: number = 0; x < this._orderableGrid.width; ++x) {
          // First check if there's a tile here
          if (this._orderableGrid.has(x, y, level)) {
            this._orderableGrid.get(x, y, level).setDepth(depth - 1);
          }

          // Next check if there's a potential moving one here
          if (movableOrderablesGrid.has(x, y, level)) {
            // We need to add 2 because for some reason same depth doesn't mean order is fixed
            const orderables = movableOrderablesGrid.get(x, y, level);
            for (let index = 0; index < orderables.length; ++index) {
              orderables[index].setDepth(depth);
            }
            depth += 2;
          }
        }
      }
    }
  }

  public tilePositionAtCoordinates(x: number, y: number): TilePosition3D {
    return this.positionUnderWorldPosition(new Vector2(x, y));
  }

  public tilePositionAtWorldPosition(worldPosition: Vector2): TilePosition3D {
    return this.positionUnderWorldPosition(worldPosition);
  }

  public worldPositionAtTilePosition(
    tilePosition: TilePosition3D,
    onlyNavigatableTiles: boolean = true,
    isCentered: boolean = true,
  ): Phaser.Math.Vector2 {
    if (onlyNavigatableTiles) {
      let layers = this._navigatableLayers;
      if (tilePosition.z > layers.length - 1 || tilePosition.z < 0) {
        return null;
      }

      const tile = layers[tilePosition.z].getTileAt(
        tilePosition.x,
        tilePosition.y,
      );
      if (!tile) {
        return null;
      }
      return getCenterPoint(tile, this.cameras.main, this);
    } else {
      return worldPositionAtTilePosition(this._map, tilePosition, isCentered);
    }
  }

  protected create() {
    super.create();

    const jsonMap = this.cache.json.get(this._tilemapKey + "_tilemap_json");

    this.loadOrderableTilesets(jsonMap);

    // Load the actual map
    this._map = this.make.tilemap({ key: this._tilemapKey + "_tilemap" });

    this.createLayerOrder(jsonMap);

    // Create a pathfinder for the currently loaded map
    this._pathfinder = this.createPathfinder();

    // Adding all actual tilesets
    this.loadTilesets();

    // Retrieve the height of the map
    const numberOfLevels = getTiledProperty(this._map, "NumberOfLevels");

    // For storing navigatable layers
    this._navigatableLayers = new Array<PhaserTilemapLayer>(numberOfLevels);

    // For bottom levels only
    this._orderableGrid = new Grid<IsometricOrderableStack>(
      this._map.width,
      this._map.height,
      numberOfLevels,
    );

    // A place to store properties
    this._propertiesGrid = new Grid<PropertiesContainer>(
      this._map.width,
      this._map.height,
      numberOfLevels,
    );

    // For keeping track of all obstacles
    const stackGraph = new Grid<IsometricOrderableStack>(
      this._map.width,
      this._map.height,
      numberOfLevels,
    );

    this._layers.forEach((tilemapLayer: TilemapLayer) => {
      if (!tilemapLayer.visible) {
        return;
      }
      const types = tilemapLayer.getProperty("Types");
      const level = tilemapLayer.getProperty("Level");
      const animated = tilemapLayer.getProperty("Animated");

      const layerData = tilemapLayer.original;

      if (tilemapLayer instanceof TilemapTileLayer) {
        if (types) {
          // Check if we need to render it ourselves so we can order it

          if (types.includes("orderable")) {
            const tilemap = layerData.data;
            const layerIndex = this._map.getLayerIndex(layerData.name);
            const tilemapLayer = new PhaserTilemapLayer(
              this,
              this._map,
              layerIndex,
              this._tilesets,
              layerData.x,
              layerData.y,
            );

            for (let y = 0; y < layerData.height; ++y) {
              for (let x = 0; x < layerData.width; ++x) {
                const tile: Tile = tilemap[y][x];

                // Only add if it's not an empty tile
                if (tile.index > -1) {
                  const tileset: IOrderableTileset =
                    this._orderableTilesets[tile.index];
                  const image = this.add
                    .image(
                      tile.pixelX + layerData.x,
                      tile.pixelY + layerData.y,
                      tileset.texture,
                      tile.index - tileset.firstGid,
                    )
                    .setOrigin(0, 0);

                  let stack;
                  // Check if there's something under this
                  if (level > 0 && stackGraph.has(x, y, level - 1)) {
                    stack = stackGraph.get(x, y, level - 1);
                  } else {
                    // Create a new stack and add it
                    stack = new IsometricOrderableStack();
                    this._orderableGrid.add(x, y, level, stack);
                  }
                  stack.addPart({ x: x, y: y, z: level }, image);
                  stackGraph.add(x, y, level, stack);
                }
              }
            }

            tilemapLayer.destroy(false);
          } else if (types.includes("navigatable")) {
            if (null === level) {
              console.error(
                'Navigatable layer "' +
                  layerData.name +
                  '" is missing the "Level" property',
              );
              return;
            }

            this._navigatableLayers[level] = this._map.createLayer(
              layerData.name,
              this._debugMode ? this._tilesets : null,
            );
          } else {
            // Just create and then add the layer as a default tile layer
            this._map.createLayer(layerData.name, this._tilesets);
          }
        } else {
          // Just create and then add the layer as a default tile layer
          this._map.createLayer(layerData.name, this._tilesets);
        }
      }

      if (tilemapLayer instanceof TilemapImageLayer) {
        // We will always need the image, but check if it is animated or not
        let image;
        if (animated) {
          //create animation based on the animation key
          const animKey = tilemapLayer.getProperty("AnimationKey");
          const frameKey = tilemapLayer.getProperty("FrameName");
          const frameCount = tilemapLayer.getProperty("FrameCount");
          const animDuration = tilemapLayer.getProperty("Duration");
          const animDelay = tilemapLayer.getProperty("Delay");

          this.anims.create({
            key: animKey + "_animation",
            frames: this.anims.generateFrameNames(animKey, {
              prefix: frameKey,
              suffix: ".png",
              end: frameCount - 1,
            }),
            repeat: -1,
            duration: animDuration,
            repeatDelay: animDelay,
          });

          image = this.add
            .sprite(
              layerData.x - this._map.tileWidth * 0.5 * (this._map.height - 1),
              layerData.y + this._map.tileHeight * 0.5,
              layerData.image,
            )
            .setOrigin(0);

          image.anims.play(animKey + "_animation", true);
        } else {
          image = this.add
            .image(
              layerData.x - this._map.tileWidth * 0.5 * (this._map.height - 1),
              layerData.y + this._map.tileHeight * 0.5,
              layerData.image,
            )
            .setOrigin(0);
        }
        // const image = this.add
        //   .image(
        //     layerData.x + this._map.tileWidth * 0.5,
        //     this._map.widthInPixels * 0.5,
        //     layerData.y + this._map.tileHeight * 0.5,
        //     layerData.image,
        //   )
        //   .setOrigin(0);

        // Check if we need to render it ourselves so we can order it
        if (types) {
          const tilePosition = getTiledTilePositionFromProperties(layerData);
          let propertiesContainer: PropertiesContainer =
            extractPropertiesContainer(tilemapLayer, tilePosition);

          if (types.includes("orderable")) {
            if (null === tilePosition) {
              console.error(
                "No PositionX, PositionY and/or PositionZ property set for " +
                  layerData.image,
              );
              return;
            }

            const interactable = new OrderableImage(
              tilePosition,
              image,
              propertiesContainer,
            );
            this._orderables.push(interactable);

            // Store it as an interactable as well if needed
            if (types.includes("interactable")) {
              this.addInteractable(interactable);
            }
          }
        }
      }
    });

    // Storing properties for optional use
    this._map.objects.forEach((layer) => {
      if (!layer.visible) {
        return;
      }

      layer.objects.forEach((object) => {
        const tilePosition = getTiledTilePositionFromProperties(object);
        if (null === tilePosition) {
          console.error(
            "No PositionX, PositionY and/or PositionZ property set for " +
              object.name,
          );
          return;
        }
        const { properties } = object;
        if (properties) {
          const convertedProperties = {};
          properties.forEach((property) => {
            convertedProperties[property.name] = property.value;
          });
          const propertiesContainer = new PropertiesContainer(
            convertedProperties,
            tilePosition,
            object,
          );
          this._propertiesContainers.push(propertiesContainer);
          this._propertiesGrid.add(
            tilePosition.x,
            tilePosition.y,
            tilePosition.z,
            propertiesContainer,
          );
        }
      });
    });

    this.sortOrderables();
  }

  protected createPathfinder(): Pathfinder {
    return new Pathfinder(this._map);
  }

  protected init(data?: object) {
    super.init(data);

    this._tilemapKey = this.scene.key;
  }

  protected loadTilesets() {
    this._tilesets = [];
    this._tilesetPaths.forEach((tilesetURL, index) => {
      // For now, this is fine.. Right? Surely.
      const pathSplit = tilesetURL.split("/");
      const filename = pathSplit[pathSplit.length - 1].split(".")[0];

      // Retrieve the tileset
      this._tilesets.push(
        this._map.addTilesetImage(
          filename,
          this._tilemapKey + "_tileset_" + index,
        ),
      );
    });
  }

  protected override onShutdown() {
    super.onShutdown();

    // Clear everything that can be cleared
    this.interactables.length = 0;
    this._imagePaths = {};
    this._layers.length = 0;
    this._navigatableLayers.length = 0;
    this._orderables.length = 0;
    this._orderableTilesets = {};
    this._propertiesContainers.length = 0;
    this._spritesheetImportDatas.length = 0;
    this._tilesets.length = 0;
  }

  protected positionUnderPointer = (pointer: Pointer): TilePosition3D => {
    return this.positionUnderWorldPosition(
      new Vector2(pointer.worldX, pointer.worldY),
    );
  };

  protected override preUpdate(time, deltaTime) {
    super.preUpdate(time, deltaTime);

    // Only sort when needed (or always sort to fix some clipping)
    if (this._isDirty || this._sortEveryFrame) {
      this.sortOrderables();
      this._isDirty = false;
    }
  }

  protected preload() {
    super.preload();

    // Load all the tilesets (if needed)
    this._tilesetPaths.forEach((tilesetURL, index) => {
      this.load.image(this._tilemapKey + "_tileset_" + index, tilesetURL);
    });

    // Load images
    for (const [key, path] of Object.entries(this._imagePaths)) {
      this.load.image(key, path);
    }
    // Load animation paths
    for (const [key, path] of Object.entries(this._animationPaths)) {
      let imagepath: string[] = path.split(".");
      this.load.atlas(key, imagepath[0] + ".png", path);
    }

    // Load spritesheets
    this._spritesheetImportDatas.forEach((spritesheetImportData) => {
      this.load.spritesheet(
        spritesheetImportData.key,
        spritesheetImportData.path,
        spritesheetImportData.frameConfig,
      );
    });

    // Load the tilemap as a tilemap and as straight up JSON (for parsing)
    if (this._tilemapPath) {
      this.load.json(this._tilemapKey + "_tilemap_json", this._tilemapPath);
      this.load.tilemapTiledJSON(
        this._tilemapKey + "_tilemap",
        this._tilemapPath,
      );
    }
  }

  private addOrderableTileset(
    firstGid: number = 1,
    tileCount: number,
    name: string,
  ) {
    for (let index = firstGid; index < firstGid + tileCount; ++index) {
      this._orderableTilesets[index] = {
        firstGid: firstGid,
        texture: name + "_spritesheet",
      };
    }
  }

  private createLayerOrder(jsonMap) {
    // Setting up for merging image- and tile layers
    // Retrieve the different supported layer types individually
    const { images, layers } = this._map;

    // Place for storing the different layers
    this._layers = new Array(images.length + layers.length);

    // Retrieve the data needed for organising the map structure
    const layerOrderMap = {};

    // Store layers in order
    const numberOfLayers = jsonMap.layers.length;
    for (let index = 0; index < numberOfLayers; ++index) {
      const layer = jsonMap.layers[index];
      layerOrderMap[layer.name] = index;
    }

    // Add and place imagelayers
    images.forEach((original) => {
      this._layers[layerOrderMap[original.name]] = new TilemapImageLayer(
        original,
      );
      this._layers[layerOrderMap[original.name]].x = original.x - 1344;
    });

    // Add and place tilelayers
    layers.forEach((original) => {
      this._layers[layerOrderMap[original.name]] = new TilemapTileLayer(
        original,
      );
    });
  }

  private loadOrderableTilesets(jsonMap) {
    // Some sanity checking
    if (jsonMap && "tilesets" in jsonMap) {
      const tilesets = jsonMap.tilesets;
      for (let index = 0; index < tilesets.length; ++index) {
        const tileset = tilesets[index];
        this.addOrderableTileset(
          tileset.firstgid,
          tileset.tilecount,
          tileset.name,
        );
      }
    }
  }

  public positionUnderWorldPosition = (
    worldPosition: Vector2,
  ): TilePosition3D => {
    const position = new Vector2(
      worldPosition.x - this._map.tileWidth * 0.5 + this.tileOffset.x,
      worldPosition.y - this._map.tileHeight + this.tileOffset.y,
    );
    const tilePosition: TilePosition3D = { x: -1, y: -1, z: -1 };
    for (let index = this._navigatableLayers.length - 1; index >= 0; index--) {
      const layer = this._navigatableLayers[index];
      // Check if the layer exists
      if (!layer) {
        continue;
      }
      const tileXY = layer.worldToTileXY(position.x, position.y);
      tileXY.x = Math.round(tileXY.x);
      tileXY.y = Math.round(tileXY.y);

      const tile = layer.getTileAt(tileXY.x, tileXY.y);
      if (tile && tile.index > -1) {
        tilePosition.x = tileXY.x;
        tilePosition.y = tileXY.y;
        tilePosition.z = getTiledProperty(layer.layer, "Level") || index;
        break;
      }

      if (0 === index) {
        return null;
      }
    }
    return tilePosition;
  };
}
