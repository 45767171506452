import { NetworkedComponent } from "@engine/components/scenecomponents/v2/NetworkedComponent";

// Assumes a "main" networked component already exists that handles the connections and such. This is for extending networked functionality modularly
export class ExtendedNetworkedComponent extends NetworkedComponent {
  constructor() {
    super("--no-module-attached--");
  }

  public override async connect(): Promise<void> {
    console.error(
      "[ExtendedNetworkedComponent] Don't call `connect` on an `ExtendedNetworkedComponent`",
    );
    return Promise.reject();
  }

  public override async findAndJoinMatch(
    module: string,
    data: object = {},
  ): Promise<boolean> {
    console.error(
      "[ExtendedNetworkedComponent] Don't call `connect` on an `ExtendedNetworkedComponent`",
    );
    return Promise.reject();
  }
}
