import SceneComponent from "@game/engine/components/SceneComponent";
import IPostTickable from "@game/engine/interfaces/IPostTickable";
import Controller from "../controllers/Controller";
import ITickable from "../interfaces/ITickable";
import SystemNotificationComponent from "@game/engine/components/scenecomponents/SystemNotificationComponent";
import { AssetType, IIdentifiableAsset } from "@engine/objects/DynamicLoader";
import { GameSceneLifeCyclePhase } from "@engine/enums/GameSceneLifeCyclePhase";
import { AchievementNotificationComponent } from "@engine/components/scenecomponents/AchievementNotificationComponent";
import Rectangle = Phaser.Geom.Rectangle;
import Size = Phaser.Structs.Size;
import LoaderPlugin = Phaser.Loader.LoaderPlugin;

interface IPendingFile {
  key: string;
  onCompleteCallbacks: [(key: string, success: boolean) => void];
  type: AssetType;
}

export default class GameScene extends Phaser.Scene {
  public static SceneKey: string = "-none-";
  protected _lifeCyclePhase: GameSceneLifeCyclePhase;
  // Nice and useful for storing "states"
  protected _phase: number = -1;
  // Using sets to force uniqueness
  private _components: Set<SceneComponent>;
  private _controllers: Controller[];
  private _pendingDynamicFiles: Array<IPendingFile>;
  private _postUpdateTickables: Set<IPostTickable>;
  private _preUpdateTickableComponents: Set<SceneComponent>;
  private _tickableComponents: Set<SceneComponent>;
  private _tickables: Set<ITickable>;

  public set backgroundColor(color: number) {
    this.setBackgroundColor(color);
  }

  constructor(config) {
    super(config);

    this._lifeCyclePhase = GameSceneLifeCyclePhase.Constructor;

    this._components = new Set<SceneComponent>();
    this._controllers = [];
    this._postUpdateTickables = new Set<IPostTickable>();
    this._tickables = new Set<ITickable>();
    this._tickableComponents = new Set<SceneComponent>();
    this._preUpdateTickableComponents = new Set<SceneComponent>();
  }

  public addComponent<C extends SceneComponent>(component: C): C {
    // Register us as the owner
    component.setScene(this);

    // Store it
    this._components.add(component);

    // Check if the component needs pre-ticking
    if (component.isPreTickEnabled) {
      this._preUpdateTickableComponents.add(component);
    }

    // Check if the component needs ticking
    if (component.isTickEnabled) {
      // Add it to the seperate list
      this._tickableComponents.add(component);
    }

    return component;
  }

  public areAllAssetsLoaded(assets: IIdentifiableAsset[]): boolean {
    for (const asset of assets) {
      if (!this.isAssetLoaded(asset.type, asset.key)) {
        return false;
      }
    }
    return true;
  }

  // For loading one single asset, don't use for multiple. Use `DynamicLoader` instead
  public dynamicLoadAsset(
    type: AssetType,
    assetConfig: IIdentifiableAsset,
    onComplete: (key: string, success: boolean) => void,
  ) {
    // For tracking what's currently being loaded
    if (!this._pendingDynamicFiles) {
      this._pendingDynamicFiles = new Array<IPendingFile>();
    }

    // const identifier = type + "__" + assetConfig.key;
    const pendingFile = this._pendingDynamicFiles.find(
      (file) => file.type === type && file.key === assetConfig.key,
    );
    if (pendingFile) {
      pendingFile.onCompleteCallbacks.push(onComplete);

      console.log(
        "[GameScene] Trying to load a file that's already being loaded: " +
          type +
          ": " +
          assetConfig.key,
      );
      return;
    }

    // Add it to the list
    this._pendingDynamicFiles.push({
      key: assetConfig.key,
      onCompleteCallbacks: [onComplete],
      type,
    });

    const onFileDone = (completedFileKey: string, success: boolean = true) => {
      if (completedFileKey === assetConfig.key) {
        const pendingFile = this._pendingDynamicFiles.splice(
          this._pendingDynamicFiles.findIndex(
            (pendingFile) =>
              pendingFile.type === type && pendingFile.key === assetConfig.key,
          ),
          1,
        )[0];
        this.load.off(Phaser.Loader.Events.FILE_COMPLETE, onFileComplete);
        this.load.off(Phaser.Loader.Events.FILE_LOAD_ERROR, onFileError);
        for (const onCompleteCallback of pendingFile.onCompleteCallbacks) {
          onCompleteCallback(assetConfig.key, success);
        }
      }
    };
    // Local callback so it's unique per request
    const onFileComplete = (completedFileKey: string) => {
      onFileDone(completedFileKey, true);
    };
    const onFileError = (erroredFile: any) => {
      onFileDone(erroredFile.key, false);
    };
    this.load[type](assetConfig);
    this.load.on(Phaser.Loader.Events.FILE_COMPLETE, onFileComplete);
    this.load.on(Phaser.Loader.Events.FILE_LOAD_ERROR, onFileError);
    this.load.start();
  }

  // If the asset is already loaded, returns the onComplete immediately. If the asset is still loading, adds the onComplete callback to a list of callbacks for when the file is done loading.
  public dynamicLoadAssetIfNotLoadingOrLoaded(
    assetConfig: IIdentifiableAsset,
    onComplete: (key: string, success: boolean) => void,
  ) {
    if (this.isAssetLoaded(assetConfig.type, assetConfig.key)) {
      onComplete(assetConfig.key, true);
      return;
    }
    this.dynamicLoadAsset(assetConfig.type, assetConfig, onComplete);
  }

  public dynamicLoadAssets(
    assets: IIdentifiableAsset[],
    onComplete: () => void = () => {},
  ) {
    if (assets.length === 0) {
      onComplete();
      return;
    }
    const loader = new LoaderPlugin(this);
    for (const asset of assets) {
      loader[asset.type](asset);
    }
    loader.once(Phaser.Loader.Events.COMPLETE, onComplete);
    loader.start();
  }

  public dynamicLoadAssetsIfNotLoaded(
    assets: IIdentifiableAsset[],
    onComplete: () => void,
  ) {
    if (this.areAllAssetsLoaded(assets)) {
      return onComplete();
    }

    this.dynamicLoadAssets(assets, onComplete);
  }

  // Override this if you're have a large map
  public getBounds(): Rectangle {
    return new Rectangle();
  }

  public getLifeCyclePhase(): GameSceneLifeCyclePhase {
    return this._lifeCyclePhase;
  }

  public isAssetLoaded(type: AssetType, key: string): boolean {
    if (type === "image" || type === "atlas") {
      return this.textures.exists(key);
    }
    return this.cache[type].exists(key);
  }

  public registerController(controller: Controller) {
    this._controllers.push(controller);
  }

  public registerPostTickable(tickable: IPostTickable) {
    this._postUpdateTickables.add(tickable);
  }

  public registerTickable(tickable: ITickable) {
    this._tickables.add(tickable);
  }

  public removeComponent(component): boolean {
    return this._components.delete(component);
  }

  public setBackgroundColor(color: number) {
    this.cameras.main.setBackgroundColor(color);
  }

  public unregisterPostTickable = (tickable: IPostTickable) => {
    this._postUpdateTickables.delete(tickable);
  };

  public unregisterTickable = (tickable: ITickable) => {
    this._tickables.delete(tickable);
  };

  public override update(time: number, deltaTime: number) {
    super.update(time, deltaTime);

    // Update everything that can tick
    const deltaSeconds = deltaTime * 0.001;

    // Tick is nicer than update
    this.tick(deltaSeconds, deltaTime);

    // First update the scene components
    this._tickableComponents.forEach((tickableComponent) =>
      tickableComponent.tick(deltaSeconds, deltaTime),
    );

    // Then update the tickables/entities
    this._tickables.forEach((tickable) => tickable.tick(deltaSeconds, time));
  }

  protected create() {
    this._lifeCyclePhase = GameSceneLifeCyclePhase.Create;

    // Add some default components
    this.addComponent(new AchievementNotificationComponent());
    this.addComponent(new SystemNotificationComponent());

    // Make all components able to know when the scene is created stuff
    this._components.forEach((component) => component.create());

    // Make sure we rescale the game when the window resizes
    this.scale.on("resize", this.doResize, this);
  }

  protected init(data?: object) {
    this._lifeCyclePhase = GameSceneLifeCyclePhase.Init;
  }

  protected isInPhase(phaseOrPhases: number | number[]): boolean {
    const phases = Array.isArray(phaseOrPhases)
      ? phaseOrPhases
      : [phaseOrPhases];
    for (let index = 0; index < phases.length; index++) {
      if (this._phase === phases[index]) {
        return true;
      }
    }
    return false;
  }

  protected onCreated() {
    this._lifeCyclePhase = GameSceneLifeCyclePhase.Created;
    // Let all scene components know the scene has been created
    this._components.forEach((component) => component.onCreated());

    // Start all controllers
    this._controllers.forEach((controller) => controller.start());
  }

  protected onResized(gameSize: Size) {}

  protected onShutdown() {
    this._lifeCyclePhase = GameSceneLifeCyclePhase.Shutdown;
    // Stop eventing
    this.events.off("preupdate", this.preUpdate);
    this.scale.off("resize", this.doResize);

    // Kill all components and all references to them
    this._components.forEach((component) => component.shutdown());
    this._components.clear();
    this._tickableComponents.clear();
    this._preUpdateTickableComponents.clear();

    // Kill all currently still ongoing tweens
    this.tweens.killAll();
  }

  protected postUpdate(time, deltaTime) {
    // Update everything that can tick
    const deltaSeconds = deltaTime * 0.001;

    this._postUpdateTickables.forEach((tickable) =>
      tickable.postTick(deltaSeconds, deltaTime),
    );
  }

  protected preUpdate(time, deltaTime) {
    // Update everything that can tick
    const deltaSeconds = deltaTime * 0.001;

    // First update the scene components
    this._preUpdateTickableComponents.forEach((tickableComponent) =>
      tickableComponent.preTick(deltaSeconds, deltaTime, time),
    );
  }

  protected preload() {
    this.events.once("create", this.onCreated, this);

    // In case stuff needs to run before things start to move (like a camera)
    this.events.on("preupdate", this.preUpdate, this);

    // Or after
    this.events.on("postupdate", this.postUpdate, this);

    // Calling shutdown when possible
    this.events.once("shutdown", this.onShutdown, this);

    // Make all components able to preload stuff
    this._components.forEach((component) => component.preload());
  }

  protected tick(deltaSeconds: number, deltaTime: number) {}

  private doResize = (gameSize: Size) => {
    const width = gameSize.width;
    const height = gameSize.height;

    this.cameras.resize(width, height);

    this.onResized(gameSize);
  };
}
