import OrderableIsometricCharacter from "@engine/gameobjects/OrderableIsometricCharacter";
import { makeInteractable } from "@engine/utilities/InteractableHelper";
import { InteractableEventTypes } from "@engine/eventtypes/InteractableEventTypes";
import { EmoteWheelComponent } from "@engine/components/entitycomponents/EmoteWheelComponent";
import IsometricCharacter from "@engine/IsometricCharacter";

export class OrderableIsometricPlayer extends OrderableIsometricCharacter {
  public static EventTypes = {
    Emote: "playeremote",
    ...IsometricCharacter.EventTypes,
  };

  private _emoteWheelComponent: EmoteWheelComponent;

  constructor(
    scene,
    x,
    y,
    characterName: string = "mr_anonymous",
    role?: string,
    local: boolean = false,
  ) {
    super(scene, x, y, characterName, role, local);

    this._emoteWheelComponent = this.createAndAddComponent<EmoteWheelComponent>(
      EmoteWheelComponent,
      { x: 0, y: -128 },
    );
    this._emoteWheelComponent.events.on(
      EmoteWheelComponent.EventTypes.EmoteSelected,
      this.onEmoteWheelEmoteSelected,
    );

    // Enable emotes
    makeInteractable(this._animatedIsometricAvatarComponent.sprite, this);
    this.on(InteractableEventTypes.OnDoubleClick, () => {
      this._emoteWheelComponent.toggle();
    });

    this.on(IsometricCharacter.EventTypes.StartedPath, () => {
      this._emoteWheelComponent.hide();
    });
  }

  protected onDestroy() {
    super.onDestroy();

    this._emoteWheelComponent.events.off(
      EmoteWheelComponent.EventTypes.EmoteSelected,
    );
  }

  private onEmoteWheelEmoteSelected = (identifier: string) => {
    this.emit(OrderableIsometricPlayer.EventTypes.Emote, identifier);
  };
}
