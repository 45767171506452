import SceneComponent from "@engine/components/SceneComponent";
import PublicSpaceGameScene from "@engine/scenes/PublicSpaceGameScene";
import { TilePosition3D } from "@engine/navigation/Pathfinder";
import Vector2 = Phaser.Math.Vector2;
import Pointer = Phaser.Input.Pointer;

const LONG_PRESS_INTERVAL = 500;

export enum PublicSpaceInputEvent {
  TileHovered,
  TilePressed,
  TileDoubleTapped,
  TileRightClicked,
  TileLongPress,
}

type TPublicSpaceInputListener = {
  callback: (tilePosition: TilePosition3D) => boolean;
  callbackContext: Object;
  event: PublicSpaceInputEvent;
  priority: number;
};

export class PublicSpaceInputComponent extends SceneComponent {
  private _lastPressTime = 0;
  private _listeners: TPublicSpaceInputListener[] = [];
  private _currentPointer: Pointer | null = null;

  constructor() {
    super();
  }

  public addInputListener(
    event: PublicSpaceInputEvent,
    callback: (tilePosition: TilePosition3D) => boolean,
    callbackContext: Object,
    priority: number,
  ) {
    const listener: TPublicSpaceInputListener = {
      callback: callback,
      callbackContext: callbackContext,
      event: event,
      priority: priority,
    };

    this._listeners.push(listener);
    this._listeners.sort((a, b) => b.priority - a.priority);
  }

  protected onSceneSet(scene?: PublicSpaceGameScene) {
    super.onSceneSet(scene);

    scene?.input.on("pointerdown", this.onPointerDown, this);
    scene?.input.on("pointerup", this.onPointerUp, this);
    scene?.input.on("pointermove", this.onPointerMove, this);
  }

  protected onShutdown() {
    this.scene?.input.off("pointerdown", this.onPointerDown, this);
    this.scene?.input.off("pointerup", this.onPointerUp, this);
    this.scene?.input.off("pointermove", this.onPointerMove, this);

    super.onShutdown();
  }

  private getActivePointerTilePosition(): TilePosition3D {
    return (this.scene as PublicSpaceGameScene).positionUnderWorldPosition(
      new Vector2(
        this.scene.input.activePointer.worldX,
        this.scene.input.activePointer.worldY,
      ),
    );
  }

  private reportEventToListeners(
    event: PublicSpaceInputEvent,
    tilePosition: TilePosition3D,
  ) {
    for (let i = 0; i < this._listeners.length; i++) {
      const listener = this._listeners[i];

      if (
        listener.event === event &&
        listener.callback.apply(listener.callbackContext, [tilePosition])
      ) {
        return;
      }
    }
  }

  private onPointerDown(pointer: Pointer) {
    if (this._currentPointer) return;

    this._currentPointer = pointer;
    this._lastPressTime = Date.now();

    if (this.scene.input.activePointer.rightButtonDown()) {
      const tilePosition = this.getActivePointerTilePosition();

      if (!tilePosition) return;

      return this.reportEventToListeners(
        PublicSpaceInputEvent.TileRightClicked,
        tilePosition,
      );
    }
  }

  private onPointerUp(pointer: Pointer) {
    if (this._currentPointer === pointer) {
      this._currentPointer = null;
    }

    const tilePosition = this.getActivePointerTilePosition();

    if (!tilePosition || pointer.rightButtonReleased()) {
      return;
    }

    if (Date.now() - this._lastPressTime > LONG_PRESS_INTERVAL) {
      this.reportEventToListeners(
        PublicSpaceInputEvent.TileLongPress,
        tilePosition,
      );
    } else {
      this.reportEventToListeners(
        PublicSpaceInputEvent.TilePressed,
        tilePosition,
      );
    }
  }

  private onPointerMove(pointer: Pointer) {
    const tilePosition = this.getActivePointerTilePosition();

    if (!tilePosition) {
      return;
    }

    this.reportEventToListeners(
      PublicSpaceInputEvent.TileHovered,
      tilePosition,
    );
  }
}
