global:
  back: Back
  next: Next
  play: Play
  your: Your
  username: Username
  show: Show
  hide: Hide
  repeat: Repeat
  password: Password
  email: Email
  your-email: "@:global.your @.lower:global.email"
  your-password: "@:global.your @.lower:global.password"
  already-taken: Already taken
  uppercase-letter: Uppercase Letter
  lowercase-letter: Lowercase Letter
  special-character: Special Character
  minimum: Minimum
  character: Character | Characters
  letter: Letter | Letters
  members: Members
  join: Join
  login: Login
  signup: Signup
pages:
  signup:
    abandon: Are you sure you want to abandon registration? Changes will not be saved!
    step-names:
      SignupCharacterUsernameStep: Choose your character
      SignupEmailStep: Your email
      SignupPasswordStep: Secure your account
      SignupReferralStep: Your Beta code
      SignupTopupStep: Fill your bags
    code:
      incorrect: Incorrect code, please try again!
      correct: Verification successful!
    character:
      tip: You can always modify your character later on.
      warning: You can’t change a @.lower:global.username once your avatar is created!
      error:
        username:
          too-short: "@:global.username too short"
          already-taken: "@:global.username @.lower:global.already-taken"
    email:
      send-code: Send code
      send-code-success: Code sent
      send-code-label: "@:pages.signup.email.send-code-success to your @.lower:global.email"
      consent-age: I consent that I am 18 years old
      consent-legal: I agree with the
      tos: terms of service
      eula: EULA
      error:
        already-taken: "@:global.email @.lower:global.already-taken"
    password:
      tip: Think of something difficult to guess
      at-least: At least
      minimum: "@:global.minimum of"
    referral:
      no-code: Don't have a referral/beta code? Find one in the communities below!
    topup:
      support: Support the Team a little ❤️ and Buy a Starterpack!
    download:
      welcome-user: Welcome, {user}!
      download-store: In order to play Bit Hotel on mobile, please download the game from {store} store
