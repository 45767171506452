<script setup lang="ts">
import {
  dispatchEvent,
  EnumUiEvents,
  handleEvent,
  EnumGameEvents,
  EnumNetworkEvents,
  EnumApplicationEvents,
} from "@shared/Events";
import { currentAccount } from "@shared/Global";
import { onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { mousePosition } from "./overlay/store/commonUi";
import { isMobile } from "web3modal";
import GameVersion from "@uicomps/version/GameVersion.vue";
import { useStores } from "@store";
import { Toast } from "@engine/notifications/Toast";
import PublicSpaceGameScene from "@engine/scenes/PublicSpaceGameScene";
import { retrieveAvailableDemoAccount } from "./mobile/demoAccounts";

const DAY = 24 * 60 * 60 * 1000;

const router = useRouter();
const isInputEnabled = ref(true);

watch(currentAccount, () => {
  if (currentAccount.email) {
    router.push({
      name: "home",
      query: { campaign: router.currentRoute.value.query["campaign"] },
    });
  }
  if (currentAccount.user.create_time) {
    const userCreated = new Date(currentAccount.user.create_time);
    const now = new Date();
    if (now.getTime() - userCreated.getTime() < DAY) {
      setTimeout(() => {
        const activeScenes = (_game as Phaser.Game).scene.getScenes(true);
        let isInPublicSpace = false;
        activeScenes.forEach((scene) => {
          if (scene instanceof PublicSpaceGameScene) {
            isInPublicSpace = true;
          }
        });

        if (isInPublicSpace) {
          $modals.openModal("SpecialOfferModal");
        }
      }, 60000);
    }
  }
});

window.addEventListener("move", function (e) {
  dispatchEvent(EnumUiEvents.UiMove, e.detail);
});

onMounted(async () => {
  const store = useStores();
  window.$modals = store.modal;
  window.$store = store;

  // if (isMobile()) {
  //   window.location.assign("https://bithotel.io/#/beta");
  // }
});
const evs = [
  "keydown",
  "keyup",
  "keypressed",
  "mousedown",
  "mouseup",
  "mousepressed",
  "click",
  "pointerdown",
  "pointermove",
];
function stopEvent(e: any) {
  //allow Ctrl/Cmd + R or F5 so user could refresh
  if (
    !(
      (e.keyCode == 82 && (e.keyCode == 17 || e.ctrlKey || e.metaKey)) ||
      e.keyCode == 116
    )
  ) {
    e.preventDefault();
    e.stopPropagation();
  }
}

handleEvent(EnumGameEvents.ReceivedUserTicketsDone, (ret: { key: string }) => {
  if (currentAccount.user.id === ret.key) {
    Toast.info("You just received tickets");
    dispatchEvent(EnumNetworkEvents.NetworkRequestUserData);
  }
});

handleEvent(
  EnumUiEvents.UiEnableInput,
  (_isInputEnabled: boolean) => {
    isInputEnabled.value = _isInputEnabled;
    if (_isInputEnabled) {
      for (const ev of evs) {
        window.removeEventListener(ev, stopEvent);
        window.dispatchEvent(new CustomEvent("LoadingScreenClosed"));
      }
      // setTimeout(function () {
      //   var meta = document.createElement("meta");
      //   meta.name = "viewport";
      //   meta.content =
      //     "width=device-width, height=" +
      //     window.innerHeight +
      //     ", initial-scale=1, maximum-scale=1, user-scalable=0, interactive-widget=resizes-conent";
      //   document.getElementsByTagName("head")[0].appendChild(meta);
      // }, 300);
    } else {
      for (const ev of evs) {
        window.addEventListener(ev, stopEvent);
        window.dispatchEvent(new CustomEvent("LoadingScreenOpened"));
      }
    }
  },
  onBeforeUnmount,
);

let preventDisconnectReload = false;
handleEvent(
  EnumApplicationEvents.ApplicationPreventReload,
  (prevent: boolean) => {
    preventDisconnectReload = prevent;
  },
);
handleEvent(EnumNetworkEvents.NetworkSocketDisconnected, () => {
  // if (preventDisconnectReload) return;
  // location.reload();
});

function handleMouseMove(e: MouseEvent) {
  mousePosition.clientX = e.clientX;
  mousePosition.clientY = e.clientY;
}
</script>
<template>
  <div
    class="main"
    :class="{ 'is-loading': !isInputEnabled, mobile: isMobile() }"
    @keydown.esc="$modals.closeAllModals()"
    @mousemove="handleMouseMove"
  >
    <!-- <mouse-tooltip :mouse-event="mousePosition">yo</mouse-tooltip> -->
    <router-view />
    <game-version />
    <div id="phaser-game" />
  </div>
</template>
<style lang="scss">
.is-loading,
.is-loading * {
  pointer-events: none !important;
}
.walletconnect-modal__base {
  max-height: 650px !important;
  @include respond(mobile-landscape) {
    & > div:nth-child(3) > div {
      overflow: scroll !important;
      max-height: 40vh !important;
      @media screen and (max-height: 300px) {
        max-height: 80px !important;
      }
    }
  }
}
//
</style>
