import WalletConnectProvider from "@walletconnect/web3-provider";
import { IProviderOptions } from "web3modal";
import { Chain, supportedChains } from "@shared/consts/SupportedChains";
import { EChainId } from "@shared/types";

export function getProviderOptions(chainId: EChainId): IProviderOptions {
  const chain = new Chain(chainId);
  return {
    // ttps://docs.walletconnect.org/
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: import.meta.env.VITE_APP_INFURA_KEY,
        rpc: {
          [chainId]: chain.rpc_url,
        },
      },
    },
  };
}

export function getChainData(chainId: number) {
  const chainData = supportedChains.find((chain) => chain.chain_id === chainId);
  if (!chainData) {
    throw new Error("ChainId missing or not supported");
  }

  const INFURA_KEY = import.meta.env.VITE_APP_INFURA_KEY;

  if (
    chainData.rpc_url.includes("infura.io") &&
    chainData.rpc_url.includes("%API_KEY%") &&
    INFURA_KEY
  ) {
    const rpcUrl = chainData.rpc_url.replace("%API_KEY%", INFURA_KEY);
    return {
      ...chainData,
      rpc_url: rpcUrl,
    };
  }

  return chainData;
}
