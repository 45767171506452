import { EChainId } from "@shared/types";
import { currentChainId, EVMMarketplace } from "..";
import { SignerOrProvider } from "../evm";
import { signer, web3AuthInstance } from "../wallet";
import { CasperMarketplace } from "./casper";

export * from "./evm";
export * from "./casper";

export interface Marketplace {
  addListing(nft: string, tokenId: number, price: number): Promise<string>;

  cancelListing(listingId: number): Promise<string>;

  executeListing(listingId: number): Promise<string>;

  getListing(listingId: number): Promise<any>;

  get contractAddress(): string;
}

export async function getMarketplace(): Promise<Marketplace> {
  if (currentChainId.value == EChainId.CSPR) {
    let privateKey: string | undefined;
    if (web3AuthInstance && web3AuthInstance.status() == "connected") {
      privateKey = (await web3AuthInstance.getPrivateKey()).toString();
    }
    return new CasperMarketplace(privateKey);
  } else {
    const signerOrProvider = new SignerOrProvider(signer);
    return new EVMMarketplace(signerOrProvider);
  }
}
