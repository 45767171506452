
import MightyMinesScene from "../MightyMinesScene";
import MightyMinesCharacter from './MightyMinesCharacter';

export default class Mine extends Phaser.GameObjects.Sprite {
  public dropTween: Phaser.Tweens.Tween;
  private _character:MightyMinesCharacter;
  constructor(
    scene: MightyMinesScene,
    x: number,
    y: number,
    character: MightyMinesCharacter,
  ) {
    super(scene, x, y, "mine_sprite");
    

    this.setDepth(1000);

    this.scene.anims.create({
      key: "explode",
      frameRate: 8,
      frames: this.anims.generateFrameNumbers("mine_explosion_animation", {
        start: 0,
        end: 9,
      }),
      repeat: 0,
    });

    //this.explodeMine();
  }

  public explode() {
    this.setTexture("mine_explosion_animation");

    this.setOrigin(0.45, 0.84);

    this.anims.play("explode");
    
    
    this.on(
      Phaser.Animations.Events.ANIMATION_COMPLETE_KEY + "explode",
      (_) => {
        //this._character.fadeOut();
        this.destroy();
    });
  }
}
