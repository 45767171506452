import { App } from "vue";

function camelToDash(str: string) {
  if (str != str.toLowerCase()) {
    str = str
      .split(/\.?(?=[A-Z])/)
      .join("-")
      .toLowerCase();
  }
  return str;
}
export function pathToComponentName(path: string) {
  return path
    .split("/")
    .pop()
    .replace(/\.\w+$/, "");
}
export default function RegisterGlobalComponents(app: App<Element>) {
  Object.entries(
    import.meta.glob("@uicore/global/*.vue", {
      eager: true,
    }),
  ).forEach(([path, file]) => {
    app.component(
      camelToDash(pathToComponentName(path)),
      (file as any).default,
    );
  });
}
