import SceneComponent from "@game/engine/components/SceneComponent";
import { EnumUiEvents, handleEvent } from "@shared/Events";
import { gameAudioValues } from "@shared/Global";
import { TUiGameSettingsUpdateAudio } from "@shared/types";
import SoundConfig = Phaser.Types.Sound.SoundConfig;
import WebAudioSound = Phaser.Sound.WebAudioSound;
import {
  mobileAddBackgroundMusic,
  mobileAddSoundEffects,
  mobilePauseSoundEffects,
  mobilePlayBackgroundAudio,
  mobilePlaySoundEffect,
  mobileResumeSoundEffects,
  mobileStopBackgroundAudio,
  mobileStopSoundEffect,
} from "@/mobile/audio";

interface HTML5AudioOptions {
  instances: number;
}

enum AudioType {
  BackgroundAudio,
  SoundEffect,
}

export interface IAudio {
  config?: SoundConfig;
  key: string;
  options?: HTML5AudioOptions;
  path: string;
  type?: AudioType;
}

export default class AudioComponent extends SceneComponent {
  public get backgroundAudioVolume(): number {
    return this._backgroundAudio ? this._backgroundAudio.volume : 1.0;
  }

  public set backgroundAudioVolume(volume: number) {
    if (this._backgroundAudio) {
      this._backgroundAudio.volume = Phaser.Math.Clamp(volume, 0, 1);
    }
  }

  public get masterVolume(): number {
    return this._scene && this._scene.sound ? this._scene.sound.volume : 1.0;
  }

  public set masterVolume(volume: number) {
    if (this._scene && this._scene.sound) {
      this._scene.sound.volume = Phaser.Math.Clamp(volume, 0, 1);
    }
  }

  public set pauseOnBlur(pauseOnBlur: boolean) {
    if (this._scene && this._scene.sound) {
      this._scene.sound.pauseOnBlur = pauseOnBlur;
    }
  }

  public get soundEffectVolume(): number {
    return this._soundEffectVolume;
  }

  public set soundEffectVolume(volume) {
    this._soundEffectVolume = volume;

    this.setSoundEffectVolumes(this._soundEffectVolume);
  }

  private _backgroundAudio: WebAudioSound;
  private _soundEffectVolume: number = 1.0;
  private _sounds: { [key: string]: WebAudioSound } = {};
  private _audios: IAudio[] = [];

  public addBackgroundMusic(audio: IAudio) {
    audio.type = AudioType.BackgroundAudio;
    this._audios.push(audio);
    this.registerAudios([audio]);
    mobileAddBackgroundMusic(audio);
  }

  public addSoundEffects(audios: IAudio[]) {
    audios.forEach((audio) => {
      this._audios.push(audio);
    });
    this.registerAudios(audios);
    mobileAddSoundEffects(audios);
  }

  public onShutdown() {
    super.onShutdown();
    this.stopBackgroundAudio();
    mobileStopBackgroundAudio();
  }

  public isPaused(key: string): boolean {
    if (key in this._sounds) {
      return this._sounds[key].isPaused;
    }
    return false;
  }

  public isMuted(): boolean {
    return this._scene.sound.mute;
  }

  public mute() {
    this._scene.sound.mute = true;
  }

  public pause(key: string): WebAudioSound {
    if (key in this._sounds) {
      const sound = this._sounds[key];
      mobilePauseSoundEffects(key);
      sound.pause();
      return sound;
    }
    return null;
  }

  public resume(key: string): WebAudioSound {
    if (key in this._sounds) {
      const sound = this._sounds[key];
      sound.resume();
      mobileResumeSoundEffects(key);
      return sound;
    }
    return null;
  }

  public play(
    key: string,
    loop: boolean = false,
    volume: number = 1,
    config?: SoundConfig,
  ): WebAudioSound {
    if (!config) {
      config = { loop: loop, volume: volume };
    } else {
      config.loop = config.loop || loop;
      config.volume = config.volume || volume;
    }
    if (key in this._sounds) {
      const sound = this._sounds[key];
      sound.play(config);
      mobilePlaySoundEffect(key, loop);
      return sound;
    }

    return null;
  }

  public playBackgroundAudio(
    loop: boolean = true,
    volume: number = 1,
    config?: SoundConfig,
  ) {
    if (this._backgroundAudio) {
      if (!config) {
        config = { loop: loop, volume: volume };
      } else {
        config.loop = config.loop || loop;
        config.volume = config.volume || volume;
      }
      mobilePlayBackgroundAudio(loop);
      this._backgroundAudio.play(config);
    }
  }

  public stop(key: string) {
    if (key in this._sounds) {
      const sound = this._sounds[key];
      sound.stop();
      mobileStopSoundEffect(key);
      return sound;
    }

    return null;
  }

  public stopBackgroundAudio() {
    if (this._backgroundAudio) {
      mobileStopBackgroundAudio();
      this._backgroundAudio.stop();
    }
  }

  public unmute() {
    this._scene.sound.mute = false;
  }

  private updateAudios(data: TUiGameSettingsUpdateAudio) {
    for (const audio of Object.entries(data)) {
      switch (audio[0]) {
        case "master":
          if (audio[1].muted && !this._scene.sound.mute) {
            mobileStopBackgroundAudio();
            this.mute();
            break;
          }
          if (!this._scene.sound.mute) {
            this.unmute();
          }
          this.masterVolume = audio[1].value;
          break;
        case "music":
          if (audio[1].muted && this.backgroundAudioVolume > 0) {
            this.backgroundAudioVolume = 0;
            break;
          }
          if (this.backgroundAudioVolume == 0) {
            this.backgroundAudioVolume = audio[1].value;
          }
          break;
        case "sfx":
          if (audio[1].muted && this.soundEffectVolume > 0) {
            this.soundEffectVolume = 0;
            break;
          }
          if (this.soundEffectVolume == 0) {
            this.soundEffectVolume = audio[1].value;
          }
          break;
      }
    }
  }
  private onAudiosRegistered() {
    this.updateAudios(gameAudioValues);
    handleEvent<TUiGameSettingsUpdateAudio>(
      EnumUiEvents.UiGameSettingsUpdateAudio,
      this.updateAudios.bind(this),
    );
  }

  private registerAudios(audios?: IAudio[]) {
    // Add all sounds to the library
    audios.forEach((audio: IAudio) => {
      // Set a background audio if possible, otherwise add it as a sound effect
      if (audio.type === AudioType.BackgroundAudio) {
        this._backgroundAudio = <WebAudioSound>(
          this._scene.sound.add(audio.key, audio.config)
        );
      } else {
        this._sounds[audio.key] = <WebAudioSound>(
          this._scene.sound.add(audio.key, audio.config)
        );
      }
    });

    this.onAudiosRegistered();
  }

  private setSoundEffectVolumes(volume) {
    for (const soundsKey in this._sounds) {
      this._sounds[soundsKey].volume = volume;
    }
  }
}
