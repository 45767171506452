import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import IsometricOrderableStack from "@game/engine/objects/IsometricOrderableStack";
import { Direction } from "@game/engine/navigation/Direction";
import Image = Phaser.GameObjects.Image;
import Vector2Like = Phaser.Types.Math.Vector2Like;
import PublicSpaceGameScene from "@engine/scenes/PublicSpaceGameScene";

export default class FurnitureObject {
  public lastClick: number = 0;

  readonly depth: number;
  readonly height: number;
  readonly id: string;
  readonly interactive: boolean = false;
  readonly name: string;
  readonly width: number;

  protected _frameNumber: number = 0;

  private _alive: boolean = true;
  private _animated: boolean = false;
  private _atlas: string;
  private _floorMatrix: Vector2Like[] = [];
  private _floorPositions: TilePosition3D[] = [];
  protected _frameTime: number = 0;
  private _frameRate: number = 10;
  private _numFrames: number;
  private _position: TilePosition3D;
  private _rotatedFloorMatrix: Vector2Like[] = [];
  private _direction: Direction = Direction.South;
  protected _scene: PublicSpaceGameScene;
  private _stacks: IsometricOrderableStack[] = [];
  private _tiles: Image[] = [];

  constructor(scene: PublicSpaceGameScene, name: string, id: string) {
    this._scene = scene;
    this._atlas = name;
    this.name = name;
    this.id = id;

    // Example name: modernbedblue_3x3x2_1
    const nameSplit = name.split("_");
    const dimensionsArray = nameSplit[1].split("x");

    this.width = Number.parseInt(dimensionsArray[0]);
    this.depth = Number.parseInt(dimensionsArray[1]);
    this.height = Number.parseInt(dimensionsArray[2]);
    this._numFrames = Number.parseInt(nameSplit[2]);
    this._animated = this._numFrames > 1;

    this.initializeStacks();
    this.initializeTiles();
    this.initializeFloorMatrix();

    if (this._animated) {
      scene.events.on("update", this.update, this);
    }
  }

  public set alpha(value: number) {
    this._tiles.forEach((tile) => (tile.alpha = value));
  }

  public get floorPositions(): TilePosition3D[] {
    return this._floorPositions;
  }

  public get position(): TilePosition3D {
    return this._position;
  }

  public get direction(): Direction {
    return this._direction;
  }

  public get tiles(): Image[] {
    return this._tiles;
  }

  // Used only for offline activated objects that don't require a server response.
  public activate() {}

  public destroy() {
    if (this._animated) {
      this._scene.events.off("update", this.update, this);
    }

    this._stacks.forEach((stack) => this._scene.removeOrderable(stack));
    this._tiles.forEach((tile) => tile.destroy());

    this._alive = false;
  }

  public collidesWithMapPositions(floorPositions: TilePosition3D[]): boolean {
    for (let i = 0; i < this._floorPositions.length; i++) {
      for (let j = 0; j < floorPositions.length; j++) {
        const pos1 = this.floorPositions[i];
        const pos2 = floorPositions[j];

        if (pos1.x === pos2.x && pos1.y === pos2.y && pos1.z === pos2.z) {
          return true;
        }
      }
    }
    return false;
  }

  public getNeighbourPositions(): TilePosition3D[] {
    const neighbours: TilePosition3D[] = [];

    const topLeft: Vector2Like = {
      x: this._floorPositions[0].x - 1,
      y: this._floorPositions[0].y - 1,
    };
    const bottomRight: Vector2Like = {
      x: this._floorPositions[this._floorPositions.length - 1].x + 1,
      y: this._floorPositions[this._floorPositions.length - 1].y + 1,
    };

    for (let x = topLeft.x; x <= bottomRight.x; x++) {
      for (let y = topLeft.y; y <= bottomRight.y; y++) {
        if (
          x === topLeft.x ||
          x === bottomRight.x ||
          y === topLeft.y ||
          y === bottomRight.y
        ) {
          neighbours.push({
            x: x,
            y: y,
            z: this._position.z,
          });
        }
      }
    }

    return neighbours;
  }

  public increaseRotation() {
    this.setDirection((this._direction + 1) % 4);
  }

  public setPosition(position: TilePosition3D) {
    this._position = {
      x: position.x,
      y: position.y,
      z: position.z,
    };

    this.updateTiles();
    this.updateFloorPositions();
  }

  public setDirection(direction: number) {
    this._direction = direction % 4;

    this.updateRotatedFloorMatrix();
    this.updateTiles();
    this.updateFloorPositions();
  }

  private initializeStacks() {
    for (let x = 0; x < this.width; x++) {
      for (let y = 0; y < this.depth; y++) {
        const stack = new IsometricOrderableStack();
        this._stacks.push(stack);
        this._scene.addOrderable(stack);
      }
    }
  }

  private initializeTiles() {
    for (let x = 0; x < this.width; x++) {
      for (let y = 0; y < this.depth; y++) {
        for (let z = 0; z < this.height; z++) {
          this._tiles.push(this._scene.add.image(0, 0, this._atlas));
        }
      }
    }
  }

  private initializeFloorMatrix() {
    const pivotX = Math.floor((this.width - 1) / 2);
    const pivotY = Math.floor((this.depth - 1) / 2);

    for (let x = 0; x < this.width; x++) {
      for (let y = 0; y < this.depth; y++) {
        this._floorMatrix.push({
          x: x - pivotX,
          y: y - pivotY,
        });
      }
    }

    this.updateRotatedFloorMatrix();
  }

  private getFrameIndex(
    tileIndex: number,
    layerIndex: number,
    overlay: boolean = false,
  ): number {
    // +2 % 4 because spritesheets start at South direction instead of North
    const col =
      ((this._direction + 2) % 4) * this.width + (tileIndex % this.width);
    const row =
      Math.floor(tileIndex / this.width) +
      this.depth * layerIndex +
      this._frameNumber * (this.depth * this.height);

    let frameIndex = row * this.width * 8 + col;

    if (overlay) {
      frameIndex += this.width * 4;
    }

    return frameIndex;
  }

  protected update(time, delta) {
    if (!this._alive) return;

    this._frameTime += delta;

    if (this._frameTime >= 1000 / this._frameRate) {
      this._frameNumber = (this._frameNumber + 1) % this._numFrames;
      this._frameTime -= 1000 / this._frameRate;
      this.updateFrames();
    }
  }

  protected updateFrames() {
    const texture = this._scene.textures.get(this._atlas);

    for (let i = 0; i < this._rotatedFloorMatrix.length; i++) {
      for (let i = 0; i < this._rotatedFloorMatrix.length; i++) {
        for (let z = 0; z < this.height; z++) {
          const tile = this._tiles[i + z * this._rotatedFloorMatrix.length];
          const frameName = this.getFrameIndex(i, z).toString();
          const exists = typeof texture.frames[frameName] !== "undefined";

          tile.visible = exists;
          if (exists) {
            tile.setTexture(this._atlas, frameName);
          }
        }
      }
    }
  }

  private updateTiles() {
    const texture = this._scene.textures.get(this._atlas);

    this._stacks.forEach((stack) => stack.clear());

    for (let i = 0; i < this._rotatedFloorMatrix.length; i++) {
      const stack = this._stacks[i];
      const floorOffset = this._rotatedFloorMatrix[i];

      for (let z = 0; z < this.height; z++) {
        const tile = this._tiles[i + z * this._rotatedFloorMatrix.length];
        const frameName = this.getFrameIndex(i, z).toString();
        const exists = typeof texture.frames[frameName] !== "undefined";
        const tilePos = {
          x: this._position.x + floorOffset.x,
          y: this._position.y + floorOffset.y,
          z: this._position.z,
        };
        const worldPos = this._scene.worldPositionAtTilePosition(tilePos);

        if (!worldPos) continue;

        tile.visible = exists;
        if (exists) {
          tile.setTexture(this._atlas, frameName);
          tile.setPosition(worldPos.x, worldPos.y - 16 - 96 * z); // Could this -16 offset be necessary because something was done wrong in Tiled?

          tilePos.z += z;
          stack.addPart(tilePos, tile);
        }
      }
    }
  }

  private updateFloorPositions() {
    this._floorPositions = [];

    for (let i = 0; i < this._rotatedFloorMatrix.length; i++) {
      this._floorPositions.push({
        x: this._position.x + this._rotatedFloorMatrix[i].x,
        y: this._position.y + this._rotatedFloorMatrix[i].y,
        z: this._position.z,
      });
    }
  }

  private updateRotatedFloorMatrix() {
    const matrix: Vector2Like[] = this._floorMatrix.map((element) => {
      return { x: element.x, y: element.y };
    });

    for (let i = 0; i < this._direction; i++) {
      matrix.forEach((element: Vector2Like) => {
        const x = element.x;

        element.x = -element.y;
        element.y = x;
      });
    }

    // So it grows from top left to bottom right
    matrix.sort((a, b) => {
      if (a.y !== b.y) return a.y - b.y;
      return a.x - b.x;
    });

    this._rotatedFloorMatrix = matrix;
  }
}
