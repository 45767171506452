export interface IInteractiveFurnitureConfig {
  random?: [number, number];
  sittable?: boolean;
  switchable?: boolean;
  usageInterval?: number;
  userPositions?: { x: number; y: number }[];
}

export const InteractiveFurnitureConfigs: {
  [key: string]: IInteractiveFurnitureConfig;
} = {
  // Led Dice
  "0x7A68265e94353D0F18E4563a21f84E7325293914": {
    random: [1, 6],
    usageInterval: 2000,
  },

  // Traditional Dice
  "0x6bA0d2514aE9f02cf090b001d6D1460e7a5f8bf5": {
    random: [1, 6],
    usageInterval: 2000,
  },

  // Modern Sofa
  // "0x24Db3309707756046A31f3DE2d3c58959E5dc6Ae": {
  //   sittable: true,
  //   userPositions: [
  //     { x: 0, y: 0 },
  //     { x: 1, y: 0 },
  //   ],
  // },

  // Wooden Divider Door
  "0x95575D7118B7A14DfBFDdC9dac677Ab4795a7360": {
    switchable: true,
    usageInterval: 1000
  }
};
