import { MatchData, Presence } from "@heroiclabs/nakama-js";
import { PlinkoOpCodes } from "@common/modules/plinko/PlinkoOpCodes";
import { NetworkedComponent } from "@engine/components/scenecomponents/v2/NetworkedComponent";
import { dispatchEvent, EnumNetworkEvents } from "@shared/Events";
import { PlinkoRisks } from "../common/enums/PlinkoRisks";
import PlinkoScene from "../PlinkoScene";
import EventEmitter from "eventemitter3";

interface IOnStartGameData {
  finalMoves: number[][];
}

interface IOnRefreshGameData {
  updatedRows: number;
  updatedBalls: number;
  updatedRisk: PlinkoRisks;
  updatedMultipliers: number[];
}
export class PlinkoNetworkedComponent extends NetworkedComponent {
  public eventEmitter: EventEmitter;
  protected declare _scene: PlinkoScene;
  constructor(matchData: { [key: string]: string }) {
    super("plinkov2", matchData);
    this.eventEmitter = new EventEmitter();
  }

  public override async findAndJoinMatch(): Promise<boolean> {
    return super.findAndJoinMatch(this._module, this._matchData);
  }

  public sendEndOfAnimation() {
    this.sendMatchState(PlinkoOpCodes.AnimationFinished, {});
  }

  public sendRefreshGame() {
    this.sendMatchState(PlinkoOpCodes.RefreshGame, {});
  }

  public sendStartGame() {
    this.sendMatchState(PlinkoOpCodes.StartGame, {});
  }

  public sendUpdateOfData(rows: number, balls: number, risk: PlinkoRisks) {
    this.sendMatchState(PlinkoOpCodes.UpdateData, {
      rows: rows,
      balls: balls,
      risk: risk,
    });
  }

  protected override onMatchData(matchData: MatchData) {
    switch (matchData.op_code) {
      case PlinkoOpCodes.StartGameData: {
        const { finalMoves } = this.decodeAndParseMatchData<IOnStartGameData>(
          matchData.data,
        );
        this.onStartGame(finalMoves);
        break;
      }
      case PlinkoOpCodes.RefreshGameData: {
        const { updatedRows, updatedBalls, updatedRisk, updatedMultipliers } =
          this.decodeAndParseMatchData<IOnRefreshGameData>(matchData.data);
        this.onRefreshGame(
          updatedRows,
          updatedBalls,
          updatedRisk,
          updatedMultipliers,
        );
        break;
      }
      case PlinkoOpCodes.ResetGame: {
        const { updatedRows, updatedBalls, updatedRisk, updatedMultipliers } =
          this.decodeAndParseMatchData<IOnRefreshGameData>(matchData.data);
        this.onRefreshGame(
          updatedRows,
          updatedBalls,
          updatedRisk,
          updatedMultipliers,
        );
        break;
      }
    }
  }
  private onRefreshGame(
    updatedRows: number,
    updatedBalls: number,
    updatedRisk: PlinkoRisks,
    updatedMultipliers: number[],
  ) {
    dispatchEvent(EnumNetworkEvents.NetworkRequestUserData);
    this._scene
      .initializeGame(
        updatedRows,
        updatedBalls,
        updatedRisk,
        updatedMultipliers,
      )
      .finally();
  }

  private onStartGame(finalMoves: number[][]) { 
    dispatchEvent(EnumNetworkEvents.NetworkRequestUserData);
    this._scene.startGame(finalMoves);
  }

  protected onPresenceJoined(presence: Presence): void {
    this.eventEmitter.emit("userJoined");
  }

  protected onPresenceLeft(presence: Presence): void {
    this.eventEmitter.emit("userLeft");
  }
}
