import { ResourcesHelper } from "@engine/utilities/AssetLoaderHelper";
import { currentAccount } from "@shared/Global";
import { formatNftName, replacePairs, toTitleCase } from "@shared/Helpers";
import { NFTHelpers } from "@shared/nft";
import { computed } from "vue";
export function getMinigameAsset(name: string) {
  return `/assets/overlay/minigames/${name}.png`;
}

export function getMinigameIconAsset(name: string) {
  return `/assets/overlay/minigames/icons/${name}.png`;
}

export function getMinigameTitle(name: string) {
  return `/assets/overlay/minigames/titles/${name}.png`;
}

export function getTitleIcon(name: string) {
  return `/assets/overlay/titles/${name}.png`;
}

// Was commented out by Kaspars. Uncommenting back cause it affects `npm run dev`
export function getAsset(path: string) {
  return `/assets/overlay/${path}.png`;
}

export function getBanner(path: string) {
  return `/assets/overlay/banner/${path}.png`;
}

export function getMisc(path: string) {
  return `/assets/overlay/misc/${path}.png`;
}

export function getFurnitureIcon(name: string) {
  return `/assets/overlay/icons/furniture/${name}.png`;
}

export function getIcon(name: string = "") {
  if (!name) "";
  return `/assets/overlay/icons/${name}.png`;
}

export function getGlyph(name: string) {
  return `/assets/overlay/icons/glyphs/${name}.png`;
}

export function getNetworkIcon(name: string) {
  return `/assets/overlay/networks/${name}.png`;
}

export function characterNameToImage(name: string) {
  if (name) {
    name = replacePairs(name, { _: " " });
    try {
      return NFTHelpers.NFT.fromName(name).name;
    } catch (e) {
      return "undefined";
    }
  }
  return toTitleCase(name);
}

export function getNftImage(name: string) {
  const url = `/assets/overlay/nfts/${name}.png`;

  if (ResourcesHelper.resourceExists(url)) {
    return url;
  } else {
    return "undefined";
  }

  // if (name) {
  //   name = replacePairs(name, { _: " ", ".": "" });
  //
  //   // name = new URL(`/assets/overlay/nfts/${name}.png`, import.meta.url).href;
  //   // // name = getAsset(`nfts/${name}.png`);
  // }
  // return name;
}

export function getCharacterImage(name: string) {
  return `/assets/overlay/nfts/${characterNameToImage(name)}.png`;
}

export function getCharacterAvatar(name: string) {
  return `/assets/overlay/avatar/${formatNftName(
    characterNameToImage(name),
  )}.png`;
}

export const currentAccountAvatar = computed(() =>
  getCharacterAvatar(
    currentAccount?.user?.metadata?.activeCharacter?.name || "",
  ),
);
export const currentAccountImage = computed(() =>
  getCharacterImage(
    currentAccount?.user?.metadata?.activeCharacter?.name || "",
  ),
);
