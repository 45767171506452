export default [
  {
    address: ["0xb117d7d49f5e582771509b604Bac9aF68c75271A"],
    type: "furniture",
    metadata: {
      name: "JackoLantern",
      description:
        "An epic and spooky pumpkin lantern that only the most scary hotel guests own! Great halloween decoration that lights up in the dark!",
      attributes: [
        { trait_type: "Rarity", value: "Epic" },
        { trait_type: "Replicas", value: "10" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "jackolantern_1x1x1_25",
    },
  },
  {
    address: ["0xb6618d6baD5A7D4Db5eca9Eb2F2fDB4e83e275Ca"],
    type: "furniture",
    metadata: {
      name: "Squirrel",
      description:
        "A very cute little squirrel that loves to eat little nuts when yore not looking. Great friend and pet for your room.",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "40" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "squirrel_1x1x1_60",
    },
  },
  {
    address: ["0x7875C6Bd60e2471C30CEa68863C773838dD50d0F"],
    type: "furniture",
    metadata: {
      name: "Small Autumn Tree",
      description:
        "A beautiful autumn colored oak tree that can be planted in your own room, very limited amount of trees are sold every year due to their exquisite quality and colors.",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "50" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "tallautumntreeorange_1x1x4_1",
    },
  },
  {
    address: ["0x7372D21F2a6dAF49Ba067843B0a4f6c878827c46"],
    type: "furniture",
    metadata: {
      name: "Jumbo Pumpkinspice Latte",
      description:
        "The largest and most delicious pumpkin spice latte you'll ever see in the Bit Hotel. Great decoration for those coffee lovers that want to cosy up their room!",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "60" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "jumbopumpkinspicedlatte_1x1x2_12",
    },
  },
  {
    address: ["0x7f6df7df0Ca51496b71bd419B0BC6d801ACD448a"],
    type: "furniture",
    metadata: {
      name: "Autumn Leaf Pile",
      description:
        "A small pile of colorful autumn leafs that can be placed in your room to give it an autumn theme.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "80" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "autumnleafpile_1x1x2_1",
    },
  },
  {
    address: ["0x74999e2019dA26917Cf2c2E1ddB6F7c417B42634"],
    type: "furniture",
    metadata: {
      name: "Autumn Log Fence",
      description:
        "A log divider with autumn leafs spread all over it great for making sections in your room with an autumn style.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "100" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "autumnlogdivider_2x1x2_1",
    },
  },
  {
    address: ["0xD686511a635B17F334aCD4a74Ca51c710f4B174A"],
    type: "furniture",
    metadata: {
      name: "Scarecrow",
      description:
        "A very scary little scarecrow that makes sure no unwanted hotel guests enter your room! Great for farm or halloween themed room builds!",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "300" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "scarecrow_1x1x3_1",
    },
  },
  {
    address: ["0x2395407fe62DF7640fc32625024D234dF66Ba45D"],
    type: "furniture",
    metadata: {
      name: "Autumn Decor",
      description:
        "A small autumn themed basked full of little pumpkins. A great little decoration for your room to give it a more cosy autumn vibe.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "500" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "autumndecorbasket_1x1x1_1",
    },
  },
];
