import { useModalDisplayDataStore } from "@store";
import { defineStore } from "pinia";
import { TModalIds } from "./managers/components";
import { EnumUiEvents } from "@shared/enums";
import { dispatchEvent } from "@shared/Events";
type TModal = {
  modalId: TModalIds | "";
  modalData?: { [key: string]: any };
};
type TModalHistoryObject = TModal & {};
export const useModalStore = defineStore("uiModal", {
  state: () => ({
    activeModal: { modalId: "" } as TModal,
    modalVisibility: {
      ChallengesModal: false,
      ChangeWalletModal: false,
      CryptoBomberInstructions: false,
      DepositModal: false,
      FriendsListModal: false,
      GameOverModal: false,
      GenericMessageModal: false,
      InventoryModal: false,
      LeaderboardModal: false,
      MarketplaceModal: false,
      MinigameScoreboardModal: false,
      MinigamesModal: false,
      NoTicketsModal: false,
      NpcInteractionModal: false,
      OnboardingModal: false,
      ReferralModal: false,
      RoomEditorInventoryModal: false,
      SpecialOfferModal: false,
      SendTicketsModal: false,
      SettingsModal: false,
      StoreItemModal: false,
      StoreModal: false,
      TextInstructionsModal: false,
      TopupModal: false,
      TradeBoxModal: false,
      TradeProposalModal: false,
      VipModal: false,
      RoomsModal: false,
    } as { [key in TModalIds]: boolean },
    _modalHistory: [
      {
        modalId: "",
      },
    ] as Array<TModalHistoryObject>,
  }),
  getters: {
    modalHistory(state): Array<TModalHistoryObject> {
      return [...state._modalHistory, state.activeModal];
    },
    lastOpenModal(state): TModal {
      return (
        state._modalHistory.slice(-1)[0] || {
          modalId: "",
        }
      );
    },
    displayData() {
      return useModalDisplayDataStore();
    },
  },
  actions: {
    openModal(modalId: TModalIds, modalData?: { [key: string]: any }) {
      if (!Object.keys(this.modalVisibility).includes(modalId)) {
        throw new Error(
          `Modal with id "${modalId}" was not defined, available modals are: ${Object.keys(
            this.modalVisibility,
          ).join(", ")}`,
        );
      }
      if (this.activeModal.modalId !== "") {
        this.closeModal(this.activeModal.modalId);
      }
      if (this.modalHistory.length > 0) {
        this.modalVisibility[this.activeModal.modalId as TModalIds] = false;
        //gracefully close modals
      }

      this.modalVisibility[modalId] = true;
      this.activeModal = { modalId, modalData: modalData || {} };
      dispatchEvent(
        EnumUiEvents.UiOnboardingActiveModal,
        this.activeModal.modalId,
      );
    },
    closeAllModals() {
      for (const modalId in Object.keys(this.modalVisibility)) {
        this.closeModal(modalId as TModalIds, true);
      }

      this.activeModal = { modalId: "" };
      this.addModalHistory({ modalId: "" });
      dispatchEvent(
        EnumUiEvents.UiOnboardingActiveModal,
        this.activeModal.modalId,
      );
    },
    closeModal(modalId: TModalIds | "", skipHistory: boolean = false) {
      if (
        modalId !== "" &&
        this.modalVisibility[modalId as TModalIds] === true
      ) {
        if (!skipHistory) {
          this.addModalHistory(this.activeModal);
        }
        this.modalVisibility[modalId] = false;
        if (this.activeModal.modalId === modalId) {
          this.activeModal = {
            modalId: "",
          };
        }
      }
    },
    addModalHistory(historyObject: TModalHistoryObject) {
      this._modalHistory.push(JSON.parse(JSON.stringify(historyObject)));
    },
  },
});
