import ParticleEmitter = Phaser.GameObjects.Particles.ParticleEmitter;

export class ParticleEmitterPositionProxy {
  public get x() {
    return this._target.x.propertyValue;
  }

  public set x(value: number) {
    this._target.x.onChange(value);
    // this._target.setPosition(value, this._target.y.propertyValue);
  }

  public get y() {
    return this._target.y.propertyValue;
  }

  public set y(value: number) {
    this._target.y.onChange(value);
    // this._target.setPosition(this._target.x.propertyValue, value);
  }

  constructor(private _target: ParticleEmitter) {}
}
