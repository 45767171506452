import EntityComponent from "../EntityComponent";
import TilePath from "../../objects/TilePath";
import Camera = Phaser.Cameras.Scene2D.Camera;
import Path = Phaser.Curves.Path;
import Vector2 = Phaser.Math.Vector2;
import EventEmitter = Phaser.Events.EventEmitter;
import Tilemap = Phaser.Tilemaps.Tilemap;

export default class TilePathFollowerComponent extends EntityComponent {
  public static EventTypes = { Completed: "completed", Started: "started" };

  // For communication with others
  public events: EventEmitter;
  public speed: number = 256.0;

  private _distance: number = 0;
  private _path: Path;
  private _tilePath: TilePath;

  constructor(private _map: Tilemap) {
    super();
    this.events = new EventEmitter();
    this.isTickEnabled = true;
  }

  public follow(tilePath: TilePath, camera?: Camera) {
    if (!tilePath.isFollowable()) {
      // Nah, not going to even try.
      return;
    }

    this._tilePath = tilePath;
    this._path = this._tilePath.toFollowablePathFromMap(
      this._map,
      this.owner,
      true,
    );
    this._distance = 0;

    // Start at 0 progress
    this.moveToPositionOnPath(0);

    // Let others know we've started
    this.events.emit(
      TilePathFollowerComponent.EventTypes.Started,
      this._tilePath,
    );
  }

  stopFollow() {
    this._path = null;

    this.events.emit(
      TilePathFollowerComponent.EventTypes.Completed,
      this._tilePath,
    );
  }

  moveToPositionOnPath(percentage: number) {
    const position: Vector2 = this._path.getPoint(percentage);

    if (null !== position) {
      // Move the owner into position

      this.owner.x = position.x;
      this.owner.y = position.y;
    }
  }

  public override tick(deltaSeconds: number, deltaTime: number): void {
    if (!this._path) {
      return;
    }

    let newDistance =
      this._distance + (this.speed * deltaSeconds) / this._path.getLength();
    if (newDistance >= 1.0) {
      this.moveToPositionOnPath(1.0);

      // Let others know we've finished

      this.events.emit(
        TilePathFollowerComponent.EventTypes.Completed,
        this._tilePath,
      );

      this._path = null;
      this._tilePath = null;
    } else {
      this.moveToPositionOnPath(newDistance);
    }
    this._distance = newDistance;
  }
}
