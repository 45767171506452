import { Scene } from "phaser";
import GameObject = Phaser.GameObjects.GameObject;
import { Toast } from "@engine/notifications/Toast";

interface IPositionedObject {
  setDepth(depth: number): void;
  x: number;
  y: number;
}

export enum ScreenAnchorPosition {
  Normal,
  Center,
  Edge,
  Relative,
  EdgeRelative,
}

export enum OverlayDepthLayer {
  Bottom,
  Middle,
  Top,
}

export type TAnchoredPosition = {
  x: { value: number; anchor: ScreenAnchorPosition };
  y: { value: number; anchor: ScreenAnchorPosition };
};

type TAnchoredElement = {
  object: GameObject;
  position: TAnchoredPosition;
};

export default class OverlayScene extends Scene {
  private _anchoredElements: TAnchoredElement[] = [];

  constructor() {
    super("OverlayScene");
  }

  public preload() {
    this.load.atlas(
      "common_atlas",
      "/assets/game/mini/common/common_atlas.png",
      "/assets/game/mini/common/common_atlas.json",
    );
  }

  public addAnchoredObject(
    object: GameObject,
    position: TAnchoredPosition,
    depth: OverlayDepthLayer = OverlayDepthLayer.Middle,
  ) {
    const positionedObject = object as unknown as IPositionedObject;
    this._anchoredElements.push({
      object: object,
      position: position,
    });
    this.repositionAnchoredObject(positionedObject, position);

    // Had to add this check because parts of the game use some Rex UI elements
    // that do not follow Phaser's GameObject structure and conflict with depth
    // sorting (Mario)
    if (positionedObject.setDepth) {
      positionedObject.setDepth(depth);
    }

    this.add.existing(object);
  }

  public removeAnchoredObject(object: GameObject) {
    const anchoredElement = this._anchoredElements.find(
      (element) => element.object === object,
    );
    const index = this._anchoredElements.indexOf(anchoredElement);

    if (index >= 0) {
      this._anchoredElements.splice(index, 1);
      anchoredElement.object.destroy(true);
    }
  }

  public create() {
    Toast.initialize(this);

    this.events.once("shutdown", this.onShutdown, this);
    this.scale.on("resize", this.onResize, this);
  }

  protected onShutdown() {
    this.scale.off("resize", this.onResize, this);
  }

  private anchoredCoordinateToScreenCoordinate(
    coordinate: { value: number; anchor: ScreenAnchorPosition },
    maxDimension: number,
  ): number {
    switch (coordinate.anchor) {
      case ScreenAnchorPosition.Normal:
        return coordinate.value;
      case ScreenAnchorPosition.Center:
        return maxDimension / 2 + coordinate.value;
      case ScreenAnchorPosition.Edge:
        return maxDimension + coordinate.value;
      case ScreenAnchorPosition.Relative:
        return maxDimension * coordinate.value;
      case ScreenAnchorPosition.EdgeRelative:
        return maxDimension + maxDimension * coordinate.value;
    }
  }

  private repositionAnchoredObject(
    object: IPositionedObject,
    position: TAnchoredPosition,
  ) {
    object.x = this.anchoredCoordinateToScreenCoordinate(
      position.x,
      this.scale.width,
    );
    object.y = this.anchoredCoordinateToScreenCoordinate(
      position.y,
      this.scale.height,
    );
  }

  private onResize() {
    this._anchoredElements.forEach((element) => {
      const object = element.object as unknown as IPositionedObject;

      this.repositionAnchoredObject(object, element.position);
    });
  }
}
