import { InteractableEventTypes } from "@game/engine/eventtypes/InteractableEventTypes";
import { NPCInteractableEventTypes } from "@game/engine/eventtypes/NPCInteractableEventTypes";
import { INPCSpawnerDelegate } from "@game/engine/interfaces/INPCSpawnerDelegate";
import OrderableIsometricGameScene from "@game/engine/scenes/OrderableIsometricGameScene";
import OrderableNonPlayerCharacter from "@game/engine/gameobjects/OrderableNonPlayerCharacter";
import SceneComponent from "@game/engine/components/SceneComponent";
import { Direction, NameToDirection } from "@game/engine/navigation/Direction";
import { getTilePositionFromPropertiesContainer } from "@game/engine/objects/PropertiesContainer";
import GameScene from "@game/engine/scenes/GameScene";
import EventEmitter = Phaser.Events.EventEmitter;

export default class NPCSpawnerSceneComponent extends SceneComponent {
  public set delegate(value: INPCSpawnerDelegate) {
    this._delegate = value;
  }

  public events: EventEmitter;
  public npcs: OrderableNonPlayerCharacter[];

  protected declare _scene: OrderableIsometricGameScene;

  private _delegate: INPCSpawnerDelegate;

  constructor() {
    super();

    this.npcs = [];
    this.events = new EventEmitter();
  }

  public registerInteractable(npc: OrderableNonPlayerCharacter) {
    // Attach ourselves to interactable NPCs
    npc.on(InteractableEventTypes.OnHover, (npc) => {
      this._delegate?.onNPCHover(npc);
      this.events.emit(NPCInteractableEventTypes.OnHover, npc);
    });
    npc.on(InteractableEventTypes.OnInteract, (npc) => {
      this._delegate?.onNPCInteract(npc);
      this.events.emit(NPCInteractableEventTypes.OnInteract, npc);
    });
    npc.on(InteractableEventTypes.OnOut, (npc) => {
      this._delegate?.onNPCOut(npc);
      this.events.emit(NPCInteractableEventTypes.OnOut, npc);
    });
    this.events.emit(NPCInteractableEventTypes.OnSpawn, npc);
  }

  protected onSceneSet(scene?: GameScene) {
    super.onSceneSet(scene);

    const propertiesContainers =
      this._scene.getPropertiesContainersContainingType("npc");

    if (0 === propertiesContainers.length) {
      return;
    }

    propertiesContainers.forEach((propertiesContainer) => {
      const tilePosition =
        getTilePositionFromPropertiesContainer(propertiesContainer);

      // Retrieve the character to display
      const characterName = propertiesContainer.getProperty("Character");

      // Retrieve the direction to face the NPC
      const directionName = propertiesContainer.getProperty("Direction");
      let direction = Direction.South;
      if (directionName in NameToDirection) {
        direction = NameToDirection[directionName];
      }

      // Create an NPC and place it
      const npc = new OrderableNonPlayerCharacter(
        this._scene,
        tilePosition,
        characterName,
        direction,
        propertiesContainer,
        this,
      );
      this._scene.add.existing(npc);
      this._scene.addOrderable(npc);
      this.npcs.push(npc);
    });
  }

  protected onShutdown() {
    super.onShutdown();

    this.npcs.length = 0;
  }
}
