export enum PublicSpaceOpCodes {
  // ALWAYS start at 1
  OnInitializeWorld = 1,
  OnPlayerPermissionsChangeRequest,
  OnPlayerPermissionsChanged,
  OnPlayerChangedAvatar,
  OnPlayerKickRequest,
  OnPlayerKicked,
  OnPlayerInteracted,
  OnPlayerJoined,
  OnPlayerLeft,
  OnPlayerSetGoal,
  OnPlayerTeleported,
  OnFurnitureAdded,
  OnFurnitureActivationRequest,
  OnFurnitureActivationResponse,
  OnFurnitureMoved,
  OnFurnitureRemoved,
}
