export default [
  {
    address: ["0x705249656863394368C9D298820908139c7C4Ec0"],
    type: "furniture",
    metadata: {
      name: "Smart Fridge",
      description:
        "This smart fridge is often reffered to as the 'coolest' part in your room. There is nothing better than opening your fridge and seeing it stocked up completely, give it a try! No party is complete with food and drinks. Only very few hotel guests own this smart fridge making it a very sought-after item in the hotel.",
      attributes: [
        { trait_type: "Rarity", value: "Epic" },
        { trait_type: "Replicas", value: "10" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "smartfridgesilver_2x1x3_4",
    },
  },
  {
    address: ["0x186af484614Be73369aCB65413a10c36f164aeB6"],
    type: "furniture",
    metadata: {
      name: "Moonbeam Arcade",
      description:
        "One of the most rare and stylish mini-game furniture pieces of the arcade collection. Play Moonbeam mini-games directly from your room with hidden advantages by placing this arcade machine down in your room. Only very few Hotel guests own any item of the arcade machine collection.",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "40" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "moonbeamarcademachine_1x1x2_1",
    },
  },
  {
    address: ["0x7660C2b5E928bC02048627b7741488012d953B73"],
    type: "furniture",
    metadata: {
      name: "Big Pet Bed",
      description:
        "An upgrade to the regular pet bed to give your lovely companion just that bit of extra space he deserves. This way multiple of our pets are able to relax and have a nap in your room.",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "50" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "petbedbig_2x1x2_1",
    },
  },
  {
    address: ["0x00dBa6d342a647762847A648401Cf9403e656768"],
    type: "furniture",
    metadata: {
      name: "8-Bit Heart Rug",
      description:
        "The stylish pixelart rug wanted by every gamer of the Bit Hotel. This 8-bit rug comes heart-shaped and is a great addition to your room's setup, combines especially well with the controller table.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "60" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "8bitheartrugpurple_4x4x1_1",
      disabled: true,
    },
  },
  {
    address: ["0x2cB194Cc909b424b02e636491DB0D516C9d6ef12"],
    type: "furniture",
    metadata: {
      name: "Basic Seat",
      description:
        "Comfortable, cool, and stylish. A great seat that adds a finishing touch to your own unique room.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "80" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "basicseatpurple_1x1x2_2",
    },
  },
  {
    address: ["0x95575D7118B7A14DfBFDdC9dac677Ab4795a7360"],
    type: "furniture",
    metadata: {
      name: "Wooden Divider Door",
      description:
        "Comfortable, cool, and stylish. A great seat that adds a finishing touch to your own unique room.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "100" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "woodendividerdoor_1x1x2_2",
    },
  },
  {
    address: ["0x2209a876E8Fd8cFBB81d15E1B0575a459A67f364"],
    type: "furniture",
    metadata: {
      name: "Wooden Divider",
      description:
        "A simple wooden fence that allows the owner to close off a part of their room.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "300" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "woodendivider_2x1x2x1",
    },
  },
  {
    address: ["0xa03B53F76CD1B9248904552d07698709969D7cb5"],
    type: "furniture",
    metadata: {
      name: "Ottoman Bench",
      description:
        "A luxurious and posh bench often owned by the more classy hotel guests. Great for any luxurious room design while being relatively inexpensive.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "500" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "ottomanbenchblue_2x1x2_1",
    },
  },
];
