import EventEmitter = Phaser.Events.EventEmitter;
import { Client, Notification, Session, Socket } from "@heroiclabs/nakama-js";
import { NotificationCodes as NotificatioCodesOld } from "@shared/consts/NotificationCodes";
import { NotificationCodes } from "@common/constants/NotificationCodes";
import { Toast } from "@engine/notifications/Toast";
import { dispatchEvent, EnumUiEvents } from "@shared/Events";

// Temp
enum MessageCodes {
  SystemWide = 101,
  SystemToUser = 102,
}

export enum NotificationCenterUpdateTypes {
  ReceivedSystemMessage = "notificationtypereceivedsystemmessage",
  RetrievedNewStoredNotifications = "notificationcenterretrievednewstorednotifications",
  RetrievedChatDirectInvite = "notificationcenterretrievedchatdirectinvite",
  RetrievedFriendsInvite = "notificationcenterretrievedfriendsinvite",
  RetrievedFriendsAccept = "notificationcenterretrievedfriendsaccept",
  RetrievedLevelExperience = "notificationcenterretrievedlevelexperience",
  RetrievedLevel = "notificationcenterretrievedlevel",
  RetrievedTradeProposal = "notificationcenterretrievedtradeproposal",
  RetrievedTradeAnswer = "notificationcenterretrievedtradeanswer",
  RetrievedTradeChange = "notificationcenterretrievedtradechange",
  RetrievedTradePending = "notificationcenterretrievedtradepending",
  RetrievedTradeCommit = "notificationcenterretrievedtradecommit",
  RetrievedTradeAbandon = "notificationcenterretrievedtradeabandon",
  ReceivedUserTickets = "notificationcenterreceivedusertickets",
  ReceivedKarma = "notificationcenterreceivedkarma",
  ProgressedAchievement = "notificationcenterprogressedachievement",
  UnlockedAchievement = "notificationcenterunlockedachievement",
}

export default class NotificationCenter extends EventEmitter {
  private _client!: Client;
  private readonly _notifications: Notification[];
  private _session!: Session;
  private _socket!: Socket;

  private static _instance: NotificationCenter;

  static get instance() {
    if (!NotificationCenter._instance) {
      NotificationCenter._instance = new NotificationCenter();
    }
    return NotificationCenter._instance;
  }

  public get notifications() {
    return this._notifications;
  }

  private constructor() {
    super();

    this._notifications = [];
  }

  public displaySystemWideNotification(
    subject: string,
    message: string,
    repeatAmount: number = 1,
  ) {
    const notification = { subject, content: { message } };
    this.emit(
      NotificationCenterUpdateTypes.ReceivedSystemMessage,
      notification,
      repeatAmount,
    );
  }

  public initialize(client: Client, session: Session, socket: Socket) {
    this._client = client;
    this._session = session;
    this._socket = socket;

    // Hook into it
    this._socket.onnotification = this.onNotificationReceived;
  }

  public async retrievePendingNotifications(): Promise<Notification[]> {
    const notificationList = await this._client.listNotifications(
      this._session,
      100,
    );
    // Create a set with all current notification ids in them
    const notificationIds = new Set(
      this._notifications.map((notification) => notification.id),
    );
    const newNotifications: Notification[] = [];
    // Check if there are any notifications in here we don't already have
    if (notificationList.notifications) {
      for (const notification of notificationList.notifications) {
        if (!notificationIds.has(notification.id)) {
          notificationIds.add(notification.id);
          newNotifications.push(notification);
        }
      }
    }
    // Check if there are any new notifications
    if (newNotifications.length > 0) {
      this._notifications.push(...newNotifications);
      this.emit(
        NotificationCenterUpdateTypes.RetrievedNewStoredNotifications,
        newNotifications,
      );
    }

    return newNotifications;
  }

  private onNotificationReceived = (notification: Notification) => {
    // Tasks
    const { message } = <{ message: string }>notification.content;
    switch (notification.code) {
      case NotificationCodes.AchievementProgressed: {
        break;
      }
      case NotificationCodes.AchievementUnlocked: {
        console.log("ACHIEVEMENT UNLOCKED", notification);
        this.emit(
          NotificationCenterUpdateTypes.UnlockedAchievement,
          notification,
        );
        break;
      }

      case NotificationCodes.CircadianTaskCompleted: {
        Toast.info(<string>notification.subject);
        break;
      }

      case NotificationCodes.CircadianTaskProgressed: {
        Toast.info(<string>notification.subject);
        break;
      }

      case NotificationCodes.DailyTaskProgressed: {
        Toast.info(notification.subject + ": " + message);
        break;
      }

      case NotificationCodes.DailyTaskCompleted: {
        Toast.info(<string>notification.subject);
        break;
      }

      case NotificationCodes.DailyTaskStreakContinued: {
        Toast.info(<string>notification.subject);
        break;
      }

      case NotificationCodes.SystemForceLogout: {
        dispatchEvent(EnumUiEvents.UiLogout);
        break;
      }
    }

    if (MessageCodes.SystemWide === notification.code) {
      this.emit(
        NotificationCenterUpdateTypes.ReceivedSystemMessage,
        notification,
      );
      return;
    }
    if (MessageCodes.SystemToUser === notification.code) {
      this.emit(
        NotificationCenterUpdateTypes.RetrievedNewStoredNotifications,
        notification,
      );
      return;
    }
    if (
      NotificatioCodesOld.NOTIFICATION_CHAT_DIRECT_INVITE == notification.code
    ) {
      this.emit(
        NotificationCenterUpdateTypes.RetrievedChatDirectInvite,
        notification,
      );
    }
    if (NotificatioCodesOld.NOTIFICATION_FRIENDS_INVITE == notification.code) {
      this.emit(
        NotificationCenterUpdateTypes.RetrievedFriendsInvite,
        notification,
      );
    }
    if (NotificatioCodesOld.NOTIFICATION_FRIENDS_ACCEPT == notification.code) {
      this.emit(
        NotificationCenterUpdateTypes.RetrievedFriendsAccept,
        notification,
      );
    }
    if (
      NotificatioCodesOld.NOTIFICATION_TRADING_PROPOSAL_CODE ===
      notification.code
    ) {
      this.emit(
        NotificationCenterUpdateTypes.RetrievedTradeProposal,
        notification,
      );
      return;
    }
    if (
      NotificatioCodesOld.NOTIFICATION_TRADING_ANSWER_CODE === notification.code
    ) {
      this.emit(
        NotificationCenterUpdateTypes.RetrievedTradeAnswer,
        notification,
      );
      return;
    }
    if (
      NotificatioCodesOld.NOTIFICATION_TRADING_CHANGE_ITEM_CODE ===
      notification.code
    ) {
      this.emit(
        NotificationCenterUpdateTypes.RetrievedTradeChange,
        notification,
      );
      return;
    }
    if (
      NotificatioCodesOld.NOTIFICATION_TRADING_PENDING_CODE ===
      notification.code
    ) {
      this.emit(
        NotificationCenterUpdateTypes.RetrievedTradePending,
        notification,
      );
      return;
    }
    if (
      NotificatioCodesOld.NOTIFICATION_TRADING_COMMIT_CODE === notification.code
    ) {
      this.emit(
        NotificationCenterUpdateTypes.RetrievedTradeCommit,
        notification,
      );
      return;
    }
    if (
      NotificatioCodesOld.NOTIFICATION_TRADING_ABANDON_CODE ===
      notification.code
    ) {
      this.emit(
        NotificationCenterUpdateTypes.RetrievedTradeAbandon,
        notification,
      );
      return;
    }
    if (
      NotificatioCodesOld.NOTIFICATION_TRANSFER_TICKETS === notification.code
    ) {
      this.emit(
        NotificationCenterUpdateTypes.ReceivedUserTickets,
        notification,
      );
      return;
    }
    if (NotificatioCodesOld.NOTIFICATION_SEND_KARMA === notification.code) {
      this.emit(NotificationCenterUpdateTypes.ReceivedKarma, notification);
      return;
    }
    if (NotificatioCodesOld.NOTIFICATION_EXPERIENCE === notification.code) {
      this.emit(
        NotificationCenterUpdateTypes.RetrievedLevelExperience,
        notification,
      );
      return;
    }
    if (NotificatioCodesOld.NOTIFICATION_LEVEL === notification.code) {
      this.emit(NotificationCenterUpdateTypes.RetrievedLevel, notification);
      return;
    }
  };
}
