import SceneComponent from "@game/engine/components/SceneComponent";
import FurnitureObject from "@game/engine/components/scenecomponents/furniture/furniture-objects/FurnitureObject";
import FurnitureFactory from "@game/mini/room/FurnitureFactory";
import Pathfinder, { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import PublicSpaceGameScene from "@game/engine/scenes/PublicSpaceGameScene";
import CustomizableRoomScene from "@game/mini/room/CustomizableRoomScene";
import InteractiveFurnitureObject from "@game/mini/room/objects/InteractiveFurnitureObject";
import PhaserTilemapLayer = Phaser.Tilemaps.TilemapLayer;
import equals from "@game/engine/utilities/TilePositionHelper";
import { IInteractiveFurnitureUseResponse } from "@common/modules/rooms/interfaces/FurnitureOperations";
import { IInteractiveFurnitureData } from "@common/modules/rooms/interfaces/IInteractiveFurnitureData";
import { TRoomRole } from "@shared/types";
import Tile = Phaser.Tilemaps.Tile;
import { TilePosition3DUtilities } from "@common/utilities/TilePositionUtilities";

export type TFurniturePlacement = {
  id: string;
  name: string;
  position: TilePosition3D;
  direction: number;
};

export default class FurnitureComponent extends SceneComponent {
  private _furnitureList: FurnitureObject[] = [];
  private _navigatableLayers: PhaserTilemapLayer[];
  private _playerRole: TRoomRole;

  constructor(navigatableLayers: PhaserTilemapLayer[]) {
    super();

    this._navigatableLayers = navigatableLayers;
  }

  public addFloorTile(placement: TFurniturePlacement) {
    const furnitureObject = new FurnitureObject(
      this._scene,
      placement.name,
      placement.id,
    );

    furnitureObject.setPosition(placement.position);

    this._furnitureList.push(furnitureObject);
  }

  public addFurniture(placement: TFurniturePlacement): FurnitureObject {
    const furnitureObject = FurnitureFactory.createFurniture(
      this._scene,
      placement.name,
      placement.id,
    );

    furnitureObject.setPosition(placement.position);
    furnitureObject.setDirection(placement.direction);

    this._furnitureList.push(furnitureObject);

    return furnitureObject;
  }

  public addExistingFurniture(furnitureObject: FurnitureObject) {
    this._furnitureList.push(furnitureObject);
  }

  public doesFurnitureObjectFitIn(furnitureObject: FurnitureObject) {
    const spawnPos = (this._scene as PublicSpaceGameScene).spawnData
      .tilePosition;

    // Check if it's placed entirely on navigatable tiles
    for (let i = 0; i < furnitureObject.floorPositions.length; i++) {
      const position = furnitureObject.floorPositions[i];
      const tile = this._navigatableLayers[position.z].getTileAt(
        position.x,
        position.y,
      );

      if (
        // There's no tile here, or it's the spawn point
        tile === null ||
        equals(spawnPos, position)
      ) {
        return false;
      }
    }

    // Check if its floor tiles are not already occupied by another furniture piece
    for (let i = 0; i < this._furnitureList.length; i++) {
      if (
        this._furnitureList[i].id !== furnitureObject.id &&
        this._furnitureList[i].collidesWithMapPositions(
          furnitureObject.floorPositions,
        )
      ) {
        return false;
      }
    }

    return true;
  }

  public getClosestWalkablePositionToFurniture(
    startPos: TilePosition3D,
    furnitureObject: FurnitureObject,
    pathfinder: Pathfinder,
  ): TilePosition3D {
    const targetPositions = furnitureObject.getNeighbourPositions();

    let closestPosition: TilePosition3D;
    let closestDistanceSq: number = Number.MAX_VALUE;

    targetPositions.forEach((position) => {
      const tile = this._navigatableLayers[position.z].getTileAt(
        position.x,
        position.y,
      );

      if (tile && !pathfinder.isBlocked(position)) {
        const distanceSq = Phaser.Math.Distance.BetweenPointsSquared(
          startPos,
          position,
        );
        if (closestPosition) {
          if (distanceSq < closestDistanceSq) {
            closestPosition = position;
            closestDistanceSq = distanceSq;
          }
        } else {
          closestPosition = position;
          closestDistanceSq = distanceSq;
        }
      }
    });

    return closestPosition;
  }

  public getFloorTileFurnitureObjectAt(
    tilePosition: TilePosition3D,
  ): FurnitureObject {
    return this._furnitureList.find((furnitureObject) =>
      TilePosition3DUtilities.equals(furnitureObject.position, tilePosition),
    )!;
  }

  public getFurnitureObjectAt(tilePosition: TilePosition3D): FurnitureObject {
    for (let i = 0; i < this._furnitureList.length; i++) {
      const furniturePiece = this._furnitureList[i];

      if (furniturePiece.collidesWithMapPositions([tilePosition])) {
        return furniturePiece;
      }
    }

    return null;
  }

  public getFurnitureObjectById(id: string): FurnitureObject {
    return this._furnitureList.find(
      (furnitureObject) => furnitureObject.id === id,
    );
  }

  public moveFurniture(placement: TFurniturePlacement): FurnitureObject {
    const movingObject: FurnitureObject = this._furnitureList.find(
      (furnitureObject) => furnitureObject.id === placement.id,
    );

    if (movingObject === undefined) {
      return undefined;
    }

    const roomScene = this._scene as CustomizableRoomScene;

    roomScene.setFurnitureFloorAsBlocked(movingObject, false);
    movingObject.setPosition(placement.position);
    movingObject.setDirection(placement.direction);

    roomScene.setFurnitureFloorAsBlocked(movingObject, true);

    return movingObject;
  }

  public onFurnitureActivated(response: IInteractiveFurnitureUseResponse) {
    const object = this._furnitureList.find(
      (furnitureObject) => furnitureObject.id === response.objectId,
    );

    if (!object) return;

    (object as InteractiveFurnitureObject).processActivationResponse(response);
  }

  public onFurnitureInit(interactiveFurniture: {
    [key: string]: IInteractiveFurnitureData;
  }) {
    for (let key in interactiveFurniture) {
      const furnitureData = interactiveFurniture[key];
      const furnitureObject = this.getFurnitureObjectById(
        furnitureData.id,
      ) as InteractiveFurnitureObject;

      furnitureObject.setState(furnitureData.state);
    }
  }

  public removeFloorTile(
    mapTile: Tile,
    position: TilePosition3D,
    defaultIndex: number,
  ) {
    mapTile.index = defaultIndex;

    const removedObject: FurnitureObject = this._furnitureList.find(
      (furnitureObject) =>
        furnitureObject.name === "floor_tile" &&
        furnitureObject.position.x === position.x &&
        furnitureObject.position.y === position.y &&
        furnitureObject.position.z === position.z,
    );

    this._furnitureList.splice(this._furnitureList.indexOf(removedObject), 1);
  }

  public removeFurniture(furnitureId: string, destroy?: boolean) {
    const removedObject: FurnitureObject = this._furnitureList.find(
      (furnitureObject) => furnitureObject.id === furnitureId,
    );

    if (removedObject) {
      const roomScene = this._scene as CustomizableRoomScene;
      roomScene.setFurnitureFloorAsBlocked(removedObject, false);
      this._furnitureList.splice(this._furnitureList.indexOf(removedObject), 1);

      if (destroy) {
        removedObject.destroy();
      }
    }
  }

  public setPlayerRole(role: TRoomRole) {
    this._playerRole = role;
  }

  public shutdown() {
    super.shutdown();

    this._furnitureList.forEach((furnitureObject) => furnitureObject.destroy());
    this._furnitureList = [];
  }
}
