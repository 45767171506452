<script lang="ts" setup>
import { getGlyph } from "@overlay/assets";
import { EnumUiEvents, dispatchEvent } from "@shared/Events";
import { nextTick, onBeforeMount, onBeforeUnmount, Ref, ref } from "vue";
const props = defineProps({
  offset: {
    type: String,
    default: "50%",
    validator(value: string) {
      return value.includes("px") || value.includes("%");
    },
  },
  overrideAutoClose: {
    type: Boolean,
    default: false,
  },
});
const isActive = ref(false);
const toggle = (setActive?: boolean) => {
  if (setActive === undefined) {
    isActive.value = !isActive.value;
  } else {
    isActive.value = setActive;
  }
  dispatchEvent(EnumUiEvents.UiOpenUserMenu, isActive.value);
};
const wrapper: Ref<HTMLDivElement | null> = ref(null);
async function handleWindowClick(e: MouseEvent) {
  await nextTick();

  if (!props.overrideAutoClose && !wrapper.value?.contains(e.target as Node)) {
    toggle(false);
  }
}
onBeforeMount(() => {
  window.addEventListener("pointerdown", handleWindowClick);
});
onBeforeUnmount(() => {
  window.removeEventListener("pointerdown", handleWindowClick);
});
defineEmits(["openModal"]);
defineExpose({ toggle });
</script>
<template>
  <div ref="wrapper" :class="isActive ? 'active' : ''" @click="toggle()">
    <b-button
      type="submit"
      class="mobile padding-r--m padding-l--xsm padding-v--xxs"
      :class="{ left: offset.includes('-') }"
    >
      <template #button-before>
        <img
          src="/assets/overlay/icons/glyphs/chevron-pixel.svg"
          alt="Open indicator"
          class="pos--abs no-pointer"
        />
      </template>
      <slot name="button"></slot>
      <slot></slot>
      <slot name="button-after">
        <img class="indicator pos--abs offset-r--xs" :src="getGlyph('cross')" />
      </slot>
    </b-button>
    <slot name="after-content"></slot>
  </div>
</template>
<style lang="scss" scoped>
div:deep() {
  position: relative;
  pointer-events: none !important;
  .touch-wrapper {
    transform: translate(v-bind("props.offset"));
    -webkit-transform: translate(v-bind("props.offset"));
    transition: transform 420ms cubic-bezier(1, 0, 0, 0.21);

    button {
      height: unset;
      transition: all 300ms ease;
      display: flex;
      width: 100%;
      > img:not(.indicator) {
        top: unset;
        left: get-size(xxxs);
        height: get-size(xsm);
        transition: height 600ms 150ms cubic-bezier(1, -0.2, 0, 0.21);
      }
      &.left {
        > img:not(.indicator) {
          left: unset;
          right: get-size(xxxs);
          rotate: 180deg;
        }
      }
      > img.indicator {
        top: unset;
        opacity: 0;
        width: get-size(xssm);
        height: get-size(xssm);
        transition: opacity 300ms cubic-bezier(1, -0.2, 0, 0.21);
      }
    }
  }
  > .content {
    margin-top: get-size(xxs);
    transition: transform 420ms cubic-bezier(1, 0, 0, 0.21),
      transform 420ms cubic-bezier(1, 0, 0, 0.21),
      width 100ms 420ms cubic-bezier(1, 0, 0, 0.21);
    transform: translate(200%);
    -webkit-transform: translate(200%);
    width: 0;
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    overflow: hidden;
  }
}
div.active:deep() {
  .touch-wrapper {
    transform: translate(0);
    -webkit-transform: translate(0);
    transition: transform 420ms cubic-bezier(1, -0.2, 0, 0.21);
    button {
      border-bottom-color: transparent;
      > img:not(.indicator) {
        animation: pulse 600ms linear 1;
        height: 0;
      }
      > img.indicator {
        opacity: 1;
        transition: opacity 300ms 420ms cubic-bezier(1, -0.2, 0, 0.21);
      }
    }
  }
  > .content {
    transition: transform 420ms cubic-bezier(1, 0, 0, 0.21),
      transform 420ms cubic-bezier(1, 0, 0, 0.21),
      width 100ms cubic-bezier(1, 0, 0, 0.21);

    transform: translate(0);
    -webkit-transform: translate(0);

    transform: scaleX(1);
    -webkit-transform: scaleX(1);

    width: 100%;
  }
}
@keyframes pulse {
  25% {
    scale: 0.5;
  }
  50% {
    scale: 0.7;
  }
  75% {
    scale: 1.5;
  }
}
</style>
