export default [
  {
    address: ["0x246e21F4091B48fB69D04d01e890039491faf411"],
    type: "furniture",
    metadata: {
      name: "Arcade Sofa",
      description:
        "An uniquely designed sofa that is based on the original space invaders game. A true work of art, only owned by a small selection of elite hotel guests.",
      attributes: [
        { trait_type: "Rarity", value: "Epic" },
        { trait_type: "Replicas", value: "10" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "classicarcadesofaorange_2x1x2_1",
    },
  },
  {
    address: ["0xe8e29f3c36154a613ff7eC4bb5264A576511B10d"],
    type: "furniture",
    metadata: {
      name: "Bitcube Table",
      description:
        "An unique light-pink game-cube inspired table to add to your gaming furniture collection. Among the more rare items in the gaming furniture set that are in very high demand with Bit Hotel guests due to their scarcity.",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "20" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "bitcubesidetablelightpink_1x1x2_1",
    },
  },
  {
    address: ["0x0d2a552c90A3AfD54026DCA5515b78c67165bed2"],
    type: "furniture",
    metadata: {
      name: "Controller Table",
      description:
        "A table that has the shape of a game console controller, built by craftsmen to withstand even the strongest ragequits. Part of the gaming collection that is in very high demand",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "30" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "gamecontrollertablepink_2x2x2_1",
    },
  },
  {
    address: ["0x713B1863497707acE27bF2Ee45d3af86e739f5Bd"],
    type: "furniture",
    metadata: {
      name: "Moonbeam Table",
      description:
        "A futuristic table with the Moonbeam branding on it, making it part of the highly sought after Moonbeam furniture collection. Great asset to place in your modern room builds.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "60" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "moonbeamtable_2x2x2_1",
      disabled: true,
    },
  },
  {
    address: ["0x15Fe91b2409f7065E4eD3c71D988a2AB820f2a0C"],
    type: "furniture",
    metadata: {
      name: "Pet Table",
      description:
        "A cosy bed for your Bit Hotel pets. Allows your pet to chill out and have a nap in your room. ",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "80" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "pettablemarblepurple_1x1x2_1",
    },
  },
  {
    address: ["0x744710572407ae6D3d7c03Dea46b0a1cfF3d1157"],
    type: "furniture",
    metadata: {
      name: "Modern Bed",
      description:
        "A great place to have a nap! This bed is one of the more uncommon sightings in Bit Hotel rooms and is extremely comfortable. Sweet dreams!",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "100" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "modernbedteal_3x3x2_1",
    },
  },
  {
    address: ["0x495aCd91C9d1C6855E34C94DC8c3687b279e2beb"],
    type: "furniture",
    metadata: {
      name: "Long Bookshelf",
      description:
        "A large and fancy bookshelf you can use to store your everyday items. A common appearance in Bit Hotel rooms due to its ease of use and utility.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "300" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "longbookshelfteal_1x1x3_1",
    },
  },
  {
    address: ["0xf0FA211fb7d2AD498556831ceE559B7dd9BC0156"],
    type: "furniture",
    metadata: {
      name: "Basic Seat Blue",
      description:
        "Comfortable, cool, and stylish. A great seat that adds a finishing touch to your own unique room.",
      attributes: [
        { trait_type: "Rarity", value: "Common" },
        { trait_type: "Replicas", value: "400" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "basicseatblue_1x1x2_2",
    },
  },
];
