//@ts-nocheck
import { TCharacter } from "../types";

export enum validCharacterNames {
  anti_hacker_rambo,
  astronaut_cz,
  avocado_hotel_guest,
  bartender,
  beta_tester_hotel_guest,
  bit_hotel_dinox_supporter,
  bit_hotel_mechachain_supporter,
  barbie_hotel_guest,
  cryptoknight,
  crypto_punkhead,
  degen_daytrader,
  diamond_hand_hotel_guest,
  director_dolan,
  dogefather,
  elon_dusk,
  grumpy_nyan_cat,
  inkeeper_kermit,
  innkeeper_kermit,
  janitor_jim,
  karaoke_kim,
  kawaii_keyboardwarrior,
  kucoin_fan,
  kucoin_gold,
  kucoin_silver,
  kucoin_vip,
  kucoin_white,
  lady_mcbitmain,
  lithium_hotel_guest,
  lithium,
  maitre_morty,
  meta_masked_fox,
  moonbeam_hotel_guest,
  moon_moon,
  mr_anonymous,
  mr_diamondhands,
  ms_kitty,
  notorious_neo,
  otaku,
  piccolo_bimbo,
  picollo_pepe,
  shibasketballer,
  solchick,
  superlauncher_fan,
  superlauncher_kid,
  the_dogefather,
  the_monopolist_manager,
  the_zuckerclerk,
  wally_bets,
  when_lamboy,
  default_character,
  wasder_hotel_guest,
  farmer,
  minter,
  lender,
  vault_guardian,
  governor,
  g2a_hotel_guest,
  brook_soul,
  william_skull,
  the_wise_tortus,
  ghost_receptionist,
  ghost_0,
  ghost_1,
  ghost_2,
  ghost_3,
  ghost_4,
  default_female_a,
  default_female_b,
  default_female_c,
  default_male_a,
  default_male_b,
  default_male_c,
  casperpunk_hotel_guest,
  cupid_hotel_guest,
}

export const usableCharacterNames: Array<keyof typeof validCharacterNames> = [
  "default_character",
  "avocado_hotel_guest",
  //Season 1
  "bartender",
  "barbie_hotel_guest",
  "elon_dusk",
  "janitor_jim",
  "lady_mcbitmain",
  "crypto_punkhead",
  "moon_moon",
  "mr_anonymous",
  "mr_diamondhands",
  "ms_kitty",
  "piccolo_bimbo",
  "shibasketballer",
  "the_dogefather",
  "wally_bets",
  "when_lamboy",
  //Season 2
  "astronaut_cz",
  "beta_tester_hotel_guest",
  "degen_daytrader",
  "director_dolan",
  "grumpy_nyan_cat",
  "innkeeper_kermit",
  "karaoke_kim",
  "kawaii_keyboardwarrior",
  "maitre_morty",
  "meta_masked_fox",
  "the_monopolist_manager",
  "notorious_neo",
  "picollo_pepe",
  "the_zuckerclerk",
  "wasder_hotel_guest",
  "farmer",
  "minter",
  "lender",
  "vault_guardian",
  "governor",
  "g2a_hotel_guest",
  "brook_soul",
  "william_skull",
  "the_wise_tortus",
  "anti_hacker_rambo",
  "otaku",
  // moonbeam
  "moonbeam_hotel_guest",
  //Partnership
  "cryptoknight",
  "diamond_hand_hotel_guest",
  "moonbeam",
  "lithium_hotel_guest",
  "superlauncher_fan",
  "superlauncher_kid",
  // caspers conference room
  "ghost_receptionist",
  "ghost_0",
  "ghost_1",
  "ghost_2",
  "ghost_3",
  "ghost_4",
  "default_female_a",
  "default_female_b",
  "default_female_c",
  "default_male_a",
  "default_male_b",
  "default_male_c",
  "casperpunk_hotel_guest",
  "cupid_hotel_guest",
];
