/* eslint-disable prettier/prettier */
import OrderableSelector from "@game/mini/lobby/gameobjects/OrderableSelector";
import { TilePosition3D } from "../../../common/interfaces/TilePosition3D";
import equals from "@game/engine/utilities/TilePositionHelper";
import MightyMinesCharacter from "./gameobjects/MightyMinesCharacter";
import MineFieldTile from "./gameobjects/MineFieldTile";
import IsometricCharacter, {
  PlayerState,
} from "@game/engine/IsometricCharacter";
import AudioComponent from "@game/engine/components/scenecomponents/AudioComponent";
import Mine from "./gameobjects/Mine";
import MightyMinesBackendComponent from "./MIghtyMinesBackendComponent";
import Button from "@game/engine/components/ui/Button";
import OrderableIsometricGameScene from "@game/engine/scenes/OrderableIsometricGameScene";
import TilePath from "@game/engine/objects/TilePath";
import { MinesGameStates } from "./common/enums/MinesGameStates";
import TrailParticleEmitterManager from "@game/engine/gameobjects/effects/TrailParticleEmitterManager";
import GainedTextEffect from "@game/engine/gameobjects/effects/GainedTextEffect";
import { Network } from "@game/engine/networking/Network";
import { formatNftName } from "@shared/Helpers";
import { loadAllAvailableCharacterAtlasAndJSONIntoScene } from "@game/engine/utilities/AssetLoaderHelper";
import LoadingSceneComponent from "@engine/components/scenecomponents/LoadingSceneComponent";
import { currentAccount } from "@shared/Global";
import MovementInputComponent from "@engine/components/scenecomponents/MovementInputComponent";
import RenderTexture = Phaser.GameObjects.RenderTexture;
import Vector2 = Phaser.Math.Vector2;
import Container = Phaser.GameObjects.Container;
import Text = Phaser.GameObjects.Text;
import isMobile from "@shared/IsMobile";
import TouchControlsSceneComponent from "@engine/components/scenecomponents/TouchControlsSceneComponent";
import { PlayerInventory } from "@shared/client/PlayerInventory";

export function isAllowedMightyMinesEntry(data: { [key: string]: string }): {
  allowed: boolean;
  message?: string;
} {
  if (currentAccount.wallet[data.currency] < 100) {
    return {
      allowed: false,
      message: `You dont have the minimum amount of 100 ${data.currency} to enter.`,
    };
  }
  return { allowed: true };
}

class AudioKeys {
  public static Mine_Drop = "mine-drop";
  public static Theme = "mines_theme";
}

export default class MightyMinesScene extends OrderableIsometricGameScene {
  public static SceneKey: string = "MightyMinesScene";
  private _addMinesButton: Button;
  private _addStakeButton: Button;
  private _audioComponent: AudioComponent;
  private _backendComponent: MightyMinesBackendComponent;
  private _character: MightyMinesCharacter;
  private _characterName: string = "default_character";
  private _checkoutButton: Button;
  private _checkoutEnabled: Boolean;
  private _currentPath: TilePath = null;
  private _currentPayoutBackground: RenderTexture;
  private _currentPayoutCountText: Text;
  private _currentPayoutText: Text;
  private _currentStake: number;
  private _currentStep: number;
  private _gameState: string;
  private _ingameUIBackground: Phaser.GameObjects.Sprite;
  private _ingameUIContainer: Container;
  private _loadingSceneComponent: LoadingSceneComponent;
  private _lastHoveredPosition: TilePosition3D;
  private _mineField: MineFieldTile[];
  private _mineLayer: Phaser.Tilemaps.LayerData;
  private _mineSprite: Mine;
  private _minesCountBackground: RenderTexture;
  private _minesCountText: Phaser.GameObjects.Text;
  private _minesText: Phaser.GameObjects.Text;
  private _movementInputComponent: MovementInputComponent;
  private _preUIBackground: Phaser.GameObjects.Sprite;
  private _preUIContainer: Container;
  private _priceCountText: Text;
  private _priceText: Phaser.GameObjects.Text;
  private _priceTextBackground: RenderTexture;
  private _selector: OrderableSelector;
  private _startButton: Button;
  private _subtractMinesButton: Button;
  private _subtractStakeButton: Button;
  private _totalMines: number;
  private _totalPrize: number;
  private _trailParticleEmitterManager: any;
  private _thereIsNoHover: boolean;
  private _touchControlsSceneComponent: TouchControlsSceneComponent;

  constructor() {
    super(
      {
        key: "MightyMinesScene",
        debugMode: true,
      },
      "/assets/game/mini/mighty-mines/",
      "mines.tmj",
      ["extras/walk_tile.png", "neutral_tile/neutral_tile_0.png"], //tilesets
      [
        "stage/update_coin_cloud_island_background.png",
        "stage/update_coin_cloud_island_foreground.png",
      ], //images
    );
    this._totalMines = 10;
    this._currentStake = 100;
    this._audioComponent = this.addComponent<AudioComponent>(
      new AudioComponent(),
    );
  }

  protected init() {
    // initialize global variables according to game size
    // set game size variables
    this._loadingSceneComponent = this.addComponent<LoadingSceneComponent>(
      new LoadingSceneComponent(false),
    ).on(LoadingSceneComponent.EventTypes.LoadingCompleted, () => {
      this._loadingSceneComponent.setText("connecting..");
    });
  }
  addMines() {
    if (this._totalMines < 24) {
      this._totalMines = this._totalMines + 1;
      this._minesCountText.setText(this._totalMines.toString());
    }
  }

  create() {
    super.create();
    this._audioComponent.addBackgroundMusic({
      key: AudioKeys.Theme,
      path: "/assets/game/mini/mighty-mines/audio/mp3/mines_background.mp3",
    });
    this._audioComponent.addSoundEffects([
      {
        key: AudioKeys.Mine_Drop,
        path: "/assets/game/mini/mighty-mines/audio/mp3/mine_drop.mp3",
      },
    ]);
    this._audioComponent.playBackgroundAudio();
    this._trailParticleEmitterManager = new TrailParticleEmitterManager(this);

    this.setBackgroundColor(0xc1ebf9);

    this._backendComponent = this.addComponent<MightyMinesBackendComponent>(
      new MightyMinesBackendComponent(),
    );

    this._gameState = MinesGameStates.Idle;

    this.input.on("pointerdown", this.onTileClicked, this);
    this._movementInputComponent = this.addComponent<MovementInputComponent>(
      new MovementInputComponent(),
    );

    if (!this.game.device.os.desktop) {
      this._touchControlsSceneComponent =
        this.addComponent<TouchControlsSceneComponent>(
          new TouchControlsSceneComponent(
            {
              pinchZoom: false,
              pointerDragCamera: false,
              zoomLimits: { max: 1, min: 0.15 },
            },
            { enable: true },
            false,
          ),
        );

      this._movementInputComponent.addVirtualJoystick(
        this._touchControlsSceneComponent.joystick,
      );
    }

    this.initMineMap();

    const position = this.map.tileToWorldXY(1, 0);

    this.createUI();

    this.cameras.main.centerOn(position.x, position.y);
    //this.cameras.main.centerOn(0,0)
    this.cameras.main.setBounds(-1000, -1000, 2000, 2000);

    this.onResized(this.scale.gameSize);
    this.addSelector();
    this._selector?.deactivate();

    Network.Core.core
      .getUsersData(Network.Core.currentUser.id)
      .then((data) => {
        if (
          data.length > 0 &&
          "metadata" in data[0] &&
          "activeCharacter" in data[0].metadata &&
          "name" in data[0].metadata.activeCharacter
        ) {
          this._characterName = formatNftName(
            data[0].metadata.activeCharacter.name,
          );
          if (this._character) {
            this._character.updateCharacterName(this._characterName);
          }
        }
      })
      .finally();
  }

  createUI() {
    //Pre-game UI
    const textColor = "#FFFFFF";
    const textStyleAmount = {
      align: "center",
      color: "#000000",
      fixedHeight: 42,
      font: "28px depixel",
    };
    const textStylePayout = {
      align: "center",
      color: "#000000",
      fixedHeight: 42,
      font: "20px depixel",
    };
    const textStyleLabel = {
      color: "#000000",
      font: "12px depixel",
    };

    // Amount of Mines part
    const subtractMinesButton = new Button(
      this,
      0,
      -70,
      "minusButtonNormal",
      "",
      "minusButtonPressed",
      "minusButtonHover",
      "minusButtonPressed",
    );
    this._subtractMinesButton = subtractMinesButton;
    subtractMinesButton.addOnClickEvent("mineSubtract");
    this.events.on("mineSubtract", this.subtractMines, this);

    const addMinesButton = new Button(
      this,
      50,
      -70,
      "plusButtonNormal",
      "",
      "plusButtonPressed",
      "plusButtonHover",
      "plusButtonPressed",
    );
    this._addMinesButton = addMinesButton;
    addMinesButton.addOnClickEvent("mineAdd");
    this.events.on("mineAdd", this.addMines, this);

    this._minesText = this.add.text(
      -17,
      -180,
      "Amount of Mines: ",
      textStyleLabel,
    );

    this._minesCountBackground = this.addNineslicedBackgroundAt(17, -140, 64);
    this._minesCountText = this.add.text(
      25,
      -155,
      this._totalMines.toString(),
      textStyleAmount,
    );

    this._priceTextBackground = this.addNineslicedBackgroundAt(3, -80, 96);
    this._priceText = this.add.text(
      -2,
      -120,
      "Current stake: ",
      textStyleLabel,
    );
    this._priceCountText = this.add.text(
      10,
      -95,
      this._currentStake.toString(),
      textStyleAmount,
    );

    const subtractStakeButton = new Button(
      this,
      -10,
      -40,
      "minusButtonNormal",
      "",
      "minusButtonPressed",
      "minusButtonHover",
      "minusButtonPressed",
    );
    this._subtractStakeButton = subtractStakeButton;
    subtractStakeButton.addOnClickEvent("stakeSubtract");
    this.events.on("stakeSubtract", this.stakeSubtract, this);

    const addStakeButton = new Button(
      this,
      60,
      -40,
      "plusButtonNormal",
      "",
      "plusButtonPressed",
      "plusButtonHover",
      "plusButtonPressed",
    );

    this._addStakeButton = addStakeButton;
    addStakeButton.addOnClickEvent("stakeAdd");
    this.events.on("stakeAdd", this.stakeAddition, this);

    const startButton = new Button(
      this,
      25,
      -18,
      "startButtonNormal",
      "",
      "startButtonPressed",
      "startButtonHover",
      "startButtonPressed",
    );

    this._startButton = startButton;
    startButton.addOnClickEvent("startGame");
    this.events.on("startGame", this.sendStart, this);
    this.add.existing(this._startButton);

    this._preUIBackground = this.add.sprite(50, -100, "bigBoxBackground");
    this._preUIContainer = new Container(this, -250, isMobile() ? 150 : 20);
    this.add.existing(this._preUIContainer);

    this._preUIContainer.add([
      this._preUIBackground,
      this._startButton,
      this._subtractMinesButton,
      this._addMinesButton,
      this._minesText,
      this._minesCountText,
      this._minesCountBackground,
      this._subtractStakeButton,
      this._addStakeButton,
      this._priceText,
      this._priceCountText,
      this._priceTextBackground,
    ]);

    this._preUIContainer.bringToTop(this._minesCountText);
    this._preUIContainer.bringToTop(this._priceCountText);
    this._preUIContainer.sendToBack(this._preUIBackground);

    //Ingame UI
    const checkoutButton = new Button(
      this,
      5,
      -10,
      "checkoutButtonNormal",
      "",
      "checkoutButtonPressed",
      "checkoutButtonHover",
      "checkoutButtonPressed",
    );

    this._checkoutButton = checkoutButton;
    checkoutButton.addOnClickEvent("checkout");
    this.events.on("checkout", this.sendCheckout, this);
    this.add.existing(this._checkoutButton);

    this._currentPayoutBackground = this.addNineslicedBackgroundAt(
      -55,
      -60,
      128,
    );
    this._currentPayoutText = this.add.text(
      -50,
      -100,
      "Current Payout: ",
      textStyleLabel,
    );
    this._currentPayoutCountText = this.add.text(
      -45,
      -70,
      "0",
      textStylePayout,
    );

    this._ingameUIBackground = this.add.sprite(10, -50, "smallBoxBackground");

    this._ingameUIContainer = new Container(this, isMobile() ? 300 : 380, 80);
    this.add.existing(this._ingameUIContainer);
    this._ingameUIContainer.add([
      this._ingameUIBackground,
      this._checkoutButton,
      this._currentPayoutBackground,
      this._currentPayoutCountText,
      this._currentPayoutText,
    ]);
    this._ingameUIContainer.bringToTop(this._currentPayoutCountText);
    this._ingameUIContainer.sendToBack(this._ingameUIBackground);
    this._ingameUIContainer.each((object) => {
      object.setAlpha(0);
      if (object instanceof Button) {
        object.setDisabled(true);
      }
    });

    this._loadingSceneComponent // Alright we can remove this now
      .setText("done");
    this._loadingSceneComponent.hide();
  }

  dropMine(tilePosition: TilePosition3D) {
    const worldpos: Vector2 = this.worldPositionAtTilePosition(tilePosition);

    this._mineSprite = new Mine(
      this,
      worldpos.x - 20,
      worldpos.y,
      this._character,
    );

    this.add.existing(this._mineSprite);

    this._mineSprite.setDepth(100);

    this._audioComponent.play(AudioKeys.Mine_Drop);

    this.tweens.add({
      targets: this._mineSprite,
      x: worldpos.x - 5,
      y: worldpos.y - 10,
      duration: 200,
      ease: "Sine.easeIn",
      delay: 0,
      onComplete: () => {
        this.tweens.add({
          targets: this._mineSprite,
          x: worldpos.x + 0,
          y: worldpos.y + 0,
          duration: 200,
          ease: "Sine.easeInOut",
          delay: 0,
          onComplete: () => {
            this.tweens.add({
              targets: this._mineSprite,
              x: worldpos.x,
              y: worldpos.y - 5,
              duration: 100,
              ease: "Sine.easeIn",
              delay: 0,
              onComplete: () => {
                this.tweens.add({
                  targets: this._mineSprite,
                  x: worldpos.x,
                  y: worldpos.y,
                  duration: 100,
                  ease: "Sine.easeOut",
                  delay: 0,
                  onComplete: () => {
                    this._mineSprite.once(
                      "animationcomplete",
                      this.resetGame,
                      this,
                    );
                    this._mineSprite.explode();
                    this._character.fadeOut();
                  },
                  completeDelay: 300,
                });
              },
            });
          },
        });
      },
    });
  }

  gameOver() {
    this._mineField.forEach((fieldTile) => {
      fieldTile.resetTint();
    });

    this._gameState = MinesGameStates.Idle;
    this.resetGame();
  }

  public override getBounds(): Phaser.Geom.Rectangle {
    return new Phaser.Geom.Rectangle(-1000, -1000, 2000, 2000);
  }

  getMinefieldTileAtPosition(x: number, y: number): MineFieldTile {
    let tile: MineFieldTile = null;
    this._mineField.forEach((fieldTile) => {
      if (x == fieldTile.tile.x && y == fieldTile.tile.y) {
        tile = fieldTile;
      }
    });
    return tile;
  }

  initMineMap() {
    this.map.layers.forEach((layerData) => {
      getProperty(layerData, "type");

      if (getProperty(layerData, "type") === "Minefield") {
        this._mineLayer = layerData;
        this._mineField = [];

        for (let y = 0; y < this._mineLayer.height; ++y) {
          for (let x = 0; x < this._mineLayer.width; ++x) {
            const tile: Phaser.Tilemaps.Tile = this._mineLayer.data[x][y];

            const mineFieldTile: MineFieldTile = new MineFieldTile(
              this,
              tile,
              tile.pixelX,
              tile.pixelY - 16,
              x, // - 1,
              y, // - 1,
            );

            mineFieldTile.setDepth(this._mineLayer.tilemapLayer.depth);

            if (mineFieldTile.tile.index > -1) {
              this._mineField.push(mineFieldTile);
              this.add.existing(mineFieldTile);
            }
          }
        }
        this._mineLayer.tilemapLayer.setVisible(false);
      }
    });
  }

  loadCharacter(characterName: string) {
    this.load.json(
      characterName + "_json",
      "/assets/game/characters/" + characterName + ".json",
    );
    this.load.atlas(
      characterName + "_atlas",
      "/assets/game/characters/" + characterName + ".png",
      "/assets/game/characters/" + characterName + ".json",
    );
  }

  public moveCharacterToGoalIfPossible = (
    character: IsometricCharacter,
    goalPosition: TilePosition3D,
  ) => {
    const path: TilePath = this._pathfinder.find(
      character.tilePosition,
      goalPosition,
    );
    this._currentPath = path;
    this._currentStep = 1;
    if (this._currentPath.nodes.length === 0) {
      this._gameState = MinesGameStates.Playing;
      this._currentPath = null;
      return;
    }
    const firstStep: TilePath = this._pathfinder.find(
      character.tilePosition,
      this._currentPath.nodes[1].position,
    );

    character.follow(firstStep);
    this._selector?.deactivate();
    this._gameState = MinesGameStates.Moving;
  };

  public onBombDrop(tileX: number, tileY: number) {
    //this.character.pathFollowerComponent.stopFollow();
    const mineTile = this.getMinefieldTileAtPosition(tileX, tileY);
    mineTile.flip(true);
    mineTile.resetTint();
    this._gameState = MinesGameStates.Idle;
    this.dropMine(this._character.tilePosition);

    //this.moveCharacterTo(this.character, tilePos, tilePos);
  }

  public onMultiplierReturn(
    multiplier: number,
    tileX,
    tileY,
    checkoutEnabled: boolean,
  ) {
    this._checkoutEnabled = checkoutEnabled;

    this._checkoutButton.setDisabled(true);
    const mineTile = this.getMinefieldTileAtPosition(tileX, tileY);
    mineTile.flip(false);

    mineTile.resetTint();

    const tilePosition = this.map.tileToWorldXY(
      mineTile.tile.x,
      mineTile.tile.y,
    );

    this._totalPrize = this._currentStake * multiplier;
    const finalPrize = Math.trunc(this._totalPrize);
    // Show how much we've scored above the basket
    new GainedTextEffect(
      this,
      tilePosition.x + 55,
      tilePosition.y - 20,
      "x" + finalPrize.toString(),
    );
    const targetWorldTransformMatrix =
      this._currentPayoutCountText.getWorldTransformMatrix();

    // Show a nice trail flying towards the payout

    this._trailParticleEmitterManager.animate(
      tilePosition.x + 55,
      tilePosition.y,
      targetWorldTransformMatrix.tx,
      targetWorldTransformMatrix.ty,
      () => {
        this.tweens.add({
          ease: "cubic.easeinOut",
          targets: this._currentPayoutCountText,
          props: {
            scaleX: 1.4,
            scaleY: 1.4,
          },
          duration: 64,
          yoyo: true,
          onComplete: () => {
            if (this._checkoutEnabled) {
              this._checkoutButton.setDisabled(false);
            }
            this._gameState = MinesGameStates.Playing;
            this.walkNextStep();
          },
        });
        this._currentPayoutCountText.setText(finalPrize.toString());
      },
    );
    //this.add.existing(profitText);

    //this._currentPayoutCountText.setText((this._currentStake*multiplier).toFixed(0))
  }

  onTileStepped(tilePath: TilePosition3D) {
    //const goal:TypedNode=tilePath.goal;
    const mineTile = this.getMinefieldTileAtPosition(tilePath.x, tilePath.y);

    if (mineTile && !mineTile.flipped) {
      //this.character.pathFollowerComponent.stopFollow();
      this._gameState = MinesGameStates.Flipping;
      this._backendComponent.sendRequestFlip(mineTile.gridY, mineTile.gridX);
    } else {
      this.time.addEvent({
        delay: 170,
        callback: () => {
          this.walkNextStep();
        },
        loop: false,
      });
    }
  }

  preload() {
    super.preload();

    // Load the particle shapes
    TrailParticleEmitterManager.Preload(this);

    this.loadCharacter(this._characterName);

    this.load.image(
      "tile_neutral",
      "/assets/game/mini/mighty-mines/neutral_tile/neutral_tile_0.png",
    );
    this.load.image(
      "tile_reward",
      "/assets/game/mini/mighty-mines/star_tile/004.png",
    );
    this.load.image(
      "tile_mine",
      "/assets/game/mini/mighty-mines/mine_tile/004.png",
    );
    this.load.image(
      "multiplierBG",
      "/assets/game/mini/mighty-mines/textPopup/text_field.png",
    );

    this.load.spritesheet(
      "selector_spritesheet",
      "/assets/sprites/selector-sheet.png",
      { frameHeight: 128, frameWidth: 128 },
    );

    this.load.spritesheet(
      "tile_mine_animation",
      "/assets/game/mini/mighty-mines/mine_tile/mine_tile.png",
      {
        frameWidth: 128,
        frameHeight: 103,
      },
    );

    this.load.spritesheet(
      "tile_reward_animation",
      "/assets/game/mini/mighty-mines/star_tile/star_tile.png",
      {
        frameWidth: 128,
        frameHeight: 103,
      },
    );

    this.load.spritesheet(
      "mine_explosion_animation",
      "/assets/game/mini/mighty-mines/explosion/mine_explosion.png",
      {
        frameWidth: 390,
        frameHeight: 420,
      },
    );

    this.load.image("mine_sprite", "/assets/game/mini/mighty-mines/mine.png");

    //load UI
    this.load.image(
      "startButtonNormal",
      "/assets/game/mini/mighty-mines/ui/start_idle.png",
    );
    this.load.image(
      "startButtonPressed",
      "/assets/game/mini/mighty-mines/ui/start_pressed.png",
    );
    this.load.image(
      "startButtonHover",
      "/assets/game/mini/mighty-mines/ui/start_hover.png",
    );
    this.load.image(
      "startButtonDisabled",
      "/assets/game/mini/mighty-mines/buttons/start_disabled.png",
    );
    this.load.image(
      "minusButtonNormal",
      "/assets/game/mini/mighty-mines/ui/minus_idle.png",
    );
    this.load.image(
      "checkoutButtonNormal",
      "/assets/game/mini/mighty-mines/ui/checkout_idle.png",
    );
    this.load.image(
      "checkoutButtonPressed",
      "/assets/game/mini/mighty-mines/ui/checkout_pressed.png",
    );
    this.load.image(
      "checkoutButtonHover",
      "/assets/game/mini/mighty-mines/ui/checkout_hover.png",
    );
    this.load.image(
      "minusButtonPressed",
      "/assets/game/mini/mighty-mines/ui/minus_pressed.png",
    );
    this.load.image(
      "minusButtonHover",
      "/assets/game/mini/mighty-mines/ui/minus_hover.png",
    );
    this.load.image(
      "minusButtonDisabled",
      "/assets/game/mini/plinko/buttons/minus_disabled.png",
    );

    this.load.image(
      "plusButtonNormal",
      "/assets/game/mini/mighty-mines/ui/plus_idle.png",
    );
    this.load.image(
      "plusButtonPressed",
      "/assets/game/mini/mighty-mines/ui/plus_pressed.png",
    );
    this.load.image(
      "plusButtonHover",
      "/assets/game/mini/mighty-mines/ui/plus_hover.png",
    );
    this.load.image(
      "numberBackground",
      "/assets/game/mini/mighty-mines/ui/number_box.png",
    );
    this.load.image(
      "payoutBackground",
      "/assets/game/mini/mighty-mines/ui/payout_box.png",
    );
    this.load.image(
      "bigBoxBackground",
      "/assets/game/mini/mighty-mines/ui/box_194x194.png",
    );
    this.load.image(
      "smallBoxBackground",
      "/assets/game/mini/mighty-mines/ui/box_194x134.png",
    );

    //audio
    this.load.audio(
      AudioKeys.Theme,
      "/assets/game/mini/mighty-mines/audio/mp3/mines_background.mp3",
    );
    this.load.audio(
      AudioKeys.Mine_Drop,
      "/assets/game/mini/mighty-mines/audio/mp3/mine_drop.mp3",
    );
    loadAllAvailableCharacterAtlasAndJSONIntoScene(this);
  }

  sendCheckout() {
    if (this._gameState === MinesGameStates.Playing) {
      this._gameState = MinesGameStates.Idle;
      const position = this._character.tilePosition;
      this._backendComponent.sendGameCheckout(position.x, position.y);
    }
  }

  sendStart() {
    //@todo needs to migrate to V2, as it wouldn't receive the match_data on 'init'
    // const currency = currentAccount.wallet[this._matchData["currency"]];
    // if (!currency || currency < this._currentStake) {
    //   return $modals.openModal("NoTicketsModal");
    // }

    this._backendComponent.sendStartGame(this._currentStake, this._totalMines);

    this._preUIContainer.each((object) => {
      object.setAlpha(0);
      if (object instanceof Button) {
        object.setDisabled(true);
      }
    });
  }

  stakeAddition() {
    if (this._currentStake < 1000) {
      this._currentStake = this._currentStake + 50;
      this._priceCountText.setText(this._currentStake.toString());
    }
  }

  stakeSubtract() {
    if (this._currentStake > 100) {
      this._currentStake = this._currentStake - 50;
      this._priceCountText.setText(this._currentStake.toString());
    }
  }

  startGame(checkoutEnabled: boolean) {
    this._checkoutEnabled = checkoutEnabled;

    if (this._character === undefined) {
      const position = this.map.tileToWorldXY(1, 0);
      this._character = new MightyMinesCharacter(
        this,
        position.x,
        position.y,
        this._characterName,
      );
      this.add.existing(this._character);
      this._character.events.on("new_tile_reached", (e) => {
        this.onTileStepped(e);
      });
    }

    this._ingameUIContainer.each((object) => {
      object.setAlpha(1);
      if (object instanceof Button) {
        object.setDisabled(false);
      }
    });

    this._gameState = MinesGameStates.Playing;
  }

  subtractMines() {
    if (this._totalMines > 10) {
      this._totalMines = this._totalMines - 1;
      this._minesCountText.setText(this._totalMines.toString());
    }
  }

  public override update(time: number, deltaTime: number) {
    super.update(time, deltaTime);

    this.updatePointer(this.input.mousePointer);
    this.updateMovement();
  }

  protected centerCamera() {
    this.cameras.main?.centerOn(
      this._map.widthInPixels * 0.5,
      this._map.heightInPixels * 0.5,
    );
  }

  protected onCreated(): void {
    this._audioComponent.playBackgroundAudio(true);
  }

  protected onNoTileSelected() {
    this._selector?.deactivate();
  }

  protected onResized(gameSize: Phaser.Structs.Size) {
    super.onResized(gameSize);

    //this.centerEverything(gameSize);
    const position = this.map.tileToWorldXY(3, 3);
    this.cameras.main.centerOn(position.x, position.y);

    const extra = 1.02;
    // Check if we're wider or taller
    let zoomHeight = 0;
    let zoomWidth = 0;
    // Add 10% for a little extra space
    if (this._map.heightInPixels * extra >= gameSize.height) {
      zoomHeight = gameSize.height / (this._map.heightInPixels * extra);
    } else {
      zoomHeight = 1;
    }
    if (this._map.widthInPixels * extra >= gameSize.width) {
      zoomWidth = (gameSize.width / this._map.widthInPixels) * extra;
    } else {
      zoomWidth = 1;
    }

    // const minimum = Math.min(gameSize.width, this.scale.height);
    const camera = this.cameras.main;
    camera.roundPixels = true;
    camera.setZoom(
      Phaser.Math.Clamp(Math.min(zoomWidth, zoomHeight), 0.5, 1.0),
    );
  }

  protected onTileClicked(pointer): void {
    // console.log("tile position under pointer",this.positionUnderPointer(pointer))
    // console.log("clicked with sstate: ",this._gameState)
    // console.log("clicked with charstate",this._character.state)
    if (this.positionUnderPointer(pointer) === null) {
      return;
    }
    if (
      this._gameState === MinesGameStates.Playing &&
      this._character.state === PlayerState.Idle
    ) {
      this._gameState = MinesGameStates.Moving;
      this.moveCharacterToGoalIfPossible(
        this._character,
        this.positionUnderPointer(pointer),
      );
    }
  }

  protected onTileHovered(
    //tile: Phaser.Tilemaps.Tile,
    tilePosition: TilePosition3D,
  ): void {
    if (
      this._gameState === MinesGameStates.Playing &&
      this._character.state === PlayerState.Idle &&
      this._currentPath === null
    ) {
      this._thereIsNoHover = false;
      this._selector.tilePosition = tilePosition;
      this._selector.activate();
      const path: TilePath = this._pathfinder.find(
        this._character.tilePosition,
        tilePosition,
      );
      this._mineField.forEach((fieldTile) => {
        fieldTile.hoverReset();
      });

      for (let i = 0; i < path.nodes.length; i++) {
        const element = path.nodes[i];
        const mineTile = this.getMinefieldTileAtPosition(
          element.position.x,
          element.position.y,
        );
        if (mineTile) {
          mineTile.onHover();
        }
      }
    }
  }

  private addNineslicedBackgroundAt(
    x: number,
    y: number,
    width: number = 128,
    height = 42,
  ): RenderTexture {
    return this.add
      .nineslice(x, y, width, height, "numberBackground", [8, 6, 6, 6])
      .setOrigin(0, 0.5);
  }

  private addSelector() {
    this._selector = new OrderableSelector(this);
    this.add.existing(this._selector);
    this.addOrderable(this._selector);
  }

  private resetGame() {
    if (this._character) {
      this._character.fadeOut();
      this._character = undefined;
    }
    if (this._mineSprite) {
      this._mineSprite.destroy();
      this._mineSprite = undefined;
    }
    this._currentPath = null;
    this._currentStep = 0;
    this._currentPayoutCountText.setText("0");
    this._gameState = MinesGameStates.Idle;

    this._mineField.forEach((fieldTile) => {
      fieldTile.reset();
    });

    this._preUIContainer.each((object) => {
      object.setAlpha(1);
      if (object instanceof Button) {
        object.setDisabled(false);
      }
    });

    this._ingameUIContainer.each((object) => {
      object.setAlpha(0);
      if (object instanceof Button) {
        object.setDisabled(true);
      }
    });
  }

  private updateMovement() {
    if (
      this._gameState === MinesGameStates.Playing &&
      // this._character.state === PlayerState.Idle &&
      !this._movementInputComponent.direction.equals(Vector2.ZERO)
    ) {
      const newDirection = new Vector2(
        this._movementInputComponent.direction,
      ).normalize();
      const nextTilePos = {
        x: this._character.tilePosition.x,
        y: this._character.tilePosition.y,
        z: 0,
      };

      newDirection.rotate(-Math.PI / 4);

      nextTilePos.x +=
        Math.abs(newDirection.x) > 0.5 ? Math.sign(newDirection.x) : 0;
      nextTilePos.y +=
        Math.abs(newDirection.y) > 0.5 ? Math.sign(newDirection.y) : 0;

      if (
        nextTilePos.x >= 0 &&
        nextTilePos.x < this.map.width &&
        nextTilePos.y >= 0 &&
        nextTilePos.y < this.map.height
      ) {
        this.moveCharacterToGoalIfPossible(this._character, nextTilePos);
      }
    }
  }

  private updatePointer = (pointer) => {
    const tilePosition = this.tilePositionAtWorldPosition(
      new Vector2(pointer.worldX, pointer.worldY),
    );
    if (tilePosition !== null) {
      if (null !== this._lastHoveredPosition && this._thereIsNoHover) {
        this.onTileHovered(this._lastHoveredPosition);
        this._lastHoveredPosition = tilePosition;
      }
      // If it's not a different tile
      if (
        null !== this._lastHoveredPosition &&
        equals(this._lastHoveredPosition, tilePosition)
      ) {
        return;
      }
      this._lastHoveredPosition = tilePosition;
      this.onTileHovered(this._lastHoveredPosition);
      return;
    }

    // If we got here, nothing is selected
    this.onNoTileSelected();
  };

  private walkNextStep() {
    this._currentStep++;

    if (this._currentStep === this._currentPath.nodes.length) {
      this._currentStep = 0;
      this._currentPath = null;
      this._gameState = MinesGameStates.Playing;
      this._thereIsNoHover = true;
      return;
    }
    const nextStep: TilePath = this._pathfinder.find(
      this._character.tilePosition,
      this._currentPath.nodes[this._currentStep].position,
    );

    this._character.follow(nextStep);
    this._gameState = MinesGameStates.Moving;
  }
}

interface LayerDataProperty {
  name?: string;
  type?: string;
  value?: any;
}

function getProperty(layerData: Phaser.Tilemaps.LayerData, name: string): any {
  for (let index = 0; index < layerData.properties.length; index++) {
    const property: LayerDataProperty = layerData.properties[index];
    if (property.name === name) {
      return property.value;
    }
  }
  return null;
}
