global:
  back: Voltar
  next: Próximo
  play: Jogar
  your: Sua | Seu
  username: Nome de usuário
  show: Mostrar
  hide: Esconder
  repeat: Repita
  password: Senha
  email: E-mail
  your-email: Seu @.lower:global.email
  your-password: Sua @.lower:global.password
  already-taken: Já utilizado
  uppercase-letter: Letra Maiúscula
  lowercase-letter: Letra Minúscula
  special-character: Caractere Especial
  minimum: Mínimo
  character: Caractere | Caracteres
  letter: Letter | Letters
  members: Membros
  join: Juntar-se
  login: Entrar
  signup: Cadastro
pages:
  signup:
    abandon: Tem certeza que deseja abandonar o registro? As alterações não serão salvas!
    step-names:
      SignupCharacterUsernameStep: Escolha seu personagem
      SignupEmailStep: "@:global.your-email"
      SignupPasswordStep: Proteja sua conta
      SignupReferralStep: Seu código beta
      SignupTopupStep: Encha suas malas
    code:
      incorrect: Código incorreto, por favor tente novamente!
      correct: Verificação bem-sucedida!
    character:
      tip: Você sempre pode modificar seu personagem mais tarde
      warning: Você não pode alterar um @.lower:global.username depois que seu avatar é criado!
      error:
        username:
          too-short: "@:global.username muito curto"
          already-taken: "@:global.username @.lower:global.already-taken"
    email:
      send-code: Enviar código
      send-code-success: Código enviado
      send-code-label: "@:pages.signup.email.send-code-success para o @.lower:global.your-email"
      consent-age: Eu confirmo que tenho 18 anos
      consent-legal: Eu concordo com os
      tos: termos de serviço
      eula: EULA
      error:
        already-taken: "@:global.email @.lower:global.already-taken"
    password:
      tip: Pense em algo difícil de adivinhar
      at-least: Pelo menos
      minimum: "@:global.minimum de"
    referral:
      no-code: Não tem um referral code/beta code? Encontre um nas comunidades abaixo!
    topup:
      support: Apoie a equipe um pouquinho ❤️ e compre um pacote inicial!
    download:
      welcome-user: Bem-vindos, {user}!
      download-store: Para jogar Bit Hotel no celular, faça o download do jogo em {store} store
