import Text = Phaser.GameObjects.Text;
import { Scene } from "phaser";

// This class is self-managing:
// It adds itself to the scene and removes itself as well after completion
export type GainedTextEffectConfig = {
  addToScene?: boolean; // Add to the scene automatically
  color?: string; // Default #ffffff
  destroyWhenCompleted?: boolean; // Remove ourselves after we're done
  distance?: number; // Default 42 (px)
  duration?: number; // Default 1024 (ms)
  fontSize?: number; // Default 18 (px)
};

const DefaultGainedTextEffectConfig: GainedTextEffectConfig = {
  addToScene: true,
  color: "#ffffff",
  destroyWhenCompleted: true,
  distance: 42,
  duration: 1024,
  fontSize: 18,
};

export default class GainedTextEffect extends Text {
  constructor(
    scene: Scene,
    x: number,
    y: number,
    text: string,
    config: GainedTextEffectConfig = DefaultGainedTextEffectConfig,
  ) {
    config = Object.assign({}, DefaultGainedTextEffectConfig, config);

    super(scene, x, y, text, {
      color: config.color,
      font: config.fontSize + "px depixel",
    });

    this.setOrigin(0.5).setDepth(999);

    if (config.addToScene) {
      scene.add.existing(this as Text);
    }

    const duration = config.duration;
    scene.tweens.add({
      duration,
      ease: "cubic.out",
      props: {
        alpha: {
          delay: duration * 0.75,
          duration: duration * 0.25,
          ease: "cubic.out",
          value: 0,
        },
        y: "-=" + config.distance,
      },
      targets: this,
      onComplete: config.destroyWhenCompleted
        ? () => {
            this.destroy(true);
          }
        : false,
    });
  }
}
