import { dispatchEvent, EnumNetworkEvents } from "./Events";
import { NFTHelpers } from "./nft";
import { TInventoryTile } from "./types";
interface IStorageItem<T> {
  [key: string]: T;
}
type UserMetadata = {
  activeCharacter: TInventoryTile;
  activeCharacterAddress: string;
  username: string;
  userId: string;
  userName?: any;
  [key: string]: any;
};

function cacheItemToMetadata(cacheItem: UserMetadata) {
  return {
    ...cacheItem.activeCharacter,
    activeCharacter: NFTHelpers.NFT.fromAddress(
      cacheItem.activeCharacterAddress || "0xDEF",
    ),
    activeCharacterAddress: cacheItem.activeCharacterAddress,
    username: cacheItem.username || cacheItem.userName,
    userId: cacheItem.userId,
    karma: cacheItem.karma,
    walletAddress: cacheItem.walletAddress,
    chainId: cacheItem.chainId,
  };
}
class BasicPlayerMetadataCache implements IStorageItem<UserMetadata> {
  [userId: string]: UserMetadata;
  private static _remotePlayerCharacters: IStorageItem<UserMetadata> = {};
  public static _waitingForIds: {
    [userId: string]: { resolve: (value: UserMetadata) => void };
  } = {};
  constructor() {
    const saved = localStorage.getItem("BasicPlayerMetadataCache");
    BasicPlayerMetadataCache._remotePlayerCharacters = JSON.parse(
      saved ? saved : "{}",
    );
    return new Proxy(this, {
      get: (obj: BasicPlayerMetadataCache, userId: string, receiver: any) => {
        if (
          Object.hasOwn(
            BasicPlayerMetadataCache._remotePlayerCharacters,
            userId,
          )
        ) {
          const cacheItem: UserMetadata =
            BasicPlayerMetadataCache._remotePlayerCharacters[userId];
          if (Date.now() > (cacheItem as any).expires) {
            delete BasicPlayerMetadataCache._remotePlayerCharacters[userId];
          }
          return cacheItemToMetadata(cacheItem);
          //sanity check
        } else if (userId != "undefined") {
          dispatchEvent(EnumNetworkEvents.NetworkRequestUserData, userId);
          //FIXME: figure out how to not call it twice & not have delays on next requests
          setTimeout(() => {
            dispatchEvent(EnumNetworkEvents.NetworkRequestUserData, userId);
          }, 2000);

          return new Promise((resolve, reject) => {
            if (!BasicPlayerMetadataCache._waitingForIds[userId]) {
              BasicPlayerMetadataCache._waitingForIds[userId] = { resolve };
              setTimeout(() => {
                delete BasicPlayerMetadataCache._waitingForIds[userId];
                reject("timed out");
              }, 10000);
            } else {
              setTimeout(() => {
                resolve(
                  BasicPlayerMetadataCache._remotePlayerCharacters[userId],
                );
              }, 10000);
            }
          });
        } else {
          //failsafe
          return {
            username: "",
            userId: "",
            name: "Default Character",
            activeCharacter: {
              name: "Default Character",
            },
          };
        }
      },
      set: (
        obj: BasicPlayerMetadataCache,
        key: string,
        value: UserMetadata,
        receiver: any,
      ) => {
        (value as any).expires = Date.now() + 1000 * 3600 * 24;
        BasicPlayerMetadataCache._remotePlayerCharacters[key] = value;
        localStorage.setItem(
          "BasicPlayerMetadataCache",
          JSON.stringify(BasicPlayerMetadataCache._remotePlayerCharacters),
        );
        if (BasicPlayerMetadataCache._waitingForIds[key]) {
          BasicPlayerMetadataCache._waitingForIds[key].resolve(
            cacheItemToMetadata(value),
          );
          delete BasicPlayerMetadataCache._waitingForIds[key];
        }
        return true;
      },
    });
  }
}
const BasicCache = { playerMetadata: new BasicPlayerMetadataCache() };
export default BasicCache;
