<script setup lang="ts">
import Network from "@network";
import { getNftImage } from "@overlay/assets";
import { replaceMetadataProxy } from "@overlay/modules/helpers";
import { NFTHelpers } from "@shared/nft";
import { computed, reactive, ref, watch } from "vue";
import BasicNftCard from "../nftCards/BasicNftCard.vue";
const props = defineProps({
  type: {
    type: String,
    default: "basic",
  },
  address: {
    type: String,
    required: true,
  },
});
const cardType = computed(() => {
  switch (props.type.toLowerCase()) {
    case "basic":
    default:
      return BasicNftCard;
  }
});

const nft = reactive(NFTHelpers.NFT.fromAddress(props.address));
const nftImage = ref(getNftImage(nft.name));
watch(
  () => props.address,
  async () => {
    const _nft = NFTHelpers.NFT.fromAddress(props.address);
    Object.assign(nft, _nft);
    nftImage.value = getNftImage(nft.name);
    if (nftImage.value.includes("undefined")) {
      if (nft.icon) {
        nftImage.value = replaceMetadataProxy(nft.icon);
        return;
      }

      try {
        const networkNft: any = await Network.core.getNftMetadata(
          nft.address,
          "1",
        );
        let metadata = JSON.parse(networkNft.payload.metadata);
        nftImage.value = replaceMetadataProxy(metadata?.image);
      } catch (e) {
        // console.error(e);
      }
    }
  },
);
</script>
<template>
  <component :is="cardType" :nft="nft" :nftImage="nftImage"></component>
</template>
