export enum EnumApplicationEvents {
  ApplicationStartMinigame = "APPLICATION_START_MINIGAME",
  ApplicationRestartMinigame = "APPLICATION_RESTART_MINIGAME",
  ApplicationMoveToScene = "APPLICATION_MOVE_TO_SCENE",

  ApplicationUiRetrievedMinigameSettings = "APPLICATION_UI_RETRIEVED_MINIGAME_SETTINGS",
  ApplicationNetworkRequestMinigameSettings = "APPLICATION_NETWORK_REQUEST_MINIGAME_SETTINGS",
  ApplicationNetworkAuthenticated = "APPLICATION_NETWORK_AUTHENTICATED",

  ApplicationPreventReload = "APPLICATION_PREVENT_RELOAD",

  ApplicationValueRetrieved = "APPLICATION_VALUE_RETRIEVED",
}

export enum EnumUiEvents {
  UiDataSendKarma = "UI_DATA_SEND_KARMA",
  UiGameSendKarma = "UI_GAME_SEND_KARMA",
  UiGameAddFriend = "UI_GAME_ADD_FRIEND",
  UiGameBlockFriend = "UI_GAME_BLOCK_FRIEND",
  UiGameRemoveFriend = "UI_GAME_REMOVE_FRIEND",
  UiGameSearchFriend = "UI_GAME_SEARCH_FRIEND",
  UiGameListFriends = "UI_GAME_LIST_FRIENDS",

  UiEnableInput = "UI_ENABLE_INPUT",
  UiMounted = "UI_MOUNTED",

  UiChatToggleChat = "UiChatToggleChat",
  UiChatMessage = "UI_CHAT_MESSAGE",
  UiChatInput = "UI_CHAT_INPUT",
  UiChatJoinDirect = "UI_CHAT_JOIN_DIRECT",

  UiHighlightElement = "UI_HIGHLIGHT_ELEMENT",
  UiStartFullscreen = "UI_START_FULLSCREEN",

  UiChangePlayerPermissions = "UI_CHANGE_PLAYER_PERMISSIONS",
  UiKickPlayer = "UI_KICK_PLAYER",

  UiLogin = "UI_LOGIN",
  UiRegister = "UI_REGISTER",
  UiLogout = "UI_LOGOUT",
  UiLoginFailed = "UI_LOGIN_FAILED",
  UiLoginSuccess = "UI_LOGIN_SUCCESS",

  UIMatchmakingCancelled = "UI_MATCHMAKING_CANCELLED",
  UIMatchmakingStarted = "UI_MATCHMAKING_STARTED",

  UiWalletChanged = "UI_WALLET_CHANGED",
  // Communication between UI and minigames
  UiGamePlinkoInitialize = "UI_GAME_PLINKO_INITIALIZE",
  UiGamePlinkoStart = "UI_GAME_PLINKO_START",

  // Mines
  UiGameMinesStart = "UI_GAME_MINES_START",
  UiGameMinesCashout = "UI_GAME_MINES_CASHOUT",
  UiGameMinesReset = "UI_GAME_MINES_RESET",

  // Memory Minigame
  UiGameMemoryGameLost = "UI_GAME_MEMORY_GAME_LOST",
  UIGameMemoryGameWon = "UI_GAME_MEMORY_GAME_WON",

  UIGamePushNotificationsList = "UI_GAME_PUSH_NOTIFICATIONS_LIST",
  UIGamePushNotificationsSave = "UI_GAME_PUSH_NOTIFICATIONS_SAVE",

  // UI->Network requests
  UiNetworkRequestInventory = "UI_NETWORK_REQUEST_INVENTORY",
  UiNetworkRequestInventoryUpdate = "UI_NETWORK_REQUEST_INVENTORY_UPDATE",
  UiNetworkRequestResetPassword = "UI_NETWORK_REQUEST_RESET_PASSWORD",
  UINetworkRequestRoomsList = "UI_NETWORK_REQUEST_ROOMS_LIST",
  UiNetworkUpdateCharacter = "UI_NETWORK_UPDATE_CHARACTER",
  UiNetworkGetCurrentAccount = "UI_NETWORK_GET_CURRENT_ACCOUNT",

  UiGameUpdateRemotePlayerData = "UI_GAME_UPDATE_REMOTE_PLAYER_DATA",
  UiGameSettingsUpdateAudio = "UI_GAME_SETTINGS_UPDATE_AUDIO",

  UiGameNpcInteractionReject = "UI_GAME_NPC_INTERACTION_REJECT",
  UiGameNpcInteractionAccept = "UI_GAME_NPC_INTERACTION_ACCEPT",
  UiOnboardingActiveModal = "UI_ONBOARDING_ACTIVE_MODAL",
  UiOpenModal = "UI_OPEN_MODAL",
  UiOpenWalletConnect = "UI_OPEN_WALLET_CONNECT",

  UINetworkLeaderboardRetrieveRecords = "UI_NETWORK_LEADERBOARD_RETRIEVE_RECORDS",
  UINetworkLeaderboardReceivedRecords = "UI_NETWORK_LEADERBOARD_RECEIVED_RECORDS",
  UiNetworkLeaderboardGetTop = "UI_NETWORK_LEADERBOARD_GET_TOP",
  UiNetworkLeaderboardGetTopUpdate = "UI_NETWORK_LEADERBOARD_GET_TOP_UPDATE",

  // Room Editor
  UiPlaceFurniture = "UI_ROOM_EDITOR_PLACE_FURNITURE",
  UiRoomEditorFurnitureSelected = "UI_ROOM_EDITOR_FURNITURE_SELECTED",

  // Store
  UiOpenStoreModal = "UI_OPEN_STORE_MODAL",
  UiOpenStoreItemModal = "UI_OPEN_STORE_ITEM_MODAL",
  UiOpenStoreItemModalByID = "UI_OPEN_STORE_ITEM_MODAL_BY_ID",
  UiOpenUserMenu = "UI_OPEN_USER_MENU",
  UiToggleVoiceChat = "UI_TOGGLE_VOICE_CHAT",

  UiNetworkTradeProposal = "UI_NETWORK_TRADE_PROPOSAL",
  UiNetworkTradeAnswer = "UI_NETWORK_TRADE_ANSWER",
  UiNetworkTradeChange = "UI_NETWORK_TRADE_CHANGE",
  UiNetworkTradeCommit = "UI_NETWORK_TRADE_COMMIT",
  UiNetworkTradeAbandon = "UI_NETWORK_TRADE_ABANDON",

  // Ticket transfer
  UiNetworkUserTicketTransfer = "UI_NETWORK_USER_TICKET_TRANSFER",
  UiUserTicketTransferUpdate = "UI_USER_TICKET_TRANSFER_UPDATE",

  // Mobile
  UiPurchaseSucces = "UI_PURCHASE_SUCCESS",
  UiPurchaseFailed = "UI_PURCHASE_FAILED",
  UiRevCatSignIn = "UI_REVCAT_SIGNIN",
  UiAdRewardSuccess = "UI_AD_REWARD_SUCCESS",
  UiUserCanceledPurchase = "UI_USER_CANCELED_PURCHASE",
  UiAdLoaded = "UI_AD_LOADED",
  UiAdLoading = "UI_AD_LOADING",
  UiMove = "UI_MOVE",
  UiNeedsUpdate = "UI_UPDATE_MOBILE",
}

export enum EnumGameEvents {
  // Plinko
  GamePlinkoGameOver = "GAME_PLINKO_GAME_OVER",

  // Mines
  GameMinesGameOver = "GAME_MINES_GAME_OVER",
  GameMinesAddReward = "GAME_MINES_ADD_REWARD",

  // Bomberman
  GameBombermanPlayerJoined = "GAME_BOMBERMAN_PLAYER_JOINED",
  GameBombermanOpponentJoined = "GAME_BOMBERMAN_OPPONENT_JOINED",
  GameBombermanOpponentLeft = "GAME_BOMBERMAN_OPPONENT_LEFT",
  GameBombermanReady = "GAME_BOMBERMAN_READY",
  GameBombermanLifeLost = "GAME_BOMBERMAN_LIFE_LOST",
  GameBombermanPowerup = "GAME_BOMBERMAN_POWERUP",
  GameBombermanGameOver = "GAME_BOMBERMAN_GAME_OVER",
  GameBombermanSecondPassed = "GAME_BOMBERMAN_SECOND_PASSED",
  GameBombermanFriendlyConquer = "GAME_BOMBERMAN_FRIENDLY_CONQUER",
  GameBombermanEnemyConquer = "GAME_BOMBERMAN_ENEMY_CONQUER",
  GameBombermanConquerTile = "GAME_BOMBERMAN_CONQUER_TILE",

  GamePointToLocationMarker = "GAME_POINT_TO_LOCATION_MARKER",
  GameRemoveLocationMarker = "GAME_REMOVE_LOCATION_MARKER",
  GameUiChatMessage = "GAME_NETWORK_CHAT_MESSAGE",
  // Memory
  GameUiMemoryPlayerEliminated = "GAME_UI_MEMORY_PLAYER_ELIMINATED",

  // Stamp Wars
  GameStampWarsPlayerJoined = "GAME_STAMP_WARS_PLAYER_JOINED",
  GameStampWarsOpponentJoined = "GAME_STAMP_WARS_OPPONENT_JOINED",
  GameStampWarsTick = "GAME_STAMP_WARS_TICK",

  GameCrashMultiplierHistory = "GAME_CRASH_MULTIPLIER_HISTORY",
  GameCrashPlayerHistory = "GAME_CRASH_PLAYER_HISTORY",

  GameRemotePlayerLeft = "GAME_REMOTE_PLAYER_LEFT",
  // Room Editor
  RoomEditorRoomEntered = "ROOM_EDITOR_ROOM_ENTERED",
  RoomEditorFurniturePlaced = "ROOM_EDITOR_FURNITURE_PLACED",

  GameUiListFriends = "GAME_UI_LIST_FRIENDS",
  // Minigame General
  GameUiMinigameStart = "GAME_UI_MINIGAME_START",
  GameUiMinigameEnd = "GAME_UI_MINIGAME_END",
  GameUiMinigameUpdate = "GAME_UI_MINIGAME_UPDATE",
  GameUiMinigameLobbyUpdate = "GAME_UI_MINIGAME_LOBBY_UPDATE",
  GameUiMinigamePlayerUpdate = "GAME_UI_MINIGAME_PLAYER_UPDATE",
  GameUiMinigameShowScoreboard = "GAME_UI_MINIGAME_SHOW_SCOREBOARD",
  GameUiMinigameTicketsUpdated = "GAME_UI_MINIGAME_TICKETS_UPDATED",

  GameUiNpcInteraction = "GAME_UI_NPC_INTERACTION",
  GameUiOpenOnboarding = "GAME_UI_OPEN_ONBOARDING",
  GameUiCloseOnboarding = "GAME_UI_CLOSE_ONBOARDING",
  GameUiPlayerInteraction = "GAME_UI_PLAYER_INTERACTION",

  GameUiAlreadyJoinedError = "GAME_UI_ALREADY_JOINED_ERROR",

  GameUiSearchFriend = "GAME_UI_SEARCH_FRIEND",
  GameUiTradeOpen = "GAME_UI_TRADE_OPEN",
  GameUiTradeProposal = "GAME_UI_TRADE_PROPOSAL",
  GameUiTradeChangeItem = "GAME_UI_TRADE_CHANGE_ITEM",
  GameUiTradeCommit = "GAME_UI_TRADE_CHANGE_COMMIT",
  GameUiTradeAbandon = "GAME_UI_TRADE_CHANGE_ABANDON",
  GameNetworkTradeGetOngoingTrades = "GAME_UI_GET_ONGOING_TRADES",

  GameUiReceivedKarma = "GAME_UI_RECEIVED_KARMA",
  GameUiLevelUpAnimation = "GAME_UI_LEVEL_UP_ANIMATION",
  GameUiRoomUpdateData = "GAME_UI_ROOM_UPDATE_DATA",

  GameUIPushNotificationsRetrieved = "GAME_UI_PUSH_NOTIFICATIONS_RETRIEVED",

  GameUiLevelExperience = "GAME_UI_LEVEL_EXPERIENCE",
  GameUiLevel = "GAME_UI_LEVEL",
  GameUiInitialLevelData = "GAME_UI_INITIAL_LEVEL_DATA",

  UserTransferTickets = "USER_TRANSFER_TICKETS",
  ReceivedUserTicketsDone = "USER_TRANSFER_TICKETS_DONE",
}

export enum EnumNetworkEvents {
  //Generic, callee independent requests
  NetworkRequestUserData = "NETWORK_REQUEST_USER_DATA",
  NetworkRequestUsersData = "NETWORK_REQUEST_USERS_DATA",
  NetworkRequestUsersDataResponse = "NETWORK_REQUEST_USERS_DATA_RESPONSE",
  NetworkRequestUserActiveNfts = "NETWORK_REQUEST_USER_ACTIVE_NFTS",
  NetworkRequestStorageItem = "NETWORK_REQUEST_STORAGE_ITEM",
  NetworkCallRPC = "NETWORK_CALL_RPC",
  NetworkCallRPCResponse = "NETWORK_CALL_RPC_RESPONSE",
  NetworkChannelPresenceJoined = "NETWORK_CHANNEL_PRESENCE_JOINED",
  NetworkChannelPresenceLeft = "NETWORK_CHANNEL_PRESENCE_LEFT",
  NetworkChatMessage = "NETWORK_CHAT_MESSAGE",
  NetworkChatJoinChannel = "NETWORK_CHAT_JOIN_CHANNEL",
  NetworkChatLeaveChannel = "NETWORK_CHAT_LEAVE_CHANNEL",

  NetworkUiUpdateMessageHistory = "NETWORK_UI_UPDATE_MESSAGE_HISTORY",
  NetworkUiUpdateRoomsList = "NETWORK_UI_UPDATE_ROOMS_LIST",
  NetworkApplicationRequestMinigameSettings = "NETWORK_APPLICATION_REQUEST_MINIGAME_SETTINGS",
  NetworkUpdateUserActiveNfts = "NETWORK_UPDATE_USER_ACTIVE_NFTS",
  NetworkSocketDisconnected = "NETWORK_SOCKET_DISCONNECTED",
}
export enum EnumTradeUiEvents {
  UiDataProposal = "UiDataProposal",
  UiDataAnswer = "UiDataAnswer",
  UiDataConfirm = "UiDataConfirm",
  UiDataDecline = "UiDataDecline",
  UiDataAbandon = "UiDataAbandon",
  UiSetItemInTrade = "UiSetItemInTrade",
  UiSetItemOutTrade = "UiSetItemOutTrade",
}
