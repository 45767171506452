export enum NotificationCodes {
  NOTIFICATION_CHAT_DIRECT_INVITE = -1,
  NOTIFICATION_FRIENDS_INVITE = -2,
  NOTIFICATION_FRIENDS_ACCEPT = -3,
  NOTIFICATION_EXPERIENCE = 1020,
  NOTIFICATION_LEVEL = 1021,
  NOTIFICATION_TRADING_PROPOSAL_CODE = 1011,
  NOTIFICATION_TRADING_ANSWER_CODE = 1012,
  NOTIFICATION_TRADING_CHANGE_ITEM_CODE = 1013,
  NOTIFICATION_TRADING_PENDING_CODE = 1014,
  NOTIFICATION_TRADING_COMMIT_CODE = 1015,
  NOTIFICATION_TRADING_ABANDON_CODE = 1016,
  NOTIFICATION_TRANSFER_TICKETS = 1017,
  NOTIFICATION_SEND_KARMA = 1018,
}
