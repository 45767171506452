import {
  RuntimeArgs,
  CLU256,
  CLU64,
  CLU512,
  decodeBase16,
  CLValueParsers,
  CLTypeBuilder,
  Contracts,
} from "casper-js-sdk";
import { Marketplace } from ".";
import { BaseCasper } from "../base/casper";
import { TCasperListing } from "./types";
import wasmModule from "./execute_listing_call.wasm?url";

export class CasperMarketplace extends BaseCasper implements Marketplace {
  constructor(privateKey?: string) {
    const contractHash = import.meta.env.VITE_CSPR_MP;
    super(contractHash, privateKey);
  }

  async addListing(
    nft: string,
    tokenId: number,
    price: number,
  ): Promise<string> {
    if (nft.includes("-")) {
      nft = nft.split("-")[1];
    }
    const runtimeArgs = RuntimeArgs.fromMap({
      collection: this.stringToKey(nft),
      token_id: new CLU64(tokenId),
      price: new CLU256(price * 1_000_000_000),
    });
    const deploy = this._contractClient.callEntrypoint(
      "add_listing",
      runtimeArgs,
      this._publicKey,
      this.getChainName(),
      "10000000000",
    );
    const signedDeploy = await this.signDeploy(deploy);
    return await this.putDeploy(signedDeploy);
  }

  async cancelListing(listingId: number): Promise<string> {
    const runtimeArgs = RuntimeArgs.fromMap({
      listing_id: new CLU64(listingId),
    });
    const deploy = this._contractClient.callEntrypoint(
      "cancel_listing",
      runtimeArgs,
      this._publicKey,
      this.getChainName(),
      "8000000000",
    );
    const signedDeploy = await this.signDeploy(deploy);
    return await this.putDeploy(signedDeploy);
  }

  async executeListing(listingId: number): Promise<string> {
    const purse = await this._casperClient.getAccountMainPurseUref(
      this._publicKey,
    );
    if (!purse) {
      throw new Error(`no purse found`);
    }

    const executeListingFile = await (
      await fetch(`${wasmModule}`)
    ).arrayBuffer();
    const executeListinWasm = new Uint8Array(executeListingFile);
    const contractClient = new Contracts.Contract();

    const listing = await this.getListing(listingId);

    const runtimeArgs = RuntimeArgs.fromMap({
      marketplace_contract_hash: this.stringToKey(this.address),
      listing_id: new CLU64(listingId),
      amount: new CLU512(listing.price),
    });

    const deploy = contractClient.install(
      executeListinWasm,
      runtimeArgs,
      "15000000000",
      this._publicKey,
      this.getChainName(),
    );
    const signedDeploy = await this.signDeploy(deploy);
    return await this.putDeploy(signedDeploy);
  }

  async getListing(listingId: number): Promise<TCasperListing> {
    const stateRootHash =
      await this._casperClient.nodeClient.getStateRootHash();
    const lookup = await this._casperClient.nodeClient.getDictionaryItemByName(
      stateRootHash,
      this.address,
      "listings_dict",
      listingId.toString(),
      {
        rawData: true,
      },
    );
    if (lookup.CLValue) {
      // @ts-ignore
      const lookupBytes = lookup.CLValue.bytes;
      return this.deserializeListing(lookupBytes);
    }
    throw new Error(`No listing found`);
  }

  get contractAddress(): string {
    return this._contractClient.contractHash || "";
  }

  // pub owner: AccountHash,
  // pub collection: ContractHash,
  // pub token_id: TokenId,
  // pub price: U256,
  // pub status: Status
  private deserializeListing(data: string): TCasperListing {
    const accountHash = data.substring(0, 64);
    const collection = data.substring(64, 128);
    const tokenIdStr = data.substring(128, 144);
    const tokenIdCl = CLValueParsers.fromBytes(
      decodeBase16(tokenIdStr),
      CLTypeBuilder.u64(),
    ).unwrap();
    const tokenId = +tokenIdCl.data;

    const priceLength = data.length - 2;
    const priceStr = data.substring(144, priceLength);
    const priceCl = CLValueParsers.fromBytes(
      decodeBase16(priceStr),
      CLTypeBuilder.u256(),
    ).unwrap();
    const price = +priceCl.data;
    const status = +data.substring(priceLength, data.length);
    return {
      owner: accountHash,
      collection,
      tokenId,
      price,
      status,
    };
  }
}
