export * from "./helpers";
import { computed, ref } from "vue";
import Web3Modal from "web3modal";
import { getProviderOptions } from "./helpers";
import { Chain, supportedChains } from "@shared/consts/SupportedChains";
import { EChainId } from "@shared/types";

export * from "./marketplace";
export * from "./nft";

export const { ethereum } = window;

//Default to BSC Mainnet chain
export const currentChainId = ref(EChainId.CSPR);
export const currentChain = computed(() => new Chain(currentChainId.value));
export const currentNetworkId = ref(0);

export const web3Modal = new Web3Modal({
  theme: "dark",
  cacheProvider: false,
  providerOptions: getProviderOptions(currentChainId.value),
});

export function isChainIdValid(chainToCheck?: EChainId) {
  return supportedChains.some(
    (chain) => chain.id == (chainToCheck ? chainToCheck : currentChainId.value),
  );
}
