import IsometricCharacter from "@game/engine/IsometricCharacter";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import AnimatedIsometricAvatarComponent from "@game/engine/components/entitycomponents/AnimatedIsometricAvatarComponent";
import IsometricGameScene from "@game/engine/scenes/IsometricGameScene";

// Will be used later by the controllers
export default class MightyMinesCharacter extends IsometricCharacter {
  public events: Phaser.Events.EventEmitter;
  public fadeTween: Phaser.Tweens.Tween;
  private _animatedIsometricAvatarComponent: AnimatedIsometricAvatarComponent;
  private prevTilePosition: TilePosition3D;

  constructor(scene, x, y, characterName: string) {
    super(scene, x, y);

    this.setDepth(100);

    this.events = new Phaser.Events.EventEmitter();

    // Adding an animated avatar that hooksitself into the character and displays the correct animation based on the player's movement
    this.addComponent<AnimatedIsometricAvatarComponent>(
      new AnimatedIsometricAvatarComponent(
        this.scene as IsometricGameScene,
        characterName,
      ),
    );

    this.prevTilePosition = this.tilePosition;

    this.fadeTween = this.scene.tweens.add({
      targets: this,
      alpha: 0,
      delay: 600,
      ease: Phaser.Math.Easing.Cubic.Out,
      duration: 300,
      repeat: 0,
      yoyo: false,
      paused: true,
      onComplete: () => {
        this.destroy();
      },
    });
  }

  public fadeOut() {
    this.fadeTween.play();
  }

  public tick(deltaSeconds: number, deltaTime: number) {
    super.tick(deltaSeconds, deltaTime);

    if (this.tilePosition !== this.prevTilePosition) {
      this.events.emit("new_tile_reached", this.tilePosition);
      this.prevTilePosition = this.tilePosition;
    }
  }

  public updateCharacterName(characterName: string) {
    this._animatedIsometricAvatarComponent.characterName = characterName;
  }
}
