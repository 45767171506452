import EntityComponent from "@game/engine/components/EntityComponent";
import { scaleAndFadeOut } from "@game/engine/utilities/AnimationHelper";
import { IIdentifiableAsset } from "@engine/objects/DynamicLoader";
import Image = Phaser.GameObjects.Image;
import Vector2 = Phaser.Math.Vector2;
import TWEEN_COMPLETE = Phaser.Tweens.Events.TWEEN_COMPLETE;

export default class HaloComponent extends EntityComponent {
  public static ImageKey = "__halo__";
  private readonly _deltaDistance: number;
  private _halo: Image;
  private _isLoaded: boolean;
  private readonly _offset: Vector2;
  private _timePassed: number;

  constructor(
    private _imageURL: string = "/assets/game/halos/crown.png",
    private _keyExtension: string = "",
  ) {
    super();

    this._deltaDistance = 8;
    this._isLoaded = false;
    this._offset = new Vector2(0, 160);
    this._timePassed = 0;
    this.isTickEnabled = true;
  }

  public override destroy() {
    super.destroy();

    scaleAndFadeOut(this.owner.scene.tweens, this._halo).on(
      TWEEN_COMPLETE,
      () => {
        this._halo.destroy(true);
      },
    );
  }

  public override tick(deltaSeconds: number, deltaTime: number) {
    super.tick(deltaSeconds, deltaTime);

    if (!this._isLoaded) {
      return;
    }

    const followSpeedMultiplyer = 4;
    this._timePassed += deltaSeconds * 2;

    const target: Vector2 = new Vector2(this.owner);
    target.subtract(this._offset);
    target.x += Math.sin(this._timePassed * 0.33) * this._deltaDistance;
    target.y += Math.cos(this._timePassed) * this._deltaDistance * 0.5;

    const haloPosition: Vector2 = new Vector2(this._halo);
    const destination = haloPosition.lerp(
      target,
      deltaSeconds * followSpeedMultiplyer,
    );

    this._halo.setPosition(destination.x, destination.y);
  }

  protected override onOwnerSet() {
    super.onOwnerSet();

    const scene = this.owner.scene;
    const key = HaloComponent.ImageKey + this._keyExtension;
    const assetConfig: IIdentifiableAsset = {
      key,
      type: "image",
      url: this._imageURL,
    };
    scene.dynamicLoadAssetIfNotLoadingOrLoaded(assetConfig, (key: string) => {
      this.displayHalo(scene, key);
    });
  }

  private displayHalo = (scene, key) => {
    this._halo = new Image(scene, this.owner.x, this.owner.y - 128, key)
      .setOrigin(0.5)
      .setDepth(999);
    this._halo.setAlpha(0);
    scene.add.existing(this._halo);

    scene.tweens.add({
      duration: 512,
      props: {
        alpha: 1,
      },
      ease: "Cubic.easeOut",
      targets: this._halo,
    });

    this._isLoaded = true;
  };
}
