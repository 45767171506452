interface Vector3 {
  x: number;
  y: number;
  z: number;
}

export class Grid<T> {
  public allNodes: T[] = [];
  public nodes: T[];

  constructor(
    public width: number,
    public depth: number,
    public height: number,
    private _prefillValue: T = null,
  ) {
    this.nodes = new Array<T>(width * depth * height);

    // If there's a prefill value, let's use it
    this.nodes.fill(_prefillValue);
  }

  public add(x: number, y: number, z: number, value: T) {
    // Calculate the 1d index
    const index = this.index(x, y, z);

    // Store it in an easily accessible way
    this.nodes[index] = value;

    // Store it in a nicely iterable way
    this.allNodes.push(value);
  }

  public addAtPosition(position: Vector3, value: T) {
    this.add(position.x, position.y, position.z, value);
  }

  public get(x: number, y: number, z: number): T {
    return this.nodes[this.index(x, y, z)];
  }

  public getAtPosition(position: Vector3): T {
    return this.get(position.x, position.y, position.z);
  }

  public has(x: number, y: number, z: number): boolean {
    const value: T = this.nodes[this.index(x, y, z)];
    return value !== undefined && value !== this._prefillValue;
  }

  public hasAtPosition(position: Vector3): boolean {
    return this.has(position.x, position.y, position.z);
  }

  public index(x: number, y: number, z: number): number {
    return z * this.width * this.depth + y * this.width + x;
  }

  public isValidPosition(x: number, y: number, z: number): boolean {
    return (
      x < 0 ||
      x > this.width - 1 ||
      y < 0 ||
      y > this.depth - 1 ||
      z < 0 ||
      z > this.height - 1
    );
  }

  public clearAtPosition(position: Vector3) {
    const index = this.index(position.x, position.y, position.z);
    const value: T = this.nodes[index];

    this.nodes[index] = this._prefillValue;
    this.allNodes.splice(this.allNodes.indexOf(value), 1);
  }

  public print() {
    console.log("Contains " + this.allNodes.length + " nodes");
  }
}
