export enum ENftType {
  "None" = "None",
  "Character" = "Character",
  "Room" = "Room",
  "Office" = "Office",
  "Furniture" = "Furniture",
  "Wearable" = "Wearable",
  "Badge" = "Badge",
  "Consumable" = "Consumable",
  "Background" = "Background",
}

export enum EItemType {
  "Listing",
  "Store",
}

export enum ECurrency {
  "sBTH",
  "BTH",
  "Tickets",
}

export enum EPackageSize {
  SMALL = "small",
  MEDIUM = "medium",
  BIG = "big",
  SPECIAL = "special",
}

export type TPackageItem = {
  tokenAmount: number;
  ticketsAmount?: number;
  mobilePrice: string;
  mobileIdentifier: string;
  price: number;
  bonus: number;
  image?: string;
  bonusImage?: string;
};
