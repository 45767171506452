import { Wallet, ethers, getDefaultProvider } from "ethers";
import { CHAIN_NAMESPACES, CustomChainConfig } from "@web3auth/base";
import { Web3AuthCore } from "@web3auth/core";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { TChain } from "@shared/types";
import { JsonRpcProvider } from "@ethersproject/providers";
import { isNativeMobileApp } from "@/mobile/isOnIOS";
import { Chain } from "@shared/consts/SupportedChains";
import { getMobileProvider } from "@/mobile/web3auth";

const bsc = {
  main: {
    rpcUrl: "https://bsc-dataseed3.binance.org",
    chainId: "0x38",
  },
  testnet: {
    rpcUrl: "https://data-seed-prebsc-1-s3.binance.org:8545",
    chainId: "0x61",
  },
};

// Find client id on the web3auth dashboard.
const clientId = import.meta.env.VITE_WEB3AUTH_CLIENT_ID;

export class Web3auth {
  public web3authCore: Web3AuthCore;
  public adapter: OpenloginAdapter;

  constructor(evm: boolean = true) {
    let config: Partial<CustomChainConfig> &
      Pick<CustomChainConfig, "chainNamespace">;

    if (!evm) {
      config = {
        // this is ethereum chain config, change if other chain(Solana, Polygon)
        chainNamespace: "other",
        rpcTarget: "http://209.145.55.218:7777/rpc",
        tickerName: "Casper",
      };
    } else {
      config = {
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: bsc.main.chainId,
      };
    }

    // Init the web3auth core functionality
    this.web3authCore = new Web3AuthCore({
      clientId,
      web3AuthNetwork: "cyan",
      chainConfig: config,
    });

    // Init the web3auth openlogin adapter using Google as login option
    this.adapter = new OpenloginAdapter({
      adapterSettings: {
        clientId,
        network: "cyan",
        uxMode: "popup", // other option: popup
        loginConfig: {
          google: {
            name: "metaverse-google-verifier",
            verifier: "metaverse-google-verifier",
            typeOfLogin: "google",
            clientId:
              "242021910298-hssara2oi9m0ci3dhkgb0mbjefrv8c1v.apps.googleusercontent.com",
          },
        },
      },
    });
    this.web3authCore.configureAdapter(this.adapter);
  }

  public async initWeb3AuthCore() {
    await this.web3authCore.init();
  }

  public async getPrivateKey(): Promise<string> {
    return (await this.web3authCore?.provider?.request({
      method: "private_key",
    })) as string;
  }

  // Return login status of web3auth
  public status(): string {
    return this.web3authCore.status;
  }

  public provider(): any {
    const provider = this.web3authCore.provider;

    return new ethers.providers.Web3Provider(provider as any);
  }

  public async login(): Promise<void> {
    if (!this.web3authCore) {
      console.log("web3auth not initialized yet");
      return;
    }
    try {
      await this.web3authCore.connectTo(this.adapter.name, {
        loginProvider: "google",
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async logout() {
    await this.web3authCore.logout({ cleanup: true });
  }

  public static toEthersWallet(
    privateKey: string,
    chain?: TChain | string,
  ): {
    wallet: ethers.Wallet;
    provider: ethers.providers.JsonRpcProvider;
  } {
    const provider = new ethers.providers.JsonRpcProvider(
      chain
        ? typeof chain !== "string"
          ? chain.rpc_url
          : chain
        : bsc.main.rpcUrl,
    ) as JsonRpcProvider;
    const wallet = new Wallet(privateKey, provider);
    return { wallet, provider };
  }

  public async connect(
    chainId: number,
    reconnect: boolean = false,
  ): Promise<{ provider: JsonRpcProvider; wallet: ethers.Wallet } | undefined> {
    let config: { provider: JsonRpcProvider; wallet: ethers.Wallet };
    if (!reconnect && this.status() === "connected") {
      await this.logout();
    }
    if (reconnect && this.status() !== "connected") {
      return undefined;
    }
    if (!isNativeMobileApp()) {
      if (!reconnect) await this.login();
      config = Web3auth.toEthersWallet(
        await this.getPrivateKey(),
        new Chain(chainId).default,
      );
    } else {
      const res = await getMobileProvider(new Chain(chainId).default);
      if (!res) {
        throw new Error(`smth`);
      } else {
        config = res;
      }
    }
    return config;
  }
}
