import InteractiveFurnitureObject from "@game/mini/room/objects/InteractiveFurnitureObject";
import CustomizableRoomScene from "@game/mini/room/CustomizableRoomScene";
import { TInteractiveFurnitureConfig } from "@shared/consts/InteractiveFurniture";
import { IInteractiveFurnitureUseResponse } from "@common/modules/rooms/interfaces/FurnitureOperations";

export default class FODice extends InteractiveFurnitureObject {
  protected _frameTime: number = 0;
  private _rollResult: number;
  private _usageTime: number = 0;

  constructor(
    scene: CustomizableRoomScene,
    name: string,
    id: string,
    config: TInteractiveFurnitureConfig,
  ) {
    super(scene, name, id, config);
  }

  public override processActivationResponse(
    response: IInteractiveFurnitureUseResponse,
  ) {
    if (response.data.random === 0) {
      this._rollResult = 0;
      this.displayResult();
      return;
    }

    this._activated = true;
    this._frameTime = 0;
    this._rollResult = response.data.random;
    this._usageTime = 0;
  }

  public override setState(state: number) {
    this._rollResult = state;
    this.displayResult();
  }

  protected override update(time, delta) {
    if (!this._scene) return;

    if (this._activated) {
      this._frameTime += delta;
      this._usageTime += delta;

      if (this._usageTime >= this._config.usageInterval) {
        this.displayResult();
        this._activated = false;
      } else if (this._frameTime >= this._config.usageInterval / 20) {
        this.displayRandomNumber();
        this._frameTime -= this._config.usageInterval / 20;
      }
    }
  }

  private displayRandomNumber() {
    let rnd = Phaser.Math.RND.integerInRange(1, 6);

    while (rnd === this._frameNumber) {
      rnd = Phaser.Math.RND.integerInRange(1, 6);
    }

    this._frameNumber = rnd;
    this.updateFrames();
  }

  private displayResult() {
    this._frameNumber = this._rollResult;
    this.updateFrames();
  }
}
