import { MatchData } from "@heroiclabs/nakama-js";
import { NetworkedComponent } from "@game/engine/components/scenecomponents/v2/NetworkedComponent";
import PublicSpaceGameScene from "@game/engine/scenes/PublicSpaceGameScene";
import { PublicSpaceOpCodes } from "@common/modules/publicspace/opcodes/PublicSpaceOpCodes";
import { IPublicSpacePlayer } from "@common/modules/publicspace/interfaces/IPublicSpacePlayer";
import { TilePosition3D } from "@common/interfaces/TilePosition3D";
import FurnitureObject from "@engine/components/scenecomponents/furniture/furniture-objects/FurnitureObject";
import { IInteractiveFurnitureUseResponse } from "@common/modules/rooms/interfaces/FurnitureOperations";
import { IInteractiveFurnitureData } from "@common/modules/rooms/interfaces/IInteractiveFurnitureData";
import TimerEvent = Phaser.Time.TimerEvent;

export class PublicSpaceNetworkedComponent extends NetworkedComponent {
  protected declare _scene: PublicSpaceGameScene;
  private _movementTimer: TimerEvent;

  constructor(private _space: string) {
    super(_space === "room" ? "room" : "publicspace");
  }

  public override findAndJoinMatch(
    params?: { [key: string]: string } | string,
    data: object = {},
  ): Promise<boolean> {
    return super.findAndJoinMatch(this._module, {
      space: this._space,
      ...(<{ [key: string]: string }>params),
    });
  }

  public sendActivateFurniture(furnitureObject: FurnitureObject) {
    this.sendMatchState(PublicSpaceOpCodes.OnFurnitureActivationRequest, {
      id: furnitureObject.id,
    });
  }

  public sendPlayerChangedAvatar() {
    this.sendMatchState(PublicSpaceOpCodes.OnPlayerChangedAvatar);
  }

  public sendPlayerTeleported() {
    this.sendMatchState(PublicSpaceOpCodes.OnPlayerTeleported);
  }

  public sendPlayerWillMoveTo(goal: TilePosition3D) {
    // We'll be waiting for a bit before sending this on through
    if (this._movementTimer && !this._movementTimer.hasDispatched) {
      this._scene.time.removeEvent(this._movementTimer);
    }

    // TODO: change this back
    this.sendMatchState(PublicSpaceOpCodes.OnPlayerSetGoal, goal);
    // this._movementTimer = this.scene.time.delayedCall(512, () => {
    // this.sendMatchState(PublicSpaceOpCodes.OnPlayerSetGoal, goal);
    // });
  }

  protected override onMatchData(matchData: MatchData) {
    switch (matchData.op_code) {
      case PublicSpaceOpCodes.OnFurnitureActivationResponse:
        this._scene.onFurnitureActivated(
          JSON.parse(
            this.decodeMatchData(matchData.data),
          ) as IInteractiveFurnitureUseResponse,
        );
        break;
      case PublicSpaceOpCodes.OnPlayerChangedAvatar: {
        const userId = this.decodeMatchData(matchData.data);
        this._scene.updateRemotePlayerCharacter(userId).finally();
        break;
      }
      case PublicSpaceOpCodes.OnInitializeWorld: {
        const state = this.decodeAndParseMatchData<{
          players: { [key: string]: IPublicSpacePlayer };
          interactiveFurniture: { [key: string]: IInteractiveFurnitureData };
        }>(matchData.data);
        this._scene.initializeWorld(state);
        break;
      }
      case PublicSpaceOpCodes.OnPlayerInteracted: {
        // Nothing yet, this is new
        break;
      }
      case PublicSpaceOpCodes.OnPlayerJoined: {
        const { userId, tilePosition } =
          this.decodeAndParseMatchData<IPublicSpacePlayer>(matchData.data);
        // MARKTWAIN - Should probably be improved, no need to wait probably
        this._scene.addPlayer(userId, tilePosition).finally();
        break;
      }
      case PublicSpaceOpCodes.OnPlayerLeft: {
        const userId = this.decodeMatchData(matchData.data);
        this._scene.removeRemotePlayer(userId);
        break;
      }
      case PublicSpaceOpCodes.OnPlayerSetGoal: {
        const { userId, tilePosition } =
          this.decodeAndParseMatchData<IPublicSpacePlayer>(matchData.data);
        this._scene.moveRemotePlayer(userId, tilePosition);
        break;
      }
      case PublicSpaceOpCodes.OnPlayerTeleported: {
        const userId = this.decodeMatchData(matchData.data);
        this._scene.removeRemotePlayer(userId);
        break;
      }
    }
  }
}
