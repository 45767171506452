import SceneComponent from "@game/engine/components/SceneComponent";
import Camera = Phaser.Cameras.Scene2D.Camera;
import SmoothedKeyControl = Phaser.Cameras.Controls.SmoothedKeyControl;
import Rectangle = Phaser.Geom.Rectangle;
import Vector2 = Phaser.Math.Vector2;
import Transform = Phaser.GameObjects.Components.Transform;
import TimerEvent = Phaser.Time.TimerEvent;
import Key = Phaser.Input.Keyboard.Key;
import KeyCodes = Phaser.Input.Keyboard.KeyCodes;
import Vector2Like = Phaser.Types.Math.Vector2Like;

const ZOOM_OUT_HEIGHT = 500;

export default class SmartCameraComponent extends SceneComponent {
  public static EventTypes = {
    Moved: "smartcameramoved",
  };

  // Before we take control of the camera again
  public idleTimeout: number = 2000;
  public followOffset: { x: number; y: number } = { x: 0, y: 0 };

  private _controls: SmoothedKeyControl;
  private _isFollowing: boolean;
  private readonly _keys: { [key: number]: Key };
  private _timer: TimerEvent;

  constructor(
    public camera: Camera,
    public objectToFollow?: Transform,
    public followSpeedMultiplyer: number = 2,
    public zoomSpeedMultiplier: number = 2,
    public boundsOffset: number = 240,
  ) {
    super();

    this._isFollowing = false;
    this._keys = {};
    this._timer = null;

    if (this.objectToFollow) {
      this.camera.centerOn(this.objectToFollow.x, this.objectToFollow.y);
      this._midPoint = this.camera.midPoint;

      this._isFollowing = true;
    }

    this.isPreTickEnabled = true;
  }

  private _midPoint: Vector2;

  public preTick(deltaSeconds: number, deltaTime: number): void {
    super.preTick(deltaSeconds, deltaTime);

    // If we're not following something we're going to allow movement via keyboard
    if (this._isFollowing) {
      const focusPosition = new Vector2(
        this.objectToFollow.x + this.followOffset.x,
        this.objectToFollow.y + this.followOffset.y,
      );
      const delta = focusPosition
        .clone()
        .subtract(this._midPoint)
        .multiply(
          new Vector2(
            deltaSeconds * this.followSpeedMultiplyer,
            deltaSeconds * this.followSpeedMultiplyer,
          ),
        );
      const target = this._midPoint.clone().add(delta);
      // Check if we're still attached to a scene
      if (this.camera.scene) {
        this.camera.centerOn(target.x, target.y);
        this._midPoint = target;
      }

      // Let other's know we've moved- but within reason
      if (!this.camera.midPoint.fuzzyEquals(this.objectToFollow, 1)) {
        this.emit(SmartCameraComponent.EventTypes.Moved, this);
      }
    } else {
      this._controls.update(deltaTime);
    }
  }

  protected onSceneSet() {
    super.onSceneSet();

    // Changes settings for smaller mobile screens
    if (!this.scene.game.device.os.desktop) {
      this.camera.setZoom(0.66);
      this.followOffset.y = -125;
    }

    // If we didn't get a camera on initialization, just grab the main one
    if (!this.camera) {
      this.camera = this.scene.cameras.main;
    }

    // Check if we have something to follow
    if (this.objectToFollow) {
      this.startFollowing();
    }

    // this.camera.setBounds(this.scene)
    // If the scene has bounds, apply them
    const bounds: Rectangle = this.scene.getBounds();
    if (bounds) {
      this.camera.setBounds(
        bounds.x - this.boundsOffset,
        bounds.y - this.boundsOffset,
        bounds.width,
        bounds.height,
      );
    }

    // Setting up input movement
    this._keys[KeyCodes.DOWN] = this.scene.input.keyboard.addKey(KeyCodes.DOWN);
    this._keys[KeyCodes.LEFT] = this.scene.input.keyboard.addKey(KeyCodes.LEFT);
    this._keys[KeyCodes.RIGHT] = this.scene.input.keyboard.addKey(
      KeyCodes.RIGHT,
    );
    this._keys[KeyCodes.UP] = this.scene.input.keyboard.addKey(KeyCodes.UP);

    // If any of the cursors are pressed, we stop following
    this._keys[KeyCodes.DOWN].on("down", this.stopFollowing, this);
    this._keys[KeyCodes.LEFT].on("down", this.stopFollowing, this);
    this._keys[KeyCodes.RIGHT].on("down", this.stopFollowing, this);
    this._keys[KeyCodes.UP].on("down", this.stopFollowing, this);

    this._keys[KeyCodes.DOWN].on("up", this.startOrRestartTimer, this);
    this._keys[KeyCodes.LEFT].on("up", this.startOrRestartTimer, this);
    this._keys[KeyCodes.RIGHT].on("up", this.startOrRestartTimer, this);
    this._keys[KeyCodes.UP].on("up", this.startOrRestartTimer, this);

    this.scene.input.on("wheel", this.smoothCameraZoom, this);

    this._controls = new SmoothedKeyControl({
      camera: this.camera,
      down: this._keys[KeyCodes.DOWN],
      left: this._keys[KeyCodes.LEFT],
      right: this._keys[KeyCodes.RIGHT],
      up: this._keys[KeyCodes.UP],
      acceleration: 1,
      drag: 0.004,
      maxSpeed: 1,
    });
  }

  protected onShutdown() {
    super.onShutdown();

    // Remove all input events
    this._keys[KeyCodes.DOWN].off("down", this.stopFollowing, this);
    this._keys[KeyCodes.LEFT].off("down", this.stopFollowing, this);
    this._keys[KeyCodes.RIGHT].off("down", this.stopFollowing, this);
    this._keys[KeyCodes.UP].off("down", this.stopFollowing, this);

    this.scene.input.off("wheel", this.smoothCameraZoom, this);

    // Stop the timer if needed
    this.stopTimerIfNeeded();
  }

  private startFollowing() {
    if (!this._isFollowing) {
      this._midPoint = this.camera.midPoint;
      this._isFollowing = true;
    }
  }

  private startOrRestartTimer() {
    this.stopTimerIfNeeded();
    this._timer = this.scene.time.delayedCall(
      this.idleTimeout,
      this.startFollowing,
      null,
      this,
    );
  }

  private stopFollowing() {
    // In case we're already counting down, stop it
    this.stopTimerIfNeeded();

    if (this._isFollowing) {
      this._isFollowing = false;
    }
  }

  private stopTimerIfNeeded() {
    if (null !== this._timer) {
      this._timer.remove(false);
    }
  }

  private smoothCameraZoom(pointer, gameObjects, deltaX, deltaY, deltaZ) {
    if (deltaY > 0) {
      let newZoom = this.camera.zoom - 0.1 * this.zoomSpeedMultiplier;
      if (newZoom > 0.3) {
        this.camera.zoomTo(newZoom, 150, Phaser.Math.Easing.Sine.Out);
      }
    }

    if (deltaY < 0) {
      let newZoom = this.camera.zoom + 0.1 * this.zoomSpeedMultiplier;
      if (newZoom < 1.3) {
        this.camera.zoomTo(newZoom, 150, Phaser.Math.Easing.Sine.Out);
      }
    }
  }
}
