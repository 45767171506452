import {
  displayMiniGameSettings,
  startMiniGame,
} from "@game/ApplicationHandler";
import OrderableIsometricCharacter from "@game/engine/gameobjects/OrderableIsometricCharacter";
import SceneComponent from "@game/engine/components/SceneComponent";
import TilePath from "@game/engine/objects/TilePath";
import GameScene from "@game/engine/scenes/GameScene";
import OrderableIsometricGameScene from "@game/engine/scenes/OrderableIsometricGameScene";
import Network from "@engine/networking/Network";
import NotificationCenter from "@engine/networking/NotificationCenter";

export default class TeleporterSceneComponent extends SceneComponent {
  protected declare _scene: OrderableIsometricGameScene;

  constructor(
    private _player: OrderableIsometricCharacter,
    private _disallowedTargetSceneKeys: string[] = [],
  ) {
    super();
  }

  protected override onSceneSet(scene?: OrderableIsometricGameScene) {
    super.onSceneSet(scene);

    this._player.on(
      OrderableIsometricCharacter.EventTypes.CompletedPath,
      async (tilePath: TilePath) => {
        // Check if the player ended on a tile with special properties
        const { goal } = tilePath;

        if (this._scene.hasPropertiesContainer(goal.x, goal.y, goal.z)) {
          const propertiesContainer = this._scene.getPropertiesContainer(
            goal.x,
            goal.y,
            goal.z,
          );

          if (
            "trigger" === propertiesContainer.getProperty("Type", undefined) &&
            "teleport" === propertiesContainer.getProperty("Action", undefined)
          ) {
            const target = propertiesContainer.getProperty("Target") as string;
            if (target) {
              // Do a check if these scenes have been disabled in the mean time
              const disabledModules = await Network.getDisabledModules();
              const disabledModule = disabledModules.find(
                (module) => module.scene === target,
              );
              if (!disabledModule) {
                if (!this._disallowedTargetSceneKeys.includes(target)) {
                  // So they and other's know they'll be teleporting
                  this._player.teleport();

                  // Retrieve the SceneKey, which was more complex than I thought..
                  const minigameSettings =
                    await Network.requestMinigameSettings(target);
                  if (
                    !minigameSettings.currency ||
                    minigameSettings.currency.length === 0
                  ) {
                    startMiniGame(
                      target,
                      {},
                      (scene.constructor as typeof GameScene).SceneKey,
                    );
                  } else {
                    displayMiniGameSettings({
                      sceneKey: target,
                      ...minigameSettings,
                    });
                  }
                } else {
                  console.error(
                    "Trying to teleport to a disallowed scene " + target,
                  );
                }
              } else {
                NotificationCenter.instance.displaySystemWideNotification(
                  disabledModule.notification?.subject || "Under Construction",
                  disabledModule.notification?.message ||
                    "This area is currently off-limits. Please check back later",
                );
              }
            } else {
              console.error(
                "No (valid) Target set for trigger at " +
                  JSON.stringify(propertiesContainer.tilePosition),
              );
            }
          }
        }
      },
    );
  }

  protected override onShutdown() {
    super.onShutdown();
  }
}
