export default [
  {
    address: ["0x69938683075dD86CF2fb1c9BFa6AA476cF409c7E"],
    type: "furniture",
    metadata: {
      name: "Gamer TV Shelf",
      description:
        "A Large and Epic Gamer TV Shelf that only very few gamers in the hotel own. It allows the user to store all kinds of cool gaming equipment and is often used as the center-piece of your gaming room.",
      attributes: [
        { trait_type: "Rarity", value: "Epic" },
        { trait_type: "Replicas", value: "10" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "gamertvshelforange_5x1x3_1",
    },
  },
  {
    address: ["0x72f2087933276bfDD6a055D8145B353F3594e5f1"],
    type: "furniture",
    metadata: {
      name: "Modern Stove",
      description:
        "A cute little stove to cook dinner on with your friends. Part of the modern kitchen furniture collection this kitchen appliance is one of the more rare parts of the hotel room kitchens due to its scarcity.",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "20" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "modernstovepink_1x1x2_7",
    },
  },
  {
    address: ["0x21FA2D2F5AF55dF1D0e7e2003D0714A1d9845A63"],
    type: "furniture",
    metadata: {
      name: "Rocket Lava Lamp",
      description:
        "One of the few lava lamps owned by hotel guests, its vibrant color and animated look make it a must-have for the average hotel guest. Fits especially well with a chill gaming room setup.",
      attributes: [
        { trait_type: "Rarity", value: "Super Rare" },
        { trait_type: "Replicas", value: "30" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "rocketlavalamppink_1x1x2_11",
    },
  },
  {
    address: ["0xaF47F5872Eb75723E3E85821aE1a62dC127CDFdC"],
    type: "furniture",
    metadata: {
      name: "Bit Throne",
      description:
        "A seat for the high and mighty hotel guests that wish to be addressed with 'Your Majesty'. The Bit Throne is one of the rare seats that very few hotel guests can own and signifies prestige in the Bit Hotel.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "40" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "throne_1x1x3_1",
      disabled: true,
    },
  },
  {
    address: ["0x8Be7759AcDd8d303653A491b4FC37E8D070c3411"],
    type: "furniture",
    metadata: {
      name: "Television",
      description:
        "Wanna watch Bitflix together? This television is a great must-have for those long nights of Bitflix and Chill. Definitely a requisite for your first in-game date night.",
      attributes: [
        { trait_type: "Rarity", value: "Rare" },
        { trait_type: "Replicas", value: "60" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "TVbitflixpurple_2x1x2_18",
    },
  },
  {
    address: ["0x44e5473DE133370F3217B7A902b5FF7ae0A463Ae"],
    type: "furniture",
    metadata: {
      name: "Speaker Stand",
      description:
        "Want to throw a party in your room? This speaker stand allows you to organize a fun music event or rave in your room and is part of the music furniture collection. In high demand under most hotel guests.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "100" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "speakerstandteal_1x1x2_2",
    },
  },
  {
    address: ["0xc14f60bDC53dbC75A1479Ee72FB5fA44403874FC"],
    type: "furniture",
    metadata: {
      name: "Fancy Sofa",
      description:
        "One of few majestic sofas that have gold plated sides to reflect your charms brightly into the eyes of your room guests. Definitely a rare items to see among Hotel guest rooms, therefore in high demand.",
      attributes: [
        { trait_type: "Rarity", value: "Uncommon" },
        { trait_type: "Replicas", value: "150" },
        { trait_type: "Drop", value: "Season 2" },
      ],
      texture: "fancysofapurple_2x1x3_1",
    },
  },
];
