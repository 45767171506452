<script setup lang="ts">
import { EnumNetworkEvents, dispatchEvent, handleEvent } from "@shared/Events";
import { onBeforeUnmount, onMounted, ref } from "vue";

const version = ref({
  major: 0,
  minor: 10,
  tag: "beta",
});
const buildDate = ref(import.meta.env.VITE_BUILD_DATE);
onMounted(() => {
  //Get version number from nakama api
  handleEvent(
    EnumNetworkEvents.NetworkCallRPCResponse,
    (data: { identifier: string; payload: any }) => {
      if (data.identifier && data.identifier == "Version") {
        version.value = data.payload.version;
      }
    },
    onBeforeUnmount,
  );
  dispatchEvent(EnumNetworkEvents.NetworkCallRPC, {
    identifier: "Version",
    input: {},
  });
});
</script>
<template>
  <div class="font depixel bold color--white outlined shadow">
    <span :key="key" v-for="key in Object.keys(version)">
      {{ version[key] }}
    </span>
    <span v-if="buildDate">
      {{ " " + buildDate }}
    </span>
  </div>
</template>
<style scoped lang="scss">
div {
  position: fixed;
  pointer-events: none;
  bottom: 0;
  right: get-size(xs);
  z-index: 9999999999999;
  font-size: 10px;
  opacity: 0.5;
  span {
    &:first-of-type::after {
      content: ".";
    }
    &:nth-of-type(2)::after {
      content: "-";
    }
  }
}
</style>
