import { usableCharacterNames } from "@shared/consts/Characters";
import { Scene } from "phaser";
import { NFTHelpers } from "@shared/nft";

export class ResourcesHelper {
  private static _resourcesFound: Array<string> = [];
  private static _resourcesMissing: Array<string> = [];

  public static async characterExistsOrForgetAsync(characterName) {
    return (
      (await this.resourceExistsOrForgetAsync(
        "/assets/game/characters/" + characterName + ".png",
      )) === true &&
      (await this.resourceExistsOrForgetAsync(
        "/assets/game/characters/" + characterName + ".json",
      )) === true
    );
  }

  public static async checkAllAssetsAsync() {
    usableCharacterNames.forEach((characterName) => {
      this.characterExistsOrForgetAsync(characterName);
    });

    NFTHelpers.allNfts.forEach((nft) => {
      this.resourceExistsOrForgetAsync(
        `/assets/overlay/nfts/${nft.metadata.name}.png`,
      );
    });
  }

  public static resourceExists(url: string) {
    // if (this._resourcesFound.includes(url) === true) {
    //   return true;
    // }
    // if (this._resourcesMissing.includes(url) === true) {
    //   return false;
    // }
    // //
    // this.resourceExistsOrForgetAsync(url);
    return true;
  }

  /**
   * Checks memory if resource was checked before,
   * if not: makes HEAD request and saves url in memory
   * @param url
   * @returns response.content-type!==text/html
   */
  public static async resourceExistsOrForgetAsync(
    url: string,
  ): Promise<boolean> {
    if ((await this.resourceExistsAsync(url)) === false) {
      this._resourcesMissing.push(url);
      return false;
    }
    this._resourcesFound.push(url);
    return true;
  }

  /**
   * @param url resource url relative to location.origin
   * @returns boolean
   */
  private static async resourceExistsAsync(url: string): Promise<boolean> {
    return true;
    // const response = await fetch(url, {
    //   method: "HEAD",
    // });
    // const contentType = response.headers.get("content-type") || "text/html";
    // return !contentType.toLowerCase().includes("text/html");
  }
}

export default function loadCharacterAtlasAndJSONIntoScene(
  scene: Scene,
  characterName: string,
) {
  if (ResourcesHelper.resourceExists(characterName) === false) {
    return;
  }

  scene.load.image(
    characterName,
    `/assets/overlay/avatar/${characterName}.png`,
  );

  scene.load.atlas(
    characterName + "_atlas",
    "assets/game/characters/" + characterName + ".png",
    "assets/game/characters/" + characterName + ".json",
  );
  scene.load.json(
    characterName + "_json",
    "assets/game/characters/" + characterName + ".json",
  );
}

export function loadAllAvailableCharacterAtlasAndJSONIntoScene(scene: Scene) {
  usableCharacterNames.forEach((characterName) => {
    loadCharacterAtlasAndJSONIntoScene(scene, characterName);
  });
}
