import { App } from "vue";
import DefaultPlugins from "./plugins";
// import DefaultComponents from "./components";
export async function installPlugins(app: App, _plugins?: Array<any>) {
  _plugins = _plugins || DefaultPlugins;
  for (const plugin of _plugins) {
    if (plugin.plugin) {
      if (plugin.additional) {
        app.use(plugin.plugin, plugin.options, ...plugin.additional);
        continue;
      }
      app.use(plugin.plugin, plugin.options);
      continue;
    } else {
      app.use(plugin);
      continue;
    }
  }
}

// export async function addGlobalComponents(app: App, _components?: Array<any>) {
//   _components = _components || DefaultComponents;
//   for (const component of _components) {
//     app.component(component.name, component.component);
//   }
// }
