import OrderableSelector from "@game/mini/lobby/gameobjects/OrderableSelector";
import RemotePlayerCharacter from "@game/mini/lobby/gameobjects/RemotePlayerCharacter";
import AudioComponent from "@game/engine/components/scenecomponents/AudioComponent";
import InteractableImagesComponent from "@game/engine/components/scenecomponents/InteractableImagesComponent";
import LoadingSceneComponent from "@game/engine/components/scenecomponents/LoadingSceneComponent";
import NPCSpawnerSceneComponent from "@game/engine/components/scenecomponents/NPCSpawnerSceneComponent";
import SmartCameraComponent from "@game/engine/components/scenecomponents/SmartCameraComponent";
import TeleporterSceneComponent from "@game/engine/components/scenecomponents/TeleporterSceneComponent";
import OrderableNonPlayerCharacter from "@game/engine/gameobjects/OrderableNonPlayerCharacter";
import { IInteractableImagesDelegate } from "@game/engine/interfaces/IInteractableImagesDelegate";
import { INPCSpawnerDelegate } from "@game/engine/interfaces/INPCSpawnerDelegate";
import ISpawnData from "@game/engine/interfaces/ISpawnData";
import IsometricCharacter from "@game/engine/IsometricCharacter";
import { Direction, NameToDirection } from "@game/engine/navigation/Direction";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import OrderableImage from "@game/engine/objects/OrderableImage";
import TilePath from "@game/engine/objects/TilePath";
import OrderableIsometricGameScene from "@game/engine/scenes/OrderableIsometricGameScene";
import loadCharacterAtlasAndJSONIntoScene from "@game/engine/utilities/AssetLoaderHelper";
import equals from "@game/engine/utilities/TilePositionHelper";
import { usableCharacterNames } from "@shared/consts/Characters";
import {
  dispatchEvent,
  EnumApplicationEvents,
  EnumGameEvents,
  EnumNetworkEvents,
  EnumUiEvents,
  handleEvent,
  TEventHandler,
} from "@shared/Events";
import { currentAccount } from "@shared/Global";
import { formatNftName } from "@shared/Helpers";
import { TRoomRole } from "@shared/types";
import RemotePlayerManagerSceneComponent from "../components/scenecomponents/RemotePlayerManagerSceneComponent";
import { IRemotePlayerManagerDelegate } from "../interfaces/IRemotePlayerManagerDelegate";
import IChatMessageEvent, {
  EnumChatEvents,
} from "@game/engine/interfaces/IChatMessageEvent";
import DynamicLoadComponent, {
  AssetList,
} from "@game/engine/components/scenecomponents/DynamicLoadComponent";
import { TradeSceneComponent } from "../components/scenecomponents/TradeSceneComponent";
import { ITradeDelegate } from "../interfaces/ITradeDelegate";
import { TradeSide } from "@shared/types/TradeTypes";
import { IPublicSpacePlayer } from "@common/modules/publicspace/interfaces/IPublicSpacePlayer";
import { Network } from "@network";
import { PublicSpaceNetworkedComponent } from "@game/mini/publicspace/components/scenecomponents/PublicSpaceNetworkedComponent";
import FurnitureComponent, {
  TFurniturePlacement,
} from "@game/engine/components/scenecomponents/furniture/FurnitureComponent";
import FurnitureObject from "@game/engine/components/scenecomponents/furniture/furniture-objects/FurnitureObject";
import InteractiveFurnitureObject from "@game/mini/room/objects/InteractiveFurnitureObject";
import TilePathFollowerComponent from "@game/engine/components/entitycomponents/TilePathFollowerComponent";
import { RoomNetworkedComponent } from "@game/mini/room/components/scenecomponents/RoomNetworkedComponent";
import CustomizableRoomScene from "@game/mini/room/CustomizableRoomScene";
import { GeneralUtilityComponent } from "../components/scenecomponents/GeneralUtilityComponent";
import { IGeneralUtilityDelegate } from "@engine/interfaces/IGeneralUtilityDelegate";
import { FurnitureNFTs } from "@shared/consts/nfts/FurnitureNFTs";
import { IInteractiveFurnitureUseResponse } from "@common/modules/rooms/interfaces/FurnitureOperations";
import { IInteractiveFurnitureData } from "@common/modules/rooms/interfaces/IInteractiveFurnitureData";
import isMobile from "@shared/IsMobile";
import { OrderableIsometricPlayer } from "@engine/gameobjects/OrderableIsometricPlayer";
import OrderableIsometricCharacter from "@engine/gameobjects/OrderableIsometricCharacter";
import { ConsumableNetworkedComponent } from "@engine/components/scenecomponents/ConsumableNetworkedComponent";
import { NFTHelpers } from "@shared/nft";
import OnboardingSceneComponent from "@engine/components/scenecomponents/OnboardingSceneComponent";
import { LocationMarkerSceneComponent } from "@engine/components/scenecomponents/LocationMarkerSceneComponent";
import {
  OnboardingArcadeTaskTracker,
  OnboardingLobbyTaskTracker,
  OnboardingShopTaskTracker,
} from "@engine/components/scenecomponents/tasks/OnboardingTaskTrackers";
import TouchControlsSceneComponent from "@engine/components/scenecomponents/TouchControlsSceneComponent";
import { mobileLandscape } from "@/mobile/postMessage";
import { isNativeMobileApp } from "@/mobile/isOnIOS";
import {
  PublicSpaceInputComponent,
  PublicSpaceInputEvent,
} from "@engine/components/scenecomponents/PublicSpaceInputComponent";
import Vector2 = Phaser.Math.Vector2;
import Tile = Phaser.Tilemaps.Tile;

export default class PublicSpaceGameScene
  extends OrderableIsometricGameScene
  implements
    INPCSpawnerDelegate,
    IInteractableImagesDelegate,
    IRemotePlayerManagerDelegate,
    ITradeDelegate,
    IGeneralUtilityDelegate
{
  protected _audioComponent: AudioComponent;
  // EnumAllEvents + "-" + id returned from handleEvent() that need to be removed onShutdown
  protected _eventHandlers: Array<TEventHandler> = [];
  protected _furnitureComponent: FurnitureComponent;
  protected _loadingSceneComponent: LoadingSceneComponent;
  protected _localPlayer: OrderableIsometricPlayer;
  protected _localPlayerId: string;
  protected _networkedComponent:
    | PublicSpaceNetworkedComponent
    | RoomNetworkedComponent;
  protected _playerRole: TRoomRole;
  protected _selector: OrderableSelector;
  private _characterName: string = "default_character";
  private _consumableNetworkedComponent: ConsumableNetworkedComponent;
  private _generalUtilityComponent: GeneralUtilityComponent;
  private readonly _isMobile: boolean;
  private _lastHoveredPosition: TilePosition3D;
  private _onboardingSceneComponent?: OnboardingSceneComponent;
  protected _publicSpaceInputComponent: PublicSpaceInputComponent;
  private _remotePlayerManagerComponent: RemotePlayerManagerSceneComponent;
  private _selectedSpawnLocationIdentifier: string;
  private _spawnDatas: {
    [identifier: string]: ISpawnData;
  };
  private _touchControlsSceneComponent: TouchControlsSceneComponent;
  private _tradeComponent: TradeSceneComponent;

  protected _dynamicLoadComponent: DynamicLoadComponent;

  public get dynamicLoadComponent(): DynamicLoadComponent {
    return this._dynamicLoadComponent;
  }

  protected _spawnData: ISpawnData;

  public get spawnData(): ISpawnData {
    return this._spawnData;
  }

  constructor(
    config,
    assetPath: string,
    tilemapPath: string,
    tilesetPathOrPaths: string[],
    imagePathOrPaths?,
    orderableTilesetImportDatas?,
    animationAtlasPaths?,
    sortEveryFrame?,
  ) {
    super(
      config,
      assetPath,
      tilemapPath,
      tilesetPathOrPaths,
      imagePathOrPaths,
      orderableTilesetImportDatas,
      animationAtlasPaths,
      sortEveryFrame,
    );

    // By default we spawn on the default location. Makes sense
    this._selectedSpawnLocationIdentifier = "default";

    this._isMobile = isMobile();
  }

  public async addPlayer(userId: string, tilePosition?: TilePosition3D) {
    if (this._localPlayerId === userId) {
      await this.addPlayerWithDependencies();
    } else {
      await this.addRemotePlayer(userId, tilePosition);
    }
  }

  public getNetworkMatchIdentifier() {
    return "lobby";
  }

  public getPlayerById(userId: string): OrderableIsometricCharacter | null {
    // First check if it's ourself
    if (userId === this._localPlayerId) {
      return this._localPlayer;
    }

    // Otherwise check the others
    return this._remotePlayerManagerComponent.getPlayerById(userId);
  }

  public getTileAt(tilePosition: TilePosition3D): Tile {
    return this._navigatableLayers[tilePosition.z].getTileAt(
      tilePosition.x,
      tilePosition.y,
    );
  }

  public handleChatMessage(messageEvent: IChatMessageEvent) {
    const messageDetails = messageEvent;
    if (messageDetails.sender_id === currentAccount.user.id) {
      if (this._localPlayer?.say) {
        try {
          this._localPlayer.say(
            messageDetails.content.message,
            false,
            messageDetails.content.textStyle,
          );
        } catch (e) {
          console.error("localPlayer has no chatBubbleComponent");
        }
      }
    }
    const remotePlayer = this._remotePlayerManagerComponent.getPlayerById(
      messageDetails.sender_id,
    );
    this._remotePlayerManagerComponent
      .tryAddToCache(messageDetails.sender_id, remotePlayer?.userData?.metadata)
      .finally();
    if (remotePlayer) {
      try {
        remotePlayer.say(
          messageDetails.content.message,
          true,
          messageDetails.content.textStyle,
        );
      } catch (e) {
        console.log(e);
        console.error("remotePlayer has no chatBubbleComponent");
      }
    }
    dispatchEvent(EnumGameEvents.GameUiChatMessage, messageEvent);
  }

  public handleNetworkMessage = (messageEvent: IChatMessageEvent) => {
    if (
      this._localPlayer.allowedMessage &&
      !this._localPlayer.allowedMessage(messageEvent.sender_id)
    ) {
      return;
    }
    switch (messageEvent.content.eventType) {
      case EnumChatEvents.ChatTyping: {
        this.handleChatMessage(messageEvent);
        break;
      }
      case EnumChatEvents.ChatStartTyping: {
        this.handleUiNetworkChatStartTyping(messageEvent.sender_id);
        break;
      }
      case EnumChatEvents.ChatStopTyping: {
        this.handleUiNetworkChatStopTyping(messageEvent.sender_id);
        break;
      }
    }
  };

  public handleUiNetworkChatStartTyping(sender_id: string) {
    if (sender_id === currentAccount.user.id) {
      if (this._localPlayer?.startTypingAnimation) {
        try {
          this._localPlayer.startTypingAnimation();
        } catch (e) {
          console.log(e);
          console.error("localPlayer has no chatBubbleComponent");
        }
      }
    }
    const remotePlayer =
      this._remotePlayerManagerComponent.getPlayerById(sender_id);
    this._remotePlayerManagerComponent
      .tryAddToCache(sender_id, remotePlayer?.userData?.metadata)
      .finally();
    if (remotePlayer) {
      try {
        remotePlayer.startTypingAnimation();
      } catch (e) {
        console.log(e);
        console.error("remotePlayer has no chatBubbleComponent");
      }
    }
  }

  public handleUiNetworkChatStopTyping(sender_id: string) {
    if (sender_id === currentAccount.user.id) {
      if (this._localPlayer?.stopTypingAnimation) {
        try {
          this._localPlayer.stopTypingAnimation();
        } catch (e) {
          console.log(e);
          console.error("localPlayer has no chatBubbleComponent");
        }
      }
    }
    const remotePlayer =
      this._remotePlayerManagerComponent.getPlayerById(sender_id);
    this._remotePlayerManagerComponent
      .tryAddToCache(sender_id, remotePlayer?.userData?.metadata)
      .finally();
    if (remotePlayer) {
      try {
        remotePlayer.stopTypingAnimation();
      } catch (e) {
        console.log(e);
        console.error("remotePlayer has no chatBubbleComponent");
      }
    }
  }

  public initializeWorld(state: {
    players: { [key: string]: IPublicSpacePlayer };
    interactiveFurniture: { [key: string]: IInteractiveFurnitureData };
  }) {
    this._localPlayerId = Network.Core.core.currentUser.id;

    Object.values(state.players).forEach(async (otherPlayer) => {
      await this.addPlayer(otherPlayer.userId, otherPlayer.tilePosition);
    });

    this._furnitureComponent.onFurnitureInit(state.interactiveFurniture);
  }

  public move(tilePosition: TilePosition3D) {
    const furniture =
      this._furnitureComponent.getFurnitureObjectAt(tilePosition);
    if (furniture) {
      console.log({
        furniX: furniture.floorPositions[0].x,
        furniY: furniture.floorPositions[0].y,
      });
    }
    if (furniture && furniture.interactive) {
      this.movePlayerToInteractWithFurniture(furniture);
    } else {
      this.moveCharacterToGoalIfPossible(this._localPlayer, tilePosition);
    }
  }

  public moveCharacterToGoalIfPossible = (
    character: IsometricCharacter,
    goalPosition: TilePosition3D,
  ) => {
    const path: TilePath = this._pathfinder.find(
      character.tilePosition,
      goalPosition,
    );
    character.follow(path);
  };

  public movePlayerToInteractWithFurniture(furnitureObject: FurnitureObject) {
    const playerPos: TilePosition3D = this._localPlayer.tilePosition;
    const closestPosition: TilePosition3D =
      this._furnitureComponent.getClosestWalkablePositionToFurniture(
        playerPos,
        furnitureObject,
        this._pathfinder,
      );

    if (closestPosition) {
      if (equals(closestPosition, this._localPlayer.tilePosition)) {
        this.activateFurniture(furnitureObject as InteractiveFurnitureObject);
      } else {
        this.moveCharacterToGoalIfPossible(this._localPlayer, closestPosition);
        this._localPlayer.pathFollowerComponent.events.once(
          TilePathFollowerComponent.EventTypes.Completed,
          () => {
            if (equals(this._localPlayer.tilePosition, closestPosition)) {
              this.activateFurniture(
                furnitureObject as InteractiveFurnitureObject,
              );
            }
          },
          this,
        );
      }
    }
  }

  public moveRemotePlayer(userId: string, goalPosition: TilePosition3D) {
    if (this._localPlayerId !== userId) {
      this._remotePlayerManagerComponent.moveRemotePlayer(userId, goalPosition);
    }
  }

  public onAddFriend(userId: string): Promise<boolean> {
    return this._generalUtilityComponent.addFriend(userId);
  }

  public onBlockFriend(userId: string): Promise<boolean> {
    return this._generalUtilityComponent.blockFriend(userId);
  }

  public onFurnitureActivated(response: IInteractiveFurnitureUseResponse) {
    if (!response.success) return;

    this._furnitureComponent.onFurnitureActivated(response);
  }

  public onInteractableImageHover(image: OrderableImage) {}

  public onInteractableImageInteract(image: OrderableImage) {}

  public onInteractableImageOut(image: OrderableImage) {}

  public onNPCHover(npc: OrderableNonPlayerCharacter) {}

  public onNPCInteract(npc: OrderableNonPlayerCharacter) {}

  public onNPCOut(npc: OrderableNonPlayerCharacter) {}

  public onRemotePlayerHover(npc: RemotePlayerCharacter) {}

  public onRemotePlayerInteract(npc: RemotePlayerCharacter) {}

  public onRemotePlayerOut(npc: RemotePlayerCharacter) {}

  public onRemoveFriend(userId: string): Promise<boolean> {
    return this._generalUtilityComponent.removeFriend(userId);
  }

  public onSearchFriend(input: string): Promise<void> {
    return this._generalUtilityComponent.searchFriend(input);
  }

  public onSendKarma(toId: string): Promise<void> {
    return this._generalUtilityComponent.sendKarma(toId);
  }

  public async onTradeAbandon(key: string): Promise<void> {
    await this._tradeComponent.abandon(key);
  }

  public async onTradeAnswer(key: string, from: string, answer: boolean) {
    await this._tradeComponent.answerTrade(key, from, answer);
  }

  public async onTradeChange(key: string, side: TradeSide): Promise<void> {
    await this._tradeComponent.changeItem(key, side);
  }

  public async onTradeCommit(
    key: string,
    tx: string,
    commit: boolean,
  ): Promise<void> {
    await this._tradeComponent.commit(key, tx, commit);
  }

  public async onTradePropose(toId: string) {
    await this._tradeComponent.proposeTrade(toId);
  }

  public removeRemotePlayer(userId: string) {
    this._remotePlayerManagerComponent.removeRemotePlayer(userId);
  }

  public setFurnitureFloorAsBlocked(
    furnitureObject: FurnitureObject,
    blocked: boolean,
  ) {
    const floorPositions = furnitureObject.floorPositions;
    const obstaclePositions = [];

    floorPositions.forEach((position) => {
      obstaclePositions.push({
        position: position,
        tile: this._navigatableLayers[position.z].getTileAt(
          position.x,
          position.y,
        ),
      });
    });

    this._pathfinder.setPositionsAsBlocked(obstaclePositions, blocked);
  }

  public setSpawnLocation(identifier: string) {
    this._selectedSpawnLocationIdentifier = identifier;
  }

  public override update(time: number, deltaTime: number) {
    super.update(time, deltaTime);
    if (!this._isMobile) {
      this.updatePointer(this.input.mousePointer);
    }
  }

  public async updateRemotePlayerCharacter(userId: string) {
    if (userId === this._localPlayerId) {
      return;
    }

    const userData = await Network.Core.core.getUsersData(userId);
    const userNft = NFTHelpers.NFT.fromAddress(
      userData[0].metadata.activeCharacterAddress,
    );
    this._remotePlayerManagerComponent.updatePlayerCharacter(
      userId,
      formatNftName(userNft.name),
    );
    dispatchEvent(EnumUiEvents.UiGameUpdateRemotePlayerData, {
      openModal: false,
      id: userId,
    });
  }

  windowCallMovement = (tilePosition: { x: number; y: number; z: number }) => {
    this.move(tilePosition);
  };

  windowCallTeleport = (scene: string) => {
    dispatchEvent(EnumApplicationEvents.ApplicationStartMinigame, {
      sceneKey: scene,
      senderSceneKey: this.constructor["SceneKey"],
    });
  };

  protected async create() {
    // Also hook up some other networked components
    this._consumableNetworkedComponent = this.addComponent(
      new ConsumableNetworkedComponent(),
    );

    this._publicSpaceInputComponent = this.addComponent(
      new PublicSpaceInputComponent(),
    );

    super.create();

    this.setupSpawn();

    // For handling the spawning of NPCs
    const npcSpawnerComponent = this.addComponent<NPCSpawnerSceneComponent>(
      new NPCSpawnerSceneComponent(),
    );
    // So we retrieve NPC interaction events
    npcSpawnerComponent.delegate = this;

    const locationMarkerComponent =
      this.addComponent<LocationMarkerSceneComponent>(
        new LocationMarkerSceneComponent(),
      );
    locationMarkerComponent.setNpcs(
      npcSpawnerComponent.npcs,
      npcSpawnerComponent.events,
    );
    this._remotePlayerManagerComponent = this.addComponent(
      new RemotePlayerManagerSceneComponent(this._spawnDatas),
    );

    this._remotePlayerManagerComponent.delegate = this;
    // For handling other types of interactables
    const interactableImagesComponent =
      this.addComponent<InteractableImagesComponent>(
        new InteractableImagesComponent(),
      );
    interactableImagesComponent.delegate = this;

    this._tradeComponent = this.addComponent<TradeSceneComponent>(
      new TradeSceneComponent(),
    );
    this._tradeComponent.delegate = this;

    this._generalUtilityComponent = this.addComponent<GeneralUtilityComponent>(
      new GeneralUtilityComponent(),
    );
    this._generalUtilityComponent.delegate = this;
    this._furnitureComponent = this.addComponent<FurnitureComponent>(
      new FurnitureComponent(this._navigatableLayers),
    );

    // For usage with background music and SFX
    this._audioComponent = this.addComponent<AudioComponent>(
      new AudioComponent(),
    );

    switch (this.constructor["SceneKey"]) {
      case "LobbyScene": {
        this._onboardingSceneComponent =
          this.addComponent<OnboardingSceneComponent>(
            new OnboardingSceneComponent([OnboardingLobbyTaskTracker]),
          );
        break;
      }
      case "ShopScene": {
        this._onboardingSceneComponent =
          this.addComponent<OnboardingSceneComponent>(
            new OnboardingSceneComponent([OnboardingShopTaskTracker]),
          );
        break;
      }
      case "ArcadeScene": {
        this._onboardingSceneComponent =
          this.addComponent<OnboardingSceneComponent>(
            new OnboardingSceneComponent([OnboardingArcadeTaskTracker]),
          );
        break;
      }
      default:
    }

    // Only if this is not a private room, preload and the furniture here
    if (this.scene.key !== "CustomizableRoomScene") {
      await this.loadFurniture();
    }

    // Sort everything before displaying it
    this.sortOrderables();

    // Hook into input
    this._publicSpaceInputComponent.addInputListener(
      PublicSpaceInputEvent.TilePressed,
      this.moveToPressedTile,
      this,
      0,
    );

    this._publicSpaceInputComponent.addInputListener(
      PublicSpaceInputEvent.TileHovered,
      this.onTileHovered,
      this,
      0,
    );

    this._touchControlsSceneComponent =
      this.addComponent<TouchControlsSceneComponent>(
        new TouchControlsSceneComponent(
          { pinchZoom: true, pointerDragCamera: false },
          false,
          false,
        ),
      );
    // MARKTWAIN - Simple network component. Needs to be changed based on which map we're on
    let joinParams = {};
    if (this.getNetworkMatchIdentifier() === "room") {
      this._networkedComponent = this.addComponent<RoomNetworkedComponent>(
        new RoomNetworkedComponent(),
      );
      joinParams = {
        roomId: (this as unknown as CustomizableRoomScene).roomId,
      };
    } else {
      this._networkedComponent =
        this.addComponent<PublicSpaceNetworkedComponent>(
          new PublicSpaceNetworkedComponent(this.getNetworkMatchIdentifier()),
        );
    }

    try {
      await this._networkedComponent.connect();
      await this._networkedComponent.findAndJoinMatch(joinParams);

      this._eventHandlers.push(
        handleEvent(
          EnumUiEvents.UiNetworkUpdateCharacter,
          this.onUserChangedCharacter,
        ),
      );
      this._eventHandlers.push(
        handleEvent(
          EnumNetworkEvents.NetworkChatMessage,
          this.handleNetworkMessage.bind(this),
        ),
      );
    } catch (error) {
      $modals.openModal("GenericMessageModal");
    }

    //@todo move to component
    this._eventHandlers.push(
      handleEvent(EnumUiEvents.UiMove, (data) => {
        this.windowCallMovement(data);
      }),
    );
    // window["move"] = this.windowCallMovement;
    (window as any)["teleport"] = this.windowCallTeleport;
    (window as any)["position"] = () => this._localPlayer.tilePosition;
    // Add the selector outline last so it's on top
    this.addSelector();

    this._onboardingSceneComponent?.start();
  }

  protected override async init(data?: object) {
    super.init(data);

    if (isNativeMobileApp()) {
      mobileLandscape();
    }

    this._dynamicLoadComponent = this.addComponent<DynamicLoadComponent>(
      new DynamicLoadComponent(),
    );

    this._loadingSceneComponent = this.addComponent<LoadingSceneComponent>(
      new LoadingSceneComponent(false),
    ).on(LoadingSceneComponent.EventTypes.LoadingCompleted, () => {
      this._loadingSceneComponent.setText("connecting..");
    });
  }

  protected async loadFurniture() {
    const furniturePropertiesContainers =
      this.getPropertiesContainersOfType("furniture");
    const assetList: AssetList = { atlas: [] };

    furniturePropertiesContainers.forEach((propertiesContainer) => {
      const name =
        FurnitureNFTs[
          propertiesContainer.getProperty("Identifier").toLowerCase()
        ];
      assetList.atlas.push({
        key: name,
        textureURL:
          "/assets/game/mini/room/furniture/" + name + "/" + name + ".png",
        atlasURL:
          "/assets/game/mini/room/furniture/" + name + "/" + name + ".json",
      });
    });

    await this._dynamicLoadComponent.loadAssetList(assetList);

    furniturePropertiesContainers.forEach((propertiesContainer) => {
      const placement: TFurniturePlacement = {
        direction:
          propertiesContainer.getProperty("Direction") || Direction.South,
        id: propertiesContainer.getProperty("Identifier"),
        name: FurnitureNFTs[
          propertiesContainer.getProperty("Identifier").toLowerCase()
        ],
        position: propertiesContainer.tilePosition,
      };

      const furnitureObject = this._furnitureComponent.addFurniture(placement);

      this.setFurnitureFloorAsBlocked(furnitureObject, true);
      this.movePlayerIfBlockedByFurniture(furnitureObject);
    });
  }

  protected async loadFurnitureAssets(furnitureName: string) {
    return this._dynamicLoadComponent.loadAssetList({
      atlas: [
        {
          key: furnitureName,
          textureURL:
            "/assets/game/mini/room/furniture/" +
            furnitureName +
            "/" +
            furnitureName +
            ".png",
          atlasURL:
            "/assets/game/mini/room/furniture/" +
            furnitureName +
            "/" +
            furnitureName +
            ".json",
        },
      ],
    });
  }

  protected movePlayerIfBlockedByFurniture(furnitureObject: FurnitureObject) {
    if (!this._localPlayer) {
      return;
    }

    if (
      furnitureObject.collidesWithMapPositions([this._localPlayer.tilePosition])
    ) {
      const newPositionNode = this._pathfinder.getClosestWalkableNode(
        this._localPlayer.tilePosition,
      );

      if (newPositionNode !== undefined) {
        this.moveCharacterToGoalIfPossible(this._localPlayer, {
          x: newPositionNode.x,
          y: newPositionNode.y,
          z: newPositionNode.z,
        });
      }
    }
  }

  protected onNoTileSelected() {
    this._selector?.deactivate();
  }

  protected onShutdown(): void {
    super.onShutdown();
    this._onboardingSceneComponent?.shutdown();
    for (const eventHandler of this._eventHandlers) {
      eventHandler.destroy();
    }
    this._eventHandlers = [];
    (window as any)["position"] = null;
  }

  protected onTileHovered(tilePosition: TilePosition3D): boolean {
    if (!this._selector) return false;

    const furniture =
      this._furnitureComponent.getFurnitureObjectAt(tilePosition);
    this._selector.tilePosition = tilePosition;

    if (furniture !== null) {
      this._selector.deactivate();
      if (furniture.interactive) {
        this.input.setDefaultCursor("pointer");
      }
    } else {
      this._selector.activate();
      this.input.setDefaultCursor("");
    }

    return true;
  }

  protected onUserChangedCharacter = (characterAddress: string) => {
    const characterName = NFTHelpers.NFT.fromAddress(characterAddress).name;

    this._localPlayer.updateCharacterName(formatNftName(characterName));
    this._networkedComponent.sendPlayerChangedAvatar();
  };

  protected override preload() {
    super.preload();

    this.load.spritesheet(
      "selector_spritesheet",
      "/assets/game/sprites/selector-sheet.png",
      { frameHeight: 128, frameWidth: 128 },
    );

    this.preloadAllCharacters();
    this.input.setPollAlways();
  }

  private activateFurniture(furnitureObject: InteractiveFurnitureObject) {
    if (furnitureObject.activated) return;

    if (furnitureObject.offlineActivation) {
      furnitureObject.activate();
    } else {
      (
        this._networkedComponent as RoomNetworkedComponent
      ).sendActivateFurniture(furnitureObject);
    }
  }

  private async addLocalPlayer() {
    let role;
    if (
      currentAccount.user &&
      currentAccount.user.metadata &&
      currentAccount.user.metadata.activeCharacter
    ) {
      if ("role" in currentAccount.user.metadata) {
        role = currentAccount.user.metadata["role"];
      }
      this._characterName = formatNftName(
        currentAccount.user.metadata.activeCharacter.name,
      );
    } else {
      console.error("no user");
    }
    this._localPlayer = new OrderableIsometricPlayer(
      this,
      0,
      0,
      this._characterName || "default_character",
      role,
      true,
    );

    this._localPlayer.face(this._spawnData.direction);
    this._localPlayer.tilePosition = this._spawnData.tilePosition;
    // Spread out in the Lobby to make it look busier
    if (this.constructor["SceneKey"] == "LobbyScene") {
      this._localPlayer.tilePosition = this.nextSpawnTilePosition(
        this._spawnData.tilePosition,
      );
      this._networkedComponent.sendPlayerWillMoveTo(
        this._localPlayer.tilePosition,
      );
    }
    this._localPlayer.setToTilePosition();
    this._localPlayer.on(IsometricCharacter.EventTypes.Teleported, () => {
      this._networkedComponent.sendPlayerTeleported();
    });
    this._localPlayer.on(
      IsometricCharacter.EventTypes.StartedPath,
      (tilePath: TilePath) => {
        this._networkedComponent.sendPlayerWillMoveTo(tilePath.goal.position);
      },
    );
    this._localPlayer.on(
      OrderableIsometricPlayer.EventTypes.Emote,
      (identifier: string) => {
        this._consumableNetworkedComponent.sendConsume(identifier);
      },
    );
    this.add.existing(this._localPlayer);
    // this._localPlayer.setDepth(999);
    this.addOrderable(this._localPlayer);
  }

  private async addPlayerWithDependencies() {
    await this.addLocalPlayer();

    // Add the smart camera, place it near the player first
    this.addComponent<SmartCameraComponent>(
      new SmartCameraComponent(this.cameras.main, this._localPlayer),
    );

    // Handle teleporting from certain tiles
    this.addComponent<TeleporterSceneComponent>(
      new TeleporterSceneComponent(this._localPlayer),
    );

    this._loadingSceneComponent // Alright we can remove this now
      .setText("done");
    this._loadingSceneComponent.hide();
  }

  private async addRemotePlayer(userId: string, tilePosition?: TilePosition3D) {
    const remotePlayerData = await Network.Core.core.getUsersData(userId);

    this._remotePlayerManagerComponent.createAndAddRemotePlayer(
      remotePlayerData[0],
      tilePosition,
    );
  }

  private addSelector() {
    this._selector = new OrderableSelector(this);
    this.add.existing(this._selector);
    this.addOrderable(this._selector);
  }

  private moveToPressedTile(tilePosition: TilePosition3D): boolean {
    if (this._touchControlsSceneComponent.pinching) {
      return false;
    }

    this.move(tilePosition);

    return true;
  }

  private preloadAllCharacters() {
    // Preload ALL characters
    usableCharacterNames.forEach((characterName) => {
      loadCharacterAtlasAndJSONIntoScene(this, characterName);
    });
  }

  // TODO only works for lobby now
  private nextSpawnTilePosition(tilePostion: TilePosition3D): TilePosition3D {
    const { x, y, z } = tilePostion; // TODO should work in differnt scenes
    const xNumbers = [13, 14, 15, 16, 17, 18];
    const yNumbers = [21, 22, 23, 24, 25, 26];
    const randomXIndex = Math.floor(Math.random() * xNumbers.length);
    const randomYIndex = Math.floor(Math.random() * yNumbers.length);

    const randomX = xNumbers[randomXIndex];
    const randomY = yNumbers[randomYIndex];

    return {
      x: randomX,
      y: randomY,
      z: 2,
    };
  }

  private setupSpawn() {
    this._spawnDatas = {};
    const spawnPropertiesContainers =
      this.getPropertiesContainersOfType("spawn");

    // See if we want to start at a specific spawn point
    for (let index = 0; index < spawnPropertiesContainers.length; ++index) {
      const propertiesContainer = spawnPropertiesContainers[index];
      const identifier = propertiesContainer.getProperty("Identifier");

      const tilePosition = propertiesContainer.tilePosition;
      // if (this.constructor["SceneKey"] == "LobbyScene") {
      //   tilePosition = this.nextSpawnTilePosition(tilePosition);
      // }
      if (identifier) {
        const spawnData: ISpawnData = {
          direction:
            NameToDirection[
              propertiesContainer.getProperty("Direction", "East")
            ],
          identifier,
          tilePosition,
        };
        this._spawnDatas[identifier] = spawnData;
        if (identifier === this._selectedSpawnLocationIdentifier) {
          this._spawnData = spawnData;
        }
      }
    }

    // If none is found, just use the first one
    if (!this._spawnData) {
      const spawnDatas = Object.values(this._spawnDatas);
      this._spawnData = spawnDatas.length > 0 ? spawnDatas[0] : null;
    }
  }

  private updatePointer = (pointer) => {
    // console.log(pointer.worldX, pointer.worldY);
    const tilePosition = this.tilePositionAtWorldPosition(
      new Vector2(pointer.worldX, pointer.worldY),
    );
    if (tilePosition !== null) {
      // If it's not a different tile
      if (
        null !== this._lastHoveredPosition &&
        equals(this._lastHoveredPosition, tilePosition)
      ) {
        return;
      }

      // Let the world know
      this._lastHoveredPosition = tilePosition;
      this.onTileHovered(this._lastHoveredPosition);
      return;
    }

    // If we got here, nothing is selected
    this.onNoTileSelected();
  };
}
