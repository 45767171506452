// TODO on sign up schedule push notification and cancel when finished

import { EPackageSize } from "@overlay/types/enums";
import { EventType, OrientationLock, PostMessage } from "./types";
import { EnumApplicationEvents } from "@shared/enums";
import { dispatchEvent, handleEvent } from "@shared/Events";

(window as any)["onRetrieveStorageValue"] = (response: string) => {
  const split = response.split("_____");
  const key = split[0];
  const data = {
    key: key,
    value: response.slice(key.length + 5),
  };

  dispatchEvent(EnumApplicationEvents.ApplicationValueRetrieved, data);
};

// this function is being used to test the app version
export const mobileCanRetrieve = async (): Promise<boolean> => {
  return await new Promise((resolve) => {
    handleEvent(EnumApplicationEvents.ApplicationValueRetrieved, () => {
      resolve(true);
    });
    setTimeout(() => resolve(false), 500);
    mobileRetrieveValue("test");
  });
};

export const mobileSignInWeb3Auth = () => {
  postMessage({
    type: EventType.WEB3AUTH_LOGIN,
  });
};

export const mobileCachedWeb3Auth = () => {
  postMessage({
    type: EventType.WEB3AUTH_CACHED,
  });
};

export const mobileWatchAd = () => {
  postMessage({
    type: EventType.REWARDED_AD,
    adUnitIdsIOS: [
      "ca-app-pub-3045917793437355/7262318166",
      "ca-app-pub-3045917793437355/1044781214",
    ],
    adUnitIdsAndroid: [
      "ca-app-pub-3045917793437355/6528639230",
      "ca-app-pub-3045917793437355/9525110304",
    ],
    adKeyWords: [],
    customAdData: "",
  });
};

export const mobileWatchAdWelcomeBonus = () => {
  postMessage({
    type: EventType.REWARDED_AD,

    adUnitIdsIOS: ["ca-app-pub-3045917793437355/7983767651"],
    adUnitIdsAndroid: ["ca-app-pub-3045917793437355/1816200156"],
    adKeyWords: [],
    customAdData: "",
  });
};

export const mobileReview = () => {
  postMessage({
    type: EventType.REQUEST_REVIEW,
  });
};

export const mobileSetUserId = (userId: string) => {
  postMessage({
    type: EventType.SET_USER_ID,
    userId,
  });
};

export const mobileLandscape = () => {
  postMessage({
    type: EventType.ROTATE,
    direction: OrientationLock.LANDSCAPE,
  });
};

export const checkForMobileUpdate = () => {
  const GET_DEVICE_INFO = 10;
  // if window.deviceInfo !== undefined
  // show modal
  postMessage({
    type: GET_DEVICE_INFO,
  });
  setTimeout(() => {
    console.log({ deviceInfo: window.deviceInfo });
    if (window.deviceInfo) {
      // You need to update
    }
  }, 1000);
};

export const mobilePortrait = () => {
  postMessage({
    type: EventType.ROTATE,
    direction: OrientationLock.PORTRAIT_UP,
  });
};

export const mobileGetPushToken = () => {
  postMessage({
    type: EventType.GET_PUSH_TOKEN,
  });
};

export const mobileSchedulePush = ({
  title,
  body,
  data,
  seconds,
}: {
  title: string;
  body: string;
  data: any;
  seconds: number;
}) => {
  postMessage({
    type: EventType.SCHEDULE_PUSH,
    push: {
      title,
      body,
      data,
      seconds,
    },
  });
};

export const getDeviceInfo = () => {
  postMessage({
    type: EventType.GET_DEVICE_INFO,
  });
};

export enum AdjustEvent {
  SIGN_UP = "7b92x9",
  SIGN_UP_PURCHASE = "he5m4u",
  PURCHASE = "tezgz3",
  SPECIAL_OFFER_PURCHASE = "ead7ku",
  WATCH_AD = "5tjfdy",
}

export type AdjustEventData = {
  // Our
  email?: string;
  username?: string;
  selectedCharacter?: string;
  // Adjust
  revenue?: number;
  currency?: string;
  transactionId?: string;
  callbackId?: string;
  callbackKey?: string;
  callbackValue?: any;
};

export const mobileTrackAdjust = (
  eventToken: AdjustEvent,
  trackData: AdjustEventData,
) => {
  postMessage({
    type: EventType.TRACK,
    eventToken,
    ...trackData,
  });
};
export const mobileCancelPush = () => {
  postMessage({
    type: EventType.CANCEL_ALL_SCHEDULED_NOTIFICATIONS_ASYNC,
  });
};

export const mobileUnlockOrientation = () => {
  postMessage({
    type: EventType.UNLOCK_ORIENTATION,
  });
};

export const mobileLoadingScreen = (showSplashScreen: boolean) => {
  postMessage({
    type: EventType.SET_LOADING,
    showSplashScreen,
  });
};

/**
 * Product Id's are defined in https://app.revenuecat.com/projects/9f323c72/products
 * @param productId
 */
export const mobileBuy = (productId: string) => {
  postMessage({
    type: EventType.BUY_PRODUCT,
    productId,
  });
};

export const mobileBuyPackage = (packageSize: EPackageSize) => {
  let packageIndex = -1;
  switch (packageSize) {
    case EPackageSize.SMALL:
      packageIndex = 0;
      break;
    case EPackageSize.MEDIUM:
      packageIndex = 1;
      break;
    case EPackageSize.BIG:
      packageIndex = 2;
      break;
    case EPackageSize.SPECIAL:
      packageIndex = 3;
      break;
  }

  if (packageIndex == -1) {
    throw new Error(`Invalid package`);
  }

  postMessage({
    type: EventType.BUY_PACKAGE,
    packageIndex,
  });
};

export const mobileCopyToClipboard = (clipboard: string) => {
  postMessage({
    type: EventType.COPY_CLIPBOARD,
    clipboardString: clipboard,
  });
};

export async function getPackagePrices(): Promise<any> {
  postMessage({
    type: EventType.GET_PACKAGE_PRICES,
  });
  return await new Promise((resolve) => {
    setTimeout(() => {
      resolve("timeout");
    }, 1000);
    (window as any)["exposedSetPackagePricing"] = (packages: any) => {
      resolve(JSON.parse(packages));
    };
  });
}

export async function mobileStoreValue(key: string, value: string) {
  postMessage({
    type: EventType.STORE_VALUE,
    storeData: {
      key: key,
      value: value,
    },
  });
}

export async function mobileRetrieveValue(key: string) {
  postMessage({ type: EventType.RETRIEVE_VALUE, storeData: { key: key } });
}

export const postMessage = (postMessage: PostMessage) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify(postMessage));
  }
};
