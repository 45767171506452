/* eslint-disable prettier/prettier */
import { Scene } from "phaser";

export default class Button extends Phaser.GameObjects.Container {
  private text: Phaser.GameObjects.Text;
  private button: Phaser.GameObjects.Sprite;

  private normal: string;
  private pressed: string;
  private hover: string;
  private disabled: string;

  // public scene: Scene;

  private isDisabled: boolean;

  private registeredEvents: string[] = [];

  /**
   *
   * @param scene
   * @param x
   * @param y
   * @param normal
   * @param buttonText
   * @param pressed
   * @param hover
   * @param disabled
   * @param frame
   */
  constructor(
    scene: Scene,
    x: number,
    y: number,
    normal: string,
    buttonText?: string,
    pressed?: string,
    hover?: string,
    disabled?: string,
    frame?: string | number,
  ) {
    super(scene, x, y);

    this.button = new Phaser.GameObjects.Sprite(scene, x, y, normal, frame);

    scene.add.existing(this.button);

    this.add(this.button);

    this.scene = scene;

    this.normal = normal;
    this.pressed = pressed;
    this.hover = hover;
    this.disabled = disabled;

    this.button.setInteractive();

    this.addPointerListeners();

    this.addText(buttonText);
  }

  private addPointerListeners() {
    this.button.on("pointerover", this.onOver.bind(this));
    this.button.on("pointerout", this.onOut.bind(this));
    this.button.on("pointerdown", this.onDown.bind(this));
    this.button.on("pointerup", this.onUp.bind(this));
  }

  private addText(buttonText?: string) {
    if (!buttonText) {
      buttonText = "";
    }

    //TODO: style is static at the moment extrapolate into constructor.
    this.text = new Phaser.GameObjects.Text(
      this.scene,
      this.x,
      this.y,
      buttonText,
      { font: "20px depixel", color: "#FFFFFF" },
    );

    this.scene.add.existing(this.text);

    this.text.setX(this.x - this.text.width / 2);
    this.text.setY(this.y - this.text.height / 2);

    this.add(this.text);
  }

  private onOver(event: any) {
    if (this.isDisabled) {
      return;
    }

    if (!!this.hover) {
      this.button.setTexture(this.hover);
    }
  }

  private onOut(event: any) {
    if (this.isDisabled) {
      return;
    }

    this.button.setTexture(this.normal);
  }

  private onDown(event: any) {
    if (this.isDisabled) {
      return;
    }

    if (!!this.pressed) {
      this.button.setTexture(this.pressed);
    }
  }

  private onUp(event: any) {
    if (this.isDisabled) {
      return;
    }

    for (let eventId = 0; eventId < this.registeredEvents.length; eventId++) {
      const eventToFire = this.registeredEvents[eventId];

      this.scene.events.emit(eventToFire);
    }

    this.button.setTexture(this.hover || this.normal);
  }

  public setDisabled(isDisabled: boolean): void {
    if (isDisabled) {
      this.isDisabled = true;

      this.button.setTexture(this.disabled || this.normal);
    } else {
      this.isDisabled = false;

      this.button.setTexture(this.normal);
    }
  }

  public setText(newText: string) {
    this.text.setText(newText);

    this.text.setX(this.x - this.text.width / 2);
    this.text.setY(this.y - this.text.height / 2);
  }

  public setTextStyle(textStyle: Phaser.GameObjects.TextStyle) {
    this.text.setStyle(textStyle);

    this.text.x = this.button.x - this.text.width / 2;
    this.text.y = this.button.y - this.text.height / 2;
  }

  public addOnClickEvent(event: string): boolean {
    for (let eventId = 0; eventId < this.registeredEvents.length; eventId++) {
      if (this.registeredEvents[eventId] === event) {
        return false;
      }
    }

    this.registeredEvents.push(event);

    return true;
  }

  public removeOnClickEvent(event: string): boolean {
    for (let eventId = 0; eventId < this.registeredEvents.length; eventId++) {
      if (this.registeredEvents[eventId] === event) {
        this.registeredEvents.splice(eventId, 1);

        return true;
      }
    }

    return false;
  }

  // public onClick(event: string, callback: (event: Event) => any, context: any) : void {
  //     this.scene.events.on(event, callback, context);
  // }

  // public offClick(event: string, callback: (event: Event) => any, context: any) : void {
  //     this.scene.events.off(event, callback, context);
  // }
}
